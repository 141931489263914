import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import classes from "../../assets/styles/Pages/Help/AboutUs.module.css";
import about_us from "../../assets/images/help/about_us.svg";
import { SETTING } from "../../utils/constants/apiUrl";
import { get } from "../../server";
import showToast from "../../helpers/showToast";
import Loader from "../../components/Loader";
const AboutUs = () => {
  const [loading, setLoading] = useState(false);
  const [aboutUs, setAboutUs] = useState({});

  const loadAboutUs = async () => {
    setLoading(true);
    let { status, message, data } = await get(SETTING + "/page");
    if (status) {
      if (data.length > 0) {
        const aboutUs = data.filter((page) => page.page_type === "about_us");
        setAboutUs(aboutUs["0"]);
      }
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadAboutUs();
  }, []);
  return (
    <>
      <div className="">
        <div className="helpBackLinkWrapper">
          <Link to="/help">Back</Link>
        </div>
        {/* <h6>About Us</h6> */}
        <div className={classes.aboutUsHeader}>
          <div className="row flex-md-row flex-column-reverse gy-4">
            <div className="col-md-6 col-sm-12">
              <h5 className="helpHeading">About Us</h5>
              <p className="helpSubHeading">Welcome to Contact Co</p>

              <p className="helpDetail">
                Revolutionizing Connectivity and Empowering You to Stay
                Connected in Style
              </p>
            </div>
            <div className="col-md-6 col-sm-12">
              <img src={about_us} alt="" className="helpImage" />
            </div>
          </div>
        </div>

        {!loading ? (
          <>
            <div className={`${classes.contentWrapper}`}>
              <p className="helpSubHeading">{aboutUs?.title}</p>

              <p
                className={classes.detail}
                dangerouslySetInnerHTML={{ __html: aboutUs?.description }}
              />
            </div>
          </>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
};

export default AboutUs;
