import { useNavigate, useParams, useLocation } from "react-router-dom";
import classes from "../assets/styles/EmptyListingBanner.module.css";
import heading from "../assets/images/home_page_images/heading.svg";
import dashedArrow from "../assets/images/home_page_images/dashedArrow.svg";
import useFunctionsCount from "../hooks/useFunctionsCount";
import functionTypes from "../utils/constants/functionTypes";
import showToast from "../helpers/showToast";
import { subscribedPackage } from "../utils/store";
import { useAtom } from "jotai";
import { useState } from "react";
import FunctionsLimitModal from "./Modals/FunctionsLimitModal";

const EmptyListingBanner = ({
  headLineText,
  title,
  onClick,
  link,
  showAdd = true,
  type = null,
  getData,
  dynamicQRCodeId,
}) => {
  const {
    compareLimits,
    updateCount,
    limitExceededForStarter,
    compareLimitsForDynamicQR,
  } = useFunctionsCount();
  const [showModal, setShowModal] = useState(false);
  const [purchasedPackage, setPurchasedPackage] = useAtom(subscribedPackage);
  const navigate = useNavigate();
  const { teamId, userId } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const team_id = searchParams.get("team_id");
  const user_id = searchParams.get("user_id");

  // const handleClick = (link) => {
  //   const res = handleShowCustomUrlModal(type);
  //   if (res)
  //     return showToast(
  //       false,
  //       "The requested feature is not compatible with your current plan. Upgrade your plan to continue using these features"
  //     );
  //   navigate(link);
  // };

  const handleClick = async (link) => {
    if (dynamicQRCodeId) {
      const res = handleShowCustomUrlModal(type);
      if (res)
        return showToast(
          false,
          "The requested feature is not compatible with your current plan. Upgrade your plan to continue using these features"
        );
      navigate(link);
    } else if (purchasedPackage.id === 1) {
      limitExceededForStarter()
        .then((isLimitExceeded) => {
          if (isLimitExceeded) {
            setShowModal(true);
          } else {
            navigate(link);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
      return;
    } else {
      const res = handleShowCustomUrlModal(type);
      if (res)
        return showToast(
          false,
          "The requested feature is not compatible with your current plan. Upgrade your plan to continue using these features"
        );
      navigate(link);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleShowCustomUrlModal = (type) => {
    if (type == "card") {
      if (dynamicQRCodeId !== null) {
        return compareLimitsForDynamicQR(functionTypes.CARD, dynamicQRCodeId);
      } else {
        return compareLimits(functionTypes.CARD);
      }
    }

    return false;
  };

  return (
    <>
      <div className={classes.infoWrapper}>
        <div className={classes.contentWrapper}>
          <img src={heading} alt="" />
          <div>
            <h6>Tips</h6>
            <p>
              {`You haven't created any ${headLineText} yet, in order to get started click the "${title}"  button below`}
            </p>
          </div>
        </div>
      </div>

      {showAdd ? (
        <div className={classes.arrowImgWrapper}>
          <div className={classes.arrowImg}>
            <img src={dashedArrow} alt="" />
          </div>

          <div className={classes.createBtnWrapper}>
            {link ? (
              <button onClick={() => handleClick(link)}>{title}</button>
            ) : (
              // <Link to={link}>{title}</Link>
              <button onClick={onClick}>{title}</button>
            )}
          </div>
        </div>
      ) : null}
      <FunctionsLimitModal
        show={showModal}
        handleClose={handleCloseModal}
        getData={getData}
      />
    </>
  );
};

export default EmptyListingBanner;
