import React from "react";
import classes from "../assets/styles/PageComponentsStyles/Settings/Subscription.module.css";
import basicIcon from "../assets/images/settings_page_icons/basicicon.png";
import proIcon from "../assets/images/settings_page_icons/proicon.png";
import premiumIcon from "../assets/images/settings_page_icons/premiumicon.png";
import enterpriseIcon from "../assets/images/settings_page_icons/enterpriseicon.png";
import {
  COUPON_APPLY,
  PACKAGES_LISTING,
  GET_USER,
  CANCEL_SUBSCRIPTION,
  GET_USER_SUBSCRIPTION,
} from "../utils/constants/apiUrl";
import { get } from "../server";
import {
  loggedInUser,
  subscribedPackage,
  accountType,
  isOwnerPackage,
  teamIDCheck,
  userIDCheck,
} from "../utils/store";
import { useAtom } from "jotai";
import Loader from "../components/Loader";
import { useEffect, useState } from "react";
import showToast from "../helpers/showToast";
import { loadStripe } from "@stripe/stripe-js";
import SubscriptionModal from "../components/Modals/SubscriptionModal";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import useLogout from "../hooks/useLogout";
import { AiFillCheckCircle } from "react-icons/ai";
import { post } from "../server";
import { MdSend } from "react-icons/md";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
// Load Stripe Data
const stripePromise = loadStripe(
  // "pk_test_51OZ7XQSBnxMCCnjEI1odHcAyBmW5WZd48fFUxnvHPpg2LDNKRGGCOJ3sU78SW9vi5wOmuLrwX2qxf8slIZpXFukR00DuK51ScU"
  // "pk_test_51LsD48ErALx3fhV7MXHeYvMoWC4uLJr33gThVHtfuMAbiE9kNWsDMsYmKAcv1KWGSlSBPiQwplojALZAJ1fUk8bO00NnHNL8FY" //sourav send
  "pk_live_51LsD48ErALx3fhV7pL6Gg2Wd2ymytgrKjj1tjb9cJICFlMAO24seG1sVDFTtKBFuOeD9luN1KSlS7Ho3xuY9tRZt00GZDEzFrx"
);

// Stripe OPtions
const elementsOptions = {
  fonts: [
    {
      cssSrc: "https://fonts.googleapis.com/css?family=Roboto",
    },
  ],
  locale: "en",
  placeholder: {
    number: "Card number",
    cvc: "CVC",
    expiry: "MM/YY",
  },
  style: {
    base: {
      fontSize: "16px",
      color: "#fff",
      "::placeholder": {
        color: "#fff",
      },
    },
    invalid: {
      color: "#9e2146",
    },
  },
};

const Subscription = () => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedPackage, setSelectedPackage] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [purchasedPackage, setPurchasedPackage] = useAtom(subscribedPackage);
  const [packageSubscribed, setPackageSubscribed] = useAtom(subscribedPackage);
  const [personalAccount, setPersonalAccount] = useAtom(accountType);
  const [isOwnerPackageActive, setIsOwnerPackageActive] =
    useAtom(isOwnerPackage);
  const [userTeamId, setUserTeamId] = useAtom(teamIDCheck);
  const [userID, setUserID] = useAtom(userIDCheck);
  const [activeKeys, setActiveKeys] = useState([]);
  const [hasCoupon, setHasCoupon] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [correctCouponEntered, setCorrectCouponEntered] = useState(false);
  const [incorrectCouponEntered, setIncorrectCouponEntered] = useState(false);
  const [showCouponInputs, setShowCouponInputs] = useState({});
  const [couponData, setCouponData] = useState("");
  const [coupons, setCoupons] = useState({});
  const [ownedpackageId, setOwnedPackageId] = useState(null);
  const [blankCouponField, setBlankCouponField] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const location = useLocation();
  const logout = useLogout();
  const navigate = useNavigate();
  const { teamId } = useParams();
  const creationDate = new Date(purchasedPackage?.end_date);
  const currentDate = new Date();
  // Compare the current date with the end_date
  const isExpired = creationDate < currentDate;
  const formattedDate = creationDate.toLocaleString();
  // const handleCheckboxChange = () => {
  //   setHasCoupon((prev) => !prev);
  // };
  const handleCheckboxChange = (packageId) => {
    setShowCouponInputs((prev) => ({
      ...prev,
      [packageId]: !prev[packageId],
    }));
  };

  const handleCouponInputChange = (event) => {
    setCouponCode(event.target.value);
  };

  const handleTabSelect = (subscriptionIndex, key) => {
    setActiveKeys((prevActiveKeys) => {
      // Create a copy of the previous activeKeys array
      const updatedActiveKeys = [...prevActiveKeys];
      // Update the active key for the corresponding subscription
      updatedActiveKeys[subscriptionIndex] = key;
      return updatedActiveKeys;
    });
  };

  // Handle Logout
  const handleLogout = () => {
    logout();
  };

  const applyCoupon = async (subscriptionType, packageId) => {
    if (!couponCode) {
      setBlankCouponField(true);
      return;
    }
    // setLoading(true);
    setCorrectCouponEntered(false);
    setIncorrectCouponEntered(false);

    const payload = {
      package_id: packageId,
      coupon_code: couponCode,
      subscription_type: subscriptionType,
    };

    let { status, message, data } = await post(
      COUPON_APPLY,
      payload,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      setCorrectCouponEntered(true);
      setCouponData(data);
    } else {
      setIncorrectCouponEntered(true);
    }
  };
  const getSubscriptionDetails = async () => {
    let { status, message, data } = await get(
      GET_USER,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      if (data && data.package_details && data.package_details.length > 0) {
        const teamOwnerPackages = data.package_details.filter(
          (pkg) => pkg.is_team_owner === 1
        );
        // setPerTeamMemberPrice(teamOwnerPackages[0].price_per_user_after_limit);
        setOwnedPackageId(teamOwnerPackages[0]?.package_id);
      }
    } else {
      showToast(status, message);
      navigate("/");
    }
  };
  const getSubscriptionPackageDetails = async () => {
    const payload = {
      user_id: userID,
      team_id: userTeamId,
    };
    let { status, message, data } = await post(
      GET_USER_SUBSCRIPTION,
      payload,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      setPurchasedPackage(data[0]?.package);
    } else {
      showToast(status, message);
    }
  };
  // Load singlePackage data
  const loadSubscriptions = async () => {
    // if (!userTeamId) {
    //   showToast(false, "Team ID is required to load subscriptions.");
    //   return;
    // }
    setLoading(true);
    const queryString = `?user_id=${userID}&team_id=${userTeamId}`;
    if (userTeamId) {
      let { status, message, data } = await get(
        PACKAGES_LISTING + queryString,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );

      if (status) {
        if (data) {
          let ownerpackages = data.filter(
            // (singlePackage) => singlePackage.package_type == "owner"
            (singlePackage) =>
              singlePackage.package_type === "owner" ||
              singlePackage.package_type === "customer_specific_owner"
          );
          setSubscriptions(ownerpackages);
        }
      } else {
        showToast(status, message);
      }
    }
    setLoading(false);
  };

  // const loadCoupons = async () => {
  //   setLoading(true);
  //   let { status, message, data } = await get(
  //     COUPON,
  //     Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
  //   );
  //   if (status) {
  //     if (data.length > 0) {
  //       setCoupons(data);
  //     }
  //   } else {
  //     showToast(status, message);
  //   }
  //   setLoading(false);
  // };

  useEffect(() => {
    if (userTeamId && userID) {
      loadSubscriptions();
    }
    getSubscriptionDetails();
    getSubscriptionPackageDetails();
  }, []);

  if (loading) {
    return <Loader />;
  }
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handlePayment = async (obj) => {
    setSelectedPackage(obj);
    setShowModal(true);
  };

  const handlePersonalAccount = () => {
    let teamPackage = loggedUser?.package_details?.find(
      (obj) => obj.team_id == null
    );

    setIsOwnerPackageActive(false);
    setPurchasedPackage(teamPackage);
    setUserTeamId(null);
    setPersonalAccount(true);
    setUserID(null);
    navigate("/");
  };

  const checkExpire = () => {
    let isTeamPackage = loggedUser?.package_details.filter(
      (obj) => obj.team_id == loggedUser?.teams.id
    );

    let endDate = new Date(isTeamPackage[0]?.end_date);
    let todayDate = new Date();
    if (todayDate >= endDate) return false;
    return true;
  };

  const handleCloseCancelModal = () => {
    setShowCancelModal(false);
  };

  const handleCancelSubscription = () => {
    setShowCancelModal(true);
  };

  const handleConfirmDelete = async () => {
    const payload = {
      device_type: "web",
      team_id: userTeamId,
    };
    let { status, message, data } = await post(
      CANCEL_SUBSCRIPTION,
      payload,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      setPurchasedPackage(data.package);
      showToast(true, message);
      loadSubscriptions();
      getSubscriptionDetails();
      getSubscriptionPackageDetails();
      setShowCancelModal(false);
      navigate("/paymentNotPaid");
    } else {
      showToast(false, message);
    }
    setLoading(false);
  };
  return (
    <>
      <div className="">
        <div className="d-flex flex-md-row flex-column align-items-center justify-content-between px-3">
          {location.pathname === "/subscription" && (
            <div className={classes.skipBtnWrapper}>
              <button onClick={handleLogout}>Logout</button>
            </div>
          )}

          <h1 className={classes.heading}>Subscription Plans</h1>
          <div>
            {/* {location.pathname === "/subscription" &&
              loggedUser?.role?.includes("team_owner") &&
              !personalAccount && (
                <div className={classes.skipBtnWrapper}>
                  <button
                    style={{ whiteSpace: "nowrap", width: "200px" }}
                    onClick={handlePersonalAccount}
                  >
                    Back to Personal Screen
                  </button>
                </div>
              )} */}

            {location.pathname === "/subscription" &&
              loggedUser?.role?.includes("team_owner") &&
              personalAccount && (
                <div className={classes.skipBtnWrapper}>
                  <button onClick={() => navigate("/")}>Go Back</button>
                </div>
              )}
          </div>
        </div>
        <div className={classes.mainWrapper}>
          {purchasedPackage?.id !== 1 ? (
            <div className={classes.cardsWrapper}>
              {formattedDate !== "Invalid Date" ? (
                <div className={`${classes.card} py-3 mb-4`}>
                  {purchasedPackage?.end_date !== null ? (
                    <p className="noteText text-center">
                      {isExpired
                        ? `Your subscription has expired on ${formattedDate}`
                        : `Your purchased package will end on ${formattedDate}`}
                    </p>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
          <div
            className={`${classes.cardsWrapper} cardsWrapper`}
            // style={{
            //   gridTemplateColumns: "repeat(auto-fit, minmax(0, 330px))",
            //   justifyContent: "center",
            // }}
          >
            {subscriptions.length !== 0
              ? subscriptions
                  .filter((singlePackage) => singlePackage.status === 1)
                  .map((singlePackage, index) => (
                    <div
                      className={`${classes.card} card`}
                      key={`pricingCard${index}`}
                      style={{ padding: "20px 5px" }}
                    >
                      <button
                        className={`${classes.subscriptionType} subscriptionType`}
                      >
                        {singlePackage.package_name}
                      </button>
                      <div className={`${classes.imgWrapper} imgWrapper`}>
                        <img
                          src={
                            singlePackage.package_name === "Starter"
                              ? basicIcon
                              : singlePackage.package_name === "Premium"
                              ? proIcon
                              : singlePackage.id === 6
                              ? enterpriseIcon
                              : premiumIcon
                          }
                          alt=""
                        />
                      </div>

                      {/* <h3 className={classes.title}>
                        {singlePackage.id === 6
                          ? "Custom Price"
                          : singlePackage.id === 5
                          ? `$${singlePackage.price_monthly}/month`
                          : `$${singlePackage.price}/${singlePackage.duration_type}`}
                      </h3> */}
                      <h3 className={classes.title}>
                        {couponData.package_id === singlePackage.id
                          ? `$${couponData.package_price_after_discount}/month`
                          : singlePackage.id === 6
                          ? "Custom Price"
                          : singlePackage.id === 5
                          ? `$${singlePackage.price_monthly}/month`
                          : `$${singlePackage.price}/${singlePackage.duration_type}`}
                      </h3>
                      <ul
                        className={classes.list}
                        style={{ color: "var(--heading_text_color)" }}
                      >
                        {/*  Card Section */}
                        {singlePackage.limit_card_create == 0 ? (
                          <li>
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ color: "var(--primary_text_color)" }}
                            >
                              <p>Personal/Business Card</p>
                              <p>{singlePackage.limit_card_create}</p>
                            </div>
                          </li>
                        ) : (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Personal/Business Card</p>
                              <p>{singlePackage.limit_card_create}</p>
                            </div>
                          </li>
                        )}

                        {/* Connection */}
                        {singlePackage.limit_connections_save == 0 ? (
                          <li>
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ color: "var(--primary_text_color)" }}
                            >
                              <p>Connections</p>
                              <p>{singlePackage.limit_connections_save}</p>
                            </div>
                          </li>
                        ) : (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Connections</p>
                              <p>{singlePackage.limit_connections_save}</p>
                            </div>
                          </li>
                        )}

                        {/* Payments */}
                        {singlePackage.limit_payment_create == 0 ? (
                          <li>
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ color: "var(--primary_text_color)" }}
                            >
                              <p>Payments cards</p>
                              <p>{singlePackage.limit_payment_create}</p>
                            </div>
                          </li>
                        ) : (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Payments cards</p>
                              <p>{singlePackage.limit_payment_create}</p>
                            </div>
                          </li>
                        )}

                        {/* Emergency Contact */}
                        {singlePackage.limit_emergency_contact_create == 0 ? (
                          <li>
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ color: "var(--primary_text_color)" }}
                            >
                              <p>Emergency Contacts</p>
                              <p>
                                {singlePackage.limit_emergency_contact_create}
                              </p>
                            </div>
                          </li>
                        ) : (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Emergency Contacts</p>
                              <p>
                                {singlePackage.limit_emergency_contact_create}
                              </p>
                            </div>
                          </li>
                        )}

                        {/* Custom Url */}
                        {singlePackage.limit_custom_url_create == 0 ? (
                          <li>
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ color: "var(--primary_text_color)" }}
                            >
                              <p>Custom URL</p>
                              <p>{singlePackage.limit_custom_url_create}</p>
                            </div>
                          </li>
                        ) : (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Custom URL</p>
                              <p>{singlePackage.limit_custom_url_create}</p>
                            </div>
                          </li>
                        )}

                        {/* File Upload Url */}
                        {singlePackage.limit_file_upload_create == 0 ? (
                          <li>
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ color: "var(--primary_text_color)" }}
                            >
                              <p>File Upload URL</p>
                              <p>{singlePackage.limit_file_upload_create}</p>
                            </div>
                          </li>
                        ) : (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>File Upload URL</p>
                              <p>{singlePackage.limit_file_upload_create}</p>
                            </div>
                          </li>
                        )}

                        {/* Memory */}
                        {singlePackage.limit_memory == 0 ? (
                          <li>
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ color: "var(--primary_text_color)" }}
                            >
                              <p>Memory</p>
                              <p>{singlePackage.limit_memory}</p>
                            </div>
                          </li>
                        ) : (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Memory</p>
                              <p>{singlePackage.limit_memory}</p>
                            </div>
                          </li>
                        )}

                        {/* Form Builder */}
                        {singlePackage.limit_form_builder == 0 ? (
                          <li>
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ color: "var(--primary_text_color)" }}
                            >
                              <p>Lead Builder</p>
                              <p>{singlePackage.limit_form_builder}</p>
                            </div>
                          </li>
                        ) : (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Lead Builder</p>
                              <p>{singlePackage.limit_form_builder}</p>
                            </div>
                          </li>
                        )}

                        {singlePackage.limit_medical_id == 0 ? (
                          <li>
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ color: "var(--primary_text_color)" }}
                            >
                              <p>Medical ID</p>
                              <p>{singlePackage.limit_medical_id}</p>
                            </div>
                          </li>
                        ) : singlePackage.id === 1 ? (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Medical ID</p>
                              <p></p>
                            </div>
                          </li>
                        ) : (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Medical ID</p>
                              <p>{singlePackage.limit_medical_id}</p>
                            </div>
                          </li>
                        )}

                        {singlePackage.limit_dynamic_qr_code == 0 ? (
                          <li>
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ color: "var(--primary_text_color)" }}
                            >
                              <p>Dynamic QR Code</p>
                              <p>{singlePackage.limit_dynamic_qr_code}</p>
                            </div>
                          </li>
                        ) : singlePackage.id === 1 ? (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Dynamic QR Code</p>
                              <p></p>
                            </div>
                          </li>
                        ) : (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Dynamic QR Code</p>
                              <p>{singlePackage.limit_dynamic_qr_code}</p>
                            </div>
                          </li>
                        )}

                        {singlePackage.limit_dynamic_qr_code == 0 ? (
                          <li>
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ color: "var(--primary_text_color)" }}
                            >
                              <p>Dynamic QR Code Function Limit</p>
                              <p>{singlePackage.limit_dynamic_qr_code}</p>
                            </div>
                          </li>
                        ) : singlePackage.id === 1 ? (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Dynamic QR Code Function Limit</p>
                              <p></p>
                            </div>
                          </li>
                        ) : (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Dynamic QR Code Function Limit</p>
                              <p>{singlePackage.limit_dynamic_qr_code}</p>
                            </div>
                          </li>
                        )}

                        {/*Custom Button */}
                        {singlePackage.enable_custom_buttons == 0 ? (
                          <li>
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ color: "var(--primary_text_color)" }}
                            >
                              <p>Custom Button</p>
                              <p>
                                {singlePackage.enable_custom_buttons === 0
                                  ? "False"
                                  : "True"}
                              </p>
                            </div>
                          </li>
                        ) : (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Custom Button</p>
                              <p>
                                {singlePackage.enable_custom_buttons
                                  ? "True"
                                  : "False"}
                              </p>
                            </div>
                          </li>
                        )}

                        {/*Email Notification Button */}
                        {/* {singlePackage.enable_email_notifications == 0 ? (
                      <li>
                        <div
                          className="d-flex align-items-center justify-content-between"
                          style={{ color: "var(--primary_text_color)" }}
                        >
                          <p>Email Notification</p>
                          <p>
                            {singlePackage.enable_email_notifications
                              ? "True"
                              : "False"}
                          </p>
                        </div>
                      </li>
                    ) : (
                      <li>
                        <div className="d-flex align-items-center justify-content-between">
                          <p>Email Notification</p>
                          <p>
                            {singlePackage.enable_email_notifications
                              ? "True"
                              : "False"}
                          </p>
                        </div>
                      </li>
                    )} */}
                        {/*Maximum User Limit */}
                        {singlePackage.user_limit > 0 && (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Maximum User Limit</p>
                              <p>{singlePackage.user_limit}</p>
                            </div>
                          </li>
                        )}
                        {singlePackage.price_per_user_after_limit == 0 ? (
                          <li>
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ color: "var(--primary_text_color)" }}
                            >
                              <p>Pay Per Person</p>
                              <p>{singlePackage.price_per_user_after_limit}</p>
                            </div>
                          </li>
                        ) : (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Pay Per Person</p>
                              <p>{singlePackage.price_per_user_after_limit}</p>
                            </div>
                          </li>
                        )}
                      </ul>
                      {singlePackage.id == 5 && (
                        <>
                          <div
                            className={`${classes.couponWrapper} couponWrapper`}
                          >
                            <div className="form-check text-start">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id={`couponCheckbox-${singlePackage.id}`}
                                onChange={() =>
                                  handleCheckboxChange(singlePackage.id)
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`couponCheckbox-${singlePackage.id}`}
                              >
                                Do you have a coupon?
                              </label>
                            </div>
                            {showCouponInputs[singlePackage.id] && (
                              <div className="input-group mt-3">
                                <input
                                  type="text"
                                  className="form-control coupon-form-control"
                                  placeholder="Enter Coupon code"
                                  onChange={handleCouponInputChange}
                                />
                                <button
                                  className="btn btn-outline-secondary"
                                  type="button"
                                  onClick={() => applyCoupon("monthly", "5")}
                                >
                                  {/* <img src={CouponBtn} alt="" /> */}
                                  <MdSend />
                                </button>

                                {blankCouponField && (
                                  <div className="failurText">
                                    Please enter coupon code
                                  </div>
                                )}
                                {correctCouponEntered && (
                                  <div className="successText">
                                    Yes, coupon successfully applied
                                  </div>
                                )}
                                {incorrectCouponEntered && (
                                  <div className="failurText">
                                    Sorry, coupon code invalid
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </>
                      )}
                      {singlePackage.id == 6 ? (
                        <div className={classes.btnWrapper}>
                          <Link to={`/PackageContactForm/${singlePackage.id}`}>
                            <button className={classes.btn}>Contact Us</button>
                          </Link>
                        </div>
                      ) : ownedpackageId == singlePackage.id &&
                        checkExpire() ? (
                        <>
                          <div className={classes.btnWrapper}>
                            <button className={classes.btn} disabled>
                              Subscribed{" "}
                              <div
                                style={{
                                  display: "inline",
                                  // backgroundColor: "white",
                                }}
                              >
                                <AiFillCheckCircle />
                              </div>
                            </button>
                            {singlePackage.id === 1 ? (
                              ""
                            ) : (
                              <button
                                className={`${classes.outlineBtn} mt-4`}
                                onClick={handleCancelSubscription}
                              >
                                Cancel Subscription
                              </button>
                            )}
                          </div>
                        </>
                      ) : (
                        <>
                          <div className={classes.btnWrapper}>
                            <button
                              className={classes.btn}
                              onClick={() => handlePayment(singlePackage)}
                            >
                              Select Package
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  ))
              : null}
          </div>
        </div>
      </div>
      {showModal && (
        <SubscriptionModal
          show={showModal}
          stripePromise={stripePromise}
          handleCloseModal={handleCloseModal}
          selectedPackage={selectedPackage}
          elementsOptions={elementsOptions}
          couponData={couponData}
        />
      )}
      <Modal
        show={showCancelModal}
        onHide={handleCloseCancelModal}
        aria-labelledby="contained-modal-title-vcenter"
        className="customModal"
        size="sm"
      >
        <Modal.Header>
          <div className="modalGradientCircle"></div>
          <MdClose onClick={handleCloseCancelModal} className="modalCloseBtn" />
        </Modal.Header>

        <Modal.Body
          style={{ padding: "0" }}
          className={`d-flex justify-content-center flex-column align-items-center`}
        >
          <div
            className="d-flex flex-column align-items-center justify-content-center"
            style={{ width: "fit-content", lineHeight: "18px" }}
          >
            <h5>Are you sure?</h5>
            <p
              style={{
                lineHeight: "18px",
                textAlign: "center",
                padding: "0px 20px",
              }}
            >
              Are you sure you want to cancel your Subscription
            </p>
          </div>

          <div className={`customModalBtnWrapper ${classes.btnWrapper}`}>
            <button
              onClick={handleConfirmDelete}
              style={{
                background: "#E35252",
                boxShadow: "0px 6px 20px rgba(227, 82, 82, 0.4)",
              }}
            >
              Delete
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <style type="text/css">
        {`
        .noteText{
          color: var(--error_color); 
          font-size: 12px; 
          text-align: left;
          margin-bottom:0;
        }
       .cardsWrapper{
        display:flex;
        flex-wrap:wrap;
        gap:0;
       }
       .card{
        width:32.3333%;
        margin-bottom: 15px;
        margin-right:1%;
       }
       .imgWrapper{
        margin-bottom:0;
       }
       .subscriptionType{
        width:fit-content;
        margin: 0 auto;
       }
       .couponWrapper {
        background-color: var(--primary_color);
        border-radius: 10px;
        padding: 15px 25px;
        margin: 30px 10px 0 !important;
        color:var(--heading_text_color);
      }
      .form-check-label {
        font-size: 13px;
        font-weight: 500;
            color:var(--heading_text_color);
      }
      .form-check-input, .form-check-input:focus{
        //  background: var(--primary_color);
         border: 1px solid var(--border_color);
        outline:none
       }
      .coupon-form-control, .coupon-form-control:focus{
        background-color:transparent;
        color:var(--heading_text_color);
        font-size:13px;
        font-weight:700;
        border-radius:5rem;
        box-shadow:none;
      }
      .coupon-form-control::placeholder{
        color:var(--heading_text_color);
      }
      .btn-outline-secondary, .btn-outline-secondary:focus{
        border-radius:5rem;
        border-top-right-radius: 5rem !important;
        border-bottom-right-radius: 5rem !important;
        background: var(--accent_color) !important;
        color:var(--heading_text_color);
      }
      .failurText, .successText{
        text-align:left;
        font-size:13px;
        color:#ff0000;
        display: block;
        width: 100%;
      }
      .successText{
        color: #42764e;
      }
      @media only screen and (max-width: 1399.98px) {
        .form-check-label {
          font-size: 12px;
            color:var(--heading_text_color);
        }
      }
     `}
      </style>
    </>
  );
};

export default Subscription;
