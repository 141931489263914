import React, { useMemo, useState } from "react";
import FilterIcon from "../assets/images/contact_page_icons/filtericon.svg";
import { CONTACT, EXPORT_CONTACT } from "../utils/constants/apiUrl";
import classes from "../assets/styles/Contact.module.css";
import GridView from "../components/PageComponents/Contact/GridView";
import ListView from "../components/PageComponents/Contact/ListView";
import { BsGrid } from "react-icons/bs";
import { TbLayoutList } from "react-icons/tb";
import { useEffect } from "react";
import { get, deleteRecord, post } from "../server";
import { loggedInUser, teamIDCheck } from "../utils/store";
import { useAtom } from "jotai";
import Loader from "../components/Loader";
import ConfirmationModal from "../components/Modals/ConfirmationModal";
import showToast from "../helpers/showToast";
import FormInput from "../components/Forms/FormInput";
import Pagination from "react-bootstrap/Pagination";
import searchIcon from "../assets/images/contact_page_icons/searchicon.svg";
import { useNavigate } from "react-router-dom";
import useFunctionsCount from "../hooks/useFunctionsCount";
import functionTypes from "../utils/constants/functionTypes";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { saveAs } from "file-saver";

const Contact = () => {
  const [loggedUser] = useAtom(loggedInUser);
  const [userTeamId, setUserTeamId] = useAtom(teamIDCheck);
  const [gridView, setGridView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { compareLimits, updateCount } = useFunctionsCount();
  const navigate = useNavigate();
  const [pageNo, setPageNo] = useState(1);
  const [perPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const [filter, setFilter] = useState("");

  const [search, setSearch] = useState("");
  const [textSearch, setTextSearch] = useState("");
  const [activeTab, setActiveTab] = useState("card");

  const handleTabChange = (selectedTab) => {
    setActiveTab(selectedTab);
    loadContact(selectedTab);
  };

  const handleNavigation = () => {
    const res = compareLimits(functionTypes.CONNECTION);
    if (res)
      return showToast(
        false,
        "The requested feature is not compatible with your current plan. Upgrade your plan to continue using these features"
      );
    navigate("/contacts/createManual");
  };

  const handleExportAllCollection = async () => {
    setLoading(true);
    let payload = {
      tab_type: activeTab,
      team_id: userTeamId,
    };
    let { status, message, data } = await post(
      // id ? EXPORT_CONTACT + queryString : EXPORT_CONTACT,
      EXPORT_CONTACT,
      payload,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      const filePath = data.path;
      saveAs(filePath, "leads.xlsx");
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  const handleExportCollection = async (id) => {
    setLoading(true);
    // const queryString = `?tab_type=${activeTab}&team_id=${userTeamId}`;
    let payload = {
      id: id,
      tab_type: activeTab,
      team_id: userTeamId,
    };

    if (id !== 0) {
      payload.id = id;
    }

    let { status, message, data } = await post(
      // id ? EXPORT_CONTACT + queryString : EXPORT_CONTACT,
      EXPORT_CONTACT,
      payload,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      const filePath = data.path;
      saveAs(filePath, "leads.xlsx");
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleDelete = (id) => {
    setDeleteId(id);
    handleShowModal(true);
  };

  const handleConfirmDelete = async () => {
    handleCloseModal(true);
    setLoading(true);
    let { status, message } = await deleteRecord(
      `${CONTACT}/${deleteId}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    showToast(status, message);

    if (status) {
      // contacts.filter((contact) => contact.contact_id !== deleteId);
      await loadContact();
      updateCount(functionTypes.CONNECTION, functionTypes.DELETE);
      setLoading(false);
    }
    setLoading(false);
  };

  const handleGridView = () => {
    setGridView(true);
  };

  const handleListView = () => {
    setGridView(false);
  };

  const handlePageClick = (event) => {
    setPageNo(Number(event.target.innerText));
  };

  const contactURL = useMemo(() => {
    let queryParams = "";
    if (userTeamId) {
      queryParams = `?team_id=${userTeamId}`;
    }

    let url = new URL(`${CONTACT}${queryParams}`);
    url.searchParams.append("tab_type", activeTab);
    url.searchParams.append("pagination", true);
    url.searchParams.append("page", pageNo);
    url.searchParams.append("per_page", perPage);
    url.searchParams.append("filter", filter);
    url.searchParams.append("search", search);

    return url.toString();
  }, [activeTab, pageNo, search, filter]);

  const getContactUrl = (selectedTab) => {
    let queryParams = "";
    if (userTeamId) {
      queryParams = `?team_id=${userTeamId}`;
    }

    let url = new URL(`${CONTACT}${queryParams}`);
    url.searchParams.append("tab_type", selectedTab);
    url.searchParams.append("pagination", true);
    url.searchParams.append("page", pageNo);
    url.searchParams.append("per_page", perPage);
    url.searchParams.append("filter", filter);
    url.searchParams.append("search", search);

    return url.toString();
  };

  const loadContact = async (selectedTab) => {
    setLoading(true);

    let { status, message, data } = await get(
      getContactUrl(selectedTab),
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    setTotalRecords(data?.pagination?.total);
    setTotalPages(Math.ceil(data?.pagination?.total / perPage));

    if (status) {
      if (data.length == 0) {
        setContacts([]);
      } else if (data.data.length > 0) {
        let contactCards = [];
        data.data.forEach((contact) => {
          contact.contactCard.card_social_media =
            contact.contactCard.contact_card_social_media;
          contact.contactCard.uuid = contact.uuid;
          contact.contactCard.is_manual = contact.is_manual;
          delete contact.contactCard.contact_card_social_media;
          contactCards.push(contact.contactCard);
        });
        setContacts(contactCards);
      } else {
        setContacts([]);
      }
    } else {
      showToast(status, message);
    }

    setLoading(false);
  };

  useEffect(() => {
    loadContact("card");
  }, []);

  const returnPages = () => {
    let items = [];

    for (let number = 1; number <= totalPages; number++) {
      items.push(
        <Pagination.Item key={number} active={number === pageNo}>
          {number}
        </Pagination.Item>
      );
    }

    return items;
  };

  return (
    <>
      {!loading ? (
        <div className="">
          <div className={`${classes.mobileViewWrapper}`}>
            <p className={classes.title}>
              Connections <span>({totalRecords})</span>
            </p>
            <div>
              {activeTab === "card" && (
                <button className="primary_button" onClick={handleNavigation}>
                  Add
                </button>
              )}
              {(activeTab === "card" || activeTab === "lead") && (
                <button
                  className="primary_button ms-3"
                  onClick={handleExportAllCollection}
                >
                  Export Collection
                </button>
              )}
            </div>
            <div className={classes.iconsWrapper}>
              <div className="dropdown">
                <button
                  className="customActionDropdown dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img src={FilterIcon} alt="" width="16px" height="16px" />
                </button>
                <ul className="customActionDropdownMenu dropdown-menu dropdown-menu-start ">
                  <li className="dropdown-item">
                    <span
                      onClick={() => setFilter("newest")}
                      style={{
                        color:
                          filter === "" || filter === "newest"
                            ? "var(--primary_button_color)"
                            : "var(--primary_text_color)",
                      }}
                    >
                      Newest to oldest
                    </span>
                  </li>
                  <li className="dropdown-item">
                    <span
                      onClick={() => setFilter("oldest")}
                      style={{
                        color:
                          filter === "oldest"
                            ? "var(--primary_button_color)"
                            : "var(--primary_text_color)",
                      }}
                    >
                      Oldest to newest
                    </span>
                  </li>
                  <li className="dropdown-item">
                    <span
                      style={{ color: "#E35252" }}
                      onClick={() => setFilter("")}
                    >
                      Clear
                    </span>
                  </li>
                </ul>
              </div>
              <div className={gridView ? classes.activeGrid : classes.grid}>
                <button onClick={handleGridView} style={{ padding: "0" }}>
                  <BsGrid className={classes.gridIcon} />
                </button>
              </div>
              <div className={!gridView ? classes.activeGrid : classes.grid}>
                <button
                  onClick={handleListView}
                  style={{ padding: "0", marginLeft: "4px" }}
                >
                  <TbLayoutList className={classes.gridIcon} />
                </button>
              </div>
            </div>
          </div>
          <div className={classes.mainVeiwsWrapper}>
            <div className={classes.veiwsWrapper}>
              <div className={classes.grid}>
                <div className="dropdown">
                  <button
                    className="btn filterDropdown dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img src={FilterIcon} alt="" width="16px" height="16px" />
                    <span> Filter</span>
                  </button>
                  <ul className="customActionDropdownMenu dropdown-menu dropdown-menu-start">
                    <li className="dropdown-item">
                      <span
                        onClick={() => setFilter("newest")}
                        style={{
                          color:
                            filter === "" || filter === "newest"
                              ? "var(--primary_button_color)"
                              : "var(--primary_text_color)",
                        }}
                      >
                        Newest to oldest
                      </span>
                    </li>
                    <li className="dropdown-item">
                      <span
                        onClick={() => setFilter("oldest")}
                        style={{
                          color:
                            filter === "oldest"
                              ? "var(--primary_button_color)"
                              : "var(--primary_text_color)",
                        }}
                      >
                        Oldest to newest
                      </span>
                    </li>
                    <li className="dropdown-item">
                      <span
                        style={{ color: "#E35252" }}
                        onClick={() => setFilter("")}
                      >
                        Clear
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className={gridView ? classes.activeGrid : classes.grid}>
                <button onClick={handleGridView}>
                  <BsGrid className={classes.gridIcon} />
                  <span> Grid view</span>
                </button>
              </div>
              <div className={!gridView ? classes.activeGrid : classes.grid}>
                <button onClick={handleListView}>
                  <TbLayoutList className={classes.gridIcon} />
                  <span> List view</span>
                </button>
              </div>
            </div>

            <form
              // style={{ marginBottom: "-20px" }}
              className={classes.form}
              onSubmit={(e) => {
                e.preventDefault();
                setSearch(textSearch);
              }}
            >
              <FormInput
                icon={searchIcon}
                placeholder="Search..."
                value={textSearch}
                handleChange={(e) => {
                  if (e.target.value.length === 0 && search.length !== 0) {
                    setSearch("");
                  }
                  setTextSearch(e.target.value);
                }}
                // iconOnClick={() => setSearch(textSearch)}
              />
            </form>
          </div>

          <div className={classes.listWrapper}>
            <Tabs
              defaultActiveKey="card"
              activeKey={activeTab}
              className="customSubscriptionTabs"
              transition={false}
              onSelect={handleTabChange}
              justify
            >
              <Tab eventKey="card" title="Connections">
                {gridView ? (
                  <GridView
                    contacts={contacts}
                    handleDelete={handleDelete}
                    handleExportCollection={handleExportCollection}
                  />
                ) : (
                  <ListView
                    contacts={contacts}
                    handleDelete={handleDelete}
                    handleExportCollection={handleExportCollection}
                  />
                )}

                <div className={classes.paginationWrapper}>
                  <Pagination onClick={handlePageClick}>
                    {returnPages()}
                  </Pagination>
                </div>
              </Tab>
              <Tab eventKey="lead" title="Lead">
                {gridView ? (
                  <GridView
                    contacts={contacts}
                    handleDelete={handleDelete}
                    handleExportCollection={handleExportCollection}
                  />
                ) : (
                  <ListView
                    contacts={contacts}
                    handleDelete={handleDelete}
                    handleExportCollection={handleExportCollection}
                  />
                )}

                <div className={classes.paginationWrapper}>
                  <Pagination onClick={handlePageClick}>
                    {returnPages()}
                  </Pagination>
                </div>
              </Tab>
              <Tab eventKey="custom_url" title="Custom URL's">
                {gridView ? (
                  <GridView contacts={contacts} handleDelete={handleDelete} />
                ) : (
                  <ListView contacts={contacts} handleDelete={handleDelete} />
                )}

                <div className={classes.paginationWrapper}>
                  <Pagination onClick={handlePageClick}>
                    {returnPages()}
                  </Pagination>
                </div>
              </Tab>
              <Tab eventKey="file_upload" title="File Upload URL">
                {gridView ? (
                  <GridView contacts={contacts} handleDelete={handleDelete} />
                ) : (
                  <ListView contacts={contacts} handleDelete={handleDelete} />
                )}

                <div className={classes.paginationWrapper}>
                  <Pagination onClick={handlePageClick}>
                    {returnPages()}
                  </Pagination>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      ) : (
        <Loader />
      )}

      <ConfirmationModal
        show={showModal}
        handleCloseModal={handleCloseModal}
        handleConfirmDelete={handleConfirmDelete}
        confirmTitle="contact"
      />
      <style type="text/css">
        {`
          .customSubscriptionTabs.nav-tabs{
            padding:20px;
          }
          .customSubscriptionTabs button{
            background: var(--accent_color) !important;
            color: var(--heading_text_color) !important;
          }
        `}
      </style>
    </>
  );
};

export default Contact;
