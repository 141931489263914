import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import classes from "../../assets/styles/CustomURLModal.module.css";
import { MdClose } from "react-icons/md";
const MemberSuspendModal = ({
  data,
  setSelectedData,
  handleSaveShowModal,
  handleCloseModal,
  show,
}) => {
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setSelectedData({ ...data, [name]: value });
  };

  const handleAddUrl = async (e) => {
    handleSaveShowModal(data);
  };

  // Handle Update
  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          handleCloseModal();
        }}
        className={`customModal`}
        size="sm"
      >
        <Modal.Header className={classes.modalHeader}>
          <span className={classes.modalHeading}>Change Status</span>
          <div className="modalGradientCircle"></div>
          <MdClose
            onClick={() => {
              handleCloseModal();
            }}
            className="modalCloseBtn"
          />
        </Modal.Header>

        <Modal.Body>
          <div style={{ marginTop: "-140px" }}>
            <div>
              <label
                htmlFor=""
                className={classes.label}
                style={{ fontSize: "12px" }}
              >
                Status
              </label>
              <div className="input-group globalInputWithIcon">
                <select
                  style={{ border: "0" }}
                  className="form-select customSelect"
                  name="status"
                  value={data.status}
                  onChange={handleChange}
                >
                  <option value="active">Active</option>
                  <option value="suspended">Suspend</option>
                  {/* <option value="disabled">Disabled</option>
                  <option value="blocked">Blocked</option>
                  <option value="inactive">Inactive</option> */}
                </select>
              </div>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className={classes.btnWrapper}>
            <Button
              type="button"
              onClick={() => {
                handleCloseModal();
              }}
              className={classes.cancelBtn}
            >
              Cancel
            </Button>

            <Button
              type="button"
              onClick={handleAddUrl}
              className={classes.saveBtn}
            >
              Save
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default MemberSuspendModal;
