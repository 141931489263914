import React, { useState, useEffect, useMemo } from "react";
import { useAtom } from "jotai";
import { loggedInUser } from "../../../utils/store";
import { get, post } from "../../../server";
import {
  NOTIFICATION_LIST,
  DELETE_NOTIFICATION,
} from "../../../utils/constants/apiUrl";
import showToast from "../../../helpers/showToast";
import DataTable, { createTheme } from "react-data-table-component";
import { BsThreeDotsVertical } from "react-icons/bs";
import classes from "../../../assets/styles/Pages/Settings/AddNewPage.module.css";
import { Link } from "react-router-dom";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal";
import FormInput from "../../../components/Forms/FormInput";
import searchIcon from "../../../assets/images/global_icons/searchicon.svg";
import Loader from "../../../components/Loader";

const NotificationListing = () => {
  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState([]);
  const [loggedUser] = useAtom(loggedInUser);
  const [filterText, setFilterText] = useState("");
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const handleFilter = (e) => {
    setFilterText(e.target.value);
  };
  const filteredItems = useMemo(() => {
    const filtered = notifications.filter(
      (item) =>
        `${item.name || ""}`.toLowerCase().includes(filterText.toLowerCase()) ||
        `${item.coupon || ""}`
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
        `${item.discount || ""}`.includes(filterText)
    );

    return filtered;
  }, [filterText, notifications]);

  const loadNotification = async () => {
    let { status, data, message } = await get(
      NOTIFICATION_LIST,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      setNotifications(data);
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  createTheme(
    "solarized",
    {
      background: {
        default: "background: var(--secondary_color)",
      },
    },
    "dark"
  );

  const columns = [
    {
      name: "Title",
      width: "20%",
      cell: (row) => <p className="globalDetail">{row.title}</p>,
    },
    {
      name: "Description",
      width: "50%",
      cell: (row) => <p className="globalDetail">{row.description}</p>,
    },
    {
      name: "Created At",
      // cell: (row) => {
      //   const creationDate = new Date(row.created_at);
      //   const formattedDate = creationDate.toLocaleString();
      //   return <p className="globalDetail">{formattedDate}</p>;
      // },
      cell: (row) => {
        const creationDate = new Date(row.created_at);
        const formattedDate = creationDate.toLocaleDateString("en-US", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        });
        return <p className="globalDetail">{formattedDate}</p>;
      },
    },
    {
      name: "Action",
      right: "true",
      cell: (row) => (
        <div className={classes.action}>
          <button
            className="btn customActionDropdown dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <BsThreeDotsVertical className={"globalVerticalThreeDots"} />
          </button>
          <ul className="customActionDropdownMenu dropdown-menu dropdown-menu-end ">
            <li>
              <Link
                className="dropdown-item"
                to="#"
                style={{ color: "#E35252" }}
                onClick={() => handleDelete(row.id)}
              >
                Delete
              </Link>
            </li>
          </ul>
        </div>
      ),
    },
  ];

  // Handle confirmation modal
  const handleShowConfirmationModal = () => setShowConfirmationModal(true);
  const handleCloseConfirmationModal = () => setShowConfirmationModal(false);

  // handle delete
  const handleDelete = (notificationId) => {
    setDeleteId(notificationId);
    handleShowConfirmationModal(true);
  };

  const handleConfirmDelete = async () => {
    handleCloseConfirmationModal(true);
    setLoading(true);
    let payload;
    if (deleteId === null) {
      payload = {
        delete_all: true,
        notification_id: null,
      };
    } else {
      payload = {
        delete_all: false,
        notification_id: deleteId,
      };
    }
    let { status, message } = await post(
      DELETE_NOTIFICATION,
      payload,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      setNotifications([]);
      await loadNotification();
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadNotification();
  }, []);
  return (
    <>
      {!loading ? (
        <div>
          <div className="page_Header">
            <div className="d-flex justify-content-between align-items-md-center align-items-initial flex-md-row flex-column gap-3">
              <p className="pageTitle">
                All Notification <span>({notifications.length})</span>
              </p>
              <div className="d-flex align-items-center justify-content-between gap-2">
                <FormInput
                  icon={searchIcon}
                  placeholder="Search..."
                  value={filterText}
                  handleChange={handleFilter}
                />
                <Link to="/sendNotification">
                  <button className="primary_button">Add</button>
                </Link>
                {notifications.length > 1 ? (
                  <Link onClick={() => handleDelete(null)}>
                    <button className="primary_button">Delete All</button>
                  </Link>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className={`listingWrapper mainWrapper`}>
            <DataTable
              columns={columns}
              data={filteredItems}
              pagination
              theme="solarized"
            />
          </div>

          <ConfirmationModal
            show={showConfirmationModal}
            handleCloseModal={handleCloseConfirmationModal}
            handleConfirmDelete={handleConfirmDelete}
            confirmTitle="notification"
          />
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};
export default NotificationListing;
