import React from "react";
import classes from "../../assets/styles/Pages/Help/Help.module.css";
import { FaHandsHelping } from "react-icons/fa";
import { BiPhone } from "react-icons/bi";
import { AiFillMessage } from "react-icons/ai";
import { BsPlayCircle } from "react-icons/bs";
import { ReactComponent as AboutIcon } from "../../assets/images/icon-white.svg";
import { IoDocumentTextSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
import { BsChevronRight } from "react-icons/bs";
import helpimage from "../../assets/images/help/help_home.png";
const Help = () => {
  return (
    <>
      <div className="">
        <h6 className="helpPageName">Help</h6>
        <div className={classes.helpHeader}>
          <div className="row flex-md-row flex-column-reverse gy-4">
            {/* Help top left section */}
            <div className="col-sm-6">
              <h5 className="helpHeading">Help Center</h5>
              <p className="helpSubHeading">How can we help you?</p>

              <p className="helpDetail">
                Explore our Help section for additional information, tutorials,
                FAQs, and policies.
              </p>

              <div className={classes.btnsWrapper}>
                <Link to="/help/termsandcondition">
                  <button>Terms & Condition</button>
                </Link>
                <Link to="/help/helpContactUs">
                  <button>Contact Us</button>
                </Link>
              </div>
            </div>

            {/*Right Section Image */}
            <div className="col-sm-6">
              <img src={helpimage} alt="" className="helpImage" />
            </div>
          </div>
        </div>

        {/* Second Section */}

        <div className={classes.helpbottom}>
          <div className="row gy-4 flex-xl-row flex-column">
            {/* Help top left section */}
            <div className="col-xl-6 col-12">
              <div className={classes.cardsWrapper}>
                <div className={classes.card}>
                  <Link to="/help/aboutus">
                    <div className={classes.contentWrapper}>
                      <AboutIcon
                        className={`${classes.mainIcon} ${classes.aboutIcon}`}
                      />
                      <h6>About Us</h6>

                      <div className={classes.arrowIconWrraper}>
                        <p>About Us</p>
                        <BsChevronRight />
                      </div>
                    </div>
                  </Link>
                </div>
                <div className={classes.card}>
                  <Link to="/help/helpContactUs">
                    <div className={classes.contentWrapper}>
                      <BiPhone className={classes.mainIcon} />
                      <h6>Contact Us</h6>

                      <div className={classes.arrowIconWrraper}>
                        <p>Contact Us</p>
                        <BsChevronRight />
                      </div>
                    </div>
                  </Link>
                </div>
                <div className={classes.card}>
                  <Link to="/help/helpTutorial">
                    <div className={classes.contentWrapper}>
                      <BsPlayCircle className={classes.mainIcon} />
                      <h6>Tutorials</h6>

                      <div className={classes.arrowIconWrraper}>
                        <p>Tutorials</p>
                        <BsChevronRight />
                      </div>
                    </div>
                  </Link>
                </div>
                <div className={classes.card}>
                  <Link to="/help/helpFaqs">
                    <div className={classes.contentWrapper}>
                      <AiFillMessage className={classes.mainIcon} />
                      <h6>FAQ's</h6>

                      <div className={classes.arrowIconWrraper}>
                        <p>Frequently Asked Questions</p>
                        <BsChevronRight />
                      </div>
                    </div>
                  </Link>
                </div>
                <div className={classes.card}>
                  <Link to="/help/termsandcondition">
                    <div className={classes.contentWrapper}>
                      <FaHandsHelping className={classes.mainIcon} />
                      <h6>Terms & Conditions</h6>

                      <div className={classes.arrowIconWrraper}>
                        <p>Terms & Condition</p>
                        <BsChevronRight />
                      </div>
                    </div>
                  </Link>
                </div>
                <div className={classes.card}>
                  <Link to="/help/privacyandpolicy">
                    <div className={classes.contentWrapper}>
                      <IoDocumentTextSharp className={classes.mainIcon} />
                      <h6>Privacy & Policy</h6>

                      <div className={classes.arrowIconWrraper}>
                        <p>Privacy & Policy</p>
                        <BsChevronRight />
                      </div>
                    </div>
                  </Link>
                </div>
                <div className={classes.card}>
                  <Link to="/help/exchangepolicy">
                    <div className={classes.contentWrapper}>
                      <IoDocumentTextSharp className={classes.mainIcon} />
                      <h6>Exchange Policy</h6>

                      <div className={classes.arrowIconWrraper}>
                        <p>Exchange Policy</p>
                        <BsChevronRight />
                      </div>
                    </div>
                  </Link>
                </div>
                <div className={classes.card}>
                  <Link to="/help/intellectualproperty">
                    <div className={classes.contentWrapper}>
                      <IoDocumentTextSharp className={classes.mainIcon} />
                      <h6>Intellectual Property</h6>

                      <div className={classes.arrowIconWrraper}>
                        <p>Intellectual Property</p>
                        <BsChevronRight />
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>

            {/*Right Section Image */}
            {/* <div className="col-xl-6 col-12">
              <div className={classes.helpDetail}>
                <h3 className="helpHeading">How can we help?</h3>
                <p className="helpDetail">
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit. Et
                  exercitationem quam repudiandae necessitatibus Lorem, ipsum
                  dolor sit amet consectetur adipisicing elit. Et exercitationem
                  quam repudiandae necessitatibus
                </p>

                <ul style={{ padding: "revert", listStyle: "initial" }}>
                  <li className="helpDetail">Lorem ipsum dolor sit amet.</li>
                  <li className="helpDetail">Lorem ipsum dolor sit amet.</li>
                  <li className="helpDetail">Lorem ipsum dolor sit amet.</li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Help;
