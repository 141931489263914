import React from "react";
import classes from "../../assets/styles/PriorityModal.module.css";
import { Modal } from "react-bootstrap";
import crossicon from "../../assets/images/global_icons/crossicon.svg";
import FormInput from "../../components/Forms/FormInput";
const PriorityListingModal = ({
  show,
  sortedFunctionData,
  handlePriorityChange,
  handleSave,
  handleClose,
}) => {
  return (
    <>
      <Modal
        centered
        className={`customModal priorityModal`}
        size="md"
        show={show}
      >
        <Modal.Header className={classes.priorityModalHeader}>
          <span>Function Priority List</span>
          <img
            src={crossicon}
            alt=""
            style={{ cursor: "pointer" }}
            onClick={handleClose}
          />
        </Modal.Header>

        <Modal.Body className={classes.priorityModalBody}>
          <div className={`${classes.imageUpload}`}>
            {sortedFunctionData.map((functionItem, index) => (
              <div
                className="row function-row align-items-center mb-3"
                key={index}
              >
                <div className="col-lg-2 d-none">
                  <FormInput
                    type="text"
                    name="function_type"
                    label="Function Name"
                    value={functionItem.function_type}
                    disabled
                  />
                </div>
                <div className="col-lg-9">
                  <FormInput
                    type="text"
                    name="priority"
                    label={`Enter Priority Number for ${functionItem.title}`}
                    value={
                      functionItem.priority === 0 ? "" : functionItem.priority
                    }
                    handleChange={(e) =>
                      handlePriorityChange(index, e.target.value)
                    }
                  />
                </div>
                <div className="col-lg-3">
                  <div className="form-check mt-4">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={functionItem.is_visible === true}
                      id={`isVisible${index}`}
                      onChange={(e) =>
                        handlePriorityChange(
                          index,
                          e.target.checked,
                          "is_visible"
                        )
                      }
                    />
                    <label
                      className="form-check-label mb-0"
                      htmlFor={`isVisible${index}`}
                    >
                      Visible
                    </label>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="text-center mt-4">
            <button
              className={`${classes.saveBtn} mb-3 mt-0`}
              onClick={handleSave}
            >
              Save
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default PriorityListingModal;
