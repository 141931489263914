import React, { useState, useEffect, useMemo } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from "react-router-dom";
import DataTable, { createTheme } from "react-data-table-component";
import { useAtom } from "jotai";
import classes from "../../../assets/styles/Contact.module.css";
import { FORM_BUILDER } from "../../../utils/constants/apiUrl";
import { get, deleteRecord, post, put } from "../../../server";
import showToast from "../../../helpers/showToast";
import { loggedInUser, teamIDCheck } from "../../../utils/store";
import Loader from "../../../components/Loader";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal";
import FormInput from "../../../components/Forms/FormInput";
import searchIcon from "../../../assets/images/global_icons/searchicon.svg";
import MemoryAddEditModal from "../../../components/Modals/MemoryAddEditModal";
import { formatUrl } from "../../../utils/functions/index";
import _ from "lodash";

const AllFormBuilder = () => {
  let emptyMemoryData = {
    media_type: "",
    save_type: "",
    title: "",
    media: "",
    team_id: "",
  };

  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [userTeamId, setUserTeamId] = useAtom(teamIDCheck);
  const [loading, setLoading] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const [formBuilderListings, setFormBuilderListings] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState(emptyMemoryData);

  const handleFilter = (e) => {
    setFilterText(e.target.value);
  };

  // Handle main modal
  const handleShowModal = (obj) => {
    if (obj && Object.keys(obj).length) setSelectedData(obj);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedData(emptyMemoryData);
    setShowModal(false);
  };

  const getFormBuilderListing = async () => {
    const queryString = `?team_id=${userTeamId}`;
    let { status, message, data } = await get(
      userTeamId ? FORM_BUILDER + queryString : FORM_BUILDER,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      setFormBuilderListings(data);
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  useEffect(() => {
    getFormBuilderListing();
  }, []);

  const filteredItems = useMemo(() => {
    const filtered = formBuilderListings.filter((item) =>
      `${item.name || ""}`.toLowerCase().includes(filterText.toLowerCase())
    );

    return filtered;
  }, [filterText, formBuilderListings]);

  createTheme(
    "solarized",
    {
      background: {
        default: "background: var(--secondary_color)",
      },
    },
    "dark"
  );

  const columns = [
    {
      name: "Form Name",
      width: "30%",
      cell: (row) => <p className="globalTitle">{row.name}</p>,
    },
    {
      name: "Form Fields",
      width: "60%",
      cell: (row) => (
        <p
          className="globalTitle"
          style={{ maxWidth: "max-content", whiteSpace: "normal" }}
        >
          {row.formBuilderField
            .map((field) => {
              if (field.type !== "paragraph") {
                return field.label;
              } else {
                return field.type.charAt(0).toUpperCase() + field.type.slice(1);
              }
            })
            .join(", ")}
        </p>
      ),
    },
    {
      name: "Action",
      right: "true",
      cell: (row) => (
        <div className={classes.action}>
          <button
            className="btn customActionDropdown dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <BsThreeDotsVertical className={"globalVerticalThreeDots"} />
          </button>
          <ul className="customActionDropdownMenu dropdown-menu dropdown-menu-end ">
            <li>
              <Link
                className="dropdown-item"
                to={`/formBuilder/ViewFormBuilder/${row.id}`}
              >
                View
              </Link>
            </li>
            <li>
              <Link
                className="dropdown-item"
                to={`/formBuilder/AddEditFormBuilder/${row.id}`}
              >
                Edit
              </Link>
            </li>
            <li>
              <Link
                className="dropdown-item"
                to="#"
                style={{ color: "#E35252" }}
                onClick={() => handleDelete(row.id)}
              >
                Delete
              </Link>
            </li>
          </ul>
        </div>
      ),
    },
  ];

  // Handle confirmation modal
  const handleShowConfirmationModal = () => setShowConfirmationModal(true);
  const handleCloseConfirmationModal = () => setShowConfirmationModal(false);

  // handle delete
  const handleDelete = (formBuilderId) => {
    setDeleteId(formBuilderId);
    handleShowConfirmationModal(true);
  };

  const handleConfirmDelete = async () => {
    handleCloseConfirmationModal(true);
    setLoading(true);
    let { status, message } = await deleteRecord(
      `${FORM_BUILDER}/${deleteId}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      getFormBuilderListing();
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <>
      {!loading ? (
        <div className="">
          <div className="page_Header">
            {/* <Link to="AddEditAdminUser">
                <button className="primary_button align_right">Add</button>
              </Link> */}
            <div className="d-flex justify-content-between align-items-md-center align-items-initial flex-md-row flex-column gap-3">
              <p className="pageTitle">
                Lead Builder <span>({formBuilderListings.length})</span>
              </p>
              <FormInput
                icon={searchIcon}
                placeholder="Search..."
                value={filterText}
                handleChange={handleFilter}
              />
            </div>
          </div>

          <div className={`listingWrapper mainWrapper`}>
            <DataTable
              columns={columns}
              data={filteredItems}
              paginationResetDefaultPage={resetPaginationToggle}
              pagination
              theme="solarized"
            />
          </div>

          <ConfirmationModal
            show={showConfirmationModal}
            handleCloseModal={handleCloseConfirmationModal}
            handleConfirmDelete={handleConfirmDelete}
            confirmTitle="custom url"
          />
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default AllFormBuilder;
