import React, { useEffect, useState } from "react";
import classes from "../assets/styles/CardFunctions.module.css";
import cardtype from "../assets/images/function_page_icons/cardtype.svg";
import cardname from "../assets/images/function_page_icons/cardname.svg";
import pictureicon from "../assets/images/function_page_icons/pictureicon.svg";
import company from "../assets/images/function_page_icons/company.svg";
import companylogo from "../assets/images/function_page_icons/companylogo.svg";
import userdetails from "../assets/images/function_page_icons/userdetails.svg";
import contactnumber from "../assets/images/function_page_icons/contactnumber.svg";
import socialmedialinks from "../assets/images/function_page_icons/socialmedialinks.svg";
import cardsuccess from "../assets/images/function_page_icons/cardsuccess.svg";
import crossicon from "../assets/images/global_icons/crossicon.svg";
import FormInput from "../components/Forms/FormInput";
import baseURL from "../utils/constants/baseUrl";
import Loader from "../components/Loader";
import { Link } from "react-router-dom";
import { IoDocumentTextSharp } from "react-icons/io5";
import {
  FiMail,
  FiTrash2,
  FiChevronLeft,
  FiChevronRight,
} from "react-icons/fi";
import { MdLocationOn } from "react-icons/md";
import { BsGlobe, BsImageFill } from "react-icons/bs";
import { BsArrowRight } from "react-icons/bs";
import { BsArrowLeft } from "react-icons/bs";
import { BsFillPersonFill } from "react-icons/bs";
import {
  CardBackGroundImages,
  Colors,
  AddMoreCustomUrls,
} from "../utils/data/CardFunctionsData";
import PhoneInput from "react-phone-input-2";
import Modal from "react-bootstrap/Modal";
import "react-phone-input-2/lib/material.css";
import { useDropzone } from "react-dropzone";
import { SketchPicker } from "react-color";
import _ from "lodash";
import Card from "../components/PageComponents/Function/Card";
import showToast from "../helpers/showToast";
import {
  loggedInUser,
  teamIDCheck,
  userIDCheck,
  subscribedPackage,
} from "../utils/store";
import { useAtom } from "jotai";
import { CONTACT } from "../utils/constants/apiUrl";
import { post } from "../server";
import { useNavigate } from "react-router-dom";
import uploadImage from "../helpers/uploadImage";
import ValidateSchema from "../helpers/ValidateSchema";
import Joi from "joi";
import Select from "../components/Select";
import { MdClose } from "react-icons/md";
import { useMemo } from "react";
import socialMediaLinksSchema from "../utils/SchemasValidation/ModalSocialMediaLinksSchema";
import loaderClasses from "../assets/styles/Loader.module.css";
import { formatUrl, validatePhoneWithCountryCode } from "../utils/functions";
import CropImageModal from "../components/Modals/CropImageModal";
import functionTypes from "../utils/constants/functionTypes";
import useFunctionsCount from "../hooks/useFunctionsCount";

const businessHeaderValues = {
  0: "Choose your <br /> <strong>Card Type</strong>",
  1: "Enter your <br /> <strong>Full Name</strong>",
  2: "Add your <br /> <strong>Company & Position</strong>",
  3: "Select <br /> <strong>Background</strong>",
  4: "Your card will look great <br /> with a <strong>Profile Picture</strong>",
  5: "Add your <br /> <strong>Company Logo</strong>",
  6: "Select your <br /> <strong>Theme Color</strong>",
  7: "Add more details <br /> about <strong>Yourself</strong>",
  8: "Add your <br /> <strong>Contact Number</strong>",
  9: "Add your <br /> <strong>Social Media Links</strong>",
  10: '"Card" has been added to your <br /> <strong>Card Functions</strong>',
};

const personalHeaderValues = {
  0: "Choose your <br /> <strong>Card Type</strong>",
  1: "Enter your <br /> <strong>Full Name</strong>",
  2: "Select <br /> <strong>Background</strong>",
  3: "Your card will look great <br /> with a <strong>Profile Picture</strong>",
  // 3: "Add your <br /><strong>Profile Picture</strong>",
  4: "Select your <br /> <strong>Theme Color</strong>",
  5: "Add more details <br /> about <strong>Yourself</strong>",
  6: "Add your <br /> <strong>Contact Number</strong>",
  7: "Add your <br /> <strong>Social Media Links</strong>",
  8: '"Card" has been added to your <br /> <strong>Card Functions</strong>',
};

const options = [
  { value: "personal", label: "Personal" },
  { value: "business", label: "Business" },
];

const pronounsOptions = [
  { value: "she/her", label: "She/Her" },
  { value: "he/him", label: "He/Him" },
  { value: "they/them", label: "They/Them" },
  { value: "custom", label: "Custom" },
];

const tab0Schema = Joi.object({
  card_type: Joi.string().required().messages({
    "string.empty": `Please select a card type`,
  }),
});

const tab1Schema = Joi.object({
  first_name: Joi.string().max(25).trim().required().messages({
    "string.empty": `Please enter your first name`,
    "string.max": `First name can be max 25 characters long`,
  }),

  last_name: Joi.string().max(25).allow("").messages({
    "string.max": `Last name can be max 25 characters long`,
  }),
});

const moreDetailSchema = Joi.object({
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .max(50)
    .allow("")
    .messages({
      "string.email": `Please enter valid email`,
      "string.max": `Email can be max 50 characters long`,
    }),

  city: Joi.string().max(50).allow("").messages({
    "string.max": `City can be max 50 characters long`,
  }),

  country: Joi.string().max(50).allow("").messages({
    "string.max": `Country can be max 50 characters long`,
  }),

  website_url: Joi.string()
    .pattern(
      new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%@_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%@_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      )
    )
    .allow("")
    .messages({
      "string.pattern.base": `Please enter a valid url`,
    }),
});

const companyInfoSchema = Joi.object({
  company: Joi.string().max(50).allow("").messages({
    "string.max": `Company can be max 50 characters long`,
  }),

  company_position: Joi.string().max(50).allow("").messages({
    "string.max": `Company position can be max 50 characters long`,
  }),
});
const phoneNumberSchema = Joi.array().items(
  Joi.object({
    phone_number: Joi.string()
      .pattern(
        new RegExp(
          /^(?!\b(0)\1+\b)(\+?\d{1,3}[. -]?)?\(?\d{3}\)?([. -]?)\d{3}\3\d{4}$/
        )
      )
      .allow("")
      .messages({
        "string.empty": `Please enter your phone number`,
        "string.pattern.base": `Please enter a valid phone number`,
      }),
  })
);

const CreateManualContact = () => {
  const [cardFunction, setCardFunction] = useState({
    card_type: "",
    first_name: "",
    last_name: "",
    email: "",
    company: "",
    company_position: "",
    logo: "",
    background_image: "",
    profile_image: "",
    color: "",
    country_phone: "",
    phone_number: "",
    description: "",
    address: "",
    country: "",
    state: "",
    city: "",
    website_url: "",
    card_social_media: [],
    pronouns: "",
    card_phone: [],
  });

  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [tabHeader, setTabHeader] = useState(personalHeaderValues[0]);
  const [totalTabCount, setTotalTabCount] = useState(0);

  const [mediaOpen, setMediaOpen] = useState(false);
  const [uploadImageOpen, setUploadImageOpen] = useState(false);
  const [colorPickerOpen, setColorPickerOpen] = useState(false);
  const [customMediaLinkOpen, setCustomMediaLinkOpen] = useState(false);

  const [modalMediaLinks, setModalMediaLinks] = useState([]);

  const [customColor, setCustomColor] = useState(false);
  const [customBackgroundImage, setCustomBackgroundImage] = useState(false);

  const { compareLimits, updateCount } = useFunctionsCount();

  const [imageUploadKey, setImageUploadKey] = useState("");

  const [errors, setErrors] = useState({});
  const [mediaLinkErrors, setMediaLinkErrors] = useState([]);

  const [preview, setPreview] = useState(null);
  const [result, setResult] = useState(null);
  const [fileData, setFileData] = useState("");
  const [crop, setCrop] = useState();
  const [showModal, setShowModal] = useState(false);
  const [cropType, setCropType] = useState("");

  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [userTeamId, setUserTeamId] = useAtom(teamIDCheck);
  const [userID, setUserID] = useAtom(userIDCheck);
  const [purchasedPackage, setPurchasedPackage] = useAtom(subscribedPackage);
  const [pronounCheckBox, setPronounCheckBox] = useState(false);
  const [showSkipButton, setShowSkipButton] = useState(false);

  const [customPronoun, setCustomPronoun] = useState("");
  const [customLabel, setCustomLabel] = useState([
    {
      index_no: "",
      label: "",
    },
  ]);

  const [customMediaLink, setCustomMediaLink] = useState("");
  const [customMediaLinkError, setCustomMediaLinkError] = useState({});

  const navigate = useNavigate();

  const handleCloseModal = () => {
    setCrop();
    setShowModal(false);
  };
  useEffect(() => {
    if (result) {
      if (cropType == "profile") {
        setCardFunction({ ...cardFunction, profile_image: fileData });
      } else {
        setCardFunction({ ...cardFunction, logo: fileData });
      }

      setCropType("");
    }
  }, [result]);

  const handleCustomPronounChange = (e) => {
    setCustomPronoun(e.target.value);
  };

  // handle phone type input change
  const handlePhoneTypeChange = (e, index) => {
    if (
      e.target.value === "custom" &&
      purchasedPackage &&
      purchasedPackage.id == 1
    ) {
      showToast(
        false,
        "The requested feature is not compatible with your current plan. Upgrade your plan to continue using these features"
      );
      return;
    }

    const newArray = cardFunction.card_phone.map((item, i) => {
      if (index === i) {
        return { ...item, phone_type: e.target.value };
      } else {
        return item;
      }
    });
    setCardFunction({
      ...cardFunction,
      card_phone: newArray,
    });
  };

  // handle phone type input change
  const handlePhoneNumberChange = (value, country, index) => {
    const newArray = cardFunction.card_phone.map((item, i) => {
      if (index === i) {
        return {
          ...item,
          phone_number: value,
          country_phone: `${country.dialCode}`,
        };
      } else {
        return item;
      }
    });
    setCardFunction({
      ...cardFunction,
      card_phone: newArray,
    });
  };

  const handleCustomLabel = (e, index) => {
    const newArray = cardFunction.card_phone.map((item, i) => {
      if (index === i) {
        return {
          ...item,
          custom_label: e.target.value,
        };
      } else {
        return item;
      }
    });
    setCardFunction({
      ...cardFunction,
      card_phone: newArray,
    });
  };

  const addPhoneFormField = () => {
    setCardFunction({
      ...cardFunction,
      ...cardFunction.card_phone.push({
        phone_type: "",
        country_phone: "",
        phone_number: "",
        custom_label: "",
      }),
    });
  };

  const removePhoneFormField = (i) => {
    let removeCardPhone = [...cardFunction.card_phone];
    removeCardPhone.splice(i, 1);
    setCardFunction({ ...cardFunction, card_phone: removeCardPhone });
    setErrors({});
  };

  // const filteredMediaLinks = useMemo(() => {
  //   const filteredLinks = _.cloneDeep(AddMoreCustomUrls);
  //   const newFilteredLinks = [];
  //   filteredLinks.forEach((item) => {
  //     const found = modalMediaLinks.find(
  //       (single) => single.media_type === item.name
  //     );
  //     if (!found || item.name === "custom") {
  //       newFilteredLinks.push(item);
  //     }
  //   });

  //   return newFilteredLinks;
  // }, [modalMediaLinks]);
  const filteredMediaLinks = useMemo(() => {
    const filteredLinks = _.cloneDeep(AddMoreCustomUrls);
    if (purchasedPackage.id === 1) {
      const newFilteredLinks = [];
      filteredLinks.forEach((item) => {
        const found = modalMediaLinks.find(
          (single) => single.media_type === item.name
        );
        if (!found || item.name === "custom") {
          newFilteredLinks.push(item);
        }
      });
      return newFilteredLinks;
    } else {
      return filteredLinks;
    }
  }, [modalMediaLinks]);

  const handleImageChange = (files) => {
    const selectedFile = files[0];
    const maxSizeInBytes = 10 * 1024 * 1024; // 10MB

    if (selectedFile.size > maxSizeInBytes) {
      showToast(
        false,
        "The selected image size exceeds the maximum limit of 10MB."
      );
      return;
    }

    setCardFunction({ ...cardFunction, [imageUploadKey]: selectedFile });

    if (imageUploadKey === "profile_image") {
      setPreview(URL.createObjectURL(selectedFile));
      setShowModal(true);
      setCropType("profile");
    }
    if (imageUploadKey === "logo") {
      setPreview(URL.createObjectURL(selectedFile));
      setShowModal(true);
      setCropType("logo");
    }

    if (imageUploadKey === "background_image") {
      setCustomBackgroundImage(true);
    }

    handleUploadImageClose();
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: handleImageChange,
    accept: {
      "image/jpeg": [],
      "image/png": [],
    },
  });

  const handleMediaOpen = () => {
    setModalMediaLinks(_.cloneDeep(cardFunction.card_social_media));
    setMediaOpen(true);
  };

  const handleMediaClose = () => {
    setModalMediaLinks([]);
    setMediaLinkErrors({});
    setMediaOpen(false);
  };

  const handleColorPickerOpen = () => setColorPickerOpen(true);
  const handleColorPickerClose = () => setColorPickerOpen(false);

  const handleUploadImageOpen = (key) => {
    if (
      key === "background_image" &&
      purchasedPackage &&
      purchasedPackage.id == 1
    ) {
      showToast(
        false,
        "The requested feature is not compatible with your current plan. Upgrade your plan to continue using these features"
      );
      return;
    }

    setUploadImageOpen(true);
    setImageUploadKey(key);
  };

  const handleUploadImageClose = () => {
    setUploadImageOpen(false);
    setImageUploadKey("");
  };

  const handleSkip = () => {
    setErrors({});
    if (cardFunction.card_type === "personal") {
      if (tabValue === 2) {
        setCardFunction({
          ...cardFunction,
          background_image: CardBackGroundImages[0].img,
        });
      }

      if (tabValue === 3) {
        setCardFunction({
          ...cardFunction,
          profile_image: "",
        });
      }

      if (tabValue === 4) {
        setCardFunction({ ...cardFunction, color: Colors[0].bgColor });
      }

      if (tabValue === 5) {
        setCardFunction({
          ...cardFunction,
          description: "",
          email: "",
          city: "",
          address: "",
          state: "",
          country: "",
          website_url: "",
        });
      }

      if (tabValue === 6) {
        setCardFunction({
          ...cardFunction,
          card_phone: [],
        });
      }
    }

    if (cardFunction.card_type === "business") {
      if (tabValue === 2) {
        setCardFunction({
          ...cardFunction,
          company: "",
          company_position: "",
        });
      }

      if (tabValue === 3) {
        setCardFunction({
          ...cardFunction,
          background_image: CardBackGroundImages[0].img,
        });
      }

      if (tabValue === 4) {
        setCardFunction({
          ...cardFunction,
          profile_image: "",
        });
      }

      if (tabValue === 5) {
        setCardFunction({
          ...cardFunction,
          logo: "",
        });
      }

      if (tabValue === 6) {
        setCardFunction({ ...cardFunction, color: Colors[0].bgColor });
      }

      if (tabValue === 7) {
        setCardFunction({
          ...cardFunction,
          description: "",
          email: "",
          address: "",
          city: "",
          state: "",
          country: "",
          website_url: "",
        });
      }

      if (tabValue === 8) {
        setCardFunction({
          ...cardFunction,
          card_phone: [],
        });
      }
    }

    handleShowSkip(tabValue + 1);
    setTabValue(tabValue + 1);
  };

  const handleTabIncrement = () => {
    if (tabValue === 0) {
      if (!validateForm(tab0Schema)) return;
    }

    if (tabValue === 1) {
      if (cardFunction.pronouns === "custom" && customPronoun.length > 20) {
        setErrors({
          custom_pronouns: "Custom pronouns can be max 20 characters long",
        });
        return;
      }

      if (!validateForm(tab1Schema)) return;
    }

    if (
      (cardFunction.card_type === "business" && tabValue === 7) ||
      (cardFunction.card_type === "personal" && tabValue === 5)
    ) {
      if (!validateForm(moreDetailSchema)) return;
    }

    if (
      (cardFunction.card_type === "business" && tabValue === 8) ||
      (cardFunction.card_type === "personal" && tabValue === 6)
    ) {
      setErrors({});
      for (let index = 0; index < cardFunction.card_phone.length; index++) {
        const data = cardFunction.card_phone[index];
        if (data.phone_type || data.phone_number) {
          if (data.phone_type === "") {
            setErrors({
              [`phone_number${index}`]: "Please select phone type",
            });
            return;
          }

          if (data.phone_number === "") {
            setErrors({
              [`phone_number${index}`]: "Please enter phone number",
            });
            return;
          }

          if (data.phone_number.length > data.country_phone.length) {
            let resultPhone = validatePhoneWithCountryCode(
              data.country_phone,
              data.phone_number
            );
            if (resultPhone) {
              setErrors({
                [`phone_number${index}`]: resultPhone,
              });
              return false;
            }
          }

          if (data.phone_type === "custom" && data.custom_label === "") {
            setErrors({
              [`lable${index}`]: "Please enter custom phone label",
            });
            return;
          }

          if (data.phone_type === "custom" && data.custom_label.length > 20) {
            setErrors({
              [`lable${index}`]: "Custom Label can be max 20 characters long",
            });
            return;
          }
        }
      }

      let filterNumbers = cardFunction.card_phone.filter(function (el) {
        return el.phone_number != "";
      });
      setCardFunction({
        ...cardFunction,
        card_phone: filterNumbers,
      });
    }

    if (cardFunction.card_type === "business" && tabValue === 2) {
      if (!validateForm(companyInfoSchema)) return;
    }

    if (cardFunction.card_type === "business") {
      setTabHeader(businessHeaderValues[tabValue + 1]);
    } else {
      setTabHeader(personalHeaderValues[tabValue + 1]);
    }

    if (cardFunction.card_type === "business") {
      if (tabValue + 1 === 3) {
        if (!cardFunction.background_image) {
          setCardFunction({
            ...cardFunction,
            background_image: CardBackGroundImages[0].img,
          });
        }
      } else if (tabValue + 1 === 6) {
        if (!cardFunction.color) {
          setCardFunction({ ...cardFunction, color: Colors[0].bgColor });
        }
      }
    } else {
      if (tabValue + 1 === 2) {
        if (!cardFunction.background_image) {
          setCardFunction({
            ...cardFunction,
            background_image: CardBackGroundImages[0].img,
          });
        }
      } else if (tabValue + 1 === 4) {
        if (!cardFunction.color) {
          setCardFunction({ ...cardFunction, color: Colors[0].bgColor });
        }
      }
    }

    handleShowSkip(tabValue + 1);
    setTabValue(tabValue + 1);
  };

  const handleTabDecrement = () => {
    handleShowSkip(tabValue - 1);
    setTabValue(tabValue - 1);
    if (cardFunction.card_type === "business") {
      setTabHeader(businessHeaderValues[tabValue - 1]);
    } else {
      setTabHeader(personalHeaderValues[tabValue - 1]);
    }
  };

  const handleShowSkip = (value) => {
    // check on the base of businese or personal
    if (cardFunction.card_type === "business") {
      if ([0, 1, 10].includes(value)) {
        setShowSkipButton(false);
      } else {
        setShowSkipButton(true);
      }
    } else {
      if ([0, 1, 8].includes(value)) {
        setShowSkipButton(false);
      } else {
        setShowSkipButton(true);
      }
    }
  };

  const handleChange = (e, i) => {
    const name = e.target.name;
    const value = e.target.value;

    if (
      name === "pronouns" &&
      value === "custom" &&
      purchasedPackage &&
      purchasedPackage.id == 1
    ) {
      setCardFunction({ ...cardFunction, pronouns: "" });
      showToast(
        false,
        "The requested feature is not compatible with your current plan. Upgrade your plan to continue using these features"
      );
      return;
    }

    setCardFunction({ ...cardFunction, [name]: value });
    if (name === "card_type") {
      if (value === "business") {
        setTotalTabCount(10);
      } else {
        setTotalTabCount(8);
      }
    }
  };

  const handleMediaChange = (e) => {
    if (
      e.target.value === "custom" &&
      purchasedPackage &&
      purchasedPackage.id == 1
    ) {
      showToast(
        false,
        "The requested feature is not compatible with your current plan. Upgrade your plan to continue using these features"
      );
      return;
    }
    if (e.target.value === "custom") {
      setMediaOpen(false);
      setCustomMediaLinkOpen(true);
    }

    const found = AddMoreCustomUrls.find(
      (item) => item.name === e.target.value
    );

    let newSocialMediaLinks = _.cloneDeep(modalMediaLinks);

    newSocialMediaLinks.push({
      media_type: found.name,
      url: "",
      icon: found.name === "custom" ? "custom" : found.icon,
      custom_label: "",
    });

    setModalMediaLinks(newSocialMediaLinks);
  };

  const handleCustomMediaNameSave = () => {
    if (!validateCustomMediaLink()) return;
    let newSocialMediaLinks = _.cloneDeep(modalMediaLinks);

    newSocialMediaLinks[newSocialMediaLinks.length - 1].custom_label =
      customMediaLink;

    setModalMediaLinks(newSocialMediaLinks);
    setCustomMediaLinkOpen(false);
    setCustomMediaLink("");
    setMediaOpen(true);
  };

  const handleCustomMediaModalClose = () => {
    setCustomMediaLinkOpen(false);

    let newSocialMediaLinks = _.cloneDeep(modalMediaLinks);
    newSocialMediaLinks.splice(newSocialMediaLinks.length - 1, 1);
    setModalMediaLinks(newSocialMediaLinks);

    setMediaOpen(true);
  };

  const handleModalUrlChange = (e, index) => {
    let newSocialMediaLinks = _.cloneDeep(modalMediaLinks);

    newSocialMediaLinks[index].url = formatUrl(e.target.value);

    setModalMediaLinks(newSocialMediaLinks);
  };

  const handleMedialSave = () => {
    if (!validateMediaModalForm()) return;

    setCardFunction({ ...cardFunction, card_social_media: modalMediaLinks });
    handleMediaClose();
  };

  const handleMediaDelete = (index) => {
    let newSocialMediaLinks = _.cloneDeep(cardFunction.card_social_media);
    newSocialMediaLinks.splice(index, 1);
    setCardFunction({
      ...cardFunction,
      card_social_media: newSocialMediaLinks,
    });
  };

  const handleModalMediaDelete = (index) => {
    let newSocialMediaLinks = _.cloneDeep(modalMediaLinks);
    newSocialMediaLinks.splice(index, 1);
    setModalMediaLinks(newSocialMediaLinks);
  };

  const validateForm = (schema) => {
    let newErrors = ValidateSchema(cardFunction, schema);

    setErrors(newErrors);

    if (Object.keys(newErrors).length) return false;

    return true;
  };

  const validateMediaModalForm = () => {
    let newErrors = ValidateSchema(modalMediaLinks, socialMediaLinksSchema);

    setMediaLinkErrors(newErrors);

    if (Object.keys(newErrors).length) return false;

    return true;
  };

  const validateCustomMediaLink = () => {
    let newErrors = ValidateSchema(
      { customMediaLink },
      Joi.object({
        customMediaLink: Joi.string().max(50).required().messages({
          "string.empty": `Please enter Custom Media Name`,
          "string.max": `Custom Media Name must be maximum 50 characters!`,
        }),
      })
    );

    setCustomMediaLinkError(newErrors);

    if (Object.keys(newErrors).length) return false;

    return true;
  };

  const validatePhoneNumber = () => {
    let newErrors = ValidateSchema(cardFunction.card_phone, phoneNumberSchema);
    setErrors(newErrors);
    if (Object.keys(newErrors).length) return false;
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const res = compareLimits(functionTypes.CONNECTION);
    if (res)
      return showToast(
        false,
        "The requested feature is not compatible with your current plan. Upgrade your plan to continue using these features"
      );
    setFormLoading(true);

    let payload = _.cloneDeep(cardFunction);

    // Handle website Url
    payload.website_url = formatUrl(payload.website_url);

    if (typeof payload.background_image == "object") {
      const { status, message, data } = await uploadImage(
        "card_background",
        payload.background_image,
        loggedUser?.token
      );

      if (status) {
        payload.background_image = data;
      } else {
        showToast(status, message);
        setFormLoading(false);
        return;
      }
    }

    if (typeof payload.profile_image == "object") {
      const { status, message, data } = await uploadImage(
        "card_profile",
        payload.profile_image,
        loggedUser?.token
      );

      if (status) {
        payload.profile_image = data;
      } else {
        showToast(status, message);
        setFormLoading(false);
        return;
      }
    }

    if (typeof payload.logo == "object" && payload.card_type === "business") {
      const { status, message, data } = await uploadImage(
        "card_logo",
        payload.logo,
        loggedUser?.token
      );

      if (status) {
        payload.logo = data;
      } else {
        showToast(status, message);
        setFormLoading(false);
        return;
      }
    }

    if (payload.card_type === "personal") {
      payload.logo = "";
    }

    payload.card_social_media.forEach((item) => {
      delete item.icon;
    });

    payload.contact_card_social_media = payload.card_social_media;

    if (payload.card_phone.length > 0) {
      let cardPhoneArr = payload.card_phone.map((item, index) => {
        if (item.phone_type === "custom") {
          return {
            ...item,
            phone_type: item.custom_label ? item.custom_label : "custom",
            phone_number: item.phone_number.slice(
              item.country_phone.length,
              item.phone_number.length
            ),
          };
        } else {
          return {
            ...item,
            phone_number: item.phone_number.slice(
              item.country_phone.length,
              item.phone_number.length
            ),
          };
        }
      });

      cardPhoneArr.forEach((object) => {
        delete object["custom_label"];
      });

      payload.card_phone = cardPhoneArr;
    }

    payload.contact_card_phone = payload.card_phone;

    if (payload.pronouns === "custom") {
      payload.pronouns = customPronoun;
    }

    if (payload.pronouns === "custom" && customPronoun === "") {
      payload.pronouns = "";
    }

    if (userTeamId || userID) {
      payload.team_id = userTeamId;
      payload.user_id = userID;
    }

    let { status, message, data } = await post(
      CONTACT + "/manual",
      payload,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      handleTabIncrement();
      updateCount(functionTypes.CONNECTION, functionTypes.ADD);

      setTimeout(() => {
        navigate(`/contacts`);
      }, 2000);
    }

    showToast(status, message);

    setFormLoading(false);
  };

  const handleShowSidebar = () => {
    let cardView = document.querySelector(".cardView");
    cardView.classList.add("showCardView");
  };

  const handleHideCardView = () => {
    let cardView = document.querySelector(".cardView");
    cardView.classList.remove("showCardView");
  };

  return (
    <>
      {!loading ? (
        <div className={classes.outerWrapper}>
          <div className="col-md-8 col-12">
            <div className="row">
              <div className="d-flex align-items-center justify-content-between">
                <h6 className={classes.title}>
                  <Link
                    to="/contacts"
                    style={{ color: "var(--heading_text_color)" }}
                  >
                    Connections
                  </Link>
                  {" > Add"}
                </h6>
                <div className="view">
                  <button onClick={handleShowSidebar}>View Card</button>
                </div>
              </div>
            </div>

            <div className={classes.mainWrapper}>
              <button
                onClick={handleTabDecrement}
                className={classes.prevBtn}
                disabled={
                  tabValue === 0 ||
                  (cardFunction.card_type === "business"
                    ? tabValue === 10
                    : tabValue === 8)
                }
              >
                <FiChevronLeft />
              </button>

              <button
                onClick={handleTabIncrement}
                className={classes.nextBtn}
                disabled={
                  cardFunction.card_type === "business"
                    ? tabValue === 9 || tabValue === 10
                      ? true
                      : false
                    : tabValue === 7 || tabValue === 8
                    ? true
                    : false
                }
              >
                <FiChevronRight />
              </button>

              <div className={classes.cardFormWrapper}>
                {formLoading ? (
                  <div className={classes.loaderWrapper}>
                    <div className={loaderClasses.loader} />
                  </div>
                ) : (
                  <>
                    <div className={classes.cardHeaderWrapper}>
                      {tabValue !== 0 ? (
                        <span className={classes.tabCount}>
                          <span>{tabValue}</span> / {totalTabCount}
                        </span>
                      ) : (
                        <div />
                      )}

                      <span
                        className={classes.tabHeader}
                        dangerouslySetInnerHTML={{ __html: tabHeader }}
                      />

                      {(
                        cardFunction.card_type === "business"
                          ? tabValue === 9
                          : tabValue === 7
                      ) ? (
                        <button
                          className={classes.skipButton}
                          onClick={handleSubmit}
                        >
                          Save
                        </button>
                      ) : (
                        <div style={tabValue !== 0 ? { width: "55px" } : null}>
                          {showSkipButton && (
                            <button
                              className={classes.skipButton}
                              onClick={handleSkip}
                            >
                              skip
                            </button>
                          )}
                        </div>
                      )}
                    </div>

                    <div style={tabValue === 0 ? null : { display: "none" }}>
                      <div className={classes.cardBodyWrapper}>
                        <div className="text-center">
                          <img
                            src={cardtype}
                            alt=""
                            className={classes.formImage}
                          />
                          <Select
                            placeholder="Choose your card type"
                            options={options}
                            value={cardFunction.card_type}
                            name="card_type"
                            onChange={handleChange}
                          />
                          <span className="errorText">{errors.card_type}</span>
                        </div>
                      </div>
                    </div>

                    <div style={tabValue === 1 ? null : { display: "none" }}>
                      <div className={classes.cardBodyWrapper}>
                        <div className="text-center">
                          <img
                            src={cardname}
                            alt=""
                            className={classes.formImage}
                          />
                        </div>
                        <div className={classes.selectWrapper}>
                          <div
                            className={classes.moreDetailHeader}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <div>
                              <BsFillPersonFill />
                              <span>Pronouns</span>
                            </div>
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                role="switch"
                                name="pronoun_check"
                                checked={pronounCheckBox}
                                onChange={(e) => {
                                  setPronounCheckBox(e.target.checked);

                                  if (!e.target.checked) {
                                    setCardFunction({
                                      ...cardFunction,
                                      pronouns: "",
                                    });
                                    setCustomPronoun("");
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <div style={{ marginBottom: "10px" }}>
                            <Select
                              placeholder="Choose your pronouns"
                              options={pronounsOptions}
                              value={cardFunction.pronouns}
                              name="pronouns"
                              disabled={!pronounCheckBox}
                              onChange={handleChange}
                            />
                          </div>
                          {cardFunction.pronouns === "custom" && (
                            <>
                              <div
                                className={
                                  errors.custom_pronouns
                                    ? "input-group globalInputWithIcon errorInput"
                                    : "input-group  globalInputWithIcon"
                                }
                                style={{
                                  marginTop: "10px",
                                  marginBottom: "10px",
                                }}
                              >
                                <input
                                  placeholder="Please Enter Custom Pronoun"
                                  className={
                                    errors.custom_pronouns
                                      ? "form-control inputError"
                                      : "form-control"
                                  }
                                  value={customPronoun}
                                  onChange={handleCustomPronounChange}
                                />
                              </div>
                              <p className="errorText">
                                {errors.custom_pronouns || ""}
                              </p>
                            </>
                          )}
                        </div>

                        <div className={classes.smallInputWrapper}>
                          <label className={classes.inputLabel}>
                            First Name
                          </label>
                          <FormInput
                            type="text"
                            name="first_name"
                            placeholder="First name"
                            value={cardFunction.first_name}
                            handleChange={handleChange}
                            error={errors.first_name}
                          />
                        </div>
                        <div className={classes.smallInputWrapper}>
                          <label className={classes.inputLabel}>
                            Last Name
                          </label>
                          <FormInput
                            type="text"
                            name="last_name"
                            placeholder="Last name"
                            value={cardFunction.last_name}
                            handleChange={handleChange}
                            error={errors.last_name}
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      style={
                        tabValue === 2 && cardFunction.card_type === "business"
                          ? null
                          : { display: "none" }
                      }
                    >
                      <div className={classes.cardBodyWrapper}>
                        <div className="text-center">
                          <img
                            src={company}
                            alt=""
                            className={classes.formImage}
                          />
                        </div>

                        <div className={classes.smallInputWrapper}>
                          <label className={classes.inputLabel}>
                            Company Name
                          </label>
                          <FormInput
                            type="text"
                            name="company"
                            placeholder="Company name"
                            value={cardFunction.company}
                            handleChange={handleChange}
                            error={errors.company}
                          />
                        </div>

                        <div className={classes.smallInputWrapper}>
                          <label className={classes.inputLabel}>
                            Job Position
                          </label>
                          <FormInput
                            type="text"
                            name="company_position"
                            placeholder="Position name"
                            value={cardFunction.company_position}
                            handleChange={handleChange}
                            error={errors.company_position}
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      style={
                        (
                          cardFunction.card_type === "business"
                            ? tabValue === 3
                            : tabValue === 2
                        )
                          ? null
                          : { display: "none" }
                      }
                    >
                      <div className={classes.cardBodyWrapper}>
                        <div className={classes.gridWrapper}>
                          {CardBackGroundImages.map((image) => {
                            const { id, img } = { ...image };
                            return (
                              <img
                                key={`backgroundImage${id}`}
                                src={`${baseURL.PUBLIC_URL}${img}`}
                                alt=""
                                className={
                                  cardFunction.background_image == img
                                    ? classes.selectedOption
                                    : null
                                }
                                onClick={() => {
                                  if (!customBackgroundImage) {
                                    setCardFunction({
                                      ...cardFunction,
                                      background_image: img,
                                    });
                                  }
                                }}
                              />
                            );
                          })}

                          {cardFunction.background_image &&
                          customBackgroundImage ? (
                            <div className={classes.customBackgroundWrapper}>
                              <img
                                src={
                                  cardFunction.background_image
                                    ? `${URL.createObjectURL(
                                        cardFunction.background_image
                                      )}`
                                    : ""
                                }
                                alt=""
                                className={classes.selectedOption}
                              />
                              <div className={classes.bgImgClearBtnWrapper}>
                                <MdClose
                                  className={classes.bgImgClearBtn}
                                  onClick={() => {
                                    setCardFunction({
                                      ...cardFunction,
                                      background_image:
                                        CardBackGroundImages[0].img,
                                    });
                                    setCustomBackgroundImage(false);
                                  }}
                                />
                              </div>
                            </div>
                          ) : (
                            <div
                              className={classes.emptyBackgroundImage}
                              onClick={() =>
                                handleUploadImageOpen("background_image")
                              }
                            >
                              <span>+</span>
                            </div>
                          )}
                        </div>

                        <div className="text-center">
                          <button
                            className={classes.uploadBtn}
                            type="button"
                            onClick={() =>
                              handleUploadImageOpen("background_image")
                            }
                          >
                            Upload
                          </button>
                        </div>
                      </div>
                    </div>

                    <div
                      style={
                        (
                          cardFunction.card_type === "business"
                            ? tabValue === 4
                            : tabValue === 3
                        )
                          ? null
                          : { display: "none" }
                      }
                    >
                      <div className={classes.cardBodyWrapper}>
                        <div className="text-center">
                          <div>
                            <img
                              src={
                                cardFunction.profile_image
                                  ? `${URL.createObjectURL(
                                      cardFunction.profile_image
                                    )}`
                                  : pictureicon
                              }
                              alt=""
                              className={classes.formImage}
                              style={
                                cardFunction.profile_image
                                  ? {
                                      borderRadius: "50%",
                                      objectFit: "cover",
                                      width: "160px",
                                      height: "160px",
                                    }
                                  : { width: "160px", height: "160px" }
                              }
                            />
                          </div>
                          <button
                            className={classes.uploadBtn}
                            type="button"
                            onClick={() =>
                              handleUploadImageOpen("profile_image")
                            }
                          >
                            Upload
                          </button>
                        </div>
                      </div>
                    </div>

                    <div
                      style={
                        tabValue === 5 && cardFunction.card_type === "business"
                          ? null
                          : { display: "none" }
                      }
                    >
                      <div className={classes.cardBodyWrapper}>
                        <div className="text-center">
                          <div>
                            <img
                              src={
                                cardFunction.logo
                                  ? `${URL.createObjectURL(cardFunction.logo)}`
                                  : companylogo
                              }
                              alt=""
                              className={classes.formImage}
                              style={
                                cardFunction.logo
                                  ? {
                                      borderRadius: "50%",
                                      objectFit: "cover",
                                      width: "160px",
                                      height: "160px",
                                    }
                                  : { width: "160px", height: "160px" }
                              }
                            />
                          </div>
                          <button
                            className={classes.uploadBtn}
                            type="button"
                            onClick={() => handleUploadImageOpen("logo")}
                          >
                            Upload
                          </button>
                        </div>
                      </div>
                    </div>

                    <div
                      style={
                        (
                          cardFunction.card_type === "business"
                            ? tabValue === 6
                            : tabValue === 4
                        )
                          ? null
                          : { display: "none" }
                      }
                    >
                      <div className={classes.cardBodyWrapper}>
                        <div className={classes.gridWrapper}>
                          {Colors.map((color) => {
                            const { id, bgColor } = { ...color };
                            return (
                              <div
                                key={`color${id}`}
                                style={{ backgroundColor: bgColor }}
                                className={`${classes.colorDiv} ${
                                  cardFunction.color == bgColor
                                    ? classes.selectedColorOption
                                    : null
                                }`}
                                onClick={() =>
                                  setCardFunction({
                                    ...cardFunction,
                                    color: bgColor,
                                  })
                                }
                              />
                            );
                          })}
                          {/* <div
                              className={classes.emptyColor}
                              onClick={handleColorPickerOpen}
                            >
                              <span>+</span>
                            </div> */}
                        </div>
                      </div>
                    </div>

                    <div
                      style={
                        (
                          cardFunction.card_type === "business"
                            ? tabValue === 7
                            : tabValue === 5
                        )
                          ? null
                          : { display: "none" }
                      }
                    >
                      <div className={classes.cardBodyWrapper}>
                        <div className="text-center">
                          <img
                            src={userdetails}
                            alt=""
                            style={{ width: "190px", height: "145px" }}
                            className={classes.formImage}
                          />
                        </div>
                        <div className={classes.moreDetailWrapper}>
                          <div className={classes.moreDetailHeader}>
                            <IoDocumentTextSharp />
                            <span>About me</span>
                          </div>
                          <textarea
                            // className={classes.textareaInput}
                            className={
                              errors.description
                                ? "globalTextArea errorInput"
                                : "globalTextArea"
                            }
                            type="text"
                            placeholder="Description"
                            name="description"
                            value={cardFunction.description}
                            onChange={handleChange}
                          ></textarea>
                        </div>

                        <div className={classes.moreDetailWrapper}>
                          <div className={classes.moreDetailHeader}>
                            <FiMail />
                            <span>Email Address</span>
                          </div>
                          <FormInput
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={cardFunction.email}
                            handleChange={handleChange}
                            error={errors.email}
                          />
                        </div>

                        <div className={classes.moreDetailWrapper}>
                          <div className={classes.moreDetailHeader}>
                            <MdLocationOn />
                            <span>Location</span>
                          </div>
                          <FormInput
                            type="text"
                            name="address"
                            placeholder="Address"
                            value={cardFunction.address}
                            handleChange={handleChange}
                            error={errors.address}
                          />
                          <FormInput
                            type="text"
                            name="city"
                            placeholder="City"
                            value={cardFunction.city}
                            handleChange={handleChange}
                            error={errors.city}
                          />
                          <FormInput
                            type="text"
                            name="state"
                            placeholder="State"
                            value={cardFunction.state}
                            handleChange={handleChange}
                            error={errors.state}
                          />
                          <FormInput
                            type="text"
                            name="country"
                            placeholder="Country"
                            value={cardFunction.country}
                            handleChange={handleChange}
                            error={errors.country}
                          />
                        </div>

                        <div className={classes.moreDetailWrapper}>
                          <div className={classes.moreDetailHeader}>
                            <BsGlobe />
                            <span>Website</span>
                          </div>
                          <FormInput
                            type="text"
                            name="website_url"
                            placeholder="Url"
                            value={cardFunction.website_url}
                            handleChange={handleChange}
                            error={errors.website_url}
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      style={
                        (
                          cardFunction.card_type === "business"
                            ? tabValue === 8
                            : tabValue === 6
                        )
                          ? null
                          : { display: "none" }
                      }
                    >
                      <div className={classes.cardBodyWrapper}>
                        <div className="text-center">
                          <img
                            src={contactnumber}
                            alt=""
                            className={classes.formImage}
                          />
                        </div>
                        {/* <PhoneInput
                          country={"us"}
                          onlyCountries={["us"]}
                          inputClass={
                            errors.phone_number
                              ? "phoneNumberInput errorInput"
                              : "phoneNumberInput"
                          }
                          value={cardFunction.phone_number}
                          onChange={(value, country, e, formattedValue) => {
                            setCardFunction({
                              ...cardFunction,
                              phone_number: value,
                              country_phone: `+${country.dialCode}`,
                            });
                          }}
                        />
                        <span className="errorText">{errors.phone_number}</span> */}

                        {cardFunction &&
                          cardFunction?.card_phone?.map((element, index) => {
                            return (
                              <div
                                key={index}
                                className={classes.phoneInputOuterWrapper}
                              >
                                <div
                                  className={`d-flex align-items-center gap-1 ${classes.phoneInputMainWrapper}`}
                                >
                                  <div
                                    className={`d-flex align-items-center ${
                                      errors[`phone_number${index}`]
                                        ? "phoneInputWrapper errorInput"
                                        : "phoneInputWrapper "
                                    } ${classes.phoneInputMainWrapper}`}
                                  >
                                    <div
                                      className="input-group globalInputWithIcon addCardSelect"
                                      style={{ maxWidth: "100px" }}
                                    >
                                      <select
                                        style={{ border: "0" }}
                                        className="form-select customSelect"
                                        onChange={(e) =>
                                          handlePhoneTypeChange(e, index)
                                        }
                                        value={element.phone_type}
                                      >
                                        <option value="">Select</option>
                                        <option value="home">Home</option>
                                        <option value="office">Mobile</option>
                                        <option value="custom">Custom</option>
                                      </select>
                                    </div>
                                    <div className="addPhoneInput">
                                      <PhoneInput
                                        country={"us"}
                                        enableSearch={true}
                                        countryCodeEditable={false}
                                        inputClass={
                                          errors[`phone_number${index}`]
                                            ? "phoneNumberInput errorInput"
                                            : "phoneNumberInput "
                                        }
                                        value={element.phone_number}
                                        onChange={(value, country) =>
                                          handlePhoneNumberChange(
                                            value,
                                            country,
                                            index
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                  <div
                                    className={classes.removePhonutBtnWrapper}
                                  >
                                    <button
                                      onClick={() =>
                                        removePhoneFormField(index)
                                      }
                                    >
                                      <FiTrash2 />
                                    </button>
                                  </div>
                                </div>
                                <p className="errorText">
                                  {errors[`phone_number${index}`]}
                                </p>
                                {element.phone_type === "custom" && (
                                  <div
                                    className={classes.formInputWrapper}
                                    style={{ marginTop: "0px" }}
                                  >
                                    <label className={classes.inputLabel}>
                                      Custom Label
                                    </label>
                                    <FormInput
                                      type="text"
                                      name={errors[`lable${index}`]}
                                      placeholder={`Enter Phone Label`}
                                      error={errors[`lable${index}`]}
                                      onChange={(e) =>
                                        handleCustomLabel(e, index)
                                      }
                                    />
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        <div>
                          <div className={classes.addPhonutBtnWrapper}>
                            <button onClick={() => addPhoneFormField()}>
                              Add More
                            </button>
                          </div>
                          {/* <input type="text" /> */}
                          {/* {cardFunction &&
                            cardFunction?.card_phone?.map((element, index) => {
                              return (
                                <div key={index}>
                                  <PhoneInput
                                    country={"us"}
                                    onlyCountries={["us"]}
                                    inputClass={
                                      errors.phone_number
                                        ? "phoneNumberInput errorInput"
                                        : "phoneNumberInput"
                                    }
                                    value={element.phone_number}
                                    onChange={(
                                      value,
                                      country,
                                      e,
                                      formattedValue
                                    ) => {
                                      setCardFunction({
                                        ...cardFunction,
                                        phone_number: value,
                                        country_phone: `+${country.dialCode}`,
                                      });
                                    }}
                                  />
                                  <span className="errorText">
                                    {errors.phone_number}
                                  </span>
                                </div>
                              );
                            })} */}
                        </div>
                      </div>
                    </div>

                    <div
                      style={
                        (
                          cardFunction.card_type === "business"
                            ? tabValue === 9
                            : tabValue === 7
                        )
                          ? null
                          : { display: "none" }
                      }
                    >
                      <div className={classes.cardBodyWrapper}>
                        <>
                          <div className="text-center">
                            <img
                              src={socialmedialinks}
                              alt=""
                              className={classes.formImage}
                            />
                          </div>
                          {cardFunction.card_social_media.map(
                            (single, index) => (
                              <div
                                className={classes.socialMediaLink}
                                key={`cardLink${index}`}
                              >
                                <div className="d-flex justify-content-center align-items-center">
                                  <div
                                    className={classes.socialMediaModalLinkIcon}
                                  >
                                    {single.icon === "custom"
                                      ? single.custom_label.charAt(0)
                                      : single.icon}
                                  </div>
                                  <span>{single.url}</span>
                                </div>
                                <FiTrash2
                                  onClick={() => handleMediaDelete(index)}
                                  className={classes.socialLinkDeleteIcon}
                                />
                              </div>
                            )
                          )}
                          <div className="text-center">
                            <button
                              className={classes.uploadBtn}
                              type="button"
                              onClick={handleMediaOpen}
                            >
                              Add More
                            </button>
                          </div>
                        </>
                      </div>
                    </div>

                    <div
                      style={
                        (
                          cardFunction.card_type === "business"
                            ? tabValue === 10
                            : tabValue === 8
                        )
                          ? null
                          : { display: "none" }
                      }
                    >
                      <div className={classes.cardBodyWrapper}>
                        <div className="text-center">
                          <div>
                            <img
                              src={cardsuccess}
                              alt=""
                              className={classes.formImage}
                            />
                          </div>
                          <span className={classes.successTitle}>
                            Successfully
                          </span>
                          {/* <Button
                              style={{ background: "var(--primary_button_color)", border: 0 }}
                              type="submit"
                              onClick={handleSubmit}
                            >
                              Save
                            </Button> */}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>

              <div className={classes.mobileViewBtns}>
                <button
                  onClick={handleTabDecrement}
                  className={classes.mobilePrevBtn}
                  disabled={
                    tabValue === 0 ||
                    (cardFunction.card_type === "business"
                      ? tabValue === 10
                      : tabValue === 8)
                  }
                >
                  <BsArrowLeft />
                </button>

                <button
                  onClick={handleTabIncrement}
                  disabled={
                    cardFunction.card_type === "business"
                      ? tabValue === 9 || tabValue === 10
                        ? true
                        : false
                      : tabValue === 7 || tabValue === 8
                      ? true
                      : false
                  }
                >
                  <BsArrowRight />
                </button>
              </div>
            </div>
          </div>

          <div className="col-md-4 col-12 cardView">
            <Card
              data={{
                ...cardFunction,
                pronouns:
                  cardFunction.pronouns === "custom"
                    ? customPronoun
                    : cardFunction.pronouns,
              }}
              handleClose={handleHideCardView}
            />
          </div>
        </div>
      ) : (
        <Loader />
      )}

      <Modal
        show={customMediaLinkOpen}
        onHide={handleCustomMediaModalClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={`customModal`}
        size="sm"
      >
        <Modal.Header className={classes.mediaModalHeader}>
          <span>Custom Media Link Name</span>
          <img
            src={crossicon}
            alt=""
            style={{ cursor: "pointer" }}
            onClick={handleCustomMediaModalClose}
          />
          {/* <GrClose onClick={handleUploadImageClose} /> */}
        </Modal.Header>

        <Modal.Body className={classes.mediaModalBody}>
          <FormInput
            type="text"
            placeholder="Custom Link Name"
            value={customMediaLink}
            handleChange={(e) => setCustomMediaLink(e.target.value)}
            error={customMediaLinkError.customMediaLink}
          />
        </Modal.Body>

        <Modal.Footer className={classes.mediaModalFooter}>
          <button onClick={handleCustomMediaNameSave}>Confirm</button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={mediaOpen}
        onHide={handleMediaClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={`customModal`}
        size="sm"
      >
        <Modal.Header className={classes.mediaModalHeader}>
          <span>Social Links</span>
          <img
            src={crossicon}
            alt=""
            style={{ cursor: "pointer" }}
            onClick={handleMediaClose}
          />
          {/* <GrClose onClick={handleMediaClose} /> */}
        </Modal.Header>

        <Modal.Body className={classes.mediaModalBody}>
          <p>
            Add your social links that will be visible on the homepage of your
            blog.
          </p>

          {/* <select
            className={classes.select}
            value=""
            onChange={handleMediaChange}
          >
            <option value="" selected disabled>
              Add Social Link
            </option>
            {filteredMediaLinks.map((single, index) => (
              <option value={single.name} key={`mediaOption${index}`}>
                {single.name}
              </option>
            ))}
          </select> */}
          <div className="mt-4">
            <Select
              placeholder="Add Social Link"
              options={filteredMediaLinks}
              onChange={(e) => {
                handleMediaChange(e);
              }}
              labelKey="name"
              valueKey="name"
            />
          </div>

          {modalMediaLinks.map((single, index) => (
            <>
              <div
                className={classes.socialMediaModalLink}
                key={`cardModalLink${index}`}
                style={
                  mediaLinkErrors[`${index}.url`]
                    ? { borderColor: "#e35252" }
                    : null
                }
              >
                <div className={classes.socialMediaModalLinkIcon}>
                  {single.icon === "custom"
                    ? single.custom_label.charAt(0)
                    : single.icon}
                </div>
                <input
                  defaultValue={single.url}
                  placeholder="Url"
                  onChange={(e) => handleModalUrlChange(e, index)}
                ></input>
                <FiTrash2
                  onClick={() => handleModalMediaDelete(index)}
                  className={classes.socialLinkDeleteIcon}
                />
              </div>
              {mediaLinkErrors[`${index}.url`] ? (
                <div style={{ textAlign: "start" }}>
                  <span className="errorText">
                    {mediaLinkErrors[`${index}.url`]}
                  </span>
                </div>
              ) : null}
            </>
          ))}
        </Modal.Body>

        <Modal.Footer className={classes.mediaModalFooter}>
          <button onClick={handleMedialSave}>Save</button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={uploadImageOpen}
        onHide={handleUploadImageClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={`customModal`}
        size="sm"
      >
        <Modal.Header className={classes.mediaModalHeader}>
          <span>Upload</span>
          <img
            src={crossicon}
            alt=""
            style={{ cursor: "pointer" }}
            onClick={handleUploadImageClose}
          />
          {/* <GrClose onClick={handleUploadImageClose} /> */}
        </Modal.Header>

        <Modal.Body className={classes.mediaModalBody}>
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <div
              className={`d-flex justify-content-center align-items-center flex-column ${classes.imageUpload}`}
            >
              <BsImageFill />
              <span>
                Drop your Images here, or <span>Browse</span>
              </span>
              <span>Support JPG, PNG, Only</span>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={colorPickerOpen}
        onHide={handleColorPickerClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={`customModal`}
        size="sm"
      >
        <Modal.Header className={classes.mediaModalHeader}>
          <span>Color Picker</span>
          <img
            src={crossicon}
            alt=""
            style={{ cursor: "pointer" }}
            onClick={handleColorPickerClose}
          />
          {/* <GrClose onClick={handleColorPickerClose} /> */}
        </Modal.Header>

        <Modal.Body
          className={`d-flex justify-content-center ${classes.mediaModalBody}`}
        >
          <SketchPicker
            color={cardFunction.color}
            onChangeComplete={(color) => {
              setCardFunction({ ...cardFunction, color: color.hex });
              setCustomColor(true);
            }}
          />
        </Modal.Body>
      </Modal>

      <style type="text/css">
        {`
        .btn-holder {
          justify-content: flex-end;
          display: flex;
        }
        .phoneNumberInput {
      font-size: 14px !important;
}
.addPhoneInput .react-tel-input .form-control{
  padding: 18.5px 5px 18.5px 52px;
  border: 0 !important;
}

      .react-tel-input .selected-flag{
      padding: 0 0 0 12px;
}
.customSelect.form-select{
  padding: 9px 14px;

}
.phoneInputWrapper{
    border: 2px solid var(--border_color);
    border-radius: 25px;
}
.phoneInputWrapper.errorInput {
  border: 2px solid #e35252 !important;
}
.phoneInputWrapper:focus-within {
  border: 2px solid var(--primary_button_color);
}
.addCardSelect.globalInputWithIcon{
  border: 0 !important;
  border-radius: 0;
  border-right: 2px solid var(--border_color) !important;
}
	`}
      </style>

      {preview && (
        <CropImageModal
          show={showModal}
          image={preview}
          handleCloseModal={handleCloseModal}
          result={result}
          setResult={setResult}
          setFileData={setFileData}
          crop={crop}
          setCrop={setCrop}
        />
      )}
    </>
  );
};

export default CreateManualContact;
