import { useNavigate } from "react-router-dom";
import { get } from "../server";
import { GET_USER } from "../utils/constants/apiUrl";
import {
  teamIDCheck,
  subscribedPackage,
  loggedInUser,
  accountType,
  isOwnerPackage,
  userIDCheck,
} from "../utils/store";

import { useAtom } from "jotai";
import showToast from "../helpers/showToast";
import useFunctionsCount from "./useFunctionsCount";

const useRefreshUser = () => {
  const navigate = useNavigate();
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [userTeamId, setUserTeamId] = useAtom(teamIDCheck);
  const [purchasedPackage, setPurchasedPackage] = useAtom(subscribedPackage);
  const [personalAccount, setPersonalAccount] = useAtom(accountType);
  const [isOwnerPackageActive, setIsOwnerPackageActive] =
    useAtom(isOwnerPackage);
  const [userID, setUserID] = useAtom(userIDCheck);
  const { userActions } = useFunctionsCount();

  const getUser = async () => {
    let { status, message, data } = await get(
      GET_USER,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      data.token = loggedUser.token;
      handleStates(data);
    }
  };

  const handleStates = (userData) => {
    const withTeamIdData = userData?.secret_code?.filter(
      (item) => item.team_id !== null
    );

    if (personalAccount) {
      let teamPackage = userData?.package_details?.find(
        (obj) => obj.team_id == null
      );

      setLoggedUser(userData);
      userActions(userData);

      setIsOwnerPackageActive(false);
      setPurchasedPackage(teamPackage);
      setUserTeamId(null);
      setPersonalAccount(true);
      // navigate("/");
    } else {
      // check if two roles and include team owner
      if (userData?.role?.includes("team_owner")) {
        let teamID = withTeamIdData[0]?.team_id;
        let isTeamPackage = userData?.package_details.filter(
          (obj) => obj.team_id == teamID
        );

        let endDate = new Date(isTeamPackage[0]?.end_date);
        let startDate = new Date();

        if (isTeamPackage.length <= 0 || startDate >= endDate) {
          navigate("/paymentNotPaid");
        }

        let teamPackage = userData?.package_details?.find(
          (obj) => obj.team_id == teamID
        );
        setIsOwnerPackageActive(true);
        setPurchasedPackage(teamPackage);
        setUserTeamId(teamID);
        setPersonalAccount(false);
        setUserID(userData?.teams?.user_id);
      } else {
        const isSuspended = userData?.team_members?.some(
          (member) => member.check_status === "suspended"
        );
        if (isSuspended) {
          showToast(
            false,
            "Your account has been suspended by admin, please contact support for further queries"
          );
        } else {
          let teamID = withTeamIdData[0]?.team_id;
          let isTeamPackage = userData?.package_details.filter(
            (obj) => obj.team_id == teamID
          );

          let endDate = new Date(isTeamPackage[0]?.end_date);
          let startDate = new Date();
          if (isTeamPackage.length <= 0 || startDate >= endDate) {
            return showToast(
              false,
              "You can not switch to team mode because your team is not subscribed to any package"
            );
          }
          let teamPackage = userData?.package_details?.find(
            (obj) => obj.team_id == teamID
          );
          setPurchasedPackage(teamPackage);
          setUserTeamId(withTeamIdData[0]?.team_id);
          setPersonalAccount(false);
          // navigate("/");
        }
      }
    }
  };

  const refreshUser = () => {
    getUser();
  };
  return { refreshUser };
};

export default useRefreshUser;
