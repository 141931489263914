import axios from "axios";

export const get = async (url, token = null) => {
  let data = [];
  let message = "";
  let status;

  let headers = {};
  if (token) {
    headers = { Authorization: `Bearer ${token}` };
  }

  await axios
    .get(url, { headers })
    .then((res) => {
      data = res.data.data;
      message = res.data.message;
      status = res.data.success;
    })
    .catch((error) => {
      if (!error.response) return false;
      data = error.response.data.data;
      message = error.response.data.message;
      status = error.response.data.success;
    });

  return { status, message, data };
};

export const post = async (url, payload, token = null) => {
  let data = [];
  let message = "";
  let status;

  let headers = {};
  if (token) {
    headers = { Authorization: `Bearer ${token}` };
  }

  await axios
    .post(url, payload, { headers })
    .then((res) => {
      data = res.data.data;
      message = res.data.message;
      status = res.data.success;
    })
    .catch((error) => {
      if (!error.response) return false;
      data = error.response.data.data;
      message = error.response.data.message;
      status = error.response.data.success;
    });

  return { status, message, data };
};

export const put = async (url, payload, token = null) => {
  let data = [];
  let message = "";
  let status;

  let headers = {};
  if (token) {
    headers = { Authorization: `Bearer ${token}` };
  }

  await axios
    .put(url, payload, { headers })
    .then((res) => {
      data = res.data.data;
      message = res.data.message;
      status = res.data.success;
    })
    .catch((error) => {
      if (!error.response) return false;
      data = error.response.data.data;
      message = error.response.data.message;
      status = error.response.data.success;
    });

  return { status, message, data };
};

export const deleteRecord = async (url, token) => {
  let data = [];
  let message = "";
  let status;

  let headers = {};
  if (token) {
    headers = { Authorization: `Bearer ${token}` };
  }

  await axios
    .delete(url, { headers })
    .then((res) => {
      data = res.data.data;
      message = res.data.message;
      status = res.data.success;
    })
    .catch((error) => {
      if (!error.response) return false;
      data = error.response.data.data;
      message = error.response.data.message;
      status = error.response.data.success;
    });

  return { status, message, data };
};
