import React, { useState, useEffect, useRef } from "react";
import classes from "../../../assets/styles/Pages/Function/ViewMemory.module.css";
import "react-phone-input-2/lib/material.css";
import Loader from "../../../components/Loader";
import Image from "react-bootstrap/Image";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import html2canvas from "html2canvas";
import { MEMORY } from "../../../utils/constants/apiUrl";
import { get, post } from "../../../server";
import { loggedInUser, teamIDCheck } from "../../../utils/store";
import baseURL from "../../../utils/constants/baseUrl";
import { toast } from "react-toastify";
import { useAtom } from "jotai";
import MemoryAddEditModal from "../../../components/Modals/MemoryAddEditModal";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import showToast from "../../../helpers/showToast";
import { mobileViewCheck } from "../../../utils/store";
import QRCodeModal from "../../../components/Modals/QRCodeModal";

const ViewMemoryAdmin = () => {
  const target = useRef(null);
  const navigate = useNavigate();
  const { id, teamId } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const team_id = searchParams.get("team_id");

  const [userTeamId, setUserTeamId] = useAtom(teamIDCheck);
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [loading, setLoading] = useState(true);
  const [selectedData, setSelectedData] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showCopyPopup, setShowCopyPopup] = useState(false);
  const [qrOpen, setQrOpen] = useState(false);
  const [mobileView] = useAtom(mobileViewCheck);
  const [memory, setMemory] = useState({});

  const handleDownload = () => {
    html2canvas(document.querySelector("#qr-code")).then(function (canvas) {
      const link = document.createElement("a");
      link.download = "qrcode.png";
      link.href = canvas.toDataURL();
      link.click();
    });
  };

  const handleCloseModal = () => {
    setSelectedData({});
    setShowModal(false);
  };

  const handleQRClose = () => {
    setQrOpen(false);
    setShowCopyPopup(false);
    setSelectedData("");
  };

  const handleSaveShowModal = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append("media_type", selectedData.media_type);
    formData.append("save_type", selectedData.save_type);
    formData.append("title", selectedData.title);
    formData.append("media", selectedData.media);

    let result = {};
    if (selectedData.id) {
      result = await post(
        MEMORY + `/update/${selectedData.id}`,
        formData,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
    }

    let { status, message } = result;

    if (status) {
      await loadMemory();
      handleCloseModal();
    } else {
      setLoading(false);
    }

    showToast(status, message);
  };

  const loadMemory = async () => {
    let queryParams = "";

    if (teamId || team_id || userTeamId) {
      queryParams = `?team_id=${teamId || team_id || userTeamId}`;
    }

    // if (userId || user_id) {
    //   queryParams += `${queryParams ? "&" : "?"}user_id=${userId || user_id}`;
    // }
    let { status, message, data } = await get(
      `${MEMORY}/${id}${queryParams}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      if (Object.keys(data).length > 0) {
        let objData = data;
        objData = { ...objData, user_id: loggedUser.id };
        setMemory(objData);
      }
    } else {
      // error message
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    loadMemory();
  }, []);

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
      {!loading ? (
        <div className="row justify-content-center">
          <div className="col-12 col-md-6 col-lg-5">
            <div className={classes.mainWrapper}>
              <div className="d-flex align-items-center justify-content-between">
                <h6 className={classes.title} style={{ textAlign: "left" }}>
                  {/* {teamId && userId ? (
                  <Link
                    onClick={handleGoBack}
                    style={{ color: "var(--primary_text_color)" }}
                  >
                    Functions
                  </Link>
                ) : (
                  <Link
                    to="/function"
                    style={{ color: "var(--primary_text_color)" }}
                  >
                    Functions
                  </Link>
                )} */}

                  {`Memory`}
                </h6>
                <button
                  type="button"
                  onClick={handleGoBack}
                  className="bg-transparent p-0 border-0"
                  style={{ color: "var(--primary_text_color)" }}
                >
                  <h6 className="back">{` < Back`}</h6>
                </button>
              </div>
              <div className={classes.cardWrapper}>
                <div
                  className={classes.profileCardWrapper}
                  // style={{ background: "var(--border_color)" }}
                >
                  <div className={classes.detailWrapper}>
                    <h6 className="mb-0">{`${memory.title || ""}`}</h6>
                  </div>
                  {memory.media_type === "image" ? (
                    <>
                      {memory.medias.map((item) => {
                        return (
                          <div key={item.id}>
                            <Image
                              src={
                                baseURL.AWS_URL +
                                "dev/memory/" +
                                item.media_path
                              }
                              width="100%"
                              height="300px"
                              loading="lazy"
                              decoding="async"
                              className="memory-img mt-4"
                            />
                          </div>
                        );
                      })}
                    </>
                  ) : memory.media_type === "audio" ? (
                    <>
                      {memory.medias.map((item) => {
                        return (
                          <div key={item.id}>
                            <audio width="100%" controls className="mt-4 w-100">
                              <source
                                src={
                                  baseURL.AWS_URL +
                                  "dev/memory/" +
                                  item.media_path
                                }
                                type="audio/mpeg"
                              />
                            </audio>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      {memory.medias.map((item) => {
                        return (
                          <div key={item.id}>
                            <video width="100%" controls className="mt-4 w-100">
                              <source
                                src={
                                  baseURL.AWS_URL +
                                  "dev/memory/" +
                                  item.media_path
                                }
                                type="video/mp4"
                              />
                            </video>
                          </div>
                        );
                      })}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}

      {showModal && (
        <MemoryAddEditModal
          show={showModal}
          data={selectedData}
          setSelectedData={setSelectedData}
          handleSaveShowModal={handleSaveShowModal}
          handleCloseModal={handleCloseModal}
          loading={loading}
        />
      )}

      {qrOpen && (
        <QRCodeModal
          qrOpen={qrOpen}
          target={target}
          handleQRClose={handleQRClose}
          setShowCopyPopup={setShowCopyPopup}
          handleDownload={handleDownload}
          functionType="emergencyContact"
          functionUuid={selectedData}
        />
      )}

      <Overlay
        target={target.current}
        show={showCopyPopup}
        placement={mobileView ? "left" : "right"}
      >
        {(props) => (
          <Tooltip id="overlay-example" {...props}>
            Link Copied
          </Tooltip>
        )}
      </Overlay>
      <style type="">
        {`
          .memory-img{
            object-fit:cover;
          }
        `}
      </style>
    </>
  );
};

export default ViewMemoryAdmin;
