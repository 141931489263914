import React, { useState, useEffect, useRef } from "react";
import classes from "../assets/styles/Pages/Function/ViewPublicProfile.module.css";
import "react-phone-input-2/lib/material.css";
import playstore from "../assets/images/footer_icons/playstore.svg";
import appstore from "../assets/images/footer_icons/appstore.svg";
import Image from "react-bootstrap/Image";
import { SHARE_QR } from "../utils/constants/common";
import baseURL from "../utils/constants/baseUrl";
import dummyImage from "../assets/images/global_icons/dummyimage.png";
import formatPhoneNumber from "../helpers/formatPhoneNumber";
import { BiLinkExternal } from "react-icons/bi";
import paymentlogo from "../assets/images/function_page_icons/paymenticon.svg";
import urlicon from "../assets/images/function_page_icons/url.svg";
import uploadIcon from "../assets/images/cloud-arrow.svg";
import FormBuilderLogo from "./Function/FunctionLogo/FormBuilderLogo";
import MyMemoryLogo from "./Function/FunctionLogo/MyMemoryLogo";

const PublicProfile = ({ profile }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const target = useRef(null);

  const functionMappings = [
    { key: "cards", title: "Personal Card", type: "personal" },
    { key: "cards", title: "Business Card", type: "business" },
    { key: "emergencyContacts", title: "Emergency Contact" },
    { key: "customURLs", title: "Custom URL" },
    { key: "fileUploads", title: "File Upload" },
    { key: "payments", title: "Payment" },
    { key: "memory", title: "Memory" },
    { key: "medicalIds", title: "Medical ID" },
    { key: "formBuilders", title: "Form Builder" },
  ];

  // Filter to include only present functions
  const availableFunctions = functionMappings.filter((func) => {
    if (func.key === "cards" && profile[func.key]?.length > 0) {
      return profile[func.key].map((e) => e.card_type).includes(func.type);
    } else {
      return profile[func.key]?.length > 0;
    }
  });

  // Check if only one function has data
  const isSingleItemPresent =
    availableFunctions.reduce((count, func) => {
      return (
        count +
        profile[func.key].filter((item) =>
          func.type ? item.card_type === func.type : true
        ).length
      );
    }, 0) === 1;

  const capitalizeFirstLetter = (string) => {
    if (!string) return string; // Handle null or undefined values
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <>
      <div className="container">
        <div className="py-4">
          <div className="row justify-content-center">
            <div className="col-md-5 col-12">
              <div className={classes.mainWrapper}>
                {/* <h6 className={classes.title}>Profile</h6> */}
                <div className={classes.cardWrapper}>
                  <div className="">
                    <div className={classes.profileCardWrapper}>
                      <div className={`${classes.contactInfo} contactInfo`}>
                        {isSingleItemPresent === false &&
                        availableFunctions.length >= 1 ? (
                          <>
                            {availableFunctions.map(
                              (func, index) => (
                                // (index === 0 || viewMore) && (
                                <div key={func.key} className="functionWrapper">
                                  <h6 className={classes.functionTitle}>
                                    {func.title}
                                  </h6>
                                  <div
                                    className={`${classes.functionInfo} functionInfo`}
                                  >
                                    {profile[func.key]
                                      .filter((item) =>
                                        func.type
                                          ? item.card_type === func.type
                                          : true
                                      )
                                      .map((item, idx) => (
                                        <div
                                          key={idx}
                                          className={`${classes.contactInfoInner} mt-0`}
                                        >
                                          {func.key === "cards" && (
                                            <div
                                              className={`${classes.functionInfoInner} d-flex align-items-center`}
                                            >
                                              <div
                                                className={`${classes.imgWrapper} imgWrapper me-3`}
                                              >
                                                <Image
                                                  className="globalIMageFit"
                                                  src={
                                                    item.profile_image
                                                      ? `${baseURL.PUBLIC_URL}${item.profile_image}`
                                                      : dummyImage
                                                  }
                                                  alt="img"
                                                  roundedCircle={true}
                                                  onLoad={() =>
                                                    setImageLoaded(true)
                                                  }
                                                />
                                              </div>
                                              <div
                                                className={`{classes.detailWrapper}`}
                                              >
                                                {item.first_name ||
                                                item.last_name ? (
                                                  <p
                                                    className={`${classes.subHeading} subHeading`}
                                                  >{`${item.first_name || ""} ${
                                                    item.last_name || ""
                                                  }`}</p>
                                                ) : (
                                                  ""
                                                )}
                                                <a
                                                  href={`mailto:${item.email}`}
                                                  className={
                                                    classes.subHeadingData
                                                  }
                                                >
                                                  {capitalizeFirstLetter(
                                                    item.card_type
                                                  )}
                                                </a>
                                              </div>
                                              {/* {item.email ? (
                                                <div
                                                  className={`${classes.infoContnt} infoContnt`}
                                                  style={{
                                                    borderColor:
                                                      "var(--tertiary_color)",
                                                  }}
                                                >
                                                  <>
                                                    <div>
                                                      <p
                                                        className={`${classes.subHeading} subHeading`}
                                                      >
                                                        Email
                                                      </p>
                                                      <a
                                                        href={`mailto:${item.email}`}
                                                        className={
                                                          classes.subHeadingData
                                                        }
                                                      >
                                                        {item.email}
                                                      </a>
                                                    </div>
                                                  </>
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                              {item.cardPhone[0]
                                                ?.phone_number ? (
                                                <div
                                                  className={`${classes.infoContnt} infoContnt`}
                                                  style={{
                                                    borderColor:
                                                      "var(--tertiary_color)",
                                                  }}
                                                >
                                                  <>
                                                    <div>
                                                      <p
                                                        className={`${classes.subHeading} subHeading`}
                                                      >
                                                        Phone no.
                                                      </p>
                                                      <a
                                                        href={`tel:${item.cardPhone[0]?.phone_number}`}
                                                        className={
                                                          classes.subHeadingData
                                                        }
                                                      >
                                                        {`+${
                                                          item.cardPhone[0]
                                                            ?.country_phone
                                                        }${formatPhoneNumber(
                                                          item.cardPhone[0]
                                                            ?.phone_number
                                                        )}`}
                                                      </a>
                                                    </div>
                                                  </>
                                                </div>
                                              ) : (
                                                ""
                                              )} */}
                                              <button
                                                className={classes.shareBtnIcon}
                                                ref={target}
                                                onClick={() => {
                                                  const shareUrl = `${SHARE_QR}card/${item.uuid}`;
                                                  window.open(
                                                    shareUrl,
                                                    "_blank"
                                                  );
                                                }}
                                              >
                                                <BiLinkExternal />
                                              </button>
                                            </div>
                                          )}
                                          {func.key === "emergencyContacts" && (
                                            <div
                                              className={`${classes.functionInfoInner} d-flex align-items-center`}
                                            >
                                              <div
                                                className={`${classes.imgWrapper} imgWrapper me-3`}
                                              >
                                                <Image
                                                  className="globalIMageFit"
                                                  src={
                                                    item.image
                                                      ? `${baseURL.PUBLIC_URL}${item.image}`
                                                      : dummyImage
                                                  }
                                                  alt="img"
                                                  roundedCircle={true}
                                                  onLoad={() =>
                                                    setImageLoaded(true)
                                                  }
                                                />
                                              </div>
                                              <div>
                                                <p
                                                  className={`${classes.subHeading} subHeading`}
                                                >
                                                  {`${item?.first_name || ""} ${
                                                    item?.last_name || ""
                                                  }`}
                                                </p>
                                                <a
                                                  href={`tel:${item.phone_number}`}
                                                  className={
                                                    classes.subHeadingData
                                                  }
                                                  style={{
                                                    fontSize: "11px",
                                                  }}
                                                >
                                                  {`+${
                                                    item?.country_phone
                                                  }${formatPhoneNumber(
                                                    item?.phone_number
                                                  )}`}
                                                </a>
                                              </div>
                                              <button
                                                className={classes.shareBtnIcon}
                                                ref={target}
                                                onClick={() => {
                                                  const shareUrl = `${SHARE_QR}emergencyContact/${item.uuid}`;
                                                  window.open(
                                                    shareUrl,
                                                    "_blank"
                                                  );
                                                }}
                                              >
                                                <BiLinkExternal />
                                              </button>
                                            </div>
                                          )}
                                          {func.key === "customURLs" && (
                                            <div
                                              className={`${classes.functionInfoInner} d-flex align-items-center`}
                                            >
                                              <div className="icon-circle">
                                                <img src={urlicon} alt="" />
                                              </div>
                                              <div>
                                                <p
                                                  className={`${classes.subHeading} subHeading`}
                                                >
                                                  {item.title}
                                                </p>
                                                <p
                                                  className={
                                                    classes.subHeadingData
                                                  }
                                                >
                                                  {item.url_path}
                                                </p>
                                              </div>
                                              <button
                                                className={classes.shareBtnIcon}
                                                ref={target}
                                                onClick={() => {
                                                  const shareUrl = `${SHARE_QR}customUrl/${item.uuid}`;
                                                  window.open(
                                                    shareUrl,
                                                    "_blank"
                                                  );
                                                }}
                                              >
                                                <BiLinkExternal />
                                              </button>
                                            </div>
                                          )}
                                          {func.key === "fileUploads" && (
                                            <div
                                              className={`${classes.functionInfoInner} d-flex align-items-center`}
                                            >
                                              <div className="icon-circle icon-circle-upload">
                                                <img src={uploadIcon} alt="" />
                                              </div>
                                              <div style={{ width: "auto" }}>
                                                <p
                                                  className={`${classes.subHeading} subHeading`}
                                                >
                                                  {item.title}
                                                </p>
                                                <p
                                                  className={
                                                    classes.subHeadingData
                                                  }
                                                >
                                                  {/* {item.url_path} */}
                                                  {item.url_path.length > 30
                                                    ? `${item.url_path.slice(
                                                        0,
                                                        30
                                                      )}...${item.url_path.slice(
                                                        -10
                                                      )}`
                                                    : item.url_path}
                                                </p>
                                              </div>
                                              <button
                                                className={classes.shareBtnIcon}
                                                ref={target}
                                                onClick={() => {
                                                  const shareUrl = `${SHARE_QR}fileUpload/${item.uuid}`;
                                                  window.open(
                                                    shareUrl,
                                                    "_blank"
                                                  );
                                                }}
                                              >
                                                <BiLinkExternal />
                                              </button>
                                            </div>
                                          )}
                                          {func.key === "payments" && (
                                            <>
                                              {profile.payments.length !== 0 ? (
                                                <div
                                                  className={`${classes.functionInfoInner} d-flex align-items-center`}
                                                >
                                                  <div className="icon-circle">
                                                    <Image
                                                      src={paymentlogo}
                                                      width="30px"
                                                      height="30px"
                                                      roundedCircle={true}
                                                    />
                                                  </div>
                                                  <div>
                                                    <p
                                                      className={`${classes.subHeading} subHeading`}
                                                    >
                                                      {item.title}
                                                    </p>
                                                    <p
                                                      className={
                                                        classes.subHeadingData
                                                      }
                                                    >
                                                      {item.url_path}
                                                    </p>
                                                  </div>
                                                  <button
                                                    className={
                                                      classes.shareBtnIcon
                                                    }
                                                    ref={target}
                                                    onClick={() => {
                                                      const shareUrl = `${SHARE_QR}customPayment/${item.uuid}`;
                                                      window.open(
                                                        shareUrl,
                                                        "_blank"
                                                      );
                                                    }}
                                                  >
                                                    <BiLinkExternal />
                                                  </button>
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                            </>
                                          )}
                                          {func.key === "memory" && (
                                            <div
                                              className={`${classes.functionInfoInner} d-flex align-items-center`}
                                            >
                                              <div className="icon-circle icon-circle-upload">
                                                {/* <img src={urlicon} alt="" /> */}
                                                <MyMemoryLogo
                                                  primaryColor="var(--button_text_color)"
                                                  secondaryColor="var(--primary_button_color)"
                                                />
                                              </div>
                                              <div>
                                                <div
                                                  className={`${classes.subHeading} subHeading`}
                                                >
                                                  {item.title}
                                                </div>
                                                {item.media_type ? (
                                                  <div
                                                    className={`${classes.infoContnt} infoContnt`}
                                                    style={{
                                                      borderColor:
                                                        "var(--tertiary_color)",
                                                    }}
                                                  >
                                                    <>
                                                      <div>
                                                        {/* <p
                                                          className={`${classes.subHeading} subHeading`}
                                                        >
                                                          Media Type
                                                        </p> */}
                                                        <p
                                                          className={
                                                            classes.subHeadingData
                                                          }
                                                        >
                                                          {item.media_type}
                                                        </p>
                                                      </div>
                                                    </>
                                                  </div>
                                                ) : (
                                                  ""
                                                )}
                                                {/* {item.medias_count ? (
                                                  <div
                                                    className={`${classes.infoContnt} infoContnt`}
                                                    style={{
                                                      borderColor:
                                                        "var(--tertiary_color)",
                                                    }}
                                                  >
                                                    <>
                                                      <div>
                                                        <p
                                                          className={`${classes.subHeading} subHeading`}
                                                        >
                                                          Media Count
                                                        </p>
                                                        <p
                                                          className={
                                                            classes.subHeadingData
                                                          }
                                                        >
                                                          {item.medias_count}
                                                        </p>
                                                      </div>
                                                    </>
                                                  </div>
                                                ) : (
                                                  ""
                                                )} */}
                                              </div>
                                              <button
                                                className={classes.shareBtnIcon}
                                                ref={target}
                                                onClick={() => {
                                                  const shareUrl = `${SHARE_QR}memory/${item.uuid}`;
                                                  window.open(
                                                    shareUrl,
                                                    "_blank"
                                                  );
                                                }}
                                              >
                                                <BiLinkExternal />
                                              </button>
                                            </div>
                                          )}
                                          {func.key === "medicalIds" && (
                                            <div
                                              className={`${classes.functionInfoInner} d-flex align-items-center`}
                                            >
                                              <div
                                                className={`${classes.imgWrapper} imgWrapper me-3`}
                                              >
                                                <Image
                                                  className="globalIMageFit"
                                                  src={
                                                    item.photo
                                                      ? `${baseURL.PUBLIC_URL}${item.photo}`
                                                      : dummyImage
                                                  }
                                                  alt="img"
                                                  roundedCircle={true}
                                                  onLoad={() =>
                                                    setImageLoaded(true)
                                                  }
                                                />
                                              </div>
                                              <div>
                                                <p
                                                  className={`${classes.subHeading} subHeading`}
                                                >
                                                  {`${item.first_name || ""} ${
                                                    item.last_name || ""
                                                  }`}
                                                </p>
                                                <p
                                                  className={
                                                    classes.subHeadingData
                                                  }
                                                >
                                                  {item.url_path}
                                                </p>
                                                {item?.blood_type ? (
                                                  <>
                                                    {/* <p
                                                      className={`${classes.subHeadingData} mt-2`}
                                                    >
                                                      Blood type
                                                    </p> */}
                                                    <p
                                                      className={
                                                        classes.subHeadingData
                                                      }
                                                    >
                                                      {item.blood_type}
                                                    </p>
                                                  </>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                              <button
                                                className={classes.shareBtnIcon}
                                                ref={target}
                                                onClick={() => {
                                                  const shareUrl = `${SHARE_QR}medical_id/${item.uuid}`;
                                                  window.open(
                                                    shareUrl,
                                                    "_blank"
                                                  );
                                                }}
                                              >
                                                <BiLinkExternal />
                                              </button>
                                            </div>
                                          )}
                                          {func.key === "formBuilders" && (
                                            <div
                                              className={`${classes.functionInfoInner} d-flex align-items-center`}
                                            >
                                              <div className="icon-circle icon-circle-upload">
                                                {/* <img src={urlicon} alt="" /> */}
                                                <FormBuilderLogo
                                                  primaryColor="var(--heading_text_color)"
                                                  secondaryColor="var(--heading_text_color)"
                                                />
                                              </div>
                                              <p
                                                className={
                                                  classes.subHeadingData
                                                }
                                              >
                                                {item.name}
                                              </p>
                                              <button
                                                className={classes.shareBtnIcon}
                                                ref={target}
                                                onClick={() => {
                                                  const shareUrl = `${SHARE_QR}form_builder/${item.uuid}`;
                                                  window.open(
                                                    shareUrl,
                                                    "_blank"
                                                  );
                                                }}
                                              >
                                                <BiLinkExternal />
                                              </button>
                                            </div>
                                          )}
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              )
                              // )
                            )}
                            {/* {availableFunctions.length > 1 && (
                              <div className="mt-3 text-end">
                                <button
                                  className="btn btn-primary"
                                  onClick={() => setViewMore(!viewMore)}
                                >
                                  {viewMore ? "Show Less" : "Show More"}
                                </button>
                              </div>
                            )} */}
                          </>
                        ) : isSingleItemPresent === true ? (
                          <>
                            {availableFunctions.map((func, index) => (
                              <div key={func.key} className="functionWrapper">
                                {profile[func.key].map((item, idx) => {
                                  if (item?.function_type === "card") {
                                    window.location.href = `${SHARE_QR}card/${item.uuid}`;
                                  } else if (
                                    item?.function_type === "emergency_contact"
                                  ) {
                                    window.location.href = `${SHARE_QR}emergencyContact/${item.uuid}`;
                                  } else if (
                                    item?.function_type === "custom_url"
                                  ) {
                                    window.location.href = `${SHARE_QR}customUrl/${item.uuid}`;
                                  } else if (
                                    item?.function_type === "file_upload"
                                  ) {
                                    window.location.href = `${SHARE_QR}fileUpload/${item.uuid}`;
                                  } else if (item?.function_type === "memory") {
                                    window.location.href = `${SHARE_QR}memory/${item.uuid}`;
                                  } else if (
                                    item?.function_type === "medical_id"
                                  ) {
                                    window.location.href = `${SHARE_QR}medical_id/${item.uuid}`;
                                  } else if (
                                    item?.function_type === "form_builder"
                                  ) {
                                    window.location.href = `${SHARE_QR}form_builder/${item.uuid}`;
                                  } else {
                                    window.location.href = `${SHARE_QR}customPayment/${item.uuid}`;
                                  }
                                  return (
                                    <div
                                      key={idx}
                                      className={`${classes.contactInfoInner} mt-0`}
                                    >
                                      <p className="deactivetext">Loading...</p>
                                    </div>
                                  );
                                })}
                              </div>
                            ))}
                          </>
                        ) : (
                          <div className="text-center">
                            <p className="deactivetext">
                              <strong>You have no active function</strong>
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className={classes.cardFooter}>
                  <div className="d-flex align-items-center gap-3 justify-content-center">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.contactco.contactapp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={playstore} alt="" />
                    </a>
                    <a
                      href="https://apps.apple.com/us/app/contact-co-connect-smarter/id6683301913"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={appstore} alt="" />
                    </a>
                  </div>
                  <p>
                    © {new Date().getFullYear()} Contact Co, All rights
                    reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style type="text/css">{`
      .deactivetext{
        color: #fff
      }
        .imgWrapper{
          display: inline-block;
          position:relative;
        }
        .globalIMageFit{
          width:50px !important;
          height:50px !important;
        }
        .globalIMageFitSmall{
          width:30px !important;
          height:30px !important;
          position:absolute;
          bottom:-3px;
          right:-10px;
        }
          .icon-circle{
              width: 50px !important;
    height: 50px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--primary_text_color);
    border-radius: 50px;
    background: var(--primary_button_color);
    margin-right: 16px;
          }
    .icon-circle-upload img, .icon-circle-upload svg{
        width:30px
    }
      `}</style>
    </>
  );
};

export default PublicProfile;
