import React, { useState } from "react";
import classes from "../../../assets/styles/Pages/CouponManagement/CouponManagement.module.css";
import { useAtom } from "jotai";
import { loggedInUser } from "../../../utils/store";
import Loader from "../../../components/Loader";
import SettingsInput from "../../../components/Forms/SettingsInput";
import Button from "react-bootstrap/Button";
import "react-datepicker/dist/react-datepicker.css";
import { get } from "../../../server";
import _ from "lodash";
import { SYSTEM_GENERATE_COUPON } from "../../../utils/constants/apiUrl";
import showToast from "../../../helpers/showToast";
import { useParams, useNavigate } from "react-router-dom";

const CouponQuantity = () => {
  let { id } = useParams();
  let emptyObj = {
    id: "",
    name: "",
    couponCodeText: [],
    discount: "",
    start: "",
    end: "",
    package_id: "",
    duration_type: "",
    quantity: "",
    limit: "",
  };
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [createCoupons, setCreateCoupons] = useState(emptyObj);
  const [systemGenerateCoupon, setSystemGenerateCoupon] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e, i) => {
    const name = e.target.name;
    const value = e.target.value;
    setCreateCoupons({ ...createCoupons, [name]: value });
  };
  const handleSystemGenerateCoupon = async (e) => {
    setLoading(true);
    if (systemGenerateCoupon === true) {
      const quantity = createCoupons.quantity;
      const queryString = `?quantity=${quantity}`;
      let { status, message, data } = await get(
        SYSTEM_GENERATE_COUPON + queryString,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );

      if (status) {
        navigate("/coupons/addEditCoupons", {
          state: {
            quantity: createCoupons.quantity,
            couponCodeText: data.couponCodeText,
          },
        });
      } else {
        showToast(status, message);
      }
    } else {
      navigate("/coupons/addEditCoupons", {
        state: { quantity: createCoupons.quantity },
      });
    }
    setLoading(false);
  };

  return (
    <>
      {!loading ? (
        <div>
          <div className="page_Header">
            <div className="d-flex justify-content-between align-items-md-center align-items-initial flex-md-row flex-column gap-3">
              <p className="pageTitle"> {id ? "Edit" : "Add"} Coupon</p>
            </div>
          </div>
          <div className={classes.content}>
            <div className={`mainWrapper ${classes.outerWrapper}`}>
              <div className="row align-items-center gx-5 gy-3">
                <div className="col-md-4 col-12">
                  <SettingsInput
                    label="Quantity"
                    type="text"
                    placeholder="Enter coupons quantity"
                    name="quantity"
                    handleChange={handleChange}
                    error={errors.quantity}
                  />
                </div>
                <div className="col-md-4 col-12">
                  <label>
                    <input
                      type="checkbox"
                      className="me-2"
                      onChange={() =>
                        setSystemGenerateCoupon(!systemGenerateCoupon)
                      } // Toggle checkbox state
                    />
                    System Generate Coupon
                  </label>
                </div>
              </div>
              <div className="formBottom">
                <Button
                  className="submitButton"
                  type="button"
                  onClick={handleSystemGenerateCoupon}
                >
                  Next
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default CouponQuantity;
