import React, { useEffect, useState, useMemo } from "react";
import classes from "../../assets/styles/Pages/CouponManagement/CouponManagement.module.css";
import { useAtom } from "jotai";
import { loggedInUser, teamIDCheck } from "../../utils/store";
import Loader from "../../components/Loader";
import "react-datepicker/dist/react-datepicker.css";
import { get, post } from "../../server";
import {
  LEADS,
  EXPORT_LEAD,
  IMPORT_LEAD,
  ALL_PRODUCT_DISCOUNT_LINK,
} from "../../utils/constants/apiUrl";
import showToast from "../../helpers/showToast";
import { Link } from "react-router-dom";
import DataTable, { createTheme } from "react-data-table-component";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useParams } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
const ProductDiscountList = () => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [userTeamId, setUserTeamId] = useAtom(teamIDCheck);
  const [loading, setLoading] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [errors, setErrors] = useState({});
  const [leads, setLeads] = useState([]);
  const [exportLeads, setExportLeads] = useState();
  const [activeTab, setActiveTab] = useState("all");
  let { id } = useParams();
  const handleFilter = (e) => {
    setFilterText(e.target.value);
  };

  const loadAllDiscount = async () => {
    setLoading(true);
    const payload = {
      user_id: loggedUser.id,
      team_id: userTeamId,
    };
    let { status, message, data } = await post(
      ALL_PRODUCT_DISCOUNT_LINK,
      payload,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      setLeads(data);
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadAllDiscount();
  }, []);

  return (
    <>
      {!loading ? (
        <div>
          <div className="page_Header">
            <div className="d-flex justify-content-between align-items-md-center align-items-initial flex-md-row flex-column gap-3">
              <p className="pageTitle mb-0">Product Discount</p>
            </div>
          </div>

          <div className={`listingWrapper mainWrapper p-4`}>
            {leads.length > 0 ? (
              <p>
                {leads[0]?.description}{" "}
                <a
                  href={leads[0]?.link}
                  target="_blank"
                  rel="noreferrer"
                  className="link"
                >
                  Click Here
                </a>
              </p>
            ) : (
              <p>No Link found</p>
            )}
          </div>
        </div>
      ) : (
        <Loader />
      )}
      <style type="text/css">
        {`
          .customSubscriptionTabs{
            padding:20px;
          }
          .customSubscriptionTabs button{
            background: var(--accent_color) !important;
          }
          .customSubscriptionTabs button.active{
            background: var(--primary_button_color) !important;
          }
          .secondary_button {
            padding: 6px 15px;
            background: var(--primary_color);
            border-radius: 7px;
            color: var(--primary_text_color) !important;
            transition: all 0.3s ease;
            font-weight: 500;
            font-size: 12px;
            border: 0;
            white-space: nowrap;
            width:100%;
          }
          .mainWrapper .primary_button {
            padding: 6px 15px;
            border-radius: 7px;
            font-size: 12px;
            width:100%;
          }
          .link{
            color:var(--primary_button_color)
          }
        `}
      </style>
    </>
  );
};

export default ProductDiscountList;
