const functionTypes = {
  CARD: "card",
  PAYMENT: "payment",
  FILE_UPLOAD: "file_upload",
  EMERGENCY_CONTACT: "emergency_contact",
  CUSTOM_URL: "custom_url",
  FORM_BUILDER: "form_builder",
  MEMORY: "memory",
  MEDICAL_ID: "medical_id",
  DYNAMIC_QR_CODE: "dynamic_qr_code",
  DYNAMIC_QR_CODE_FUNCTION_LIMIT: "dynamic_qr_code_each_function",
  CONNECTION: "connection",
  ADD: "add",
  DELETE: "delete",
};

export default functionTypes;
