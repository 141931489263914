import React, { useState, useEffect, useMemo } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from "react-router-dom";
import DataTable, { createTheme } from "react-data-table-component";
import { useAtom } from "jotai";
import classes from "../../assets/styles/Contact.module.css";
import dummyImage from "../../assets/images/global_icons/dummyimage.svg";
import {
  USER,
  TEAMDETAIL,
  ASSIGN_MEMBER_ROLE,
} from "../../utils/constants/apiUrl";
import { get, deleteRecord, post } from "../../server";
import showToast from "../../helpers/showToast";
import { loggedInUser, teamIDCheck } from "../../utils/store";
import Loader from "../../components/Loader";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import FormInput from "../../components/Forms/FormInput";
import searchIcon from "../../assets/images/global_icons/searchicon.svg";
import formatPhoneNumber from "../../helpers/formatPhoneNumber";
import { Image } from "react-bootstrap";
import baseURL from "../../utils/constants/baseUrl";

const TeamMembers = () => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [userTeamId, setUserTeamId] = useAtom(teamIDCheck);
  const [loading, setLoading] = useState(true);

  const [teamMembers, setTeamMembers] = useState([]);
  const [deleteId, setDeleteId] = useState(null);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [filterText, setFilterText] = useState("");

  const handleFilter = (e) => {
    setFilterText(e.target.value);
  };
  const getTeamMembers = async () => {
    setLoading(true);
    let { status, message, data } = await get(
      `${TEAMDETAIL}/${
        Object.keys(loggedUser).length !== 0 ? userTeamId : null
      }`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      const teamMembersData = data.team_members.map((member) => ({
        ...member.user_data,
        team_id: member.team_id,
        user_id: member.user_id,
        is_admin: member.is_admin,
        member_id: member.id,
      }));
      setTeamMembers(teamMembersData);
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  useEffect(() => {
    getTeamMembers();
  }, []);

  const filteredItems = useMemo(() => {
    const filtered = teamMembers.filter(
      (item) =>
        `${item.first_name || ""} ${item.last_name || ""}`
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
        `${item.email || ""}`
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
        `${item.city || ""}`.toLowerCase().includes(filterText.toLowerCase()) ||
        `${item.country || ""}`
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
        `${item.country_phone ? `+${item.country_phone}` : ""}${
          item.phone_number || ""
        }`.includes(filterText) ||
        `${item.is_admin == 1 ? "yes" : "no" || ""}`
          .toLowerCase()
          .includes(filterText.toLowerCase())
    );

    return filtered;
  }, [filterText, teamMembers]);

  createTheme(
    "solarized",
    {
      background: {
        default: "background: var(--secondary_color)",
      },
    },
    "dark"
  );

  const columns = [
    {
      name: "Name",
      width: "30%",
      cell: (row) => (
        <div className={classes.profileWrapper}>
          <Image
            src={`${baseURL.PUBLIC_URL}${row.profile_image}` || dummyImage}
            roundedCircle={true}
            loading="lazy"
            decoding="async"
            width="50px"
            height="50px"
            onError={(e) => {
              e.currentTarget.src = dummyImage;
            }}
          />
          <div>
            <p className="globalName">
              {row.first_name} {row.last_name}
            </p>
          </div>
        </div>
      ),
    },
    {
      name: "Email",
      width: "15%",
      cell: (row) => (
        <a
          href={`mailto:${row.email}`}
          className="globalDetail"
          style={{ display: "block" }}
        >
          {row.email}
        </a>
      ),
    },
    {
      name: "Phone Number",
      width: "15%",
      cell: (row) => (
        <>
          {row.phone_number ? (
            <a
              href={`tel:${
                row.country_phone
                  ? "+" +
                    row.country_phone +
                    " " +
                    formatPhoneNumber(row.phone_number)
                  : row.phone_number || ""
              }`}
              className="globalDetail"
            >
              {row.country_phone
                ? "+" +
                  row.country_phone +
                  " " +
                  formatPhoneNumber(row.phone_number)
                : row.phone_number || ""}
            </a>
          ) : (
            <p className="globalDetail">-</p>
          )}
        </>
      ),
    },
    {
      width: "20%",
      name: "City, Country",
      cell: (row) => (
        <>
          {row.city || row.country ? (
            <a
              href={`http://maps.google.com/?q=${row.city || ""}${
                row.city && row.country ? ", " : ""
              }${row.country || " "}`}
              className="globalDetail"
              target="_blank"
              rel="noreferrer"
            >
              {row.city || ""}
              {row.city && row.country ? "," : ""} {row.country}
            </a>
          ) : (
            <p className="globalDetail">-</p>
          )}
        </>
      ),
    },
    {
      name: "Is Admin",
      width: "10%",
      cell: (row) => (
        <p className="globalDetail">{row.is_admin ? "Yes" : "No"}</p>
      ),
    },
    {
      name: "Action",
      width: "10%",
      right: "true",
      cell: (row) => (
        <div className={classes.action}>
          <button
            className="btn customActionDropdown dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <BsThreeDotsVertical className={"globalVerticalThreeDots"} />
          </button>
          <ul className="customActionDropdownMenu dropdown-menu dropdown-menu-end ">
            <li>
              <Link
                className="dropdown-item"
                to={`/team/teamMembers/addEditTeamMember/${row.user_id}`}
              >
                Edit
              </Link>
            </li>
            <li>
              <Link
                className="dropdown-item"
                to={`/team/${row.team_id}/teamMembers/member/${row.user_id}/function`}
              >
                Functions
              </Link>
            </li>
            <li>
              <span
                className="dropdown-item"
                onClick={() => handleMemberAdmin(row)}
              >
                {row.is_admin ? "Remove as Admin" : "Mark as Admin"}
              </span>
            </li>
            <li>
              <span
                className="dropdown-item"
                style={{ color: "#E35252" }}
                onClick={() => handleDelete(row.id)}
              >
                Remove from team
              </span>
            </li>
          </ul>
        </div>
      ),
    },
  ];
  // Handle confirmation modal
  const handleShowConfirmationModal = () => setShowConfirmationModal(true);
  const handleCloseConfirmationModal = () => setShowConfirmationModal(false);

  // handle delete
  const handleDelete = (id) => {
    setDeleteId(id);
    handleShowConfirmationModal(true);
  };

  const handleConfirmDelete = async () => {
    handleCloseConfirmationModal(true);
    setLoading(true);

    let { status, message } = await deleteRecord(
      `${USER}/${deleteId}?team_id=${userTeamId}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    showToast(status, message);

    await getTeamMembers();
    setLoading(false);
  };

  // Handle member admin Role
  const handleMemberAdmin = async (data) => {
    setLoading(true);
    let { status, message } = await post(
      `${ASSIGN_MEMBER_ROLE}/${data.member_id}`,
      { make_admin: !data.is_admin },
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    showToast(status, message);
    await getTeamMembers();
    setLoading(false);
  };

  return (
    <>
      {!loading ? (
        <div className="">
          <div className="page_Header">
            <div className="d-flex justify-content-between align-items-md-center align-items-initial flex-md-row flex-column gap-3">
              <p className="pageTitle">
                Team Members <span>({teamMembers.length})</span>
              </p>
              <div className="d-flex align-items-center justify-content-between gap-2">
                <Link
                  className={classes.addMemberButton}
                  to={`/team/teamMembers/addEditTeamMember`}
                >
                  Add Member
                </Link>
                <FormInput
                  icon={searchIcon}
                  placeholder="Search..."
                  value={filterText}
                  handleChange={handleFilter}
                />
              </div>
            </div>
          </div>
          <div className={`listingWrapper mainWrapper`}>
            <DataTable
              columns={columns}
              data={filteredItems}
              pagination
              theme="solarized"
            />
          </div>
          <ConfirmationModal
            show={showConfirmationModal}
            handleCloseModal={handleCloseConfirmationModal}
            handleConfirmDelete={handleConfirmDelete}
            confirmTitle="user"
            isRemoveMemberMessage={true}
          />
        </div>
      ) : (
        <Loader />
      )}

      <style type="text/css">
        {`
          .profileWrapper {
            display: flex;
            align-items: center;
            gap: 20px;
          }
          .profileWrapper img {
            border: 2px solid var(--primary_text_color);
          }

	      `}
      </style>
    </>
  );
};

export default TeamMembers;
