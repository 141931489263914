import React, { useState, useRef } from "react";
import { Image, Modal } from "react-bootstrap";
import qrcode from "../../../assets/images/function_page_icons/qrcode.svg";
import classes from "../../../assets/styles/PageComponentsStyles/Home/BusinessCard.module.css";
import { BsThreeDots } from "react-icons/bs";
import bgImage from "../../../assets/images/home_page_images/bgImage-blank.png";
import baseUrl from "../../../utils/constants/baseUrl";
import { Link } from "react-router-dom";
import CardView from "../Function/Card";
import { AddMoreCustomUrls } from "../../../utils/data/CardFunctionsData";
import { MdClose } from "react-icons/md";
import html2canvas from "html2canvas";
import { get, post } from "../../../server";
import { loggedInUser, mobileViewCheck } from "../../../utils/store";
import { useAtom } from "jotai";
import {
  DUPLICATE_Card_URL,
  FUNCTION_STATUS,
} from "../../../utils/constants/apiUrl";
import showToast from "../../../helpers/showToast";
import _ from "lodash";
import pictureicon from "../../../assets/images/function_page_icons/pictureicon.svg";
import companylogo from "../../../assets/images/function_page_icons/companylogo.svg";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import ShareModal from "../../Modals/ShareModal";
import QRCodeModal from "../../Modals/QRCodeModal";
import useFunctionsCount from "../../../hooks/useFunctionsCount";
import functionTypes from "../../../utils/constants/functionTypes";
import Form from "react-bootstrap/Form";

const BusinessCard = ({
  id,
  handleDelete,
  card_type,
  first_name,
  last_name,
  email,
  company,
  company_position,
  logo,
  background_image,
  profile_image,
  color,
  country_phone,
  phone_number,
  description,
  address,
  country,
  city,
  state,
  website_url,
  status,
  uuid,
  cardSocialMedia,
  cardListing,
  setCardListing,
  pronouns,
  cardPhone,
  teamId,
  userId,
  team_id,
  user_id,
  publicMode,
  content_view_type,
  profile_image_shape,
  logo_shape,
  function_type,
  loadCard,
  qrCodeCustomize,
  qr_image,
  qr_bg_color,
  qr_front_color,
  is_expired,
  dynamicQRCodeId,
}) => {
  let name = `${first_name ? first_name : ""} ${last_name ? last_name : ""}`;

  const [open, setOpen] = useState(false);
  const [qrOpen, setQrOpen] = useState(false);
  const [duplicateOpen, setDuplicateOpen] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);
  const [mobileView] = useAtom(mobileViewCheck);

  const { compareLimits, updateCount } = useFunctionsCount();

  const [loggedUser] = useAtom(loggedInUser);

  const target = useRef(null);

  const [show, setShow] = useState(false);

  const [data, setData] = useState({
    id: id,
    card_type: card_type,
    first_name: first_name,
    last_name: last_name,
    email: email,
    company: company,
    company_position: company_position,
    logo: logo,
    background_image: background_image,
    profile_image: profile_image,
    color: color,
    country_phone: country_phone,
    phone_number: phone_number,
    description: description,
    address: address,
    country: country,
    city: city,
    state: state,
    website_url: website_url,
    status: status,
    card_social_media: cardSocialMedia,
    pronouns: pronouns,
    card_phone: cardPhone,
    content_view_type: content_view_type,
    profile_image_shape: profile_image_shape,
    logo_shape: logo_shape,
    function_type: function_type,
    qrCodeCustomize: qrCodeCustomize,
    qr_image: qr_image,
    qr_bg_color: qr_bg_color,
    qr_front_color: qr_front_color,
    dynamic_qr_code_id: dynamicQRCodeId,
  });

  const handleOpen = () => {
    let newCardSocialMedia = [];

    data.card_social_media.forEach((item) => {
      let foundIcon = AddMoreCustomUrls.find(
        (single) =>
          single.name === item.media_type || single.oldName === item.media_type
      );

      newCardSocialMedia.push({
        media_type: item.media_type,
        url: item.url,
        icon: item.media_type === "custom" ? "custom" : foundIcon?.icon,
        custom_label: item.custom_label,
      });
    });

    setData({ ...data, card_social_media: newCardSocialMedia });

    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleQrOpen = () => {
    setQrOpen(true);
  };

  const handleQRClose = () => {
    setQrOpen(false);
    setShow(false);
  };

  const handleDuplicateOpen = () => {
    const isLimitExceeded = compareLimits(functionTypes.CARD);
    if (isLimitExceeded) {
      showToast(false, "Limit exceeded, Please Upgrade your plan");
    } else {
      setDuplicateOpen(true);
    }
  };

  const handleDuplicateClose = () => {
    setDuplicateOpen(false);
  };

  const handleShareOpen = () => {
    setShareOpen(true);
  };

  const handleShareClose = () => {
    setShareOpen(false);
    setShow(false);
  };

  const handleDownload = () => {
    html2canvas(document.querySelector("#qr-code")).then(function (canvas) {
      const link = document.createElement("a");
      link.download = "qrcode.png";
      link.href = canvas.toDataURL();
      link.click();
    });
  };

  const handleDuplicate = async () => {
    const { status, message, data } = await get(
      `${DUPLICATE_Card_URL}/${id}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      updateCount(functionTypes.CARD, functionTypes.ADD, dynamicQRCodeId);
      let newCards = _.cloneDeep(cardListing);
      newCards.unshift(data);
      setCardListing(newCards);

      handleDuplicateClose();
    }

    showToast(status, message);
  };
  const handleStatus = async () => {
    let newStatus = data.status == true ? 0 : 1;
    let queryParams = "";
    if (dynamicQRCodeId) {
      queryParams += `?dynamic_qr_code_id=${dynamicQRCodeId}`;
    }
    const payload = {
      id: id,
      status: newStatus,
      type: function_type,
    };

    let { status, message } = await post(
      //FUNCTION_STATUS,
      `${FUNCTION_STATUS}${queryParams}`,
      payload,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      setData((prevData) => ({
        ...prevData,
        status: !prevData.status,
      }));
      loadCard();
    }
  };

  return (
    <>
      <div className={classes.mainWrapper}>
        <div className={classes.threeDotsDiv}>
          {is_expired === 0 ? (
            <Form.Check
              type="switch"
              checked={status}
              onChange={handleStatus}
            />
          ) : (
            <Form.Check
              type="switch"
              checked={status}
              onChange={handleStatus}
              disabled
            />
          )}
          <button
            className="btn customActionDropdown dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <BsThreeDots className={classes.threeDots} />
          </button>
          {publicMode ? (
            <ul className="customActionDropdownMenu dropdown-menu dropdown-menu-start ">
              <li>
                <span className="dropdown-item" onClick={handleOpen}>
                  View
                </span>
              </li>

              <li>
                <span className="dropdown-item" onClick={handleQrOpen}>
                  QR Code
                </span>
              </li>
            </ul>
          ) : is_expired === 0 ? (
            <ul className="customActionDropdownMenu dropdown-menu dropdown-menu-start ">
              <li>
                <span className="dropdown-item" onClick={handleOpen}>
                  View
                </span>
              </li>
              {dynamicQRCodeId !== null ? (
                ""
              ) : (
                <>
                  <li>
                    <span
                      className="dropdown-item"
                      onClick={handleDuplicateOpen}
                    >
                      Duplicate
                    </span>
                  </li>
                  <li>
                    <span className="dropdown-item" onClick={handleQrOpen}>
                      QR Code
                    </span>
                  </li>
                </>
              )}
              <li>
                <Link
                  className="dropdown-item"
                  to={
                    team_id && user_id && dynamicQRCodeId !== null
                      ? `/${id}?team_id=${team_id}&user_id=${user_id}&dynamic_qr_code=${dynamicQRCodeId}`
                      : teamId && userId
                      ? `/team/${teamId}/teamMembers/member/${userId}/function/cardfunctions/${id}`
                      : `/function/cardfunctions/${id}${
                          team_id && user_id
                            ? `?team_id=${team_id}&user_id=${user_id}`
                            : dynamicQRCodeId !== null
                            ? `?dynamic_qr_code=${dynamicQRCodeId}`
                            : ""
                        }`
                  }
                >
                  Edit
                </Link>
              </li>
              <li>
                <span className="dropdown-item" onClick={handleStatus}>
                  {data.status === true ? "Inactive" : "Active"}
                </span>
              </li>
              {dynamicQRCodeId !== null ? (
                ""
              ) : (
                <li>
                  <span className="dropdown-item" onClick={handleShareOpen}>
                    Share
                  </span>
                </li>
              )}
              <li>
                <button
                  className="dropdown-item"
                  href="#"
                  style={{ color: "var(--error_color)", cursor: "pointer" }}
                  onClick={() => handleDelete(id, "business")}
                >
                  Delete
                </button>
              </li>
            </ul>
          ) : (
            <ul className="customActionDropdownMenu dropdown-menu dropdown-menu-start ">
              <li>
                <button
                  className="dropdown-item"
                  href="#"
                  style={{ color: "var(--error_color)", cursor: "pointer" }}
                  onClick={() => handleDelete(id, "business")}
                >
                  Delete
                </button>
              </li>
            </ul>
          )}
        </div>
        <div className={classes.imgWrapper}>
          <div className={classes.coverImg}>
            <Image
              src={
                background_image
                  ? baseUrl.PUBLIC_URL + background_image
                  : bgImage
              }
              onError={(e) => {
                e.currentTarget.src = bgImage;
              }}
              loading="lazy"
              decoding="async"
            />
          </div>
          {/* <div className={classes.profileImg}> */}
          <div
            className={`${classes.profileImg} ${
              data?.profile_image_shape
                ? data.profile_image_shape
                : classes.defaultShape
            } profileImg`}
          >
            <Image
              src={
                profile_image ? baseUrl.PUBLIC_URL + profile_image : pictureicon
              }
              style={profile_image ? null : { border: "none" }}
              onError={(e) => {
                e.currentTarget.src = pictureicon;
              }}
              loading="lazy"
              decoding="async"
            />
          </div>
          {logo ? (
            // <div className={classes.logoWrapper}>
            <div
              className={`${classes.logoWrapper} ${
                data?.logo_shape ? data.logo_shape : classes.defaultShape
              } logoWrapper`}
            >
              <Image
                src={logo ? baseUrl.PUBLIC_URL + logo : companylogo}
                alt="logo-img"
                loading="lazy"
                decoding="async"
                onError={(e) => {
                  e.currentTarget.src = companylogo;
                }}
              />
            </div>
          ) : null}
        </div>

        <div className={classes.infoWrapper}>
          <div className={`col-8 ${classes.leftSide}`}>
            <div className={classes.name}>{name}</div>
            <div className={classes.profession}>{company_position}</div>
          </div>
          {/* <MdQrCode /> */}
          {is_expired === 0 ? (
            <img
              src={qrcode}
              alt=""
              className={classes.qrCode}
              onClick={handleQrOpen}
            />
          ) : (
            <img
              src={qrcode}
              alt=""
              className={classes.qrCode}
              //onClick={handleQrOpen}
            />
          )}
        </div>
      </div>

      <Modal
        show={open}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        // className={`customModal`}
        dialogClassName="rightAlignedModal"
      >
        <CardView
          data={data}
          showSidebar={true}
          handleClose={handleClose}
          handleQrOpen={handleQrOpen}
          handleShareOpen={handleShareOpen}
          shareOpen={shareOpen}
          showCountryCodeSeparate={true}
          inViewMode={true}
          publicMode={publicMode}
        />
      </Modal>

      <Modal
        show={duplicateOpen}
        onHide={handleDuplicateClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={`customModal`}
        size="sm"
      >
        <Modal.Header>
          <div className="modalGradientCircle"></div>
          <MdClose onClick={handleDuplicateClose} className="modalCloseBtn" />
        </Modal.Header>

        <Modal.Body
          style={{ padding: "0" }}
          className={`d-flex justify-content-center flex-column align-items-center`}
        >
          <div
            className="d-flex flex-column align-items-center justify-content-center"
            style={{ width: "fit-content" }}
          >
            <h5>Are you sure?</h5>
            <p style={{ textAlign: "center" }}>
              Are you sure you want to
              <br />
              duplicate this Card?
            </p>
          </div>

          <div
            className={`customModalBtnWrapper ${classes.btnWrapper}`}
            style={{ marginTop: "80px" }}
          >
            <button onClick={handleDuplicate}>Duplicate</button>
          </div>
        </Modal.Body>
      </Modal>

      <style type="text/css">
        {`
         .listDesign {
           //background-color: #e1e17d;
         }
        
         .gridDesign .contactInfoInner {
           display: flex;
           flex-wrap: wrap;
           justify-content:space-between;
         }
         .gridDesign .infoContnt {
           width: 48%;
           flex: 0 0 auto;
           padding: 0.5rem;
           display: block;id
           text-align: center;
           border:1px solid var(--tertiary_color);
           background:#fff;
           border-radius: 5px;
         }
         .treeDesign .contactInfoInner h6, .treeDesign .contactInfoInner .infoContnt{
           position:relative;
         }
         .gridDesign .infoContnt a{
          //  white-space:normal;
          //  display: inline-flex;
          word-break: break-word;
         }
         .gridDesign .contactInfo h6{
           width:100%;
         }
         .gridDesign .moreInfoIcon{
           max-width:38px;
           max-height:38px;
           margin:0 0 10px;
         }
         .treeDesign .contactInfoInner{position:relative;padding-left:15px}
         .treeDesign .contactInfoInner:before{
           content:"";
           border-left: 1px solid #B2B2B2;
           height: 100%;
           position: absolute;id
           top: 13px;
           left: 0px;
         }
        
         .treeDesign .contactInfoInner h6{
           background-color: #DBDBDB;
           padding: 0.2rem 0.75rem;
           display:inline-block;
         }
         .treeDesign .contactInfoInner h6:before{
           content:"";
           width: 15px;
           position: absolute;
           left: -15px;
           top: 0px;
           bottom: 0;
           height: 1px;
           margin: auto;id
           position: absolute;
           height: 1px;
           background-color: #B2B2B2;
           left: -15px;
           top: 0;
           bottom: 0;
           margin: auto;
         }
         .treeDesign .contactInfoInner .infoContnt:before{
           content:"";
           width: 15px;
           position: absolute;
           height: 1px;
           background-color: #B2B2B2;
           left: -15px;
           top: 0;
           bottom: 0;
           margin: auto;
         }
         .treeDesign .contactInfoInner .infoContnt:after{
           content:"";
           width: 5px;
           height: 5px;
           background-color: #B2B2B2;
           border-radius: 50%;
           position: absolute;
           left: -2.4%;
         }
         .iconDesign .contactInfoInner{
           display:flex;
           flex-wrap:wrap;
         }
         .iconDesign .contactInfo h6{
           width:100%;
         }
         .iconDesign .contactInfoInner .infoContnt{
           width:30.33333%;
           flex: 0 0 auto;
           margin-right:3%;
         }
         .iconDesign .contactInfoInner .infoContnt .moreInfoIcon{
           display:none;
         }
         .iconDesign .contactInfoInner .infoContnt .subHeading{
           display:none;
         }
         .profileImg.circle div,
         .profileImg.circle img {
           border-radius: 50%;
           transition: 0.5s all ease;
         }
         .profileImg.square div,
         .profileImg.square img {
           border-radius: 2px;
           transition: 0.5s all ease;
         }
         .profileImg.rectangular div,
         .profileImg.rectangular img {
           border-radius: 2px;
           width: 90px;
           height: 65px;
           transition: 0.5s all ease;
         }
         .logoWrapper.circle div,
         .logoWrapper.circle img {
           border-radius: 50%;
           transition: 0.5s all ease;
         }
         .logoWrapper.square div,
         .logoWrapper.square img {
           border-radius: 2px;
           transition: 0.5s all ease;
         }
         .logoWrapper.rectangular div,
         .logoWrapper.rectangular img {
           border-radius: 2px;
           width: 50px;
           height: 25px;
           transition: 0.5s all ease;
         }
       `}
      </style>

      {qrOpen && (
        <QRCodeModal
          qrOpen={qrOpen}
          target={target}
          functionType="card"
          functionUuid={uuid}
          handleQRClose={handleQRClose}
          setShowCopyPopup={setShow}
          handleDownload={handleDownload}
          id={data.id}
          function_type={data.function_type}
          functionId={data.id}
          QRCodeCustomize={data.qrCodeCustomize}
          refreshData={loadCard}
        />
      )}
      {shareOpen && (
        <ShareModal
          shareOpen={shareOpen}
          target={target}
          handleShareClose={handleShareClose}
          setShowCopyPopup={setShow}
          functionType="card"
          functionUuid={uuid}
        />
      )}

      <Overlay
        target={target.current}
        show={show}
        placement={mobileView ? "left" : "right"}
      >
        {(props) => (
          <Tooltip id="overlay-example" {...props}>
            Link Copied
          </Tooltip>
        )}
      </Overlay>
    </>
  );
};

export default BusinessCard;
