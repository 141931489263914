import {
  loggedInUser,
  globalAppSetting,
  teamIDCheck,
  accountType,
  userIDCheck,
  userRemainingFunctions,
  memberRemainingFunctions,
  subscribedPackage,
  isOwnerPackage,
  userRemainingFunctionsForDynamicQR,
} from "../utils/store";
import { LOGOUT } from "../utils/constants/apiUrl";
import { useAtom } from "jotai";
import { useNavigate } from "react-router-dom";
import { post } from "../server";
import { logoutFbApi } from "../utils/functions";

const useLogout = () => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [appSetting, setAppSetting] = useAtom(globalAppSetting);
  const [userID, setUserID] = useAtom(userIDCheck);
  const [personalAccount, setPersonalAccount] = useAtom(accountType);
  const [userTeamId, setUserTeamId] = useAtom(teamIDCheck);
  const [userAvailableFunctions, setUserAvailableFunctions] = useAtom(
    userRemainingFunctions
  );
  const [memberAvailableFunctions, setMemberAvailableFunctions] = useAtom(
    memberRemainingFunctions
  );
  const [
    userAvailableFunctionsForDynamicQR,
    setUserAvailableFunctionsForDynamicQR,
  ] = useAtom(userRemainingFunctionsForDynamicQR);
  const [purchasedPackage, setPurchasedPackage] = useAtom(subscribedPackage);
  const [isOwnerPackageActive, setIsOwnerPackageActive] =
    useAtom(isOwnerPackage);

  const navigate = useNavigate();

  const logout = () => {
    logoutFbApi();
    post(
      LOGOUT,
      [],
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    setLoggedUser({});
    setAppSetting({});
    setUserID(null);
    setPersonalAccount(true);
    setUserTeamId(null);
    setPurchasedPackage({});
    setIsOwnerPackageActive(false);
    setUserAvailableFunctions({
      total_cards: 0,
      total_connections: 0,
      total_payments: 0,
      total_emergency_contacts: 0,
      total_custom_urls: 0,
      total_file_uploads: 0,
      total_form_builders: 0,
      total_memory: 0,
      total_dynamic_qr_codes: 0,
    });

    setMemberAvailableFunctions({
      total_cards: 0,
      total_connections: 0,
      total_payments: 0,
      total_emergency_contacts: 0,
      total_custom_urls: 0,
      total_file_uploads: 0,
      total_form_builders: 0,
      total_memory: 0,
      total_dynamic_qr_codes: 0,
    });

    setUserAvailableFunctionsForDynamicQR({
      total_cards: 0,
      total_connections: 0,
      total_payments: 0,
      total_emergency_contacts: 0,
      total_custom_urls: 0,
      total_file_uploads: 0,
      total_form_builders: 0,
      total_memory: 0,
      total_dynamic_qr_codes: 0,
    });

    navigate("/login");
  };

  return logout;
};

export default useLogout;
