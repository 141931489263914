import React, { useEffect, useState } from "react";
import classes from "../../assets/styles/Pages/Settings/AddNewPage.module.css";
import { get, post, put } from "../../server";
import { PAGES } from "../../utils/constants/apiUrl";
import { loggedInUser } from "../../utils/store";
import { useAtom } from "jotai";
import Loader from "../../components/Loader";
import showToast from "../../helpers/showToast";
import { useParams, useNavigate } from "react-router-dom";
import AddEditPageSchema from "../../utils/SchemasValidation/AddEditPageSchema";
// utils/SchemasValidation/AddEditPageSchema";
import ValidateSchema from "../../helpers/ValidateSchema";
// ../../helpers";
import Button from "react-bootstrap/Button";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const AddNewPage = () => {
  let emptyObj = {
    description: "",
    page_type: "",
    title: "",
  };

  const { pageId } = useParams();
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [page, setPage] = useState(emptyObj);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setPage({ ...page, [name]: value });
  };

  // handle Validation
  const validateForm = () => {
    let newErrors = ValidateSchema(page, AddEditPageSchema);
    setErrors(newErrors);
    if (Object.keys(newErrors).length) return false;
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setLoading(true);
    if (pageId) {
      let { status, message } = await put(
        PAGES + `/${pageId}`,
        page,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
      if (status) {
        navigate("/pages");
      }
      showToast(status, message);
    } else {
      let { status, message } = await post(
        PAGES,
        page,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
      if (status) {
        navigate("/pages");
      }
      showToast(status, message);
    }
    setLoading(false);
  };

  const loadSinglePage = async () => {
    setLoading(true);
    let { status, message, data } = await get(
      PAGES + `/${pageId}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      setPage(data);
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (pageId) loadSinglePage();
  }, []);
  return (
    <>
      {!loading ? (
        <div>
          <h6>{pageId ? "Edit The Page" : "Add New Page"}</h6>
          <div className={`mainWrapper ${classes.outerWrapper}`}>
            <div className={classes.inputsWrapper}>
              <div>
                <input
                  type="text"
                  placeholder="Page Title"
                  name="title"
                  value={page.title}
                  className={
                    errors?.title ? "globalInput errorInput" : "globalInput"
                  }
                  onChange={handleChange}
                />
                <p className="errorText">{errors?.title || ""}</p>
              </div>
              <div>
                <div
                  className={
                    errors?.page_type
                      ? "input-group globalInputWithIcon errorInput"
                      : "input-group globalInputWithIcon"
                  }
                >
                  <select
                    className={
                      errors?.page_type
                        ? "form-select customSelect errorInput"
                        : "form-select customSelect"
                    }
                    name="page_type"
                    value={page.page_type}
                    onChange={handleChange}
                  >
                    <option value="">Select Page Type</option>
                    <option value="about_us">About Us</option>
                    <option value="privacy">Privacy</option>
                    <option value="term_condition">Term & Condition</option>
                    <option value="exchange_policy">Exchange Policy</option>
                    <option value="intellectual_property">
                      Intellectual Property
                    </option>
                  </select>
                </div>
                <p className="errorText">{errors?.page_type || ""}</p>
              </div>
            </div>
            <div
              className={
                errors?.description ? "ck-content errorInput" : "ck-content"
              }
            >
              <CKEditor
                editor={ClassicEditor}
                config={{
                  removePlugins: [
                    "EasyImage",
                    "ImageUpload",
                    "MediaEmbed",
                    "Indent",
                    "Table",
                    "Essentials",
                  ],
                }}
                data={page.description}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setPage({ ...page, description: data });
                }}
              />
            </div>

            <p className="errorText">{errors?.description || ""}</p>
            <div className="formBottom">
              <Button
                className="submitButton"
                type="submit"
                onClick={handleSubmit}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default AddNewPage;
