import React, { useEffect, useState, useMemo } from "react";
import classes from "../../../assets/styles/Pages/Subscriptions/ManageSubscriptions.module.css";
import { useAtom } from "jotai";
import { loggedInUser } from "../../../utils/store";
import Loader from "../../../components/Loader";
import FormInput from "../../../components/Forms/FormInput";
import searchIcon from "../../../assets/images/global_icons/searchicon.svg";
import {
  SUBSCRIPTIONS_PACKAGES,
  PACKAGES_REMOVE,
  PACKAGES_STATUS,
} from "../../../utils/constants/apiUrl";
import { Link } from "react-router-dom";
import DataTable, { createTheme } from "react-data-table-component";
import { BsThreeDotsVertical } from "react-icons/bs";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal";
import { get, post } from "../../../server";
import showToast from "../../../helpers/showToast";
import { Tab, Tabs } from "react-bootstrap";
const ManageSubscriptions = () => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [loading, setLoading] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [packages, setPackages] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [statusId, setStatusId] = useState(null);
  const [activeTab, setActiveTab] = useState("customer_specific_personal");

  const handleFilter = (e) => {
    setFilterText(e.target.value);
  };
  const handleTabChange = (selectedTab) => {
    setActiveTab(selectedTab);
    loadPackages(selectedTab);
  };
  const loadPackages = async (package_type) => {
    setLoading(true);
    let url = `${SUBSCRIPTIONS_PACKAGES}?type=${package_type}`;
    let { status, message, data } = await get(
      url,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      if (data.length > 0) {
        setPackages(data);
        const status = data.map((statusValue) => statusValue.status);
        setStatusId(status);
      }
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  const filteredItems = useMemo(() => {
    const filtered = packages.filter(
      (item) =>
        `${item.name || ""}`.toLowerCase().includes(filterText.toLowerCase()) ||
        `${item.coupon || ""}`
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
        `${item.discount || ""}`.includes(filterText)
    );

    return filtered;
  }, [filterText, packages]);

  createTheme(
    "solarized",
    {
      background: {
        default: "background: var(--secondary_color)",
      },
    },
    "dark"
  );

  const columns = [
    {
      name: "Company Name",
      width: "200px",
      cell: (row) => <p className="globalDetail">{row.company_name}</p>,
    },
    {
      name: "Package Name",
      width: "150px",
      cell: (row) => <p className="globalDetail">{row.package_name}</p>,
    },
    {
      name: "Creation Date",
      width: "200px",
      //cell: (row) => <p className="globalDetail">{row.creation_date}</p>,
      cell: (row) => {
        const creationDate = new Date(row.creation_date);
        const formattedDate = creationDate.toLocaleString();
        return <p className="globalDetail">{formattedDate}</p>;
      },
    },
    {
      name: "Max User Limit",
      width: "150px",
      cell: (row) => <p className="globalDetail">{row.user_limit}</p>,
    },
    {
      name: "Status",
      width: "200px",
      //cell: (row) => <p className="globalDetail">{row.status}</p>,
      cell: (row) => {
        const statusText = row.status === 1 ? "Active" : "Inactive";
        return <p className="globalDetail">{statusText}</p>;
      },
    },
    {
      name: "Action",
      width: "100px",
      right: "true",
      cell: (row, index) => {
        return (
          <div className={classes.action}>
            <button
              className="btn customActionDropdown dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <BsThreeDotsVertical className={"globalVerticalThreeDots"} />
            </button>
            <ul className="customActionDropdownMenu dropdown-menu dropdown-menu-end ">
              <li>
                <Link className="dropdown-item" to={`addEditPackage/${row.id}`}>
                  Edit
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item"
                  to="#"
                  onClick={() => updateStatus(row.id)}
                >
                  {/* Inactive */}
                  {statusId[index] === 1 ? "Inactive" : "Active"}
                </Link>
              </li>
              <li>
                <Link
                  className="dropdown-item"
                  to="#"
                  style={{ color: "#E35252" }}
                  onClick={() => handleDelete(row.id)}
                >
                  Delete
                </Link>
              </li>
            </ul>
          </div>
        );
      },
    },
  ];

  // Handle confirmation modal
  const handleShowConfirmationModal = () => setShowConfirmationModal(true);
  const handleCloseConfirmationModal = () => setShowConfirmationModal(false);

  // handle delete
  const handleDelete = (packageId) => {
    setDeleteId(packageId);
    handleShowConfirmationModal(true);
  };

  const handleConfirmDelete = async () => {
    handleCloseConfirmationModal(true);
    setLoading(true);
    const queryString = `?package_id=${deleteId}`;
    let { status } = await post(
      PACKAGES_REMOVE + queryString,
      {},
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      await loadPackages();
      setLoading(false);
    }
    setLoading(false);
  };
  const updateStatus = async (packageId) => {
    setLoading(true);
    setStatusId(packageId);
    let newStatus = 1;
    const packageIndex = packages.findIndex((pkg) => pkg.id === packageId);
    if (packageIndex !== -1) {
      if (statusId[packageIndex] === 1) {
        newStatus = 0;
      }
    }
    const payload = {
      package_id: packageId,
      status: newStatus,
    };
    let { status } = await post(
      PACKAGES_STATUS,
      payload,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      await loadPackages();
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadPackages(activeTab);
  }, []);

  return (
    <>
      {!loading ? (
        <div>
          <div className="page_Header">
            <div className="d-flex justify-content-between align-items-md-center align-items-initial flex-md-row flex-column gap-3">
              <p className="pageTitle">Manage Subscriptions</p>
              <div className="d-flex align-items-center justify-content-between gap-2">
                <FormInput
                  icon={searchIcon}
                  placeholder="Search..."
                  value={filterText}
                  handleChange={handleFilter}
                />
                <Link to="/manageSubscriptions/addEditPackage">
                  <button className="primary_button">Add Package</button>
                </Link>
              </div>
            </div>
          </div>
          <div className={`listingWrapper mainWrapper`}>
            <div className={classes.tabsWrapper}>
              <Tabs
                defaultActiveKey="customer_specific_personal"
                activeKey={activeTab}
                className="customSubscriptionTabs"
                transition={false}
                onSelect={handleTabChange}
                justify
              >
                <Tab
                  eventKey="customer_specific_personal"
                  title="User Specific Package"
                >
                  <DataTable
                    columns={columns}
                    data={filteredItems}
                    pagination
                    theme="solarized"
                  />
                </Tab>
                <Tab
                  eventKey="customer_specific_owner"
                  title="Team Specific Package"
                >
                  <DataTable
                    columns={columns}
                    data={filteredItems}
                    pagination
                    theme="solarized"
                  />
                </Tab>
              </Tabs>
            </div>
          </div>

          <ConfirmationModal
            show={showConfirmationModal}
            handleCloseModal={handleCloseConfirmationModal}
            handleConfirmDelete={handleConfirmDelete}
            confirmTitle="coupon"
          />
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default ManageSubscriptions;
