import React, { useState, useEffect, useMemo } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from "react-router-dom";
import DataTable, { createTheme } from "react-data-table-component";
import { useAtom } from "jotai";
import classes from "../../../assets/styles/Contact.module.css";
import { Card_URL } from "../../../utils/constants/apiUrl";
import { get, deleteRecord } from "../../../server";
import showToast from "../../../helpers/showToast";
import { loggedInUser } from "../../../utils/store";
import Loader from "../../../components/Loader";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal";
import FormInput from "../../../components/Forms/FormInput";
import searchIcon from "../../../assets/images/global_icons/searchicon.svg";

const AllCards = () => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [loading, setLoading] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const [cards, setCards] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const handleFilter = (e) => {
    setFilterText(e.target.value);
  };

  const loadCards = async () => {
    setLoading(true);
    let { status, message, data } = await get(
      Card_URL,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      if (data.length > 0) {
        setCards(data);
      }
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadCards();
  }, []);

  const filteredItems = useMemo(() => {
    const filtered = cards.filter(
      (item) =>
        item.card_type.toLowerCase().includes(filterText.toLowerCase()) ||
        `${item.first_name || ""} ${item.last_name || ""}`
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
        `${item.country_phone ? `+${item.country_phone}` : ""}${
          item.phone_number || ""
        }`.includes(filterText) ||
        `${item?.user_data?.first_name || ""} ${
          item?.user_data?.last_name || ""
        }`
          .toLowerCase()
          .includes(filterText.toLowerCase())
    );

    return filtered;
  }, [filterText, cards]);

  createTheme(
    "solarized",
    {
      background: {
        default: "background: var(--secondary_color)",
      },
    },
    "dark"
  );

  const columns = [
    {
      name: "Card Type",
      width: "200px",
      cell: (row) => (
        <div className="profileWrapper">
          <p className="globalDetail">{row.card_type.toUpperCase()}</p>
        </div>
      ),
    },
    {
      name: "Name",
      width: "200px",
      cell: (row) => (
        <div className={"globalDetail"}>
          {row.first_name} {row.last_name}
        </div>
      ),
    },
    // {
    //   name: "Phone #",
    //   width: "20%",
    //   cell: (row) => <div className={"globalDetail"}>{row.phone_number}</div>,
    // },
    {
      name: "Username",
      width: "200px",
      cell: (row) => (
        <div className={"globalDetail"}>
          {row?.user_data?.first_name} {row?.user_data?.last_name}
        </div>
      ),
    },
    {
      name: "Created in",
      width: "150px",
      cell: (row) => (
        <div className={"globalDetail"}>
          {row.team_detail !== null ? "Team Mode" : "Individual"}
        </div>
      ),
    },
    {
      name: "Team Name",
      width: "200px",
      cell: (row) => (
        <div className={"globalDetail"}>
          {row.team_detail !== null ? row?.team_detail.name : "-"}
        </div>
      ),
    },

    {
      name: "Action",
      right: "true",
      cell: (row) => (
        <div className={classes.action}>
          <button
            className="btn customActionDropdown dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <BsThreeDotsVertical className={"globalVerticalThreeDots"} />
          </button>
          <ul className="customActionDropdownMenu dropdown-menu dropdown-menu-end ">
            <li>
              <Link className="dropdown-item" to={`ViewCard/${row.id}`}>
                View
              </Link>
            </li>
            <li>
              <Link className="dropdown-item" to={`AddEditCard/${row.id}`}>
                Edit
              </Link>
            </li>
            <li>
              <Link
                className="dropdown-item"
                to="#"
                style={{ color: "var(--error_color)" }}
                onClick={() => handleDelete(row.id)}
              >
                Delete
              </Link>
            </li>
          </ul>
        </div>
      ),
    },
  ];

  // Handle confirmation modal
  const handleShowConfirmationModal = () => setShowConfirmationModal(true);
  const handleCloseConfirmationModal = () => setShowConfirmationModal(false);

  // handle delete
  const handleDelete = (id) => {
    setDeleteId(id);
    handleShowConfirmationModal(true);
  };

  const handleConfirmDelete = async () => {
    handleCloseConfirmationModal(true);
    setLoading(true);
    let { status, message } = await deleteRecord(
      `${Card_URL}/${deleteId}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    showToast(status, message);

    if (status) {
      await loadCards();
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <>
      {!loading ? (
        <div className="">
          <div className="page_Header">
            {/* <Link to="AddEditAdminUser">
                <button className="primary_button align_right">Add</button>
              </Link> */}
            <div className="d-flex justify-content-between align-items-md-center align-items-initial flex-md-row flex-column gap-3">
              <p className="pageTitle">
                Cards <span>({cards.length})</span>
              </p>
              <FormInput
                icon={searchIcon}
                placeholder="Search..."
                value={filterText}
                handleChange={handleFilter}
              />
            </div>
          </div>

          <div className={`listingWrapper mainWrapper`}>
            <DataTable
              columns={columns}
              data={filteredItems}
              paginationResetDefaultPage={resetPaginationToggle}
              pagination
              theme="solarized"
            />
          </div>

          <ConfirmationModal
            show={showConfirmationModal}
            handleCloseModal={handleCloseConfirmationModal}
            handleConfirmDelete={handleConfirmDelete}
            confirmTitle="card"
          />
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default AllCards;
