import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import classes from "../../assets/styles/CustomURLModal.module.css";
import { MdClose } from "react-icons/md";

const ContactUsStatusModal = ({
  loading,
  data,
  setSelectedData,
  handleSaveShowModal,
  handleCloseModal,
  show,
}) => {
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setSelectedData({ ...data, [name]: value });
  };

  // // handle Validation
  // const validateForm = () => {
  //   let newErrors = ValidateSchema(data, AddCustomURLSchema);
  //   setErrors(newErrors);
  //   if (Object.keys(newErrors).length) return false;
  //   return true;
  // };

  const handleAddUrl = async (e) => {
    handleSaveShowModal(data);
  };

  // Handle Update
  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          handleCloseModal();
          setErrors({});
        }}
        className={`customModal`}
        size="sm"
      >
        <Modal.Header className={classes.modalHeader}>
          <span className={classes.modalHeading}>Change Status</span>
          <div className="modalGradientCircle"></div>
          <MdClose
            onClick={() => {
              handleCloseModal();
              setErrors({});
            }}
            className="modalCloseBtn"
          />
        </Modal.Header>
        <>
          <Modal.Body>
            <div style={{ marginTop: "-140px" }}>
              <div className={classes.selectWrapper}>
                <label
                  htmlFor=""
                  style={{
                    fontSize: "12px",
                    color: "#fff",
                    marginBottom: "10px",
                  }}
                >
                  Status
                </label>
                <div className="input-group globalInputWithIcon">
                  <select
                    className="form-select customSelect"
                    name="status"
                    value={data.status}
                    onChange={handleChange}
                  >
                    <option value="pending">Pending</option>
                    <option value="in_progress">In Progress</option>
                    <option value="completed">Completed</option>
                    <option value="cancelled">Cancelled</option>
                  </select>
                </div>
                <span className="errorText">{errors.status}</span>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <div className={classes.btnWrapper}>
              <Button
                type="button"
                onClick={() => {
                  handleCloseModal();
                  setErrors({});
                }}
                className={classes.cancelBtn}
              >
                Cancel
              </Button>

              <Button
                type="button"
                onClick={handleAddUrl}
                className={classes.saveBtn}
              >
                Save
              </Button>
            </div>
          </Modal.Footer>
        </>
      </Modal>
    </>
  );
};

export default ContactUsStatusModal;
