const features = [
  { id: 1, text: "Cards", url: "/function/cards" },
  { id: 2, text: "Venmo", url: "/function/paymentListing" },
  { id: 3, text: "Cash App", url: "/function/paymentListing" },
  { id: 4, text: "Emergency Contact", url: "/function/emergencycontacts" },
  { id: 5, text: "Custom URL's", url: "/function/customurlslisting" },
  { id: 6, text: "File Upload", url: "/function/fileupload" },
];
const shop = [
  {
    id: 1,
    text: "Visit Our Store",
    url: "https://contactco.com/collections/all-products",
  },
];
const legal = [
  { id: 1, text: "Terms & Condition", url: "/help/termsandcondition" },
  { id: 2, text: "Privacy Policy", url: "/help/privacyandpolicy" },
  { id: 3, text: "Intellectual Property", url: "/help/intellectualproperty" },
];
const contact = [
  { id: 1, text: "About Us", url: "/help/aboutus" },
  { id: 2, text: "Help & Support", url: "/help" },
  { id: 3, text: "FAQ's", url: "/help/helpFaqs" },
  { id: 4, text: "Intellectual Property", url: "/help/intellectualproperty" },
];

export { features, shop, legal, contact };
