import Home from "./pages/Home";
import Contact from "./pages/Contact";
import EditContact from "./pages/EditContact";
import CreateManualContact from "./pages/CreateManualContact";
import Function from "./pages/Function/Function";
import Settings from "./pages/Settings/Settings";
import Leads from "./pages/Lead/Leads";
import ViewLead from "./pages/Lead/ViewLead";
import ProductDiscountList from "./pages/ProductDiscount/ProductDiscountList";
import Help from "./pages/Help/Help";
import HelpTutorial from "./pages/Help/Tutorial";
import HelpFaqs from "./pages/Help/Faqs";
import ContactUsFaqs from "./pages/Help/ContactUs";
import CardFunctions from "./pages/CardFunctions";
import Cards from "./pages/Card";
import EditCardFunctions from "./pages/EditCardFunctions";
import ViewEmergencyContact from "./pages/Function/ViewEmergencyContact";
import DashboardLayout from "./layout/DashboardLayout";
import EmergencyContacts from "./pages/Function/EmergencyContacts";
import AboutUs from "./pages/Help/AboutUs";
import Pages from "./pages/Settings/Pages";
import AllPackageContacts from "./pages/admin/package/AllPackageContacts";
import AddEditPackageContact from "./pages/admin/package/AddEditPackageContact";
import AddNewPage from "./pages/Settings/AddNewPage";
import NotificationListing from "./pages/admin/notification/NotificationListing";
import SendNotification from "./pages/admin/notification/SendNotification";
import AppSettings from "./pages/admin/setting/AppSettings";
import ManageSubscriptions from "./pages/admin/subscriptions/ManageSubscriptions";
import ActiveSubscriptions from "./pages/admin/subscriptions/ActiveSubscriptions";
import AddEditPackage from "./pages/admin/subscriptions/AddEditPackage";
import AllQRCode from "./pages/admin/generateQRCode/AllQRCode";
import AddEditQRCode from "./pages/admin/generateQRCode/AddEditQRCode";
import BatchQRCode from "./pages/admin/generateQRCode/BatchQRCode";
import AssignQRCode from "./pages/admin/generateQRCode/AssignQRCode";
import Pricing from "./pages/admin/pricing/AllPricing";
import AddEditPricing from "./pages/admin/pricing/AddEditPricing";
import Tutorial from "./pages/admin/tutorial/AllTutorial";
import AddEditTutorial from "./pages/admin/tutorial/AddEditTutorial";
import AdminUser from "./pages/admin/adminUser/AllAdminUsers";
import AddEditAdminUser from "./pages/admin/adminUser/AddEditAdminUser";
import ContactUs from "./pages/admin/contactUs/AllContactUs";
import AddEditContactUs from "./pages/admin/contactUs/AddEditContactUs";
import AllCustomUrls from "./pages/admin/customUrl/AllCustomUrls";
import AllVenmos from "./pages/admin/venmo/AllVenmos";
import AllCashApps from "./pages/admin/cashApp/AllCashApps";
import AllEmergencyContacts from "./pages/admin/emergencyContact/AllEmergencyContacts";
import AllMemory from "./pages/admin/memory/AllMemory";
import AllFormBuilder from "./pages/admin/formBuilder/AllFormBuilder";
import AddEditFormBuilder from "./pages/admin/formBuilder/AddEditFormBuilder";
import AllCards from "./pages/admin/cards/AllCards";
import AddEditCard from "./pages/admin/cards/AddEditCard";
import Faqs from "./pages/admin/faqs/AllFaqs";
import AddEditFaqs from "./pages/admin/faqs/AddEditFaqs";
import Users from "./pages/admin/user/AllUsers";
import AddEditUser from "./pages/admin/user/AddEditUser";
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import { get, put } from "./server";
import {
  PACKAGES_LISTING,
  GET_USER_ACTION,
  THEME_GET,
} from "./utils/constants/apiUrl";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CardView from "./pages/CardView";
import EmergencyContactsListing from "./pages/Function/EmergencyContacts";
import CustomURLSListing from "./pages/Function/CustomURLSListing";
import MemoryListing from "./pages/Function/MemoryListing";
import MedicalListing from "./pages/Function/MedicalListing";
import ViewMemory from "./pages/Function/ViewMemory";
import ViewMedicalId from "./pages/Function/ViewMedicalId";
import CashAppListing from "./pages/Function/CashAppListing";
import VenmoListing from "./pages/Function/VenmoListing";
import { useAtom } from "jotai";
import {
  loggedInUser,
  isOwnerPackage,
  teamIDCheck,
  userIDCheck,
  subscriptionPackages,
  subscribedPackage,
  accountType,
  globalThemeColor,
  globalButtonColor,
} from "./utils/store";
import RequireAuth from "./components/RequireAuth";
import CheckTeamOwnerPackage from "./components/CheckTeamOwnerPackage";
import Layout from "./layout/Layout";
import Missing from "./components/Missing";
import Checkout from "./components/Checkout";
import { Routes, Route, useParams, Navigate } from "react-router-dom";
import Unauthorized from "./components/Unauthorized";
import HelpContactUs from "./pages/Help/ContactUs";
import ForgotPassword from "./pages/ForgotPassword";
import ScrollToTop from "./components/ScrollToTop";
import TermsAndCondition from "./pages/Help/TermsAndCondition";
import PrivacyAndPolicy from "./pages/Help/PrivacyAndPolicy";
import ExchangePolicy from "./pages/Help/ExchangePolicy";
import PublicCard from "./pages/PublicCard";
import PublicContact from "./pages/PublicContact";
import ViewContact from "./pages/ViewContact";
import PublicEmergencyContact from "./pages/PublicEmergencyContact";
import PublicAlreadyMemberSetup from "./pages/PublicAlreadyMemberSetup";
import PublicUserNFC from "./components/PageComponents/Function/PublicUserNFC";
import AppleAppSiteAssociation from "./pages/apple-app-site-association";
import IntellectualProperty from "./pages/Help/IntellectualProperty";
import FileUpload from "./pages/Function/FileUplaod";
import AllFileUpload from "./pages/admin/fileUpload/AllFileUpload";
import AllMedicalId from "./pages/admin/medicalId/AllMedicalId";
import AllPayments from "./pages/admin/payments/AllPayments";
import PaymentListing from "./pages/Function/PaymentListing";
import TeamMembers from "./pages/Team/TeamMembers";
import AddEditTeamMember from "./pages/Team/AddEditTeamMember";
import AllTeamOwners from "./pages/admin/team/AllTeamOwners";
import TeamDetail from "./pages/admin/team/TeamDetail";
import EditTeamMember from "./pages/admin/team/EditTeamMember";
import Invitations from "./pages/Team/Invitations";
import PendingMemberPayments from "./pages/Team/PendingMemberPayments";
import Team from "./pages/Team/Team";
import ManageNodes from "./pages/Team/ManageNodes";
import ViewHierarchy from "./pages/Team/ViewHierarchy";
import EditTeamOwner from "./pages/admin/team/EditTeamOwner";
import Subscription from "./pages/Subscription";
import SubscriptionIndividual from "./pages/SubscriptionIndividual";
import PublicNodes from "./pages/Team/PublicNodes";
import PublicFunctions from "./pages/Team/PublicFunctions";
import React, { useEffect, useState } from "react";
import PublicTeamNode from "./pages/Team/PublicTeamNode";
import Stats from "./pages/Team/Stats";
import UserStats from "./pages/Stats";
import PublicFunctionsQR from "./pages/PublicFunctionsQR";
import showToast from "./helpers/showToast";
import { getUserSubscriptionPlan } from "./utils/functions";
import SubscriptionPackages from "./components/PageComponents/Settings/Subscription";
import PackageContactForm from "./pages/PackageContactForm";
import PaymentNotPaid from "./components/PaymentNotPaid";
import SocialSignup from "./pages/SocialSignup";
import useFunctionsCount from "./hooks/useFunctionsCount";
import useRefreshUser from "./hooks/useRefreshUser";
import CheckSubscriptionExpiry from "./components/CheckSubscriptionExpiry";
import AllCoupons from "./pages/admin/CouponManagement/AllCoupons";
import AddEditCoupons from "./pages/admin/CouponManagement/AddEditCoupons";
import EditCoupons from "./pages/admin/CouponManagement/EditCoupons";
import CouponQuantity from "./pages/admin/CouponManagement/CouponQuantity";
import AllProductDiscount from "./pages/admin/ProductDiscount/AllProductDiscount";
import AddEditProductDiscount from "./pages/admin/ProductDiscount/AddEditProductDiscount";
import AllProductDiscountLink from "./pages/admin/ProductDiscountLink/AllProductDiscountLink";
import AddEditProductDiscountLink from "./pages/admin/ProductDiscountLink/AddEditProductDiscountLink";
import QRCodePage from "./pages/QRCode/QRCode";
import AddEditQRCodePage from "./pages/QRCode/AddEditQRCodePage";
import FormBuilder from "./pages/Function/FormBuilder";
import FormBuilderListing from "./pages/Function/FormBuilderListing";
import EmailTemplate from "./pages/Function/EmailTemplate";
import _ from "lodash";
import ViewCoupons from "./pages/admin/CouponManagement/ViewCoupons";
import ViewEmergencyContactAdmin from "./pages/admin/emergencyContact/ViewEmergencyContactAdmin";
import ViewFormBuilder from "./pages/admin/formBuilder/ViewFormBuilder";
import ViewCard from "./pages/admin/cards/ViewCard";
import ViewMedicalIdAdmin from "./pages/admin/medicalId/ViewMedicalIdAdmin";
import ViewMemoryAdmin from "./pages/admin/memory/ViewMemoryAdmin";
function App() {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [userId, setUserId] = useAtom(userIDCheck);
  const [userTeamId, setUserTeamId] = useAtom(teamIDCheck);
  const [loading, setLoading] = useState(false);
  const [userID, setUserID] = useAtom(userIDCheck);
  const [purchasePackages, setPurchasePackages] = useAtom(subscriptionPackages);
  const [purchasedPackage, setPurchasedPackage] = useAtom(subscribedPackage);
  const [personalAccount, setPersonalAccount] = useAtom(accountType);
  const { userActions } = useFunctionsCount();
  const { refreshUser } = useRefreshUser();

  const fetchAllPckages = async () => {
    let queryParams = "";

    if (loggedUser?.id) {
      queryParams = `?user_id=${loggedUser.id}&team_id=${userTeamId}`;
    }
    let { status, data, message } = await get(
      `${PACKAGES_LISTING}${queryParams}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    //let { status, message, data } = await get(PACKAGES_LISTING);
    if (status) {
      setPurchasePackages(data);
    } else {
      showToast(status, message);
    }
  };

  useEffect(() => {
    if (loggedUser && Object.keys(loggedUser).length > 0) {
      refreshUser();
    }
  }, []);

  useEffect(() => {
    if (loggedUser && Object.keys(loggedUser).length > 0) {
      if (loggedUser.role?.includes("team_admin") && !personalAccount) {
        const teamData = loggedUser?.secret_code?.filter(
          (item) => item.team_id !== null
        );

        setUserTeamId(teamData?.[0]?.team_id);
        setUserID(loggedUser?.connected_teams?.[0]?.user_id);
      }

      fetchAllPckages();
    }
  }, [loggedUser, personalAccount]);

  useEffect(() => {
    if (loggedUser?.package_details?.length > 0) {
      const packageWithTeam = loggedUser?.package_details?.filter(
        (obj) => obj.team_id !== null
      );

      const packageWithoutTeam = loggedUser?.package_details?.filter(
        (obj) => obj.team_id === null
      );

      if (!personalAccount) {
        const subcribedPackage = getUserSubscriptionPlan(
          packageWithTeam?.[0]?.package_id,
          purchasePackages
        );
        if (subcribedPackage) {
          setPurchasedPackage(subcribedPackage);
        } else {
          setPurchasedPackage({});
        }
      } else {
        const subcribedPackage = getUserSubscriptionPlan(
          packageWithoutTeam?.[0]?.package_id,
          purchasePackages
        );
        if (subcribedPackage) {
          setPurchasedPackage(subcribedPackage);
        } else {
          setPurchasedPackage({});
        }
      }
    }
  }, [purchasePackages]);

  const ROLES = {
    User: "customer",
    SuperAdmin: "super_admin",
    SubAdmin: "sub_admin",
    TeamOwner: "team_owner",
    TeamAdmin: "team_admin",
  };
  // const [theme, setTheme] = useState("light");
  // const toggleTheme = () => {
  //   if (theme === "light") {
  //     setTheme("dark");
  //   } else {
  //     setTheme("light");
  //   }
  // };

  const RedirectToSettings = ({ loggedUser, children }) => {
    if (loggedUser?.is_new_user === 1) {
      return <Navigate to="/settings" replace />; // Redirect to settings if is_new_user is 1
    }
    return children; // Otherwise, render the children (home page, etc.)
  };

  return (
    // <div className={`${theme}`}>
    <div>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Layout />}>
          {/* PuBLIC ROUTES */}
          <Route element={<Signup />} path="signup" />
          <Route element={<Login />} path="login" />
          <Route element={<SocialSignup />} path="socialSignup" />

          <Route
            element={<PublicFunctionsQR />}
            path="share/:functionType/:uuid"
          />

          <Route element={<ForgotPassword />} path="forgotpassword" />
          <Route
            element={<PackageContactForm />}
            path="PackageContactForm/:packageId"
          />
          {/* <Route element={<PublicCard />} path="share/card/:uuid" />
          <Route
            element={<PublicEmergencyContact />}
            path="share/emergencyContact/:uuid"
          />*/}
          {/* <Route element={<PublicContact />} path="share/contact/:uuid" /> */}
          <Route element={<PublicNodes />} path="qr/node/:uuid" />
          <Route element={<PublicTeamNode />} path="qr/team/:teamId" />

          <Route
            element={<PublicFunctions />}
            path="publicFunctions/:teamId/:userId"
          />
          <Route
            element={<AppleAppSiteAssociation />}
            path=".well-known/apple-app-site-association"
          />

          <Route element={<PublicAlreadyMemberSetup />} path="alreadyMember" />

          <Route element={<PublicUserNFC />} path="nfc/:uuid" />
          <Route path="unauthorized" element={<Unauthorized />} />

          <Route
            element={<SubscriptionIndividual />}
            path="individualSubscription"
          />

          {/* PRIVATE ROUTES */}

          <Route element={<RequireAuth allowedRoles={[ROLES.TeamOwner]} />}>
            <Route element={<Subscription />} path="subscription" />
          </Route>

          <Route element={<DashboardLayout />}>
            {/* FOR ADMIN USERS AND CUSTOMERS */}
            <Route
              element={
                <>
                  <RequireAuth
                    allowedRoles={[
                      ROLES.SuperAdmin,
                      ROLES.SubAdmin,
                      ROLES.TeamOwner,
                      ROLES.TeamAdmin,
                      ROLES.User,
                    ]}
                  />
                  {/* <CheckTeamOwnerPackage /> */}
                  {/* <CheckSubscriptionExpiry /> */}
                </>
              }
            >
              {/* {loggedUser?.is_new_user === 1 ? (
                <Route index element={<Settings />} />
              ) : (
                <Route index element={<Home />} />
              )} */}
              <Route
                path="/"
                element={
                  <RedirectToSettings loggedUser={loggedUser}>
                    <Home />
                  </RedirectToSettings>
                }
              />
              {/* <Route path="settings" element={<Settings />} /> */}
              {/* <Route index element={<Function />} /> */}
            </Route>

            {/* FOR Team Owner */}
            <Route
              element={
                <>
                  <RequireAuth
                    allowedRoles={[ROLES.TeamOwner, ROLES.TeamAdmin]}
                  />
                  {/* <CheckTeamOwnerPackage /> */}
                </>
              }
            >
              {/* For his Team */}
              <Route path="team" element={<Team />} />
              <Route path="team/manageNodes" element={<ManageNodes />} />
              <Route path="team/teamMembers" element={<TeamMembers />} />
              <Route path="team/viewHierarchy" element={<ViewHierarchy />} />
              <Route path="team/stats" element={<Stats />} />
              <Route
                path="team/teamMembers/addEditTeamMember"
                element={<AddEditTeamMember />}
              />
              <Route
                path="team/teamMembers/addEditTeamMember/:userId"
                element={<AddEditTeamMember />}
              />
              <Route path="invitations" element={<Invitations />} />
              <Route element={<Subscription />} path="viewSubscription" />
              <Route
                path="pendingPayments"
                element={<PendingMemberPayments />}
              />
              <Route
                path="team/:teamId/teamMembers/member/:userId/function"
                element={<Function />}
              />

              <Route
                path="team/:teamId/teamMembers/member/:userId/function/cards"
                element={<Cards />}
              />
              <Route
                path="team/:teamId/teamMembers/member/:userId/function/cardfunctions/:id"
                element={<EditCardFunctions />}
              />
              <Route
                path="team/:teamId/teamMembers/member/:userId/function/cardfunctions"
                element={<CardFunctions />}
              />
              <Route
                path="team/:teamId/teamMembers/member/:userId/function/viewemergencycontact/:id"
                element={<ViewEmergencyContact />}
              />
              <Route
                path="team/:teamId/teamMembers/member/:userId/function/emergencycontacts"
                element={<EmergencyContacts />}
              />
              <Route
                path="team/:teamId/teamMembers/member/:userId/function/customurlslisting"
                element={<CustomURLSListing />}
              />
              <Route
                path="team/:teamId/teamMembers/member/:userId/function/memoryListing"
                element={<MemoryListing />}
              />
              <Route
                path="team/:teamId/teamMembers/member/:userId/function/medicalListing"
                element={<MedicalListing />}
              />
              <Route
                path="team/:teamId/teamMembers/member/:userId/function/viewMemory/:id"
                element={<ViewMemory />}
              />
              <Route
                path="team/:teamId/teamMembers/member/:userId/function/viewMedicalId/:id"
                element={<ViewMedicalId />}
              />
              <Route
                path="team/:teamId/teamMembers/member/:userId/function/fileupload"
                element={<FileUpload />}
              />

              <Route
                path="team/:teamId/teamMembers/member/:userId/function/paymentListing"
                element={<PaymentListing />}
              />
              <Route
                path="team/:teamId/teamMembers/member/:userId/function/formBuilderListing"
                element={<FormBuilderListing />}
              />
            </Route>
            {/* Shared Routes Between User and TeamOwner */}
            <Route
              element={
                <>
                  <RequireAuth
                    allowedRoles={[
                      ROLES.TeamOwner,
                      ROLES.TeamAdmin,
                      ROLES.User,
                    ]}
                  />
                  {/* <CheckTeamOwnerPackage /> */}
                  {/* <CheckSubscriptionExpiry /> */}
                </>
              }
            >
              <Route
                path="contacts/createManual"
                element={<CreateManualContact />}
              />
              <Route path="contacts/:id" element={<EditContact />} />
              <Route
                path="contacts/viewcontact/:id"
                element={<ViewContact />}
              />
              <Route path="contacts" element={<Contact />} />
              <Route path="function" element={<Function />} />
              <Route path="function/cards" element={<Cards />} />
              <Route
                path="function/cardfunctions/:id"
                element={<EditCardFunctions />}
              />
              <Route
                path="function/cardfunctions"
                element={<CardFunctions />}
              />
              <Route
                path="function/viewemergencycontact/:id"
                element={<ViewEmergencyContact />}
              />
              <Route
                path="function/emergencycontacts"
                element={<EmergencyContacts />}
              />
              <Route
                path="function/customurlslisting"
                element={<CustomURLSListing />}
              />
              <Route path="function/fileupload" element={<FileUpload />} />

              <Route
                path="function/memoryListing"
                element={<MemoryListing />}
              />
              <Route
                path="function/medicalListing"
                element={<MedicalListing />}
              />
              <Route path="function/viewMemory/:id" element={<ViewMemory />} />
              <Route
                path="function/viewMedicalId/:id"
                element={<ViewMedicalId />}
              />

              <Route
                path="function/paymentListing"
                element={<PaymentListing />}
              />
              <Route path="function/formBuilder" element={<FormBuilder />} />
              <Route
                path="function/emailTemplate"
                element={<EmailTemplate />}
              />
              <Route
                path="function/formBuilder/:id"
                element={<FormBuilder />}
              />
              <Route
                path="function/formBuilderListing"
                element={<FormBuilderListing />}
              />

              {/* <Route path="function/venmoListing" element={<VenmoListing />} />
              <Route
                path="function/cashAppListing"
                element={<CashAppListing />}
              /> */}
              <Route
                path="function/emergencyContactslisting"
                element={<EmergencyContactsListing />}
              />
              <Route path="function/cardview" element={<CardView />} />
              <Route path="settings" element={<Settings />} />
              <Route path="/stats" element={<UserStats />} />
              <Route path="packages" element={<SubscriptionPackages />} />
              <Route path="qrcode" element={<QRCodePage />} />
              <Route path="/qrcode/:id" element={<QRCodePage />} />
              <Route
                path="qrcode/addEditQRCode"
                element={<AddEditQRCodePage />}
              />
              <Route
                path="qrcode/addEditQRCode/:id"
                element={<AddEditQRCodePage />}
              />
              <Route path="help" element={<Help />} />
              <Route path="help/helpContactUs" element={<HelpContactUs />} />
              <Route path="help/helpTutorial" element={<HelpTutorial />} />
              <Route path="help/helpFaqs" element={<HelpFaqs />} />
              <Route path="help/aboutus" element={<AboutUs />} />
              <Route
                path="help/termsandcondition"
                element={<TermsAndCondition />}
              />
              <Route
                path="help/privacyandpolicy"
                element={<PrivacyAndPolicy />}
              />
              <Route path="help/exchangepolicy" element={<ExchangePolicy />} />
              <Route
                path="help/intellectualproperty"
                element={<IntellectualProperty />}
              />

              <Route path="leads" element={<Leads />} />
              <Route path="leads/:id" element={<Leads />} />
              <Route path="leads/:lead_type" element={<Leads />} />
              <Route path="leads/viewLead" element={<ViewLead />} />
              <Route path="leads/viewLead/:id" element={<ViewLead />} />
              <Route
                path="/discountedProduct"
                element={<ProductDiscountList />}
              />
            </Route>
            {/* FOR CUSTOMER */}
            {/* <Route element={<RequireAuth allowedRoles={[ROLES.User]} />}>
             
            </Route> */}
            {/* FOR SUPER ADMIN AND SUB ADMIN */}
            <Route
              element={
                <RequireAuth
                  allowedRoles={[ROLES.SuperAdmin, ROLES.SubAdmin]}
                />
              }
            >
              {/* Admin Cards*/}
              <Route path="cards" element={<AllCards />} />
              <Route path="cards/AddEditCard" element={<AddEditCard />} />
              <Route
                path="cards/AddEditCard/:cardId"
                element={<AddEditCard />}
              />
              <Route path="cards/ViewCard" element={<ViewCard />} />
              <Route path="cards/ViewCard/:cardId" element={<ViewCard />} />

              {/* admin custom Url ,Venmo,Cash App,Emergenct contact*/}
              <Route path="customUrls" element={<AllCustomUrls />} />
              <Route path="fileUpload" element={<AllFileUpload />} />
              <Route path="cashApp" element={<AllCashApps />} />
              <Route path="venmos" element={<AllVenmos />} />
              <Route path="payments" element={<AllPayments />} />
              <Route
                path="emergenctContacts"
                element={<AllEmergencyContacts />}
              />
              <Route
                path="emergencyContacts/viewEmergencyContacts/:id"
                element={<ViewEmergencyContactAdmin />}
              />
              <Route path="memory" element={<AllMemory />} />
              <Route path="memory/Viewmemory" element={<ViewMemoryAdmin />} />
              <Route
                path="memory/Viewmemory/:id"
                element={<ViewMemoryAdmin />}
              />
              <Route path="medicalId" element={<AllMedicalId />} />
              <Route
                path="medicalId/ViewMedicalId"
                element={<ViewMedicalIdAdmin />}
              />
              <Route
                path="medicalId/ViewMedicalId/:id"
                element={<ViewMedicalIdAdmin />}
              />
              <Route path="formBuilder" element={<AllFormBuilder />} />
              <Route
                path="formBuilder/AddEditFormBuilder"
                element={<AddEditFormBuilder />}
              />
              <Route
                path="formBuilder/AddEditFormBuilder/:id"
                element={<AddEditFormBuilder />}
              />
              <Route
                path="formBuilder/ViewFormBuilder"
                element={<ViewFormBuilder />}
              />
              <Route
                path="formBuilder/ViewFormBuilder/:id"
                element={<ViewFormBuilder />}
              />

              {/* Tutorial section */}
              <Route path="tutorial" element={<Tutorial />} />
              <Route
                path="tutorial/addEditTutorial"
                element={<AddEditTutorial />}
              />
              <Route
                path="tutorial/addEditTutorial/:tutorialId"
                element={<AddEditTutorial />}
              />

              {/* FAQS */}
              <Route path="faqs" element={<Faqs />} />
              <Route path="faqs/addEditFaq" element={<AddEditFaqs />} />
              <Route path="faqs/addEditFaq/:faqId" element={<AddEditFaqs />} />

              {/*HELP CONTACT SECTION */}
              <Route path="contactUs" element={<ContactUs />} />
              <Route
                path="contactUs/AddEditContactUs"
                element={<AddEditContactUs />}
              />
              <Route
                path="contactUs/AddEditContactUs/:contactUsId"
                element={<AddEditContactUs />}
              />

              {/* pages */}

              <Route path="pages" element={<Pages />} />
              <Route path="pages/addnewpage/:pageId" element={<AddNewPage />} />
              <Route path="pages/addnewpage" element={<AddNewPage />} />

              <Route path="packageContacts" element={<AllPackageContacts />} />
              <Route
                path="packageContacts/:contactId"
                element={<AddEditPackageContact />}
              />
              {/* <Route path="pages/addnewpage/:pageId" element={<AddNewPage />} />
              <Route path="pages/addnewpage" element={<AddNewPage />} /> */}

              {/* users */}
              <Route path="users" element={<Users />} />
              <Route path="users/AddEditUser" element={<AddEditUser />} />
              <Route
                path="users/AddEditUser/:userId"
                element={<AddEditUser />}
              />
              <Route path="users/qrCode" element={<AllQRCode />} />
              <Route path="users/qrCode/:id" element={<AllQRCode />} />
              <Route
                path="/users/qrCode/addEditQRCode"
                element={<AddEditQRCode />}
              />
              <Route
                path="/users/qrCode/addEditQRCode/:id"
                element={<AddEditQRCode />}
              />
              {/* Team  */}

              <Route path="allTeamOwners" element={<AllTeamOwners />} />
              <Route path="allTeamOwners/:userId" element={<EditTeamOwner />} />
              <Route
                path="allTeamOwners/teamDetail/:teamId"
                element={<TeamDetail />}
              />
              <Route
                path="allTeamOwners/teamDetail/:teamId/editTeamMember/:teamMemberId"
                element={<EditTeamMember />}
              />

              {/* Prcincing/Subscription */}
              <Route path="pricing" element={<Pricing />} />
              <Route
                path="pricing/addEditPricing"
                element={<AddEditPricing />}
              />
              <Route
                path="pricing/addEditPricing/:priceId"
                element={<AddEditPricing />}
              />

              {/* Send Notification */}
              <Route
                path="notificationListing"
                element={<NotificationListing />}
              />
              <Route path="sendNotification" element={<SendNotification />} />

              {/* App setting */}
              <Route path="appSetting" element={<AppSettings />} />

              {/* Manage Subscriptions */}
              <Route
                path="manageSubscriptions"
                element={<ManageSubscriptions />}
              />
              <Route
                path="activeSubscriptions"
                element={<ActiveSubscriptions />}
              />
              <Route
                path="manageSubscriptions/:type"
                element={<ManageSubscriptions />}
              />
              <Route
                path="/manageSubscriptions/addEditPackage"
                element={<AddEditPackage />}
              />
              <Route
                path="/manageSubscriptions/addEditPackage/:id"
                element={<AddEditPackage />}
              />
              <Route path="/generateQRCode" element={<AllQRCode />} />
              <Route
                path="/generateQRCode/addEditQRCode"
                element={<AddEditQRCode />}
              />
              <Route
                path="/generateQRCode/batchQRCode"
                element={<BatchQRCode />}
              />
              <Route
                path="/generateQRCode/batchQRCode/:id"
                element={<BatchQRCode />}
              />
              <Route
                path="/generateQRCode/batchQRCode/assignQRCode"
                element={<AssignQRCode />}
              />
              <Route
                path="/generateQRCode/batchQRCode/assignQRCode/:uuid"
                element={<AssignQRCode />}
              />

              <Route path="coupons" element={<AllCoupons />} />
              <Route path="/coupons/:id" component={AllCoupons} />
              <Route
                path="/coupons/addEditCoupons"
                element={<AddEditCoupons />}
              />
              <Route
                path="/coupons/editCoupons/:id"
                element={<EditCoupons />}
              />
              <Route
                path="/coupons/couponQuantity"
                element={<CouponQuantity />}
              />
              <Route path="/coupons/viewCoupons" element={<ViewCoupons />} />
              <Route
                path="/coupons/viewCoupons/:id"
                element={<ViewCoupons />}
              />
              <Route path="productDiscount" element={<AllProductDiscount />} />
              <Route
                path="/productDiscount/:id"
                component={AllProductDiscount}
              />
              <Route
                path="/productDiscount/addEditProductDiscount"
                element={<AddEditProductDiscount />}
              />
              <Route
                path="/productDiscount/addEditProductDiscount/:id"
                element={<AddEditProductDiscount />}
              />

              <Route
                path="productDiscountLink"
                element={<AllProductDiscountLink />}
              />
              <Route
                path="/productDiscountLink/addEditProductDiscountLink"
                element={<AddEditProductDiscountLink />}
              />
              <Route
                path="/productDiscountLink/addEditProductDiscountLink/:id"
                element={<AddEditProductDiscountLink />}
              />
            </Route>
            {/* ONLY FOR ADMIN */}
            <Route element={<RequireAuth allowedRoles={[ROLES.SuperAdmin]} />}>
              {/* Admin Users */}
              <Route path="adminUser" element={<AdminUser />} />
              <Route
                path="adminUser/AddEditAdminUser"
                element={<AddEditAdminUser />}
              />
              <Route
                path="adminUser/AddEditAdminUser/:adminId"
                element={<AddEditAdminUser />}
              />
            </Route>
          </Route>
          {/* No Record */}
          <Route path="*" element={<Missing />} />
          <Route path="paymentNotPaid" element={<PaymentNotPaid />} />
          <Route path="checkout" element={<Checkout />} />
          <Route path="/terms-and-service" element={<TermsAndCondition />} />
          <Route path="/privacy-policy" element={<PrivacyAndPolicy />} />
        </Route>
      </Routes>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default App;
