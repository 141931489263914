import React, { useEffect, useState } from "react";
import classes from "../../../assets/styles/Pages/Settings/AddNewPage.module.css";
import { get, put, post } from "../../../server";
import { USER, CHANGE_USER_QRCODE } from "../../../utils/constants/apiUrl";
import { loggedInUser } from "../../../utils/store";
import { useAtom } from "jotai";
import Loader from "../../../components/Loader";
import showToast from "../../../helpers/showToast";
import { useParams, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import "react-datepicker/dist/react-datepicker.css";
import Form from "react-bootstrap/Form";

const AssignQRCode = () => {
  const { userId, uuid } = useParams();
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [users, setUsers] = useState();
  const [selectedUserId, setSelectedUserId] = useState("");
  const [functionType, setFunctionType] = useState("");

  const handleFunctionTypeChange = (e) => {
    setFunctionType(e.target.value);
  };

  // Handle Submit
  const handleSubmit = async (e) => {
    setLoading(true);

    const payload = {
      user_id: selectedUserId,
      uuid: uuid,
      function_type: functionType,
    };
    let { status, message, data } = await post(
      CHANGE_USER_QRCODE,
      payload,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      navigate("/generateQRCode");
    }
    showToast(status, message);
    setLoading(false);
  };

  const loadUsers = async () => {
    setLoading(true);
    let { status, message, data } = await get(
      USER,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      if (data.length > 0) {
        setUsers(data);
      }
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadUsers();
  }, []);

  return (
    <>
      {!loading ? (
        <div>
          <h6> Change User QR Code</h6>
          <div className={`mainWrapper ${classes.outerWrapper}`}>
            <div className="row gx-5 align-items-center">
              <div className="col-md-4 col-12">
                <div className={classes.selectWrapper}>
                  <label
                    htmlFor=""
                    style={{
                      fontSize: "12px",
                      marginBottom: "10px",
                      lineHeight: "18px",
                    }}
                  >
                    User
                  </label>
                  <div className="input-group globalInputWithIcon">
                    <select
                      className="form-select customSelect"
                      name="user_id"
                      value={selectedUserId}
                      onChange={(e) => setSelectedUserId(e.target.value)}
                    >
                      <option value="">Select User</option>
                      {Array.isArray(users) &&
                        users.map((user) => (
                          <option key={user.id} value={user.id}>
                            {user.email}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-12">
                <Form.Check
                  inline
                  type="radio"
                  name="functionType"
                  label="Change Profile QR Code"
                  value="profile_personal"
                  checked={functionType === "profile_personal"}
                  onChange={handleFunctionTypeChange}
                />
                <Form.Check
                  inline
                  type="radio"
                  name="functionType"
                  label="Change Team QR Code"
                  value="profile_owner"
                  checked={functionType === "profile_owner"}
                  onChange={handleFunctionTypeChange}
                />
              </div>
            </div>
            <div className="formBottom">
              <Button
                className="submitButton"
                type="submit"
                onClick={handleSubmit}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default AssignQRCode;
