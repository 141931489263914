import React, { useState } from "react";
import classes from "../assets/styles/Pages/Function/ViewEmergencyContact.module.css";
import "react-phone-input-2/lib/material.css";
import contacticon from "../assets/images/function_page_icons/contacticon.svg";
import playstore from "../assets/images/footer_icons/playstore.svg";
import appstore from "../assets/images/footer_icons/appstore.svg";
import Image from "react-bootstrap/Image";
import {
  EMERGENCY_CONTACT_VCF_FILE_URL,
  MEDICAL_ID_VCF_FILE_URL,
} from "../utils/constants/apiUrl";
import { post } from "../server";
import { loggedInUser } from "../utils/store";
import { useAtom } from "jotai";
import showToast from "../helpers/showToast";
import baseURL from "../utils/constants/baseUrl";
import { useParams } from "react-router-dom";
import dummyImage from "../assets/images/global_icons/dummyimage.png";
import _ from "lodash";
import formatPhoneNumber from "../helpers/formatPhoneNumber";

const PublicMedicalID = ({ medicalIDData }) => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [VCFData, setVCFData] = useState(medicalIDData);
  const [imageLoaded, setImageLoaded] = useState(false);

  const { uuid } = useParams();

  const [showFullText, setShowFullText] = useState(false);
  const displayText = showFullText
    ? medicalIDData?.description
    : medicalIDData?.description?.length > 60
    ? medicalIDData?.description?.slice(0, 60) + "..."
    : medicalIDData?.description;

  const toggleText = () => {
    setShowFullText(!showFullText);
  };

  const handleVcfFile = async () => {
    let { status, message, data } = await post(
      MEDICAL_ID_VCF_FILE_URL + `/${VCFData.uuid}`,
      VCFData,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      const link = document.createElement("a");
      link.download = "temp.vcf";
      link.href = data.url;
      link.click();
    } else {
      showToast(status, message);
    }
  };

  return (
    <>
      <div className="container">
        <div className="row py-5 justify-content-center">
          <div className="col-md-5">
            <div className="w-100">
              <h6 className={classes.title}>Medical ID</h6>
              <div className={classes.cardWrapper}>
                <div className="">
                  <div className={classes.profileCardWrapper}>
                    <div className={classes.imgWrapper}>
                      <Image
                        className="globalIMageFit"
                        src={
                          medicalIDData.photo
                            ? `${baseURL.PUBLIC_URL + medicalIDData.photo}`
                            : dummyImage
                        }
                        alt="img"
                        roundedCircle={true}
                        onLoad={() => setImageLoaded(true)}
                      />
                    </div>
                    <div className={classes.detailWrapper}>
                      <h6 className="mb-3">{`${
                        medicalIDData.first_name || ""
                      } ${medicalIDData.last_name || ""}`}</h6>
                      <div className="card border-0">
                        {medicalIDData?.dob ? (
                          <div className="row">
                            <div className="col-6">
                              <p className="text-start fw-bold mt-0">
                                Date of Birth :{" "}
                              </p>
                            </div>
                            <div className="col-6 text-end">
                              <p className="text-end mt-0">
                                {medicalIDData.dob}
                              </p>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {medicalIDData?.blood_type ? (
                          <div className="row">
                            <div className="col-6">
                              <p className="text-start fw-bold">
                                Blood Type :{" "}
                              </p>
                            </div>
                            <div className="col-6">
                              <p className="text-end">
                                {medicalIDData.blood_type}
                              </p>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {medicalIDData?.weight ? (
                          <div className="row">
                            <div className="col-6">
                              <p className="text-start fw-bold">Weight : </p>
                            </div>
                            <div className="col-6 text-end">
                              <p className="text-end">{medicalIDData.weight}</p>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {medicalIDData?.height ? (
                          <div className="row">
                            <div className="col-6">
                              <p className="text-start fw-bold">Height : </p>
                            </div>
                            <div className="col-6 text-end">
                              <p className="text-end">{medicalIDData.height}</p>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {medicalIDData?.primary_language ? (
                          <div className="row">
                            <div className="col-6">
                              <p className="text-start fw-bold">Language : </p>
                            </div>
                            <div className="col-6 text-end">
                              <p className="text-end">
                                {medicalIDData.primary_language}
                              </p>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {medicalIDData?.allergies_reactions ? (
                          <div className="row">
                            <div className="col-6">
                              <p className="text-start fw-bold">
                                Allergies Reactions :{" "}
                              </p>
                            </div>
                            <div className="col-6 text-end">
                              <p className="text-end">
                                {medicalIDData.allergies_reactions}
                              </p>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {medicalIDData?.medical_condition ? (
                          <div className="row">
                            <div className="col-6">
                              <p className="text-start fw-bold">
                                Medical Condition :{" "}
                              </p>
                            </div>
                            <div className="col-6 text-end">
                              <p className="text-end">
                                {medicalIDData.medical_condition}
                              </p>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                      {medicalIDData?.medical_condition ? (
                        <div className="card border-0 mt-3">
                          <div className="row">
                            <div className="col-12">
                              <p className="text-start fw-bold mt-0">
                                Medical Notes :{" "}
                              </p>
                            </div>
                            <div className="col-12">
                              <p className="text-start mb-0">
                                {medicalIDData.medical_notes}
                              </p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {medicalIDData?.medications ? (
                        <div className="card border-0 mt-3">
                          <div className="row">
                            <div className="col-12">
                              <p className="text-start fw-bold mt-0">
                                Medications :{" "}
                              </p>
                            </div>
                            <div className="col-12">
                              <p className="text-start mb-0">
                                {medicalIDData.medications}
                              </p>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {medicalIDData?.medicalIdEmergencyContact.length > 0 ? (
                        <div className="card border-0 mt-3">
                          <h6 className="text-start mt-0 mb-3 sub-title">
                            Medical Emergency Contact
                          </h6>
                          {medicalIDData?.medicalIdEmergencyContact.map(
                            (emergencyContact) => {
                              return (
                                <>
                                  <p className="text-start mt-0">
                                    <strong>Name :</strong>{" "}
                                    {emergencyContact.person_name}
                                  </p>
                                  <p className="text-start">
                                    <strong>Relation :</strong>{" "}
                                    {emergencyContact.relation}
                                  </p>
                                  <p className="text-start">
                                    <strong>Phone No. : </strong>
                                    {`+${
                                      emergencyContact.country_phone
                                    }${formatPhoneNumber(
                                      emergencyContact.phone_number
                                    )}`}
                                  </p>
                                  <hr />
                                </>
                              );
                            }
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                      {/* <p className={classes.description}>
                        {displayText}

                        {medicalIDData?.description?.length > 60 && (
                          <button onClick={toggleText}>
                            {showFullText ? "Show Less" : "Read More"}
                          </button>
                        )}
                      </p> */}
                    </div>
                    <div
                      className={` text-center ${classes.savePhonebookBtnWrapper}`}
                    >
                      <button onClick={handleVcfFile}>
                        <span>
                          <img src={contacticon} alt="" />
                        </span>
                        Save Contact
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className={classes.cardFooter}>
                <div className="d-flex align-items-center gap-3 justify-content-center">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.contactco.contactapp"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={playstore} alt="" />
                  </a>
                  <a
                    href="https://apps.apple.com/us/app/contact-co-connect-smarter/id6683301913"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={appstore} alt="" />
                  </a>
                </div>
                <p>
                  © {new Date().getFullYear()} Contact Co, All rights reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style type="">
        {`
          p{
            font-size: 13px
          }
          .card {
              background-color: #eee;
              padding: 26px;
              border-radius: 20px;
          }
          .card .sub-title {
              font-weight: 700;
              font-size: 14px;
              line-height: 22px;
              color: var(--primary_text_color);
          }
        `}
      </style>
    </>
  );
};

export default PublicMedicalID;
