import React, { useEffect, useState } from "react";
import classes from "../../../assets/styles/PageComponentsStyles/Settings/Theme.module.css";
import { THEME_GET, THEME_CHANGE } from "../../../utils/constants/apiUrl";
import { get, put } from "../../../server";
import { loggedInUser, subscribedPackage } from "../../../utils/store";
import { useAtom } from "jotai";
import showToast from "../../../helpers/showToast";
import Loader from "../../Loader";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa";

const Theme = () => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [purchasedPackage, setPurchasedPackage] = useAtom(subscribedPackage);
  const [themeColor, setThemeColor] = useState("");
  const [buttonColor, setButtonColor] = useState("");
  const [defaultTheme, setDefaultTheme] = useState(false);
  const [selectedTheme, setSelectedTheme] = useState("");
  const [useCustomColor, setUseCustomColor] = useState(false);
  const navigate = useNavigate();

  const Colors = [
    {
      id: 1,
      bgColor: "#22A081",
    },
    {
      id: 2,
      bgColor: "#E35252",
    },
    {
      id: 3,
      bgColor: "#E4A56B",
    },
    {
      id: 4,
      bgColor: "#7000B5",
    },
    {
      id: 5,
      bgColor: "#EEB8C9",
    },
    {
      id: 6,
      bgColor: "#DFF87C",
    },
    {
      id: 7,
      bgColor: "#34D844",
    },
    {
      id: 8,
      bgColor: "#3B58BD",
    },
    {
      id: 9,
      bgColor: "#FF5099",
    },
    {
      id: 10,
      bgColor: "",
      custom: true,
    },
  ];

  useEffect(() => {
    if (!useCustomColor) {
      // Apply theme color only if custom color is not used
      if (selectedTheme === "dark") {
        setThemeColor("#222222");
        setButtonColor("#22a081");
      } else if (selectedTheme === "light") {
        setThemeColor("#fafafa");
        setButtonColor("#22a081");
      } else if (selectedTheme === "default") {
        setThemeColor("#1e1e1e");
        setButtonColor("#22a081");
      } else {
        setThemeColor("");
        setButtonColor("");
      }
    }
  }, [selectedTheme, useCustomColor]);

  const handleSubmit = async (e) => {
    setLoading(true);
    let payload = {
      theme_color: useCustomColor
        ? themeColor
        : selectedTheme === "default"
        ? null
        : themeColor,
      button_color: useCustomColor
        ? buttonColor
        : selectedTheme === "default"
        ? null
        : buttonColor,
    };

    let { status, message } = await put(
      THEME_CHANGE,
      payload,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      fetchColors();
      navigate("/");
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  const fetchColors = async () => {
    setLoading(true);
    let { status, data } = await get(
      THEME_GET,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      if (data?.theme_color == null || data?.button_color == null) {
        setSelectedTheme("default");
        if (purchasedPackage.id !== 1) {
          setDefaultTheme(true);
          setThemeColor("#1e1e1e");
          setButtonColor("#22a081");
        }
      } else {
        setThemeColor(data?.theme_color);
        setButtonColor(data?.button_color);
        if (
          data?.theme_color === "#ffffff" ||
          data?.theme_color === "#fafafa"
        ) {
          setSelectedTheme("light");
        } else if (data?.theme_color === "#222222") {
          setSelectedTheme("dark");
        } else {
          setUseCustomColor(true);
        }
      }
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchColors();
  }, []);

  return (
    <>
      {!loading ? (
        <div className={`${classes.themeWrapper}`}>
          {purchasedPackage.id !== 1 ? (
            <>
              <div className="colorCheckBox">
                <h6 className="mb-3">Choose Your Theme</h6>
                <Form.Check
                  type="radio"
                  name="theme"
                  label="Default theme"
                  value="default"
                  checked={selectedTheme === "default" && !useCustomColor}
                  onChange={() => setSelectedTheme("default")}
                />
                <Form.Check
                  type="radio"
                  name="theme"
                  label="Dark theme"
                  value="dark"
                  checked={selectedTheme === "dark" && !useCustomColor}
                  onChange={() => setSelectedTheme("dark")}
                />
                <Form.Check
                  type="radio"
                  name="theme"
                  label="Light theme"
                  value="light"
                  checked={selectedTheme === "light" && !useCustomColor}
                  onChange={() => setSelectedTheme("light")}
                />
              </div>

              <div className={`${classes.note} mb-3`}>
                <p className="mb-1">NOTE :</p>
                <ol className="ps-3">
                  <li className="mb-1">
                    To select a custom color, check the{" "}
                    <strong>"Custom Color"</strong> checkbox.
                  </li>
                  <li className="mb-1">
                    If you choose a different theme, make sure to uncheck the{" "}
                    <strong>"Custom Color"</strong> checkbox.
                  </li>
                  <li>
                    Avoid selecting similar color shades, as this can make text
                    difficult to read.
                  </li>
                </ol>
              </div>
              <div className="colorCheckBox">
                <Form.Check
                  type="checkbox"
                  label="Use Custom Color"
                  //checked={useCustomColor}
                  checked={useCustomColor === true}
                  onChange={(e) => {
                    setUseCustomColor(e.target.checked);
                    if (e.target.checked) {
                      setSelectedTheme(""); // Uncheck any predefined theme when custom is selected
                    }
                  }}
                />
              </div>
              <div className={`${classes.colorBox}`}>
                <Form.Group controlId="">
                  <label className={classes.formColorLabel}>Theme Color</label>
                  <div className={classes.formBox}>
                    {/* {Colors.map((color) => (
                      <button
                        key={color.id}
                        className={classes.colorButton}
                        style={{ backgroundColor: color.bgColor }}
                        onClick={() => {
                          setThemeColor(color.bgColor);
                        }}
                      ></button>
                    ))}
                    <input
                      type="color"
                      value={themeColor}
                      onChange={(e) => setThemeColor(e.target.value)}
                    /> */}
                    {Colors.map((color) => (
                      <React.Fragment key={color.id}>
                        {color.custom ? (
                          <div className={classes.customInput}>
                            <input
                              type="color"
                              //value={themeColor}
                              onChange={(e) => setThemeColor(e.target.value)}
                            />
                            <FaPlus />
                          </div>
                        ) : (
                          <div
                            className={
                              themeColor === color.bgColor
                                ? classes.activeColor
                                : classes.inActiveColor
                            }
                          >
                            <button
                              className={classes.colorButton}
                              style={{ backgroundColor: color.bgColor }}
                              onClick={() => {
                                setThemeColor(color.bgColor);
                              }}
                            ></button>
                          </div>
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                </Form.Group>
              </div>
              <div className={`${classes.colorBox}`}>
                <Form.Group controlId="">
                  <label className={classes.formColorLabel}>Button Color</label>
                  <div className={classes.formBox}>
                    {/* <input
                      type="color"
                      value={buttonColor}
                      onChange={(e) => setButtonColor(e.target.value)}
                    /> */}
                    {Colors.map((color) => (
                      <React.Fragment key={color.id}>
                        {color.custom ? (
                          <div className={classes.customInput}>
                            <input
                              type="color"
                              //value={buttonColor}
                              onChange={(e) => setButtonColor(e.target.value)}
                            />
                            <FaPlus />
                          </div>
                        ) : (
                          // <button
                          //   className={classes.colorButton}
                          //   style={{ backgroundColor: color.bgColor }}
                          //   onClick={() => {
                          //     setButtonColor(color.bgColor);
                          //   }}
                          // ></button>
                          <div
                            className={
                              buttonColor === color.bgColor
                                ? classes.activeColor
                                : classes.inActiveColor
                            }
                          >
                            <button
                              className={classes.colorButton}
                              style={{ backgroundColor: color.bgColor }}
                              onClick={() => {
                                setButtonColor(color.bgColor);
                              }}
                            ></button>
                          </div>
                        )}
                      </React.Fragment>
                    ))}
                  </div>
                </Form.Group>
              </div>
            </>
          ) : (
            <>
              <h6 className="mb-3">Choose Your Theme</h6>
              <div className={classes.toggleBox}>
                <Form.Check
                  type="radio"
                  name="theme"
                  label="Default theme"
                  value="default"
                  checked={selectedTheme === "default"}
                  onChange={() => setSelectedTheme("default")}
                />
                <Form.Check
                  type="radio"
                  name="theme"
                  label="Dark theme"
                  value="dark"
                  checked={selectedTheme === "dark"}
                  onChange={() => setSelectedTheme("dark")}
                />
                <Form.Check
                  type="radio"
                  name="theme"
                  label="Light theme"
                  value="light"
                  checked={selectedTheme === "light"}
                  onChange={() => setSelectedTheme("light")}
                />
              </div>
            </>
          )}
          <div className={classes.colorBox}>
            <button
              type="button"
              className={classes.submitBtnWrapper}
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      ) : (
        <Loader />
      )}
      <style type="text/css">{`
        .colorCheckBox{
          padding: 0 0.5rem;
          margin-bottom: 1rem;
        }
        .colorCheckBox label{
          font-size: 12px;
          line-height: 18px;
          color: var(--primary_text_color);
        }
      `}</style>
    </>
  );
};

export default Theme;
