import React, { useEffect, useState } from "react";
import classes from "../../../assets/styles/Pages/Settings/AddNewPage.module.css";
import { post, get } from "../../../server";
import { APP_SETTING } from "../../../utils/constants/apiUrl";
import { loggedInUser, globalAppSetting } from "../../../utils/store";
import { useAtom } from "jotai";
import Loader from "../../../components/Loader";
import showToast from "../../../helpers/showToast";
import AppSettingSchema from "../../../utils/SchemasValidation/AppSettingSchema";
import ValidateSchema from "../../../helpers/ValidateSchema";
import Button from "react-bootstrap/Button";
import PhoneInput from "react-phone-input-2";
import { formatUrl } from "../../../utils/functions";
import _ from "lodash";
import { validatePhoneWithCountryCode } from "../../../utils/functions";
let emptyObj = {
  phone_number: "",
  country_phone: "",
  email: "",
  facebook_url: "",
  twitter_url: "",
  instagram_url: "",
  linkdin_url: "",
  tiktok_url: "",
  address: "",
};

const AppSettings = () => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [appSettingStorage, setAppSettingStorage] = useAtom(globalAppSetting);
  const [appSetting, setAppSetting] = useState(emptyObj);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  // const autoCompleteRef = useRef();
  // const inputRef = useRef();
  // const options = {
  //   componentRestrictions: { country: "ng" },
  //   fields: ["address_components", "geometry", "icon", "name"],
  //   types: ["establishment"],
  // };

  // useEffect(() => {
  //   autoCompleteRef.current = new window.google.maps.places.Autocomplete(
  //     inputRef.current,
  //     options
  //   );
  // }, []);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setAppSetting({ ...appSetting, [name]: value });
  };

  const loadAppSetting = async () => {
    setLoading(true);
    let { status, message, data } = await get(
      APP_SETTING,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      if (data.length > 0) {
        data = data[0];
        if (data?.country_phone) {
          data.country_phone = `${data.country_phone}`;
        }

        if (data?.phone_number) {
          data.phone_number = `${data.country_phone ? data.country_phone : ""}${
            data.phone_number
          }`;
        }
        setAppSettingStorage(data);
        setAppSetting(data);
      }
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadAppSetting();
  }, []);

  // handle Validation
  const validateForm = () => {
    let newErrors = ValidateSchema(appSetting, AppSettingSchema);
    setErrors(newErrors);
    if (Object.keys(newErrors).length) return false;

    if (appSetting.phone_number?.length > appSetting.country_phone?.length) {
      let resultPhone = validatePhoneWithCountryCode(
        appSetting.country_phone,
        appSetting.phone_number
      );
      if (resultPhone) {
        setErrors({
          phone_number: resultPhone,
        });
        return false;
      }
    }

    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setLoading(true);

    let payload = _.cloneDeep(appSetting);

    // Handle facebook Url
    payload.facebook_url = formatUrl(payload.facebook_url);
    payload.twitter_url = formatUrl(payload.twitter_url);
    payload.instagram_url = formatUrl(payload.instagram_url);
    payload.linkdin_url = formatUrl(payload.linkdin_url);

    // if (payload.phone_number) {
    //   payload.phone_number = payload.phone_number.slice(
    //     payload.country_phone.length - 1,
    //     payload.phone_number.length
    //   );
    //   payload.country_phone = payload.country_phone.slice(
    //     1,
    //     payload.country_phone.length
    //   );
    // } else {
    //   payload.country_phone = "";
    //   payload.phone_number = "";
    // }

    if (payload.phone_number && payload.country_phone.length > 0) {
      payload.phone_number = payload.phone_number.slice(
        payload.country_phone.length,
        payload.phone_number.length
      );
    } else {
      payload.country_phone = "";
    }
    if (!payload.phone_number && payload.country_phone) {
      payload.country_phone = "";
    }
    let { status, message, data } = await post(
      APP_SETTING,
      payload,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    showToast(status, message);
    if (status) {
      setAppSettingStorage(data);
    }
    setLoading(false);
  };

  return (
    <>
      {!loading ? (
        <div>
          <h6> App Setting </h6>
          <div className={`mainWrapper ${classes.outerWrapper}`}>
            <div className="row gx-5 gy-3">
              <div className="col-md-6 col-12">
                <label htmlFor="">Email</label>
                <input
                  type="text"
                  placeholder="Email"
                  name="email"
                  value={appSetting.email}
                  className={
                    errors?.email ? "globalInput errorInput" : "globalInput"
                  }
                  onChange={handleChange}
                />
                <p className="errorText">{errors?.email || ""}</p>
              </div>
              <div className="col-md-6  col-12">
                <label htmlFor="" className={classes.label}>
                  Contact Number
                </label>
                <PhoneInput
                  country={"us"}
                  enableSearch={true}
                  countryCodeEditable={false}
                  inputClass={
                    errors.phone_number
                      ? "phoneNumberInput errorInput"
                      : "phoneNumberInput"
                  }
                  value={appSetting.phone_number}
                  onChange={(value, country, e, formattedValue) => {
                    setAppSetting({
                      ...appSetting,
                      phone_number: value,
                      country_phone: `${country.dialCode}`,
                    });
                  }}
                />
                <span className="errorText">{errors.phone_number}</span>
              </div>
              <div className="col-md-6 col-12">
                <label htmlFor="">Facebook Url</label>
                <input
                  type="text"
                  placeholder="Facebook Url"
                  name="facebook_url"
                  value={appSetting.facebook_url}
                  className={
                    errors?.facebook_url
                      ? "globalInput errorInput"
                      : "globalInput"
                  }
                  onChange={handleChange}
                />
                <p className="errorText">{errors?.facebook_url || ""}</p>
              </div>
              <div className="col-md-6 col-12">
                <label htmlFor="">Twitter Url</label>
                <input
                  type="text"
                  placeholder="Twitter Url"
                  name="twitter_url"
                  value={appSetting.twitter_url}
                  className={
                    errors?.twitter_url
                      ? "globalInput errorInput"
                      : "globalInput"
                  }
                  onChange={handleChange}
                />
                <p className="errorText">{errors?.twitter_url || ""}</p>
              </div>

              <div className="col-md-6 col-12">
                <label htmlFor="">Instagram Url</label>
                <input
                  type="text"
                  placeholder="Instagram Url"
                  name="instagram_url"
                  value={appSetting.instagram_url}
                  className={
                    errors?.instagram_url
                      ? "globalInput errorInput"
                      : "globalInput"
                  }
                  onChange={handleChange}
                />
                <p className="errorText">{errors?.instagram_url || ""}</p>
              </div>

              <div className="col-md-6 col-12">
                <label htmlFor="">Linkdin Url</label>
                <input
                  type="text"
                  placeholder="Linkdin Url"
                  name="linkdin_url"
                  value={appSetting.linkdin_url}
                  className={
                    errors?.linkdin_url
                      ? "globalInput errorInput"
                      : "globalInput"
                  }
                  onChange={handleChange}
                />
                <p className="errorText">{errors?.linkdin_url || ""}</p>
              </div>
              <div className="col-md-6 col-12">
                <label htmlFor="">TikTok Url</label>
                <input
                  type="text"
                  placeholder="TikTok Url"
                  name="tiktok_url"
                  value={appSetting.tiktok_url}
                  className={
                    errors?.tiktok_url
                      ? "globalInput errorInput"
                      : "globalInput"
                  }
                  onChange={handleChange}
                />
                <p className="errorText">{errors?.tiktok_url || ""}</p>
              </div>

              <div className="col-md-6 col-12">
                <label htmlFor="">Address</label>
                <input
                  type="text"
                  placeholder="Enter Address"
                  name="address"
                  value={appSetting.address}
                  className={
                    errors?.address ? "globalInput errorInput" : "globalInput"
                  }
                  onChange={handleChange}
                />
                <p className="errorText">{errors?.address || ""}</p>
                {/* <PlaceComponent /> */}

                {/* <div>
                  <label>enter address :</label>
                  <input ref={inputRef} />
                </div> */}
                {/* <label htmlFor="">Address</label>
                <input
                  type="text"
                  placeholder="Address"
                  name="address"
                  value={appSetting.address}
                  className={
                    errors?.address ? "globalInput errorInput" : "globalInput"
                  }
                  onChange={handleChange}
                />
                <p className="errorText">{errors?.address || ""}</p> */}
              </div>
            </div>
            <div className="formBottom">
              <Button
                className="submitButton"
                type="submit"
                onClick={handleSubmit}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}

      <style type="text/css">
        {`
        label {
          display: block;
    font-size: 12px;
    line-height: 18px;
    color: var(--primary_text_color);
    margin-bottom: 10px;
        }
        }
	`}
      </style>
    </>
  );
};

export default AppSettings;
