import React, { useEffect, useState } from "react";
import classes from "../../../assets/styles/Pages/Settings/AddNewPage.module.css";
import { get, put, post } from "../../../server";
import { USER } from "../../../utils/constants/apiUrl";
import { loggedInUser } from "../../../utils/store";
import { useAtom } from "jotai";
import Loader from "../../../components/Loader";
import showToast from "../../../helpers/showToast";
import { useParams, useNavigate } from "react-router-dom";
import SettingsInput from "../../../components/Forms/SettingsInput";
import AddEditUserSchema from "../../../utils/SchemasValidation/AddEditUserSchema";
import ValidateSchema from "../../../helpers/ValidateSchema";
import person from "../../../assets/images/settings_page_icons/person.svg";
import emailicon from "../../../assets/images/settings_page_icons/email.svg";
import location from "../../../assets/images/settings_page_icons/location.svg";
import PhoneInput from "react-phone-input-2";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { validatePhoneWithCountryCode } from "../../../utils/functions";

const AddEditUser = () => {
  let emptyObj = {
    id: "",
    first_name: "",
    last_name: "",
    dob: "",
    gender: "",
    profile_image: "",
    city: "",
    country: "",
    address: "",
    phone_number: "",
    job_title: "",
    email: "",
    password: "",
    confirm_password: "",
    user_social_media: [
      { media_type: "facebook", url: "", placeholder: "facebook.com/" },
      { media_type: "twitter", url: "", placeholder: "twitter.com/" },
      { media_type: "linkdin", url: "", placeholder: "linkedin.com/" },
      { media_type: "dribble", url: "", placeholder: "dribbble.com/" },
      { media_type: "instagram", url: "", placeholder: "instagram.com/" },
      { media_type: "behance", url: "", placeholder: "behance.net/" },
    ],
  };

  const { userId } = useParams();
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [profile, setProfile] = useState(emptyObj);
  const [defaultMedias, setDefaultMedias] = useState([]);

  // Hanlde on change
  const handleChange = (e, i) => {
    const name = e.target.name;
    const value = e.target.value;
    setProfile({ ...profile, [name]: value });
  };

  // Validate form
  const validateForm = () => {
    let profileData = profile;
    if (!userId) delete profileData.id;
    let newErrors = ValidateSchema(profileData, AddEditUserSchema);
    setErrors(newErrors);
    if (Object.keys(newErrors).length) return false;

    if (profileData.phone_number.length > profileData.country_phone.length) {
      let resultPhone = validatePhoneWithCountryCode(
        profileData.country_phone,
        profileData.phone_number
      );
      if (resultPhone) {
        setErrors({
          phone_number: resultPhone,
        });
        return false;
      }
    }

    return true;
  };

  // Handle Submit
  const handleSubmit = async (e) => {
    if (!validateForm()) return;
    setLoading(true);
    profile.user_social_media.map((ele) => {
      ["updated_at", "created_at", "placeholder"].forEach((e) => delete ele[e]);
    });

    let newDob = profile.dob;
    if (profile.dob) {
      newDob = `${
        newDob.getMonth() + 1
      }/${newDob.getDate()}/${newDob.getFullYear()}`;
    }

    let payload = { ...profile };

    payload.dob = newDob;
    if (!payload.password) delete payload.password;
    if (!payload.confirm_password) delete payload.confirm_password;

    if (payload.phone_number && payload.country_phone.length > 0) {
      payload.phone_number = payload.phone_number.slice(
        payload.country_phone.length,
        payload.phone_number.length
      );
    } else {
      payload.country_phone = "";
    }
    if (!payload.phone_number && payload.country_phone) {
      payload.country_phone = "";
    }

    if (userId) {
      let { status, message, data } = await put(
        `${USER}/${userId}`,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );

      if (status) {
        navigate("/users");
      }

      showToast(status, message);
    } else {
      let { status, message, data } = await post(
        `${USER}`,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );

      if (status) {
        navigate("/users");
      }
      showToast(status, message);
    }
    setLoading(false);
  };

  // load user
  const loadUser = async () => {
    setLoading(true);
    let { status, message, data } = await get(
      USER + "/" + userId,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      let tempMedias = profile.user_social_media.map((ourResult) => {
        let result = data.user_social_media.find(
          (apiResult) => apiResult.media_type == ourResult.media_type
        );
        return result ?? ourResult;
      });

      if (data.dob) {
        data.dob = new Date(data.dob);
      }

      if (data.country_phone) {
        data.country_phone = `${data.country_phone}`;
      } else {
        data.country_phone = "";
      }

      if (data.phone_number) {
        data.phone_number = `${data.country_phone ? data.country_phone : ""}${
          data.phone_number
        }`;
      } else {
        data.phone_number = "";
      }

      data = { ...data, password: "", confirm_password: "" };

      setProfile(data);
      setDefaultMedias(tempMedias);
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  const handleDateChange = (e) => {
    setProfile({ ...profile, dob: e });
  };

  useEffect(() => {
    if (userId) {
      loadUser();
    }
  }, [userId]);

  return (
    <>
      {!loading ? (
        <div>
          <h6> {userId ? "Edit" : "Add"} User </h6>
          <div className={`mainWrapper ${classes.outerWrapper}`}>
            <div className="row gx-5 gy-3">
              <div className="col-md-4 col-12">
                <SettingsInput
                  label="First Name"
                  type="text"
                  placeholder="First Name"
                  name="first_name"
                  value={profile.first_name}
                  handleChange={handleChange}
                  icon={person}
                  error={errors.first_name}
                />
              </div>

              <div className="col-md-4 col-12">
                <SettingsInput
                  label="Last Name"
                  type="text"
                  placeholder="Last Name"
                  name="last_name"
                  value={profile.last_name}
                  handleChange={handleChange}
                  icon={person}
                  error={errors.last_name}
                />
              </div>

              <div className="col-md-4 col-12">
                <SettingsInput
                  label="Email"
                  type="email"
                  placeholder="email@mail.com"
                  socialProfiles={false}
                  name="email"
                  value={profile.email}
                  handleChange={handleChange}
                  icon={emailicon}
                  error={errors.email}
                />
              </div>

              <div className="col-md-4 col-12">
                <label style={{ fontSize: "12px" }} className={classes.label}>
                  Contact Number
                </label>
                <PhoneInput
                  country={"us"}
                  enableSearch={true}
                  countryCodeEditable={false}
                  inputClass={
                    errors.phone_number
                      ? "phoneNumberInput errorInput"
                      : "phoneNumberInput"
                  }
                  value={profile.phone_number}
                  onChange={(value, country, e, formattedValue) => {
                    setProfile({
                      ...profile,
                      phone_number: value,
                      country_phone: country.dialCode,
                    });
                  }}
                />
                <p className="errorText">{errors?.phone_number || ""}</p>
              </div>

              <div className="col-md-4 col-12">
                <label style={{ fontSize: "12px" }} className={classes.label}>
                  Password
                </label>
                <input
                  type="password"
                  placeholder="Password"
                  name="password"
                  value={profile.password}
                  className={
                    errors?.password ? "globalInput errorInput" : "globalInput"
                  }
                  onChange={handleChange}
                />
                <p className="errorText">{errors?.password || ""}</p>
              </div>

              <div className="col-md-4 col-12">
                <label style={{ fontSize: "12px" }} className={classes.label}>
                  Confirm Password
                </label>
                <input
                  type="password"
                  placeholder="Confirm Password"
                  name="confirm_password"
                  value={profile.confirm_password}
                  className={
                    errors?.confirm_password
                      ? "globalInput errorInput"
                      : "globalInput"
                  }
                  onChange={handleChange}
                />
                <p className="errorText">{errors?.confirm_password || ""}</p>
              </div>

              <div className="col-md-4 col-12">
                <SettingsInput
                  label="City"
                  type="text"
                  placeholder="City"
                  socialProfiles={false}
                  name="city"
                  value={profile.city}
                  handleChange={handleChange}
                  icon={location}
                  error={errors.city}
                />
              </div>
              <div className="col-md-4 col-12">
                <SettingsInput
                  label="Country"
                  type="text"
                  placeholder="Country"
                  socialProfiles={false}
                  name="country"
                  value={profile.country}
                  handleChange={handleChange}
                  icon={location}
                  error={errors.country}
                />
              </div>

              <div className="col-md-4 col-12">
                <SettingsInput
                  label="Address"
                  type="text"
                  placeholder="Address"
                  socialProfiles={false}
                  name="address"
                  value={profile.address}
                  handleChange={handleChange}
                  icon={location}
                  error={errors.address}
                />
              </div>

              {/* <div className="col-md-4 col-12">
                <SettingsInput
                  label="Date of Birth"
                  type="date"
                  placeholder="27/5/2002"
                  socialProfiles={false}
                  name="dob"
                  value={profile.dob}
                  handleChange={handleChange}
                  icon={dateofbirth}
                  max={new Date().toISOString().split("T")[0]}
                  error={errors.dob}
                />
              </div> */}
              <div className="col-md-4 col-12">
                {/* <SettingsInput
                  label="Date of Birth"
                  type="date"
                  placeholder="27/5/2002"
                  socialProfiles={false}
                  name="dob"
                  value={profile.dob}
                  handleChange={handleChange}
                  icon={dateofbirth}
                  max={new Date().toISOString().split("T")[0]}
                  error={errors.dob}
                /> */}
                <label htmlFor="" className={classes.label}>
                  Date of Birth
                </label>
                <DatePicker
                  selected={profile.dob}
                  onChange={handleDateChange}
                  dateFormat="MM/dd/yyyy"
                  className="dobInput"
                  placeholderText="MM/dd/yyyy"
                  showYearDropdown
                  maxDate={new Date()}
                  isClearable
                  yearDropdownItemNumber={110}
                  scrollableYearDropdown
                />
              </div>

              <div className="col-md-4 col-12">
                <div className={classes.selectWrapper}>
                  <label htmlFor="" style={{ fontSize: "12px" }}>
                    Gender
                  </label>
                  <div className="input-group globalInputWithIcon">
                    <select
                      className="form-select customSelect"
                      name="gender"
                      value={profile.gender}
                      onChange={handleChange}
                    >
                      <option value="">Select Gender</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                      <option value="other">Prefer not to say</option>
                    </select>
                  </div>
                  <span className="errorText">{errors.gender}</span>
                </div>
              </div>
            </div>
            <div className="formBottom">
              <Button
                className="submitButton"
                type="submit"
                onClick={handleSubmit}
              >
                Save
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default AddEditUser;
