import React, { useState, useEffect } from "react";
import classes from "../../../assets/styles/Pages/CouponManagement/CouponManagement.module.css";
import { useAtom } from "jotai";
import { loggedInUser } from "../../../utils/store";
import Loader from "../../../components/Loader";
import SettingsInput from "../../../components/Forms/SettingsInput";
import Button from "react-bootstrap/Button";
import "react-datepicker/dist/react-datepicker.css";
import { post, get, put } from "../../../server";
import _ from "lodash";
import {
  PRODUCT_DISCOUNT,
  PRODUCT_DISCOUNT_LINK,
} from "../../../utils/constants/apiUrl";
import showToast from "../../../helpers/showToast";
import { useParams, useNavigate } from "react-router-dom";

const AddEditProductDiscountLink = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  let emptyObj = {
    id: "",
    link: "",
    description: "",
    package_id: "",
    status: "",
  };
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [createDiscount, setCreateDiscount] = useState(emptyObj);
  const handleChange = (e, i) => {
    const name = e.target.name;
    const value = e.target.value;
    setCreateDiscount({ ...createDiscount, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //if (!validateForm()) return;
    setLoading(true);
    let payload = _.cloneDeep(createDiscount);
    if (id) {
      let { status, message, data } = await put(
        PRODUCT_DISCOUNT_LINK + `/${id}`,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
      if (status) {
        setCreateDiscount(data);
        navigate("/productDiscountLink");
      } else {
        showToast(status, message);
      }
    } else {
      let { status, message, data } = await post(
        PRODUCT_DISCOUNT_LINK,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
      if (status) {
        setCreateDiscount(data);
        navigate("/productDiscountLink");
      } else {
        showToast(status, message);
      }
    }

    setLoading(false);
  };

  const loadDiscounts = async () => {
    setLoading(true);
    let { status, message, data } = await get(
      `${PRODUCT_DISCOUNT_LINK}/${id}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      setCreateDiscount(data);
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (id) {
      loadDiscounts();
    }
  }, []);

  return (
    <>
      {!loading ? (
        <div>
          <div className="page_Header">
            <div className="d-flex justify-content-between align-items-md-center align-items-initial flex-md-row flex-column gap-3">
              <p className="pageTitle mb-0">
                {" "}
                {id ? "Edit" : "Add"} Discount Link
              </p>
            </div>
          </div>
          <div className={classes.content}>
            <div className={`mainWrapper ${classes.outerWrapper}`}>
              <div className="row align-items-center gx-5 gy-3">
                <div className="col-md-4 col-12">
                  <SettingsInput
                    label="Product Link"
                    type="text"
                    placeholder="Enter product link"
                    name="link"
                    value={createDiscount.link}
                    handleChange={handleChange}
                    error={errors.link}
                  />
                </div>

                <div className="col-md-4 col-12">
                  <div className={classes.selectWrapper}>
                    <label
                      htmlFor=""
                      style={{
                        fontSize: "12px",
                        marginBottom: "10px",
                        lineHeight: "18px",
                      }}
                    >
                      Select package
                    </label>
                    <div className="input-group globalInputWithIcon">
                      <select
                        className="form-select customSelect"
                        name="package_id"
                        value={createDiscount.package_id}
                        onChange={handleChange}
                      >
                        <option value="">Select package</option>
                        <option value="2">Premium</option>
                        <option value="3">Elite</option>
                        <option value="5">Team Elite</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-12">
                  <div className={classes.selectWrapper}>
                    <label
                      htmlFor=""
                      style={{
                        fontSize: "12px",
                        marginBottom: "10px",
                        lineHeight: "18px",
                      }}
                    >
                      Status
                    </label>
                    <div className="input-group globalInputWithIcon">
                      <select
                        className="form-select customSelect"
                        name="status"
                        value={createDiscount.status}
                        onChange={handleChange}
                      >
                        <option value="">Select Status</option>
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <label
                    htmlFor=""
                    style={{
                      fontSize: "12px",
                      marginBottom: "10px",
                      lineHeight: "18px",
                    }}
                  >
                    Description
                  </label>
                  <textarea
                    name="description"
                    rows="2"
                    value={createDiscount.description}
                    placeholder="Add Description"
                    onChange={handleChange}
                    className={
                      errors?.description
                        ? "globalTextArea errorInput"
                        : "globalTextArea"
                    }
                  />
                  <p className="errorText">{errors?.description || ""}</p>
                </div>
                <div className="col-12 noteText">
                  NOTE: After creating a new link or editing a link, if any
                  other link under that package is active, then the previous
                  link will be deactivated.
                </div>
              </div>
              <div className="formBottom">
                <Button
                  className="submitButton"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
      <style type="text/css">
        {`
        .noteText{
          color: var(--error_color); 
          font-size: 12px; 
          text-align: left;
          margin-bottom:0;
        `}
      </style>
    </>
  );
};

export default AddEditProductDiscountLink;
