import React, { useState } from "react";
import playstore from "../assets/images/home_page_images/playstore.svg";
import appstore from "../assets/images/home_page_images/appstore.svg";
import { ReactComponent as Hero } from "../assets/images/home_page_images/heroimage.svg";
import classes from "../assets/styles/HeroSection.module.css";

import remove from "../assets/images/home_page_images/remove.svg";

import { Link } from "react-router-dom";
const HeroSection = () => {
  const [removeHeroSection, setRemoveHeroSection] = useState(false);
  const handleRemoveHeroSection = () => {
    setRemoveHeroSection(true);
  };

  return (
    <>
      <div
        className={
          removeHeroSection
            ? classes.removeHeroSectionWrapper
            : classes.heroSectionWrapper
        }
      >
        <div className="row flex-md-row flex-column-reverse p-relative gy-md-0 gy-3">
          <div className="col-md-6 col-12">
            <div>
              <h5 className={classes.title}>Contact Mobile App</h5>
              <p className={classes.description}>
                Embarking on a journey where connections deepen, sharing
                simplifies, and inspiration becomes the driving force.
                Experience the future of networking with us, and together, let’s
                “Connect. Share. Inspire.”
              </p>
            </div>
            <div className={classes.imagesWrapper}>
              <div>
                <a
                  //href="https://play.google.com/store/apps/details?id=com.contactco.contactapp"
                  href="https://play.google.com/store/apps/details?id=com.contactco.contactapp"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={playstore} alt="" />
                </a>
              </div>
              <div>
                <a
                  //href="https://apps.apple.com/us/app/contact-co-connect-smarter/id6683301913"
                  href="https://apps.apple.com/us/app/contact-co-connect-smarter/id6683301913"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={appstore} alt="" />
                </a>
              </div>
            </div>
            <div className={classes.downloadBtnWrapper}>
              <Link to="https://apps.apple.com/us/app/contact-co-connect-smarter/id6683301913">
                <button className={classes.downloadBtn}>Download App</button>
              </Link>
              <Link to="/help/aboutus">
                <button className={classes.cancelBtn}>Learn More</button>
              </Link>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <div className={classes.heroImgWrapper}>
              <Hero />
              {/* <img src={hero} alt="" className={classes.heroImage} /> */}
            </div>
          </div>
        </div>
        <img
          src={remove}
          alt=""
          className={classes.heroSectionRemoveBtn}
          onClick={handleRemoveHeroSection}
        />
      </div>
    </>
  );
};

export default HeroSection;
