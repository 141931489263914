import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./CheckoutForm";

// Load Stripe Data
const stripePromise = loadStripe(
  // "pk_test_51OZ7XQSBnxMCCnjEI1odHcAyBmW5WZd48fFUxnvHPpg2LDNKRGGCOJ3sU78SW9vi5wOmuLrwX2qxf8slIZpXFukR00DuK51ScU"
  // "pk_test_51LsD48ErALx3fhV7MXHeYvMoWC4uLJr33gThVHtfuMAbiE9kNWsDMsYmKAcv1KWGSlSBPiQwplojALZAJ1fUk8bO00NnHNL8FY"
  "pk_live_51LsD48ErALx3fhV7pL6Gg2Wd2ymytgrKjj1tjb9cJICFlMAO24seG1sVDFTtKBFuOeD9luN1KSlS7Ho3xuY9tRZt00GZDEzFrx"
);

// Stripe Options
const elementsOptions = {
  fonts: [
    {
      cssSrc: "https://fonts.googleapis.com/css?family=Roboto",
    },
  ],
  locale: "en",
  placeholder: {
    number: "Card number",
    cvc: "CVC",
    expiry: "MM/YY",
  },
  style: {
    base: {
      fontSize: "16px",
      color: "#fff",
      "::placeholder": {
        color: "#fff",
      },
    },
    invalid: {
      color: "#9e2146",
    },
  },
};

const Checkout = () => {
  return (
    <Elements stripe={stripePromise} options={elementsOptions}>
      <CheckoutForm />
    </Elements>
  );
};

export default Checkout;
