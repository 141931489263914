import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import PersonalCard from "../../components/PageComponents/Home/PersonalCard";
import BusinessCard from "../../components/PageComponents/Home/BusinessCard";
import classes from "../../assets/styles/Home.module.css";
import GetEmergencyContact from "../../components/PageComponents/Home/GetEmergencyContact";
import ShowCustomURLS from "../../components/PageComponents/Home/ShowCustomURLS";
import ShowFileUpload from "../../components/PageComponents/Home/ShowFileUpload";
import ShowPayment from "../../components/PageComponents/Home/ShowPayment";
import HeroSection from "../../components/HeroSection";

import {
  Card_URL,
  EMERGENCY_CONTACT,
  PAYMENT,
  CUSTOME_URL,
  FILE_UPLOAD,
  PUBLIC_TEAM_MEMBER_FUNCTIONS,
} from "../../utils/constants/apiUrl";
import { deleteRecord, post, put } from "../../server";
import { loggedInUser, globalAppSetting, teamIDCheck } from "../../utils/store";
import { useAtom } from "jotai";
import Loader from "../../components/Loader";
import showToast from "../../helpers/showToast";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import _ from "lodash";
import CustomURLSAddEditModal from "../../components/Modals/CustomURLSAddEditModal";

import FileUploadAddEditModal from "../../components/Modals/FileUploadAddEditModal";
import { formatUrl } from "../../utils/functions";
import uploadImage from "../../helpers/uploadImage";
import handlePhoneNumber from "../../helpers/handlePhoneNumber";
import PaymentAddEditModal from "../../components/Modals/PaymentAddEditModal";
import { Image } from "react-bootstrap";
import baseURL from "../../utils/constants/baseUrl";
import dummyImage from "../../assets/images/global_icons/dummyimage.svg";

const emptyEmergencyContactData = {
  isNew: true,
  id: "",
  first_name: "",
  last_name: "",
  country_phone: "",
  phone_number: "",
  image: "",
  description: "",
};

const emptyCustomUrlData = {
  isNew: true,
  id: "",
  title: "",
  url_path: "",
};

const emptyPaymentData = {
  isNew: true,
  id: "",
  title: "",
  url_path: "",
  payment_type: "",
};

const emptyFilesData = {
  isNew: true,
  id: "",
  title: "",
  url_path: "",
};

const PublicFunctions = () => {
  const { teamId, userId } = useParams();

  const [loggedUser] = useAtom(loggedInUser);
  const [userTeamId, setUserTeamId] = useAtom(teamIDCheck);
  const [appSetting, setAppSetting] = useAtom(globalAppSetting);
  const [loading, setLoading] = useState(true);
  const [modalLoading, setModalLoading] = useState(false);
  const [personalCards, setPersonalCards] = useState([]);
  const [businessCards, setBusinessCards] = useState([]);
  const [personalCardsCount, setPersonalCardsCount] = useState(0);
  const [businessCardsCount, setBusinessCardsCount] = useState(0);
  const [emergencyContacts, setEmergencyContacts] = useState([]);
  const [customUrls, setCustomUrls] = useState([]);
  const [payment, setPayment] = useState([]);
  const [venmos, setVenmos] = useState([]);
  const [files, setFiles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [cards, setCards] = useState([]);

  const [teamMember, setTeamMember] = useState({});

  const [deleteId, setDeleteId] = useState(null);
  const [deleteType, setDeleteType] = useState(null);

  const [emergencyContactData, setEmergencyContactData] = useState(
    emptyEmergencyContactData
  );
  const [customUrlData, setCustomUrlData] = useState(emptyCustomUrlData);
  const [paymentData, setPaymentData] = useState(emptyPaymentData);
  const [paymentCount, setPaymentCount] = useState("");
  const [fileUploadData, setFileUploadData] = useState(emptyFilesData);

  const [showCustomUrlModal, setShowCustomUrlModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showFileUploadModal, setShowFileUploadModal] = useState(false);

  const handlePaymentOpenModal = () => {
    setShowPaymentModal(true);
  };

  const handlePaymentCloseModal = () => {
    setPaymentData(emptyPaymentData);
    setShowPaymentModal(false);
  };
  const handleCustomUrlOpenModal = () => {
    setShowCustomUrlModal(true);
  };

  const handleCustomUrlCloseModal = () => {
    setCustomUrlData(emptyCustomUrlData);
    setShowCustomUrlModal(false);
  };
  const handleFileUploadOpenModal = () => {
    setShowFileUploadModal(true);
  };

  const handleFileUploadCloseModal = () => {
    setFileUploadData(emptyFilesData);
    setShowFileUploadModal(false);
  };

  const handleDelete = (id, type) => {
    setDeleteId(id);
    setDeleteType(Card_URL);
    handleShowModal(true);
  };

  const handleEmergencyDelete = (id) => {
    setDeleteId(id);
    setDeleteType(EMERGENCY_CONTACT);
    handleShowModal(true);
  };

  const handleCustomDelete = (id) => {
    setDeleteId(id);
    setDeleteType(CUSTOME_URL);
    handleShowModal(true);
  };
  const handleFileUploadDelete = (id) => {
    setDeleteId(id);
    setDeleteType(FILE_UPLOAD);
    handleShowModal(true);
  };

  const handlePaymentDelete = (id) => {
    setDeleteId(id);
    setDeleteType(PAYMENT);
    handleShowModal(true);
  };

  const handleConfirmDelete = async () => {
    handleCloseModal(true);
    setLoading(true);

    let { status, message } = await deleteRecord(
      `${deleteType}/${deleteId}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      await getHomeDashboardData();
      showToast(status, message);
    } else {
      showToast(status, message);
    }

    setLoading(false);
  };

  const handlePersonalBusinessCard = (data) => {
    let personalCardsData = [];
    let businessCardsData = [];
    data.forEach((card) => {
      if (card.card_type === "personal") {
        personalCardsData.push(card);
      } else {
        businessCardsData.push(card);
      }

      setPersonalCards(personalCardsData);
      setBusinessCards(businessCardsData);
    });
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const getHomeDashboardData = async () => {
    setLoading(true);
    const payload = {
      user_id: userId,
      team_id: teamId,
    };
    let { status, message, data } = await post(
      PUBLIC_TEAM_MEMBER_FUNCTIONS,
      payload
    );

    if (status) {
      if (Object.keys(data).length > 0) {
        setTeamMember(data.user_data);
        setPersonalCardsCount(data.personalCardCount);
        setBusinessCardsCount(data.businessCardCount);

        if (data.cards.length > 0) {
          setCards(data.cards);

          let personalCardsData = [];
          let businessCardsData = [];

          data.cards.forEach((card) => {
            if (card.card_type === "personal") {
              personalCardsData.push(card);
            } else {
              businessCardsData.push(card);
            }

            setPersonalCards(personalCardsData);
            setBusinessCards(businessCardsData);
          });
        } else {
          setCards([]);
          setPersonalCards([]);
          setBusinessCards([]);
        }

        setCustomUrls(data.customUrls);
        setEmergencyContacts(data.emergencyContacts);
        setPayment(data.onlyPayments);
        setPaymentCount(
          data.cashAppCount + data.customPaymentCount + data.venmoCount
        );
        setFiles(data.fileUploads);
      }
    } else {
      // error message
      showToast(status, message);
    }
    setLoading(false);
  };

  const handleSavePayment = async () => {
    setModalLoading(true);

    let payload = _.cloneDeep(paymentData);
    payload.url_path = formatUrl(payload.url_path);

    let result = {};

    if (payload.id) {
      result = await put(
        PAYMENT + `/${payload.id}`,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
    } else {
      if (userTeamId) {
        payload.team_id = userTeamId;
      }
      result = await post(
        PAYMENT,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
    }

    let { status, message } = result;

    if (status) {
      await getHomeDashboardData();
      handleCloseModal();
    }

    showToast(status, message);
    setModalLoading(false);
  };

  const handleSaveCustomUrl = async () => {
    setModalLoading(true);

    let payload = _.cloneDeep(customUrlData);
    payload.url_path = formatUrl(payload.url_path);

    let result = {};

    if (payload.id) {
      result = await put(
        CUSTOME_URL + `/${payload.id}`,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
    } else {
      if (userTeamId) {
        payload.team_id = userTeamId;
      }
      result = await post(
        CUSTOME_URL,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
    }

    let { status, message } = result;

    if (status) {
      await getHomeDashboardData();
      handleCloseModal();
    }

    showToast(status, message);
    setModalLoading(false);
  };
  const handleSaveFileUpload = async () => {
    setModalLoading(true);

    let payload = _.cloneDeep(fileUploadData);
    payload.url_path = formatUrl(payload.url_path);

    let result = {};

    if (payload.id) {
      result = await put(
        FILE_UPLOAD + `/${payload.id}`,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
    } else {
      if (userTeamId) {
        payload.team_id = userTeamId;
      }
      result = await post(
        FILE_UPLOAD,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
    }

    let { status, message } = result;

    if (status) {
      await getHomeDashboardData();
      handleCloseModal();
    }

    showToast(status, message);
    setModalLoading(false);
  };

  const handleSaveEmergencyContact = async (newData) => {
    setModalLoading(true);

    let payload = _.cloneDeep(newData);

    handlePhoneNumber(payload);

    if (typeof payload.image == "object" && payload.image) {
      const { status, message, data } = await uploadImage(
        "emergency_contact",
        payload.image,
        loggedUser?.token
      );

      if (status) {
        payload.image = data;
      } else {
        showToast(status, message);
        setLoading(false);
        return;
      }
    }

    let result = {};

    if (payload.id) {
      result = await put(
        EMERGENCY_CONTACT + `/${payload.id}`,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
    } else {
      if (userTeamId) {
        payload.team_id = userTeamId;
      }
      result = await post(
        EMERGENCY_CONTACT,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
    }

    let { status, message } = result;

    if (status) {
      await getHomeDashboardData();
      setEmergencyContactData(emptyEmergencyContactData);
    } else {
      setModalLoading(false);
    }

    showToast(status, message);
    setModalLoading(false);
    setLoading(false);
  };

  const handlePersonalScrollLeft = () => {
    document.getElementById("personalCardWrapper").scrollLeft -= 220;
  };

  const handlePersonalScrollRight = () => {
    document.getElementById("personalCardWrapper").scrollLeft += 220;
  };

  const handleBusinessScrollLeft = () => {
    document.getElementById("businessCardWrapper").scrollLeft -= 220;
  };

  const handleBusinessScrollRight = () => {
    document.getElementById("businessCardWrapper").scrollLeft += 220;
  };

  useEffect(() => {
    getHomeDashboardData();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {cards.length === 0 &&
      emergencyContacts.length === 0 &&
      customUrls.length === 0 &&
      venmos.length === 0 &&
      files.length === 0 &&
      payment.length === 0 ? (
        <>
          <div className={classes.noFunctionsCreated}>
            <h6>No Functions Created against this Team Memeber</h6>
          </div>
          {/* <Missing message="No Functions Created against this Team Member" /> */}
        </>
      ) : (
        <>
          <nav className={classes.navbarSection}>
            <div className={classes.profileImgeWrapper}>
              <Image
                className="globalIMageBorder"
                src={
                  teamMember.profile_image
                    ? `${baseURL.PUBLIC_URL}${teamMember.profile_image}`
                    : dummyImage
                }
                roundedCircle={true}
              />
            </div>
            <div className={classes.personProfile}>
              <h6>
                {`${teamMember.first_name}
                    ${teamMember.last_name}`}
              </h6>
            </div>
          </nav>
          <div className={`container-fluid py-4 px-4 ${classes.mainWrapper}`}>
            <HeroSection />
            {/* Cards Section */}
            <div className={`${classes.cardsMainWrapper}`}>
              <h6 className={classes.title}>Cards</h6>
              <div className={classes.cardsWrapper}>
                <div className={classes.linkViewAllWrapper}>
                  <p>Personal Card ({personalCardsCount})</p>
                  <div>
                    <FiChevronLeft
                      className={classes.arrowBtn}
                      onClick={handlePersonalScrollLeft}
                    />
                    <FiChevronRight
                      className={classes.arrowBtn}
                      onClick={handlePersonalScrollRight}
                    />
                  </div>
                </div>
                <div className={classes.boxWrapper} id="personalCardWrapper">
                  {personalCards.length !== 0 ||
                  businessCards.length !== 0 ? null : (
                    <div className={classes.noCards}>
                      No Cards Created against this Team Memeber
                    </div>
                  )}

                  {personalCards.map((card) => {
                    return (
                      <PersonalCard
                        key={`personalCard${card.id}`}
                        {...card}
                        handleDelete={handleDelete}
                        cardListing={personalCards}
                        setCardListing={setPersonalCards}
                        publicMode={true}
                      />
                    );
                  })}
                </div>

                <div className={classes.linkViewAllWrapper}>
                  <p className={classes.totalBusinessCardsTitle}>
                    Business Card ({businessCardsCount})
                  </p>
                  <div>
                    <FiChevronLeft
                      className={classes.arrowBtn}
                      onClick={handleBusinessScrollLeft}
                    />
                    <FiChevronRight
                      className={classes.arrowBtn}
                      onClick={handleBusinessScrollRight}
                    />
                  </div>
                </div>

                <div className={classes.boxWrapper} id="businessCardWrapper">
                  {businessCards.map((card) => {
                    return (
                      <BusinessCard
                        key={`businessCard${card.id}`}
                        {...card}
                        handleDelete={handleDelete}
                        cardListing={businessCards}
                        setCardListing={setBusinessCards}
                        publicMode={true}
                      />
                    );
                  })}
                </div>
              </div>
            </div>

            {/* Emergency Contact and Custom URL'S  Section*/}
            <div className="row my-4 g-xl-3 gy-4 flex-xl-row flex-column">
              <div className="col-xl-6 col-12">
                <div className={classes.emergencyContactsWrapper}>
                  <h6 className={classes.title}>Emergency Contacts</h6>
                  <div className={classes.emergencyContentWrapper}>
                    <div
                      className={classes.linkViewAllWrapper}
                      style={{ marginBottom: "20px" }}
                    >
                      <p>Contacts ({emergencyContacts.length})</p>
                    </div>

                    <div>
                      {emergencyContacts.length !== 0 ? null : (
                        <div className={classes.noRecord}>
                          No Emergency Contact Created against this Team Memeber
                        </div>
                      )}

                      <div
                        className={classes.emergencyContactCardsWrapper}
                        style={{ marginTop: 0 }}
                      >
                        {emergencyContacts.map((contact) => {
                          return (
                            <GetEmergencyContact
                              key={`emergencyContact${contact.id}`}
                              {...contact}
                              emptyEmergencyContactData={
                                emptyEmergencyContactData
                              }
                              handleSaveShowModal={handleSaveEmergencyContact}
                              selectedData={emergencyContactData}
                              setSelectedData={setEmergencyContactData}
                              handleEmergencyDelete={handleEmergencyDelete}
                              loading={modalLoading}
                              publicMode={true}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-6 col-12">
                <div className={classes.emergencyContactsWrapper}>
                  <h6 className={classes.title}>Custom URL’s</h6>
                  <div className={classes.emergencyContentWrapper}>
                    <div
                      className={classes.linkViewAllWrapper}
                      style={{ marginBottom: "20px" }}
                    >
                      <p>Custom URL's ({customUrls.length})</p>
                    </div>

                    <div>
                      {customUrls.length !== 0 ? null : (
                        <div className={classes.noRecord}>
                          No Custom URL's Created against this Team Memeber
                        </div>
                      )}

                      <div
                        className={classes.emergencyContactCardsWrapper}
                        style={{ marginTop: 0 }}
                      >
                        {customUrls.map((custom) => {
                          return (
                            <ShowCustomURLS
                              key={`custom${custom.id}`}
                              {...custom}
                              emptyCustomUrlData={emptyCustomUrlData}
                              handleSaveShowModal={handleSaveCustomUrl}
                              selectedData={customUrlData}
                              setSelectedData={setCustomUrlData}
                              handleCustomDelete={handleCustomDelete}
                              loading={modalLoading}
                              publicMode={true}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/*Payment, File Upload*/}
            <div className="row my-4 g-xl-3 gy-4 flex-xl-row flex-column">
              <div className="col-xl-6 col-12">
                <div className={classes.emergencyContactsWrapper}>
                  <h6 className={classes.title}>Payment Services</h6>
                  <div className={classes.emergencyContentWrapper}>
                    <div
                      className={classes.linkViewAllWrapper}
                      style={{ marginBottom: "20px" }}
                    >
                      <p>Payments Services ({paymentCount})</p>
                    </div>

                    <div>
                      {payment.length !== 0 ? null : (
                        <div className={classes.noRecord}>
                          No Payments Created against this Team Memeber
                        </div>
                      )}

                      <div
                        className={classes.emergencyContactCardsWrapper}
                        style={{ marginTop: 0 }}
                      >
                        {payment.map((single) => {
                          return (
                            <ShowPayment
                              key={`payment${single.id}`}
                              {...single}
                              emptyPaymentData={emptyPaymentData}
                              handleSaveShowModal={handleSavePayment}
                              selectedData={paymentData}
                              setSelectedData={setPaymentData}
                              handlePaymentDelete={handlePaymentDelete}
                              loading={modalLoading}
                              publicMode={true}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-xl-6 col-12">
                <div className={classes.emergencyContactsWrapper}>
                  <h6 className={classes.title}>File Upload URL</h6>
                  <div className={classes.emergencyContentWrapper}>
                    <div
                      className={classes.linkViewAllWrapper}
                      style={{ marginBottom: "20px" }}
                    >
                      <p>File Upload URL ({files.length})</p>
                    </div>

                    <div>
                      {files.length !== 0 ? null : (
                        <div className={classes.noRecord}>
                          No Files Created against this Team Memeber
                        </div>
                      )}

                      <div
                        className={classes.emergencyContactCardsWrapper}
                        style={{ marginTop: 0 }}
                      >
                        {files.map((file) => {
                          return (
                            <ShowFileUpload
                              key={`custom${file.id}`}
                              {...file}
                              emptyFilesData={emptyFilesData}
                              handleSaveShowModal={handleSaveFileUpload}
                              selectedData={fileUploadData}
                              setSelectedData={setFileUploadData}
                              handleFileUploadDelete={handleFileUploadDelete}
                              loading={modalLoading}
                              publicMode={true}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <CustomURLSAddEditModal
            loading={modalLoading}
            show={showCustomUrlModal}
            data={customUrlData}
            setSelectedData={setCustomUrlData}
            handleSaveShowModal={async () => {
              await handleSaveCustomUrl();
              handleCustomUrlCloseModal();
            }}
            handleCloseModal={handleCustomUrlCloseModal}
          />
          <FileUploadAddEditModal
            loading={modalLoading}
            show={showFileUploadModal}
            data={fileUploadData}
            setSelectedData={setFileUploadData}
            handleSaveShowModal={async () => {
              await handleSaveFileUpload();
              handleFileUploadCloseModal();
            }}
            handleCloseModal={handleFileUploadCloseModal}
          />

          <PaymentAddEditModal
            loading={modalLoading}
            show={showPaymentModal}
            data={paymentData}
            setSelectedData={setPaymentData}
            handleSaveShowModal={async () => {
              await handleSavePayment();
              handlePaymentCloseModal();
            }}
            handleCloseModal={handlePaymentCloseModal}
          />

          <ConfirmationModal
            show={showModal}
            handleCloseModal={handleCloseModal}
            handleConfirmDelete={handleConfirmDelete}
          />
        </>
      )}
    </>
  );
};

export default PublicFunctions;
