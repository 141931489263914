import React, { useState } from "react";
import { Modal, Form } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import classes from "../../assets/styles/QRCodeModal.module.css";
import { GiShare } from "react-icons/gi";
import { QRCode } from "react-qrcode-logo";
import qrCodeBorder from "../../assets/images/qr-code-border.svg";
import { SHARE_QR } from "../../utils/constants/common";
import qrCodeLogo from "../../assets/images/sidebar_icons/logoIcon.png";
import uploadQrLogo from "../../assets/images/function_page_icons/camera.svg";
import removeQrLogo from "../../assets/images/function_page_icons/nocamera.svg";
import crossicon from "../../assets/images/global_icons/crossicon.svg";
import ImageUploadSquare from "../../components/ImageUploadSquare";
import { BsImageFill } from "react-icons/bs";
import baseUrl from "../../utils/constants/baseUrl";
import showToast from "../../helpers/showToast";
import { CUSTOMIZE_QRCODE, IMAGE_UPLOAD } from "../../utils/constants/apiUrl";
import { loggedInUser, teamIDCheck } from "../../utils/store";
import { useAtom } from "jotai";
import { post } from "../../server";
const QRCodeModal = ({
  target,
  qrOpen,
  handleQRClose,
  setShowCopyPopup,
  handleDownload,
  qrCodeId,
  functionType,
  function_type,
  functionUuid,
  url,
  QRCodeCustomize,
  refreshData,
}) => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [userTeamId, setUserTeamId] = useAtom(teamIDCheck);
  const [loding, setLoading] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [image, selectImage] = useState("");
  const [uploadedImageUrl, setUploadedImageUrl] = useState("");
  const [bgColor, setBgColor] = useState(
    QRCodeCustomize?.qr_bg_color || "#ffffff"
  );
  const [fgColor, setFgColor] = useState(
    QRCodeCustomize?.qr_front_color || "#000000"
  );
  const [QRLogo, setQRLogo] = useState(QRCodeCustomize?.qr_image || qrCodeLogo);
  const [QRCodeImage, setQRCodeImage] = useState(
    QRCodeCustomize?.qr_code_image
  );
  const handleEditModalOpen = () => {
    setEditModalOpen(true);
  };

  const handleEditModalClose = () => {
    setEditModalOpen(false);
  };
  const handleUploadModalOpen = () => {
    setUploadModalOpen(true);
  };

  const handleUploadModalClose = () => {
    setUploadModalOpen(false);
  };

  const handleQRLogoSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (image && typeof image == "object") {
      let formDataObj = new FormData();
      formDataObj.append("image_type", "card_logo");
      formDataObj.append("image", image);

      let { status, message, data } = await post(
        IMAGE_UPLOAD,
        formDataObj,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
      if (status) {
        setUploadedImageUrl(data);
        setQRLogo(uploadedImageUrl);
        setUploadModalOpen(false);
      } else {
        showToast(status, message);
        setLoading(false);
        return false;
      }
    }
  };
  const handleRemoveLogoImage = () => {
    setQRLogo("");
    setUploadedImageUrl("");
  };
  const handleSave = async (e) => {
    e.preventDefault();
    setLoading(true);
    const qrCodeContainer = document.getElementById("qrCodeContainer");
    const childElement = qrCodeContainer.querySelector("#qr-code");
    const childELementDurl = childElement.toDataURL("image/png");
    const blob = await fetch(childELementDurl).then((res) => res.blob());

    let formDataObj = new FormData();
    formDataObj.append("image_type", "qr_code_image");
    const file = new File([blob], "qr_code_image.png", { type: "image/png" });
    formDataObj.append("image", file);
    let { status, message, data } = await post(
      IMAGE_UPLOAD,
      formDataObj,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      setQRCodeImage(data);
      const payload = {
        id: QRCodeCustomize?.id,
        function_type:
          QRCodeCustomize?.function_type || QRCodeCustomize?.functionType,
        function_id: QRCodeCustomize?.function_id,
        qr_bg_color: bgColor,
        qr_front_color: fgColor,
        qr_image: QRLogo,
        qr_code_image: data,
      };
      let { status, message } = await post(
        CUSTOMIZE_QRCODE,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
      if (status) {
        showToast(status, message);
        await refreshData();
        handleQRClose(false);
      } else {
        showToast(status, message);
        setLoading(false);
        return false;
      }
    } else {
      showToast(status, message);
      setLoading(false);
      return false;
    }
    setEditModalOpen(false);
  };
  return (
    <>
      <Modal
        show={qrOpen}
        onHide={handleQRClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={`customModal ${classes.QRCodeModal}`}
        size="sm"
      >
        <Modal.Header className={classes.modalHeader}>
          <div className="modalGradientCircle"></div>
          <MdClose onClick={handleQRClose} className="modalCloseBtn" />
        </Modal.Header>
        <Modal.Body
          style={{ padding: "0" }}
          //className={`d-flex justify-content-center flex-column align-items-center`}
        >
          <div
            className="d-flex flex-column align-items-center justify-content-center"
            style={{ marginTop: "-120px" }}
          >
            <div className={classes.qrWrapper}>
              <img className={classes.qrBorder} src={qrCodeBorder} alt="" />
              {QRCodeCustomize && QRCodeCustomize?.qr_code_image !== null ? (
                <img
                  className={classes.qrCodeImage}
                  src={baseUrl.PUBLIC_URL + QRCodeCustomize?.qr_code_image}
                  width="170px"
                  height="170px"
                  loading="lazy"
                  decoding="async"
                  alt=""
                  id={qrCodeId ? qrCodeId : "qr-code"}
                />
              ) : (
                <QRCode
                  value={
                    url ? url : `${SHARE_QR}${functionType}/${functionUuid}`
                  }
                  id={qrCodeId ? qrCodeId : "qr-code"}
                  logoImage={
                    QRCodeCustomize?.qr_image !== null
                      ? QRCodeCustomize?.qr_image
                      : baseUrl.PUBLIC_URL + qrCodeLogo
                  }
                  bgColor={
                    QRCodeCustomize?.qr_bg_color !== null
                      ? QRCodeCustomize?.qr_bg_color
                      : "#ffffff"
                  }
                  fgColor={
                    QRCodeCustomize?.qr_front_color !== null
                      ? QRCodeCustomize?.qr_front_color
                      : "#000000"
                  }
                  //logoPadding={3}
                  removeQrCodeBehindLogo
                />
                // <p>adbghyja</p>
              )}
            </div>
            <h5 style={{ margin: "10px" }}>QR Code</h5>
            <p
              style={{
                lineHeight: "18px",
                marginBottom: "5px",
                textAlign: "center",
              }}
            >
              Edit, Share or Download Your
              <br />
              QR Code
            </p>
          </div>
          <div className="row justify-content-center mx-0 px-3 gx-2">
            <div className="col-sm-6 text-center">
              <button
                className={`${classes.editBtn} d-block w-100`}
                ref={target}
                onClick={() => handleEditModalOpen({})}
              >
                Edit Qr Code
              </button>
            </div>
            <div className="col-sm-6 text-center">
              <button
                className={`${classes.shareBtn} d-block w-100`}
                ref={target}
                onClick={() => {
                  setShowCopyPopup(true);
                  setTimeout(() => {
                    setShowCopyPopup(false);
                  }, 2000);
                  navigator.clipboard.writeText(
                    url ? url : `${SHARE_QR}${functionType}/${functionUuid}`
                  );
                }}
              >
                <GiShare />
                Share
              </button>
            </div>
          </div>
          {QRCodeCustomize && QRCodeCustomize?.qr_code_image !== null ? (
            <div className={`customModalBtnWrapper ${classes.btnWrapper}`}>
              <a
                href={baseUrl.PUBLIC_URL + QRCodeImage}
                download="qrcode.png"
                target="_blank"
                rel="noreferrer"
              >
                <button>Download QR</button>
              </a>
            </div>
          ) : (
            <div className={`customModalBtnWrapper ${classes.btnWrapper}`}>
              <button onClick={handleDownload}>Download QR</button>
            </div>
          )}
          {/* <div className={`customModalBtnWrapper ${classes.btnWrapper}`}>
            <button onClick={handleDownload}>Download QR</button>
          </div> */}
        </Modal.Body>
      </Modal>

      <Modal
        show={editModalOpen}
        onHide={handleEditModalClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={`customModal editQRModal`}
        size="sm"
      >
        <Modal.Header className={classes.modalHeader}>
          <div className="modalGradientCircle"></div>
          <MdClose onClick={handleEditModalClose} className="modalCloseBtn" />
        </Modal.Header>

        <Modal.Body
          style={{ padding: "0" }}
          className={`d-flex justify-content-center flex-column align-items-center`}
        >
          <div
            className="d-flex flex-column align-items-center justify-content-center"
            style={{ width: "fit-content", marginTop: "-120px" }}
          >
            <div className={classes.qrWrapper}>
              <img className={classes.qrBorder} src={qrCodeBorder} alt="" />
              <div id="qrCodeContainer">
                <QRCode
                  value={
                    url ? url : `${SHARE_QR}${functionType}/${functionUuid}`
                  }
                  id={qrCodeId ? qrCodeId : "qr-code"}
                  logoImage={
                    uploadedImageUrl === ""
                      ? QRLogo
                      : baseUrl.PUBLIC_URL + uploadedImageUrl
                  }
                  bgColor={bgColor}
                  fgColor={fgColor}
                  logoPadding={3}
                  removeQrCodeBehindLogo
                />
              </div>
            </div>
            <button
              className={classes.saveBtn}
              ref={target}
              onClick={handleSave}
            >
              Save
            </button>
          </div>
        </Modal.Body>
        <Modal.Footer className={classes.qrEditFooter}>
          <ul className="list-unstyled m-0">
            <li className="mb-3">
              <button
                className={classes.uploadBtn}
                onClick={handleUploadModalOpen}
              >
                <img src={uploadQrLogo} alt="" />
              </button>
            </li>
            <li className="mb-3">
              <button
                className={classes.removeImgBtn}
                onClick={handleRemoveLogoImage}
              >
                <img src={removeQrLogo} alt="" />
              </button>
            </li>
            <li className="mb-3">
              <Form.Group controlId="">
                <input
                  type="color"
                  className={classes.colorPicker}
                  value={bgColor}
                  onChange={(e) => setBgColor(e.target.value)}
                />
              </Form.Group>
            </li>
            <li>
              <Form.Group controlId="">
                <input
                  type="color"
                  className={classes.colorPicker}
                  value={fgColor}
                  onChange={(e) => setFgColor(e.target.value)}
                />
              </Form.Group>
            </li>
          </ul>
        </Modal.Footer>
      </Modal>

      <Modal
        centered
        className={`customModal uploadModal`}
        size="md"
        show={uploadModalOpen}
      >
        <Modal.Header className={classes.mediaModalHeader}>
          <span>Upload</span>
          <img
            src={crossicon}
            alt=""
            style={{ cursor: "pointer" }}
            onClick={handleUploadModalClose}
          />
        </Modal.Header>

        <Modal.Body className={classes.mediaModalBody}>
          <div className="text-center">
            <div
              className={`d-flex justify-content-center align-items-center flex-column position-relative ${classes.imageUpload}`}
            >
              <ImageUploadSquare
                selectedImage={
                  typeof image == "object" ? image : baseUrl.PUBLIC_URL + image
                }
                setSelectedImage={selectImage}
              />
              <BsImageFill />
              <span>
                Drop your Images here, or <span>Browse</span>
              </span>
              <span>Support JPG, PNG, Only</span>
            </div>
            <button
              className={`${classes.saveBtn} mb-3 mt-0`}
              ref={target}
              onClick={handleQRLogoSave}
            >
              Save
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default QRCodeModal;
