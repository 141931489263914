import React, { useState, useEffect, useRef } from "react";
import classes from "../../assets/styles/Pages/Function/ViewMemory.module.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import headingicon from "../../assets/images/function_page_icons/headingicon.svg";
import contacticon from "../../assets/images/function_page_icons/contacticon.svg";
import Loader from "../../components/Loader";
import Image from "react-bootstrap/Image";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import html2canvas from "html2canvas";
import { GiShare } from "react-icons/gi";
import { QRCode } from "react-qrcode-logo";
import { MdClose } from "react-icons/md";
import { Modal } from "react-bootstrap";
import qrCodeBorder from "../../assets/images/qr-code-border.svg";
import {
  EMERGENCY_CONTACT,
  EMERGENCY_CONTACT_VCF_FILE_URL,
  MEMORY,
} from "../../utils/constants/apiUrl";
import { get, put, post } from "../../server";
import { loggedInUser, teamIDCheck } from "../../utils/store";
import baseURL from "../../utils/constants/baseUrl";
import { toast } from "react-toastify";
import { useAtom } from "jotai";
import FormInput from "../../components/Forms/FormInput";
import personicon from "../../assets/images/global_icons/personicon.svg";
import dummyImage from "../../assets/images/global_icons/dummyimage.svg";
import MemoryAddEditModal from "../../components/Modals/MemoryAddEditModal";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import showToast from "../../helpers/showToast";
import _ from "lodash";
import handlePhoneNumber from "../../helpers/handlePhoneNumber";
import uploadImage from "../../helpers/uploadImage";
import { mobileViewCheck } from "../../utils/store";
import QRCodeModal from "../../components/Modals/QRCodeModal";
import formatPhoneNumber from "../../helpers/formatPhoneNumber";
import useFunctionsCount from "../../hooks/useFunctionsCount";
import functionTypes from "../../utils/constants/functionTypes";

const ViewMemory = () => {
  const target = useRef(null);
  const navigate = useNavigate();
  const { id, uuid, teamId, userId } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const team_id = searchParams.get("team_id");
  const user_id = searchParams.get("user_id");

  const [userTeamId, setUserTeamId] = useAtom(teamIDCheck);
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [modalLoading, setModalLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedData, setSelectedData] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showCopyPopup, setShowCopyPopup] = useState(false);
  const [qrOpen, setQrOpen] = useState(false);
  const [mobileView] = useAtom(mobileViewCheck);
  const [memory, setMemory] = useState({});
  const [showFullText, setShowFullText] = useState(false);
  const { compareLimits, updateCount, limitExceededForStarter } =
    useFunctionsCount();
  const displayText = showFullText
    ? memory?.description
    : memory?.description?.length > 60
    ? memory?.description?.slice(0, 60) + "..."
    : memory?.description;

  const toggleText = () => {
    setShowFullText(!showFullText);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setMemory({ ...memory, [name]: value });
  };

  const handleOpenModal = () => {
    let newObj = _.cloneDeep(memory);
    setSelectedData(newObj);
    setShowModal(true);
  };

  const handleDownload = () => {
    html2canvas(document.querySelector("#qr-code")).then(function (canvas) {
      const link = document.createElement("a");
      link.download = "qrcode.png";
      link.href = canvas.toDataURL();
      link.click();
    });
  };

  const handleCloseModal = () => {
    setSelectedData({});
    setShowModal(false);
  };

  const handleQrOpen = (memory) => {
    setSelectedData(memory);
    setQrOpen(true);
  };

  const handleQRClose = () => {
    setQrOpen(false);
    setShowCopyPopup(false);
    setSelectedData("");
  };

  const handleSaveShowModal = async () => {
    setLoading(true);
    // let payload = _.cloneDeep(selectedData);
    // payload.media = formatUrl(payload.media);

    const formData = new FormData();
    formData.append("media_type", selectedData.media_type);
    formData.append("save_type", selectedData.save_type);
    formData.append("title", selectedData.title);
    formData.append("reminder", selectedData.reminder);

    // Append each selected media file to FormData
    const mediaFiles = Array.isArray(selectedData.medias)
      ? selectedData.medias
      : [];
    mediaFiles.forEach((file, index) => {
      formData.append(`medias[${index}]`, file);
    });
    let result = {};
    if (selectedData.id) {
      result = await post(
        MEMORY + `/update/${selectedData.id}`,
        formData,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
    }

    let { status, message, data } = result;

    if (status) {
      await loadMemory();
      handleCloseModal();
    } else {
      setLoading(false);
    }

    showToast(status, message);
  };

  const loadMemory = async () => {
    let queryParams = "";

    if (teamId || team_id || userTeamId) {
      queryParams = `?team_id=${teamId || team_id || userTeamId}`;
    }

    // if (userId || user_id) {
    //   queryParams += `${queryParams ? "&" : "?"}user_id=${userId || user_id}`;
    // }
    let { status, message, data } = await get(
      `${MEMORY}/${id}${queryParams}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      if (Object.keys(data).length > 0) {
        let objData = data;
        objData = { ...objData, user_id: loggedUser.id };
        setMemory(objData);
      }
    } else {
      // error message
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    loadMemory();
  }, []);

  const handleVcfFile = async () => {
    // setLoading(true);

    let { status, message, data } = await post(
      EMERGENCY_CONTACT_VCF_FILE_URL + `/${memory.uuid}`,
      memory,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      const link = document.createElement("a");
      link.download = "temp.vcf";
      link.href = data.url;
      link.click();
    } else {
      showToast(status, message);
    }
    // setLoading(false);
  };

  const handleUpdate = async () => {
    setLoading(true);
    let { status, message, data } = await put(
      EMERGENCY_CONTACT + `/${id}`,
      memory,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    // error message

    setLoading(false);
  };

  const handleGoBack = () => {
    navigate(-1);
    // if (teamId && userId) {
    //   navigate(-2);
    // } else {
    //   navigate("/function");
    // }
  };

  return (
    <>
      {!loading ? (
        <div className="row justify-content-center">
          <div className="col-12 col-md-6 col-lg-5">
            <div className={classes.mainWrapper}>
              <div
                className={`d-flex justify-content-between align-items-center`}
              >
                <h6 className={classes.title} style={{ textAlign: "left" }}>
                  {teamId && userId ? (
                    <Link
                      onClick={handleGoBack}
                      style={{ color: "var(--primary_text_color)" }}
                    >
                      Functions
                    </Link>
                  ) : (
                    <Link
                      to="/function"
                      style={{ color: "var(--primary_text_color)" }}
                    >
                      Functions
                    </Link>
                  )}

                  {` > Memory`}
                </h6>

                <button
                  type="button"
                  onClick={handleGoBack}
                  className="bg-transparent p-0 border-0"
                  style={{ color: "var(--primary_text_color)" }}
                >
                  <h6 className="back">{` < Back`}</h6>
                </button>
              </div>
              <div className={classes.cardWrapper}>
                <div
                  className={classes.profileCardWrapper}
                  // style={{ background: "var(--border_color)" }}
                >
                  <div className={classes.detailWrapper}>
                    <h6 className="mb-0 mt-0">{`${memory.title || ""}`}</h6>
                  </div>
                  {memory.media_type === "image" ? (
                    <>
                      {memory.medias.map((item) => {
                        return (
                          <div key={item.id}>
                            <Image
                              src={
                                baseURL.AWS_URL +
                                "dev/memory/" +
                                item.media_path
                              }
                              width="100%"
                              height="300px"
                              loading="lazy"
                              decoding="async"
                              className="memory-img mt-4"
                            />
                          </div>
                        );
                      })}
                    </>
                  ) : memory.media_type === "audio" ? (
                    <>
                      {memory.medias.map((item) => {
                        return (
                          <div key={item.id}>
                            <audio width="100%" controls className="mt-4 w-100">
                              <source
                                src={
                                  baseURL.AWS_URL +
                                  "dev/memory/" +
                                  item.media_path
                                }
                                type="audio/mpeg"
                              />
                            </audio>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <>
                      {memory.medias.map((item) => {
                        return (
                          <div key={item.id}>
                            <video width="100%" controls className="mt-4 w-100">
                              <source
                                src={
                                  baseURL.AWS_URL +
                                  "dev/memory/" +
                                  item.media_path
                                }
                                type="video/mp4"
                              />
                            </video>
                          </div>
                        );
                      })}
                    </>
                  )}
                  <div className={`mt-2  ${classes.savePhonebookBtnWrapper}`}>
                    <div className="d-flex align-items-center justify-content-center gap-3">
                      <button onClick={handleOpenModal} className={classes.btn}>
                        Edit
                      </button>
                      <button
                        onClick={() => handleQrOpen(memory)}
                        className={classes.btn}
                      >
                        Download QR
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}

      {showModal && (
        <MemoryAddEditModal
          show={showModal}
          data={selectedData}
          setSelectedData={setSelectedData}
          handleSaveShowModal={handleSaveShowModal}
          handleCloseModal={handleCloseModal}
          loading={loading}
        />
      )}

      {qrOpen && (
        // <QRCodeModal
        //   qrOpen={qrOpen}
        //   target={target}
        //   handleQRClose={handleQRClose}
        //   setShowCopyPopup={setShowCopyPopup}
        //   handleDownload={handleDownload}
        //   functionType="emergencyContact"
        //   functionUuid={selectedData}
        // />
        <QRCodeModal
          qrOpen={qrOpen}
          target={target}
          handleQRClose={handleQRClose}
          setShowCopyPopup={setShowCopyPopup}
          handleDownload={handleDownload}
          functionType={memory.function_type}
          function_type={memory.function_type}
          functionId={memory.id}
          functionUuid={memory.uuid}
          id={memory.id}
          QRCodeCustomize={memory.qrCodeCustomize}
          refreshData={loadMemory}
        />
      )}

      <Overlay
        target={target.current}
        show={showCopyPopup}
        placement={mobileView ? "left" : "right"}
      >
        {(props) => (
          <Tooltip id="overlay-example" {...props}>
            Link Copied
          </Tooltip>
        )}
      </Overlay>
      <style type="">
        {`
          .memory-img{
            object-fit:cover;
          }
            .back{
              font-size: 12px;
            }
        `}
      </style>
    </>
  );
};

export default ViewMemory;
