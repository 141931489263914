import React, { useState, useEffect, useMemo } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from "react-router-dom";
import DataTable, { createTheme } from "react-data-table-component";
import { useAtom } from "jotai";
import classes from "../../../assets/styles/Contact.module.css";
import { CUSTOME_URL, MEDICAL_ID } from "../../../utils/constants/apiUrl";
import { get, deleteRecord, post, put } from "../../../server";
import showToast from "../../../helpers/showToast";
import { loggedInUser } from "../../../utils/store";
import Loader from "../../../components/Loader";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal";
import FormInput from "../../../components/Forms/FormInput";
import searchIcon from "../../../assets/images/global_icons/searchicon.svg";
import { formatUrl } from "../../../utils/functions/index";
import _ from "lodash";
import MedicalAddEditModal from "../../../components/Modals/MedicalAddEditModal";
import Image from "react-bootstrap/Image";
import pictureicon from "../../../assets/images/function_page_icons/picIcon.svg";
import baseUrl from "../../../utils/constants/baseUrl";

const AllMedicalId = () => {
  let emptyMedicalIdData = {
    isNew: true,
    id: "",
    first_name: "",
    last_name: "",
    dob: "",
    primary_language: "",
    blood_type: "",
    weight: "",
    height: "",
    medical_condition: "",
    allergies_reactions: "",
    medical_notes: "",
    medications: "",
    photo: "",
    medicalIdEmergencyContacts: [
      { person_name: "", relation: "", country_phone: "", phone_number: "" },
    ],
  };

  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [loading, setLoading] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const [medicalID, setMedicalID] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState(emptyMedicalIdData);

  const handleFilter = (e) => {
    setFilterText(e.target.value);
  };

  // Handle main modal
  const handleShowModal = (obj) => {
    if (obj && Object.keys(obj).length) setSelectedData(obj);
    setShowModal(true);
  };

  const handleSaveShowModal = async () => {
    setLoading(true);

    let payload = _.cloneDeep(selectedData);
    payload.url_path = formatUrl(payload.url_path);

    let result = {};
    if (selectedData.id) {
      result = await put(
        CUSTOME_URL + `/${selectedData.id}`,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
    } else {
      result = await post(
        CUSTOME_URL,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
    }

    let { status, message, data } = result;

    if (status) {
      setLoading(false);

      let medicalIdsArr = _.cloneDeep(medicalID);

      // On Update
      if (selectedData.id) {
        const index = medicalID.findIndex((el) => el.id == selectedData.id);
        medicalIdsArr[index] = data;
      } else {
        medicalIdsArr.push(data);
      }

      setMedicalID(medicalIdsArr);
      handleCloseModal();
    } else {
      setLoading(false);
    }

    showToast(status, message);
  };

  const handleCloseModal = () => {
    setSelectedData(emptyMedicalIdData);
    setShowModal(false);
  };

  const loadMedicalIds = async () => {
    setLoading(true);
    let { status, message, data } = await get(
      MEDICAL_ID,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      if (data.length > 0) {
        setMedicalID(data);
      }
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadMedicalIds();
  }, []);

  const filteredItems = useMemo(() => {
    const filtered = medicalID.filter(
      (item) =>
        `${item?.user_data?.first_name || ""} ${
          item?.user_data?.last_name || ""
        }`
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
        item.title.toLowerCase().includes(filterText.toLowerCase()) ||
        item.url_path.includes(filterText.toLowerCase())
    );

    return filtered;
  }, [filterText, medicalID]);

  createTheme(
    "solarized",
    {
      background: {
        default: "background: var(--secondary_color)",
      },
    },
    "dark"
  );

  const columns = [
    {
      name: "Image",
      width: "10%",
      cell: (row) => (
        <div className={classes.titleWrapper}>
          <Image
            className="globalIMageBorder globalIMageFit"
            src={baseUrl.PUBLIC_URL + row.photo || pictureicon}
            roundedCircle={true}
            width="50px"
            height="50px"
            onError={(e) => {
              e.currentTarget.src = pictureicon;
            }}
          />
        </div>
      ),
    },
    {
      name: "Name",
      width: "15%",
      cell: (row) => (
        <p className="globalTitle">{`${row.first_name || ""} ${
          row.last_name || ""
        }`}</p>
      ),
    },
    {
      name: "Medical Condition",
      cell: (row) => (
        <p className="globalTitle">{row.medical_condition || "-"}</p>
      ),
    },
    {
      name: "Allergies Reactions",
      cell: (row) => (
        <p className="globalTitle">{row.allergies_reactions || "-"}</p>
      ),
    },
    {
      name: "Blood Group",
      cell: (row) => <p className="globalTitle">{row.blood_type || "-"}</p>,
    },
    {
      name: "Created in",
      width: "150px",
      cell: (row) => (
        <div className={"globalDetail"}>
          {row.team_id !== null ? "Team Mode" : "Individual"}
        </div>
      ),
    },
    // {
    //   name: "Team Name",
    //   width: "200px",
    //   cell: (row) => (
    //     <div className={"globalDetail"}>
    //       {row.team_detail !== null ? row.team_detail.name : "-"}
    //     </div>
    //   ),
    // },
    {
      name: "Action",
      right: "true",
      cell: (row) => (
        <div className={classes.action}>
          <button
            className="btn customActionDropdown dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <BsThreeDotsVertical className={"globalVerticalThreeDots"} />
          </button>
          <ul className="customActionDropdownMenu dropdown-menu dropdown-menu-end ">
            <li>
              <Link
                className="dropdown-item"
                to={`/medicalId/ViewMedicalId/${row.id}`}
              >
                View
              </Link>
            </li>
            <li>
              <Link
                className="dropdown-item"
                onClick={() => handleShowModal(row)}
              >
                Edit
              </Link>
            </li>
            <li>
              <Link
                className="dropdown-item"
                to="#"
                style={{ color: "#E35252" }}
                onClick={() => handleDelete(row.id)}
              >
                Delete
              </Link>
            </li>
          </ul>
        </div>
      ),
    },
  ];

  // Handle confirmation modal
  const handleShowConfirmationModal = () => setShowConfirmationModal(true);
  const handleCloseConfirmationModal = () => setShowConfirmationModal(false);

  // handle delete
  const handleDelete = (id) => {
    setDeleteId(id);
    handleShowConfirmationModal(true);
  };

  const handleConfirmDelete = async () => {
    handleCloseConfirmationModal(true);
    setLoading(true);
    let { status, message } = await deleteRecord(
      `${MEDICAL_ID}/${deleteId}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    showToast(status, message);

    if (status) {
      await loadMedicalIds();
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <>
      {!loading ? (
        <div className="">
          <div className="page_Header">
            {/* <Link to="AddEditAdminUser">
                <button className="primary_button align_right">Add</button>
              </Link> */}
            <div className="d-flex justify-content-between align-items-md-center align-items-initial flex-md-row flex-column gap-3">
              <p className="pageTitle">
                Medical IDs <span>({medicalID.length})</span>
              </p>
              <FormInput
                icon={searchIcon}
                placeholder="Search..."
                value={filterText}
                handleChange={handleFilter}
              />
            </div>
          </div>

          <div className={`listingWrapper mainWrapper`}>
            <DataTable
              columns={columns}
              data={filteredItems}
              paginationResetDefaultPage={resetPaginationToggle}
              pagination
              theme="solarized"
            />
          </div>

          <ConfirmationModal
            show={showConfirmationModal}
            handleCloseModal={handleCloseConfirmationModal}
            handleConfirmDelete={handleConfirmDelete}
            confirmTitle="custom url"
          />

          {/* Custom url modals */}
          {showModal && (
            <MedicalAddEditModal
              show={showModal}
              data={selectedData}
              setSelectedData={setSelectedData}
              handleSaveShowModal={handleSaveShowModal}
              handleCloseModal={handleCloseModal}
            />
          )}
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default AllMedicalId;
