import React from "react";

const MyMemoryLogo = ({ primaryColor, secondaryColor }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.1"
    width="512"
    height="512"
    x="0"
    y="0"
    viewBox="0 0 496 496"
    class=""
  >
    <g>
      <path
        fill={secondaryColor}
        d="M120 376c-17.68 0-32-14.32-32-32V232H40c-17.68 0-32 14.32-32 32v192c0 17.68 14.32 32 32 32h240c17.68 0 32-14.32 32-32v-80zm0 0"
        opacity="1"
        data-original="#fcd770"
        class=""
      ></path>
      <path
        fill={primaryColor}
        d="M392 264v80c0 17.68-14.32 32-32 32H120c-17.68 0-32-14.32-32-32V152c0-17.68 14.32-32 32-32h240c17.68 0 32 14.32 32 32zm0 0"
        opacity="1"
        data-original="#e6e9ed"
        class=""
      ></path>
      <path
        fill={secondaryColor}
        d="m312 248-128 64V184zm0 0"
        opacity="1"
        data-original="#ff826e"
        class=""
      ></path>
      <path
        fill={secondaryColor}
        d="M184 120V40c0-17.68 14.32-32 32-32h240c17.68 0 32 14.32 32 32v192c0 17.68-14.32 32-32 32h-64V152c0-17.68-14.32-32-32-32zm0 0"
        opacity="1"
        data-original="#48cfad"
        class=""
      ></path>
      <path
        fill={primaryColor}
        d="M112 440v16H80v-32h32zm0 0"
        opacity="1"
        data-original="#e6e9ed"
        class=""
      ></path>
      <g fill={secondaryColor}>
        <path
          d="M112 160v64h16v-64h32v-16h-32c-8.824 0-16 7.176-16 16zM112 240h16v16h-16zM112 272h16v16h-16zM352 256h16v16h-16zM352 288h16v16h-16zM352 320h16v16h-16zM32 304h16v16H32zM32 336h16v16H32zM32 272h16v16H32zm0 0"
          fill="#23303f"
          opacity="1"
          data-original="#1e1713"
          class=""
        ></path>
        <path
          d="M456 0H216c-22.055 0-40 17.945-40 40v72h-56c-22.055 0-40 17.945-40 40v72H40c-22.055 0-40 17.945-40 40v192c0 22.055 17.945 40 40 40h240c22.055 0 40-17.945 40-40v-72h40c22.055 0 40-17.945 40-40v-72h56c22.055 0 40-17.945 40-40V40c0-22.055-17.945-40-40-40zM304 456c0 13.23-10.77 24-24 24H40c-13.23 0-24-10.77-24-24V264c0-13.23 10.77-24 24-24h40v104c0 22.055 17.945 40 40 40h184zm80-112c0 13.23-10.77 24-24 24H120c-13.23 0-24-10.77-24-24V152c0-13.23 10.77-24 24-24h240c13.23 0 24 10.77 24 24zm64-280H336V48h112zm32 168c0 13.23-10.77 24-24 24h-56V152c0-22.055-17.945-40-40-40h-24V80h112v81.473c-2.512-.899-5.184-1.473-8-1.473-13.23 0-24 10.77-24 24s10.77 24 24 24 24-10.77 24-24V32H320v80H192V40c0-13.23 10.77-24 24-24h240c13.23 0 24 10.77 24 24zm-32-48c0 4.414-3.586 8-8 8s-8-3.586-8-8 3.586-8 8-8 8 3.586 8 8zm0 0"
          fill="#23303f"
          opacity="1"
          data-original="#1e1713"
          class=""
        ></path>
        <path
          d="M176 324.945 329.887 248 176 171.055zm16-128L294.113 248 192 299.055zM120 416H72v16H32v16h40v16h48v-16h168v-16H120zm-16 32H88v-16h16zM208 32h96v16h-96zM208 64h96v16h-96zm0 0"
          fill="#23303f"
          opacity="1"
          data-original="#1e1713"
          class=""
        ></path>
      </g>
    </g>
  </svg>
);

export default MyMemoryLogo;
