import React from "react";
import Modal from "react-bootstrap/Modal";
import { MdClose } from "react-icons/md";
import classes from "../../assets/styles/ConfirmationModal.module.css";
import { useNavigate } from "react-router-dom";
import { deleteRecord } from "../../server";
import { FUNCTION_DELETE } from "../../utils/constants/apiUrl";
import showToast from "../../helpers/showToast";
import { useAtom } from "jotai";
import { loggedInUser } from "../../utils/store";
const FunctionsLimitModal = ({ show, handleClose, getData }) => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const navigate = useNavigate();

  const handleConfirmDelete = async () => {
    let { status, message } = await deleteRecord(
      FUNCTION_DELETE,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      showToast(status, message);
      getData();
      handleClose();
    } else {
      showToast(status, message);
    }
  };

  const handlePlanUpgrade = () => {
    let link = "/packages";
    navigate(link);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        className="customModal"
        size="sm"
      >
        <Modal.Header>
          <div className="modalGradientCircle"></div>
          <MdClose onClick={handleClose} className="modalCloseBtn" />
        </Modal.Header>

        <Modal.Body
          style={{ padding: "0" }}
          className={`d-flex justify-content-center flex-column align-items-center`}
        >
          <div
            className="d-flex flex-column align-items-center justify-content-center"
            style={{ width: "fit-content", lineHeight: "18px" }}
          >
            <h5>Feature is unavailable!</h5>
            <p
              style={{
                lineHeight: "18px",
                textAlign: "center",
                padding: "0px 20px",
              }}
            >
              In free mode you can only create one function at a time. In order
              to create more functions you must either delete the existing
              function or upgrade to a higher package.
            </p>
          </div>

          <div
            className={`customModalBtnWrapper limitCustomModalBtnWrapper ${classes.btnWrapper}`}
          >
            <button
              onClick={handleConfirmDelete}
              style={{
                background: "#E35252",
                boxShadow: "0px 6px 20px rgba(227, 82, 82, 0.4)",
              }}
            >
              Delete Function
            </button>
            <button onClick={handlePlanUpgrade}>Upgrade Now</button>
          </div>
        </Modal.Body>
      </Modal>
      <style type="text/css">
        {`
        .limitCustomModalBtnWrapper{
          display:flex;
          justify-content:space-between;
          margin-top:3rem;
        }
        .customModal .limitCustomModalBtnWrapper button{
          width:49%;
        }
      `}
      </style>
    </>
  );
};
export default FunctionsLimitModal;
