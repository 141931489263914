import React, { useState } from "react";
import classes from "../../../assets/styles/PageComponentsStyles/Settings/Password.module.css";
import SettingsInput from "../../Forms/SettingsInput";
import { SETTING } from "../../../utils/constants/apiUrl";
import { post } from "../../../server";
import { loggedInUser } from "../../../utils/store";
import { useAtom } from "jotai";
import Loader from "../../Loader";
import passwordIcon from "../../../assets/images/signup_icons/lock.svg";
import showToast from "../../../helpers/showToast";
import ValidateSchema from "../../../helpers/ValidateSchema";
import PasswordSchema from "../../../utils/SchemasValidation/PasswordSchema";

const Password = () => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const [password, setPassword] = useState({
    password: "",
    confirm_password: "",
    current_password: "",
  });

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setPassword({ ...password, [name]: value });
  };

  const validateForm = () => {
    let newErrors = ValidateSchema(password, PasswordSchema);

    setErrors(newErrors);

    if (Object.keys(newErrors).length) return false;

    return true;
  };

  const handleUpdate = async () => {
    if (!validateForm()) return;

    setLoading(true);

    let { status, message } = await post(
      SETTING + `/changePassword`,
      password,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      setPassword({
        password: "",
        confirm_password: "",
        current_password: "",
      });
    }

    showToast(status, message);

    setLoading(false);
  };

  return (
    <>
      {!loading ? (
        <div className={classes.passwordWrapper}>
          <h6 className={classes.title}>Change Password</h6>
          <div className={classes.inputWrapper}>
            <div className="row gy-1">
              <div className="col-md-6 col-lg-4 col-12">
                <SettingsInput
                  label="Current Password"
                  type="password"
                  placeholder="********"
                  socialProfiles={false}
                  name="current_password"
                  value={password.current_password}
                  handleChange={handleChange}
                  icon={passwordIcon}
                  error={errors.current_password}
                />
              </div>

              <div className="col-lg-4 col-12"></div>
              <div className="col-lg-4 col-12"></div>

              <div className="col-md-6 col-lg-4 col-12">
                <SettingsInput
                  label="New Password"
                  type="password"
                  placeholder="********"
                  socialProfiles={false}
                  name="password"
                  value={password.password}
                  handleChange={handleChange}
                  icon={passwordIcon}
                  error={errors.password}
                />
              </div>

              <div className="col-lg-4 col-12"></div>
              <div className="col-lg-4 col-12"></div>

              <div className="col-md-6 col-lg-4 col-12">
                <SettingsInput
                  label="Confirm Password"
                  type="password"
                  placeholder="********"
                  socialProfiles={false}
                  name="confirm_password"
                  value={password.confirm_password}
                  handleChange={handleChange}
                  icon={passwordIcon}
                  error={errors.confirm_password}
                />
              </div>
            </div>
          </div>
          <div className={classes.submitBtnWrapper}>
            <button type="submit" onClick={handleUpdate}>
              Update Password
            </button>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default Password;
