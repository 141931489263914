import React from "react";

const MedicalIDLogo = ({ primaryColor, secondaryColor, accentColor }) => (
  <svg
    width="70"
    height="72"
    viewBox="0 0 78 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_160_7)">
      <path
        d="M29.8026 31.309C31.3972 36.9259 30.7038 39.4205 28 43.4762M34.7596 29.0558C37.9937 37.365 35.0432 42.4208 32.0558 47.532M39.266 27.2532C42.6373 36.7383 41.2079 41.0885 36.5622 49.7852M43.7724 25C47.8979 36.5191 46.9226 42.1599 40.6179 52.4891"
        stroke="#D84646"
        stroke-width="4"
        stroke-linecap="round"
      />
      <path
        d="M6.67975 24.1117L19.7468 35.8721C20.3113 36.3801 20.1258 37.3072 19.4093 37.5589L3.51224 43.1444C2.9651 43.3366 2.69569 43.9524 2.92587 44.4847L10.0957 61.065C10.3178 61.5786 10.918 61.81 11.4273 61.5785L25.4183 55.219C26.1202 54.8999 26.9038 55.4617 26.8271 56.2288L25.0965 73.5355C25.0427 74.073 25.4255 74.556 25.961 74.6264L42.9514 76.862C43.5208 76.937 44.0361 76.518 44.0791 75.9454L45.3602 58.864C45.4199 58.0674 46.3435 57.659 46.973 58.1508L60.7048 68.8787C61.1428 69.2209 61.7758 69.1403 62.114 68.6991L72.4015 55.2807C72.7336 54.8475 72.657 54.2279 72.2293 53.8887L59.7632 44.0018C59.1818 43.5408 59.2874 42.6294 59.9588 42.3135L74.6351 35.407C75.1189 35.1793 75.338 34.6112 75.1323 34.1176L68.3665 17.8796C68.1613 17.3871 67.6056 17.1424 67.1038 17.3236L51.5059 22.9562C50.8106 23.2073 50.0926 22.6431 50.1721 21.9081L51.8953 5.96807C51.9536 5.42929 51.5721 4.94216 51.0351 4.86959L34.4609 2.62984C33.9255 2.55749 33.4294 2.9235 33.3406 3.45644L30.7774 18.8356C30.6525 19.585 29.7706 19.9269 29.1732 19.4575L17.3042 10.1319C16.863 9.7852 16.223 9.86931 15.8864 10.3182L6.54871 22.7684C6.23808 23.1826 6.29493 23.7654 6.67975 24.1117Z"
        stroke="#D84646"
        stroke-width="5"
      />
    </g>
    <defs>
      <clipPath id="clip0_160_7">
        <rect width="78" height="80" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default MedicalIDLogo;
