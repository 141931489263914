import React, { useState, useEffect, useMemo } from "react";
import classes from "../../assets/styles/Pages/Function/CustomURLSListing.module.css";
import ShowFormBuilderFunctions from "../../components/PageComponents/Function/ShowFormBuilderFunctions";
import Loader from "../../components/Loader";
import { useAtom } from "jotai";
import { get, deleteRecord, post, put } from "../../server";
import {
  FORM_BUILDER,
  SEND_EMAIL_TEMPLATE,
  EMAIL_TEMPLATE,
  FUNCTION_STATUS,
} from "../../utils/constants/apiUrl";
import {
  loggedInUser,
  teamIDCheck,
  subscribedPackage,
} from "../../utils/store";
import { toast } from "react-toastify";
import showToast from "../../helpers/showToast";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import FormInput from "../../components/Forms/FormInput";
import searchIcon from "../../assets/images/global_icons/searchicon.svg";
import _ from "lodash";
import EmptyListingBanner from "../../components/EmptyListingBanner";
import useFunctionsCount from "../../hooks/useFunctionsCount";
import { FaCirclePlus } from "react-icons/fa6";
import FunctionsLimitModal from "../../components/Modals/FunctionsLimitModal";
import functionTypes from "../../utils/constants/functionTypes";

const FormBuilderListing = () => {
  let emptyCustomUrlData = {
    isNew: true,
    id: "",
    title: "",
    url_path: "",
  };
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const team_id = searchParams.get("team_id");
  const user_id = searchParams.get("user_id");
  const dynamicQRCodeId = searchParams.get("dynamic_qr_code");

  const [loading, setLoading] = useState(true);
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [userTeamId, setUserTeamId] = useAtom(teamIDCheck);
  const [formBuilderListings, setFormBuilderListings] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState(emptyCustomUrlData);
  const [filterText, setFilterText] = useState("");
  const {
    compareLimits,
    updateCount,
    limitExceededForStarter,
    compareLimitsForDynamicQR,
  } = useFunctionsCount();
  const [showLimitModal, setShowLimitModal] = useState(false);
  const [purchasedPackage, setPurchasedPackage] = useAtom(subscribedPackage);
  const [emailStatus, setEmailStatus] = useState(false);

  const handleFilter = (e) => {
    setFilterText(e.target.value);
  };

  const handleFormBuilder = async () => {
    if (dynamicQRCodeId) {
      const isLimitExceeded = compareLimitsForDynamicQR(
        functionTypes.FORM_BUILDER,
        dynamicQRCodeId
      );
      if (isLimitExceeded) {
        return showToast(
          false,
          "The requested feature is not compatible with your current plan. Upgrade your plan to continue using these features"
        );
      } else {
        navigate(`/function/formBuilder?dynamic_qr_code=${dynamicQRCodeId}`);
      }
    } else if (purchasedPackage.id === 1) {
      limitExceededForStarter()
        .then((isLimitExceeded) => {
          if (isLimitExceeded) {
            setShowLimitModal(true);
          } else {
            navigate("/function/formBuilder");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      const isLimitExceeded = compareLimits(functionTypes.FORM_BUILDER);
      if (isLimitExceeded) {
        return showToast(
          false,
          "The requested feature is not compatible with your current plan. Upgrade your plan to continue using these features"
        );
      } else {
        navigate("/function/formBuilder");
      }
    }
  };

  // const handleFormBuilder = async () => {
  //   navigate("/function/formBuilder");
  // };

  const handleCloseModal = () => {
    setFormBuilderListings([]);
    setShowModal(false);
    setShowLimitModal(false);
  };

  // Handle confirmation modal
  const handleShowConfirmationModal = () => setShowConfirmationModal(true);
  const handleCloseConfirmationModal = () => setShowConfirmationModal(false);

  // handle delete
  const handleDelete = (formBuilderId) => {
    setDeleteId(formBuilderId);
    handleShowConfirmationModal(true);
  };

  const handleConfirmDelete = async () => {
    handleCloseConfirmationModal(true);
    setLoading(true);
    let queryParams = "";
    if (dynamicQRCodeId) {
      queryParams += `?dynamic_qr_code_id=${dynamicQRCodeId}`;
    }
    let { status, message } = await deleteRecord(
      `${FORM_BUILDER}/${deleteId}${queryParams}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      updateCount(
        functionTypes.FORM_BUILDER,
        functionTypes.DELETE,
        dynamicQRCodeId
      );
      getFormBuilderListing();
      setLoading(false);
    }
    setLoading(false);
  };

  // Get all custom urls
  const getFormBuilderListing = async () => {
    let queryParams = "";
    if (dynamicQRCodeId) {
      queryParams += `?dynamic_qr_code_id=${dynamicQRCodeId}`;
    }

    if (userTeamId) {
      queryParams += `${queryParams ? "&" : "?"}team_id=${userTeamId}`;
    }
    let { status, message, data } = await get(
      // userTeamId ? FORM_BUILDER + queryParams : FORM_BUILDER,
      `${FORM_BUILDER}${queryParams}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      setFormBuilderListings(data);
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  const filteredItems = useMemo(() => {
    const filtered = formBuilderListings.filter((item) =>
      `${item.name || ""}`.toLowerCase().includes(filterText.toLowerCase())
    );

    return filtered;
  }, [filterText, formBuilderListings]);
  const handleGoBack = () => {
    navigate(-1);
  };

  const handleStatus = async (id) => {
    const allFiles = formBuilderListings.find((file) => file.id === id);
    let newStatus = allFiles.status == true ? 0 : 1;
    let queryParams = "";

    if (dynamicQRCodeId) {
      queryParams += `?dynamic_qr_code_id=${dynamicQRCodeId}`;
    }
    const payload = {
      id: allFiles.id,
      status: newStatus,
      type: allFiles.function_type,
      team_id: allFiles.team_id,
    };
    let { status, message } = await post(
      `${FUNCTION_STATUS}${queryParams}`,
      payload,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      await getFormBuilderListing();
    } else {
      toast.error(message);
    }
    setLoading(false);
  };

  const getHandleStatus = async () => {
    const queryString = `?function_type=form_builder`;
    let { status, message, data } = await get(
      EMAIL_TEMPLATE + queryString,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      if (data !== null) {
        setEmailStatus(data.email_to_lead_user);
      } else {
        setEmailStatus(0);
      }
    } else {
      toast.error(message);
    }
    setLoading(false);
  };

  const handleStatusChange = async () => {
    let newEmailStatus = emailStatus == true ? 0 : 1;
    const payload = {
      email_to_lead_user: newEmailStatus,
    };
    let { status, message } = await put(
      SEND_EMAIL_TEMPLATE,
      payload,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      await getHandleStatus();
    } else {
      toast.error(message);
    }
    setLoading(false);
  };

  useEffect(() => {
    getFormBuilderListing();
    getHandleStatus();
  }, [userTeamId]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="">
          <div className={classes.mainWrapper}>
            <h6 className={classes.title}>
              {/* <Link
                onClick={handleGoBack}
                style={{ color: "var(--heading_text_color)" }}
              >
                Functions
              </Link> */}
              <Link to="/" style={{ color: "var(--heading_text_color)" }}>
                Functions
              </Link>
              {` > Lead Builder`}
            </h6>
            <div>
              <div className="d-flex align-items-center justify-content-end mb-2">
                {/* <Form.Check
                  type="switch"
                  checked={emailStatus}
                  onChange={handleStatusChange}
                  label="Send Email Template"
                /> */}
                {purchasedPackage.id !== 1 ? (
                  <>
                    <div className="form-check form-switch">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        checked={emailStatus}
                        onChange={handleStatusChange}
                      />
                      <label class="form-check-label">
                        {emailStatus == true
                          ? "Trun Off Send Email"
                          : "Want To Send Email"}
                      </label>
                    </div>
                    <Link to="/function/emailTemplate">
                      <button
                        className="primary_button ms-3"
                        style={{ padding: "10px 15px" }}
                      >
                        Email Template
                      </button>
                    </Link>
                    {dynamicQRCodeId !== null ? (
                      <Link
                        className="primary_button ms-3"
                        to={`/qrcode`}
                        style={{ padding: "10px 15px" }}
                      >
                        {`< Back`}
                      </Link>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
              </div>
              {formBuilderListings.length !== 0 ? (
                <FormInput
                  icon={searchIcon}
                  placeholder="Search..."
                  value={filterText}
                  handleChange={handleFilter}
                />
              ) : null}
            </div>
          </div>

          {formBuilderListings.length === 0 ? (
            <EmptyListingBanner
              headLineText="lead builder"
              title="Create Lead Builder"
              onClick={handleFormBuilder}
              showAdd={
                loggedUser.role.includes("customer") ||
                loggedUser.role.includes("team_owner")
              }
            />
          ) : (
            <div className={classes.customURLSWrapper}>
              <div>
                <div className={classes.addWrapper}>
                  {(Object.keys(loggedUser).length !== 0 &&
                    loggedUser.role.includes("customer")) ||
                  loggedUser.role.includes("team_owner") ? (
                    <span onClick={handleFormBuilder}>
                      <div className={classes.addContentWrapper}>
                        {/* <img src={addcontact} alt="" /> */}
                        <FaCirclePlus />
                        <p>Create Lead Builder</p>
                      </div>
                    </span>
                  ) : null}
                </div>

                <ShowFormBuilderFunctions
                  loading={loading}
                  show={showModal}
                  FormBuilders={filteredItems}
                  handleDelete={handleDelete}
                  handleStatus={handleStatus}
                  getFormBuilderListing={getFormBuilderListing}
                  dynamicQRCodeId={dynamicQRCodeId}
                />
              </div>
            </div>
          )}
        </div>
      )}
      <ConfirmationModal
        show={showConfirmationModal}
        handleCloseModal={handleCloseConfirmationModal}
        handleConfirmDelete={handleConfirmDelete}
        confirmTitle="Lead Builder"
      />
      <FunctionsLimitModal
        show={showLimitModal}
        handleClose={handleCloseModal}
        getData={getFormBuilderListing}
      />
    </>
  );
};

export default FormBuilderListing;
