import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import SecondLoader from "../SecondLoader";
import ValidateSchema from "../../helpers/ValidateSchema";
import classes from "../../assets/styles/CustomURLModal.module.css";
import { MdClose } from "react-icons/md";
import FormInput from "../../components/Forms/FormInput";
import { SocialTeamOwnerSignupSchema } from "../../utils/SchemasValidation/SignupSchema";

const AuthModal = ({
  loading,
  data,
  setSelectedData,
  handleSaveShowModal,
  handleCloseModal,
  show,
  setSocialTeamOwner,
  socialTeamOwner,
  isTeamOwner,
}) => {
  const [errors, setErrors] = useState({});
  const [teamName, setTeamName] = useState("");

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setTeamName(value);
    setSocialTeamOwner({ ...socialTeamOwner, [name]: value });
  };

  // handle Validation
  const validateForm = () => {
    let newErrors = ValidateSchema(
      socialTeamOwner,
      SocialTeamOwnerSignupSchema
    );
    setErrors(newErrors);
    if (Object.keys(newErrors).length) return false;
    return true;
  };

  const handleAddUrl = (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    setSocialTeamOwner({ ...socialTeamOwner, team_name: teamName });
    handleCloseModal();
  };

  // Handle Update
  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          handleCloseModal();
          setErrors({});
          setTeamName("");
        }}
        className={`customModal`}
        size="sm"
      >
        <Modal.Header className={classes.modalHeader}>
          <span className={classes.modalHeading}>Auth</span>
          <div
            className="modalGradientCircle"
            style={{ position: "absolute", top: "25px", zIndex: 0 }}
          ></div>
          <MdClose
            onClick={() => {
              handleCloseModal();
              setErrors({});
            }}
            className="modalCloseBtn"
          />
        </Modal.Header>
        {loading ? (
          <SecondLoader />
        ) : (
          <>
            <Modal.Body>
              <div>
                {isTeamOwner ? (
                  <FormInput
                    type="text"
                    label="Team Name"
                    placeholder="Team Name"
                    name="team_name"
                    value={socialTeamOwner.team_name}
                    onChange={handleChange}
                    error={errors?.team_name}
                  />
                ) : null}
              </div>
            </Modal.Body>

            <Modal.Footer>
              <div
                className={classes.btnWrapper}
                style={{ position: "relative", zIndex: 1 }}
              >
                <Button
                  type="button"
                  onClick={() => {
                    handleCloseModal();
                    setErrors({});
                    setTeamName("");
                  }}
                  className={classes.cancelBtn}
                >
                  Cancel
                </Button>

                <Button
                  type="button"
                  onClick={handleAddUrl}
                  className={classes.saveBtn}
                >
                  Save
                </Button>
              </div>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </>
  );
};

export default AuthModal;
