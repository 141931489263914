import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import SecondLoader from "../SecondLoader";
import AddEmergencyContactSchema from "../../utils/SchemasValidation/AddEmergencyContactSchema";
import ValidateSchema from "../../helpers/ValidateSchema";
import classes from "../../assets/styles/CustomURLModal.module.css";
import { MdClose } from "react-icons/md";
import FormInput from "../../components/Forms/FormInput";
import PhoneInput from "react-phone-input-2";
import ImageUpload from "../../components/ImageUpload";
import pictureicon from "../../assets/images/function_page_icons/picIcon.svg";
import contacticon from "../../assets/images/function_page_icons/contacticon.svg";
import deleteicon from "../../assets/images/function_page_icons/delete.svg";
import _ from "lodash";
import { validatePhoneWithCountryCode } from "../../utils/functions";
import showToast from "../../helpers/showToast";
import { EMERGENCY_CONTACT_VCF_FILE_URL } from "../../utils/constants/apiUrl";
import { post } from "../../server";
import { loggedInUser } from "../../utils/store";
import { useAtom } from "jotai";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const MedicalAddEditModal = ({
  loading,
  data,
  setSelectedData,
  handleSaveShowModal,
  handleCloseModal,
  show,
  publicMode,
}) => {
  const [errors, setErrors] = useState({});
  const [imagePath, setImagePath] = useState("");
  const [medicalID, setMedicalID] = useState({});
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [MedicalEmergencyContacts, setMedicalEmergencyContacts] = useState([]);
  const [showEmergencyContacts, setShowEmergencyContacts] = useState(false);

  useEffect(() => {
    if (publicMode && !data.isNew) {
      setMedicalID(data);

      // setMedicalEmergencyContacts(data.medicalIdEmergencyContacts || []);
    }
  }, [publicMode, data]);
  useEffect(() => {
    if (data?.medicalIdEmergencyContact?.length > 0) {
      setMedicalEmergencyContacts(data.medicalIdEmergencyContact);
      setShowEmergencyContacts(true); // Ensure the emergency contacts section is visible
    }
  }, [data]);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setSelectedData({ ...data, [name]: value });
  };

  const handleDateChange = (date) => {
    if (date instanceof Date && !isNaN(date)) {
      // Format the date to YYYY-mm-dd
      const formattedDate = date.toISOString().split("T")[0];
      setSelectedData({ ...data, dob: formattedDate });
    } else {
      // Handle invalid date
      setSelectedData({ ...data, dob: null });
    }
  };

  // handle Validation
  const validateForm = () => {
    let newErrors = ValidateSchema(data, AddEmergencyContactSchema);
    setErrors(newErrors);
    if (Object.keys(newErrors).length) return false;

    // Check if phone number is empty
    if (!data.phone_number) {
      setErrors({ ...errors, phone_number: "Phone number is required" });
      return false;
    }
    if (
      data.phone_number.length > data.country_phone.length ||
      data.phone_number.length === data.country_phone.length
    ) {
      let resultPhone = validatePhoneWithCountryCode(
        data.country_phone,
        data.phone_number
      );
      if (resultPhone) {
        setErrors({
          phone_number: resultPhone,
        });
        return false;
      }
    }
    return true;
  };

  const handleAddUrl = async (e) => {
    //if (!validateForm()) return;

    let newData = _.cloneDeep(data);
    newData.photo = imagePath;
    newData.medicalIdEmergencyContacts = MedicalEmergencyContacts;
    setSelectedData(newData);
    handleSaveShowModal(newData);
  };

  useEffect(() => {
    setImagePath(data?.photo);
  }, [data.photo]);

  const handleVcfFile = async () => {
    // setLoading(true);
    let { status, message, data } = await post(
      EMERGENCY_CONTACT_VCF_FILE_URL + `/${medicalID.uuid}`,
      medicalID,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      const link = document.createElement("a");
      link.download = "temp.vcf";
      link.href = data.url;
      link.click();
    } else {
      showToast(status, message);
    }
    // setLoading(false);
  };

  const handleAddEmergencyContact = () => {
    setMedicalEmergencyContacts([
      ...MedicalEmergencyContacts,
      { person_name: "", relation: "", country_phone: "", phone_number: "" },
    ]);
    setShowEmergencyContacts(true);
  };

  const handleEmergencyContactChange = (index, e) => {
    const { name, value } = e.target;
    const updatedContacts = MedicalEmergencyContacts.map((contact, i) =>
      i === index ? { ...contact, [name]: value } : contact
    );
    setMedicalEmergencyContacts(updatedContacts);
  };

  const handleRemoveEmergencyContact = (index) => {
    const updatedContacts = MedicalEmergencyContacts.filter(
      (_, i) => i !== index
    );
    setMedicalEmergencyContacts(updatedContacts);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          setImagePath("");
          handleCloseModal();
          setErrors({});
          setMedicalID({});
        }}
        className={`customModal`}
        size="xl"
      >
        <Modal.Header className={classes.modalHeader}>
          <span className={classes.modalHeading}>
            {publicMode ? "View" : data?.id ? "Edit" : "Add "} Medical ID
          </span>
          <div className="modalGradientCircle"></div>
          <MdClose
            onClick={() => {
              setImagePath("");
              handleCloseModal();
              setErrors({});
              setMedicalID({});
            }}
            className="modalCloseBtn"
          />
        </Modal.Header>
        {loading ? (
          <SecondLoader />
        ) : (
          <>
            <Modal.Body>
              <div style={{ marginTop: "-140px" }}>
                <div className="d-flex align-items-center justify-content-center w-100 mb-3">
                  <ImageUpload
                    imageLink={true}
                    imageUrl={data.photo}
                    imageType="medical_id"
                    setPath={setImagePath}
                    width="120px"
                    height="120px"
                    dummyImage={pictureicon}
                  />
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <FormInput
                      type="text"
                      label="First Name"
                      placeholder="Enter first name"
                      name="first_name"
                      value={data.first_name}
                      onChange={handleChange}
                      error={errors?.first_name}
                      readOnly={publicMode ? true : false}
                    />
                  </div>
                  <div className="col-md-4">
                    <FormInput
                      type="text"
                      label="Last Name"
                      placeholder="Enter last name"
                      name="last_name"
                      value={data.last_name}
                      onChange={handleChange}
                      error={errors?.last_name}
                      readOnly={publicMode ? true : false}
                    />
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="" className={classes.label}>
                      Date of Birth
                    </label>
                    <DatePicker
                      selected={data.dob ? new Date(data.dob) : null}
                      onChange={handleDateChange}
                      dateFormat="yyyy/MM/dd"
                      className="dobInput profile"
                      placeholderText="yyyy/MM/dd"
                      showYearDropdown
                      isClearable
                      maxDate={new Date()}
                      yearDropdownItemNumber={110}
                      scrollableYearDropdown
                    />
                  </div>
                  <div className="col-md-3">
                    <FormInput
                      type="text"
                      label="Language"
                      placeholder="Enter Language"
                      name="primary_language"
                      value={data.primary_language}
                      onChange={handleChange}
                      error={errors?.primary_language}
                      readOnly={publicMode ? true : false}
                    />
                  </div>
                  <div className="col-md-3">
                    <FormInput
                      type="text"
                      label="Blood Type"
                      placeholder="Enter Blood Type"
                      name="blood_type"
                      value={data.blood_type}
                      onChange={handleChange}
                      error={errors?.blood_type}
                      readOnly={publicMode ? true : false}
                    />
                  </div>
                  <div className="col-md-3">
                    <FormInput
                      type="text"
                      label="Weight"
                      placeholder="Enter Weight"
                      name="weight"
                      value={data.weight}
                      onChange={handleChange}
                      error={errors?.weight}
                      readOnly={publicMode ? true : false}
                    />
                  </div>
                  <div className="col-md-3">
                    <FormInput
                      type="text"
                      label="Height"
                      placeholder="Enter Height"
                      name="height"
                      value={data.height}
                      onChange={handleChange}
                      error={errors?.height}
                      readOnly={publicMode ? true : false}
                    />
                  </div>
                  <div className="col-md-6">
                    <FormInput
                      type="text"
                      label="Medical Condition"
                      placeholder="Enter Medical Condition"
                      name="medical_condition"
                      value={data.medical_condition}
                      onChange={handleChange}
                      error={errors?.medical_condition}
                      readOnly={publicMode ? true : false}
                    />
                  </div>
                  <div className="col-md-6">
                    <FormInput
                      type="text"
                      label="Allergies Reactions"
                      placeholder="Enter Allergies Reactions"
                      name="allergies_reactions"
                      value={data.allergies_reactions}
                      onChange={handleChange}
                      error={errors?.allergies_reactions}
                      readOnly={publicMode ? true : false}
                    />
                  </div>
                  <div className="col-md-6">
                    <label className={classes.label}>Medical Notes</label>
                    <textarea
                      name="medical_notes"
                      id=""
                      readOnly={publicMode ? true : false}
                      cols="100"
                      rows="5"
                      value={data.medical_notes}
                      placeholder="Enter Medical Notes"
                      onChange={handleChange}
                      className={
                        errors?.medical_notes
                          ? "globalTextArea errorInput"
                          : "globalTextArea"
                      }
                    />
                    <p className="errorText">{errors?.medical_notes || ""}</p>
                  </div>
                  <div className="col-md-6">
                    <label className={classes.label}>Medications</label>
                    <textarea
                      name="medications"
                      id=""
                      readOnly={publicMode ? true : false}
                      cols="100"
                      rows="5"
                      value={data.medications}
                      placeholder="Enter Medications"
                      onChange={handleChange}
                      className={
                        errors?.medications
                          ? "globalTextArea errorInput"
                          : "globalTextArea"
                      }
                    />
                    <p className="errorText">{errors?.medications || ""}</p>
                  </div>
                  <div className="col-12">
                    {showEmergencyContacts && (
                      <div className="col-12">
                        {MedicalEmergencyContacts.map((contact, index) => (
                          <div className="row align-items-end" key={index}>
                            <div className="col-md-4">
                              <FormInput
                                type="text"
                                label="Person Name"
                                placeholder="Enter person name"
                                name="person_name"
                                value={contact.person_name}
                                onChange={(e) =>
                                  handleEmergencyContactChange(index, e)
                                }
                              />
                            </div>
                            <div className="col-md-3">
                              <FormInput
                                type="text"
                                label="Relation"
                                placeholder="Enter relation"
                                name="relation"
                                value={contact.relation}
                                onChange={(e) =>
                                  handleEmergencyContactChange(index, e)
                                }
                              />
                            </div>
                            <div className="col-md-4">
                              <label className={classes.label}>
                                Phone Number
                              </label>
                              <PhoneInput
                                country={"us"}
                                enableSearch={true}
                                countryCodeEditable={false}
                                inputClass={
                                  errors.phone_number
                                    ? "phoneNumberInput errorInput"
                                    : "phoneNumberInput"
                                }
                                value={contact.phone_number}
                                onChange={(value, country) => {
                                  setMedicalEmergencyContacts((prevContacts) =>
                                    prevContacts.map((c, i) =>
                                      i === index
                                        ? {
                                            ...c,
                                            phone_number: value,
                                            country_phone: country.dialCode,
                                          }
                                        : c
                                    )
                                  );
                                }}
                              />
                              <p className="errorText">
                                {errors?.phone_number}
                              </p>
                            </div>
                            <div className="col-md-1 text-end">
                              <Button
                                className="mb-2"
                                variant="outline-danger"
                                onClick={() =>
                                  handleRemoveEmergencyContact(index)
                                }
                              >
                                <img src={deleteicon} alt="" />
                              </Button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}

                    <Button
                      type="button"
                      onClick={handleAddEmergencyContact}
                      className={`${classes.saveBtn} mt-3 w-25`}
                    >
                      Add Emergency Contact
                    </Button>
                  </div>
                </div>
              </div>
            </Modal.Body>

            <Modal.Footer
              style={{ justifyContent: publicMode ? "center" : "flex-end" }}
            >
              {publicMode ? (
                <div
                  className={`text-center ${classes.savePhonebookBtnWrapper}`}
                >
                  <button onClick={handleVcfFile}>
                    <span>
                      <img src={contacticon} alt="" />
                    </span>
                    Save Contact
                  </button>
                </div>
              ) : (
                <div className={classes.btnWrapper}>
                  <Button
                    type="button"
                    onClick={() => {
                      setImagePath("");
                      handleCloseModal();
                      setErrors({});
                    }}
                    className={classes.cancelBtn}
                  >
                    Cancel
                  </Button>

                  <Button
                    type="button"
                    onClick={handleAddUrl}
                    className={classes.saveBtn}
                  >
                    Save
                  </Button>
                </div>
              )}
            </Modal.Footer>
          </>
        )}
      </Modal>
      <style type="text/css">
        {`
          .dobInput{
            color: var(--heading_text_color)
          }
        `}
      </style>
    </>
  );
};

export default MedicalAddEditModal;
