import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import classes from "../assets/styles/Home.module.css";
import HeroSection from "../components/HeroSection";
import FunctionCard from "../components/PageComponents/Function/FunctionCard";
import FunctionCreateCardsData from "../utils/data/FunctionCreateCardsData";
import {
  Card_URL,
  EMERGENCY_CONTACT,
  PAYMENT,
  CUSTOME_URL,
  FILE_UPLOAD,
  HOMEDASHBOARD,
  APP_SETTING,
  THEME_GET,
  PRIORITY,
  MEMORY,
  FORM_BUILDER,
  CHECKOUT_USER_STATUS_UPDATE,
} from "../utils/constants/apiUrl";
import { get, deleteRecord, post, put } from "../server";
import {
  loggedInUser,
  globalAppSetting,
  teamIDCheck,
  themeColorAtom,
  buttonColorAtom,
  subscribedPackage,
} from "../utils/store";
import { useAtom } from "jotai";
import Loader from "../components/Loader";
import showToast from "../helpers/showToast";
import ConfirmationModal from "../components/Modals/ConfirmationModal";
import _ from "lodash";
import CustomURLSAddEditModal from "../components/Modals/CustomURLSAddEditModal";
import FileUploadAddEditModal from "../components/Modals/FileUploadAddEditModal";
import { formatUrl } from "../utils/functions";
import uploadImage from "../helpers/uploadImage";
import handlePhoneNumber from "../helpers/handlePhoneNumber";
import PaymentAddEditModal from "../components/Modals/PaymentAddEditModal";
import useFunctionsCount from "../hooks/useFunctionsCount";
import functionTypes from "../utils/constants/functionTypes";
import chroma from "chroma-js";
import { useLocation } from "react-router-dom";
import { RiListUnordered } from "react-icons/ri";
import PriorityListingModal from "../components/Modals/PriorityListingModal";
import { Modal } from "react-bootstrap";
import { LuAlertTriangle } from "react-icons/lu";
import { MdClose } from "react-icons/md";

const emptyEmergencyContactData = {
  isNew: true,
  id: "",
  first_name: "",
  last_name: "",
  country_phone: "",
  phone_number: "",
  image: "",
  description: "",
};

const emptyCustomUrlData = {
  isNew: true,
  id: "",
  title: "",
  url_path: "",
};

const emptyPaymentData = {
  isNew: true,
  id: "",
  title: "",
  url_path: "",
  payment_type: "",
};

const emptyFilesData = {
  isNew: true,
  id: "",
  title: "",
  url_path: "",
};

const Home = (props) => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const purchasedPackageEndDate = new Date(
    loggedUser?.package_details[0]?.end_date
  );
  const currentDate = new Date();
  const isExpired = purchasedPackageEndDate < currentDate;
  const [showPackageExpiredModal, setShowPackageExpiredModal] =
    useState(isExpired);
  const [newLoggedUser, setNewLoggedUser] = useState(loggedUser?.is_new_user);
  const [newUserModal, setNewUserModal] = useState(
    newLoggedUser === 1 ? true : false
  );
  const [userTeamId, setUserTeamId] = useAtom(teamIDCheck);
  const [appSetting, setAppSetting] = useAtom(globalAppSetting);
  const [themeColor, setThemeColor] = useAtom(themeColorAtom);
  const [buttonColor, setButtonColor] = useAtom(buttonColorAtom);
  const [loading, setLoading] = useState(true);
  const [modalLoading, setModalLoading] = useState(false);
  const [personalCards, setPersonalCards] = useState([]);
  const [businessCards, setBusinessCards] = useState([]);
  const [personalCardsCount, setPersonalCardsCount] = useState(0);
  const [businessCardsCount, setBusinessCardsCount] = useState(0);
  const [formBuilderCount, setFormBuilderCount] = useState(0);
  const [memoryCount, setMemoryCount] = useState(0);
  const [emergencyContacts, setEmergencyContacts] = useState([]);
  const [customUrls, setCustomUrls] = useState([]);
  const [medicalCount, setMedicalCount] = useState(0);
  const [payment, setPayment] = useState([]);
  const [cashApps, setCashApps] = useState([]);
  const [venmos, setVenmos] = useState([]);
  const [files, setFiles] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [cards, setCards] = useState([]);
  const navigate = useNavigate();

  const [deleteId, setDeleteId] = useState(null);
  const [deleteType, setDeleteType] = useState(null);

  const [emergencyContactData, setEmergencyContactData] = useState(
    emptyEmergencyContactData
  );
  const [customUrlData, setCustomUrlData] = useState(emptyCustomUrlData);
  const [paymentData, setPaymentData] = useState(emptyPaymentData);
  const [paymentCount, setPaymentCount] = useState("");
  const [fileUploadData, setFileUploadData] = useState(emptyFilesData);

  const [showCustomUrlModal, setShowCustomUrlModal] = useState(false);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [showFileUploadModal, setShowFileUploadModal] = useState(false);
  const [showEmergencyModal, setShowEmergencyModal] = useState(false);

  const [showPriorityModal, setShowPriorityModal] = useState(false);
  const [sortedFunctionData, setSortedFunctionData] = useState([]);

  const { compareLimits, updateCount } = useFunctionsCount();
  const { teamId, userId } = useParams();

  const handleClosePackageExpiredModal = () => {
    setShowPackageExpiredModal(false);
  };

  const handleUpgrade = () => {
    navigate("/packages");
  };

  const handleEmergencyOpenModal = () => {
    setShowEmergencyModal(true);
  };

  const handleCloseEmergencyModal = () => {
    setEmergencyContactData(emptyEmergencyContactData);
    setShowEmergencyModal(false);
  };

  const handlePaymentOpenModal = () => {
    const isLimitExceeded = compareLimits(functionTypes.PAYMENT);
    if (isLimitExceeded)
      return showToast(
        false,
        "The requested feature is not compatible with your current plan. Upgrade your plan to continue using these features"
      );
    setShowPaymentModal(true);
  };

  const handlePaymentCloseModal = () => {
    setPaymentData(emptyPaymentData);
    setShowPaymentModal(false);
  };
  const handleCustomUrlOpenModal = () => {
    const isLimitExceeded = compareLimits(functionTypes.CUSTOM_URL);
    if (isLimitExceeded)
      return showToast(
        false,
        "The requested feature is not compatible with your current plan. Upgrade your plan to continue using these features"
      );
    setShowCustomUrlModal(true);
  };

  const handleCustomUrlCloseModal = () => {
    setCustomUrlData(emptyCustomUrlData);
    setShowCustomUrlModal(false);
  };
  const handleFileUploadOpenModal = () => {
    const isLimitExceeded = compareLimits(functionTypes.FILE_UPLOAD);
    if (isLimitExceeded)
      return showToast(
        false,
        "The requested feature is not compatible with your current plan. Upgrade your plan to continue using these features"
      );
    setShowFileUploadModal(true);
  };

  const handleFileUploadCloseModal = () => {
    setFileUploadData(emptyFilesData);
    setShowFileUploadModal(false);
  };

  const handleDelete = (id, type) => {
    setDeleteId(id);

    setDeleteType(Card_URL);
    handleShowModal(true);
  };

  const handleEmergencyDelete = (id) => {
    setDeleteId(id);
    setDeleteType(EMERGENCY_CONTACT);
    handleShowModal(true);
  };

  const handleCustomDelete = (id) => {
    setDeleteId(id);
    setDeleteType(CUSTOME_URL);
    handleShowModal(true);
  };
  const handleFileUploadDelete = (id) => {
    setDeleteId(id);
    setDeleteType(FILE_UPLOAD);
    handleShowModal(true);
  };

  const handlePaymentDelete = (id) => {
    setDeleteId(id);
    setDeleteType(PAYMENT);
    handleShowModal(true);
  };

  const handleConfirmDelete = async () => {
    handleCloseModal(true);
    setLoading(true);

    let { status, message } = await deleteRecord(
      `${deleteType}/${deleteId}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      if (deleteType == Card_URL) {
        updateCount(functionTypes.CARD, functionTypes.DELETE);
      }
      if (deleteType == PAYMENT) {
        updateCount(functionTypes.PAYMENT, functionTypes.DELETE);
      }
      if (deleteType == CUSTOME_URL) {
        updateCount(functionTypes.CUSTOM_URL, functionTypes.DELETE);
      }
      if (deleteType == FILE_UPLOAD) {
        updateCount(functionTypes.FILE_UPLOAD, functionTypes.DELETE);
      }
      if (deleteType == EMERGENCY_CONTACT) {
        updateCount(functionTypes.EMERGENCY_CONTACT, functionTypes.DELETE);
      }
      if (deleteType == MEMORY) {
        updateCount(functionTypes.MEMORY, functionTypes.DELETE);
      }
      if (deleteType == FORM_BUILDER) {
        updateCount(functionTypes.FORM_BUILDER, functionTypes.DELETE);
      }

      await getHomeDashboardData();
      showToast(status, message);
    } else {
      showToast(status, message);
    }

    setLoading(false);
  };

  const handlePersonalBusinessCard = (data) => {
    let personalCardsData = [];
    let businessCardsData = [];
    data.forEach((card) => {
      if (card.card_type === "personal") {
        personalCardsData.push(card);
      } else {
        businessCardsData.push(card);
      }

      setPersonalCards(personalCardsData);
      setBusinessCards(businessCardsData);
    });
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const getHomeDashboardData = async () => {
    setLoading(true);
    let queryParams = "";

    if (userTeamId) {
      queryParams = `?team_id=${userTeamId}`;
    }
    let { status, data, message } = await get(
      `${HOMEDASHBOARD}${queryParams}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      if (Object.keys(data).length > 0) {
        setPersonalCardsCount(data.personalCardCount);
        setBusinessCardsCount(data.businessCardCount);

        if (data.cards.length > 0) {
          setCards(data.cards);

          let personalCardsData = [];
          let businessCardsData = [];

          data.cards.forEach((card) => {
            if (card.card_type === "personal") {
              personalCardsData.push(card);
            } else {
              businessCardsData.push(card);
            }

            setPersonalCards(personalCardsData);
            setBusinessCards(businessCardsData);
          });
        } else {
          setCards([]);
          setPersonalCards([]);
          setBusinessCards([]);
        }

        setCustomUrls(data.customUrls);
        setEmergencyContacts(data.emergencyContacts);
        setPayment(data.onlyPayments);
        setPaymentCount(
          data.cashAppCount + data.customPaymentCount + data.venmoCount
        );
        setFiles(data.fileUploads);
        setFormBuilderCount(data.formBuilderCount);
        setMemoryCount(data.memoryCount);
        setMedicalCount(data.medicalIdCount);
      }
    } else {
      // error message
      showToast(status, message);
    }
    setLoading(false);
  };

  const handleSavePayment = async () => {
    setModalLoading(true);

    let payload = _.cloneDeep(paymentData);
    payload.url_path = formatUrl(payload.url_path);

    let result = {};

    if (payload.id) {
      result = await put(
        PAYMENT + `/${payload.id}`,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
    } else {
      if (userTeamId) {
        payload.team_id = userTeamId;
      }
      result = await post(
        PAYMENT,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
    }

    let { status, message, data } = result;

    if (status) {
      if (!payload.id) {
        updateCount(functionTypes.PAYMENT, functionTypes.ADD);
      }
      await getHomeDashboardData();
      handleCloseModal();
    }

    showToast(status, message);
    setModalLoading(false);
  };

  const handleSaveCustomUrl = async () => {
    setModalLoading(true);
    let payload = _.cloneDeep(customUrlData);
    payload.url_path = formatUrl(payload.url_path);

    let result = {};

    if (payload.id) {
      result = await put(
        CUSTOME_URL + `/${payload.id}`,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
    } else {
      if (userTeamId) {
        payload.team_id = userTeamId;
      }
      result = await post(
        CUSTOME_URL,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
    }

    let { status, message, data } = result;

    if (status) {
      if (!payload.id) {
        updateCount(functionTypes.CUSTOM_URL, functionTypes.ADD);
      }
      await getHomeDashboardData();
      handleCloseModal();
    }

    showToast(status, message);
    setModalLoading(false);
  };
  const handleSaveFileUpload = async () => {
    setModalLoading(true);

    let payload = _.cloneDeep(fileUploadData);
    payload.url_path = formatUrl(payload.url_path);

    let result = {};

    if (payload.id) {
      result = await put(
        FILE_UPLOAD + `/${payload.id}`,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
    } else {
      if (userTeamId) {
        payload.team_id = userTeamId;
      }
      result = await post(
        FILE_UPLOAD,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
    }

    let { status, message, data } = result;

    if (status) {
      if (!payload.id) {
        updateCount(functionTypes.FILE_UPLOAD, functionTypes.ADD);
      }
      await getHomeDashboardData();
      handleCloseModal();
    }

    showToast(status, message);
    setModalLoading(false);
  };

  const handleSaveEmergencyContact = async (newData) => {
    setModalLoading(true);
    let payload = _.cloneDeep(newData);
    handlePhoneNumber(payload);

    if (typeof payload.image == "object" && payload.image) {
      const { status, message, data } = await uploadImage(
        "emergency_contact",
        payload.image,
        loggedUser?.token
      );

      if (status) {
        payload.image = data;
      } else {
        setModalLoading(false);
        showToast(status, message);
        return;
      }
    }

    let result = {};

    if (payload.id) {
      result = await put(
        EMERGENCY_CONTACT + `/${payload.id}`,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
    } else {
      if (userTeamId) {
        payload.team_id = userTeamId;
      }
      result = await post(
        EMERGENCY_CONTACT,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
    }

    let { status, message, data } = result;

    if (status) {
      if (!payload.id) {
        updateCount(functionTypes.EMERGENCY_CONTACT, functionTypes.ADD);
      }
      handleCloseEmergencyModal();
      await getHomeDashboardData();
      setEmergencyContactData(emptyEmergencyContactData);
    } else {
      setModalLoading(false);
    }
    showToast(status, message);
    setModalLoading(false);
    setLoading(false);
  };

  const handlePersonalScrollLeft = () => {
    document.getElementById("personalCardWrapper").scrollLeft -= 220;
  };

  const handlePersonalScrollRight = () => {
    document.getElementById("personalCardWrapper").scrollLeft += 220;
  };

  const handleBusinessScrollLeft = () => {
    document.getElementById("businessCardWrapper").scrollLeft -= 220;
  };

  const handleBusinessScrollRight = () => {
    document.getElementById("businessCardWrapper").scrollLeft += 220;
  };

  const getAppSetting = async () => {
    let { status, data, message } = await get(
      APP_SETTING,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      if (Object.keys(data).length > 0) setAppSetting(data[0]);
    } else {
      showToast(status, message);
    }
  };

  useEffect(() => {
    getHomeDashboardData();
    getAppSetting();
  }, [userTeamId]);

  // const [themeColor, setThemeColor] = useState(null);
  // const [buttonColor, setButtonColor] = useState(null);
  const location = useLocation();
  const role = location.state?.userRole;
  // Function to check if a color is red or similar to red
  const isSimilarToRed = (color) => {
    const [hue, saturation, lightness] = chroma(color).hsl();

    // Check if the hue is within the red range (0° ± 30°)
    const isRedHue = (hue >= 0 && hue <= 30) || (hue >= 330 && hue <= 360);

    // Ensure saturation and lightness are within reasonable ranges
    const isSaturated = saturation > 0.5; // Avoid dull colors
    const isBrightEnough = lightness > 0.2; // Avoid very dark shades
    const isNotTooBright = lightness < 0.8; // Avoid very light pinks

    return isRedHue && isSaturated && isBrightEnough && isNotTooBright;
  };
  const fetchColors = async (role) => {
    setLoading(true);
    let { status, message, data } = await get(
      THEME_GET,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if ((role !== "super_admin" || role !== "sub_admin") && status) {
      // const themeColor = data?.theme_color;
      // const buttonColor = data?.button_color;
      const newThemeColor = data?.theme_color;
      const newButtonColor = data?.button_color;
      // Update atoms with new colors
      setThemeColor(newThemeColor);
      setButtonColor(newButtonColor);
      if (data?.theme_color !== null || data?.button_color !== null) {
        const isLight = chroma(newThemeColor).luminance() > 0.4;
        const isBtnLight = chroma(newButtonColor).luminance() > 0.4;
        // Check if theme color is similar to red
        const errorColor = isSimilarToRed(newThemeColor)
          ? "#000000"
          : "#e35252";
        const primaryTextColor = isLight
          ? chroma(newThemeColor).darken(4.5).hex()
          : chroma(newThemeColor).brighten(3.5).hex();
        const headingTextColor = isLight ? "#000000" : "#ffffff";
        const secondaryColor = isLight
          ? chroma(newThemeColor).darken(0.1).hex()
          : chroma(newThemeColor).brighten(0.2).hex();
        const accentColor = isLight
          ? chroma(newThemeColor).darken(0.4).hex()
          : chroma(newThemeColor).brighten(0.1).hex();
        const tertiaryColor = isLight
          ? chroma(newThemeColor).darken(0.3).hex()
          : chroma(newThemeColor).brighten(0.3).hex();
        const borderColor = isLight
          ? chroma(newThemeColor).darken(0.4).hex()
          : chroma(newThemeColor).brighten(0.5).hex();
        const buttonColorLight = isBtnLight
          ? chroma(newButtonColor).darken(1.5).hex()
          : chroma(newButtonColor).brighten(1.5).hex();
        const socialBg = isLight
          ? chroma(newThemeColor).darken(0.8).hex()
          : chroma(newThemeColor).brighten(-0.5).hex();
        const buttonTextColor = isBtnLight ? "#000000" : "#ffffff";
        const gradientColorDark = isLight ? "#ffffff" : "#22a081";
        const gradientColorLight = isLight ? "#ffffff" : "#76e2c6";
        const heroGradientColor = isLight ? secondaryColor : "#535f79";

        const root = document.documentElement;
        root.style.setProperty("--primary_color", newThemeColor);
        root.style.setProperty("--secondary_color", secondaryColor);
        root.style.setProperty("--accent_color", accentColor);
        root.style.setProperty("--tertiary_color", tertiaryColor);
        root.style.setProperty("--border_color", borderColor);
        root.style.setProperty("--primary_text_color", primaryTextColor);
        root.style.setProperty("--heading_text_color", headingTextColor);
        root.style.setProperty("--primary_button_color", newButtonColor);
        root.style.setProperty(
          "--primary_button_color_light",
          buttonColorLight
        );
        root.style.setProperty("--social_bg", socialBg);
        root.style.setProperty("--button_text_color", buttonTextColor);
        root.style.setProperty("--gradient_color_dark", gradientColorDark);
        root.style.setProperty("--gradient_color_light", gradientColorLight);
        root.style.setProperty("--hero_gradient_color", heroGradientColor);
        root.style.setProperty("--error_color", errorColor);
      } else {
        const root = document.documentElement;
        root.style.setProperty("--primary_color", "#1e1e1e");
        root.style.setProperty("--secondary_color", "#21293b");
        root.style.setProperty("--accent_color", "#19202e");
        root.style.setProperty("--tertiary_color", "#232c3d");
        root.style.setProperty("--border_color", "#333e56");
        root.style.setProperty("--primary_text_color", "#535f79");
        root.style.setProperty("--heading_text_color", "#ffffff");
        root.style.setProperty("--primary_button_color", "#22a081");
        root.style.setProperty("--primary_button_color_light", "#76e2c6");
        root.style.setProperty("--social_bg", "#10151b");
        root.style.setProperty("--gradient_color_dark", "#22a081");
        root.style.setProperty("--gradient_color_light", "#76e2c6");
        root.style.setProperty("--hero_gradient_color", "#535f79");
        root.style.setProperty("--error_color", "#e35252");
      }
    } else {
      const root = document.documentElement;
      root.style.setProperty("--primary_color", "#1e1e1e");
      root.style.setProperty("--secondary_color", "#21293b");
      root.style.setProperty("--accent_color", "#19202e");
      root.style.setProperty("--tertiary_color", "#232c3d");
      root.style.setProperty("--border_color", "#333e56");
      root.style.setProperty("--primary_text_color", "#535f79");
      root.style.setProperty("--heading_text_color", "#ffffff");
      root.style.setProperty("--primary_button_color", "#22a081");
      root.style.setProperty("--primary_button_color_light", "#76e2c6");
      root.style.setProperty("--social_bg", "#10151b");
      root.style.setProperty("--gradient_color_dark", "#22a081");
      root.style.setProperty("--gradient_color_light", "#76e2c6");
      root.style.setProperty("--hero_gradient_color", "#535f79");
      root.style.setProperty("--error_color", "#e35252");
    }
    setLoading(false);
  };
  const [tempSortedFunctionData, setTempSortedFunctionData] = React.useState(
    []
  );
  const handlePriorityModalShow = () => {
    // Deep copy to isolate temporary state
    setTempSortedFunctionData(JSON.parse(JSON.stringify(sortedFunctionData)));
    setShowPriorityModal(true);
  };

  const handlePriorityModalClose = () => {
    setTempSortedFunctionData([]); // Reset temporary state
    setShowPriorityModal(false);
  };

  // const handlePriorityChange = (index, newValue) => {
  //   const updatedSortedFunctionData = [...sortedFunctionData];
  //   updatedSortedFunctionData[index].priority = newValue;
  //   setSortedFunctionData(updatedSortedFunctionData);
  // };

  const handlePriorityChange = (index, newValue, field = "priority") => {
    const updatedTempData = [...tempSortedFunctionData];
    if (field === "priority") {
      updatedTempData[index].priority = newValue;
    } else if (field === "is_visible") {
      updatedTempData[index].is_visible = newValue;
    }
    setTempSortedFunctionData(updatedTempData); // Update temporary state
  };

  // const handleSave = async (e) => {
  //   e.preventDefault();
  //   setLoading(true);
  //   const userFunctionSettings = [];
  //   const functionRows = document.querySelectorAll(".function-row");
  //   functionRows.forEach((row) => {
  //     // const functionType = row.querySelector(
  //     //   'select[name="function_type"]'
  //     // ).value;
  //     const functionType = row.querySelector(
  //       'input[name="function_type"]'
  //     ).value;
  //     const priority = row.querySelector('input[name="priority"]').value;
  //     const isVisible = row.querySelector('input[type="checkbox"]').checked
  //       ? 1
  //       : 0;
  //     userFunctionSettings.push({
  //       function_type: functionType,
  //       priority: priority,
  //       is_visible: isVisible,
  //     });
  //   });
  //   const payload = {
  //     team_id: teamId,
  //     userFunctionSettings: userFunctionSettings,
  //   };

  //   // Make API call
  //   let { status, message, data } = await post(
  //     PRIORITY,
  //     payload,
  //     Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
  //   );

  //   if (status) {
  //     priorityFunction();
  //     setShowPriorityModal(false);
  //   } else {
  //     showToast(status, message);
  //   }

  //   setLoading(false);
  // };
  const handleSave = async (e) => {
    e.preventDefault();
    setLoading(true);

    const userFunctionSettings = tempSortedFunctionData.map((item) => ({
      function_type: item.function_type,
      priority: item.priority || 0, // Ensure priority is not empty
      is_visible: item.is_visible ? 1 : 0,
    }));
    // const userFunctionSettings = [];
    // const functionRows = document.querySelectorAll(".function-row");
    // functionRows.forEach((row) => {
    //   // const functionType = row.querySelector(
    //   //   'select[name="function_type"]'
    //   // ).value;
    //   const functionType = row.querySelector(
    //     'input[name="function_type"]'
    //   ).value;
    //   const priority = row.querySelector('input[name="priority"]').value;
    //   const isVisible = row.querySelector('input[type="checkbox"]').checked
    //     ? 1
    //     : 0;
    //   userFunctionSettings.push({
    //     function_type: functionType,
    //     priority: priority,
    //     is_visible: isVisible,
    //   });
    // });

    const payload = {
      team_id: teamId,
      userFunctionSettings,
    };

    const { status, message } = await post(
      PRIORITY,
      payload,
      loggedUser?.token || null
    );

    if (status) {
      setSortedFunctionData([...tempSortedFunctionData]); // Commit temporary state
      setShowPriorityModal(false);
      priorityFunction();
    } else {
      showToast(status, message);
    }

    setLoading(false);
  };

  const priorityFunction = async () => {
    setLoading(true);
    const queryString = `?team_id=${userTeamId}`;
    let { status, message, data } = await get(
      userTeamId ? PRIORITY + queryString : PRIORITY,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      priorityData(data);
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  const priorityData = (sortPriorityData) => {
    const sortedData = [...FunctionCreateCardsData].map((card) => {
      const priorityItem = sortPriorityData.find(
        (item) => item.function_type === card.function_type
      );
      return {
        ...card,
        priority: priorityItem ? priorityItem.priority : 0,
        is_visible: priorityItem ? priorityItem.is_visible === "1" : "0",
      };
    });

    sortedData.sort((a, b) => {
      if (a.priority !== b.priority) {
        return a.priority - b.priority;
      }
    });

    setSortedFunctionData(sortedData);
  };

  useEffect(() => {
    // if (loggedUser && Object.keys(loggedUser).length > 0) {
    fetchColors(role);
    priorityFunction();
    // }
  }, [role]);

  useEffect(() => {
    setNewLoggedUser(loggedUser?.is_new_user);
    newLoggedUser === 1 ? setNewUserModal(true) : setNewUserModal(false);
  }, [loggedUser]);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="mt-3">
            <h6 className={classes.title}>Functions</h6>
          </div>
          <div className={classes.mainWrapper}>
            <HeroSection />
          </div>
          {loggedUser?.role[0] === "super_admin" ||
          loggedUser?.role[0] === "sub_admin" ? (
            ""
          ) : (
            <div className={classes.boxWrapper}>
              <button
                className={classes.priorityBtn}
                onClick={handlePriorityModalShow}
              >
                <RiListUnordered /> <span>PRIORITY FUNCTION</span>
              </button>
              {/* {FunctionCreateCardsData.map((card) => {
              return (
                <FunctionCard
                  key={card.id}
                  {...card}
                  teamId={teamId}
                  userId={userId}
                  personalCardsCount={personalCardsCount}
                  businessCardsCount={businessCardsCount}
                  customUrls={customUrls}
                  emergencyContacts={emergencyContacts}
                  payment={payment}
                  files={files}
                />
              );
            })} */}
              {sortedFunctionData.map((card) => (
                <FunctionCard
                  key={card.id}
                  {...card}
                  teamId={teamId}
                  userId={userId}
                  personalCardsCount={personalCardsCount}
                  businessCardsCount={businessCardsCount}
                  customUrls={customUrls}
                  emergencyContacts={emergencyContacts}
                  payment={payment}
                  files={files}
                  formBuilderCount={formBuilderCount}
                  memoryCount={memoryCount}
                  medicalCount={medicalCount}
                  isVisible={card.is_visible ? " " : "cardHide"}
                />
              ))}
            </div>
          )}

          <CustomURLSAddEditModal
            loading={modalLoading}
            show={showCustomUrlModal}
            data={customUrlData}
            setSelectedData={setCustomUrlData}
            handleSaveShowModal={async () => {
              await handleSaveCustomUrl();
              handleCustomUrlCloseModal();
            }}
            handleCloseModal={handleCustomUrlCloseModal}
          />
          <FileUploadAddEditModal
            loading={modalLoading}
            show={showFileUploadModal}
            data={fileUploadData}
            setSelectedData={setFileUploadData}
            handleSaveShowModal={async () => {
              await handleSaveFileUpload();
              handleFileUploadCloseModal();
            }}
            handleCloseModal={handleFileUploadCloseModal}
          />

          <PaymentAddEditModal
            loading={modalLoading}
            show={showPaymentModal}
            data={paymentData}
            setSelectedData={setPaymentData}
            handleSaveShowModal={async () => {
              await handleSavePayment();
              handlePaymentCloseModal();
            }}
            handleCloseModal={handlePaymentCloseModal}
          />

          <ConfirmationModal
            show={showModal}
            handleCloseModal={handleCloseModal}
            handleConfirmDelete={handleConfirmDelete}
          />
          <PriorityListingModal
            show={showPriorityModal}
            sortedFunctionData={tempSortedFunctionData} // Use temporary state
            handlePriorityChange={handlePriorityChange}
            handleSave={handleSave}
            handleClose={handlePriorityModalClose}
          />
          <Modal
            show={showPackageExpiredModal}
            //onHide={handleClosePackageExpiredModal}
            aria-labelledby="contained-modal-title-vcenter"
            className="customModal"
            size="sm"
          >
            <Modal.Header>
              <div className="modalGradientCircle"></div>
              {/* <MdClose
                onClick={handleClosePackageExpiredModal}
                className="modalCloseBtn"
              /> */}
            </Modal.Header>

            <Modal.Body
              // style={{ padding: "0" }}
              className={`d-flex justify-content-center flex-column align-items-center`}
            >
              <div
                // className="d-flex flex-column align-items-center justify-content-center"
                style={{
                  width: "fit-content",
                  marginTop: "-110px",
                  textAlign: "center",
                }}
              >
                <h5
                  style={{
                    color: "var(--error_color)",
                  }}
                >
                  Your Package is Expired!
                </h5>
                <p
                  style={{
                    fontSize: "12px",
                    color: "var(--heading_text_color)",
                    marginBottom: "30px",
                    lineHeight: "18px",
                  }}
                >
                  Your subscription is expired! Please upgrade or downgrade your
                  subscription in order to continue using this app.
                </p>
              </div>

              <div className={`customModalBtnWrapper ${classes.btnWrapper}`}>
                <button className="primary_button" onClick={handleUpgrade}>
                  Upgrade Now
                </button>
              </div>
            </Modal.Body>
          </Modal>
        </>
      )}
    </>
  );
};

export default Home;
