import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import SecondLoader from "../SecondLoader";
import AddCustomURLSchema from "../../utils/SchemasValidation/AddCustomURLSchema";
import ValidateSchema from "../../helpers/ValidateSchema";
import classes from "../../assets/styles/CustomURLModal.module.css";
import { MdClose } from "react-icons/md";
import FormInput from "../../components/Forms/FormInput";
import { ReactComponent as UploadIcon } from "../../assets/images/cloud-arrow.svg";
import crossicon from "../../assets/images/global_icons/crossicon.svg";

const FileUploadAddEditModal = ({
  loading,
  data,
  setSelectedData,
  handleSaveShowModal,
  handleCloseModal,
  show,
}) => {
  const [errors, setErrors] = useState({});
  const [selectedFile, setSelectedFile] = useState(null);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setSelectedData({ ...data, [name]: value });
  };

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]); // Update selected file state
  };

  // handle Validation
  const validateForm = () => {
    let newErrors = ValidateSchema(data, AddCustomURLSchema);
    setErrors(newErrors);
    if (Object.keys(newErrors).length) return false;
    return true;
  };

  const handleAddUrl = async (e) => {
    //if (!validateForm()) return;
    //handleSaveShowModal(data);
    handleSaveShowModal(data, selectedFile);
  };

  // Handle Update
  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          handleCloseModal();
          setErrors({});
        }}
        className={`customModal`}
        size="sm"
      >
        <Modal.Header className={classes.modalHeader}>
          <span className={classes.modalHeading}>
            {data?.isNew ? "Add" : "Edit "} File URL
          </span>
          <div className="modalGradientCircle"></div>
          <MdClose
            onClick={() => {
              handleCloseModal();
              setErrors({});
            }}
            className="modalCloseBtn"
          />
        </Modal.Header>
        {loading ? (
          <SecondLoader />
        ) : (
          <>
            <Modal.Body>
              <div style={{ marginTop: "-140px" }}>
                {/* <div className={`${classes.titleWrapper} mb-4`}>
                  <div
                    className={`${classes.urliconWrapper} w-25 mx-auto urliconWrapper position-relative`}
                  >
                    <input
                      type="file"
                      accept=".pdf,.docx,.xlsx" // Specify accepted file types
                      onChange={handleFileChange}
                    />
                    <UploadIcon />
                    <p className="upload-label mb-0 mt-2">Upload File</p>
                  </div>
                </div> */}
                {/* <div className={classes.fileUploadWrapper}>
                  <label>Upload File</label>
                  <input
                    type="file"
                    accept=".pdf,.docx,.xlsx" // Specify accepted file types
                    onChange={handleFileChange}
                  />
                </div> */}
                <FormInput
                  type="text"
                  label="Title"
                  placeholder="Enter Title"
                  name="title"
                  value={data.title}
                  onChange={handleChange}
                  error={errors?.title}
                />
                <FormInput
                  type="file"
                  label={data?.isNew ? "Upload File" : "Upload New File"}
                  placeholder="Enter Title"
                  onChange={handleFileChange}
                />
                <div className="or">
                  <span className="">OR</span>
                </div>
                <FormInput
                  type="text"
                  label="URL"
                  placeholder="Enter URL"
                  name="url_path"
                  value={data.url_path}
                  error={errors?.url_path}
                  onChange={handleChange}
                  // disabled={true}
                />
              </div>
            </Modal.Body>

            <Modal.Footer>
              <div className={classes.btnWrapper}>
                <Button
                  type="button"
                  onClick={() => {
                    handleCloseModal();
                    setErrors({});
                  }}
                  className={classes.cancelBtn}
                >
                  Cancel
                </Button>

                <Button
                  type="button"
                  onClick={handleAddUrl}
                  className={classes.saveBtn}
                >
                  Save
                </Button>
              </div>
            </Modal.Footer>
          </>
        )}
      </Modal>
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={`customModal`}
        size="sm"
      >
        <Modal.Header className={classes.mediaModalHeader}>
          <span>Upload</span>
          <img src={crossicon} alt="" style={{ cursor: "pointer" }} />
        </Modal.Header>

        <Modal.Body className={classes.mediaModalBody}>
          <div>
            <div
              className={`d-flex justify-content-center align-items-center flex-column ${classes.imageUpload}`}
            >
              <UploadIcon />
              <span>
                Drop your File here, or <span>Browse</span>
              </span>
              <span>
                Support JPG, PNG, JPEG, GIF, SVG, PDF, DOC, DOCX, TXT, XLS,
                XLSX, CSV Only
              </span>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <style type="">
        {`
          .upload-label{
            color: var(--heading_text_color) !important;
          }
          .urliconWrapper{
            cursor: pointer;
          }
          .urliconWrapper input{
            position: absolute;
            height: 100%;
            width: 100%;
            opacity: 0;
            cursor: pointer;
          }
          input[type="file"]::-webkit-file-upload-button {
            display:none
          }
          .or{
            color: var(--heading_text_color);
            margin: 1.5rem auto 1rem;
            position: relative;
            text-align: center;
          }
            .or:before{
              content: "";
              background-color: var(--heading_text_color);
              width: 100%;
              height: 1px;
              display: block;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
            }
              .or span{
                background-color: var(--secondary_color);
                padding: 0 0.75rem;
                position: relative;
                font-size: 1rem;
                letter-spacing: 1px;
                font-weight:600
              }
        `}
      </style>
    </>
  );
};

export default FileUploadAddEditModal;
