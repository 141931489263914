import { useState, useEffect, useRef } from "react";
import {
  USERNFC,
  EMERGENCY_CONTACT_VCF_FILE_URL,
} from "../../../utils/constants/apiUrl";
import { get, post } from "../../../server/index";
import showToast from "../../../helpers/showToast";
import Loader from "../../Loader";
import Card from "../Function/Card";
import { AddMoreCustomUrls } from "../../../utils/data/CardFunctionsData";
import { useParams } from "react-router-dom";
import { Image } from "react-bootstrap";
import baseURL from "../../../utils/constants/baseUrl";
// import classes from "../../../assets/styles/Pages/Function/ViewEmergencyContact.module.css";
import classes from "../../../assets/styles/Pages/Function/ViewPublicProfile.module.css";
import "react-phone-input-2/lib/material.css";
import dummyImage from "../../../assets/images/global_icons/dummyimage.png";
import contacticon from "../../../assets/images/function_page_icons/contacticon.svg";
import playstore from "../../../assets/images/footer_icons/playstore.svg";
import appstore from "../../../assets/images/footer_icons/appstore.svg";
import _ from "lodash";
import formatPhoneNumber from "../../../helpers/formatPhoneNumber";
import { BiLinkExternal } from "react-icons/bi";
import { SHARE_QR } from "../../../utils/constants/common";
import paymentlogo from "../../../assets/images/function_page_icons/paymenticon.svg";
import urlicon from "../../../assets/images/function_page_icons/url.svg";
import uploadIcon from "../../../assets/images/cloud-arrow.svg";
import MyMemoryLogo from "../../../pages/Function/FunctionLogo/MyMemoryLogo";
import FormBuilderLogo from "../../../pages/Function/FunctionLogo/FormBuilderLogo";

const PublicUserNFC = () => {
  const [loading, setLoading] = useState(false);
  const [userNFCList, setUserNFCList] = useState([]);
  const [imageLoaded, setImageLoaded] = useState(false);
  const { uuid } = useParams();
  const target = useRef(null);
  // Check if only one function has data
  const [isSingleItemPresent, setIsSingleItemPresent] = useState(false);
  const capitalizeFirstLetter = (string) => {
    if (!string) return string; // Handle null or undefined values
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const checkFunctionTypeExists = (data, functionTypes) => {
    let noOfRecords = 0;
    // Loop through the data to check for any of the function types
    for (let i = 0; i < data.length; i++) {
      const functionType = data[i].function_type;

      // Check if the current function_type matches any of the required functionTypes
      if (functionTypes.includes(functionType) && data[i].data.length === 1) {
        noOfRecords = noOfRecords + 1;
      }
    }
    return noOfRecords === 1; // No matching function type found
  };

  const renderCard = (eachUserNFC) => {
    return (
      <div className={`${classes.functionInfoInner} d-flex align-items-center`}>
        <div className={`${classes.imgWrapper} imgWrapper me-3`}>
          <Image
            className="globalIMageFit"
            src={
              eachUserNFC.profile_image
                ? `${baseURL.PUBLIC_URL}${eachUserNFC.profile_image}`
                : dummyImage
            }
            alt="img"
            roundedCircle={true}
            onLoad={() => setImageLoaded(true)}
          />
        </div>
        <div className={`{classes.detailWrapper}`}>
          {eachUserNFC?.first_name || eachUserNFC?.last_name ? (
            <p className={`${classes.subHeading} subHeading`}>{`${
              eachUserNFC.first_name || ""
            } ${eachUserNFC.last_name || ""}`}</p>
          ) : (
            ""
          )}
          <a
            href={`mailto:${eachUserNFC.email}`}
            className={classes.subHeadingData}
          >
            {capitalizeFirstLetter(eachUserNFC.card_type)}
          </a>
        </div>
        <button
          className={classes.shareBtnIcon}
          ref={target}
          onClick={() => {
            const shareUrl = `${SHARE_QR}card/${eachUserNFC.uuid}`;
            window.open(shareUrl, "_blank");
          }}
        >
          <BiLinkExternal />
        </button>
      </div>
    );
  };

  const renderEmergencyContact = (eachUserNFC) => {
    return (
      <div className={`${classes.functionInfoInner} d-flex align-items-center`}>
        <div className={`${classes.imgWrapper} imgWrapper me-3`}>
          <Image
            className="globalIMageFit"
            src={
              eachUserNFC.image
                ? `${baseURL.PUBLIC_URL}${eachUserNFC.image}`
                : dummyImage
            }
            alt="img"
            roundedCircle={true}
            onLoad={() => setImageLoaded(true)}
          />
        </div>
        <div>
          <p className={`${classes.subHeading} subHeading`}>
            {`${eachUserNFC?.first_name || ""} ${eachUserNFC?.last_name || ""}`}
          </p>
          <a
            href={`tel:${eachUserNFC.phone_number}`}
            className={classes.subHeadingData}
            style={{
              fontSize: "11px",
            }}
          >
            {`+${eachUserNFC?.country_phone}${formatPhoneNumber(
              eachUserNFC?.phone_number
            )}`}
          </a>
        </div>
        <button
          className={classes.shareBtnIcon}
          ref={target}
          onClick={() => {
            const shareUrl = `${SHARE_QR}emergencyContact/${eachUserNFC.uuid}`;
            window.open(shareUrl, "_blank");
          }}
        >
          <BiLinkExternal />
        </button>
      </div>
    );
  };

  const renderMemory = (eachUserNFC) => {
    return (
      <div className={`${classes.functionInfoInner} d-flex align-items-center`}>
        <div className="icon-circle icon-circle-upload">
          <MyMemoryLogo
            primaryColor="var(--button_text_color)"
            secondaryColor="var(--primary_button_color)"
          />
        </div>
        <div>
          <div className={`${classes.subHeading} subHeading`}>
            {eachUserNFC.title}
          </div>
          {eachUserNFC.media_type ? (
            <div
              className={`${classes.infoContnt} infoContnt`}
              style={{
                borderColor: "var(--tertiary_color)",
              }}
            >
              <>
                <div>
                  <p className={classes.subHeadingData}>
                    {eachUserNFC.media_type}
                  </p>
                </div>
              </>
            </div>
          ) : (
            ""
          )}
          {/* {eachUserNFC.medias_count ? (
          <div
            className={`${classes.infoContnt} infoContnt`}
            style={{
              borderColor: "var(--tertiary_color)",
            }}
          >
            <>
              <div>
                <p className={`${classes.subHeading} subHeading`}>
                  Media Count
                </p>
                <p className={classes.subHeadingData}>
                  {eachUserNFC.medias_count}
                </p>
              </div>
            </>
          </div>
        ) : (
          ""
        )} */}
        </div>
        <button
          className={classes.shareBtnIcon}
          ref={target}
          onClick={() => {
            const shareUrl = `${SHARE_QR}memory/${eachUserNFC.uuid}`;
            window.open(shareUrl, "_blank");
          }}
        >
          <BiLinkExternal />
        </button>
      </div>
    );
  };

  const renderMedicalId = (eachUserNFC) => {
    return (
      <div className={`${classes.functionInfoInner} d-flex align-items-center`}>
        <div className={`${classes.imgWrapper} imgWrapper me-3`}>
          <Image
            className="globalIMageFit"
            src={
              eachUserNFC.photo
                ? `${baseURL.PUBLIC_URL}${eachUserNFC.photo}`
                : dummyImage
            }
            alt="img"
            roundedCircle={true}
            onLoad={() => setImageLoaded(true)}
          />
        </div>
        <div>
          <p className={`${classes.subHeading} subHeading`}>
            {`${eachUserNFC.first_name || ""} ${eachUserNFC.last_name || ""}`}
          </p>
          <p className={classes.subHeadingData}>{eachUserNFC.url_path}</p>
          {eachUserNFC?.blood_type ? (
            <>
              <p className={classes.subHeadingData}>{eachUserNFC.blood_type}</p>
            </>
          ) : (
            ""
          )}
        </div>
        <button
          className={classes.shareBtnIcon}
          ref={target}
          onClick={() => {
            const shareUrl = `${SHARE_QR}medical_id/${eachUserNFC.uuid}`;
            window.open(shareUrl, "_blank");
          }}
        >
          <BiLinkExternal />
        </button>
      </div>
    );
  };

  const renderFileUpload = (functionType, eachUserNFC) => {
    return (
      <div className={`${classes.functionInfoInner} d-flex align-items-center`}>
        <div className="icon-circle icon-circle-upload">
          <img src={uploadIcon} alt="" />
        </div>
        <div>
          <p className={`${classes.subHeading} subHeading`}>
            {eachUserNFC.title}
          </p>
          <p className={classes.subHeadingData}>
            {eachUserNFC.url_path.length > 30
              ? `${eachUserNFC.url_path.slice(
                  0,
                  30
                )}...${eachUserNFC.url_path.slice(-10)}`
              : eachUserNFC.url_path}
          </p>
        </div>
        <button
          className={classes.shareBtnIcon}
          ref={target}
          onClick={() => {
            const shareUrl = `${SHARE_QR}fileUpload/${eachUserNFC.uuid}`;
            window.open(shareUrl, "_blank");
          }}
        >
          <BiLinkExternal />
        </button>
      </div>
    );
  };

  const renderPayment = (functionType, eachUserNFC) => {
    return (
      <div className={`${classes.functionInfoInner} d-flex align-items-center`}>
        <div className="icon-circle">
          <Image
            src={paymentlogo}
            width="30px"
            height="30px"
            roundedCircle={true}
          />
        </div>
        <div>
          <p className={`${classes.subHeading} subHeading`}>
            {eachUserNFC.title}
          </p>
          <p className={classes.subHeadingData}>{eachUserNFC.url_path}</p>
        </div>
        <button
          className={classes.shareBtnIcon}
          ref={target}
          onClick={() => {
            const shareUrl = `${SHARE_QR}customPayment/${eachUserNFC.uuid}`;
            window.open(shareUrl, "_blank");
          }}
        >
          <BiLinkExternal />
        </button>
      </div>
    );
  };

  const renderCustomUrl = (functionType, eachUserNFC) => {
    return (
      <div className={`${classes.functionInfoInner} d-flex align-items-center`}>
        <div className="icon-circle">
          <img src={urlicon} alt="" />
        </div>
        <div>
          <p className={`${classes.subHeading} subHeading`}>
            {eachUserNFC.title}
          </p>
          <p className={classes.subHeadingData}>{eachUserNFC.url_path}</p>
        </div>
        <button
          className={classes.shareBtnIcon}
          ref={target}
          onClick={() => {
            const shareUrl = `${SHARE_QR}customUrl/${eachUserNFC.uuid}`;
            window.open(shareUrl, "_blank");
          }}
        >
          <BiLinkExternal />
        </button>
      </div>
    );
  };

  const renderFormBuilder = (functionType, eachUserNFC) => {
    return (
      <div className={`${classes.functionInfoInner} d-flex align-items-center`}>
        <div className="icon-circle icon-circle-upload">
          <FormBuilderLogo
            primaryColor="var(--heading_text_color)"
            secondaryColor="var(--heading_text_color)"
          />
        </div>
        <p className={classes.subHeadingData}>{eachUserNFC.name}</p>
        <button
          className={classes.shareBtnIcon}
          ref={target}
          onClick={() => {
            const shareUrl = `${SHARE_QR}form_builder/${eachUserNFC.uuid}`;
            window.open(shareUrl, "_blank");
          }}
        >
          <BiLinkExternal />
        </button>
      </div>
    );
  };

  const renderEachData = (functionType, nfcDataList) => {
    return functionType === "card"
      ? nfcDataList.map((eachUserNFC) => renderCard(eachUserNFC))
      : functionType === "emergency_contact"
      ? nfcDataList.map((eachUserNFC) => renderEmergencyContact(eachUserNFC))
      : functionType === "memory"
      ? nfcDataList.map((eachUserNFC) => renderMemory(eachUserNFC))
      : functionType === "medical_id"
      ? nfcDataList.map((eachUserNFC) => renderMedicalId(eachUserNFC))
      : ["cash_app", "venmo", "custom_payment", "payment"].includes(
          functionType
        )
      ? nfcDataList.map((eachUserNFC) =>
          renderPayment(functionType, eachUserNFC)
        )
      : functionType === "file_upload"
      ? nfcDataList.map((eachUserNFC) =>
          renderFileUpload(functionType, eachUserNFC)
        )
      : functionType === "custom_url"
      ? nfcDataList.map((eachUserNFC) =>
          renderCustomUrl(functionType, eachUserNFC)
        )
      : functionType === "form_builder"
      ? nfcDataList.map((eachUserNFC) =>
          renderFormBuilder(functionType, eachUserNFC)
        )
      : null;
  };

  const fetchUserNFC = async () => {
    setLoading(true);
    let { status, message, data: nfcData } = await get(`${USERNFC}/${uuid}`);
    if (status) {
      if (
        checkFunctionTypeExists(nfcData, [
          "card",
          "emergency_contact",
          "custom_url",
          "file_upload",
          "payment",
          "memory",
          "medical_id",
          "form_builder",
        ])
      ) {
        let item = nfcData[0];
        if (item?.function_type === "card") {
          window.location.href = `${SHARE_QR}card/${item.data[0].uuid}`;
        } else if (item?.function_type === "emergency_contact") {
          window.location.href = `${SHARE_QR}emergencyContact/${item.data[0].uuid}`;
        } else if (item?.function_type === "custom_url") {
          window.location.href = `${SHARE_QR}customUrl/${item.data[0].uuid}`;
        } else if (item?.function_type === "file_upload") {
          window.location.href = `${SHARE_QR}fileUpload/${item.data[0].uuid}`;
        } else if (item?.function_type === "memory") {
          window.location.href = `${SHARE_QR}memory/${item.data[0].uuid}`;
        } else if (item?.function_type === "medical_id") {
          window.location.href = `${SHARE_QR}medical_id/${item.data[0].uuid}`;
        } else if (item?.function_type === "form_builder") {
          window.location.href = `${SHARE_QR}form_builder/${item.data[0].uuid}`;
        } else {
          window.location.href = `${SHARE_QR}customPayment/${item.data[0].uuid}`;
        }
      } else {
        setUserNFCList(nfcData);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUserNFC();
  }, []);

  if (loading) {
    return <Loader />;
  }
  console.log(userNFCList);
  return (
    <>
      <div className="container">
        <div className="py-4">
          <div className="row justify-content-center">
            <div className="col-md-5 col-12">
              <div className={`${classes.mainWrapper} mainWrapper`}>
                {/* <h6 className={classes.title}>Profile</h6> */}
                <div className={classes.cardWrapper}>
                  <div className={classes.profileCardWrapper}>
                    <div className={`${classes.contactInfo} contactInfo`}>
                      {userNFCList.map((eachData, index) => {
                        const nfcDataList = Array.isArray(eachData.data)
                          ? eachData.data
                          : [];

                        // Filter personal and business cards
                        const personalNfcDataList = nfcDataList.filter(
                          (eachUserNFC) => eachUserNFC.card_type === "personal"
                        );
                        const businessNfcDataList = nfcDataList.filter(
                          (eachUserNFC) => eachUserNFC.card_type === "business"
                        );

                        return (
                          <div key={index}>
                            <h6 className={classes.functionTitle}>
                              {eachData.function_type === "file_upload"
                                ? "File Upload"
                                : eachData.function_type === "memory"
                                ? "Memory"
                                : eachData.function_type === "custom_url"
                                ? "Custom URL’s"
                                : eachData.function_type === "medical_id"
                                ? "Medical ID"
                                : eachData.function_type === "emergency_contact"
                                ? "Emergency Contact"
                                : eachData.function_type === "form_builder"
                                ? "Lead Builder"
                                : eachData.function_type === "payment"
                                ? "Payment Service"
                                : ""}
                            </h6>

                            {/* Render based on available card type */}
                            {personalNfcDataList.length > 0 ||
                            businessNfcDataList.length > 0 ? (
                              <>
                                {personalNfcDataList.length > 0 && (
                                  <>
                                    <h6 className={classes.functionTitle}>
                                      Personal Card
                                    </h6>
                                    <div
                                      className={`${classes.functionInfo} functionInfo`}
                                    >
                                      <div
                                        className={`${classes.contactInfoInner} mt-0`}
                                      >
                                        {renderEachData(
                                          eachData.function_type,
                                          personalNfcDataList
                                        )}
                                      </div>
                                    </div>
                                  </>
                                )}
                                {businessNfcDataList.length > 0 && (
                                  <>
                                    <h6 className={classes.functionTitle}>
                                      Business Card
                                    </h6>
                                    <div
                                      className={`${classes.functionInfo} functionInfo`}
                                    >
                                      <div
                                        className={`${classes.contactInfoInner} mt-0`}
                                      >
                                        {renderEachData(
                                          eachData.function_type,
                                          businessNfcDataList
                                        )}
                                      </div>
                                    </div>
                                  </>
                                )}
                              </>
                            ) : (
                              <div
                                className={`${classes.functionInfo} functionInfo`}
                              >
                                <div
                                  className={`${classes.contactInfoInner} mt-0`}
                                >
                                  {renderEachData(
                                    eachData.function_type,
                                    nfcDataList
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      })}
                      {userNFCList.length === 0 ? (
                        <div className="text-center">
                          <p className="deactivetext">
                            <strong>You have no active function</strong>
                          </p>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>

              <div className={classes.cardFooter}>
                <div className="d-flex align-items-center gap-3 justify-content-center">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.contactco.contactapp"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={playstore} alt="" />
                  </a>
                  <a
                    href="https://apps.apple.com/us/app/contact-co-connect-smarter/id6683301913"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={appstore} alt="" />
                  </a>
                </div>
                <p>
                  © {new Date().getFullYear()} Contact Co, All rights reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style type="text/css">{`
        .deactivetext{
          color: #fff
        }
        .imgWrapper{
          display: inline-block;
          position:relative;
        }
        .globalIMageFit{
          width:50px !important;
          height:50px !important;
        }
        .globalIMageFitSmall{
          width:30px !important;
          height:30px !important;
          position:absolute;
          bottom:-3px;
          right:-10px;
        }
        .mainWrapper{
          max-width: 100%
        }
          .icon-circle{
              width: 50px !important;
    height: 50px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--primary_text_color);
    border-radius: 50px;
    background: var(--primary_button_color);
    margin-right: 16px;
          }
    .icon-circle-upload img, .icon-circle-upload svg{
        width:30px
    }
      `}</style>
    </>
  );
};

export default PublicUserNFC;
