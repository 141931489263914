import React, { useState } from "react";
import classes from "../assets/styles/Pages/Function/ViewEmergencyContact.module.css";
import "react-phone-input-2/lib/material.css";
import playstore from "../assets/images/footer_icons/playstore.svg";
import appstore from "../assets/images/footer_icons/appstore.svg";
import { EMERGENCY_CONTACT_VCF_FILE_URL } from "../utils/constants/apiUrl";
import { post } from "../server";
import { loggedInUser } from "../utils/store";
import { useAtom } from "jotai";
import showToast from "../helpers/showToast";
import { useParams } from "react-router-dom";
import baseURL from "../utils/constants/baseUrl";
import { Image } from "react-bootstrap";

const PublicMemory = ({ memoryData }) => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [VCFData, setVCFData] = useState(memoryData);
  const [imageLoaded, setImageLoaded] = useState(false);

  const { uuid } = useParams();

  const [showFullText, setShowFullText] = useState(false);

  const handleVcfFile = async () => {
    let { status, message, data } = await post(
      EMERGENCY_CONTACT_VCF_FILE_URL + `/${VCFData.uuid}`,
      VCFData,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      const link = document.createElement("a");
      link.download = "temp.vcf";
      link.href = data.url;
      link.click();
    } else {
      showToast(status, message);
    }
  };

  return (
    <>
      <div className="container">
        <div className="row justify-content-center py-5">
          <div className="Col-12 col-lg-5">
            <div className={classes.mainWrapper} style={{ maxWidth: "100%" }}>
              <h6 className={classes.title}>Memory</h6>
              <div className={classes.cardWrapper}>
                <div className="">
                  <div className={classes.profileCardWrapper}>
                    <div className={classes.detailWrapper}>
                      <h6 className="mb-0">{`${memoryData.title || ""}`}</h6>
                      {memoryData.media_type === "image" ? (
                        <>
                          {memoryData.medias.map((item) => {
                            return (
                              <div key={item.id}>
                                <Image
                                  src={
                                    baseURL.AWS_URL +
                                    "dev/memory/" +
                                    item.media_path
                                  }
                                  width="100%"
                                  height="300px"
                                  loading="lazy"
                                  decoding="async"
                                  className="memory-img mt-4"
                                />
                              </div>
                            );
                          })}
                        </>
                      ) : memoryData.media_type === "audio" ? (
                        <>
                          {memoryData.medias.map((item) => {
                            return (
                              <div key={item.id}>
                                <audio
                                  width="100%"
                                  controls
                                  className="mt-4 w-100"
                                >
                                  <source
                                    src={
                                      baseURL.AWS_URL +
                                      "dev/memory/" +
                                      item.media_path
                                    }
                                    type="audio/mpeg"
                                  />
                                </audio>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          {memoryData.medias.map((item) => {
                            return (
                              <div key={item.id}>
                                <video
                                  width="100%"
                                  controls
                                  className="mt-4 w-100"
                                >
                                  <source
                                    src={
                                      baseURL.AWS_URL +
                                      "dev/memory/" +
                                      item.media_path
                                    }
                                    type="video/mp4"
                                  />
                                </video>
                              </div>
                            );
                          })}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className={classes.cardFooter}>
                <div className="d-flex align-items-center gap-3 justify-content-center">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.contactco.contactapp"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={playstore} alt="" />
                  </a>
                  <a
                    href="https://apps.apple.com/us/app/contact-co-connect-smarter/id6683301913"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={appstore} alt="" />
                  </a>
                </div>
                <p>
                  © {new Date().getFullYear()} Contact Co, All rights reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PublicMemory;
