import React, { useEffect } from "react";
import classes from "../../../assets/styles/PageComponentsStyles/Settings/Subscription.module.css";

import basicicon from "../../../assets/images/settings_page_icons/basicicon.png";
import proicon from "../../../assets/images/settings_page_icons/proicon.png";
import premiumicon from "../../../assets/images/settings_page_icons/premiumicon.png";
import enterpriseIcon from "../../../assets/images/settings_page_icons/enterpriseicon.png";
import SubscriptionModal from "../../../components/Modals/SubscriptionModal";
import { Link, useParams } from "react-router-dom";
import {
  loggedInUser,
  subscriptionPackages,
  subscribedPackage,
  userIDCheck,
  teamIDCheck,
} from "../../../utils/store";
import { useAtom } from "jotai";
import Loader from "../../Loader";
import { useState } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { AiFillCheckCircle } from "react-icons/ai";
import { loadStripe } from "@stripe/stripe-js";
import { get, post } from "../../../server";
import {
  CANCEL_SUBSCRIPTION,
  COUPON_APPLY,
  GET_USER_SUBSCRIPTION,
} from "../../../utils/constants/apiUrl";
import { IoSend } from "react-icons/io5";
import showToast from "../../../helpers/showToast";
import { Modal } from "react-bootstrap";
import { MdClose } from "react-icons/md";
// Load Stripe Data
const stripePromise = loadStripe(
  // "pk_test_51OZ7XQSBnxMCCnjEI1odHcAyBmW5WZd48fFUxnvHPpg2LDNKRGGCOJ3sU78SW9vi5wOmuLrwX2qxf8slIZpXFukR00DuK51ScU" // test
  // "pk_test_51LsD48ErALx3fhV7MXHeYvMoWC4uLJr33gThVHtfuMAbiE9kNWsDMsYmKAcv1KWGSlSBPiQwplojALZAJ1fUk8bO00NnHNL8FY" //sourav send
  "pk_live_51LsD48ErALx3fhV7pL6Gg2Wd2ymytgrKjj1tjb9cJICFlMAO24seG1sVDFTtKBFuOeD9luN1KSlS7Ho3xuY9tRZt00GZDEzFrx"
);

// Stripe OPtions
const elementsOptions = {
  fonts: [
    {
      cssSrc: "https://fonts.googleapis.com/css?family=Roboto",
    },
  ],
  locale: "en",
  placeholder: {
    number: "Card number",
    cvc: "CVC",
    expiry: "MM/YY",
  },
  style: {
    base: {
      fontSize: "16px",
      color: "#fff",
      "::placeholder": {
        color: "#fff",
      },
    },
    invalid: {
      color: "#9e2146",
    },
  },
};
const Subscription = () => {
  const { teamId } = useParams();
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [purchasePackages, setPurchasePackages] = useAtom(subscriptionPackages);
  const [purchasedPackage, setPurchasedPackage] = useAtom(subscribedPackage);
  const [selectedPackage, setSelectedPackage] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [subscriptions, setSubscriptions] = useState(purchasePackages);
  const [loading, setLoading] = useState(false);
  const [activeKeys, setActiveKeys] = useState([]);
  const [selectedSubscriptionType, setSelectedSubscriptionType] =
    useState("monthly");
  const [couponCode, setCouponCode] = useState("");

  const [hasCouponMonthly, setHasCouponMonthly] = useState([]);
  const [hasCouponYearly, setHasCouponYearly] = useState([]);
  const [blankCouponField, setBlankCouponField] = useState(false);
  const [correctCouponEntered, setCorrectCouponEntered] = useState(false);
  const [incorrectCouponEntered, setIncorrectCouponEntered] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [couponData, setCouponData] = useState("");
  const creationDate = new Date(purchasedPackage?.end_date);
  const currentDate = new Date();
  // Compare the current date with the end_date
  const isExpired = creationDate < currentDate;
  const formattedDate = creationDate.toLocaleString();

  // const handleCheckboxChangeMonthly = () => {
  //   setHasCouponMonthly((prev) => !prev);
  // };
  // const handleCheckboxChangeYearly = () => {
  //   setHasCouponYearly((prev) => !prev);
  // };

  const handleCheckboxChangeMonthly = (index) => {
    setHasCouponMonthly((prev) => {
      const updated = [...prev];
      updated[index] = !updated[index];
      return updated;
    });
  };

  const handleCheckboxChangeYearly = (index) => {
    setHasCouponYearly((prev) => {
      const updated = [...prev];
      updated[index] = !updated[index];
      return updated;
    });
  };

  const handleTabSelect = (subscriptionIndex, key) => {
    setActiveKeys((prevActiveKeys) => {
      // Create a copy of the previous activeKeys array
      const updatedActiveKeys = [...prevActiveKeys];
      // Update the active key for the corresponding subscription
      updatedActiveKeys[subscriptionIndex] = key;
      return updatedActiveKeys;
    });
  };

  useEffect(() => {
    setCorrectCouponEntered(false);
    setIncorrectCouponEntered(false);
  }, [activeKeys]);

  const handleCouponInputChange = (event) => {
    setCouponCode(event.target.value);
  };
  // const applyCoupon = async () => {
  //   setLoading(true);
  //   const payload = {
  //     // package_id:,
  //     coupon_code:couponCode,
  //     subscription_type: selectedSubscriptionType,
  //   };
  //   let { status, message, data } = await post(
  //     COUPON_APPLY,
  //     payload,
  //     Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
  //   );
  //   if (status) {
  //     console.log("COUPON_APPLY", data);
  //   }
  // };

  const applyCoupon = async (subscriptionType, packageId) => {
    if (!couponCode) {
      setBlankCouponField(true);
      return;
    }
    // setLoading(true);
    setCorrectCouponEntered(false);
    setIncorrectCouponEntered(false);

    const payload = {
      package_id: packageId,
      coupon_code: couponCode,
      subscription_type: subscriptionType,
    };
    let { status, message, data } = await post(
      COUPON_APPLY,
      payload,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      setCorrectCouponEntered(true);
      setCouponData(data);
    } else {
      setIncorrectCouponEntered(true);
    }
  };

  const getSubscriptionDetails = async () => {
    const payload = {
      user_id: loggedUser.id,
      team_id: null,
    };
    let { status, message, data } = await post(
      GET_USER_SUBSCRIPTION,
      payload,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      setPurchasedPackage(data[0]?.package);
    } else {
      showToast(status, message);
    }
  };

  const handlePayment = async (obj, subscription_type) => {
    setSelectedPackage(obj);
    setSelectedSubscriptionType(subscription_type);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleCloseCancelModal = () => {
    setShowCancelModal(false);
  };

  const handleCancelSubscription = () => {
    setShowCancelModal(true);
  };

  const handleConfirmDelete = async () => {
    const payload = {
      device_type: "web",
      team_id: teamId,
    };
    let { status, message, data } = await post(
      CANCEL_SUBSCRIPTION,
      payload,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      setPurchasedPackage(data.package);
      showToast(true, message);
      setShowCancelModal(false);
    } else {
      showToast(false, message);
    }
    setLoading(false);
  };

  useEffect(() => {
    getSubscriptionDetails();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      {purchasedPackage?.id !== 1 ? (
        <div className={classes.cardsWrapper}>
          <div className={`${classes.card} py-3 mb-4`}>
            {purchasedPackage?.end_date !== null ? (
              <p className="noteText text-center">
                {isExpired
                  ? `Your subscription has expired on ${formattedDate}`
                  : `Your purchased package will end on ${formattedDate}`}
              </p>
            ) : (
              ""
            )}
          </div>
        </div>
      ) : (
        ""
      )}

      <div className={classes.cardsWrapper}>
        {subscriptions.length !== 0
          ? subscriptions
              .filter(
                (opt) =>
                  opt.package_type == "personal" ||
                  opt.package_type == "customer_specific_personal"
              )
              .map((singlePackage, index) => (
                <div className={classes.card} key={`pricingCard${index}`}>
                  <button className={classes.subscriptionType}>
                    {purchasedPackage?.id === singlePackage.id && !isExpired ? (
                      <>
                        {singlePackage.package_name} &nbsp;
                        <AiFillCheckCircle />
                      </>
                    ) : (
                      <>{singlePackage.package_name}</>
                    )}
                  </button>
                  {singlePackage.id === 1 ||
                  singlePackage.id === 2 ||
                  singlePackage.id === 3 ? (
                    <div className={classes.tabsWrapper}>
                      <Tabs
                        defaultActiveKey="monthly"
                        id={`uncontrolled-tab-example-${index}`}
                        className="customSubscriptionTabs"
                        transition={false}
                        activeKey={activeKeys[index]}
                        onSelect={(k) => handleTabSelect(index, k)}
                      >
                        <Tab eventKey="monthly" title="Monthly">
                          <div className={classes.imgWrapper}>
                            <img
                              src={
                                singlePackage.package_name === "Starter"
                                  ? basicicon
                                  : singlePackage.package_name === "Premium"
                                  ? proicon
                                  : singlePackage.id === 6
                                  ? enterpriseIcon
                                  : premiumicon
                              }
                              alt={
                                singlePackage.package_name === "Starter"
                                  ? "Starter"
                                  : singlePackage.package_name === "Premium"
                                  ? "Premium"
                                  : singlePackage.id === 6
                                  ? "Enterprise"
                                  : "Elite"
                              }
                            />
                          </div>
                          {/* <h3 className={classes.title}>
                          {singlePackage.package_name === "Starter"
                            ? "Free"
                            : singlePackage.package_name === "Enterprise"
                            ? "Custom Price"
                            : `$${singlePackage.price_monthly}`}
                        </h3> */}
                          <h3 className={classes.title}>
                            {couponData.package_id === singlePackage.id
                              ? `$${couponData.package_price_after_discount}`
                              : singlePackage.package_name === "Starter"
                              ? "Free"
                              : singlePackage.id === 7
                              ? "Custom Price"
                              : singlePackage.package_type ===
                                "customer_specific_personal"
                              ? `$${singlePackage.price}/${singlePackage.duration_type}`
                              : `$${singlePackage.price_monthly}`}
                          </h3>

                          <ul className={classes.list}>
                            {/* Connection */}
                            {singlePackage.limit_connections_save === 0 ? (
                              <li>
                                <div
                                  className="d-flex align-items-center justify-content-between"
                                  style={{ color: "var(--primary_text_color)" }}
                                >
                                  <p>Connections</p>
                                  <p>{singlePackage.limit_connections_save}</p>
                                </div>
                              </li>
                            ) : (
                              <li>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p>Connections</p>
                                  <p>{singlePackage.limit_connections_save}</p>
                                </div>
                              </li>
                            )}
                            {/*  Card Section */}
                            {singlePackage.limit_card_create === 0 ? (
                              <li>
                                <div
                                  className="d-flex align-items-center justify-content-between"
                                  style={{ color: "var(--primary_text_color)" }}
                                >
                                  <p>Personal/Business Card</p>
                                  <p>{singlePackage.limit_card_create}</p>
                                </div>
                              </li>
                            ) : singlePackage.id === 1 ? (
                              <li>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p>Personal/Business Card</p>
                                  <p></p>
                                </div>
                              </li>
                            ) : (
                              <li>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p>Personal/Business Card</p>
                                  <p>{singlePackage.limit_card_create}</p>
                                </div>
                              </li>
                            )}

                            {/* Payments */}
                            {singlePackage.limit_payment_create == 0 ? (
                              <li>
                                <div
                                  className="d-flex align-items-center justify-content-between"
                                  style={{ color: "var(--primary_text_color)" }}
                                >
                                  <p>Payments cards</p>
                                  <p>{singlePackage.limit_payment_create}</p>
                                </div>
                              </li>
                            ) : singlePackage.id === 1 ? (
                              <li>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p>Payments cards</p>
                                  <p></p>
                                </div>
                              </li>
                            ) : (
                              <li>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p>Payments cards</p>
                                  <p>{singlePackage.limit_payment_create}</p>
                                </div>
                              </li>
                            )}

                            {/* Emergency Contact */}
                            {singlePackage.limit_emergency_contact_create ==
                            0 ? (
                              <li>
                                <div
                                  className="d-flex align-items-center justify-content-between"
                                  style={{ color: "var(--primary_text_color)" }}
                                >
                                  <p>Emergency Contacts</p>
                                  <p>
                                    {
                                      singlePackage.limit_emergency_contact_create
                                    }
                                  </p>
                                </div>
                              </li>
                            ) : singlePackage.id === 1 ? (
                              <li>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p>Emergency Contacts</p>
                                  <p></p>
                                </div>
                              </li>
                            ) : (
                              <li>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p>Emergency Contacts</p>
                                  <p>
                                    {
                                      singlePackage.limit_emergency_contact_create
                                    }
                                  </p>
                                </div>
                              </li>
                            )}

                            {/* Custom Url */}
                            {singlePackage.limit_custom_url_create == 0 ? (
                              <li>
                                <div
                                  className="d-flex align-items-center justify-content-between"
                                  style={{ color: "var(--primary_text_color)" }}
                                >
                                  <p>Custom URL</p>
                                  <p>{singlePackage.limit_custom_url_create}</p>
                                </div>
                              </li>
                            ) : singlePackage.id === 1 ? (
                              <li>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p>Custom URL</p>
                                  <p></p>
                                </div>
                              </li>
                            ) : (
                              <li>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p>Custom URL</p>
                                  <p>{singlePackage.limit_custom_url_create}</p>
                                </div>
                              </li>
                            )}

                            {/* File Upload Url */}
                            {singlePackage.limit_file_upload_create == 0 ? (
                              <li>
                                <div
                                  className="d-flex align-items-center justify-content-between"
                                  style={{ color: "var(--primary_text_color)" }}
                                >
                                  <p>File Upload URL</p>
                                  <p>
                                    {singlePackage.limit_file_upload_create}
                                  </p>
                                </div>
                              </li>
                            ) : singlePackage.id === 1 ? (
                              <li>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p>File Upload URL</p>
                                  <p></p>
                                </div>
                              </li>
                            ) : (
                              <li>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p>File Upload URL</p>
                                  <p>
                                    {singlePackage.limit_file_upload_create}
                                  </p>
                                </div>
                              </li>
                            )}
                            {singlePackage.limit_memory == 0 ? (
                              <li>
                                <div
                                  className="d-flex align-items-center justify-content-between"
                                  style={{ color: "var(--primary_text_color)" }}
                                >
                                  <p>Memory</p>
                                  <p>{singlePackage.limit_memory}</p>
                                </div>
                              </li>
                            ) : singlePackage.id === 1 ? (
                              <li>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p>Memory</p>
                                  <p></p>
                                </div>
                              </li>
                            ) : (
                              <li>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p>Memory</p>
                                  <p>{singlePackage.limit_memory}</p>
                                </div>
                              </li>
                            )}
                            {singlePackage.limit_form_builder == 0 ? (
                              <li>
                                <div
                                  className="d-flex align-items-center justify-content-between"
                                  style={{ color: "var(--primary_text_color)" }}
                                >
                                  <p>Lead Builder</p>
                                  <p>{singlePackage.limit_form_builder}</p>
                                </div>
                              </li>
                            ) : singlePackage.id === 1 ? (
                              <li>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p>Lead Builder</p>
                                  <p></p>
                                </div>
                              </li>
                            ) : (
                              <li>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p>Lead Builder</p>
                                  <p>{singlePackage.limit_form_builder}</p>
                                </div>
                              </li>
                            )}

                            {singlePackage.limit_medical_id == 0 ? (
                              <li>
                                <div
                                  className="d-flex align-items-center justify-content-between"
                                  style={{ color: "var(--primary_text_color)" }}
                                >
                                  <p>Medical ID</p>
                                  <p>{singlePackage.limit_medical_id}</p>
                                </div>
                              </li>
                            ) : singlePackage.id === 1 ? (
                              <li>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p>Medical ID</p>
                                  <p></p>
                                </div>
                              </li>
                            ) : (
                              <li>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p>Medical ID</p>
                                  <p>{singlePackage.limit_medical_id}</p>
                                </div>
                              </li>
                            )}

                            {singlePackage.id === 1 &&
                            singlePackage.limit_dynamic_qr_code == 0 ? (
                              <li>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p>Dynamic QR Code</p>
                                  <p></p>
                                </div>
                              </li>
                            ) : singlePackage.limit_dynamic_qr_code == 0 ? (
                              <li>
                                <div
                                  className="d-flex align-items-center justify-content-between"
                                  style={{ color: "var(--primary_text_color)" }}
                                >
                                  <p>Dynamic QR Code</p>
                                  <p>{singlePackage.limit_dynamic_qr_code}</p>
                                </div>
                              </li>
                            ) : (
                              <li>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p>Dynamic QR Code</p>
                                  <p>{singlePackage.limit_dynamic_qr_code}</p>
                                </div>
                              </li>
                            )}

                            {singlePackage.limit_dynamic_qr_code_each_function ==
                            0 ? (
                              <li>
                                <div
                                  className="d-flex align-items-center justify-content-between"
                                  style={{ color: "var(--primary_text_color)" }}
                                >
                                  <p>Dynamic QR Code Function Limit</p>
                                  <p>
                                    {
                                      singlePackage.limit_dynamic_qr_code_each_function
                                    }
                                  </p>
                                </div>
                              </li>
                            ) : singlePackage.id === 1 ? (
                              <li>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p>Dynamic QR Code Function Limit</p>
                                  <p></p>
                                </div>
                              </li>
                            ) : (
                              <li>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p>Dynamic QR Code Function Limit</p>
                                  <p>
                                    {
                                      singlePackage.limit_dynamic_qr_code_each_function
                                    }
                                  </p>
                                </div>
                              </li>
                            )}

                            {/*Custom Button */}
                            {singlePackage.enable_custom_buttons == 0 ? (
                              <li>
                                <div
                                  className="d-flex align-items-center justify-content-between"
                                  style={{ color: "var(--primary_text_color)" }}
                                >
                                  <p>Custom Button</p>
                                  <p>
                                    {singlePackage.enable_custom_buttons
                                      ? "Yes"
                                      : "No"}
                                  </p>
                                </div>
                              </li>
                            ) : (
                              <li>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p>Custom Button</p>
                                  <p>
                                    {singlePackage.enable_custom_buttons
                                      ? "Yes"
                                      : "No"}
                                  </p>
                                </div>
                              </li>
                            )}

                            {/*Email Notification Button */}
                            {/* {singlePackage.enable_email_notifications == 0 ? (
                         <li>
                           <div
                             className="d-flex align-items-center justify-content-between"
                             style={{ color: "var(--primary_text_color)" }}
                           >
                             <p>Email Notification</p>
                             <p>
                               {singlePackage.enable_email_notifications
                                 ? "Yes"
                                 : "No"}
                             </p>
                           </div>
                         </li>
                         ) : (
                           <li>
                             <div className="d-flex align-items-center justify-content-between">
                               <p>Email Notification</p>
                               <p>
                                 {singlePackage.enable_email_notifications
                                   ? "Yes"
                                   : "No"}
                               </p>
                             </div>
                           </li>
                         )} */}
                          </ul>

                          {singlePackage.id === 1 ? (
                            <p className="noteText mt-2">
                              In free mode you are only able to create one
                              function at a time and deleting it allows you to
                              create another.
                            </p>
                          ) : (
                            ""
                          )}
                          {singlePackage.id === 4 ? (
                            <>
                              <div className={classes.couponWrapper}>
                                <div class="form-check text-start">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                  />
                                  <label
                                    class="form-check-label"
                                    for="flexCheckDefault"
                                  >
                                    Do you have a coupon?
                                  </label>
                                </div>
                              </div>
                              <div className={classes.btnWrapper}>
                                <Link
                                  to={`/PackageContactForm/${singlePackage.id}`}
                                >
                                  <button className={classes.btn}>
                                    Contact Us
                                  </button>
                                </Link>
                              </div>
                            </>
                          ) : purchasedPackage.id == singlePackage.id &&
                            !isExpired ? (
                            <>
                              <div className={classes.btnWrapper}>
                                <button className={classes.btn} disabled>
                                  Subscribed{" "}
                                  <div
                                    style={{
                                      display: "inline",
                                      // backgroundColor: "white",
                                    }}
                                  >
                                    <AiFillCheckCircle />
                                  </div>
                                </button>

                                {singlePackage.id === 1 ? (
                                  ""
                                ) : (
                                  <button
                                    className={`${classes.outlineBtn} mt-4`}
                                    onClick={handleCancelSubscription}
                                  >
                                    Cancel Subscription
                                  </button>
                                )}
                              </div>
                            </>
                          ) : (
                            // <>
                            //   {/* <div
                            //     style={{
                            //       borderTop: "1px solid var(--border_color)",
                            //       marginTop: "10px",
                            //     }}
                            //   > */}
                            //   <div className={classes.imagesWrapper}>
                            //     <div>
                            //       <a
                            //         href="https://play.google.com/store/apps/details?id=com.contactco.contactapp"
                            //         target="_blank"
                            //       >
                            //         <img src={playstore} alt="" />
                            //       </a>
                            //     </div>
                            //     <div>
                            //       <a
                            //         href="https://apps.apple.com/us/app/contact-co-connect-smarter/id6683301913"
                            //         target="_blank"
                            //       >
                            //         <img src={appstore} alt="" />
                            //       </a>
                            //     </div>
                            //   </div>
                            //   {/* </div> */}
                            // </>
                            <>
                              {singlePackage.package_name === "Starter" ||
                              singlePackage.id == 7 ? (
                                ""
                              ) : (
                                <div className={classes.couponWrapper}>
                                  <div className="form-check text-start">
                                    {/* <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                    onChange={handleCheckboxChangeMonthly}
                                  /> */}
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value=""
                                      id={`monthlyCheckbox-${index}`}
                                      onChange={() =>
                                        handleCheckboxChangeMonthly(index)
                                      }
                                      checked={hasCouponMonthly[index]}
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="flexCheckDefault"
                                    >
                                      Do you have a coupon?
                                    </label>
                                  </div>
                                  {hasCouponMonthly[index] && (
                                    <div className="input-group mt-3">
                                      <input
                                        type="text"
                                        className="form-control coupon-form-control"
                                        placeholder="Enter Coupon code"
                                        onChange={handleCouponInputChange}
                                      />
                                      <button
                                        className="btn btn-outline-secondary"
                                        type="button"
                                        onClick={() =>
                                          applyCoupon(
                                            "monthly",
                                            singlePackage.id
                                          )
                                        }
                                      >
                                        <IoSend />
                                      </button>
                                      {blankCouponField && (
                                        <div className="failurText">
                                          Please enter coupon code
                                        </div>
                                      )}
                                      {correctCouponEntered && (
                                        <div className="successText">
                                          Yes, coupon successfully applied
                                        </div>
                                      )}
                                      {incorrectCouponEntered && (
                                        <div className="failurText">
                                          Sorry, coupon code invalid
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              )}
                              {singlePackage.id == 7 ? (
                                <div className={classes.btnWrapper}>
                                  <Link
                                    to={`/PackageContactForm/${singlePackage.id}`}
                                  >
                                    <button className={classes.btn}>
                                      Contact Us
                                    </button>
                                  </Link>
                                </div>
                              ) : (
                                <div className={classes.btnWrapper}>
                                  <button
                                    className={classes.btn}
                                    onClick={() =>
                                      handlePayment(singlePackage, "monthly")
                                    }
                                  >
                                    Select Package
                                  </button>
                                </div>
                              )}
                            </>
                          )}
                        </Tab>
                        <Tab eventKey="yearly" title="Yearly">
                          <div className={classes.imgWrapper}>
                            <img
                              src={
                                singlePackage.package_name === "Starter"
                                  ? basicicon
                                  : singlePackage.package_name === "Premium"
                                  ? proicon
                                  : singlePackage.id === 6
                                  ? enterpriseIcon
                                  : premiumicon
                              }
                              alt={
                                singlePackage.package_name === "Starter"
                                  ? "Starter"
                                  : singlePackage.package_name === "Premium"
                                  ? "Premium"
                                  : singlePackage.id === 6
                                  ? "Enterprise"
                                  : "Elite"
                              }
                            />
                          </div>
                          {/* <h3 className={classes.title}>
                          {singlePackage.package_name === "Starter"
                            ? "Free"
                            : singlePackage.package_name === "Enterprise"
                            ? "Custom Price"
                            : `$${singlePackage.price_yearly}`}
                        </h3> */}
                          <h3 className={classes.title}>
                            {couponData.package_id === singlePackage.id
                              ? `$${couponData.package_price_after_discount}`
                              : singlePackage.package_name === "Starter"
                              ? "Free"
                              : singlePackage.package_name === "Enterprise"
                              ? "Custom Price"
                              : singlePackage.package_type ===
                                "customer_specific_personal"
                              ? `$${singlePackage.price}/${singlePackage.duration_type}`
                              : `$${singlePackage.price_yearly}`}
                          </h3>

                          <ul className={classes.list}>
                            {/* Connection */}
                            {singlePackage.limit_connections_save == 0 ? (
                              <li>
                                <div
                                  className="d-flex align-items-center justify-content-between"
                                  style={{ color: "var(--primary_text_color)" }}
                                >
                                  <p>Connections</p>
                                  <p>{singlePackage.limit_connections_save}</p>
                                </div>
                              </li>
                            ) : (
                              <li>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p>Connections</p>
                                  <p>{singlePackage.limit_connections_save}</p>
                                </div>
                              </li>
                            )}

                            {/*  Card Section */}
                            {singlePackage.limit_card_create == 0 ? (
                              <li>
                                <div
                                  className="d-flex align-items-center justify-content-between"
                                  style={{ color: "var(--primary_text_color)" }}
                                >
                                  <p>Personal/Business Card</p>
                                  <p>{singlePackage.limit_card_create}</p>
                                </div>
                              </li>
                            ) : singlePackage.id === 1 ? (
                              <li>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p>Personal/Business Card</p>
                                  <p></p>
                                </div>
                              </li>
                            ) : (
                              <li>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p>Personal/Business Card</p>
                                  <p>{singlePackage.limit_card_create}</p>
                                </div>
                              </li>
                            )}

                            {/* Payments */}
                            {singlePackage.limit_payment_create == 0 ? (
                              <li>
                                <div
                                  className="d-flex align-items-center justify-content-between"
                                  style={{ color: "var(--primary_text_color)" }}
                                >
                                  <p>Payments cards</p>
                                  <p>{singlePackage.limit_payment_create}</p>
                                </div>
                              </li>
                            ) : singlePackage.id === 1 ? (
                              <li>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p>Payments cards</p>
                                  <p></p>
                                </div>
                              </li>
                            ) : (
                              <li>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p>Payments cards</p>
                                  <p>{singlePackage.limit_payment_create}</p>
                                </div>
                              </li>
                            )}

                            {/* Emergency Contact */}
                            {singlePackage.limit_emergency_contact_create ==
                            0 ? (
                              <li>
                                <div
                                  className="d-flex align-items-center justify-content-between"
                                  style={{ color: "var(--primary_text_color)" }}
                                >
                                  <p>Emergency Contacts</p>
                                  <p>
                                    {
                                      singlePackage.limit_emergency_contact_create
                                    }
                                  </p>
                                </div>
                              </li>
                            ) : singlePackage.id === 1 ? (
                              <li>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p>Emergency Contacts</p>
                                  <p></p>
                                </div>
                              </li>
                            ) : (
                              <li>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p>Emergency Contacts</p>
                                  <p>
                                    {
                                      singlePackage.limit_emergency_contact_create
                                    }
                                  </p>
                                </div>
                              </li>
                            )}

                            {/* Custom Url */}
                            {singlePackage.limit_custom_url_create == 0 ? (
                              <li>
                                <div
                                  className="d-flex align-items-center justify-content-between"
                                  style={{ color: "var(--primary_text_color)" }}
                                >
                                  <p>Custom URL</p>
                                  <p>{singlePackage.limit_custom_url_create}</p>
                                </div>
                              </li>
                            ) : singlePackage.id === 1 ? (
                              <li>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p>Custom URL</p>
                                  <p></p>
                                </div>
                              </li>
                            ) : (
                              <li>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p>Custom URL</p>
                                  <p>{singlePackage.limit_custom_url_create}</p>
                                </div>
                              </li>
                            )}

                            {/* File Upload Url */}
                            {singlePackage.limit_file_upload_create == 0 ? (
                              <li>
                                <div
                                  className="d-flex align-items-center justify-content-between"
                                  style={{ color: "var(--primary_text_color)" }}
                                >
                                  <p>File Upload URL</p>
                                  <p>
                                    {singlePackage.limit_file_upload_create}
                                  </p>
                                </div>
                              </li>
                            ) : singlePackage.id === 1 ? (
                              <li>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p>File Upload URL</p>
                                  <p></p>
                                </div>
                              </li>
                            ) : (
                              <li>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p>File Upload URL</p>
                                  <p>
                                    {singlePackage.limit_file_upload_create}
                                  </p>
                                </div>
                              </li>
                            )}
                            {singlePackage.limit_memory == 0 ? (
                              <li>
                                <div
                                  className="d-flex align-items-center justify-content-between"
                                  style={{ color: "var(--primary_text_color)" }}
                                >
                                  <p>Memory</p>
                                  <p>{singlePackage.limit_memory}</p>
                                </div>
                              </li>
                            ) : singlePackage.id === 1 ? (
                              <li>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p>Memory</p>
                                  <p></p>
                                </div>
                              </li>
                            ) : (
                              <li>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p>Memory</p>
                                  <p>{singlePackage.limit_memory}</p>
                                </div>
                              </li>
                            )}
                            {/* Form Builder */}
                            {singlePackage.limit_form_builder == 0 ? (
                              <li>
                                <div
                                  className="d-flex align-items-center justify-content-between"
                                  style={{ color: "var(--primary_text_color)" }}
                                >
                                  <p>Lead Builder</p>
                                  <p>{singlePackage.limit_form_builder}</p>
                                </div>
                              </li>
                            ) : singlePackage.id === 1 ? (
                              <li>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p>Lead Builder</p>
                                  <p></p>
                                </div>
                              </li>
                            ) : (
                              <li>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p>Lead Builder</p>
                                  <p>{singlePackage.limit_form_builder}</p>
                                </div>
                              </li>
                            )}

                            {singlePackage.limit_medical_id == 0 ? (
                              <li>
                                <div
                                  className="d-flex align-items-center justify-content-between"
                                  style={{ color: "var(--primary_text_color)" }}
                                >
                                  <p>Medical ID</p>
                                  <p>{singlePackage.limit_medical_id}</p>
                                </div>
                              </li>
                            ) : singlePackage.id === 1 ? (
                              <li>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p>Medical ID</p>
                                  <p></p>
                                </div>
                              </li>
                            ) : (
                              <li>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p>Medical ID</p>
                                  <p>{singlePackage.limit_medical_id}</p>
                                </div>
                              </li>
                            )}

                            {singlePackage.limit_dynamic_qr_code == 0 ? (
                              <li>
                                <div
                                  className="d-flex align-items-center justify-content-between"
                                  style={{ color: "var(--primary_text_color)" }}
                                >
                                  <p>Dynamic QR Code</p>
                                  <p>{singlePackage.limit_dynamic_qr_code}</p>
                                </div>
                              </li>
                            ) : singlePackage.id === 1 ? (
                              <li>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p>Dynamic QR Code</p>
                                  <p></p>
                                </div>
                              </li>
                            ) : (
                              <li>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p>Dynamic QR Code</p>
                                  <p>{singlePackage.limit_dynamic_qr_code}</p>
                                </div>
                              </li>
                            )}

                            {singlePackage.limit_dynamic_qr_code_each_function ==
                            0 ? (
                              <li>
                                <div
                                  className="d-flex align-items-center justify-content-between"
                                  style={{ color: "var(--primary_text_color)" }}
                                >
                                  <p>Dynamic QR Code Function Limit</p>
                                  <p>
                                    {
                                      singlePackage.limit_dynamic_qr_code_each_function
                                    }
                                  </p>
                                </div>
                              </li>
                            ) : singlePackage.id === 1 ? (
                              <li>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p>Dynamic QR Code Function Limit</p>
                                  <p></p>
                                </div>
                              </li>
                            ) : (
                              <li>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p>Dynamic QR Code Function Limit</p>
                                  <p>
                                    {
                                      singlePackage.limit_dynamic_qr_code_each_function
                                    }
                                  </p>
                                </div>
                              </li>
                            )}

                            {/*Custom Button */}
                            {singlePackage.enable_custom_buttons == 0 ? (
                              <li>
                                <div
                                  className="d-flex align-items-center justify-content-between"
                                  style={{ color: "var(--primary_text_color)" }}
                                >
                                  <p>Custom Button</p>
                                  <p>
                                    {singlePackage.enable_custom_buttons
                                      ? "Yes"
                                      : "No"}
                                  </p>
                                </div>
                              </li>
                            ) : (
                              <li>
                                <div className="d-flex align-items-center justify-content-between">
                                  <p>Custom Button</p>
                                  <p>
                                    {singlePackage.enable_custom_buttons
                                      ? "Yes"
                                      : "No"}
                                  </p>
                                </div>
                              </li>
                            )}

                            {/*Email Notification Button */}
                            {/* {singlePackage.enable_email_notifications == 0 ? (
                         <li>
                           <div
                             className="d-flex align-items-center justify-content-between"
                             style={{ color: "var(--primary_text_color)" }}
                           >
                             <p>Email Notification</p>
                             <p>
                               {singlePackage.enable_email_notifications
                                 ? "Yes"
                                 : "No"}
                             </p>
                           </div>
                         </li>
                       ) : (
                         <li>
                           <div className="d-flex align-items-center justify-content-between">
                             <p>Email Notification</p>
                             <p>
                               {singlePackage.enable_email_notifications
                                 ? "Yes"
                                 : "No"}
                             </p>
                           </div>
                         </li>
                       )} */}
                          </ul>
                          {singlePackage.id === 1 ? (
                            <p className="noteText mt-2">
                              In free mode you are only able to create one
                              function at a time and deleting it allows you to
                              create another.
                            </p>
                          ) : (
                            ""
                          )}
                          {singlePackage.id === 4 || singlePackage.id === 7 ? (
                            <div className={classes.btnWrapper}>
                              <Link
                                to={`/PackageContactForm/${singlePackage.id}`}
                              >
                                <button className={classes.btn}>
                                  Contact Us
                                </button>
                              </Link>
                            </div>
                          ) : purchasedPackage.id == singlePackage.id ? (
                            <>
                              <div className={classes.btnWrapper}>
                                <button className={classes.btn} disabled>
                                  Subscribed{" "}
                                  <div
                                    style={{
                                      display: "inline",
                                    }}
                                  >
                                    <AiFillCheckCircle />
                                  </div>
                                </button>
                                {singlePackage.id === 1 ? (
                                  ""
                                ) : (
                                  <button
                                    className={`${classes.outlineBtn} mt-4`}
                                    onClick={handleCancelSubscription}
                                  >
                                    Cancel Subscription
                                  </button>
                                )}
                              </div>
                            </>
                          ) : (
                            <>
                              {singlePackage.package_name === "Starter" ? (
                                ""
                              ) : (
                                <div className={classes.couponWrapper}>
                                  <div className="form-check text-start">
                                    {/* <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                    onChange={handleCheckboxChangeYearly}
                                  /> */}
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      value=""
                                      id={`yearlyCheckbox-${index}`}
                                      onChange={() =>
                                        handleCheckboxChangeYearly(index)
                                      }
                                      checked={hasCouponYearly[index]}
                                    />
                                    <label
                                      className="form-check-label"
                                      for="flexCheckDefault"
                                    >
                                      Do you have a coupon?
                                    </label>
                                  </div>
                                  {hasCouponYearly[index] && (
                                    <div className="input-group mt-3">
                                      <input
                                        type="text"
                                        className="form-control coupon-form-control"
                                        placeholder="Enter Coupon code"
                                        onChange={handleCouponInputChange}
                                      />
                                      <button
                                        className="btn btn-outline-secondary"
                                        type="button"
                                        onClick={() =>
                                          applyCoupon(
                                            "yearly",
                                            singlePackage.id
                                          )
                                        }
                                      >
                                        <IoSend />
                                      </button>

                                      {correctCouponEntered && (
                                        <div className="successText">
                                          Yes, coupon successfully applied.
                                        </div>
                                      )}
                                      {incorrectCouponEntered && (
                                        <div className="failurText">
                                          Sorry, coupon code invalid
                                        </div>
                                      )}
                                    </div>
                                  )}
                                </div>
                              )}
                              <div className={classes.btnWrapper}>
                                <button
                                  className={classes.btn}
                                  onClick={() =>
                                    handlePayment(singlePackage, "yearly")
                                  }
                                >
                                  Select Package
                                </button>
                              </div>
                            </>
                          )}
                        </Tab>
                      </Tabs>
                    </div>
                  ) : (
                    <div className={classes.tabsWrapper}>
                      <div className={classes.imgWrapper}>
                        <img
                          src={
                            singlePackage.package_name === "Starter"
                              ? basicicon
                              : singlePackage.package_name === "Premium"
                              ? proicon
                              : singlePackage.id === 6
                              ? enterpriseIcon
                              : premiumicon
                          }
                          alt={
                            singlePackage.package_name === "Starter"
                              ? "Starter"
                              : singlePackage.package_name === "Premium"
                              ? "Premium"
                              : singlePackage.id === 6
                              ? "Enterprise"
                              : "Elite"
                          }
                        />
                      </div>
                      <h3 className={classes.title}>
                        {couponData.package_id === singlePackage.id
                          ? `$${couponData.package_price_after_discount}`
                          : singlePackage.package_name === "Starter"
                          ? "Free"
                          : singlePackage.id === 7
                          ? "Custom Price"
                          : singlePackage.package_type ===
                            "customer_specific_personal"
                          ? `$${singlePackage.price}/${singlePackage.duration_type}`
                          : `$${singlePackage.price_monthly}`}
                      </h3>

                      <ul className={classes.list}>
                        {/* Connection */}
                        {singlePackage.limit_connections_save === 0 ? (
                          <li>
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ color: "var(--primary_text_color)" }}
                            >
                              <p>Connections</p>
                              <p>{singlePackage.limit_connections_save}</p>
                            </div>
                          </li>
                        ) : (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Connections</p>
                              <p>{singlePackage.limit_connections_save}</p>
                            </div>
                          </li>
                        )}
                        {/*  Card Section */}
                        {singlePackage.limit_card_create === 0 ? (
                          <li>
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ color: "var(--primary_text_color)" }}
                            >
                              <p>Personal/Business Card</p>
                              <p>{singlePackage.limit_card_create}</p>
                            </div>
                          </li>
                        ) : singlePackage.id === 1 ? (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Personal/Business Card</p>
                              <p></p>
                            </div>
                          </li>
                        ) : (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Personal/Business Card</p>
                              <p>{singlePackage.limit_card_create}</p>
                            </div>
                          </li>
                        )}

                        {/* Payments */}
                        {singlePackage.limit_payment_create == 0 ? (
                          <li>
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ color: "var(--primary_text_color)" }}
                            >
                              <p>Payments cards</p>
                              <p>{singlePackage.limit_payment_create}</p>
                            </div>
                          </li>
                        ) : singlePackage.id === 1 ? (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Payments cards</p>
                              <p></p>
                            </div>
                          </li>
                        ) : (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Payments cards</p>
                              <p>{singlePackage.limit_payment_create}</p>
                            </div>
                          </li>
                        )}

                        {/* Emergency Contact */}
                        {singlePackage.limit_emergency_contact_create == 0 ? (
                          <li>
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ color: "var(--primary_text_color)" }}
                            >
                              <p>Emergency Contacts</p>
                              <p>
                                {singlePackage.limit_emergency_contact_create}
                              </p>
                            </div>
                          </li>
                        ) : singlePackage.id === 1 ? (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Emergency Contacts</p>
                              <p></p>
                            </div>
                          </li>
                        ) : (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Emergency Contacts</p>
                              <p>
                                {singlePackage.limit_emergency_contact_create}
                              </p>
                            </div>
                          </li>
                        )}

                        {/* Custom Url */}
                        {singlePackage.limit_custom_url_create == 0 ? (
                          <li>
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ color: "var(--primary_text_color)" }}
                            >
                              <p>Custom URL</p>
                              <p>{singlePackage.limit_custom_url_create}</p>
                            </div>
                          </li>
                        ) : singlePackage.id === 1 ? (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Custom URL</p>
                              <p></p>
                            </div>
                          </li>
                        ) : (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Custom URL</p>
                              <p>{singlePackage.limit_custom_url_create}</p>
                            </div>
                          </li>
                        )}

                        {/* File Upload Url */}
                        {singlePackage.limit_file_upload_create == 0 ? (
                          <li>
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ color: "var(--primary_text_color)" }}
                            >
                              <p>File Upload URL</p>
                              <p>{singlePackage.limit_file_upload_create}</p>
                            </div>
                          </li>
                        ) : singlePackage.id === 1 ? (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>File Upload URL</p>
                              <p></p>
                            </div>
                          </li>
                        ) : (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>File Upload URL</p>
                              <p>{singlePackage.limit_file_upload_create}</p>
                            </div>
                          </li>
                        )}
                        {singlePackage.limit_memory == 0 ? (
                          <li>
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ color: "var(--primary_text_color)" }}
                            >
                              <p>Memory</p>
                              <p>{singlePackage.limit_memory}</p>
                            </div>
                          </li>
                        ) : singlePackage.id === 1 ? (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Memory</p>
                              <p></p>
                            </div>
                          </li>
                        ) : (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Memory</p>
                              <p>{singlePackage.limit_memory}</p>
                            </div>
                          </li>
                        )}
                        {singlePackage.limit_form_builder == 0 ? (
                          <li>
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ color: "var(--primary_text_color)" }}
                            >
                              <p>Lead Builder</p>
                              <p>{singlePackage.limit_form_builder}</p>
                            </div>
                          </li>
                        ) : singlePackage.id === 1 ? (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Lead Builder</p>
                              <p></p>
                            </div>
                          </li>
                        ) : (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Lead Builder</p>
                              <p>{singlePackage.limit_form_builder}</p>
                            </div>
                          </li>
                        )}

                        {singlePackage.limit_medical_id == 0 ? (
                          <li>
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ color: "var(--primary_text_color)" }}
                            >
                              <p>Medical ID</p>
                              <p>{singlePackage.limit_medical_id}</p>
                            </div>
                          </li>
                        ) : singlePackage.id === 1 ? (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Medical ID</p>
                              <p></p>
                            </div>
                          </li>
                        ) : (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Medical ID</p>
                              <p>{singlePackage.limit_medical_id}</p>
                            </div>
                          </li>
                        )}

                        {singlePackage.limit_dynamic_qr_code == 0 ? (
                          <li>
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ color: "var(--primary_text_color)" }}
                            >
                              <p>Dynamic QR Code</p>
                              <p>{singlePackage.limit_dynamic_qr_code}</p>
                            </div>
                          </li>
                        ) : singlePackage.id === 1 ? (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Dynamic QR Code</p>
                              <p></p>
                            </div>
                          </li>
                        ) : (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Dynamic QR Code</p>
                              <p>{singlePackage.limit_dynamic_qr_code}</p>
                            </div>
                          </li>
                        )}

                        {singlePackage.limit_dynamic_qr_code_each_function ==
                        0 ? (
                          <li>
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ color: "var(--primary_text_color)" }}
                            >
                              <p>Dynamic QR Code Function Limit</p>
                              <p>
                                {
                                  singlePackage.limit_dynamic_qr_code_each_function
                                }
                              </p>
                            </div>
                          </li>
                        ) : singlePackage.id === 1 ? (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Dynamic QR Code Function Limit</p>
                              <p></p>
                            </div>
                          </li>
                        ) : (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Dynamic QR Code Function Limit</p>
                              <p>
                                {
                                  singlePackage.limit_dynamic_qr_code_each_function
                                }
                              </p>
                            </div>
                          </li>
                        )}

                        {/*Custom Button */}
                        {singlePackage.enable_custom_buttons == 0 ? (
                          <li>
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ color: "var(--primary_text_color)" }}
                            >
                              <p>Custom Button</p>
                              <p>
                                {singlePackage.enable_custom_buttons
                                  ? "Yes"
                                  : "No"}
                              </p>
                            </div>
                          </li>
                        ) : (
                          <li>
                            <div className="d-flex align-items-center justify-content-between">
                              <p>Custom Button</p>
                              <p>
                                {singlePackage.enable_custom_buttons
                                  ? "Yes"
                                  : "No"}
                              </p>
                            </div>
                          </li>
                        )}
                      </ul>

                      {singlePackage.id === 4 ? (
                        <>
                          <div className={classes.couponWrapper}>
                            <div class="form-check text-start">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckDefault"
                              />
                              <label
                                class="form-check-label"
                                for="flexCheckDefault"
                              >
                                Do you have a coupon?
                              </label>
                            </div>
                          </div>
                          <div className={classes.btnWrapper}>
                            <Link
                              to={`/PackageContactForm/${singlePackage.id}`}
                            >
                              <button className={classes.btn}>
                                Contact Us
                              </button>
                            </Link>
                          </div>
                        </>
                      ) : purchasedPackage.id == singlePackage.id ? (
                        <>
                          <div className={classes.btnWrapper}>
                            <button className={classes.btn} disabled>
                              Subscribed{" "}
                              <div
                                style={{
                                  display: "inline",
                                  // backgroundColor: "white",
                                }}
                              >
                                <AiFillCheckCircle />
                              </div>
                            </button>

                            {singlePackage.id === 1 ? (
                              ""
                            ) : (
                              <button
                                className={`${classes.outlineBtn} mt-4`}
                                onClick={handleCancelSubscription}
                              >
                                Cancel Subscription
                              </button>
                            )}
                          </div>
                        </>
                      ) : (
                        // <>
                        //   {/* <div
                        //     style={{
                        //       borderTop: "1px solid var(--border_color)",
                        //       marginTop: "10px",
                        //     }}
                        //   > */}
                        //   <div className={classes.imagesWrapper}>
                        //     <div>
                        //       <a
                        //         href="https://play.google.com/store/apps/details?id=com.contactco.contactapp"
                        //         target="_blank"
                        //       >
                        //         <img src={playstore} alt="" />
                        //       </a>
                        //     </div>
                        //     <div>
                        //       <a
                        //         href="https://apps.apple.com/us/app/contact-co-connect-smarter/id6683301913"
                        //         target="_blank"
                        //       >
                        //         <img src={appstore} alt="" />
                        //       </a>
                        //     </div>
                        //   </div>
                        //   {/* </div> */}
                        // </>
                        <>
                          {singlePackage.package_name === "Starter" ||
                          singlePackage.id == 7 ? (
                            ""
                          ) : (
                            <div className={classes.couponWrapper}>
                              <div className="form-check text-start">
                                {/* <input
                                    className="form-check-input"
                                    type="checkbox"
                                    value=""
                                    id="flexCheckDefault"
                                    onChange={handleCheckboxChangeMonthly}
                                  /> */}
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id={`monthlyCheckbox-${index}`}
                                  onChange={() =>
                                    handleCheckboxChangeMonthly(index)
                                  }
                                  checked={hasCouponMonthly[index]}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckDefault"
                                >
                                  Do you have a coupon?
                                </label>
                              </div>
                              {hasCouponMonthly[index] && (
                                <div className="input-group mt-3">
                                  <input
                                    type="text"
                                    className="form-control coupon-form-control"
                                    placeholder="Enter Coupon code"
                                    onChange={handleCouponInputChange}
                                  />
                                  <button
                                    className="btn btn-outline-secondary"
                                    type="button"
                                    onClick={() =>
                                      applyCoupon("monthly", singlePackage.id)
                                    }
                                  >
                                    <IoSend />
                                  </button>
                                  {blankCouponField && (
                                    <div className="failurText">
                                      Please enter coupon code
                                    </div>
                                  )}
                                  {correctCouponEntered && (
                                    <div className="successText">
                                      Yes, coupon successfully applied
                                    </div>
                                  )}
                                  {incorrectCouponEntered && (
                                    <div className="failurText">
                                      Sorry, coupon code invalid
                                    </div>
                                  )}
                                </div>
                              )}
                            </div>
                          )}
                          {singlePackage.id == 7 ? (
                            <div className={classes.btnWrapper}>
                              <Link
                                to={`/PackageContactForm/${singlePackage.id}`}
                              >
                                <button className={classes.btn}>
                                  Contact Us
                                </button>
                              </Link>
                            </div>
                          ) : (
                            <div className={classes.btnWrapper}>
                              <button
                                className={classes.btn}
                                onClick={() =>
                                  handlePayment(
                                    singlePackage,
                                    singlePackage.duration_type
                                  )
                                }
                              >
                                Select Package
                              </button>
                            </div>
                          )}
                        </>
                      )}
                    </div>
                  )}
                </div>
              ))
          : null}
      </div>
      {showModal && (
        <SubscriptionModal
          show={showModal}
          stripePromise={stripePromise}
          handleCloseModal={handleCloseModal}
          selectedPackage={selectedPackage}
          selectedSubscriptionType={selectedSubscriptionType}
          elementsOptions={elementsOptions}
          couponData={couponData}
        />
      )}
      <Modal
        show={showCancelModal}
        onHide={handleCloseCancelModal}
        aria-labelledby="contained-modal-title-vcenter"
        className="customModal"
        size="sm"
      >
        <Modal.Header>
          <div className="modalGradientCircle"></div>
          <MdClose onClick={handleCloseCancelModal} className="modalCloseBtn" />
        </Modal.Header>

        <Modal.Body
          style={{ padding: "0" }}
          className={`d-flex justify-content-center flex-column align-items-center`}
        >
          <div
            className="d-flex flex-column align-items-center justify-content-center"
            style={{ width: "fit-content", lineHeight: "18px" }}
          >
            <h5>Are you sure?</h5>
            <p
              style={{
                lineHeight: "18px",
                textAlign: "center",
                padding: "0px 20px",
              }}
            >
              Are you sure you want to cancel your Subscription
            </p>
          </div>

          <div className={`customModalBtnWrapper ${classes.btnWrapper}`}>
            <button
              onClick={handleConfirmDelete}
              style={{
                background: "#E35252",
                boxShadow: "0px 6px 20px rgba(227, 82, 82, 0.4)",
              }}
            >
              Delete
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <style type="text/css">
        {`
         .form-check-label {
           font-size: 13px;
           font-weight: 500;
            color:var(--heading_text_color);
         }
         .form-check-input, .form-check-input:focus{
          //  background: var(--primary_color);
           border: 1px solid var(--border_color);
          outline:none
         }
         .coupon-form-control, .coupon-form-control:focus{
           background-color:transparent;
           color:var(--heading_text_color);
           font-size:13px;
           font-weight:700;
           border-radius:5rem;
           box-shadow:none;
         }
         .coupon-form-control::placeholder{
           color:var(--heading_text_color);
         }
         .btn-outline-secondary, .btn-outline-secondary:focus{
           border-radius:5rem;
           border-top-right-radius: 5rem !important;
            border-bottom-right-radius: 5rem !important;
            border-color: var(--border_color);
         }
         .failurText, .successText{
          text-align:left;
          font-size:13px;
          color:#ff0000;
          display:block;
          width:100%;
        }
        .successText{
          color: #42764e;
        }
        .noteText{
          color: var(--error_color); 
          font-size: 12px; 
          text-align: left;
          margin-bottom:0;
        }
         @media only screen and (max-width: 1399.98px) {
           .form-check-label {
             font-size: 12px;
            color:var(--heading_text_color);
           }
         }
       `}
      </style>
    </>
  );
};

export default Subscription;
