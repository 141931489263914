import React, { useState, useEffect } from "react";
import classes from "../../../assets/styles/Pages/CouponManagement/CouponManagement.module.css";
import { useAtom } from "jotai";
import { loggedInUser } from "../../../utils/store";
import Loader from "../../../components/Loader";
import FormInput from "../../../components/Forms/FormInput";
import searchIcon from "../../../assets/images/global_icons/searchicon.svg";
import SettingsInput from "../../../components/Forms/SettingsInput";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { post, get } from "../../../server";
import _ from "lodash";
import { COUPON_UPDATE, COUPON_DETAILS } from "../../../utils/constants/apiUrl";
import showToast from "../../../helpers/showToast";
import { useParams, useNavigate } from "react-router-dom";

const EditCoupons = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  let emptyObj = {
    id: "",
    name: "",
    couponCodeText: [],
    discount: "",
    start: "",
    end: "",
    package_id: "",
    duration_type: "",
    quantity: "",
    limit: "",
  };
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [loading, setLoading] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [errors, setErrors] = useState({});
  const [createCoupons, setCreateCoupons] = useState(emptyObj);
  const [showDurationType, setShowDurationType] = useState(false);
  const [couponCodeText, setCouponCodeText] = useState([]);

  const handleFilter = (e) => {
    setFilterText(e.target.value);
  };
  const handleStartDateChange = (e) => {
    setCreateCoupons({ ...createCoupons, start: e });
  };
  const handleEndDateChange = (e) => {
    setCreateCoupons({ ...createCoupons, end: e });
  };
  const handlePackage = (e, i) => {
    const name = e.target.name;
    const value = e.target.value;
    setCreateCoupons({ ...createCoupons, [name]: value });
    if (value === "2" || value === "3") {
      setShowDurationType(true);
    } else {
      setShowDurationType(false);
    }
  };
  const handleChange = (e, i) => {
    const name = e.target.name;
    const value = e.target.value;
    setCreateCoupons({ ...createCoupons, [name]: value });
  };
  const handleGenerateCodeChange = (e, i) => {
    const name = e.target.name;
    const value = e.target.value;
    const updatedCouponCodes = [...createCoupons.couponCodeText];
    updatedCouponCodes[i] = value;
    setCreateCoupons({
      ...createCoupons,
      couponCodeText: updatedCouponCodes,
    });
  };
  const handleDurationTypeChange = (e) => {
    const value = e.target.value;
    setCreateCoupons({ ...createCoupons, duration_type: value });
  };
  const handleAppliedTillChange = (e) => {
    const value = e.target.value;
    setCreateCoupons({ ...createCoupons, applied_till: value });
  };
  const handleLimitChange = (e) => {
    const value = e.target.value;
    if (value === "value") {
      setCreateCoupons({ ...createCoupons, limit: "" }); // Reset value for input when "Value" is selected
    } else if (value === "-1") {
      setCreateCoupons({ ...createCoupons, limit: -1 }); // Set limit to -1 for "Unlimited"
    }
  };

  const handleLimitInputChange = (e) => {
    const { value } = e.target;
    setCreateCoupons({ ...createCoupons, limit: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    //if (!validateForm()) return;
    setLoading(true);
    // let payload = { ...createCoupons };
    let payload = _.cloneDeep(createCoupons);
    if (id) {
      payload.coupon_id = id;
    }
    payload.applied_till = createCoupons.applied_till;
    //payload.duration_type = createCoupons.duration_type;
    if (createCoupons.package_id === "2" || createCoupons.package_id === "3") {
      payload.duration_type = createCoupons.duration_type;
    } else {
      payload.duration_type = createCoupons?.duration_type || "";
    }
    if (createCoupons.limit === -1) {
      payload.limit = -1;
    } else {
      payload.limit = createCoupons?.limit;
    }
    let { status, message, data } = await post(
      COUPON_UPDATE,
      payload,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      setCreateCoupons(data);
      navigate("/coupons");
    } else {
      showToast(status, message);
    }

    setLoading(false);
  };

  const loadCoupons = async () => {
    setLoading(true);
    const couponId = id;
    const queryString = `?coupon_id=${couponId}`;
    let { status, message, data } = await get(
      COUPON_DETAILS + queryString,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      data.start = new Date(data.start);
      data.end = new Date(data.end);
      setCreateCoupons(data);
      setCouponCodeText(data.couponCodeText);
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (id) {
      loadCoupons();
      if (createCoupons.duration_type) {
        setShowDurationType(true);
      } else {
        setShowDurationType(false);
      }
    }
  }, [id, createCoupons.duration_type]);

  return (
    <>
      {!loading ? (
        <div>
          <div className="page_Header">
            <div className="d-flex justify-content-between align-items-md-center align-items-initial flex-md-row flex-column gap-3">
              <p className="pageTitle"> {id ? "Edit" : "Add"} Coupon</p>
              <div className="d-flex align-items-center justify-content-between gap-2">
                <FormInput
                  icon={searchIcon}
                  placeholder="Search..."
                  value={filterText}
                  handleChange={handleFilter}
                />
              </div>
            </div>
          </div>
          <div className={classes.content}>
            <div className={`mainWrapper ${classes.outerWrapper}`}>
              <div className="row align-items-center gx-5 gy-3">
                <div className="col-md-4 col-12">
                  <SettingsInput
                    label="Coupon title"
                    type="text"
                    placeholder="New Year Discount"
                    name="name"
                    value={createCoupons.name}
                    handleChange={handleChange}
                    error={errors.name}
                  />
                </div>
                <div className="col-md-4 col-12">
                  <div className={classes.selectWrapper}>
                    <label
                      htmlFor=""
                      style={{
                        fontSize: "12px",
                        marginBottom: "10px",
                        lineHeight: "18px",
                      }}
                    >
                      Select package
                    </label>
                    <div className="input-group globalInputWithIcon">
                      <select
                        className="form-select customSelect"
                        name="package_id"
                        value={createCoupons.package_id}
                        onChange={handlePackage}
                      >
                        <option value="">Select package</option>
                        {/* <option value="1">Starter</option> */}
                        <option value="2">Premium</option>
                        <option value="3">Elite</option>
                        <option value="5">Team Elite</option>
                        {/* <option value="6">Enterprise</option> */}
                      </select>
                    </div>
                    <span className="errorText">{errors.package_id}</span>
                  </div>
                </div>
                {showDurationType == true ? (
                  <div className="col-md-4 col-12">
                    <div className={classes.selectWrapper}>
                      <label
                        htmlFor=""
                        style={{
                          fontSize: "12px",
                          marginBottom: "10px",
                          lineHeight: "18px",
                        }}
                      >
                        Duration Type
                      </label>
                      <div className="input-group globalInputWithIcon">
                        <select
                          className="form-select customSelect"
                          value={createCoupons.duration_type}
                          onChange={handleDurationTypeChange}
                        >
                          <option value="">Select Duration</option>
                          <option value="monthly">Monthly</option>
                          <option value="yearly">Yearly</option>
                        </select>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="col-md-4 col-12"></div>
                )}
                {/* <div className="col-md-4 col-12">
                  <SettingsInput
                    label="Limit"
                    type="text"
                    placeholder="Enter coupons limit"
                    name="limit"
                    value={createCoupons.limit}
                    handleChange={handleChange}
                    error={errors.limit}
                  />
                </div> */}
                <div className="col-md-4 col-12">
                  <SettingsInput
                    label="Percentage discount"
                    type="text"
                    placeholder="Enter discount value"
                    name="discount"
                    value={createCoupons.discount}
                    handleChange={handleChange}
                    error={errors.discount}
                  />
                </div>

                <div className="col-md-4 col-12">
                  <div className={classes.selectWrapper}>
                    <label
                      htmlFor=""
                      style={{
                        fontSize: "12px",
                        marginBottom: "10px",
                        lineHeight: "18px",
                      }}
                    >
                      Applied Till
                    </label>
                    <div className="input-group globalInputWithIcon">
                      <select
                        className="form-select customSelect"
                        value={createCoupons.applied_till}
                        onChange={handleAppliedTillChange}
                      >
                        <option value="">Select Applied For</option>
                        <option value="1_month">1 Month</option>
                        <option value="2_month">2 Month</option>
                        <option value="3_month">3 Month</option>
                        <option value="6_month">6 Month</option>
                        <option value="1_year">1 Year</option>
                        <option value="never_ending">Never Ending</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-12">
                  <label
                    htmlFor=""
                    className={classes.label}
                    style={{
                      color: "var(--heading_text_color)",
                    }}
                  >
                    From Date
                  </label>
                  <DatePicker
                    selected={createCoupons.start}
                    onChange={handleStartDateChange}
                    dateFormat="MM/dd/yyyy"
                    className="dobInput"
                    placeholderText="MM/dd/yyyy"
                    showYearDropdown
                    isClearable
                    yearDropdownItemNumber={110}
                    scrollableYearDropdown
                  />
                </div>
                <div className="col-md-4 col-12">
                  <label
                    htmlFor=""
                    className={classes.label}
                    style={{
                      color: "var(--heading_text_color)",
                    }}
                  >
                    To Date
                  </label>
                  <DatePicker
                    selected={createCoupons.end}
                    onChange={handleEndDateChange}
                    dateFormat="MM/dd/yyyy"
                    className="dobInput"
                    placeholderText="MM/dd/yyyy"
                    showYearDropdown
                    isClearable
                    yearDropdownItemNumber={110}
                    scrollableYearDropdown
                  />
                </div>
                <div className="col-md-4 col-12">
                  <label
                    style={{
                      fontSize: "12px",
                      marginBottom: "10px",
                      lineHeight: "18px",
                    }}
                  >
                    Coupon Limit
                  </label>
                  <div className="row">
                    <div className="col-md-6">
                      <label>
                        <input
                          type="radio"
                          name="limit"
                          value="value"
                          checked={createCoupons.limit !== -1}
                          onChange={handleLimitChange}
                        />{" "}
                        <span
                          style={{
                            fontSize: "12px",
                            marginBottom: "10px",
                            lineHeight: "18px",
                          }}
                        >
                          Value
                        </span>
                      </label>
                    </div>
                    <div className="col-md-6">
                      <label>
                        <input
                          type="radio"
                          name="limit"
                          value={-1}
                          checked={createCoupons.limit === -1}
                          onChange={handleLimitChange}
                        />{" "}
                        <span
                          style={{
                            fontSize: "12px",
                            marginBottom: "10px",
                            lineHeight: "18px",
                          }}
                        >
                          Unlimited
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                {createCoupons.limit !== -1 && (
                  <div className="col-md-4 col-12">
                    <SettingsInput
                      label="Enter Coupon Limit"
                      type="text"
                      placeholder="Enter coupon limit"
                      name="limitValue"
                      value={createCoupons.limit}
                      handleChange={handleLimitInputChange}
                      error={errors.limit}
                    />
                  </div>
                )}
                {couponCodeText.map((couponCode, index) => (
                  <div key={index} className="col-md-4 col-12">
                    <SettingsInput
                      label={`Generate coupon code`}
                      type="text"
                      placeholder="Enter coupon code"
                      name={`couponCodes${index}`}
                      value={couponCode.coupon}
                      handleChange={(e) => handleGenerateCodeChange(e, index)}
                      error={errors[`couponCodes${index}`]}
                    />
                  </div>
                ))}
              </div>
              {/* <div className="formBottom">
                <Button
                  className="submitButton"
                  type="submit"
                  onClick={handleSystemGenerateCoupon}
                >
                  System Generate Code
                </Button>
              </div> */}
              <div className="formBottom">
                <Button
                  className="submitButton"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default EditCoupons;
