import React, { useEffect, useState, useMemo } from "react";
import classes from "../../assets/styles/Pages/CouponManagement/CouponManagement.module.css";
import { useAtom } from "jotai";
import { loggedInUser, teamIDCheck } from "../../utils/store";
import Loader from "../../components/Loader";
import "react-datepicker/dist/react-datepicker.css";
import { get, post } from "../../server";
import { LEADS, EXPORT_LEAD, IMPORT_LEAD } from "../../utils/constants/apiUrl";
import showToast from "../../helpers/showToast";
import { Link } from "react-router-dom";
import DataTable, { createTheme } from "react-data-table-component";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useParams } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
const Leads = () => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [userTeamId, setUserTeamId] = useAtom(teamIDCheck);
  const [loading, setLoading] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [errors, setErrors] = useState({});
  const [leads, setLeads] = useState([]);
  const [exportLeads, setExportLeads] = useState();
  const [activeTab, setActiveTab] = useState("all");
  let { id } = useParams();
  const handleFilter = (e) => {
    setFilterText(e.target.value);
  };

  const exportAllLeads = async (id) => {
    setLoading(true);
    const queryString = `?id=${id}`;
    let { status, message, data } = await get(
      id ? EXPORT_LEAD + queryString : EXPORT_LEAD,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      const filePath = data.path;
      saveAs(filePath, "leads.xlsx");
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  const importLead = async (rowId) => {
    setLoading(true);
    const payload = {
      id: rowId,
    };
    let { status, message, data } = await post(
      IMPORT_LEAD,
      payload,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      await loadAllLeads();
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };
  const loadAllLeads = async (lead_type) => {
    setLoading(true);
    // const queryString = `?team_id=${userTeamId}&lead_type=${lead_type}`;
    // const leadQueryString = `?lead_type=${lead_type}`;
    const queryString = userTeamId
      ? `?team_id=${userTeamId}&lead_type=${lead_type}`
      : `?lead_type=${lead_type}`;
    const url = LEADS + queryString;
    let { status, message, data } = await get(
      url,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      setLeads(data);
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  const handleTabChange = (selectedTab) => {
    setActiveTab(selectedTab);
    loadAllLeads(selectedTab);
  };

  const filteredItems = useMemo(() => {
    const filtered = leads.filter(
      (item) =>
        `${item.name || ""}`.toLowerCase().includes(filterText.toLowerCase()) ||
        `${item.email || ""}`.includes(filterText)
    );

    return filtered;
  }, [filterText, leads]);

  const formatText = (text) => {
    const words = text.split("_");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );

    return capitalizedWords.join(" ");
  };

  createTheme(
    "solarized",
    {
      background: {
        default: "background: var(--secondary_color)",
      },
    },
    "dark"
  );
  const getFormData = (row, field_type) => {
    const userLeadData = row.userLeadData || [];
    const formData = userLeadData.find(
      (item) => item.field_type === field_type
    );
    return formData ? formData.data : "-";
  };

  const columns = [
    {
      name: "Full Name",
      cell: (row) => (
        <p className="globalDetail">
          {getFormData(row, "first_name")} {getFormData(row, "last_name")}
        </p>
      ),
    },
    {
      name: "Email",
      cell: (row) => (
        <a
          href={`mailto:${getFormData(row, "email")}`}
          className="globalDetail"
        >
          {getFormData(row, "email")}
        </a>
      ),
    },
    // {
    //   name: "Phone no",
    //   cell: (row) => (
    //     <a
    //       href={`tel:${getFormData(row, "Contact Number")}`}
    //       className="globalDetail"
    //       style={{ display: "block" }}
    //     >
    //       {getFormData(row, "Contact Number")}
    //     </a>
    //   ),
    // },
    {
      name: "Company",
      cell: (row) => (
        <p className="globalDetail">{getFormData(row, "company")}</p>
      ),
    },
    // {
    //   name: "Location",
    //   cell: (row) => (
    //     <p className="globalDetail">{getFormData(row, "Location")}</p>
    //   ),
    // },
    {
      name: "",
      width: "170px",
      cell: (row) =>
        row.is_imported === 1 ? (
          <button className="secondary_button" disabled>
            Saved
          </button>
        ) : (
          <button className="primary_button" onClick={() => importLead(row.id)}>
            Import Collection
          </button>
        ),
    },
    {
      name: "Action",
      width: "80px",
      right: "true",
      cell: (row) => (
        <div className={classes.action}>
          <button
            className="btn customActionDropdown dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <BsThreeDotsVertical className={"globalVerticalThreeDots"} />
          </button>
          <ul className="customActionDropdownMenu dropdown-menu dropdown-menu-end ">
            <li>
              <Link className="dropdown-item" to={`viewLead/${row.id}`}>
                View
              </Link>
            </li>
            <li>
              <button
                className="dropdown-item"
                onClick={() => exportAllLeads(row.id)}
              >
                Export Collection
              </button>
            </li>
            {/* <li>
              <Link
                className="dropdown-item"
                to="#"
                style={{ color: "#E35252" }}
                onClick={() => handleDelete(row.id)}
              >
                Delete
              </Link>
            </li> */}
          </ul>
        </div>
      ),
    },
  ];

  useEffect(() => {
    loadAllLeads("all");
  }, []);

  return (
    <>
      {!loading ? (
        <div>
          <div className="page_Header">
            <div className="d-flex justify-content-between align-items-md-center align-items-initial flex-md-row flex-column gap-3">
              <p className="pageTitle">
                Leads <span>({leads.length})</span>
              </p>
              <div className="d-flex align-items-center justify-content-between gap-2">
                {/* <button className="primary_button" onClick={exportAllLeads}>
                  Export Collection
                </button> */}
                {!loading && leads.length > 0 && (
                  <button
                    className="primary_button"
                    onClick={() => exportAllLeads()}
                  >
                    Export Collection
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className={`listingWrapper mainWrapper`}>
            <div className={classes.tabsWrapper}>
              <Tabs
                defaultActiveKey="all"
                activeKey={activeTab}
                className="customSubscriptionTabs"
                transition={false}
                onSelect={handleTabChange}
                justify
              >
                <Tab eventKey="all" title="All Leads">
                  <DataTable
                    columns={columns}
                    data={filteredItems}
                    pagination
                    theme="solarized"
                  />
                </Tab>
                <Tab eventKey="new" title="New">
                  <DataTable
                    columns={columns}
                    data={filteredItems}
                    pagination
                    theme="solarized"
                  />
                </Tab>
                <Tab eventKey="imported" title="Archive">
                  <DataTable
                    columns={columns}
                    data={filteredItems}
                    pagination
                    theme="solarized"
                  />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
      <style type="text/css">
        {`
          .customSubscriptionTabs{
            padding:20px;
          }
          .customSubscriptionTabs button{
            background: var(--accent_color) !important;
          }
          .customSubscriptionTabs button.active{
            background: var(--primary_button_color) !important;
          }
          .secondary_button {
            padding: 6px 15px;
            background: var(--primary_color);
            border-radius: 7px;
            color: var(--primary_text_color) !important;
            transition: all 0.3s ease;
            font-weight: 500;
            font-size: 12px;
            border: 0;
            white-space: nowrap;
            width:100%;
          }
          .mainWrapper .primary_button {
            padding: 6px 15px;
            border-radius: 7px;
            font-size: 12px;
            width:100%;
          }
        `}
      </style>
    </>
  );
};

export default Leads;
