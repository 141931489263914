import { Link } from "react-router-dom";
const PaymentNotPaid = ({ message }) => {
  return (
    <div className="notfoundMainWrapper">
      <div className="notfound">
        <div className="notfoundBg">
          <div></div>
          <div></div>
          <div></div>
        </div>
        <h1>oops!</h1>
        <h2>
          You have not subscribed any plan or subscription is expired. Kindly
          purchase your subscription
        </h2>

        <Link to="/subscription">Go Back Subscription</Link>
      </div>
    </div>
  );
};

export default PaymentNotPaid;
