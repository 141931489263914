import React, { useState, useRef, useEffect } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import classes from "../../assets/styles/Pages/Settings/Settings.module.css";
import Profile from "../../components/PageComponents/Settings/Profile";
import Password from "../../components/PageComponents/Settings/Password";
import Notification from "../../components/PageComponents/Settings/Notification";
import ImageUpload from "../../components/ImageUpload";
import dummyImage from "../../assets/images/global_icons/dummyimage.svg";
import { loggedInUser, subscribedPackage } from "../../utils/store";
import { useAtom } from "jotai";
import NFCs from "../../components/PageComponents/Settings/NFCs";
import TeamDetail from "../../components/PageComponents/Settings/TeamDetail";
import CancelSubscription from "../../components/PageComponents/Settings/CancelSubscription";
import QRCodeModal from "../../components/Modals/QRCodeModal";
import html2canvas from "html2canvas";
import {
  CHECKOUT_USER_STATUS_UPDATE,
  GET_USER,
} from "../../utils/constants/apiUrl";
import { get, post } from "../../server";
import showToast from "../../helpers/showToast";
import Theme from "../../components/PageComponents/Settings/Theme";
import { Modal } from "react-bootstrap";
import { LuAlertTriangle } from "react-icons/lu";
import { useNavigate } from "react-router-dom";
import useFunctionsCount from "../../hooks/useFunctionsCount";

const Settings = () => {
  const [imagePath, setImagePath] = useState("");
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [purchasedPackage, setPurchasedPackage] = useAtom(subscribedPackage);
  const [name, setName] = useState("");
  const [key, setKey] = useState(
    loggedUser?.is_new_user === 1 ? "password" : "profile"
  );
  const navigate = useNavigate();
  const [qrOpen, setQrOpen] = useState(false);
  const target = useRef(null);
  const [data, setData] = useState({});
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const { userActions } = useFunctionsCount();
  const [newLoggedUser, setNewLoggedUser] = useState(loggedUser?.is_new_user);
  const [newUserModal, setNewUserModal] = useState(
    newLoggedUser === 1 ? true : false
  );

  const handleQrOpen = () => {
    setQrOpen(true);
  };

  const handleQRClose = () => {
    setQrOpen(false);
    setShow(false);
  };
  const handleDownload = () => {
    html2canvas(document.querySelector("#qr-code")).then(function (canvas) {
      const link = document.createElement("a");
      link.download = "qrcode.png";
      link.href = canvas.toDataURL();
      link.click();
    });
  };
  const loadProfile = async () => {
    setLoading(true);
    let { status, message, data } = await get(
      GET_USER,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      setData(data);
      userActions(data);
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };
  const handleNewUserStatusChange = async () => {
    const payload = {
      is_new_user: 0,
    };
    let { status, data, message } = await post(
      `${CHECKOUT_USER_STATUS_UPDATE}/${loggedUser.id}`,
      payload,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      setNewLoggedUser(data.is_new_user);
      setLoggedUser((prevUser) => ({
        ...prevUser,
        is_new_user: 0,
      }));
      navigate("/settings");
      setNewUserModal(false);
    } else {
      setNewUserModal(true);
    }
  };
  useEffect(() => {
    loadProfile();
  }, []);
  return (
    <>
      <div className="">
        <h6 className={classes.title}>Settings</h6>
        <div className={classes.tabsWrapper}>
          <div className={classes.imageWrapper}>
            <div className="position-relative">
              <ImageUpload
                imageLink={true}
                imageUrl={imagePath}
                setPath={setImagePath}
                imageType="profile"
                dummyImage={dummyImage}
              />
              {/* <div className={classes.qrCode} onClick={handleQrOpen}>
                <img src={qrcode} alt="" />
              </div> */}
            </div>
            <div>
              <h6>{name}</h6>
            </div>
          </div>
          <Tabs
            defaultActiveKey={
              loggedUser?.is_new_user === 1 ? "password" : "profile"
            }
            id="uncontrolled-tab-example"
            className="mb-3 customTabs"
            transition={false}
            activeKey={key}
            onSelect={(k) => setKey(k)}
          >
            <Tab eventKey="profile" title="Profile">
              <Profile
                imagePath={imagePath}
                setImagePath={setImagePath}
                name={name}
                setName={setName}
              />
            </Tab>
            {!loggedUser?.is_social || loggedUser?.is_new_user === 1 ? (
              <Tab eventKey="password" title="Password">
                <Password />
              </Tab>
            ) : (
              ""
            )}
            {loggedUser?.role?.includes("team_owner") && (
              <Tab eventKey="teamDetail" title="Team Details">
                <TeamDetail />
              </Tab>
            )}
            {loggedUser?.role?.includes("team_owner") && (
              <Tab eventKey="cancelSubscription" title="Cancel Subscription">
                <CancelSubscription />
              </Tab>
            )}

            <Tab eventKey="notification" title="Notification">
              <Notification />
            </Tab>

            {loggedUser?.active_nfc && (
              <Tab eventKey="NFCs" title="Unlink Bracelet">
                <NFCs setKey={setKey} />
              </Tab>
            )}
            <Tab eventKey="theme" title="Theme">
              <Theme />
            </Tab>
          </Tabs>
        </div>
      </div>
      <Modal
        centered
        className={`customModal priorityModal`}
        size="sm"
        show={newUserModal}
      >
        <Modal.Body className={`${classes.alertIcon} p-5 text-center`}>
          <div>
            <LuAlertTriangle />
          </div>
          <p className={classes.modTitle}>Change Your Default Password</p>
          <p>
            For your security, please update your password immediately. Using a
            default password can leave your account vulnerable.
          </p>
          <button class={classes.saveBtn} onClick={handleNewUserStatusChange}>
            OK
          </button>
        </Modal.Body>
      </Modal>
      {qrOpen && (
        <QRCodeModal
          qrOpen={qrOpen}
          target={target}
          functionType="profile"
          functionUuid={data.uuid}
          handleQRClose={handleQRClose}
          setShowCopyPopup={setShow}
          handleDownload={handleDownload}
          id={data.id}
          function_type={data.function_type}
          function_id={data.id}
          qr_bg_color={data.qrCodeCustomize?.qr_bg_color}
          qr_front_color={data.qrCodeCustomize?.qr_front_color}
          qr_image={data.qrCodeCustomize?.qr_image}
        />
      )}
    </>
  );
};

export default Settings;
