import React, { useEffect, useState, useMemo } from "react";
import classes from "../../../assets/styles/Pages/CouponManagement/CouponManagement.module.css";
import { useAtom } from "jotai";
import { loggedInUser } from "../../../utils/store";
import Loader from "../../../components/Loader";
import "react-datepicker/dist/react-datepicker.css";
import { get, post } from "../../../server";
import {
  COUPON,
  COUPON_DELETE,
  EXPORT_COUPON,
} from "../../../utils/constants/apiUrl";
import showToast from "../../../helpers/showToast";
import { Link } from "react-router-dom";
import DataTable, { createTheme } from "react-data-table-component";
import { BsThreeDotsVertical } from "react-icons/bs";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal";
import FormInput from "../../../components/Forms/FormInput";
import searchIcon from "../../../assets/images/global_icons/searchicon.svg";
import { useParams } from "react-router-dom";
import { saveAs } from "file-saver";
const AllCoupons = () => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [loading, setLoading] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [coupons, setCoupons] = useState([]);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const handleFilter = (e) => {
    setFilterText(e.target.value);
  };

  const loadCoupons = async () => {
    setLoading(true);
    let { status, message, data } = await get(
      COUPON,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      if (data.length > 0) {
        setCoupons(data);
      }
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };
  const exportCoupons = async (id) => {
    setLoading(true);
    const queryString = `?coupon_code_id=${id}`;
    let { status, message, data } = await get(
      id ? EXPORT_COUPON + queryString : EXPORT_COUPON,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      const filePath = data.path;
      saveAs(filePath, "coupon.xlsx");
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  const filteredItems = useMemo(() => {
    const filtered = coupons.filter(
      (item) =>
        `${item.name || ""}`.toLowerCase().includes(filterText.toLowerCase()) ||
        `${item.coupon || ""}`
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
        `${item.discount || ""}`.includes(filterText)
    );

    return filtered;
  }, [filterText, coupons]);

  const formatText = (text) => {
    const words = text.split("_");
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );

    return capitalizedWords.join(" ");
  };

  createTheme(
    "solarized",
    {
      background: {
        default: "background: var(--secondary_color)",
      },
    },
    "dark"
  );

  const columns = [
    {
      name: "Coupon Name",
      width: "150px",
      cell: (row) => <p className="globalDetail">{row.name}</p>,
    },
    {
      name: "Quantity",
      width: "150px",
      cell: (row) => <p className="globalDetail">{row.quantity}</p>,
    },
    {
      name: "Discount",
      width: "100px",
      cell: (row) => <p className="globalDetail">{row.discount}</p>,
    },
    {
      name: "Package",
      width: "120px",
      cell: (row) => (
        <p className="globalDetail">
          {row.package_id === 2
            ? "Premium"
            : row.package_id === 3
            ? "Elite"
            : "Team Elite"}
        </p>
      ),
    },
    {
      name: "Duration",
      width: "120px",
      cell: (row) => (
        <p className="globalDetail">
          {row.duration_type
            ? row.duration_type.charAt(0).toUpperCase() +
              row.duration_type.slice(1)
            : "-"}
        </p>
      ),
    },
    {
      name: "Applied Till",
      width: "150px",
      cell: (row) => (
        <p className="globalDetail">
          {row.applied_till ? formatText(row.applied_till) : "-"}
        </p>
      ),
    },
    {
      name: "Start Date",
      width: "200px",
      cell: (row) => <p className="globalDetail">{row.start}</p>,
    },
    {
      name: "End Date",
      width: "200px",
      cell: (row) => <p className="globalDetail">{row.end}</p>,
    },
    {
      name: "Action",
      width: "100px",
      right: "true",
      cell: (row) => (
        <div className={classes.action}>
          <button
            className="btn customActionDropdown dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <BsThreeDotsVertical className={"globalVerticalThreeDots"} />
          </button>
          <ul className="customActionDropdownMenu dropdown-menu dropdown-menu-end ">
            <li>
              <Link className="dropdown-item" to={`viewCoupons/${row.id}`}>
                View Coupon Codes
              </Link>
            </li>
            <li>
              <Link className="dropdown-item" to={`editCoupons/${row.id}`}>
                Edit
              </Link>
            </li>
            <li>
              <button
                className="dropdown-item"
                onClick={() => exportCoupons(row.id)}
              >
                Export Collection
              </button>
            </li>
            <li>
              <Link
                className="dropdown-item"
                to="#"
                style={{ color: "#E35252" }}
                onClick={() => handleDelete(row.id)}
              >
                Delete
              </Link>
            </li>
          </ul>
        </div>
      ),
    },
  ];

  // Handle confirmation modal
  const handleShowConfirmationModal = () => setShowConfirmationModal(true);
  const handleCloseConfirmationModal = () => setShowConfirmationModal(false);

  // handle delete
  const handleDelete = (couponId) => {
    setDeleteId(couponId);
    handleShowConfirmationModal(true);
  };

  const handleConfirmDelete = async () => {
    handleCloseConfirmationModal(true);
    setLoading(true);
    const queryString = `?coupon_id=${deleteId}`;
    // const queryString = `?id=${couponId}`;
    let { status, message } = await post(
      COUPON_DELETE + queryString,
      {},
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      await loadCoupons();
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadCoupons();
  }, []);

  return (
    <>
      {!loading ? (
        <div>
          <div className="page_Header">
            <div className="d-flex justify-content-between align-items-md-center align-items-initial flex-md-row flex-column gap-3">
              <p className="pageTitle">
                Coupons <span>({coupons.length})</span>
              </p>
              <div>
                <div className="d-flex align-items-center justify-content-end gap-2 mb-2">
                  <Link to="/coupons/couponQuantity">
                    <button className="primary_button">Add</button>
                  </Link>
                  {coupons.length > 0 ? (
                    <button
                      className="primary_button"
                      onClick={() => exportCoupons()}
                    >
                      Export Coupons
                    </button>
                  ) : (
                    ""
                  )}
                </div>
                <FormInput
                  icon={searchIcon}
                  placeholder="Search..."
                  value={filterText}
                  handleChange={handleFilter}
                />
              </div>
            </div>
          </div>
          <div className={`listingWrapper mainWrapper`}>
            <DataTable
              columns={columns}
              data={filteredItems}
              pagination
              theme="solarized"
            />
          </div>

          <ConfirmationModal
            show={showConfirmationModal}
            handleCloseModal={handleCloseConfirmationModal}
            handleConfirmDelete={handleConfirmDelete}
            confirmTitle="coupon"
          />
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default AllCoupons;
