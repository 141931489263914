import React, { useState, useEffect } from "react";
import classes from "../../../assets/styles/Pages/CouponManagement/CouponManagement.module.css";
import { useAtom } from "jotai";
import { loggedInUser } from "../../../utils/store";
import Loader from "../../../components/Loader";
import SettingsInput from "../../../components/Forms/SettingsInput";
import Button from "react-bootstrap/Button";
import "react-datepicker/dist/react-datepicker.css";
import { post, get } from "../../../server";
import _ from "lodash";
import { GENERATE_QRCODE } from "../../../utils/constants/apiUrl";
import showToast from "../../../helpers/showToast";
import { useParams, useNavigate } from "react-router-dom";
import { Form } from "react-bootstrap";
const AddEditQRCode = () => {
  let { id } = useParams();
  //   let emptyObj = {
  //     name: "",
  //     quantity: "",
  //     is_logo: false,
  //   };
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [showDurationType, setShowDurationType] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [qrCodes, setQRCodes] = useState({
    name: "",
    quantity: "",
    is_logo: false,
  });
  const [isLogo, setIsLogo] = useState(false);
  const navigate = useNavigate();
  // const handleTypeChange = (e) => {
  //   const name = e.target.name;
  //   const value = e.target.value;
  //   if (value === "custom_qr_code") {
  //     setShowDurationType(true);
  //     setQRCodes({ ...qrCodes, [name]: value });
  //   } else {
  //     setShowDurationType(false);
  //     setQRCodes({
  //       ...qrCodes,
  //       qr_code_content: "",
  //     });
  //   }
  // };
  const handleTypeChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    // Update the state based on the selected type
    if (value === "custom_qr_code") {
      setShowDurationType(true);
    } else {
      setShowDurationType(false);
      // Clear the qr_code_content field when switching back to profile_qr_code
      setQRCodes((prev) => ({
        ...prev,
        qr_code_content: "",
      }));
    }

    // Update the type in the state (very important for switching between types)
    setQRCodes({ ...qrCodes, [name]: value });
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setQRCodes({ ...qrCodes, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setIsLogo(isChecked);
    setQRCodes({ ...qrCodes, is_logo: isChecked });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let payload = _.cloneDeep(qrCodes); // clone the qrCodes state

    // If the type is profile_qr_code, remove qr_code_content from the payload
    if (payload.type === "profile_qr_code") {
      delete payload.qr_code_content;
    }
    let { status, message, data } = await post(
      // id ? COUPON_UPDATE : COUPON_CREATE,
      GENERATE_QRCODE,
      payload,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      setQRCodes(data);
      navigate("/generateQRCode");
      showToast(true, message);
    } else {
      showToast(status, message);
    }

    setLoading(false);
  };

  return (
    <>
      {!loading ? (
        <div>
          <div className="page_Header">
            <div className="d-flex justify-content-between align-items-md-center align-items-initial flex-md-row flex-column gap-3">
              <p className="pageTitle"> {id ? "Edit" : "Generate"} QR Code</p>
            </div>
          </div>
          <div className={classes.content}>
            <div className={`mainWrapper ${classes.outerWrapper}`}>
              <div className="row gx-5 gy-3">
                <div className="col-md-4 col-12">
                  <SettingsInput
                    label="Title"
                    type="text"
                    placeholder="Enter QR Code Batch Title"
                    name="name"
                    value={qrCodes.name}
                    handleChange={handleChange}
                    error={errors.name}
                  />
                </div>
                <div className="col-md-4 col-12">
                  <div className={classes.selectWrapper}>
                    <label
                      htmlFor=""
                      style={{
                        fontSize: "12px",
                        marginBottom: "10px",
                        lineHeight: "18px",
                      }}
                    >
                      Type
                    </label>
                    <div className="input-group globalInputWithIcon">
                      <select
                        className="form-select customSelect"
                        name="type"
                        value={qrCodes.type}
                        onChange={handleTypeChange}
                      >
                        <option value="">Select type</option>
                        <option value="profile_qr_code">Profile QR Code</option>
                        <option value="custom_qr_code">Custom QR Code</option>
                      </select>
                    </div>
                  </div>
                </div>
                {showDurationType == true ? (
                  <div className="col-md-4 col-12">
                    <SettingsInput
                      label="QR Code Content"
                      type="text"
                      placeholder="Enter QR Code Content"
                      name="qr_code_content"
                      value={qrCodes.qr_code_content}
                      handleChange={handleChange}
                      error={errors.qr_code_content}
                    />
                  </div>
                ) : (
                  ""
                )}
                <div className="col-md-4 col-12">
                  <SettingsInput
                    label="Quantity"
                    type="text"
                    placeholder="Enter QR Code Quantity"
                    name="quantity"
                    value={qrCodes.quantity}
                    handleChange={handleChange}
                    error={errors.quantity}
                  />
                </div>
                <div className="col-md-4 col-12">
                  <Form.Check
                    type="checkbox"
                    label={"QR Code Logo"}
                    checked={isLogo}
                    onChange={handleCheckboxChange}
                  />
                </div>
              </div>
              <div className="formBottom">
                <Button
                  className="submitButton"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Generate
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
      <style type="text/css">
        {`
            .form-check-label{
                font-size: 12px;
                line-height: 18px;
                color: var(--heading_text_color);
            }
        `}
      </style>
    </>
  );
};

export default AddEditQRCode;
