import React, { useEffect, useState, useMemo } from "react";
import classes from "../../../assets/styles/Pages/Subscriptions/ManageSubscriptions.module.css";
import { useAtom } from "jotai";
import { loggedInUser } from "../../../utils/store";
import Loader from "../../../components/Loader";
import FormInput from "../../../components/Forms/FormInput";
import searchIcon from "../../../assets/images/global_icons/searchicon.svg";
import {
  ACTIVE_SUBSCRIPTIONS_PACKAGES,
  CANCEL_SUBSCRIPTION,
} from "../../../utils/constants/apiUrl";
import { Link } from "react-router-dom";
import DataTable, { createTheme } from "react-data-table-component";
import { BsThreeDotsVertical } from "react-icons/bs";
import { get, post } from "../../../server";
import showToast from "../../../helpers/showToast";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import FilterIcon from "../../../assets/images/contact_page_icons/filtericon.svg";
const ActiveSubscriptions = () => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [loading, setLoading] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [packages, setPackages] = useState([]);
  const [statusId, setStatusId] = useState(null);
  const [activeTab, setActiveTab] = useState("");
  const [activePkgName, setActivePkgName] = useState("");
  const [activePkgType, setActivePkgType] = useState("");

  const handleFilter = (e) => {
    setFilterText(e.target.value);
  };
  const handlePkgNameFilterClick = (pkg_name) => {
    setActivePkgName(pkg_name);
    loadPackages(activeTab, pkg_name, activePkgType);
  };
  const handlePkgTypeFilterClick = (pkg_type) => {
    setActivePkgType(pkg_type);
    loadPackages(activeTab, activePkgName, pkg_type);
  };
  const loadPackages = async (
    device_type = "",
    pkg_name = "",
    pkg_type = ""
  ) => {
    setLoading(true);
    let queryParams = [];

    if (device_type) {
      queryParams.push(device_type);
    }
    if (pkg_name) {
      queryParams.push(pkg_name);
    }
    if (pkg_type) {
      queryParams.push(pkg_type);
    }

    let queryString = queryParams.length
      ? `?search=${queryParams.join("+")}`
      : "";

    let { status, message, data } = await get(
      ACTIVE_SUBSCRIPTIONS_PACKAGES + queryString,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      if (data.length > 0 || data.length !== 0) {
        setPackages(data);
        const status = data.map((statusValue) => statusValue.status);
        setStatusId(status);
      } else {
        setPackages([]);
      }
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  const handleTabChange = (selectedTab) => {
    setActiveTab(selectedTab);
    loadPackages(selectedTab);
  };

  const filteredItems = useMemo(() => {
    const filtered = packages.filter(
      (item) =>
        `${item.first_name || ""}`
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
        `${item.last_name || ""}`
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
        `${item.package_name || ""}`
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
        `${item.package_name || ""}`.includes(filterText)
    );

    return filtered;
  }, [filterText, packages]);

  createTheme(
    "solarized",
    {
      background: {
        default: "background: var(--secondary_color)",
      },
    },
    "dark"
  );

  const columns = [
    {
      name: "Name",
      width: "200px",
      cell: (row) => (
        <p className="globalDetail">
          {row.first_name} {row.last_name}
        </p>
      ),
    },
    {
      name: "Email",
      width: "200px",
      // cell: (row) => <p className="globalDetail">{row.email}</p>,
      cell: (row) => (
        <a
          href={`mailto:${row.email}`}
          className="globalDetail"
          style={{ display: "block" }}
        >
          {row.email}
        </a>
      ),
    },
    {
      name: "Package Name",
      width: "150px",
      cell: (row) => <p className="globalDetail">{row.package_name}</p>,
    },
    {
      name: "Package Type",
      width: "150px",
      cell: (row) => <p className="globalDetail">{row.package_type}</p>,
    },
    // {
    //   name: "Device",
    //   width: "150px",
    //   cell: (row) => (
    //     <p className="globalDetail">
    //       {row.device_type === "web"
    //         ? "Web"
    //         : row.device_type === "android"
    //         ? "Android"
    //         : row.device_type === "ios"
    //         ? "ios"
    //         : ""}
    //     </p>
    //   ),
    // },
    {
      name: "Creation Date",
      width: "200px",
      //cell: (row) => <p className="globalDetail">{row.creation_date}</p>,
      cell: (row) => {
        const creationDate = new Date(row.start_date);
        const formattedDate = creationDate.toLocaleString();
        return <p className="globalDetail">{formattedDate}</p>;
      },
    },
    {
      name: "End Date",
      width: "200px",
      cell: (row) => {
        const creationDate = new Date(row.end_date);
        const formattedDate = creationDate.toLocaleString();
        return <p className="globalDetail">{formattedDate}</p>;
      },
    },

    {
      name: "Action",
      width: "100px",
      right: "true",
      cell: (row, index) => (
        <div className={classes.action}>
          <button
            className="btn customActionDropdown dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <BsThreeDotsVertical className={"globalVerticalThreeDots"} />
          </button>
          <ul className="customActionDropdownMenu dropdown-menu dropdown-menu-end ">
            <li>
              <Link
                className="dropdown-item"
                to="#"
                style={{ color: "#E35252" }}
                onClick={() => cancelSubscription(row.user_id, row.team_id)}
              >
                Cancel Subscription
              </Link>
            </li>
          </ul>
        </div>
      ),
    },
  ];

  const cancelSubscription = async (userId, teamId) => {
    setLoading(true);
    const payload = {
      user_id: userId,
      team_id: teamId,
      device_type: "web",
    };
    let { status, message } = await post(
      CANCEL_SUBSCRIPTION,
      payload,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      await loadPackages();
      showToast(true, message);
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadPackages();
  }, []);

  return (
    <>
      {!loading ? (
        <div>
          <div className="page_Header">
            <div className="d-flex justify-content-between align-items-md-center align-items-initial flex-md-row flex-column gap-3">
              <p className="pageTitle">Active Subscriptions</p>
              <div className="row align-items-center justify-content-end">
                <div className="col-6">
                  <FormInput
                    icon={searchIcon}
                    placeholder="Search..."
                    value={filterText}
                    handleChange={handleFilter}
                  />
                </div>
                <div className="col-12 mt-2">
                  <div className="d-flex justify-content-end align-items-center">
                    <div className="dropdown me-2">
                      <button
                        className="btn filterDropdown dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img
                          src={FilterIcon}
                          alt=""
                          width="16px"
                          height="16px"
                        />
                        <span> Filter By Package Name</span>
                      </button>
                      <ul className="customActionDropdownMenu dropdown-menu dropdown-menu-start">
                        <li className="dropdown-item">
                          <span
                            onClick={() => handlePkgNameFilterClick("starter")}
                            style={{
                              color:
                                activePkgName === "starter"
                                  ? "var(--primary_button_color)"
                                  : "var(--primary_text_color)",
                            }}
                          >
                            Starter
                          </span>
                        </li>
                        <li className="dropdown-item">
                          <span
                            onClick={() => handlePkgNameFilterClick("premium")}
                            style={{
                              color:
                                activePkgName === "premium"
                                  ? "var(--primary_button_color)"
                                  : "var(--primary_text_color)",
                            }}
                          >
                            Premium
                          </span>
                        </li>
                        <li className="dropdown-item">
                          <span
                            onClick={() => handlePkgNameFilterClick("elite")}
                            style={{
                              color:
                                activePkgName === "elite"
                                  ? "var(--primary_button_color)"
                                  : "var(--primary_text_color)",
                            }}
                          >
                            Elite
                          </span>
                        </li>
                        <li className="dropdown-item">
                          <span
                            onClick={() => handlePkgNameFilterClick("")}
                            style={{
                              color:
                                activePkgName === ""
                                  ? "var(--primary_button_color)"
                                  : "var(--primary_text_color)",
                            }}
                          >
                            Clear
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="dropdown">
                      <button
                        className="btn filterDropdown dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img
                          src={FilterIcon}
                          alt=""
                          width="16px"
                          height="16px"
                        />
                        <span> Filter By Package Type</span>
                      </button>
                      <ul className="customActionDropdownMenu dropdown-menu dropdown-menu-start">
                        <li className="dropdown-item">
                          <span
                            onClick={() => handlePkgTypeFilterClick("personal")}
                            style={{
                              color:
                                activePkgType === "personal"
                                  ? "var(--primary_button_color)"
                                  : "var(--primary_text_color)",
                            }}
                          >
                            personal
                          </span>
                        </li>
                        <li className="dropdown-item">
                          <span
                            onClick={() => handlePkgTypeFilterClick("owner")}
                            style={{
                              color:
                                activePkgType === "owner"
                                  ? "var(--primary_button_color)"
                                  : "var(--primary_text_color)",
                            }}
                          >
                            Owner
                          </span>
                        </li>
                        <li className="dropdown-item">
                          <span
                            onClick={() => handlePkgTypeFilterClick("")}
                            style={{
                              color:
                                activePkgType === ""
                                  ? "var(--primary_button_color)"
                                  : "var(--primary_text_color)",
                            }}
                          >
                            Clear
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`listingWrapper mainWrapper`}>
            <div className={classes.tabsWrapper}>
              <Tabs
                defaultActiveKey=""
                activeKey={activeTab}
                className="customSubscriptionTabs"
                transition={false}
                onSelect={handleTabChange}
                justify
              >
                <Tab eventKey="" title="All Subscription">
                  <DataTable
                    columns={columns}
                    data={filteredItems}
                    pagination
                    theme="solarized"
                  />
                </Tab>
                <Tab eventKey="ios" title="ios">
                  <DataTable
                    columns={columns}
                    data={filteredItems}
                    pagination
                    theme="solarized"
                  />
                </Tab>
                <Tab eventKey="android" title="Android">
                  <DataTable
                    columns={columns}
                    data={filteredItems}
                    pagination
                    theme="solarized"
                  />
                </Tab>
                <Tab eventKey="web" title="Web App">
                  <DataTable
                    columns={columns}
                    data={filteredItems}
                    pagination
                    theme="solarized"
                  />
                </Tab>
                <Tab eventKey="shopify" title="Shopify">
                  <DataTable
                    columns={columns}
                    data={filteredItems}
                    pagination
                    theme="solarized"
                  />
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
      <style type="text/css">
        {`
          .customSubscriptionTabs {
            padding: 20px;
          }
          .customSubscriptionTabs button {
            background: var(--accent_color) !important;
          }
          .filterDropdown {
            border-radius: 25px;
            padding: 5px 14px;
          }
          .filterDropdown span {
            font-size: 14px;
            line-height: 21px;
            color: var(--heading_text_color);
            padding-left: 10px;
          }
        `}
      </style>
    </>
  );
};

export default ActiveSubscriptions;
