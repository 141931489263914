import React from "react";
import classes from "../../../assets/styles/PageComponentsStyles/Function/FunctionCard.module.css";
import { Link } from "react-router-dom";
import { useAtom } from "jotai";
import { loggedInUser, accountType } from "../../../utils/store";
import MedicalIDLogo from "../../../pages/Function/FunctionLogo/MedicalIDLogo";
import MyMemoryLogo from "../../../pages/Function/FunctionLogo/MyMemoryLogo";
import FormBuilderLogo from "../../../pages/Function/FunctionLogo/FormBuilderLogo";
import UploadFilesIcon from "../../../pages/Function/FunctionLogo/UploadFilesIcon";
import CustomURLsLogo from "../../../pages/Function/FunctionLogo/CustomURLsLogo";
import Emergencycontactlogo from "../../../pages/Function/FunctionLogo/Emergencycontactlogo";
import Paymenticon from "../../../pages/Function/FunctionLogo/Paymenticon";
import CardFunctionLogo from "../../../pages/Function/FunctionLogo/CardFunctionLogo";
const FunctionCard = ({
  bgColor,
  image,
  FunctionCreateCardsObj,
  title,
  function_type,
  description,
  url,
  teamId,
  userId,
  personalCardsCount,
  businessCardsCount,
  formBuilderCount,
  memoryCount,
  payment,
  emergencyContacts,
  customUrls,
  medicalCount,
  files,
  isVisible,
}) => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [personalAccount, setPersonalAccount] = useAtom(accountType);

  return (
    <>
      <div className={`${classes.mainWrapper} ${isVisible}`}>
        <div className={classes.cardWrapper}>
          <div className={classes.imgWrapper}>
            <div className={classes.coverImg}>
              <div
                style={{ background: bgColor }}
                className={classes.circle}
              ></div>
            </div>
            <div className={classes.profileImg}>
              {/* <Image
                src={image}
                alt="profile-img"
                loading="lazy"
                decoding="async"
              /> */}
              {/* {image} */}
              {function_type === "card" ? (
                <CardFunctionLogo
                  primaryColor="var(--button_text_color)"
                  secondaryColor="var(--primary_button_color)"
                />
              ) : function_type === "payment" ? (
                <Paymenticon
                  primaryColor="var(--button_text_color)"
                  secondaryColor="var(--primary_button_color)"
                />
              ) : function_type === "emergency_contact" ? (
                <Emergencycontactlogo
                  primaryColor="var(--button_text_color)"
                  secondaryColor="var(--border_color)"
                />
              ) : function_type === "custom_url" ? (
                <CustomURLsLogo
                  primaryColor="var(--heading_text_color)"
                  secondaryColor="var(--primary_button_color)"
                  accentColor="var(--border_color)"
                />
              ) : function_type === "file_upload" ? (
                <UploadFilesIcon
                  primaryColor="var(--heading_text_color)"
                  secondaryColor="var(--primary_button_color)"
                />
              ) : function_type === "form_builder" ? (
                <FormBuilderLogo
                  primaryColor="var(--heading_text_color)"
                  secondaryColor="var(--primary_button_color)"
                />
              ) : function_type === "memory" ? (
                <MyMemoryLogo
                  primaryColor="var(--button_text_color)"
                  secondaryColor="var(--primary_button_color)"
                />
              ) : function_type === "medical_id" ? (
                <MedicalIDLogo
                  primaryColor="var(--button_text_color)"
                  secondaryColor="var(--border_color)"
                />
              ) : (
                ""
              )}
            </div>
          </div>
          <div className={classes.infoWrapper}>
            <div className={classes.name}>{title}</div>
            <div className={classes.profession}>{description}</div>
          </div>

          <div className={classes.btnWrapper}>
            <Link
              to={
                teamId && userId
                  ? `/team/${teamId}/teamMembers/member/${userId}${url}`
                  : `${url}${
                      loggedUser?.role?.includes("team_owner") &&
                      !personalAccount
                        ? `?team_id=${
                            Object.keys(loggedUser).length !== 0
                              ? loggedUser.teams.id
                              : null
                          }&user_id=${
                            Object.keys(loggedUser).length !== 0
                              ? loggedUser.teams.user_id
                              : null
                          }`
                        : ""
                    }`
              }
            >
              {(function_type === "card" &&
                (personalCardsCount > 0 || businessCardsCount > 0)) ||
              (function_type === "payment" && payment.length > 0) ||
              (function_type === "emergency_contact" &&
                emergencyContacts.length > 0) ||
              (function_type === "custom_url" && customUrls.length > 0) ||
              (function_type === "file_upload" && files.length > 0) ||
              (function_type === "form_builder" && formBuilderCount > 0) ||
              (function_type === "memory" && memoryCount > 0) ||
              (function_type === "medical_id" && medicalCount > 0) ? (
                <button className={classes.createBtn}>Manage Function</button>
              ) : (
                <button className={classes.createBtn}>Create</button>
              )}
            </Link>
          </div>
        </div>
      </div>
      <style type="text/css">
        {`
          .cardHide {
            display: none;
          }
        `}
      </style>
    </>
  );
};
export default FunctionCard;
