import React from "react";
import DataTable, { createTheme } from "react-data-table-component";
import classes from "../../assets/styles/PageComponentsStyles/Contact/ListView.module.css";
import dummyImage from "../../assets/images/global_icons/dummyimage.svg";
import { Image } from "react-bootstrap";
import baseURL from "../../utils/constants/baseUrl";

const StatsDetail = ({ isNFC, isQRCode, data }) => {
  createTheme(
    "solarized",
    {
      background: {
        default: "background: var(--secondary_color)",
      },
    },
    "dark"
  );

  const nfcColumns = [
    {
      name: "Name",
      //width: "40%",
      cell: (row) => (
        <div className={classes.profileWrapper}>
          <Image
            src={
              `${baseURL.PUBLIC_URL}${row.user_data.profile_image}` ||
              dummyImage
            }
            roundedCircle={true}
            width="50px"
            height="50px"
            onError={(e) => {
              e.currentTarget.src = dummyImage;
            }}
          />
          <div>
            <p className="globalName">
              {row.user_data.first_name} {row.user_data.last_name}
            </p>
          </div>
        </div>
      ),
    },
    {
      name: "Email",
      width: "40%",
      cell: (row) => (
        <a
          href={`mailto:${row.user_data.email}`}
          className="globalDetail"
          style={{ display: "block" }}
        >
          {row.user_data.email}
        </a>
      ),
    },
    {
      name: "Total Taps",
      width: "20%",
      cell: (row) => (
        <p
          className="globalDetail"
          style={{ fontWeight: "bolder", fontSize: "16px" }}
        >
          {row.total_stats}
        </p>
      ),
    },
  ];

  const qrCodeColumns = [
    {
      name: "Node Name",
      width: "50%",
      cell: (row) => (
        <div className={classes.profileWrapper}>
          <div className={classes.nodeImage}>
            {row.hierarchy_tree.name.charAt(0)}
          </div>
          <p className="globalName">{row.hierarchy_tree.name} </p>
        </div>
      ),
    },
    {
      name: "Total Stats",
      width: "50%",
      cell: (row) => (
        <p
          className="globalDetail"
          style={{ fontWeight: "bolder", fontSize: "16px" }}
        >
          {row.total_stats}
        </p>
      ),
    },
  ];

  const functionsColumns = [
    {
      name: "Name",
      //width: "40%",
      cell: (row) => (
        <div className={classes.profileWrapper}>
          <Image
            src={
              `${baseURL.PUBLIC_URL}${row.user_data.profile_image}` ||
              dummyImage
            }
            roundedCircle={true}
            width="50px"
            height="50px"
            onError={(e) => {
              e.currentTarget.src = dummyImage;
            }}
          />
          <div>
            <p className="globalName">
              {row.user_data.first_name} {row.user_data.last_name}
            </p>
          </div>
        </div>
      ),
    },
    {
      name: "Email",
      width: "40%",
      cell: (row) => (
        <a
          href={`mailto:${row.user_data.email}`}
          className="globalDetail"
          style={{ display: "block" }}
        >
          {row.user_data.email}
        </a>
      ),
    },
    {
      name: "Total Scans",
      width: "20%",
      cell: (row) => (
        <p
          className="globalDetail"
          style={{ fontWeight: "bolder", fontSize: "16px" }}
        >
          {row.total_stats}
        </p>
      ),
    },
  ];

  return (
    <>
      <div className={`listingWrapper mainWrapper`}>
        <DataTable
          columns={
            isNFC ? nfcColumns : isQRCode ? qrCodeColumns : functionsColumns
          }
          data={data}
          pagination
          theme="solarized"
          noDataComponent=" " //or your component
        />
      </div>
    </>
  );
};

export default StatsDetail;
