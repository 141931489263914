import React, { useState, useEffect } from "react";
import classes from "../../assets/styles/Pages/Help/TermsAndCondition.module.css";
import privacy_image from "../../assets/images/help/privacy.svg";
import { Link } from "react-router-dom";
import { SETTING } from "../../utils/constants/apiUrl";
import { get } from "../../server";
import showToast from "../../helpers/showToast";
import Loader from "../../components/Loader";
import { loggedInUser } from "../../utils/store";
import { useAtom } from "jotai";
const TermsAndCondition = () => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [loading, setLoading] = useState(false);
  const [term, setterm] = useState({});

  const isLoggedIn = Object.keys(loggedUser).length > 0;

  const loadTermCondition = async () => {
    setLoading(true);
    let { status, message, data } = await get(SETTING + "/page");
    if (status) {
      if (data.length > 0) {
        const termsCondition = data.filter(
          (page) => page.page_type === "term_condition"
        );
        setterm(termsCondition["0"]);
      }
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadTermCondition();
  }, []);

  return (
    <>
      {!isLoggedIn ? (
        <div className={classes.helpHeader}>
          <div className="container">
            <div className="row flex-md-row flex-column-reverse gy-4 mt-4">
              {/* TermsAndCondition top left section */}
              <div className="col-sm-6">
                <h3 className="helpHeading">Terms & Condition</h3>
                <p className="helpSubHeading">
                  Terms of Service for Contact Co
                </p>
                <p className="helpDetail"></p>
              </div>

              {/*Right Section Image */}
              <div className="col-sm-6">
                <img src={privacy_image} alt="" className="helpImage" />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="helpBackLinkWrapper">
            <Link to="/help">Back</Link>
          </div>
          <div className={classes.helpHeader}>
            <div className="row flex-md-row flex-column-reverse gy-4">
              {/* TermsAndCondition top left section */}
              <div className="col-sm-6">
                <h3 className="helpHeading">Terms & Condition</h3>
                <p className="helpSubHeading">
                  Terms of Service for Contact Co
                </p>
                <p className="helpDetail"></p>
              </div>

              {/*Right Section Image */}
              <div className="col-sm-6">
                <img src={privacy_image} alt="" className="helpImage" />
              </div>
            </div>
          </div>
        </>
      )}

      {!loading ? (
        <>
          <div className={`${classes.contentWrapper}`}>
            <p className="helpSubHeading">{term?.title}</p>
            <p
              className={classes.detail}
              dangerouslySetInnerHTML={{ __html: term?.description }}
            />
          </div>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default TermsAndCondition;
