import React, { useState } from "react";
import "react-phone-input-2/lib/material.css";
import _ from "lodash";
import Card from "../components/PageComponents/Function/Card";
import { loggedInUser } from "../utils/store";
import { useAtom } from "jotai";
import { useParams, useNavigate } from "react-router-dom";

const PublicCard = ({ cardFunction }) => {
  // const [cardFunction, setCardFunction] = useState({
  //   card_type: "",
  //   first_name: "",
  //   last_name: "",
  //   email: "",
  //   company: "",
  //   company_position: "",
  //   logo: "",
  //   background_image: "",
  //   profile_image: "",
  //   color: "",
  //   country_phone: "",
  //   phone_number: "",
  //   description: "",
  //   address: "",
  //   country: "",
  //   city: "",
  //   state: "",
  //   website_url: "",
  //   card_social_media: [],
  //   pronouns: "",
  //   card_phone: [{ phone_type: "", country_phone: "", phone_number: "" }],
  // });

  const [loading, setLoading] = useState(false);

  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);

  const { uuid } = useParams();

  const navigate = useNavigate();

  // useEffect(() => {
  //   fetchSingleCard();
  // }, []);

  // const fetchSingleCard = async () => {
  //   setLoading(true);

  //   let { status, message, data } = await get(
  //     `${SHARE_CARD}/${uuid}`,
  //     Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
  //   );

  //   if (status) {
  //     let card_social_media = [];

  //     data.cardSocialMedia.forEach((item) => {
  //       let foundIcon = AddMoreCustomUrls.find(
  //         (single) => single.name === item.media_type
  //       );

  //       card_social_media.push({
  //         media_type: item.media_type,
  //         url: item.url,
  //         icon: item.media_type === "custom" ? "custom" : foundIcon?.icon,
  //         custom_label: item.custom_label,
  //       });
  //     });

  //     setCardFunction({
  //       card_type: data.card_type,
  //       first_name: data.first_name ? data.first_name : "",
  //       last_name: data.last_name ? data.last_name : "",
  //       email: data.email ? data.email : "",
  //       company: data.company ? data.company : "",
  //       company_position: data.company_position ? data.company_position : "",
  //       logo: data.logo ? data.logo : "",
  //       background_image: data.background_image ? data.background_image : "",
  //       profile_image: data.profile_image ? data.profile_image : "",
  //       color: data.color ? data.color : "",
  //       country_phone: data.country_phone ? `+${data.country_phone}` : "",
  //       phone_number: data.phone_number
  //         ? `${data.country_phone ? data.country_phone : ""}${
  //             data.phone_number
  //           }`
  //         : "",
  //       description: data.description ? data.description : "",
  //       address: data.address ? data.address : "",
  //       country: data.country ? data.country : "",
  //       city: data.city ? data.city : "",
  //       state: data.state ? data.state : "",
  //       website_url: data.website_url ? data.website_url : "",
  //       card_social_media: card_social_media,
  //       pronouns: data.pronouns ? data.pronouns : "",
  //       card_phone: data.cardPhone,
  //     });
  //   } else {
  //     showToast(status, message);
  //   }

  //   setLoading(false);
  // };

  return (
    <div className="col-lg-4 col-md-5 col-12">
      <Card data={cardFunction} inViewMode={true} />
    </div>
  );
};

export default PublicCard;
