import React, { useState, useEffect, useRef } from "react";
import classes from "../../assets/styles/Pages/QRCode/QRCode.module.css";
import { QRCode } from "react-qrcode-logo";
import qrCodeBorder from "../../assets/images/qr-code-border.svg";
import { SHARE_QR } from "../../utils/constants/common";
import qrCodeLogo from "../../assets/images/sidebar_icons/collapsedLogo.svg";
import { GiShare } from "react-icons/gi";
import baseUrl from "../../utils/constants/baseUrl";
import Loader from "../../components/Loader";
import { useAtom } from "jotai";
import { GET_USER, DYNAMIC_QRCODE } from "../../utils/constants/apiUrl";
import { get, deleteRecord, post } from "../../server";
import showToast from "../../helpers/showToast";
import formatPhoneNumber from "../../helpers/formatPhoneNumber";
import { Link } from "react-router-dom";
import { BsThreeDotsVertical } from "react-icons/bs";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import ShareModal from "../../components/Modals/ShareModal";
import {
  loggedInUser,
  teamIDCheck,
  subscribedPackage,
} from "../../utils/store";
import html2canvas from "html2canvas";
import { toPng } from "html-to-image";
const functionTypeMap = {
  card: "Digital Card",
  payment: "Payment Service",
  emergency_contact: "Emergency Contact",
  custom_url: "Custom URL’s",
  file_upload: "File Upload URL",
};
const TestQRCode = ({
  target,
  url,
  qr_bg_color,
  qr_front_color,
  qr_image,
  qrCodeId,
  value,
  logo,
}) => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [teamID, setTeamID] = useAtom(teamIDCheck);
  const [loading, setLoading] = useState(false);
  const [purchasedPackage, setPurchasedPackage] = useAtom(subscribedPackage);
  const [bgColor, setBgColor] = useState(qr_bg_color || "#ffffff");
  const [fgColor, setFgColor] = useState(qr_front_color || "#000000");
  const [logoImageURL, setLogoImageURL] = useState(qr_image || qrCodeLogo);
  const [qrCodeData, setQRCodeData] = useState([]);
  const [shareOpen, setShareOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [uuid, setUuid] = useState();
  const [data, setData] = useState({});

  const loadProfile = async () => {
    setLoading(true);
    let { status, message, data } = await get(
      GET_USER,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      setData(data);
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };
  const [imageUrl, setImageUrl] = useState(null);
  const qrCodeContainerRef = useRef(null);

  const generateQRCodeImage = async () => {
    const qrCodeContainer = document.getElementById("qrCodeContainer");
    const childElement = qrCodeContainer.querySelector("#react-qrcode-logo");
    const childELementDurl = childElement.toDataURL();
    setImageUrl(childELementDurl);

    // if (!qrCodeContainer) {
    //   console.error("QR code container not found");
    //   return;
    // }
  };

  const handleShareOpen = (uuid) => {
    setShareOpen(true);
    setUuid(uuid);
  };
  const handleShareClose = () => {
    setShareOpen(false);
    setShow(false);
  };

  useEffect(() => {
    loadProfile();
  }, []);
  const qrRef = useRef(null);
  const handleCapture = () => {
    html2canvas(qrRef.current).then((canvas) => {
      const url = canvas.toDataURL();
      const a = document.createElement("a");
      a.href = url;
      a.download = "qrcode.png";
      a.click();
    });
  };

  return (
    <div className={classes.qrWrapper}>
      <div className={classes.qrCodeInner}>
        <div className={classes.qrCode}>
          <div id="qrCodeContainer">
            <QRCode value={value} logoImage={logo} />
          </div>
        </div>
      </div>

      <button onClick={generateQRCodeImage}>Generate QR Code Image</button>
      {imageUrl && <img src={imageUrl} alt="QR Code Image" />}
    </div>
  );
};
export default TestQRCode;
