import React, { useEffect, useState } from "react";
import { AddMoreCustomUrls } from "../utils/data/CardFunctionsData";
import "react-phone-input-2/lib/material.css";
import _ from "lodash";
import Card from "../components/PageComponents/Function/Card";
import { get } from "../server";
import { CONTACT } from "../utils/constants/apiUrl";
import { loggedInUser } from "../utils/store";
import { useAtom } from "jotai";
import { useParams } from "react-router-dom";
import Loader from "../components/Loader";
import showToast from "../helpers/showToast";
import { Link } from "react-router-dom";

const ViewContact = () => {
  const [cardFunction, setCardFunction] = useState({
    card_type: "",
    first_name: "",
    last_name: "",
    email: "",
    company: "",
    company_position: "",
    logo: "",
    background_image: "",
    profile_image: "",
    color: "",
    country_phone: "",
    phone_number: "",
    description: "",
    address: "",
    country: "",
    state: "",
    city: "",
    uuid: "",
    website_url: "",
    card_social_media: [],
    pronouns: "",
    card_phone: [{ phone_type: "", country_phone: "", phone_number: "" }],
    contact_card_other_data: [],
  });
  const [loading, setLoading] = useState(false);

  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);

  const { id } = useParams();

  useEffect(() => {
    fetchSingleContact();
  }, []);

  const fetchSingleContact = async () => {
    setLoading(true);

    let { status, message, data } = await get(
      `${CONTACT}/${id}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    let payload = _.cloneDeep(data);

    if (status) {
      data = data.contactCard;
      let card_social_media = [];

      data.contact_card_social_media.forEach((item) => {
        let foundIcon = AddMoreCustomUrls.find(
          (single) =>
            single.name === item.media_type ||
            single.oldName === item.media_type
        );

        card_social_media.push({
          media_type: item.media_type,
          url: item.url,
          icon: item.media_type === "custom" ? "custom" : foundIcon?.icon,
          custom_label: item.custom_label,
        });
      });

      setCardFunction({
        card_type: data.card_type,
        first_name: data.first_name ? data.first_name : "",
        last_name: data.last_name ? data.last_name : "",
        email: data.email ? data.email : "",
        company: data.company ? data.company : "",
        company_position: data.company_position ? data.company_position : "",
        logo: data.logo ? data.logo : "",
        background_image: data.background_image ? data.background_image : "",
        profile_image: data.profile_image ? data.profile_image : "",
        color: data.color ? data.color : "",
        country_phone: data.country_phone ? `+${data.country_phone}` : "",
        phone_number: data.phone_number
          ? `${data.country_phone ? data.country_phone : ""}${
              data.phone_number
            }`
          : "",
        description: data.description ? data.description : "",
        address: data.address ? data.address : "",
        country: data.country ? data.country : "",
        city: data.city ? data.city : "",
        state: data.state ? data.state : "",
        website_url: data.website_url ? data.website_url : "",
        card_social_media: card_social_media,
        pronouns: data.pronouns ? data.pronouns : "",
        card_phone: data.contact_card_phone,
        uuid: payload.uuid ? payload.uuid : "",
        contact_card_other_data: data.contact_card_other_data
          ? data.contact_card_other_data
          : "",
      });
    } else {
      showToast(status, message);
    }

    setLoading(false);
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <h6
        style={{
          fontWeight: "700",
          color: "var(--primary_text_color)",
          lineHeight: "100%",
        }}
      >
        <Link to="/contacts" style={{ color: "var(--primary_text_color)" }}>
          Connections
        </Link>
        {` > View Connection`}
      </h6>
      <div
        className="my-md-5 my-4"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "20px",
        }}
      >
        <div className="col-md-4 col-12">
          <Card data={cardFunction} inViewMode={true} hideHeader={true} />
        </div>
      </div>
    </>
  );
};

export default ViewContact;
