import React, { useState, useRef } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import classes from "../../../assets/styles/PageComponentsStyles/Function/ShowCustomURLSFunctions.module.css";
import DataTable, { createTheme } from "react-data-table-component";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import html2canvas from "html2canvas";
import Image from "react-bootstrap/Image";
import baseUrl from "../../../utils/constants/baseUrl";
import pictureicon from "../../../assets/images/function_page_icons/picIcon.svg";
import { useAtom } from "jotai";
import { mobileViewCheck } from "../../../utils/store";
import { Link } from "react-router-dom";
import formatPhoneNumber from "../../../helpers/formatPhoneNumber";
import ShareModal from "../../Modals/ShareModal";
import QRCodeModal from "../../Modals/QRCodeModal";
import Form from "react-bootstrap/Form";

const ShowEmergencyFunctions = ({
  show,
  handleShowModal,
  handleCloseModal,
  emergencyContacts,
  handleDelete,
  teamId,
  userId,
  team_id,
  user_id,
  handleStatus,
  getEmergencyContacts,
}) => {
  const target = useRef(null);

  const [showCopyPopup, setShowCopyPopup] = useState(false);
  const [qrOpen, setQrOpen] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);
  const [selectedData, setSelectedData] = useState("");
  const [mobileView] = useAtom(mobileViewCheck);

  const handleQrOpen = (obj) => {
    setSelectedData(obj);
    setQrOpen(true);
  };

  const handleQRClose = () => {
    setQrOpen(false);
    setShowCopyPopup(false);
    setSelectedData("");
  };

  const handleShareOpen = (obj) => {
    setSelectedData(obj);
    setShareOpen(true);
  };

  const handleShareClose = () => {
    setShareOpen(false);
    setShowCopyPopup(false);
    setSelectedData("");
  };
  const handleDownload = () => {
    html2canvas(document.querySelector("#qr-code")).then(function (canvas) {
      const link = document.createElement("a");
      link.download = "qrcode.png";
      link.href = canvas.toDataURL();
      link.click();
    });
  };

  createTheme(
    "solarized",
    {
      background: {
        default: "background: var(--secondary_color)",
      },
    },
    "dark"
  );

  const columns = [
    {
      name: "Image",
      width: "10%",
      cell: (row) => (
        <div className={classes.titleWrapper}>
          <Image
            className="globalIMageBorder globalIMageFit"
            src={baseUrl.PUBLIC_URL + row.image || pictureicon}
            roundedCircle={true}
            width="50px"
            height="50px"
            onError={(e) => {
              e.currentTarget.src = pictureicon;
            }}
          />
        </div>
      ),
    },

    {
      name: "Name",
      width: "30%",
      cell: (row) => (
        <p className="globalTitle">{`${row.first_name || ""} ${
          row.last_name || ""
        }`}</p>
      ),
    },
    {
      name: "Number",
      width: "30%",
      cell: (row) => (
        <a
          href={`tel:${
            row.phone_number
              ? row.country_phone
                ? "+" +
                  row.country_phone +
                  " " +
                  formatPhoneNumber(row.phone_number)
                : row.phone_number || ""
              : "-"
          }`}
          className={"globalUrlPath"}
        >
          {row.phone_number
            ? row.country_phone
              ? "+" +
                row.country_phone +
                " " +
                formatPhoneNumber(row.phone_number)
              : row.phone_number || ""
            : "-"}
        </a>
      ),
    },
    {
      name: "Status",
      width: "10%",
      center: "true",
      cell: (row) =>
        row.is_expired === 1 ? (
          <Form.Check
            type="switch"
            checked={row.status}
            onClick={() => handleStatus(row.id, row.function_type)}
            disabled
          />
        ) : (
          <Form.Check
            type="switch"
            checked={row.status}
            onClick={() => handleStatus(row.id, row.function_type)}
          />
        ),
    },
    {
      name: "Action",
      right: "true",
      cell: (row) => (
        <div className={classes.action}>
          <button
            className="btn customActionDropdown dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <BsThreeDotsVertical className={"globalVerticalThreeDots"} />
          </button>
          {row.is_expired === 1 ? (
            <ul className="customActionDropdownMenu dropdown-menu dropdown-menu-end ">
              <li>
                <span
                  className="dropdown-item"
                  to="#"
                  style={{ color: "var(--error_color)", cursor: "pointer" }}
                  onClick={() => handleDelete(row.id)}
                >
                  Delete
                </span>
              </li>
            </ul>
          ) : (
            <ul className="customActionDropdownMenu dropdown-menu dropdown-menu-end ">
              <li>
                <Link
                  className="dropdown-item"
                  to={
                    teamId && userId
                      ? `/team/${teamId}/teamMembers/member/${userId}/function/viewemergencycontact/${row.id}`
                      : `/function/viewemergencycontact/${row.id}${
                          team_id && user_id
                            ? `?team_id=${team_id}&user_id=${user_id}`
                            : ""
                        }`
                  }
                >
                  View
                </Link>
              </li>
              <li>
                <span
                  className="dropdown-item"
                  onClick={() => handleQrOpen(row)}
                >
                  QR Code
                </span>
              </li>
              <li>
                <span
                  className="dropdown-item"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleShowModal(row)}
                >
                  Edit
                </span>
              </li>
              <li>
                <span
                  className="dropdown-item"
                  onClick={() => handleStatus(row.id, row.function_type)}
                >
                  {row.status == true ? "Inactive" : "Active"}
                </span>
              </li>
              <li>
                <span
                  className="dropdown-item"
                  onClick={() => handleShareOpen(row)}
                >
                  Share
                </span>
              </li>
              <li>
                <span
                  className="dropdown-item"
                  to="#"
                  style={{ color: "var(--error_color)", cursor: "pointer" }}
                  onClick={() => handleDelete(row.id)}
                >
                  Delete
                </span>
              </li>
            </ul>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="listingWrapper">
        <DataTable
          columns={columns}
          data={emergencyContacts}
          theme="solarized"
        />
      </div>
      {qrOpen && (
        <QRCodeModal
          qrOpen={qrOpen}
          target={target}
          handleQRClose={handleQRClose}
          setShowCopyPopup={setShowCopyPopup}
          handleDownload={handleDownload}
          functionType="emergencyContact"
          functionUuid={selectedData.uuid}
          id={selectedData.id}
          function_type={selectedData.function_type}
          functionId={selectedData.id}
          QRCodeCustomize={selectedData.qrCodeCustomize}
          refreshData={getEmergencyContacts}
        />
      )}

      {shareOpen && (
        <ShareModal
          target={target}
          shareOpen={shareOpen}
          handleShareClose={handleShareClose}
          setShowCopyPopup={setShowCopyPopup}
          functionType="emergencyContact"
          functionUuid={selectedData.uuid}
        />
      )}

      <Overlay
        target={target.current}
        show={showCopyPopup}
        placement={mobileView ? "left" : "right"}
      >
        {(props) => (
          <Tooltip id="overlay-example" {...props}>
            Link Copied
          </Tooltip>
        )}
      </Overlay>
      <style type="">
        {`
          .form-switch{
            padding-left: 2.52rem;
          }
        `}
      </style>
    </>
  );
};

export default ShowEmergencyFunctions;
