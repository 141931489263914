import React, { useEffect, useState, useMemo } from "react";
import classes from "../../../assets/styles/Pages/CouponManagement/CouponManagement.module.css";
import { useAtom } from "jotai";
import { loggedInUser } from "../../../utils/store";
import Loader from "../../../components/Loader";
import "react-datepicker/dist/react-datepicker.css";
import { get } from "../../../server";
import { COUPON_DETAILS, EXPORT_COUPON } from "../../../utils/constants/apiUrl";
import showToast from "../../../helpers/showToast";
import { Link } from "react-router-dom";
import DataTable, { createTheme } from "react-data-table-component";
import FormInput from "../../../components/Forms/FormInput";
import searchIcon from "../../../assets/images/global_icons/searchicon.svg";
import { useParams } from "react-router-dom";
import { BsThreeDotsVertical } from "react-icons/bs";
import { saveAs } from "file-saver";
const ViewCoupons = () => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [loading, setLoading] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [coupons, setCoupons] = useState([]);
  let { id } = useParams();
  const handleFilter = (e) => {
    setFilterText(e.target.value);
  };

  const loadCoupons = async () => {
    setLoading(true);
    const couponId = id;
    const queryString = `?coupon_id=${couponId}`;
    let { status, message, data } = await get(
      COUPON_DETAILS + queryString,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      setCoupons(data.couponCodeText);
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  const filteredItems = useMemo(() => {
    const filtered = coupons.filter(
      (item) =>
        `${item.name || ""}`.toLowerCase().includes(filterText.toLowerCase()) ||
        `${item.coupon || ""}`
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
        `${item.discount || ""}`.includes(filterText)
    );

    return filtered;
  }, [filterText, coupons]);

  const handleCopy = (code) => {
    navigator.clipboard
      .writeText(code)
      .then(() => {
        showToast(true, "Coupon code copied");
      })
      .catch((error) => {
        showToast(false, "Failed to copy coupon code");
      });
  };

  // const exportCoupon = async (id) => {
  //   setLoading(true);
  //   const queryString = `?id=${id}`;
  //   let { status, message, data } = await get(
  //     EXPORT_COUPON + queryString,
  //     Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
  //   );
  //   if (status) {
  //     const filePath = data.path;
  //     saveAs(filePath, "coupon.xlsx");
  //   } else {
  //     showToast(status, message);
  //   }
  //   setLoading(false);
  // };

  createTheme(
    "solarized",
    {
      background: {
        default: "background: var(--secondary_color)",
      },
    },
    "dark"
  );

  const columns = [
    {
      name: "Coupon Code",
      cell: (row) => <p className="globalDetail">{row.coupon}</p>,
    },
    {
      name: "Action",
      right: "true",
      cell: (row) => (
        <div className={classes.action}>
          <button
            className="btn customActionDropdown dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <BsThreeDotsVertical className={"globalVerticalThreeDots"} />
          </button>
          <ul className="customActionDropdownMenu dropdown-menu dropdown-menu-end ">
            <li>
              <button
                className="dropdown-item"
                onClick={() => handleCopy(row.coupon)}
              >
                Copy
              </button>
            </li>
            {/* <li>
              <button
                className="dropdown-item"
                onClick={() => exportCoupon(row.id)}
              >
                Export Collection
              </button>
            </li> */}
          </ul>
        </div>
      ),
    },
    // {
    //   name: "Action",
    //   right: "true",
    //   cell: (row) => (
    //     <button
    //       className="primary_button"
    //       onClick={() => handleCopy(row.coupon)}
    //     >
    //       Copy
    //     </button>
    //   ),
    // },
  ];

  useEffect(() => {
    loadCoupons();
  }, []);

  return (
    <>
      {!loading ? (
        <div>
          <div className="page_Header">
            <div className="d-flex justify-content-between align-items-md-center align-items-initial flex-md-row flex-column gap-3">
              <p className="pageTitle">
                Coupon Codes <span>({coupons.length})</span>
              </p>
              <div className="d-flex align-items-center justify-content-between gap-2">
                <FormInput
                  icon={searchIcon}
                  placeholder="Search..."
                  value={filterText}
                  handleChange={handleFilter}
                />
              </div>
            </div>
          </div>
          <div className={`listingWrapper mainWrapper`}>
            <DataTable
              columns={columns}
              data={filteredItems}
              pagination
              theme="solarized"
            />
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default ViewCoupons;
