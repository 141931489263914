import React, { useEffect, useState } from "react";
import classes from "../../assets/styles/ViewLeads.module.css";
import { useAtom } from "jotai";
import { loggedInUser, teamIDCheck } from "../../utils/store";
import Loader from "../../components/Loader";
import "react-datepicker/dist/react-datepicker.css";
import { get } from "../../server";
import { LEADS } from "../../utils/constants/apiUrl";
import showToast from "../../helpers/showToast";
import baseUrl from "../../utils/constants/baseUrl";
import { useParams, useNavigate, Link } from "react-router-dom";

const ViewLead = () => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [userTeamId, setUserTeamId] = useAtom(teamIDCheck);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [lead, setLead] = useState([]);
  let { id } = useParams();
  const navigate = useNavigate();

  const loadAllLeads = async () => {
    setLoading(true);
    const queryString = `?team_id=${userTeamId}`;
    let { status, message, data } = await get(
      userTeamId ? `${LEADS}/${id}` + queryString : `${LEADS}/${id}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    // let { status, message, data } = await get(
    //   `${LEADS}/${id}`,
    //   Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    // );
    if (status) {
      if (data.userLeadData.length > 0) {
        setLead(data.userLeadData);
      }
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadAllLeads();
  }, []);
  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <>
      {!loading ? (
        <div>
          <div className={classes.mainWrapper}>
            <h6 className={classes.title}>
              <Link
                onClick={handleGoBack}
                style={{ color: "var(--heading_text_color)" }}
              >
                {`< Back`}
              </Link>
            </h6>
            {lead.length > 0 ? (
              <table className={`table table-bordered ${classes.table}`}>
                <thead>
                  <tr>
                    <th>Label</th>
                    <th>Data</th>
                  </tr>
                </thead>
                <tbody>
                  {lead.map((item) => {
                    let typeCell, dataCell;

                    if (
                      item.type === "heading" ||
                      item.type === "paragraph" ||
                      item.type === "list" ||
                      item.type === "line"
                    ) {
                      <> </>;
                    } else if (item.type === "upload") {
                      typeCell = <td>{item.label}</td>;
                      dataCell = (
                        <td>
                          <img
                            src={
                              item.data ? baseUrl.PUBLIC_URL + item.data : ""
                            }
                            width="50px"
                            alt=""
                          />
                        </td>
                      );
                    } else {
                      typeCell = <td>{item.label}</td>;
                      dataCell = <td>{item.data}</td>;
                    }

                    return (
                      <tr key={item.id}>
                        {typeCell}
                        {dataCell}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            ) : (
              <p className="text-center pt-3">No data found</p>
            )}
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default ViewLead;
