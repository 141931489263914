import React, { useState, useEffect } from "react";
import classes from "../../../assets/styles/Pages/Subscriptions/ManageSubscriptions.module.css";
import { useAtom } from "jotai";
import { loggedInUser } from "../../../utils/store";
import Loader from "../../../components/Loader";
import searchIcon from "../../../assets/images/global_icons/searchicon.svg";
import SettingsInput from "../../../components/Forms/SettingsInput";
import FormInput from "../../../components/Forms/FormInput";
import Button from "react-bootstrap/Button";
import "react-datepicker/dist/react-datepicker.css";
import { post, get } from "../../../server";
import {
  PACKAGE_CREATE,
  ALLTEAMOWNERS,
  IMAGE_UPLOAD,
  PACKAGES_UPDATE,
  PACKAGES_DETAILS,
  USER,
} from "../../../utils/constants/apiUrl";
import showToast from "../../../helpers/showToast";
import { useParams, useNavigate } from "react-router-dom";
import Table from "react-bootstrap/Table";
import ImageUploadSquare from "../../../components/ImageUploadSquare";
const AddEditPackage = ({ logo }) => {
  const [emptyObj, setEmptyObj] = useState({
    id: "",
    package_name: "",
    duration: "",
    duration_type: "",
    package_type: "customer_specific_personal",
    price_limit: "",
    individual_person_fee: "",
    user_id: "",
    price: "",
    is_nerve_ending: "",
    logo: "",
    user_limit: "",
    price_per_user: "",
    limit_card_create: "",
    limit_payment_create: "",
    limit_emergency_contact_create: "",
    limit_file_upload_create: "",
    limit_form_builder: "",
    limit_medical_id: "",
    limit_memory: "",
    limit_memory_size: "",
    limit_dynamic_qr_code: "",
    limit_dynamic_qr_code_each_function: "",
    limit_connections_save: "",
    limit_custom_url_create: "",
    enable_custom_buttons: "",
  });
  let { id } = useParams();
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [loading, setLoading] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [errors, setErrors] = useState({});
  const [teamOwners, setTeamOwners] = useState([]);
  const [createPackages, setCreatePackages] = useState(emptyObj);
  const [isNeverEndingPackage, setIsNeverEndingPackage] = useState(false);
  const [getLogo, setGetLogo] = useState();
  const [image, selectImage] = useState("");
  const [logoImageURL, setLogoImageURL] = useState();
  const [packageFor, setPackageFor] = useState("customer_specific_owner");
  const navigate = useNavigate();
  const handleFilter = (e) => {
    setFilterText(e.target.value);
  };

  const handleRadioChange = (e) => {
    const value = e.target.value === "true" ? 1 : 0;
    setCreatePackages({
      ...createPackages,
      enable_custom_buttons: value,
    });
  };
  const handleNeverEndingPackageChange = (e) => {
    setIsNeverEndingPackage(e.target.checked);
  };

  const handleChange = (e) => {
    const { name, value, type } = e.target;

    // Handle radio buttons separately
    if (type === "radio") {
      setCreatePackages({ ...createPackages, package_type: value });
    } else {
      setCreatePackages({ ...createPackages, [name]: value });
    }

    // Update packageFor based on the selected package type
    if (value === "customer_specific_owner") {
      setPackageFor("customer_specific_owner");
    } else if (value === "customer_specific_personal") {
      setPackageFor("customer_specific_personal");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let uploadedImageUrl = "";
    if (image === "" && !getLogo) {
      showToast(false, "Please upload an image");
      setLoading(false);
    } else {
      if (image && typeof image === "object") {
        let formDataObj = new FormData();
        formDataObj.append("image_type", "logo");
        formDataObj.append("image", image);

        let { status, message, data } = await post(
          IMAGE_UPLOAD,
          formDataObj,
          Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
        );

        if (status) {
          uploadedImageUrl = data || "";
          setLogoImageURL(uploadedImageUrl);
          let payload = { ...createPackages };
          payload.is_nerve_ending = isNeverEndingPackage ? "1" : "0";
          payload.logo = uploadedImageUrl;
          if (createPackages.package_type === "customer_specific_personal") {
            payload.user_limit = "0";
            payload.price_per_user = "0";
          }
          if (id) {
            payload.package_id = id;
          }

          let {
            status: packagesStatus,
            message: packagesMessage,
            data: packagesData,
          } = await post(
            id ? PACKAGES_UPDATE : PACKAGE_CREATE,
            payload,
            Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
          );

          if (packagesStatus) {
            setCreatePackages(packagesData);
            navigate("/manageSubscriptions");
            showToast(status, message);
          } else {
            showToast(packagesStatus, packagesMessage);
          }
        } else {
          showToast(status, message);
        }
      } else {
        // Use the existing logo if no new image is selected
        let payload = { ...createPackages };
        payload.is_nerve_ending = isNeverEndingPackage ? "1" : "0";
        payload.logo = getLogo; // Use the existing logo
        if (id) {
          payload.package_id = id;
        }

        let {
          status: packagesStatus,
          message: packagesMessage,
          data: packagesData,
        } = await post(
          id ? PACKAGES_UPDATE : PACKAGE_CREATE,
          payload,
          Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
        );

        if (packagesStatus) {
          setCreatePackages(packagesData);
          navigate("/manageSubscriptions");
        } else {
          showToast(packagesStatus, packagesMessage);
        }
      }
    }

    setLoading(false);
  };
  const getAllTeamOwners = async () => {
    setLoading(true);
    let { status, message, data } = await get(
      ALLTEAMOWNERS,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      if (data.length > 0) {
        setTeamOwners(data);
      }
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };
  const getAllUsers = async () => {
    setLoading(true);
    let { status, message, data } = await get(
      USER,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      if (data.length > 0) {
        setTeamOwners(data);
      }
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  const loadPackages = async () => {
    setLoading(true);
    const packageId = id;
    const queryString = `?package_id=${packageId}`;
    let { status, message, data } = await get(
      PACKAGES_DETAILS + queryString,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      setCreatePackages(data);
      setIsNeverEndingPackage(true);
      setGetLogo(data?.logo);
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (id) {
      loadPackages();
    }
  }, [id]);

  useEffect(() => {
    const fetchUsersOrOwners = async () => {
      if (createPackages.package_type === "customer_specific_owner") {
        await getAllTeamOwners();
      } else {
        await getAllUsers();
      }
    };
    fetchUsersOrOwners();
  }, [createPackages.package_type]);

  return (
    <>
      {!loading ? (
        <div>
          <div className="page_Header">
            <div className="d-flex justify-content-between align-items-md-center align-items-initial flex-md-row flex-column gap-3">
              <p className="pageTitle"> {id ? "Edit" : "Add"} Package</p>
              <div className="d-flex align-items-center justify-content-between gap-2">
                <FormInput
                  icon={searchIcon}
                  placeholder="Search..."
                  value={filterText}
                  handleChange={handleFilter}
                />
              </div>
            </div>
          </div>
          <div className={classes.content}>
            <div className={`mainWrapper ${classes.outerWrapper}`}>
              <div className="row gx-5 gy-3">
                <div className="col-md-4 col-12">
                  <div className="form-check form-check-inline w-100 ps-0">
                    <label
                      style={{
                        fontSize: "12px",
                        marginBottom: "10px",
                        lineHeight: "18px",
                      }}
                    >
                      Select Package For
                    </label>
                    <div className="row">
                      <div className="col-md-6">
                        <label>
                          <input
                            type="radio"
                            name="packageFor"
                            value="customer_specific_personal"
                            // checked={packageFor === "user"}
                            // onChange={handleSelectPackageFor}
                            checked={
                              createPackages.package_type ===
                              "customer_specific_personal"
                            }
                            onChange={handleChange}
                          />{" "}
                          <span
                            style={{
                              fontSize: "12px",
                              marginBottom: "10px",
                              lineHeight: "18px",
                            }}
                          >
                            User
                          </span>
                        </label>
                      </div>
                      <div className="col-md-6">
                        <label>
                          <input
                            type="radio"
                            name="packageFor"
                            value="customer_specific_owner"
                            // checked={packageFor === "team"}
                            // onChange={handleSelectPackageFor}
                            checked={
                              createPackages.package_type ===
                              "customer_specific_owner"
                            }
                            onChange={handleChange}
                          />{" "}
                          <span
                            style={{
                              fontSize: "12px",
                              marginBottom: "10px",
                              lineHeight: "18px",
                            }}
                          >
                            Team
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-12">
                  <div className={classes.selectWrapper}>
                    <label
                      htmlFor=""
                      style={{
                        fontSize: "12px",
                        marginBottom: "10px",
                        lineHeight: "18px",
                      }}
                    >
                      Select{" "}
                      {createPackages.package_type ===
                      "customer_specific_personal"
                        ? "User"
                        : "Team"}
                    </label>
                    <div className="input-group globalInputWithIcon">
                      <select
                        className="form-select customSelect"
                        name="user_id"
                        value={createPackages.user_id}
                        onChange={handleChange}
                      >
                        <option>
                          Select{" "}
                          {createPackages.package_type ===
                          "customer_specific_personal"
                            ? "User"
                            : "Team"}
                        </option>
                        {createPackages.package_type ===
                        "customer_specific_personal"
                          ? Array.isArray(teamOwners) &&
                            teamOwners.map((owner) => (
                              <option key={owner.id} value={owner.id}>
                                {owner?.email}
                              </option>
                            ))
                          : Array.isArray(teamOwners) &&
                            teamOwners.map((owner) => (
                              <option key={owner.id} value={owner.id}>
                                {owner?.teams !== null ? owner.email : ""}
                              </option>
                            ))}
                      </select>
                    </div>
                    <span className="errorText">{errors.package_id}</span>
                  </div>
                </div>
                <div className="col-md-4 col-12">
                  <div className="companyLogo">
                    <ImageUploadSquare
                      selectedImage={typeof image == "object" ? image : getLogo}
                      setSelectedImage={logoImageURL || selectImage}
                    />
                  </div>
                </div>
              </div>
              <div className="row gx-5 gy-3 align-items-center">
                <div className="col-md-4 col-12">
                  <label
                    htmlFor=""
                    style={{
                      fontSize: "12px",
                      marginBottom: "10px",
                      lineHeight: "18px",
                    }}
                  >
                    Subscription type
                  </label>
                  <div className={`inputSelect ${classes.inputSelect}`}>
                    <FormInput
                      type="text"
                      placeholder="Enter Subscription Types"
                      name="duration"
                      value={createPackages.duration}
                      onChange={handleChange}
                      error={errors?.title}
                    />
                    <select
                      className="form-select customSelect "
                      name="duration_type"
                      value={createPackages.duration_type}
                      onChange={handleChange}
                    >
                      <option selected>Select</option>
                      <option value="year">Yearly</option>
                      <option value="month">Monthly</option>
                      <option value="days">Days</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-4 col-12">
                  <SettingsInput
                    label="Package Name"
                    type="text"
                    placeholder="Enter Package Name"
                    name="package_name"
                    value={createPackages.package_name}
                    handleChange={handleChange}
                    error={errors.package_name}
                  />
                </div>
                <div className="col-md-4 col-12">
                  <SettingsInput
                    label="Package Price"
                    type="text"
                    placeholder="Enter discount value"
                    name="price"
                    value={createPackages.price}
                    handleChange={handleChange}
                    error={errors.price}
                  />
                </div>
                {/* </div>
              <div className={classes.features}>
                <div className="row gx-5 gy-3 align-items-center"> */}
                {createPackages.package_type === "customer_specific_owner" ? (
                  <>
                    <div className="col-md-4 col-12">
                      <SettingsInput
                        label="Maximum person limit"
                        type="text"
                        placeholder="Maximum person limit"
                        name="user_limit"
                        value={createPackages.user_limit}
                        handleChange={handleChange}
                        error={errors.user_limit}
                      />
                    </div>
                    <div className="col-md-4 col-12">
                      <SettingsInput
                        label="Individual Person Price"
                        type="text"
                        placeholder="Individual Person Price"
                        name="price_per_user"
                        value={createPackages.price_per_user}
                        handleChange={handleChange}
                        error={errors.price_per_user}
                      />
                    </div>
                  </>
                ) : (
                  ""
                )}
                <div className="col-md-4 col-12">
                  <div className="form-check text-start">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="is_nerve_ending"
                      value={createPackages.is_nerve_ending}
                      checked={isNeverEndingPackage}
                      onChange={handleNeverEndingPackageChange}
                    />
                    <label
                      className="form-check-label"
                      style={{
                        fontSize: "12px",
                        marginBottom: "10px",
                        lineHeight: "18px",
                      }}
                    >
                      Never Ending Package
                    </label>
                  </div>
                </div>
                <div className="col-12">
                  <p className="mb-2">Features list</p>
                  <Table className={classes.table}>
                    <thead>
                      <tr>
                        <th>Feature name</th>
                        <th>Quantity</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Personal/Business Card</td>
                        <td className={`quantity ${classes.quantity}`}>
                          <SettingsInput
                            type="text"
                            name="limit_card_create"
                            value={createPackages.limit_card_create}
                            handleChange={handleChange}
                            error={errors.name}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Connections</td>
                        <td className={`quantity ${classes.quantity}`}>
                          <SettingsInput
                            type="text"
                            name="limit_connections_save"
                            value={createPackages.limit_connections_save}
                            handleChange={handleChange}
                            error={errors.name}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Payments cards</td>
                        <td className={`quantity ${classes.quantity}`}>
                          <SettingsInput
                            type="text"
                            name="limit_payment_create"
                            value={createPackages.limit_payment_create}
                            handleChange={handleChange}
                            error={errors.limit_payment_create}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Emergency Contacts</td>
                        <td className={`quantity ${classes.quantity}`}>
                          <SettingsInput
                            type="text"
                            name="limit_emergency_contact_create"
                            value={
                              createPackages.limit_emergency_contact_create
                            }
                            handleChange={handleChange}
                            error={errors.limit_emergency_contact_create}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Custom URL</td>
                        <td className={`quantity ${classes.quantity}`}>
                          <SettingsInput
                            type="text"
                            name="limit_custom_url_create"
                            value={createPackages.limit_custom_url_create}
                            handleChange={handleChange}
                            error={errors.limit_custom_url_create}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>File Upload URL</td>
                        <td className={`quantity ${classes.quantity}`}>
                          <SettingsInput
                            type="text"
                            name="limit_file_upload_create"
                            value={createPackages.limit_file_upload_create}
                            handleChange={handleChange}
                            error={errors.limit_file_upload_create}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Memory</td>
                        <td className={`quantity ${classes.quantity}`}>
                          <SettingsInput
                            type="text"
                            name="limit_memory"
                            value={createPackages.limit_memory}
                            handleChange={handleChange}
                            error={errors.limit_memory}
                          />
                        </td>
                      </tr>
                      {/* <tr>
                        <td>Memory Size</td>
                        <td className={`quantity ${classes.quantity}`}>
                          <SettingsInput
                            type="text"
                            name="limit_memory_size"
                            value={createPackages.limit_memory_size}
                            handleChange={handleChange}
                            error={errors.limit_memory_size}
                          />
                        </td>
                      </tr> */}
                      <tr>
                        <td>Lead Builder</td>
                        <td className={`quantity ${classes.quantity}`}>
                          <SettingsInput
                            type="text"
                            name="limit_form_builder"
                            value={createPackages.limit_form_builder}
                            handleChange={handleChange}
                            error={errors.limit_form_builder}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Medical ID</td>
                        <td className={`quantity ${classes.quantity}`}>
                          <SettingsInput
                            type="text"
                            name="limit_medical_id"
                            value={createPackages.limit_medical_id}
                            handleChange={handleChange}
                            error={errors.limit_medical_id}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Dynamic QR Code</td>
                        <td className={`quantity ${classes.quantity}`}>
                          <SettingsInput
                            type="text"
                            name="limit_dynamic_qr_code"
                            value={createPackages.limit_dynamic_qr_code}
                            handleChange={handleChange}
                            error={errors.limit_dynamic_qr_code}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Dynamic QR Code - Each Function Limit</td>
                        <td className={`quantity ${classes.quantity}`}>
                          <SettingsInput
                            type="text"
                            name="limit_dynamic_qr_code_each_function"
                            value={
                              createPackages.limit_dynamic_qr_code_each_function
                            }
                            handleChange={handleChange}
                            error={errors.limit_dynamic_qr_code_each_function}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td>Custom Button</td>
                        <td className={`quantity ${classes.quantity}`}>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="enable_custom_buttons"
                              value="true"
                              checked={
                                createPackages.enable_custom_buttons === 1
                              }
                              onChange={handleRadioChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="enable_custom_buttons_true"
                            >
                              True
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="enable_custom_buttons"
                              value="false"
                              checked={
                                createPackages.enable_custom_buttons === 0
                              }
                              onChange={handleRadioChange}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="enable_custom_buttons_false"
                            >
                              False
                            </label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
                {/* </div> */}
              </div>
              <div className="formBottom">
                <Button
                  className="submitButton"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
      <style type="text/css">
        {`
          .inputSelect .globalInputWithIcon{
          border-right: 0px;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          height:100%
          }
          .inputSelect .customSelect{
          border: 2px solid var(--border_color) !important;
          border-radius: 60px !important;
          border-left: 0px;
          border-top-left-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
          width:50%
          }
          .imageWrapper {
          width: 80px !important;
          height: 80px !important;
          border-radius:50%;
          }
          .imageWrapper::before{
          background:transparent !important;
          border-radius:50%;
          }
          .imageWrapper img{
          width: 80px !important;
          height: 80px !important;
          border-radius:50%;
          }
          .companyLogo{
          width: 80px !important;
          height: 80px !important;
          position:relative;
          }
          .removeImgBtn{
          position:absolute;
          bottom:0;
          right:0;
          background-color:#2D374A;
          color:#fff;
          width:30px;
          border-radius:50%;
          }
          // .imageWrapper .icon {
          // color:var(--primary_button_color);
          // font-size:18px
          // }
          table td.quantity > div.form-check{
          display: inline-block;
          margin-left: 20px;
          width:auto;
          }
        `}
      </style>
    </>
  );
};

export default AddEditPackage;
