import React, { useState, useEffect } from "react";
import classes from "../../../assets/styles/Pages/CouponManagement/CouponManagement.module.css";
import { useAtom } from "jotai";
import { loggedInUser } from "../../../utils/store";
import Loader from "../../../components/Loader";
import SettingsInput from "../../../components/Forms/SettingsInput";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { post, get, put } from "../../../server";
import _ from "lodash";
import { PRODUCT_DISCOUNT } from "../../../utils/constants/apiUrl";
import showToast from "../../../helpers/showToast";
import { useParams, useNavigate } from "react-router-dom";

const AddEditProductDiscount = () => {
  let { id } = useParams();
  const navigate = useNavigate();
  let emptyObj = {
    id: "",
    name: "",
    discount: "",
    discount_type: "",
    start_date: "",
    end_date: "",
    package_id: "",
    duration_type: "",
    status: "",
  };
  const { discountID } = useParams();
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [loading, setLoading] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [errors, setErrors] = useState({});
  const [createDiscount, setCreateDiscount] = useState(emptyObj);
  const handleChange = (e, i) => {
    const name = e.target.name;
    const value = e.target.value;
    setCreateDiscount({ ...createDiscount, [name]: value });
  };
  const handleStartDateChange = (date) => {
    // Convert the selected date to the local timezone without altering the time
    const localDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    );
    setCreateDiscount({ ...createDiscount, start_date: localDate });
  };

  const handleEndDateChange = (date) => {
    const localDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    );
    setCreateDiscount({ ...createDiscount, end_date: localDate });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //if (!validateForm()) return;
    setLoading(true);
    let payload = _.cloneDeep(createDiscount);
    payload.start_date = new Date(
      payload.start_date.getTime() -
        payload.start_date.getTimezoneOffset() * 60000
    ).toISOString();
    payload.end_date = new Date(
      payload.end_date.getTime() - payload.end_date.getTimezoneOffset() * 60000
    ).toISOString();
    if (id) {
      let { status, message, data } = await put(
        PRODUCT_DISCOUNT + `/${id}`,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
      if (status) {
        setCreateDiscount(data);
        navigate("/productDiscount");
      } else {
        showToast(status, message);
      }
    } else {
      let { status, message, data } = await post(
        PRODUCT_DISCOUNT,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
      if (status) {
        setCreateDiscount(data);
        navigate("/productDiscount");
      } else {
        showToast(status, message);
      }
    }

    setLoading(false);
  };

  const loadDiscounts = async () => {
    setLoading(true);
    let { status, message, data } = await get(
      `${PRODUCT_DISCOUNT}/${id}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      data.start_date = new Date(
        new Date(data.start_date).getTime() +
          new Date(data.start_date).getTimezoneOffset() * 60000
      );
      data.end_date = new Date(
        new Date(data.end_date).getTime() +
          new Date(data.end_date).getTimezoneOffset() * 60000
      );
      setCreateDiscount(data);
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (id) {
      loadDiscounts();
    }
  }, []);

  return (
    <>
      {!loading ? (
        <div>
          <div className="page_Header">
            <div className="d-flex justify-content-between align-items-md-center align-items-initial flex-md-row flex-column gap-3">
              <p className="pageTitle mb-0"> {id ? "Edit" : "Add"} Discount</p>
            </div>
          </div>
          <div className={classes.content}>
            <div className={`mainWrapper ${classes.outerWrapper}`}>
              <div className="row align-items-center gx-5 gy-3">
                <div className="col-md-4 col-12">
                  <SettingsInput
                    label="Discount name"
                    type="text"
                    placeholder="Enter discount name"
                    name="name"
                    value={createDiscount.name}
                    handleChange={handleChange}
                    error={errors.name}
                  />
                </div>

                <div className="col-md-4 col-12">
                  <div className={classes.selectWrapper}>
                    <label
                      htmlFor=""
                      style={{
                        fontSize: "12px",
                        marginBottom: "10px",
                        lineHeight: "18px",
                      }}
                    >
                      Select package
                    </label>
                    <div className="input-group globalInputWithIcon">
                      <select
                        className="form-select customSelect"
                        name="package_id"
                        value={createDiscount.package_id}
                        onChange={handleChange}
                      >
                        <option value="">Select package</option>
                        <option value="2">Premium</option>
                        <option value="3">Elite</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-12">
                  <div className={classes.selectWrapper}>
                    <label
                      htmlFor=""
                      style={{
                        fontSize: "12px",
                        marginBottom: "10px",
                        lineHeight: "18px",
                      }}
                    >
                      Duration Type
                    </label>
                    <div className="input-group globalInputWithIcon">
                      <select
                        className="form-select customSelect"
                        name="duration_type"
                        value={createDiscount.duration_type}
                        onChange={handleChange}
                      >
                        <option value="">Select Duration</option>
                        <option value="monthly">Monthly</option>
                        <option value="yearly">Yearly</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-12">
                  <div className={classes.selectWrapper}>
                    <label
                      htmlFor=""
                      style={{
                        fontSize: "12px",
                        marginBottom: "10px",
                        lineHeight: "18px",
                      }}
                    >
                      Discount Type
                    </label>
                    <div className="input-group globalInputWithIcon">
                      <select
                        className="form-select customSelect"
                        name="discount_type"
                        value={createDiscount.discount_type}
                        onChange={handleChange}
                      >
                        <option value="">Select Type</option>
                        <option value="percentage">Percentage</option>
                        <option value="amount">Flat Rate</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className="col-md-4 col-12">
                  <SettingsInput
                    label="Discount"
                    type="text"
                    placeholder="Enter discount value"
                    name="discount"
                    value={createDiscount.discount}
                    handleChange={handleChange}
                    error={errors.discount}
                  />
                </div>

                <div className="col-md-4 col-12">
                  <div className={classes.selectWrapper}>
                    <label
                      htmlFor=""
                      style={{
                        fontSize: "12px",
                        marginBottom: "10px",
                        lineHeight: "18px",
                      }}
                    >
                      Status
                    </label>
                    <div className="input-group globalInputWithIcon">
                      <select
                        className="form-select customSelect"
                        name="status"
                        value={createDiscount.status}
                        onChange={handleChange}
                      >
                        <option value="">Select Status</option>
                        <option value="1">Active</option>
                        <option value="0">Inactive</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-12">
                  <label
                    htmlFor=""
                    className={classes.label}
                    style={{
                      color: "var(--heading_text_color)",
                    }}
                  >
                    From Date
                  </label>
                  <DatePicker
                    selected={createDiscount.start_date}
                    onChange={handleStartDateChange}
                    dateFormat="MM/dd/yyyy"
                    className="dobInput"
                    placeholderText="MM/dd/yyyy"
                    showYearDropdown
                    isClearable
                    yearDropdownItemNumber={110}
                    scrollableYearDropdown
                  />
                </div>
                <div className="col-md-4 col-12">
                  <label
                    htmlFor=""
                    className={classes.label}
                    style={{
                      color: "var(--heading_text_color)",
                    }}
                  >
                    To Date
                  </label>
                  <DatePicker
                    selected={createDiscount.end_date}
                    onChange={handleEndDateChange}
                    dateFormat="MM/dd/yyyy"
                    className="dobInput"
                    placeholderText="MM/dd/yyyy"
                    showYearDropdown
                    isClearable
                    yearDropdownItemNumber={110}
                    scrollableYearDropdown
                  />
                </div>
              </div>
              <div className="formBottom">
                <Button
                  className="submitButton"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default AddEditProductDiscount;
