import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/signup_icons/logo.svg";
import google from "../assets/images/signup_icons/google.png";
import facebook from "../assets/images/signup_icons/face.svg";
import personicon from "../assets/images/signup_icons/personicon.svg";
import emailicon from "../assets/images/signup_icons/email.svg";
import lockicon from "../assets/images/signup_icons/lock.svg";
import classes from "../assets/styles/Signup.module.css";
import FormInput from "../components/Forms/FormInput";
import { SIGNUP, TEAM_OWNER_SIGNUP } from "../utils/constants/apiUrl";
import { post } from "../server";
import { useAtom } from "jotai";
import { loggedInUser, isOwnerPackage } from "../utils/store";
import { useNavigate, useLocation } from "react-router-dom";
import {
  UserSignupSchema,
  TeamOwnerSignupSchema,
} from "../utils/SchemasValidation/SignupSchema";
import ValidateSchema from "../helpers/ValidateSchema";
import showToast from "../helpers/showToast";
import Loader from "../components/Loader";
import { useGoogleLogin } from "@react-oauth/google";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import _ from "lodash";
import { logoutFbApi } from "../utils/functions";
import useFunctionsCount from "../hooks/useFunctionsCount";
import AuthModal from "../components/Modals/AuthModal";
import useLogout from "../hooks/useLogout";

const Signup = () => {
  let emptyObj = {
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
  };
  const logout = useLogout();

  let navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const uuid = searchParams.get("uuid");
  const team_id = searchParams.get("team_id");
  const email = searchParams.get("email");

  const { userActions } = useFunctionsCount();

  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [isOwnerPackageActive, setIsOwnerPackageActive] =
    useAtom(isOwnerPackage);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [user, setUser] = useState(emptyObj);
  const [teamOwner, setTeamOwner] = useState({
    team_name: "",
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
  });

  const [key, setKey] = useState("individual");

  const [showAuthModal, setShowAuthModal] = useState(false);
  const [isTeamOwner, setIsTeamOwner] = useState(false);
  const [socialTeamOwner, setSocialTeamOwner] = useState({ team_name: "" });

  const handleCloseModal = () => {
    setShowAuthModal(false);
  };

  useEffect(() => {
    if (email) {
      setUser({ ...user, email: email });
    }
  }, [email]);

  // const handleChange = (e) => {
  //   if (key === "individual") {
  //     const name = e.target.name;
  //     const value = e.target.value.trim();
  //     setUser({ ...user, [name]: value });
  //   } else {
  //     const name = e.target.name;
  //     const value = e.target.value;
  //     setTeamOwner({ ...teamOwner, [name]: value });
  //   }
  // };
  const handleChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.trim();

    // Update the relevant state based on the selected tab (individual or team)
    if (key === "individual") {
      setUser((prev) => ({ ...prev, [name]: trimmedValue }));

      // Validate the specific field and clear the error for that field if valid
      const fieldErrors = ValidateSchema(
        { [name]: trimmedValue },
        UserSignupSchema
      );
      setErrors((prevErrors) => ({ ...prevErrors, [name]: fieldErrors[name] }));
    } else {
      setTeamOwner((prev) => ({ ...prev, [name]: trimmedValue }));

      // Validate and clear errors for team owner fields
      const fieldErrors = ValidateSchema(
        { [name]: trimmedValue },
        TeamOwnerSignupSchema
      );
      setErrors((prevErrors) => ({ ...prevErrors, [name]: fieldErrors[name] }));
    }
  };

  const validateForm = () => {
    if (key === "individual") {
      let newErrors = ValidateSchema(user, UserSignupSchema);
      setErrors(newErrors);
      if (Object.keys(newErrors).length) return false;
      return true;
    } else {
      let newErrors = ValidateSchema(teamOwner, TeamOwnerSignupSchema);
      setErrors(newErrors);
      if (Object.keys(newErrors).length) return false;
      return true;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setIsOwnerPackageActive(false);
    setLoading(true);
    if (key === "individual") {
      let payload = _.cloneDeep(user);
      if (uuid && team_id) {
        payload.uuid = uuid;
        payload.team_id = team_id;
      }
      let { status, message, data } = await post(SIGNUP, payload);
      if (status) {
        if (
          email &&
          loggedUser?.email?.length > 0 &&
          loggedUser?.email !== email
        ) {
          logout();
          setLoggedUser(data);
          userActions(data);
          navigate("/");
        } else {
          setLoggedUser(data);
          userActions(data);
          navigate("/individualSubscription");
        }
      }
      showToast(status, message);
    } else {
      let { status, message, data } = await post(TEAM_OWNER_SIGNUP, teamOwner);
      if (status) {
        setLoggedUser(data);
        userActions(data);
        navigate("/subscription");
      }

      if (!status) {
        showToast(status, message);
      }
    }
    setLoading(false);
  };
  // const loginButton = useGoogleLogin({
  //   onSuccess: (codeResponse) => {
  //     fetch(
  //       `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${codeResponse.access_token}`
  //     )
  //       .then((res) => {
  //         return res.json();
  //       })
  //       .then(async (users) => {
  //         return await sendSocialToken(users);
  //       })
  //       .catch((error) => {
  //         showToast(false, error);
  //         setLoading(false);
  //         return;
  //       });
  //   },
  //   onError: (err) => {
  //     setLoading(false);
  //     showToast(false, err);
  //   },
  // });
  const loginButton = useGoogleLogin({
    onSuccess: (codeResponse) => {
      fetch(
        `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${codeResponse.access_token}`
      )
        // .log("codeResponse.access_token", codeResponse.access_token)
        .then((res) => {
          return res.json();
        })
        .then(async (users) => {
          return await sendSocialToken(users);
        })
        .catch((error) => {
          showToast(false, error);
          setLoading(false);
          return;
        });
    },
    onError: (err) => {
      setLoading(false);
      showToast(false, err);
    },
  });

  const sendSocialToken = async (user) => {
    setLoading(true);

    let payload = user;
    payload.first_name = user.given_name;
    payload.last_name = user.family_name;
    payload.token = user.sub;
    payload.provider_type = "google";

    if (uuid && team_id) {
      payload.uuid = uuid;
      payload.team_id = team_id;
    }

    if (uuid && team_id) {
      if (payload.email === email) {
        navigate(
          `/socialSignup?first_name=${payload.first_name}&last_name=${
            payload.last_name
          }&socialEmail=${payload.email}&is_team_owner=${
            key === "individual" ? false : true
          }&token=${payload.token}&provider_type=${payload.provider_type}${
            uuid && team_id && `&uuid=${uuid}&team_id=${team_id}`
          }`
        );
      } else {
        showToast(false, "Invitation email and social emails do not match");
        setLoading(false);
        return;
      }
    } else {
      navigate(
        `/socialSignup?first_name=${payload.first_name}&last_name=${
          payload.last_name
        }&socialEmail=${payload.email}&is_team_owner=${
          key === "individual" ? false : true
        }&token=${payload.token}&provider_type=${payload.provider_type}`
      );
    }

    setLoading(false);
  };

  const responseFacebook = async (response) => {
    // check error
    if (response?.error?.message) {
      showToast(false, response.error.message);
      return;
    }

    if (!response.id) {
      showToast(false, "Authentication faild");
      logoutFbApi();
      return;
    }

    setLoading(true);
    let payload = response;

    const email = response.email || response.emails?.[0]?.value || "";
    const birthday = response.birthday || "";
    const gender = response.gender || "";
    const profileLink = response.link || "";

    if (response?.name) {
      let dataName = handleFaceBookName(response?.name);
      if (dataName.length > 0) {
        payload.first_name = dataName["0"];
        payload.last_name = dataName?.["1"];
        payload.email = dataName?.["2"] || email;
      }
    }
    payload.gender = gender;
    payload.token = payload.id;
    payload.provider_type = "facebook";

    delete payload.id;

    if (uuid && team_id) {
      payload.uuid = uuid;
      payload.team_id = team_id;
    }

    if (uuid && team_id) {
      if (!payload.email.length > 0) {
        navigate(
          `/socialSignup?first_name=${payload.first_name.replace(
            /\s/g,
            ""
          )}&last_name=${payload.last_name.replace(
            /\s/g,
            ""
          )}&socialEmail=${email}&is_team_owner=${
            key === "individual" ? false : true
          }&token=${payload.token}&provider_type=${payload.provider_type}${
            uuid && team_id && `&uuid=${uuid}&team_id=${team_id}`
          }`
        );
      } else if (payload.email === email) {
        navigate(
          `/socialSignup?first_name=${payload.first_name.replace(
            /\s/g,
            ""
          )}&last_name=${payload.last_name.replace(/\s/g, "")}&socialEmail=${
            payload.email
          }&is_team_owner=${key === "individual" ? false : true}&token=${
            payload.token
          }&provider_type=${payload.provider_type}${
            uuid && team_id && `&uuid=${uuid}&team_id=${team_id}`
          }`
        );
      } else {
        showToast(false, "Invitation email and social emails do not match");
        setLoading(false);
        return;
      }
    } else {
      navigate(
        `/socialSignup?first_name=${payload.first_name.replace(
          /\s/g,
          ""
        )}&last_name=${payload.last_name.replace(/\s/g, "")}&socialEmail=${
          payload.email
        }&is_team_owner=${key === "individual" ? false : true}&token=${
          payload.token
        }&provider_type=${payload.provider_type}`
      );
    }
    setLoading(false);
  };

  const handleFaceBookName = (str) => {
    if (str) {
      let lastString = str.trim().split(" ").slice(-1);
      if (lastString.length > 0) {
        let firstName = str.replace(lastString["0"], "");
        let nameArr = [];
        nameArr.push(firstName, lastString["0"]);
        return nameArr;
      }
    }
    return [];
  };

  return (
    <>
      <section className={classes.page}>
        {!loading ? (
          <div className="container">
            <header>
              <nav className={classes.navbar}>
                <ul className="d-flex justify-content-between align-items-center">
                  <li>
                    <Link to="#">
                      <img src={logo} alt="logo" className={classes.logo} />
                    </Link>
                  </li>
                  <ul className={classes.list}>
                    <li className="">
                      <Link to="/signup">
                        <button className={classes.signBtn}>Sign Up</button>
                      </Link>
                    </li>
                    <li>
                      <Link to="/login">
                        <button className={classes.signBtn}>Sign in</button>
                      </Link>
                    </li>
                  </ul>
                </ul>
              </nav>
            </header>

            <div className={classes.accountWrapper}>
              <div className={classes.headingsWrapper}>
                <h1 className={classes.heading}>Create account</h1>
                <h4 className={classes.subHeading}>Create your free account</h4>
              </div>
              <Tabs
                defaultActiveKey="individual"
                id="uncontrolled-tab-example"
                className="customSignuUpPageTabs"
                transition={false}
                activeKey={key}
                onSelect={(k) => setKey(k)}
              >
                <Tab eventKey="individual" title="As an Individual">
                  <div className={classes.createAccountBox}>
                    <form>
                      <div className={classes.nameInputsWrapper}>
                        <div className="loginInput">
                          <FormInput
                            // label="First Name"
                            type="text"
                            placeholder="First Name"
                            name="first_name"
                            value={user.first_name}
                            handleChange={handleChange}
                            error={errors?.first_name}
                            icon={personicon}
                            autofocus={true}
                          />
                        </div>
                        <div className="loginInput">
                          <FormInput
                            // label="Last Name"
                            type="text"
                            placeholder="Last Name"
                            name="last_name"
                            value={user.last_name}
                            handleChange={handleChange}
                            error={errors?.last_name}
                            icon={personicon}
                          />
                        </div>
                      </div>
                      <div className={classes.inputsGap}>
                        <div className="loginInput">
                          <FormInput
                            // label="Email"
                            type="text"
                            name="email"
                            placeholder="Email Address"
                            value={user.email}
                            handleChange={handleChange}
                            error={errors?.email}
                            icon={emailicon}
                            disabled={email ? true : false}
                          />
                        </div>
                      </div>

                      <div className={classes.inputsGap}>
                        <div className="loginInput">
                          <FormInput
                            // label="Password"
                            type="password"
                            name="password"
                            placeholder="Password"
                            value={user.password}
                            handleChange={handleChange}
                            error={errors?.password}
                            icon={lockicon}
                          />
                        </div>
                      </div>
                      <div className={classes.inputsGap}>
                        <div className="loginInput">
                          <FormInput
                            // label="Confirm Password"
                            type="password"
                            name="confirm_password"
                            placeholder="Confirm Password"
                            value={user.confirm_password}
                            handleChange={handleChange}
                            error={errors?.confirm_password}
                            icon={lockicon}
                          />
                        </div>
                      </div>

                      <div className={classes.submitBtnWrapper}>
                        <button
                          className={`${classes.submitBtn} submitBtn`}
                          type="submit"
                          onClick={handleSubmit}
                        >
                          Create account
                        </button>
                      </div>
                    </form>
                    <h6 className={classes.moreSignOptions}>OR Sign up with</h6>

                    <ul className={classes.socialIconsWrapper}>
                      <li
                        className={classes.iconAvatar}
                        onClick={() => {
                          loginButton();
                        }}
                      >
                        <button>
                          <img src={google} alt="" />
                        </button>
                      </li>
                      {/* <li className={classes.iconAvatar}>
                    <Link to="#">
                      <img src={facebook} alt="" />
                    </Link>
                  </li> */}

                      <FacebookLogin
                        // appId="3507512309478815"
                        appId="956908413125623"
                        //appId="292102873859116" // for testing
                        autoLoad={false}
                        fields="name,email,birthday,picture,gender,link"
                        scope="public_profile,email,user_birthday,user_gender,user_link"
                        callback={responseFacebook}
                        render={(renderProps) => (
                          <li
                            className={classes.iconAvatar}
                            onClick={renderProps.onClick}
                          >
                            <button>
                              <img src={facebook} alt="" />
                            </button>
                          </li>
                        )}
                      />

                      {/* <li className={classes.iconAvatar}>
                    <Link to="#">
                      <img src={insta} alt="" />
                    </Link>
                  </li> */}
                    </ul>

                    <div
                      className={`${classes.notAccountWrapper} notAccountWrapper`}
                    >
                      <p>Already have an account?</p>
                      <Link to="/login">Sign in</Link>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="teamowner" title="As a Team Owner">
                  <div className={classes.createAccountBox}>
                    <form>
                      <div className={classes.teamNameinputGap}>
                        <div className="loginInput">
                          <FormInput
                            // label="Email"
                            type="text"
                            name="team_name"
                            placeholder="Team Name"
                            value={teamOwner.team_name}
                            handleChange={handleChange}
                            error={errors?.team_name}
                            icon={personicon}
                            autofocus={true}
                          />
                        </div>
                      </div>
                      <div className={classes.nameInputsWrapper}>
                        <div className="loginInput">
                          <FormInput
                            // label="First Name"
                            type="text"
                            placeholder="First Name"
                            name="first_name"
                            value={teamOwner.first_name}
                            handleChange={handleChange}
                            error={errors?.first_name}
                            icon={personicon}
                          />
                        </div>
                        <div className="loginInput">
                          <FormInput
                            // label="Last Name"
                            type="text"
                            placeholder="Last Name"
                            name="last_name"
                            value={teamOwner.last_name}
                            handleChange={handleChange}
                            error={errors?.last_name}
                            icon={personicon}
                          />
                        </div>
                      </div>
                      <div className={classes.inputsGap}>
                        <div className="loginInput">
                          <FormInput
                            // label="Email"
                            type="text"
                            name="email"
                            placeholder="Email Address"
                            value={teamOwner.email}
                            handleChange={handleChange}
                            error={errors?.email}
                            icon={emailicon}
                          />
                        </div>
                      </div>

                      <div className={classes.inputsGap}>
                        <div className="loginInput">
                          <FormInput
                            // label="Password"
                            type="password"
                            name="password"
                            placeholder="Password"
                            value={teamOwner.password}
                            handleChange={handleChange}
                            error={errors?.password}
                            icon={lockicon}
                          />
                        </div>
                      </div>
                      <div className={classes.inputsGap}>
                        <div className="loginInput">
                          <FormInput
                            // label="Confirm Password"
                            type="password"
                            name="confirm_password"
                            placeholder="Confirm Password"
                            value={teamOwner.confirm_password}
                            handleChange={handleChange}
                            error={errors?.confirm_password}
                            icon={lockicon}
                          />
                        </div>
                      </div>

                      <div className={classes.submitBtnWrapper}>
                        <button
                          className={`${classes.submitBtn} submitBtn`}
                          type="submit"
                          onClick={handleSubmit}
                        >
                          Continue
                        </button>
                      </div>
                    </form>
                    <h6 className={classes.moreSignOptions}>OR Sign up with</h6>

                    <ul className={classes.socialIconsWrapper}>
                      <li
                        className={classes.iconAvatar}
                        onClick={() => {
                          loginButton();
                        }}
                      >
                        <button>
                          <img src={google} alt="" />
                        </button>
                      </li>
                      {/* <li className={classes.iconAvatar}>
                    <Link to="#">
                      <img src={facebook} alt="" />
                    </Link>
                  </li> */}

                      <FacebookLogin
                        // appId="3507512309478815"
                        appId="956908413125623"
                        //appId="292102873859116" // for testing
                        autoLoad={false}
                        fields="name,email,birthday,picture,gender,link"
                        scope="public_profile,email,user_birthday,user_gender,user_link"
                        callback={responseFacebook}
                        render={(renderProps) => (
                          <li
                            className={classes.iconAvatar}
                            onClick={renderProps.onClick}
                          >
                            <button>
                              <img src={facebook} alt="" />
                            </button>
                          </li>
                        )}
                      />

                      {/* <li className={classes.iconAvatar}>
                    <Link to="#">
                      <img src={insta} alt="" />
                    </Link>
                  </li> */}
                    </ul>

                    <div
                      className={`${classes.notAccountWrapper} notAccountWrapper`}
                    >
                      <p>Already have an account?</p>
                      <Link to="/login">Sign in</Link>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </section>

      <AuthModal
        show={showAuthModal}
        handleCloseModal={handleCloseModal}
        isTeamOwner={isTeamOwner}
        setSocialTeamOwner={setSocialTeamOwner}
        socialTeamOwner={socialTeamOwner}
      />
      <style type="text/css">
        {`
          html, body{
            background: #1e1e1e;
          }
          .loginInput .globalInputWithIcon, .loginInput .globalInputWithIcon.input-group:focus-within {
            border-color: #333e56;
          }
          .loginInput .globalInputWithIcon .form-control, 
          .loginInput .globalInputWithIcon .input-group-text {
            background: #21293b;
            color:#fff;
          }

          .loginInput .globalInputWithIcon .form-control::placeholder, .loginInput .globalInputWithIcon .input-group-text {
            color: #fff;
            opacity: 0.5;
          }
          .loginInput input:-webkit-autofill, .loginInput input:-webkit-autofill:hover, 
          .loginInput input:-webkit-autofill:focus, .loginInput input:-webkit-autofill:active{
            -webkit-box-shadow: 0 0 0 30px #21293b inset !important;
             -webkit-text-fill-color: #fff !important;
          }
          .customSignuUpPageTabs button{
            background: #21293b !important;
            color: #fff !important
          }
          .customSignuUpPageTabs .active{
            background: #22a081 !important;
            color: #fff !important
          }
          .submitBtn{
            background: #22a081 !important;
            color: #fff !important
          }
          .notAccountWrapper a{
            color: #22a081;
          }
        `}
      </style>
    </>
  );
};

export default Signup;
