import baseUrl from "./baseUrl";

const API_BASE = baseUrl.BASE_URL;

const AUTH_PATH = "auth/";
const CONTACT_PATH = "contact";
const EMERGENCY_CONTACT_PATH = "emergencyContact";
const SETTING_PATH = "setting";

const VENMO_PATH = "venmo";
const CASH_APP_PATH = "cashApp";
const CUSTOME_URL_PATH = "customUrl";
const File_Upload_PATH = "fileUpload";
const Card_URL_Path = "card";
const GENERAL = "general";
const EMERGENCY_CONTACT_VCF_FILE = "general/createVCFFile/emergencyContact";
const MEDICAL_ID_VCF_FILE = "general/createVCFFile/medical_id";
const CARD_VCF_FILE = "general/createVCFFile/card";
const COUPON_PATH = "coupon";
const SUBSCRIPTIONS_PATH = "subscriptions";

export const LOGIN = API_BASE + AUTH_PATH + "login";
export const ALREADY_MEMBER = API_BASE + AUTH_PATH + "alreadyMember";
export const SOCIAL_LOGIN = API_BASE + AUTH_PATH + "socialLogin";
export const SOCIAL_SIGNUP = API_BASE + AUTH_PATH + "socialSignup";
export const LOGOUT = API_BASE + AUTH_PATH + "logout";
export const FORGET_PASSWORD = API_BASE + AUTH_PATH + "forgotPassword";
export const CHECK_OTP = API_BASE + AUTH_PATH + "checkOTP";
export const RESET_PASSWORD = API_BASE + AUTH_PATH + "resetPassword";
export const GET_USER = API_BASE + AUTH_PATH + "getUser";
export const GET_USER_SUBSCRIPTION = API_BASE + "subscriptions";
export const GET_USER_ACTION = API_BASE + AUTH_PATH + "getUserAction";
export const UPDATE_USER = API_BASE + AUTH_PATH + "updateUser";
export const DELETE_USER_ACCOUNT = API_BASE + GENERAL + "/deleteUserAccount";
export const DELETE_OWNER_ACCOUNT = API_BASE + GENERAL + "/deleteOwnerAccount";

export const SIGNUP = API_BASE + AUTH_PATH + "register";
export const CREATE_USER_TEAM_OWNER_ACCOUNT =
  API_BASE + AUTH_PATH + "createUserTeamOwnerAccount";

export const TEAM_OWNER_SIGNUP = API_BASE + AUTH_PATH + "registerTeamOwner";
export const HIERARCHY_TREE = API_BASE + "public/tree/getHierarchyData";
export const NODE_SCAN = API_BASE + "public/tree/nodeScan";
export const PUBLIC_TEAM_MEMBER_FUNCTIONS =
  API_BASE + "public/allPublicTeamMemberFunction";
export const CREATE_NODE = API_BASE + "tree/createNode";
export const UPDATE_NODE = API_BASE + "tree/updateNodeName";
export const REASSIGN_NODE = API_BASE + "tree/reassignNode";
export const DELETE_NODE = API_BASE + "tree/deleteNode";
export const ATTACH_MEMBER = API_BASE + "tree/attachMemberNode";
export const REMOVE_MEMBER = API_BASE + "tree/removeMemberNode";
export const TEAM_STATS = API_BASE + "general/getTeamStats";
export const USER_STATS = API_BASE + "general/getUserStats";

export const CHANGE_MEMBER_PARENT = API_BASE + "tree/changeMemberParent";
export const GET_MEMBER_FUNCTIONS = API_BASE + "tree/getMemberFunctions";
export const MANAGE_MEMEBER_ACTIVE_FUNCTION =
  API_BASE + "tree/manageHierarchyMemberFunction";
export const REMOVE_ACTIVE_FUNCTION =
  API_BASE + "tree/removeHierarchyMemberFunction/";

export const INVITATION = API_BASE + "invitation";
export const SEND_INVITATION = API_BASE + "invitation/sendInvitation";
export const RESEND_INVITATION = API_BASE + "invitation/reSendInvitation";
export const ALLTEAMOWNERS = API_BASE + "admin/team/getAllTeamOwner";
export const TEAMDETAIL = API_BASE + "team";
export const TEAM_MEMBER_STATUS = API_BASE + "team/TeamMember/status";
export const ADD_TEAM_MEMBER = API_BASE + "team/TeamMember";
export const PENDING_MEMBER_PAYMENT =
  API_BASE + "team/getPendingTeamMemberPayment";
export const PAY_MEMBER_PAYMENT = API_BASE + "team/payMemberPayment";
export const ASSIGN_MEMBER_ROLE = API_BASE + "team/TeamMember/assignMemberRole";

export const CONTACT = API_BASE + CONTACT_PATH;
export const EXPORT_CONTACT = API_BASE + "export/contact";
export const ADMIN_USERS = API_BASE + "userAdmin";
export const SETTING = API_BASE + SETTING_PATH;
export const PAGES = API_BASE + SETTING_PATH + "/page";

export const VENMO_URL = API_BASE + VENMO_PATH;
export const CASH_APP_URL = API_BASE + CASH_APP_PATH;
export const CUSTOME_URL = API_BASE + CUSTOME_URL_PATH;
export const FILE_UPLOAD = API_BASE + File_Upload_PATH;
export const MEMORY = API_BASE + "memory";
export const Card_URL = API_BASE + Card_URL_Path;
export const DUPLICATE_Card_URL = API_BASE + Card_URL_Path + "/duplicate";
export const FUNCTION_STATUS = API_BASE + GENERAL + "/changeStatus";
export const CUSTOMIZE_QR_CODE = API_BASE + Card_URL_Path + "/qr/customize";
export const CUSTOMIZE_QRCODE = API_BASE + "qrcode/customize";
export const DYNAMIC_QRCODE = API_BASE + "dynamicQRCode";

export const EMERGENCY_CONTACT = API_BASE + EMERGENCY_CONTACT_PATH;
export const USER_EMERGENCY_CONTACT = API_BASE + EMERGENCY_CONTACT_PATH;
export const MEDICAL_ID = API_BASE + "medicalId";
export const HANDLE_FUNCTION_STATUS =
  API_BASE + GENERAL + "/changeFunctionStatus";

export const HANDLE_STATUS = API_BASE + GENERAL + "changeStatus";
export const USER = API_BASE + "admin/user";
export const DEVICETYPEUSERCOUNT = API_BASE + "admin/userCountByDeviceType";
export const CHANGE_USER_QRCODE = API_BASE + "admin/user/changeProfileQRCode";
export const GENERATE_QRCODE = API_BASE + "admin/adminQrCodes";
export const PRICING = API_BASE + SETTING_PATH + "/pricing";
export const TUTORIAL = API_BASE + SETTING_PATH + "/tutorial";
export const CONTACT_US = API_BASE + SETTING_PATH + "/contactUs";
export const HOME_DASHBOARD = API_BASE + GENERAL + "/homeDashboard";
export const IMAGE_UPLOAD = API_BASE + GENERAL + "/imageUpload";
export const DOCUMENT_UPLOAD = API_BASE + GENERAL + "/documentUpload";
export const NOTIFICATION_LIST = API_BASE + GENERAL + "/getPushNotification";
export const DELETE_NOTIFICATION =
  API_BASE + GENERAL + "/deletePushNotification";
export const SEND_NOTIFICATION = API_BASE + GENERAL + "/sendPushNotification";
export const USER_NOTIFICATION = API_BASE + GENERAL + "/userNotification";
export const DELETE_USER_NOTIFICATION =
  API_BASE + GENERAL + "/deleteUserNotification";
export const THEME_GET = API_BASE + SETTING_PATH + "/userNotification";
export const THEME_CHANGE = API_BASE + SETTING_PATH + "/userTheme";
export const APP_SETTING = API_BASE + "appSetting";
export const SHARE_CARD = API_BASE + "share/card";
export const SHARE_FUNCTIONS = API_BASE + "share/";
export const SHARE_CONTACT = API_BASE + "share/contact";
export const SHARE_EMERGENCY_CONTACT = API_BASE + "share/emergencyContact";
export const SHARE_PROFILE = API_BASE + "share/profile";
export const DELETE_USER_NFCS = API_BASE + "userNfc/deleteAllUserNfc";
export const USERNFC = API_BASE + "userNfc";
export const PAYMENT = API_BASE + "payment";
export const TRANSACTION = API_BASE + "transaction";
export const HOMEDASHBOARD = API_BASE + GENERAL + "/homeDashboardV1";
export const EMERGENCY_CONTACT_VCF_FILE_URL =
  API_BASE + EMERGENCY_CONTACT_VCF_FILE;
export const MEDICAL_ID_VCF_FILE_URL = API_BASE + MEDICAL_ID_VCF_FILE;
export const PACKAGES_LISTING = API_BASE + "purchase/getAllPackages";
export const PACKAGE = API_BASE + "purchase";
export const SUBSCRIPTIONS_PACKAGES = API_BASE + SUBSCRIPTIONS_PATH;
export const PACKAGE_CREATE = API_BASE + SUBSCRIPTIONS_PATH + "/create";
export const PACKAGES_REMOVE = API_BASE + SUBSCRIPTIONS_PATH + "/remove";
export const PACKAGES_STATUS = API_BASE + SUBSCRIPTIONS_PATH + "/update-status";
export const PACKAGES_UPDATE = API_BASE + SUBSCRIPTIONS_PATH + "/update";
export const PACKAGES_DETAILS = API_BASE + SUBSCRIPTIONS_PATH + "/details";
export const ACTIVE_SUBSCRIPTIONS_PACKAGES =
  API_BASE + "admin/getAllUsersSubscriptions";
export const ENTERPRISE_CONTACT_US = API_BASE + "purchase/packageContactForm";
export const CARD_VCF_FILE_URL = API_BASE + CARD_VCF_FILE;

export const COUPON = API_BASE + COUPON_PATH;
export const COUPON_CREATE = API_BASE + COUPON_PATH + "/create";
export const COUPON_UPDATE = API_BASE + COUPON_PATH + "/update";
export const COUPON_DETAILS = API_BASE + COUPON_PATH + "/details";
export const COUPON_DELETE = API_BASE + COUPON_PATH + "/remove";
export const COUPON_APPLY = API_BASE + "transaction/applyCoupon";
export const SYSTEM_GENERATE_COUPON =
  API_BASE + COUPON_PATH + "/generateCouponCode";
export const EXPORT_COUPON = API_BASE + COUPON_PATH + "/export";

export const FUNCTION_DELETE =
  API_BASE + GENERAL + "/deleteFreePackageFunction";

export const FORM_BUILDER = API_BASE + "formBuilder";
export const LEADS = API_BASE + "lead";
export const LEAD_ADD = API_BASE + "lead/add";
export const EXPORT_LEAD = API_BASE + "export/lead";
export const IMPORT_LEAD = API_BASE + "import/lead";
export const PRIORITY = API_BASE + "user-function-settings";
export const CREATE_EMAIL_TEMPLATE = API_BASE + GENERAL + "/Template";
export const EMAIL_TEMPLATE = API_BASE + GENERAL + "/get-email-template";
export const SEND_EMAIL_TEMPLATE = API_BASE + "emailSetting";

export const PRODUCT_DISCOUNT = API_BASE + "product-discount";
export const PRODUCT_DISCOUNT_LINK = API_BASE + "product-discount-link";
export const ALL_PRODUCT_DISCOUNT_LINK = API_BASE + "get-product-discount-link";
export const CHECKOUT_PRODUCT_DETAILS =
  API_BASE + GENERAL + "/get-subscription-product-details";
export const CHECKOUT_EMAIL_EXISTS = API_BASE + "check-user-package-type";
export const CHECKOUT_SUBSCRIPTION =
  API_BASE + "transaction/individualUserSubscription";
export const CHECKOUT_USER_STATUS_UPDATE = API_BASE + "user/update";
export const CANCEL_SUBSCRIPTION = API_BASE + "transaction/cancel-subscription";
