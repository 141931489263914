import React, { useState, useEffect, useMemo } from "react";
import classes from "../../assets/styles/Pages/Function/CustomURLSListing.module.css";
import Loader from "../../components/Loader";
import { useAtom } from "jotai";
import { get, deleteRecord, post, put } from "../../server";
import { MEDICAL_ID, FUNCTION_STATUS } from "../../utils/constants/apiUrl";
import {
  loggedInUser,
  teamIDCheck,
  subscribedPackage,
} from "../../utils/store";
import { toast } from "react-toastify";
import showToast from "../../helpers/showToast";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import FormInput from "../../components/Forms/FormInput";
import searchIcon from "../../assets/images/global_icons/searchicon.svg";
import _ from "lodash";
import uploadImage from "../../helpers/uploadImage";
import EmptyListingBanner from "../../components/EmptyListingBanner";
import useFunctionsCount from "../../hooks/useFunctionsCount";
import functionTypes from "../../utils/constants/functionTypes";
import { FaCirclePlus } from "react-icons/fa6";
import FunctionsLimitModal from "../../components/Modals/FunctionsLimitModal";
import MedicalAddEditModal from "../../components/Modals/MedicalAddEditModal";
import ShowMedicalIDFunctions from "../../components/PageComponents/Function/ShowMedicalIDFunctions";

const MedicalListing = () => {
  let emptyMedicalIDData = {
    //isNew: true,
    id: "",
    first_name: "",
    last_name: "",
    dob: "",
    primary_language: "",
    blood_type: "",
    weight: "",
    height: "",
    medical_condition: "",
    allergies_reactions: "",
    medical_notes: "",
    medications: "",
    photo: "",
    medicalIdEmergencyContacts: [
      { person_name: "", relation: "", country_phone: "", phone_number: "" },
    ],
  };

  const navigate = useNavigate();
  const { teamId, userId } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const team_id = searchParams.get("team_id");
  const user_id = searchParams.get("user_id");
  const dynamicQRCodeId = searchParams.get("dynamic_qr_code");

  const [loading, setLoading] = useState(true);
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [userTeamId, setUserTeamId] = useAtom(teamIDCheck);
  const [medicalID, setMedicalID] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState(emptyMedicalIDData);
  const [filterText, setFilterText] = useState("");
  const {
    compareLimits,
    updateCount,
    limitExceededForStarter,
    compareLimitsForDynamicQR,
  } = useFunctionsCount();
  const [showLimitModal, setShowLimitModal] = useState(false);
  const [purchasedPackage, setPurchasedPackage] = useAtom(subscribedPackage);

  const handleFilter = (e) => {
    setFilterText(e.target.value);
  };

  // Handle main modal
  const handleShowModal = (obj) => {
    let newObj = _.cloneDeep(obj);

    if (newObj && Object.keys(newObj).length) {
      if (newObj.phone_number) {
        newObj.phone_number = `${
          newObj.country_phone ? newObj.country_phone : ""
        }${newObj.phone_number}`;
      }

      if (newObj.country_phone) {
        newObj.country_phone = `+${newObj.country_phone}`;
      }

      setSelectedData(newObj);
    }

    setShowModal(true);
  };

  const handleShowMedicalIDModal = async () => {
    if (dynamicQRCodeId) {
      const isLimitExceeded = compareLimitsForDynamicQR(
        functionTypes.MEDICAL_ID,
        dynamicQRCodeId
      );
      if (isLimitExceeded) {
        return showToast(
          false,
          "The requested feature is not compatible with your current plan. Upgrade your plan to continue using these features"
        );
      } else {
        setShowModal(true);
      }
    } else if (purchasedPackage.id === 1) {
      limitExceededForStarter()
        .then((isLimitExceeded) => {
          if (isLimitExceeded) {
            setShowLimitModal(true);
          } else {
            setShowModal(true);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      const isLimitExceeded = compareLimits(functionTypes.MEDICAL_ID);
      if (isLimitExceeded) {
        return showToast(
          false,
          "The requested feature is not compatible with your current plan. Upgrade your plan to continue using these features"
        );
      } else {
        setShowModal(true);
      }
    }
  };
  const handlePhoneNumber = (payload) => {
    // Process each emergency contact in the array
    payload.medicalIdEmergencyContacts = payload.medicalIdEmergencyContacts.map(
      (contact) => {
        // Format phone_number and country_phone
        if (contact.phone_number) {
          contact.phone_number = contact.phone_number.trim();
        }

        if (contact.country_phone) {
          contact.country_phone = contact.country_phone.trim();
        }

        return contact;
      }
    );
  };

  const handleSaveShowModal = async (newData) => {
    setLoading(true);

    let payload = _.cloneDeep(newData);
    handlePhoneNumber(payload);

    if (payload.photo && typeof payload.photo === "object") {
      const { status, message, data } = await uploadImage(
        "medical_id",
        payload.photo,
        loggedUser?.token
      );

      if (status) {
        payload.photo = data;
      } else {
        showToast(status, message);
        setLoading(false);
        return;
      }
    }

    payload.dynamic_qr_code_id = dynamicQRCodeId;
    let result = {};
    if (selectedData.id) {
      result = await put(
        MEDICAL_ID + `/${selectedData.id}`,
        payload,
        loggedUser?.token || null
      );
    } else {
      if (teamId && userId) {
        payload.team_id = teamId;
        payload.user_id = userId;
      }
      if (team_id && user_id) {
        payload.team_id = team_id;
        payload.user_id = user_id;
      }
      if (userTeamId) {
        payload.team_id = userTeamId;
      }
      result = await post(MEDICAL_ID, payload, loggedUser?.token || null);
    }

    const { status, message, data } = result;

    if (status) {
      setLoading(false);

      let medicalIDArr = _.cloneDeep(medicalID);

      // On Update
      if (!selectedData.id) {
        updateCount(
          functionTypes.MEDICAL_ID,
          functionTypes.ADD,
          dynamicQRCodeId
        );
      }
      if (selectedData.id) {
        const index = medicalID.findIndex((el) => el.id == selectedData.id);
        medicalIDArr[index] = data;
      } else {
        medicalIDArr.unshift(data);
      }

      setMedicalID(medicalIDArr);
      handleCloseModal();
    } else {
      setLoading(false);
    }
    showToast(status, message);
  };

  const handleCloseModal = () => {
    setSelectedData(emptyMedicalIDData);
    setShowModal(false);
    setShowLimitModal(false);
  };

  // Handle confirmation modal
  const handleShowConfirmationModal = () => setShowConfirmationModal(true);
  const handleCloseConfirmationModal = () => setShowConfirmationModal(false);

  // handle delete
  const handleDelete = (id) => {
    setDeleteId(id);
    handleShowConfirmationModal(true);
  };

  const handleConfirmDelete = async () => {
    handleCloseConfirmationModal(true);

    setLoading(true);
    let queryParams = "";

    if (dynamicQRCodeId) {
      queryParams += `?dynamic_qr_code_id=${dynamicQRCodeId}`;
    }

    if (teamId || team_id || userTeamId) {
      queryParams += `${queryParams ? "&" : "?"}team_id=${
        teamId || team_id || userTeamId
      }`;
    }

    // if (teamId || team_id || userTeamId) {
    //   queryParams = `?team_id=${teamId || team_id || userTeamId}`;
    // }
    let { status, message } = await deleteRecord(
      `${MEDICAL_ID}/${deleteId}${queryParams}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    showToast(status, message);

    if (status) {
      await getMedicalIdList();
      updateCount(
        functionTypes.MEDICAL_ID,
        functionTypes.DELETE,
        dynamicQRCodeId
      );
      setLoading(false);
    }

    setLoading(false);
  };

  // Get all medical id
  const getMedicalIdList = async () => {
    let queryParams = "";

    if (dynamicQRCodeId) {
      queryParams += `?dynamic_qr_code_id=${dynamicQRCodeId}`;
    }

    if (teamId || team_id || userTeamId) {
      queryParams += `${queryParams ? "&" : "?"}team_id=${
        teamId || team_id || userTeamId
      }`;
    }

    // if (teamId || team_id || userTeamId) {
    //   queryParams = `?team_id=${teamId || team_id || userTeamId}`;
    // }

    if (userId || user_id) {
      queryParams += `${queryParams ? "&" : "?"}user_id=${userId || user_id}`;
    }

    let { status, message, data } = await get(
      `${MEDICAL_ID}${queryParams}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      setMedicalID(data);
    } else {
      toast.error(message);
    }
    setLoading(false);
  };

  const handleStatus = async (id) => {
    const allMedicalID = medicalID.find((medicalId) => medicalId.id === id);
    let newStatus = allMedicalID.status == true ? 0 : 1;
    let queryParams = "";

    if (dynamicQRCodeId) {
      queryParams += `?dynamic_qr_code_id=${dynamicQRCodeId}`;
    }
    const payload = {
      id: allMedicalID.id,
      status: newStatus,
      type: allMedicalID.function_type,
      team_id: allMedicalID.team_id,
    };
    let { status, message } = await post(
      `${FUNCTION_STATUS}${queryParams}`,
      payload,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      await getMedicalIdList();
    } else {
      toast.error(message);
    }
    setLoading(false);
  };

  useEffect(() => {
    getMedicalIdList();
  }, [userTeamId]);

  const filteredItems = useMemo(() => {
    const filtered = medicalID.filter(
      (item) =>
        `${item.first_name || ""} ${item.last_name || ""}`
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
        `${item.country_phone ? `+${item.country_phone}` : ""}${
          item.phone_number || ""
        }`.includes(filterText)
    );

    return filtered;
  }, [filterText, medicalID]);

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="">
          <div className={classes.mainWrapper}>
            <h6 className={classes.title}>
              <Link
                onClick={handleGoBack}
                style={{ color: "var(--heading_text_color)" }}
              >
                Functions
              </Link>
              {` > Medical ID`}
            </h6>

            <div className="d-flex align-items-center justify-content-center">
              {medicalID.length !== 0 ? (
                <FormInput
                  icon={searchIcon}
                  placeholder="Search..."
                  value={filterText}
                  handleChange={handleFilter}
                />
              ) : null}
              {dynamicQRCodeId !== null ? (
                <Link className="primary_button ms-4" to={`/qrcode`}>
                  {`< Back`}
                </Link>
              ) : (
                ""
              )}
            </div>
          </div>

          {medicalID.length === 0 ? (
            <EmptyListingBanner
              headLineText="Medical ID"
              title="Add Medical ID"
              onClick={handleShowMedicalIDModal}
              showAdd={
                loggedUser.role.includes("customer") ||
                loggedUser.role.includes("team_owner")
              }
            />
          ) : (
            <div className={classes.customURLSWrapper}>
              <div>
                <div className={classes.addWrapper}>
                  {(Object.keys(loggedUser).length !== 0 &&
                    loggedUser.role.includes("customer")) ||
                  loggedUser.role.includes("team_owner") ? (
                    <span onClick={handleShowMedicalIDModal}>
                      <div className={classes.addContentWrapper}>
                        {/* <img src={addcontact} alt="" /> */}
                        <FaCirclePlus />
                        <p>Add Medical ID</p>
                      </div>
                    </span>
                  ) : null}
                </div>
                <ShowMedicalIDFunctions
                  loading={loading}
                  show={showModal}
                  medicalID={filteredItems}
                  handleShowModal={handleShowModal}
                  handleCloseModal={handleCloseModal}
                  handleDelete={handleDelete}
                  teamId={teamId}
                  userId={userId}
                  team_id={team_id}
                  user_id={user_id}
                  handleStatus={handleStatus}
                  getMedicalIdList={getMedicalIdList}
                  dynamicQRCodeId={dynamicQRCodeId}
                />
              </div>
            </div>
          )}

          {/* Medical Id modal */}
          {showModal && (
            <MedicalAddEditModal
              show={showModal}
              data={selectedData}
              setSelectedData={setSelectedData}
              handleSaveShowModal={handleSaveShowModal}
              handleCloseModal={handleCloseModal}
            />
          )}
        </div>
      )}

      {/* confirmation modal */}

      <ConfirmationModal
        show={showConfirmationModal}
        handleCloseModal={handleCloseConfirmationModal}
        handleConfirmDelete={handleConfirmDelete}
        confirmTitle="Medical ID"
      />
      <FunctionsLimitModal
        show={showLimitModal}
        handleClose={handleCloseModal}
        getData={getMedicalIdList}
      />
    </>
  );
};

export default MedicalListing;
