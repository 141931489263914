import { useState, useRef } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import classes from "../../../assets/styles/PageComponentsStyles/Home/ShowCustomURLS.module.css";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import html2canvas from "html2canvas";
import { Image } from "react-bootstrap";
import PaymentAddEditModal from "../../Modals/PaymentAddEditModal";
import cashapplogo from "../../../assets/images/function_page_icons/cashapplogo.svg";
import venmologo from "../../../assets/images/function_page_icons/venmologo.svg";
import paymentlogo from "../../../assets/images/function_page_icons/paymenticon.svg";
import { useAtom } from "jotai";
import { mobileViewCheck } from "../../../utils/store";
import ShareModal from "../../Modals/ShareModal";
import QRCodeModal from "../../Modals/QRCodeModal";

const ShowPayment = ({
  id,
  title,
  url_path,
  payment_type,
  uuid,
  emptyPaymentData,
  handleSaveShowModal,
  selectedData,
  setSelectedData,
  handlePaymentDelete,
  loading,
  publicMode,
}) => {
  const target = useRef(null);

  const [showCopyPopup, setShowCopyPopup] = useState(false);
  const [qrOpen, setQrOpen] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [mobileView] = useAtom(mobileViewCheck);

  const handleOpenModal = () => {
    let newObj = {
      id,
      title,
      url_path,
      payment_type,
    };

    setSelectedData(newObj);

    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedData(emptyPaymentData);
    setShowModal(false);
  };

  const handleQrOpen = () => {
    setQrOpen(true);
  };

  const handleQRClose = () => {
    setQrOpen(false);
    setShowCopyPopup(false);
  };

  const handleShareOpen = () => {
    setShareOpen(true);
  };

  const handleShareClose = () => {
    setShareOpen(false);
    setShowCopyPopup(false);
  };

  const handleDownload = () => {
    html2canvas(document.querySelector("#qr-code")).then(function (canvas) {
      const link = document.createElement("a");
      link.download = "qrcode.png";
      link.href = canvas.toDataURL();
      link.click();
    });
  };

  return (
    <>
      <div className={classes.linkWrapper}>
        <div className={classes.contentWrapper}>
          {payment_type === "cash_app" ? (
            <Image
              src={cashapplogo}
              width="50px"
              height="50px"
              roundedCircle={true}
            />
          ) : payment_type === "venmo" ? (
            <Image
              src={venmologo}
              width="50px"
              height="50px"
              roundedCircle={true}
            />
          ) : (
            <div
              style={{
                width: "50px",
                height: "50px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "2px solid var(--primary_text_color)",
                borderRadius: "50px",
                background: "#22a081",
              }}
            >
              <Image
                src={paymentlogo}
                width="30px"
                height="30px"
                roundedCircle={true}
              />
            </div>
          )}

          <div>
            <p className={classes.title}>{title}</p>
            <a
              href={url_path}
              target="_blank"
              style={{ display: "block" }}
              className={classes.urlPath}
              rel="noreferrer"
            >
              {url_path}
            </a>
          </div>
        </div>
        <div className={classes.actionsWrapper}>
          <button
            className="btn customActionDropdown dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <BsThreeDotsVertical className={classes.threeDotsIcon} />
          </button>
          {publicMode ? (
            <ul className="customActionDropdownMenu dropdown-menu dropdown-menu-end ">
              <li>
                <a
                  href={url_path}
                  target="_blank"
                  className="dropdown-item"
                  rel="noreferrer"
                >
                  Visit Link
                </a>
              </li>
              <li>
                <span className="dropdown-item" onClick={handleQrOpen}>
                  QR Code
                </span>
              </li>
            </ul>
          ) : (
            <ul className="customActionDropdownMenu dropdown-menu dropdown-menu-end ">
              <li>
                <span className="dropdown-item" onClick={handleQrOpen}>
                  QR Code
                </span>
              </li>
              <li>
                <span className="dropdown-item" onClick={handleOpenModal}>
                  Edit
                </span>
              </li>
              <li>
                <span className="dropdown-item" onClick={handleShareOpen}>
                  Share
                </span>
              </li>
              <li>
                <span
                  className="dropdown-item"
                  style={{ color: "var(--error_color)" }}
                  onClick={() => handlePaymentDelete(id)}
                >
                  Delete
                </span>
              </li>
            </ul>
          )}
        </div>
      </div>

      {showModal && (
        <PaymentAddEditModal
          show={showModal}
          data={selectedData}
          setSelectedData={setSelectedData}
          handleSaveShowModal={async () => {
            await handleSaveShowModal(selectedData);
            handleCloseModal();
          }}
          handleCloseModal={handleCloseModal}
          loading={loading}
        />
      )}

      {qrOpen && (
        <QRCodeModal
          qrOpen={qrOpen}
          target={target}
          handleQRClose={handleQRClose}
          setShowCopyPopup={setShowCopyPopup}
          handleDownload={handleDownload}
          functionType={
            payment_type === "custom_payment"
              ? "customPayment"
              : payment_type === "cash_app"
              ? "cashApp"
              : payment_type
          }
          functionUuid={uuid}
        />
      )}

      {shareOpen && (
        <ShareModal
          target={target}
          shareOpen={shareOpen}
          handleShareClose={handleShareClose}
          setShowCopyPopup={setShowCopyPopup}
          functionType={
            payment_type === "custom_payment"
              ? "customPayment"
              : payment_type === "cash_app"
              ? "cashApp"
              : payment_type
          }
          functionUuid={uuid}
        />
      )}

      <Overlay
        target={target.current}
        show={showCopyPopup}
        placement={mobileView ? "left" : "right"}
      >
        {(props) => (
          <Tooltip id="overlay-example" {...props}>
            Link Copied
          </Tooltip>
        )}
      </Overlay>
    </>
  );
};

export default ShowPayment;
