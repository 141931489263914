import React, { useState, useRef } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import classes from "../../../assets/styles/PageComponentsStyles/Function/ShowCustomURLSFunctions.module.css";
import DataTable, { createTheme } from "react-data-table-component";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import html2canvas from "html2canvas";
import { useAtom } from "jotai";
import { mobileViewCheck } from "../../../utils/store";
import ShareModal from "../../Modals/ShareModal";
import QRCodeModal from "../../Modals/QRCodeModal";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import { loggedInUser } from "../../../utils/store";
import Form from "react-bootstrap/Form";

const ShowFormBuilderFunctions = ({
  FormBuilders,
  handleDelete,
  handleStatus,
  getFormBuilderListing,
  dynamicQRCodeId,
}) => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const target = useRef(null);
  const [loading, setLoading] = useState(false);
  const [showCopyPopup, setShowCopyPopup] = useState(false);
  const [qrOpen, setQrOpen] = useState(false);
  const [shareOpen, setShareOpen] = useState(false);
  const [selectedData, setSelectedData] = useState("");
  const [mobileView] = useAtom(mobileViewCheck);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  let { id } = useParams();
  const handleQrOpen = (obj) => {
    setSelectedData(obj);
    setQrOpen(true);
  };

  const handleQRClose = () => {
    setQrOpen(false);
    setShowCopyPopup(false);
    setSelectedData("");
  };

  const handleShareOpen = (obj) => {
    setSelectedData(obj);
    setShareOpen(true);
  };

  const handleShareClose = () => {
    setShareOpen(false);
    setShowCopyPopup(false);
    setSelectedData("");
  };

  const handleDownload = () => {
    html2canvas(document.querySelector("#qr-code")).then(function (canvas) {
      const link = document.createElement("a");
      link.download = "qrcode.png";
      link.href = canvas.toDataURL();
      link.click();
    });
  };

  createTheme(
    "solarized",
    {
      background: {
        default: "background: var(--secondary_color)",
      },
    },
    "dark"
  );

  const columns = [
    {
      name: "Form Name",
      width: "20%",
      cell: (row) => <p className="globalTitle">{row.name}</p>,
    },
    {
      name: "Form Fields",
      width: "50%",
      cell: (row) => (
        <p
          className="globalTitle"
          style={{ maxWidth: "max-content", whiteSpace: "normal" }}
        >
          {row.formBuilderField
            .map((field) => {
              if (field.type !== "paragraph") {
                return field.label;
              } else {
                return field.type.charAt(0).toUpperCase() + field.type.slice(1);
              }
            })
            .join(", ")}
        </p>
      ),
    },
    {
      name: "Status",
      width: "10%",
      center: "true",
      cell: (row) =>
        row.is_expired === 1 ? (
          <Form.Check
            type="switch"
            checked={row.status}
            onClick={() => handleStatus(row.id, row.function_type)}
            disabled
          />
        ) : (
          <Form.Check
            type="switch"
            checked={row.status}
            onClick={() => handleStatus(row.id, row.function_type)}
          />
        ),
    },
    {
      name: "Action",
      right: "true",
      cell: (row) => (
        <div className={classes.action}>
          <button
            className="btn customActionDropdown dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <BsThreeDotsVertical className={"globalVerticalThreeDots"} />
          </button>
          {row.is_expired === 1 ? (
            <ul className="customActionDropdownMenu dropdown-menu dropdown-menu-end ">
              <li>
                <span
                  className="dropdown-item"
                  to="#"
                  style={{ color: "var(--error_color)", cursor: "pointer" }}
                  onClick={() => handleDelete(row.id)}
                >
                  Delete
                </span>
              </li>
            </ul>
          ) : (
            <ul className="customActionDropdownMenu dropdown-menu dropdown-menu-end ">
              <li>
                <Link
                  className="dropdown-item"
                  to={
                    dynamicQRCodeId !== null
                      ? `/function/formBuilder/${row.id}?dynamic_qr_code=${dynamicQRCodeId}`
                      : `/function/formBuilder/${row.id}`
                  }
                >
                  Edit
                </Link>
              </li>
              <li>
                <span
                  className="dropdown-item"
                  onClick={() => handleStatus(row.id, row.function_type)}
                >
                  {row.status == true ? "Inactive" : "Active"}
                </span>
              </li>
              {dynamicQRCodeId !== null ? (
                ""
              ) : (
                <>
                  <li>
                    <span
                      className="dropdown-item"
                      onClick={() => handleQrOpen(row)}
                    >
                      QR Code
                    </span>
                  </li>
                  <li>
                    <span
                      className="dropdown-item"
                      onClick={() => handleShareOpen(row)}
                    >
                      Share
                    </span>
                  </li>
                </>
              )}
              <li>
                <span
                  className="dropdown-item"
                  to="#"
                  style={{ color: "var(--error_color)", cursor: "pointer" }}
                  onClick={() => handleDelete(row.id)}
                >
                  Delete
                </span>
              </li>
            </ul>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="listingWrapper">
        <DataTable
          columns={columns}
          data={FormBuilders}
          theme="solarized"
          pagination
        />
      </div>

      {qrOpen && (
        <QRCodeModal
          qrOpen={qrOpen}
          target={target}
          handleQRClose={handleQRClose}
          setShowCopyPopup={setShowCopyPopup}
          handleDownload={handleDownload}
          functionType="form_builder"
          functionUuid={selectedData.uuid}
          id={selectedData.id}
          function_type={selectedData.function_type}
          functionId={selectedData.id}
          QRCodeCustomize={selectedData.qrCodeCustomize}
          refreshData={getFormBuilderListing}
        />
      )}

      {shareOpen && (
        <ShareModal
          target={target}
          shareOpen={shareOpen}
          handleShareClose={handleShareClose}
          setShowCopyPopup={setShowCopyPopup}
          functionType="form_builder"
          functionUuid={selectedData.uuid}
        />
      )}

      <Overlay
        target={target.current}
        show={showCopyPopup}
        placement={mobileView ? "left" : "right"}
      >
        {(props) => (
          <Tooltip id="overlay-example" {...props}>
            Link Copied
          </Tooltip>
        )}
      </Overlay>
    </>
  );
};

export default ShowFormBuilderFunctions;
