// function formatPhoneNumber(phoneNumberString) {
//   console.log("phoneNumberString", phoneNumberString);
//   var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
//   console.log("cleaned", cleaned);
//   var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
//   console.log("match", match);
//   if (match) {
//     var intlCode = match[1] ? "1 " : "";
//     return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
//   }
//   return phoneNumberString;
// }
// export default formatPhoneNumber;

function formatPhoneNumber(phoneNumberString) {
  var cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  var cleanedLength = cleaned.length;
  var match;

  if (cleanedLength > 10) {
    // For numbers longer than 10 digits (e.g., with an extension or international format)
    match = cleaned.match(/^(0|)?(\d{3})(\d{3})(\d{3})(\d*)$/);
    if (match) {
      var intlCode = match[1] ? "1 " : "";
      return [
        intlCode,
        "(",
        match[2],
        ") ",
        match[3],
        "-",
        match[4],
        match[5] ? "-" + match[5] : "",
      ].join("");
    }
  } else {
    // For standard 10-digit numbers
    match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      var intlCode = match[1] ? "1 " : "";
      return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
    }
  }

  return phoneNumberString;
}
export default formatPhoneNumber;
