import React, { useEffect, useState } from "react";
import classes from "../../../assets/styles/Pages/Settings/AddNewPage.module.css";
import { post } from "../../../server";
import { SEND_NOTIFICATION } from "../../../utils/constants/apiUrl";
import { loggedInUser } from "../../../utils/store";
import { useAtom } from "jotai";
import Loader from "../../../components/Loader";
import showToast from "../../../helpers/showToast";
import { useParams, useNavigate } from "react-router-dom";
import AddEditPriceSchema from "../../../utils/SchemasValidation/AddEditPriceSchema";
import ValidateSchema from "../../../helpers/ValidateSchema";
import Button from "react-bootstrap/Button";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

let emptyObj = {
  title: "",
  description: "",
  send_now: "",
  datetime: "",
};

const SendNotification = () => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [notification, setNotification] = useState(emptyObj);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  // const handleDateChange = (date) => {
  //   if (date instanceof Date && !isNaN(date)) {
  //     // Format the date to YYYY-mm-dd
  //     const formattedDate = date.toISOString().split("T")[0];
  //     setNotification({ ...notification, datetime: formattedDate });
  //   } else {
  //     // Handle invalid date
  //     setNotification({ ...notification, datetime: null });
  //   }
  // };
  const handleDateChange = (date) => {
    if (date instanceof Date && !isNaN(date)) {
      setNotification({ ...notification, datetime: date });
    } else {
      setNotification({ ...notification, datetime: null });
    }
  };

  // const handleChange = (e) => {
  //   const name = e.target.name;
  //   let value = e.target.value;
  //   if (name === "send_now") {
  //     value = value === "true"; // Convert the string back to a boolean
  //   }
  //   setNotification({ ...notification, [name]: value });
  // };

  const handleChange = (e) => {
    const name = e.target.name;

    let value = e.target.value; // Convert string "true"/"false" to a boolean

    if (name === "send_now") {
      value = value === "true"; // Convert value to boolean
      setNotification({
        ...notification,
        send_now: value,
        datetime: value ? "" : notification.datetime, // Clear datetime if send_now is true
      });
    } else {
      setNotification({ ...notification, [name]: e.target.value });
    }
  };

  // handle Validation
  const validateForm = () => {
    let newErrors = ValidateSchema(notification, AddEditPriceSchema);
    setErrors(newErrors);
    if (Object.keys(newErrors).length) return false;
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setLoading(true);
    let { status, message } = await post(
      SEND_NOTIFICATION,
      notification,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    showToast(status, message);
    if (status) {
      setNotification(emptyObj);
      navigate("/notificationListing");
    }
    setLoading(false);
  };

  return (
    <>
      {!loading ? (
        <div>
          <h6> Send Notifications </h6>
          <div className={`mainWrapper ${classes.outerWrapper}`}>
            <div className="row gx-5 gy-3">
              <div className="col-12">
                <label htmlFor="">Title</label>
                <input
                  type="text"
                  placeholder="Notification Title"
                  name="title"
                  value={notification.title}
                  className={
                    errors?.title ? "globalInput errorInput" : "globalInput"
                  }
                  onChange={handleChange}
                />
                <p className="errorText">{errors?.title || ""}</p>
              </div>
              <div className="col-12">
                <label htmlFor=""> Description </label>
                <textarea
                  name="description"
                  id=""
                  cols="100"
                  rows="5"
                  value={notification.description}
                  placeholder="Add Description"
                  onChange={handleChange}
                  className={
                    errors?.description
                      ? "globalTextArea errorInput"
                      : "globalTextArea"
                  }
                />
                <p className="errorText">{errors?.description || ""}</p>
              </div>
              <div className="col-md-4 col-12">
                <label
                  style={{
                    fontSize: "12px",
                    marginBottom: "10px",
                    lineHeight: "18px",
                  }}
                >
                  Date & Time
                </label>
                <div className="row">
                  <div className="col-md-6">
                    <label>
                      <input
                        type="radio"
                        name="send_now"
                        value="true"
                        checked={notification.send_now === true}
                        onChange={handleChange}
                      />{" "}
                      <span
                        style={{
                          fontSize: "12px",
                          marginBottom: "10px",
                          lineHeight: "18px",
                        }}
                      >
                        Send Now
                      </span>
                    </label>
                  </div>
                  <div className="col-md-6">
                    <label>
                      <input
                        type="radio"
                        name="send_now"
                        value="false"
                        checked={notification.send_now === false}
                        onChange={handleChange}
                      />{" "}
                      <span
                        style={{
                          fontSize: "12px",
                          marginBottom: "10px",
                          lineHeight: "18px",
                        }}
                      >
                        Custom Date & Time
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              {/* {notification.limit !== -1 && (
                  <div className="col-md-4 col-12">
                    <SettingsInput
                      label="Enter Coupon Limit"
                      type="text"
                      placeholder="Enter coupon limit"
                      name="limitValue"
                      value={notification.limit}
                      handleChange={handleChange}
                      error={errors.limit}
                    />
                  </div>
                )} */}
              {notification.send_now === false && (
                <div className="col-4 mt-3">
                  <DatePicker
                    selected={
                      notification.datetime
                        ? new Date(notification.datetime)
                        : null
                    }
                    onChange={handleDateChange}
                    dateFormat="MM/dd/yyyy"
                    className="dobInput"
                    placeholderText="MM/dd/yyyy"
                    showYearDropdown
                    isClearable
                    yearDropdownItemNumber={110}
                    scrollableYearDropdown
                  />
                </div>
              )}
              <div className="col-6"></div>
            </div>
            <div className="formBottom">
              <Button
                className="submitButton"
                type="submit"
                onClick={handleSubmit}
              >
                Send Notification
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}

      <style type="text/css">
        {`
        label {
          display: block;
    font-size: 12px;
    line-height: 18px;
    color: var(--primary_text_color);
    margin-bottom: 10px;
        }
        }
	`}
      </style>
    </>
  );
};

export default SendNotification;
