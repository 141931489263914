import React, { useState, useEffect, useRef } from "react";
import classes from "../assets/styles/Pages/Function/ViewPublicProfile.module.css";
import "react-phone-input-2/lib/material.css";
import contacticon from "../assets/images/function_page_icons/contacticon.svg";
import playstore from "../assets/images/footer_icons/playstore.svg";
import appstore from "../assets/images/footer_icons/appstore.svg";
import Image from "react-bootstrap/Image";
import { EMERGENCY_CONTACT_VCF_FILE_URL } from "../utils/constants/apiUrl";
import { post } from "../server";
import { loggedInUser } from "../utils/store";
import { useAtom } from "jotai";
import showToast from "../helpers/showToast";
import baseURL from "../utils/constants/baseUrl";
import { useParams } from "react-router-dom";
import dummyImage from "../assets/images/global_icons/dummyimage.png";
import _ from "lodash";
import formatPhoneNumber from "../helpers/formatPhoneNumber";
import { BiLinkExternal } from "react-icons/bi";
import { SHARE_QR } from "../utils/constants/common";
const PublicDynamicQR = ({ dynamicQR }) => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  // const [loading, setLoading] = useState(true);
  // const [emergencyContact, setEmergencyContact] = useState({});
  const [VCFData, setVCFData] = useState(dynamicQR);
  const [imageLoaded, setImageLoaded] = useState(false);

  const { uuid } = useParams();

  const [showFullText, setShowFullText] = useState(false);
  const [address, setAddress] = useState("");
  const target = useRef(null);
  useEffect(() => {
    const cardFunction = dynamicQR?.dynamicQRCodeFunctions.find(
      (func) => func.function_type === "card"
    );
    if (cardFunction) {
      const { cards } = cardFunction;
      let addressArr = [];
      if (cards[0]?.address) addressArr.push(cards[0].address);
      if (cards[0]?.city) addressArr.push(cards[0].city);
      if (cards[0]?.state) addressArr.push(cards[0].state);
      if (cards[0]?.country) addressArr.push(cards[0].country);

      handleAddress(addressArr);
    }
  }, [dynamicQR]);

  const handleAddress = (dynamicQR) => {
    let commaAddress = dynamicQR.join(", ");
    setAddress(commaAddress);
  };

  const toggleText = () => {
    setShowFullText(!showFullText);
  };

  const handleVcfFile = async () => {
    let { status, message, data } = await post(
      EMERGENCY_CONTACT_VCF_FILE_URL + `/${VCFData.uuid}`,
      VCFData,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      const link = document.createElement("a");
      link.download = "temp.vcf";
      link.href = data.url;
      link.click();
    } else {
      showToast(status, message);
    }
  };

  const cards = dynamicQR?.dynamicQRCodeFunctions.find(
    (func) => func.function_type === "card"
  );
  const payment = dynamicQR?.dynamicQRCodeFunctions.find(
    (func) => func.function_type === "payment"
  );
  const emergencyContact = dynamicQR?.dynamicQRCodeFunctions.find(
    (func) => func.function_type === "emergency_contact"
  );
  const customURL = dynamicQR?.dynamicQRCodeFunctions.find(
    (func) => func.function_type === "custom_url"
  );
  const fileUpload = dynamicQR?.dynamicQRCodeFunctions.find(
    (func) => func.function_type === "file_upload"
  );
  const memory = dynamicQR?.dynamicQRCodeFunctions.find(
    (func) => func.function_type === "memory"
  );
  const medicalId = dynamicQR?.dynamicQRCodeFunctions.find(
    (func) => func.function_type === "medical_id"
  );
  const formBuilder = dynamicQR?.dynamicQRCodeFunctions.find(
    (func) => func.function_type === "form_builder"
  );

  return (
    <>
      <div className="container">
        <div className="py-5">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-5 col-12">
              <div className={classes.mainWrapper}>
                <div className={classes.cardWrapper}>
                  <div className="">
                    <div
                      className={classes.profileCardWrapper}
                      style={{ paddingTop: "20px" }}
                    >
                      <div className={`${classes.contactInfo}`}>
                        {cards?.cards[0] ? (
                          <>
                            <h6
                              style={{
                                fontWeight: "700",
                                fontSize: "13px",
                                marginTop: "20px",
                              }}
                            >
                              Card Info
                            </h6>

                            {cards?.cards.map((cardItem, index) =>
                              cardItem.status == true ? (
                                <div
                                  className={`${classes.contactInfoInner} mt-2 position-relative`}
                                >
                                  <div key={index}>
                                    <div
                                      className={`${classes.imgWrapper} imgWrapper`}
                                    >
                                      <Image
                                        className="globalIMageFit"
                                        src={
                                          cardItem.profile_image
                                            ? `${baseURL.PUBLIC_URL}${cardItem.profile_image}`
                                            : dummyImage
                                        }
                                        alt="img"
                                        roundedCircle={true}
                                        onLoad={() => setImageLoaded(true)}
                                      />
                                      {cardItem.logo ? (
                                        <Image
                                          className="globalIMageFit globalIMageFitSmall"
                                          src={
                                            cardItem.logo
                                              ? `${baseURL.PUBLIC_URL}${cardItem.logo}`
                                              : dummyImage
                                          }
                                          alt="img"
                                          roundedCircle={true}
                                          onLoad={() => setImageLoaded(true)}
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <div
                                      className={`{classes.detailWrapper} mb-2`}
                                    >
                                      {cardItem.first_name ||
                                      cardItem.last_name ? (
                                        <h6 className="mb-0 mt-3">{`${
                                          cardItem.first_name || ""
                                        } ${cardItem.last_name || ""}`}</h6>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    {cardItem.email ? (
                                      <div
                                        className={`${classes.infoContnt} infoContnt`}
                                        style={{
                                          borderColor: "var(--tertiary_color)",
                                        }}
                                      >
                                        <>
                                          <div>
                                            <p
                                              className={`${classes.subHeading} subHeading`}
                                            >
                                              Email
                                            </p>
                                            <a
                                              href={`mailto:${cardItem.email}`}
                                              className={classes.subHeadingData}
                                            >
                                              {cardItem.email}
                                            </a>
                                          </div>
                                        </>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    {cardItem.cardPhone[0]?.phone_number ? (
                                      <div
                                        className={`${classes.infoContnt} infoContnt`}
                                        style={{
                                          borderColor: "var(--tertiary_color)",
                                        }}
                                      >
                                        {/* <>
                                    <div
                                      className={`${classes.moreInfoIcon} moreInfoIcon`}
                                    >
                                      {dynamicQR?.dynamicQRCodeFunctions[0]
                                        ?.cards[0]?.cardPhone[0]
                                        ?.phone_number ? (
                                        <BsFillTelephoneFill />
                                      ) : null}
                                    </div>
                                  </> */}
                                        <>
                                          <div>
                                            <p
                                              className={`${classes.subHeading} subHeading`}
                                            >
                                              Phone no.
                                            </p>
                                            <a
                                              href={`tel:${cardItem.cardPhone[0]?.phone_number}`}
                                              className={classes.subHeadingData}
                                            >
                                              {`+${
                                                cardItem.cardPhone[0]
                                                  ?.country_phone
                                              }${formatPhoneNumber(
                                                cardItem.cardPhone[0]
                                                  ?.phone_number
                                              )}`}
                                            </a>
                                          </div>
                                        </>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    <button
                                      className={classes.shareBtnIcon}
                                      ref={target}
                                      onClick={() => {
                                        const shareUrl = `${SHARE_QR}${cardItem.function_type}/${cardItem.uuid}`;
                                        window.open(shareUrl, "_blank");
                                      }}
                                    >
                                      <BiLinkExternal />
                                    </button>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )
                            )}
                          </>
                        ) : (
                          ""
                        )}
                        {payment?.payments[0] ? (
                          <>
                            <h6
                              style={{
                                fontWeight: "700",
                                fontSize: "13px",
                                marginTop: "20px",
                              }}
                            >
                              Payments Info
                            </h6>
                            {payment?.payments.map((paymentItem, index) =>
                              paymentItem.status == true ? (
                                <div
                                  key={index}
                                  className={`${classes.contactInfoInner} mt-2 position-relative`}
                                >
                                  <div
                                    className={`${classes.infoContnt} infoContnt mb-0`}
                                    style={{
                                      borderColor: "var(--tertiary_color)",
                                    }}
                                  >
                                    <div>
                                      <p
                                        className={`${classes.subHeading} subHeading`}
                                      >
                                        {paymentItem.title}
                                      </p>
                                      <a
                                        href={paymentItem.url_path}
                                        className={classes.subHeadingData}
                                        target="_blank"
                                      >
                                        {paymentItem.url_path}
                                      </a>
                                    </div>
                                  </div>
                                  <button
                                    className={classes.shareBtnIcon}
                                    ref={target}
                                    onClick={() => {
                                      // const shareUrl = `${SHARE_QR}${paymentItem.function_type}/${paymentItem.uuid}`;

                                      let shareUrl;
                                      if (
                                        paymentItem.payment_type === "cash_app"
                                      ) {
                                        shareUrl = `${SHARE_QR}cashApp/${paymentItem.uuid}`;
                                      } else if (
                                        paymentItem.payment_type === "venmo"
                                      ) {
                                        shareUrl = `${SHARE_QR}venmo/${paymentItem.uuid}`;
                                      } else {
                                        shareUrl = `${SHARE_QR}customPayment/${paymentItem.uuid}`;
                                      }
                                      window.open(shareUrl, "_blank");
                                    }}
                                  >
                                    <BiLinkExternal />
                                  </button>
                                </div>
                              ) : (
                                ""
                              )
                            )}
                          </>
                        ) : (
                          ""
                        )}
                        {emergencyContact?.emergencyContacts[0] ? (
                          <>
                            <h6
                              style={{
                                fontWeight: "700",
                                fontSize: "13px",
                                marginTop: "20px",
                              }}
                            >
                              Emergency Contacts
                            </h6>
                            {emergencyContact?.emergencyContacts.map(
                              (emergencyContactItem, index) =>
                                emergencyContactItem.status == true ? (
                                  <div
                                    key={index}
                                    className={`${classes.contactInfoInner} mt-2 position-relative`}
                                  >
                                    <div
                                      className={`${classes.infoContnt} infoContnt`}
                                      style={{
                                        borderColor: "var(--tertiary_color)",
                                      }}
                                    >
                                      <div>
                                        <div
                                          className={`${classes.imgWrapper} imgWrapper mb-2`}
                                        >
                                          <Image
                                            className="globalIMageFit"
                                            src={
                                              emergencyContactItem.image
                                                ? `${baseURL.PUBLIC_URL}${emergencyContactItem.image}`
                                                : dummyImage
                                            }
                                            alt="img"
                                            roundedCircle={true}
                                            onLoad={() => setImageLoaded(true)}
                                          />
                                        </div>
                                        <h6
                                          className="mb-0"
                                          style={{
                                            color:
                                              "var(--primary_button_color)",
                                            fontSize: "11px",
                                            fontWeight: "600",
                                          }}
                                        >{`${
                                          emergencyContactItem?.first_name || ""
                                        } ${
                                          emergencyContactItem?.last_name || ""
                                        }`}</h6>
                                        <h6 className="mt-1">
                                          <a
                                            href={`tel:${emergencyContactItem.phone_number}`}
                                            className={classes.subHeadingData}
                                            style={{
                                              fontSize: "11px",
                                            }}
                                          >
                                            {`+${
                                              emergencyContactItem?.country_phone
                                            }${formatPhoneNumber(
                                              emergencyContactItem?.phone_number
                                            )}`}
                                          </a>
                                        </h6>
                                      </div>
                                      <button
                                        className={classes.shareBtnIcon}
                                        ref={target}
                                        onClick={() => {
                                          const shareUrl = `${SHARE_QR}emergencyContact/${emergencyContactItem.uuid}`;
                                          window.open(shareUrl, "_blank");
                                        }}
                                      >
                                        <BiLinkExternal />
                                      </button>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )
                            )}
                          </>
                        ) : (
                          ""
                        )}
                        {customURL?.customURLs[0] ? (
                          <>
                            <h6
                              style={{
                                fontWeight: "700",
                                fontSize: "13px",
                                marginTop: "20px",
                              }}
                            >
                              Custom URL’s
                            </h6>

                            {customURL?.customURLs.map(
                              (customURLsItem, index) =>
                                customURLsItem.status == true ? (
                                  <div
                                    key={index}
                                    className={`${classes.contactInfoInner} mt-2 position-relative`}
                                  >
                                    <div
                                      className={`${classes.infoContnt} infoContnt`}
                                      style={{
                                        borderColor: "var(--tertiary_color)",
                                      }}
                                    >
                                      <div>
                                        <p
                                          className={`${classes.subHeading} subHeading`}
                                        >
                                          {customURLsItem.title}
                                        </p>
                                        <a
                                          href={customURLsItem.url_path}
                                          className={classes.subHeadingData}
                                          target="_blank"
                                        >
                                          {customURLsItem.url_path}
                                        </a>
                                      </div>
                                      <button
                                        className={classes.shareBtnIcon}
                                        ref={target}
                                        onClick={() => {
                                          const shareUrl = `${SHARE_QR}customUrl/${customURLsItem.uuid}`;
                                          window.open(shareUrl, "_blank");
                                        }}
                                      >
                                        <BiLinkExternal />
                                      </button>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )
                            )}
                          </>
                        ) : (
                          ""
                        )}
                        {fileUpload?.fileUploads[0] ? (
                          <>
                            <h6
                              style={{
                                fontWeight: "700",
                                fontSize: "13px",
                                marginTop: "20px",
                              }}
                            >
                              File Upload
                            </h6>

                            {fileUpload?.fileUploads.map(
                              (fileUploadsItem, index) =>
                                fileUploadsItem.status == true ? (
                                  <div
                                    key={index}
                                    className={`${classes.contactInfoInner} mt-2 position-relative`}
                                  >
                                    <div
                                      className={`${classes.infoContnt} infoContnt`}
                                      style={{
                                        borderColor: "var(--tertiary_color)",
                                      }}
                                    >
                                      <div>
                                        <p
                                          className={`${classes.subHeading} subHeading`}
                                        >
                                          {fileUploadsItem.title}
                                        </p>
                                        <a
                                          href={fileUploadsItem.url_path}
                                          className={classes.subHeadingData}
                                          target="_blank"
                                        >
                                          {fileUploadsItem.url_path}
                                        </a>
                                      </div>
                                      <button
                                        className={classes.shareBtnIcon}
                                        ref={target}
                                        onClick={() => {
                                          const shareUrl = `${SHARE_QR}fileUpload/${fileUploadsItem.uuid}`;
                                          window.open(shareUrl, "_blank");
                                        }}
                                      >
                                        <BiLinkExternal />
                                      </button>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )
                            )}
                          </>
                        ) : (
                          ""
                        )}
                        {memory?.memory[0] ? (
                          <>
                            <h6
                              style={{
                                fontWeight: "700",
                                fontSize: "13px",
                                marginTop: "20px",
                              }}
                            >
                              Memory
                            </h6>
                            {memory?.memory.map((memoryItem, index) =>
                              memoryItem.status == true ? (
                                <div
                                  key={index}
                                  className={`${classes.contactInfoInner} mt-2 position-relative`}
                                >
                                  <div
                                    className={`${classes.infoContnt} infoContnt`}
                                    style={{
                                      borderColor: "var(--tertiary_color)",
                                    }}
                                  >
                                    <div>
                                      <div
                                        className={`{classes.detailWrapper} mb-3`}
                                      >
                                        {memoryItem.title ? (
                                          <h6 className="mb-0">
                                            {memoryItem.title}
                                          </h6>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      {memoryItem.media_type ? (
                                        <div
                                          className={`${classes.infoContnt} infoContnt`}
                                          style={{
                                            borderColor:
                                              "var(--tertiary_color)",
                                          }}
                                        >
                                          <>
                                            <div>
                                              <p
                                                className={`${classes.subHeading} subHeading`}
                                              >
                                                Media Type
                                              </p>
                                              <p
                                                className={
                                                  classes.subHeadingData
                                                }
                                              >
                                                {memoryItem.media_type}
                                              </p>
                                            </div>
                                          </>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      {memoryItem.medias_count ? (
                                        <div
                                          className={`${classes.infoContnt} infoContnt`}
                                          style={{
                                            borderColor:
                                              "var(--tertiary_color)",
                                          }}
                                        >
                                          <>
                                            <div>
                                              <p
                                                className={`${classes.subHeading} subHeading`}
                                              >
                                                Media Counts
                                              </p>
                                              <p
                                                className={
                                                  classes.subHeadingData
                                                }
                                              >
                                                {memoryItem.medias_count}
                                              </p>
                                            </div>
                                          </>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                    <button
                                      className={classes.shareBtnIcon}
                                      ref={target}
                                      onClick={() => {
                                        const shareUrl = `${SHARE_QR}medical_id/${memoryItem.uuid}`;
                                        window.open(shareUrl, "_blank");
                                      }}
                                    >
                                      <BiLinkExternal />
                                    </button>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )
                            )}
                          </>
                        ) : (
                          ""
                        )}
                        {medicalId?.medicalIds[0] ? (
                          <>
                            <h6
                              style={{
                                fontWeight: "700",
                                fontSize: "13px",
                                marginTop: "20px",
                              }}
                            >
                              Medical Id
                            </h6>
                            {medicalId?.medicalIds.map((medicalIdItem, index) =>
                              medicalIdItem.status == true ? (
                                <div
                                  key={index}
                                  className={`${classes.contactInfoInner} mt-2 position-relative`}
                                >
                                  <div
                                    className={`${classes.infoContnt} infoContnt`}
                                    style={{
                                      borderColor: "var(--tertiary_color)",
                                    }}
                                  >
                                    <div>
                                      <div
                                        className={`${classes.imgWrapper} imgWrapper mb-2`}
                                      >
                                        <Image
                                          className="globalIMageFit"
                                          src={
                                            medicalIdItem.photo
                                              ? `${baseURL.PUBLIC_URL}${medicalIdItem.photo}`
                                              : dummyImage
                                          }
                                          alt="img"
                                          roundedCircle={true}
                                          onLoad={() => setImageLoaded(true)}
                                        />
                                      </div>
                                      <div
                                        className={`{classes.detailWrapper} mb-3`}
                                      >
                                        {medicalIdItem.first_name ||
                                        medicalIdItem.last_name ? (
                                          <h6 className="mb-0">{`${
                                            medicalIdItem.first_name || ""
                                          } ${
                                            medicalIdItem.last_name || ""
                                          }`}</h6>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      {medicalIdItem?.dob ? (
                                        <div
                                          className={`${classes.infoContnt} infoContnt`}
                                          style={{
                                            borderColor:
                                              "var(--tertiary_color)",
                                          }}
                                        >
                                          <>
                                            <div>
                                              <p
                                                className={`${classes.subHeading} subHeading`}
                                              >
                                                Date of Birth
                                              </p>
                                              <p
                                                className={
                                                  classes.subHeadingData
                                                }
                                              >
                                                {medicalIdItem.dob}
                                              </p>
                                            </div>
                                          </>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      {medicalIdItem?.blood_type ? (
                                        <div
                                          className={`${classes.infoContnt} infoContnt`}
                                          style={{
                                            borderColor:
                                              "var(--tertiary_color)",
                                          }}
                                        >
                                          <>
                                            <div>
                                              <p
                                                className={`${classes.subHeading} subHeading`}
                                              >
                                                Blood type
                                              </p>
                                              <p
                                                className={
                                                  classes.subHeadingData
                                                }
                                              >
                                                {medicalIdItem.blood_type}
                                              </p>
                                            </div>
                                          </>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                      {/* <h6 className="mt-1">
                                        <a
                                          href={`tel:${medicalIdItem.phone_number}`}
                                          className={classes.subHeadingData}
                                          style={{
                                            fontSize: "11px",
                                          }}
                                        >
                                          {`+${
                                            medicalIdItem?.country_phone
                                          }${formatPhoneNumber(
                                            medicalIdItem?.phone_number
                                          )}`}
                                        </a>
                                      </h6> */}
                                    </div>
                                    <button
                                      className={classes.shareBtnIcon}
                                      ref={target}
                                      onClick={() => {
                                        const shareUrl = `${SHARE_QR}medical_id/${medicalIdItem.uuid}`;
                                        window.open(shareUrl, "_blank");
                                      }}
                                    >
                                      <BiLinkExternal />
                                    </button>
                                  </div>
                                </div>
                              ) : (
                                ""
                              )
                            )}
                          </>
                        ) : (
                          ""
                        )}
                        {formBuilder?.formBuilders[0] ? (
                          <>
                            <h6
                              style={{
                                fontWeight: "700",
                                fontSize: "13px",
                                marginTop: "20px",
                              }}
                            >
                              Form Builder
                            </h6>
                            {formBuilder?.formBuilders.map(
                              (formBuilderItem, index) =>
                                formBuilderItem.status == true ? (
                                  <div
                                    key={index}
                                    className={`${classes.contactInfoInner} mt-2 position-relative`}
                                  >
                                    <div
                                      className={`${classes.infoContnt} infoContnt`}
                                      style={{
                                        borderColor: "var(--tertiary_color)",
                                      }}
                                    >
                                      <div>
                                        <div
                                          className={`{classes.detailWrapper} mb-3`}
                                        >
                                          {formBuilderItem.name ? (
                                            <h6 className="mb-0">
                                              {formBuilderItem.name}
                                            </h6>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                        {formBuilderItem.media_type ? (
                                          <div
                                            className={`${classes.infoContnt} infoContnt`}
                                            style={{
                                              borderColor:
                                                "var(--tertiary_color)",
                                            }}
                                          >
                                            <>
                                              <div>
                                                <p
                                                  className={`${classes.subHeading} subHeading`}
                                                >
                                                  Media Type
                                                </p>
                                                <p
                                                  className={
                                                    classes.subHeadingData
                                                  }
                                                >
                                                  {formBuilderItem.media_type}
                                                </p>
                                              </div>
                                            </>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </div>
                                      <button
                                        className={classes.shareBtnIcon}
                                        ref={target}
                                        onClick={() => {
                                          const shareUrl = `${SHARE_QR}form_builder/${formBuilderItem.uuid}`;
                                          window.open(shareUrl, "_blank");
                                        }}
                                      >
                                        <BiLinkExternal />
                                      </button>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )
                            )}
                          </>
                        ) : (
                          ""
                        )}
                      </div>
                      <div
                        className={` text-center ${classes.savePhonebookBtnWrapper}`}
                      >
                        <button onClick={handleVcfFile}>
                          <span>
                            <img src={contacticon} alt="" />
                          </span>
                          Save Contact
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.cardFooter}>
                <div className="d-flex align-items-center gap-3 justify-content-center">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.contactco.contactapp"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={playstore} alt="" />
                  </a>
                  <a
                    href="https://apps.apple.com/us/app/contact-co-connect-smarter/id6683301913"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={appstore} alt="" />
                  </a>
                </div>
                <p>
                  © {new Date().getFullYear()} Contact Co, All rights reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <style type="text/css">{`
        .imgWrapper{
          display: inline-block;
          position:relative;
        }
        .globalIMageFit{
          width:60px !important;
          height:60px !important;
        }
        .globalIMageFitSmall{
          width:30px !important;
          height:30px !important;
          position:absolute;
          bottom:-3px;
          right:-10px;
        }
      `}</style>
    </>
  );
};

export default PublicDynamicQR;
