import {
  teamIDCheck,
  subscribedPackage,
  userRemainingFunctions,
  memberRemainingFunctions,
  loggedInUser,
  userRemainingFunctionsForDynamicQR,
} from "../utils/store";

import { useAtom } from "jotai";
import { get } from "../server";
import { HOMEDASHBOARD } from "../utils/constants/apiUrl";

const useFunctionsCount = () => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [userTeamId, setUserTeamId] = useAtom(teamIDCheck);
  const [purchasedPackage, setPurchasedPackage] = useAtom(subscribedPackage);
  const [userAvailableFunctions, setUserAvailableFunctions] = useAtom(
    userRemainingFunctions
  );
  const [memberAvailableFunctions, setMemberAvailableFunctions] = useAtom(
    memberRemainingFunctions
  );
  const [
    userAvailableFunctionsForDynamicQR,
    setUserAvailableFunctionsForDynamicQR,
  ] = useAtom(userRemainingFunctionsForDynamicQR);

  const userActions = (userData) => {
    console.log("loggedInUser", loggedInUser);
    const actionInfoWithTeam = userData?.user_action_info?.filter(
      (obj) => obj.team_id !== null
    );
    const actionInfoWithoutTeam = userData?.user_action_info?.filter(
      (obj) => obj.team_id === null
    );
    const actionInfoWithoutTeamForDynamicQR =
      userData?.user_action_info?.filter(
        (obj) => obj.dynamic_qr_code_id !== null
      );
    console.log(
      "actionInfoWithoutTeamForDynamicQR",
      actionInfoWithoutTeamForDynamicQR
    );
    setMemberAvailableFunctions({
      ...memberAvailableFunctions,
      total_cards: actionInfoWithTeam?.[0]?.total_cards,
      total_connections: actionInfoWithTeam?.[0]?.total_connections,
      total_custom_urls: actionInfoWithTeam?.[0]?.total_custom_urls,
      total_emergency_contacts:
        actionInfoWithTeam?.[0]?.total_emergency_contacts,
      total_file_uploads: actionInfoWithTeam?.[0]?.total_file_uploads,
      total_payments: actionInfoWithTeam?.[0]?.total_payments,
      total_form_builders: actionInfoWithTeam?.[0]?.total_form_builders,
      total_memory: actionInfoWithTeam?.[0]?.total_memory,
      total_medical_id: actionInfoWithTeam?.[0]?.total_medical_id,
      total_dynamic_qr_codes: actionInfoWithTeam?.[0]?.total_dynamic_qr_codes,
    });

    setUserAvailableFunctions({
      ...userAvailableFunctions,
      total_cards: actionInfoWithoutTeam?.[0]?.total_cards,
      total_connections: actionInfoWithoutTeam?.[0]?.total_connections,
      total_custom_urls: actionInfoWithoutTeam?.[0]?.total_custom_urls,
      total_emergency_contacts:
        actionInfoWithoutTeam?.[0]?.total_emergency_contacts,
      total_file_uploads: actionInfoWithoutTeam?.[0]?.total_file_uploads,
      total_payments: actionInfoWithoutTeam?.[0]?.total_payments,
      total_form_builders: actionInfoWithoutTeam?.[0]?.total_form_builders,
      total_memory: actionInfoWithoutTeam?.[0]?.total_memory,
      total_medical_id: actionInfoWithoutTeam?.[0]?.total_medical_id,
      total_dynamic_qr_codes:
        actionInfoWithoutTeam?.[0]?.total_dynamic_qr_codes,
    });
    setUserAvailableFunctionsForDynamicQR(actionInfoWithoutTeamForDynamicQR);
  };

  const updateUserActionsForDynamicQRCode = (dynamic_qr_code_id) => {
    
    setUserAvailableFunctionsForDynamicQR([
      ...userAvailableFunctionsForDynamicQR,
      {
        dynamic_qr_code_id: dynamic_qr_code_id,
        total_cards: 0,
        total_payments: 0,
        total_emergency_contacts: 0,
        total_custom_urls: 0,
        total_file_uploads: 0,
        total_form_builders: 0,
        total_memory: 0,
        total_medical_id: 0,
      }
    ]);
  };
  // const functionCount = async () => {
  //   let { status, message, data } = await get(
  //     HOMEDASHBOARD,
  //     Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
  //   );
  //   if (status) {
  //     console.log("data", data);
  //   }
  // };

  const compareLimits = (functionType) => {
    console.log("functionType", functionType);
    console.log("purchasedPackage", purchasedPackage);
    console.log(
      "purchasedPackage",
      purchasedPackage?.[
        `limit_${functionType === "connection" ? "connections" : functionType}${
          functionType === "memory"
            ? ""
            : functionType === "medical_id"
            ? ""
            : functionType === "form_builder"
            ? ""
            : functionType === "dynamic_qr_code"
            ? ""
            : `_${functionType === "connection" ? "save" : "create"}`
        }`
      ]
    );
    // Get the limit value from data based on the functionType
    const limitValue =
      purchasedPackage?.[
        `limit_${functionType === "connection" ? "connections" : functionType}${
          functionType === "memory"
            ? ""
            : functionType === "medical_id"
            ? ""
            : functionType === "form_builder"
            ? ""
            : functionType === "dynamic_qr_code"
            ? ""
            : `_${functionType === "connection" ? "save" : "create"}`
        }`
      ];
    console.log("limitValue", limitValue);
    // Get the total value from userAvailableFunctions based on the functionType
    let totalValue;
    console.log("totalValue", totalValue);
    if (userTeamId) {
      if (functionType === "medical_id") {
        totalValue = memberAvailableFunctions[`total_${functionType}`];
      } else if (functionType === "memory") {
        totalValue = memberAvailableFunctions[`total_${functionType}`];
      } else if (functionType === "form_builder") {
        totalValue = memberAvailableFunctions[`total_${functionType}s`];
      } else if (functionType === "dynamic_qr_code") {
        totalValue = memberAvailableFunctions[`total_${functionType}s`];
      } else {
        totalValue = memberAvailableFunctions[`total_${functionType}s`];
      }
    } else {
      if (functionType === "medical_id") {
        totalValue = userAvailableFunctions[`total_${functionType}`];
      } else if (functionType === "memory") {
        totalValue = userAvailableFunctions[`total_${functionType}`];
      } else if (functionType === "form_builder") {
        totalValue = userAvailableFunctions[`total_${functionType}s`];
      } else if (functionType === "dynamic_qr_code") {
        totalValue = userAvailableFunctions[`total_${functionType}s`];
      } else {
        totalValue = userAvailableFunctions[`total_${functionType}s`];
      }
      console.log("totalValue", totalValue);
    }
    console.log("limitValue", limitValue);
    console.log("totalValue", totalValue);
    // Perform the comparison and return true or false
    if (limitValue && (limitValue > totalValue || limitValue == "unlimited")) {
      console.log("limitValue", limitValue);
      console.log("totalValue", totalValue);
      //return false;
    } else {
      return true;
    }
  };

  const compareLimitsForDynamicQR = (functionType, dynamicQRCodeId) => {
    console.log("functionType-----", functionType);
    console.log("purchasedPackage----------", purchasedPackage);
    console.log("dynamicQRCodeId---------", dynamicQRCodeId);
    console.log(
      "userAvailableFunctionsForDynamicQR",
      userAvailableFunctionsForDynamicQR
    );
    // const qrAction = userAvailableFunctionsForDynamicQR.filter(
    //   (action) => action.dynamic_qr_code_id === Number(dynamicQRCodeId)
    // )[0];
    const qrAction = userAvailableFunctionsForDynamicQR.filter((action) => {
      console.log("action", action);
      return action.dynamic_qr_code_id === Number(dynamicQRCodeId);
    })[0];

    console.log("qrAction", qrAction);
    // Get the limit value from data based on the functionType
    const limitValue =
      purchasedPackage?.[`limit_dynamic_qr_code_each_function`];
    console.log("limitValue-------", limitValue);
    // Get the total value from userAvailableFunctions based on the functionType
    let totalValue;
    if (functionType === "medical_id") {
      totalValue = qrAction[`total_${functionType}`];
    } else if (functionType === "memory") {
      totalValue = qrAction[`total_${functionType}`];
    } else if (functionType === "form_builder") {
      totalValue = qrAction[`total_${functionType}s`];
    } else {
      totalValue = qrAction[`total_${functionType}s`];
    }
    console.log("totalValue-------------", totalValue);

    console.log("limitValue", limitValue);
    console.log("totalValue", totalValue);
    // Perform the comparison and return true or false
    if (limitValue && (limitValue > totalValue || limitValue == "unlimited")) {
      console.log("limitValue", limitValue);
      console.log("totalValue", totalValue);
      return false;
    } else {
      return true;
    }
  };

  const updateCount = (functionType, actionType, dynamicQRCodeId) => {
    if (actionType === "add") {
      if (dynamicQRCodeId) {
        setUserAvailableFunctionsForDynamicQR(
          userAvailableFunctionsForDynamicQR.map((action) => {
            if (action.dynamic_qr_code_id === Number(dynamicQRCodeId)) {
              action[
                `total_${functionType}${
                  ["memory", "medical_id"].includes(functionType) ? "" : "s"
                }`
              ] =
                action[
                  `total_${functionType}${
                    ["memory", "medical_id"].includes(functionType) ? "" : "s"
                  }`
                ] + 1;
            }
            return action;
          })
        );
      } else if (userTeamId) {
        functionType === "memory"
          ? setMemberAvailableFunctions((prevFunctions) => ({
              ...prevFunctions,
              [`total_${functionType}`]:
                prevFunctions[`total_${functionType}`] + 1,
            }))
          : functionType === "medical_id"
          ? setMemberAvailableFunctions((prevFunctions) => ({
              ...prevFunctions,
              [`total_${functionType}`]:
                prevFunctions[`total_${functionType}`] + 1,
            }))
          : functionType === "form_builder"
          ? setMemberAvailableFunctions((prevFunctions) => ({
              ...prevFunctions,
              [`total_${functionType}s`]:
                prevFunctions[`total_${functionType}s`] + 1,
            }))
          : functionType === "dynamic_qr_code"
          ? setMemberAvailableFunctions((prevFunctions) => ({
              ...prevFunctions,
              [`total_${functionType}s`]:
                prevFunctions[`total_${functionType}s`] + 1,
            }))
          : setMemberAvailableFunctions((prevFunctions) => ({
              ...prevFunctions,
              [`total_${functionType}s`]:
                prevFunctions[`total_${functionType}s`] + 1,
            }));
      } else {
        functionType === "memory"
          ? setUserAvailableFunctions((prevFunctions) => ({
              ...prevFunctions,
              [`total_${functionType}`]:
                prevFunctions[`total_${functionType}`] + 1,
            }))
          : functionType === "medical_id"
          ? setUserAvailableFunctions((prevFunctions) => ({
              ...prevFunctions,
              [`total_${functionType}`]:
                prevFunctions[`total_${functionType}`] + 1,
            }))
          : functionType === "form_builder"
          ? setUserAvailableFunctions((prevFunctions) => ({
              ...prevFunctions,
              [`total_${functionType}s`]:
                prevFunctions[`total_${functionType}s`] + 1,
            }))
          : functionType === "dynamic_qr_code"
          ? setUserAvailableFunctions((prevFunctions) => ({
              ...prevFunctions,
              [`total_${functionType}s`]:
                prevFunctions[`total_${functionType}s`] + 1,
            }))
          : setUserAvailableFunctions((prevFunctions) => ({
              ...prevFunctions,
              [`total_${functionType}s`]:
                prevFunctions[`total_${functionType}s`] + 1,
            }));
      }
    } else if (actionType === "delete") {
      if (dynamicQRCodeId) {
        console.log('dynamicQRCodeId', dynamicQRCodeId);
        setUserAvailableFunctionsForDynamicQR(
          userAvailableFunctionsForDynamicQR.map((action) => {
            if (action.dynamic_qr_code_id === Number(dynamicQRCodeId)) {
              action[
                `total_${functionType}${
                  ["memory", "medical_id"].includes(functionType) ? "" : "s"
                }`
              ] =
                action[
                  `total_${functionType}${
                    ["memory", "medical_id"].includes(functionType) ? "" : "s"
                  }`
                ] - 1;
            }
            return action;
          })
        );
      } else if (userTeamId) {
        functionType === "memory"
          ? setMemberAvailableFunctions((prevFunctions) => ({
              ...prevFunctions,
              [`total_${functionType}`]:
                prevFunctions[`total_${functionType}`] > 0
                  ? prevFunctions[`total_${functionType}`] - 1
                  : 0,
            }))
          : functionType === "medical_id"
          ? setMemberAvailableFunctions((prevFunctions) => ({
              ...prevFunctions,
              [`total_${functionType}`]:
                prevFunctions[`total_${functionType}`] > 0
                  ? prevFunctions[`total_${functionType}`] - 1
                  : 0,
            }))
          : functionType === "form_builder"
          ? setMemberAvailableFunctions((prevFunctions) => ({
              ...prevFunctions,
              [`total_${functionType}s`]:
                prevFunctions[`total_${functionType}s`] > 0
                  ? prevFunctions[`total_${functionType}s`] - 1
                  : 0,
            }))
          : functionType === "dynamic_qr_code"
          ? setMemberAvailableFunctions((prevFunctions) => ({
              ...prevFunctions,
              [`total_${functionType}s`]:
                prevFunctions[`total_${functionType}s`] > 0
                  ? prevFunctions[`total_${functionType}s`] - 1
                  : 0,
            }))
          : setMemberAvailableFunctions((prevFunctions) => ({
              ...prevFunctions,
              [`total_${functionType}s`]:
                prevFunctions[`total_${functionType}s`] > 0
                  ? prevFunctions[`total_${functionType}s`] - 1
                  : 0,
            }));
      } else {
        functionType === "memory" || functionType === "medical_id"
          ? // functionType === "form_builder" ||
            // functionType === "dynamic_qr_code"
            setUserAvailableFunctions((prevFunctions) => ({
              ...prevFunctions,
              [`total_${functionType}`]:
                prevFunctions[`total_${functionType}`] > 0
                  ? prevFunctions[`total_${functionType}`] - 1
                  : 0,
            }))
          : setUserAvailableFunctions((prevFunctions) => ({
              ...prevFunctions,
              [`total_${functionType}s`]:
                prevFunctions[`total_${functionType}s`] > 0
                  ? prevFunctions[`total_${functionType}s`] - 1
                  : 0,
            }));
      }
    }
  };

  const limitExceededForStarter = async () => {
    let { status, message, data } = await get(
      HOMEDASHBOARD,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      const keys = Object.keys(data);
      for (let i = 0; i < keys.length; i++) {
        if (data[keys[i]] === 1) {
          return true;
        }
      }
      return false; // No limit exceeded
    } else {
      // Handle the case where the request fails
      console.error("Failed to fetch data:", message);
      throw new Error("Failed to fetch data");
    }
  };

  return {
    compareLimits,
    updateCount,
    userActions,
    limitExceededForStarter,
    compareLimitsForDynamicQR,
    updateUserActionsForDynamicQRCode,
  };
};

export default useFunctionsCount;
