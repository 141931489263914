import React, { useState } from "react";
import classes from "../assets/styles/ForgotPassword.module.css";
import FormInput from "../components/Forms/FormInput";
import { loggedInUser } from "../utils/store";
import { useAtom } from "jotai";
import { Link } from "react-router-dom";
import { post } from "../server";
import { useNavigate } from "react-router-dom";
import ValidateSchema from "../helpers/ValidateSchema";
import showToast from "../helpers/showToast";
import Joi from "joi";
import _ from "lodash";
import Loader from "../components/Loader";
import { TiTick } from "react-icons/ti";

import {
  FORGET_PASSWORD,
  CHECK_OTP,
  RESET_PASSWORD,
} from "../utils/constants/apiUrl";

const subTitle = [
  "Enter your recovery email",
  "Submit verification code",
  "Enter your new password",
];

const buttonText = ["Send", "Continue", "Submit"];

const step0Schema = Joi.object({
  email: Joi.string()
    .max(50)
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "string.empty": `Please enter email`,
      "string.email": `Please enter valid email address`,
      "string.max": `Email must be maximum 50 characters!`,
    }),
});

const step1Schema = Joi.object({
  email: Joi.string()
    .max(50)
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "string.empty": `Please enter email`,
      "string.email": `Please enter valid email address`,
      "string.max": `Email must be maximum 50 characters!`,
    }),
  otp_code: Joi.string().max(6).min(6).required().messages({
    "string.empty": `Please enter opt code`,
    "string.max": `OTP code must be minimum or maximum 6 digits!`,
    "string.min": `OTP code must be minimum or maximum 6 digits!`,
  }),
});

const step2Schema = Joi.object({
  email: Joi.string()
    .max(50)
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "string.empty": `Please enter email address`,
      "string.email": `Please enter valid email address`,
      "string.max": `Email must be maximum 50 characters!`,
    }),

  otp_code: Joi.string().max(6).min(6).required().messages({
    "string.empty": `Please enter opt code`,
    "string.max": `OTP code must be minimum or maximum 6 digits!`,
    "string.min": `OTP code must be minimum or maximum 6 digits!`,
  }),

  new_password: Joi.string().min(8).required().messages({
    "string.empty": `Please enter password`,
    "string.min": `Password must be minimum 8 characters!`,
  }),
  confirm_password: Joi.string()
    .required()
    .valid(Joi.ref("new_password"))
    .messages({
      "string.empty": `Please enter confirm password `,
      "any.only": `Confirm password must match new password`,
    }),
});

const ForgotPassword = () => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const [forgetPassword, setForgetPassword] = useState({
    email: "",
    otp_code: "",
    new_password: "",
    confirm_password: "",
  });

  const [stepValue, setStepValue] = useState(0);

  // const handleChange = (e) => {
  //   const name = e.target.name;
  //   const value = e.target.value;
  //   setForgetPassword({ ...forgetPassword, [name]: value });
  // };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    // Update the form state
    setForgetPassword({ ...forgetPassword, [name]: value });

    // Clear the error for the current field if it exists
    if (errors[name]) {
      setErrors((prevErrors) => {
        const newErrors = { ...prevErrors };
        delete newErrors[name];
        return newErrors;
      });
    }
  };

  const validateForm = (schema) => {
    let newErrors = ValidateSchema(forgetPassword, schema);
    setErrors(newErrors);
    if (Object.keys(newErrors).length) return false;
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (stepValue === 0) {
      if (!validateForm(step0Schema)) return;
    }
    if (stepValue === 1) {
      if (!validateForm(step1Schema)) return;
    }
    if (stepValue === 2) {
      if (!validateForm(step2Schema)) return;
    }
    setLoading(true);

    let payload = _.cloneDeep(forgetPassword);

    let step = stepValue;
    if (stepValue == 0) {
      let { status, message, data } = await post(
        FORGET_PASSWORD,
        {
          email: payload.email,
        },
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );

      if (status) {
        step = 1;
      } else {
        showToast(status, message);
      }
    } else if (stepValue == 1) {
      let { status, message, data } = await post(
        CHECK_OTP,
        {
          email: payload.email,
          code: payload.otp_code,
        },
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );

      if (status) {
        step = 2;
      } else {
        showToast(status, message);
      }
    } else if (stepValue == 2) {
      let { status, message, data } = await post(
        RESET_PASSWORD,
        {
          email: payload.email,
          code: payload.otp_code,
          password: payload.new_password,
          confirm_password: payload.confirm_password,
        },
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );

      if (status) {
        step = 3;
        // navigate("/login");
      }
      // showToast(status, message);
    }
    setStepValue(step);
    setLoading(false);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <section className={classes.page}>
          <div className={classes.mainWrapper}>
            <div>
              <h1 className={classes.heading}>Forgot Password</h1>
              <p className={classes.subHeading}>{subTitle[stepValue]}</p>
            </div>
            <div className={classes.boxWrapper}>
              {stepValue == 0 && (
                <>
                  <FormInput
                    label="Recovery Email"
                    placeholder="Email Address"
                    name="email"
                    type="text"
                    value={forgetPassword.email}
                    handleChange={handleChange}
                    error={errors?.email}
                    autofocus={true}
                  />
                  <p className={classes.info}>
                    Enter your email address below and we'll send you a link to
                    reset your password.
                  </p>
                </>
              )}

              {stepValue == 1 && (
                <>
                  <FormInput
                    label="Enter Code"
                    placeholder="Otp Code"
                    name="otp_code"
                    type="number"
                    value={forgetPassword.otp_code}
                    handleChange={handleChange}
                    error={errors?.otp_code}
                    autofocus={true}
                  />
                  <p className={classes.info}>
                    We've sent you an email with verification code. Please enter
                    code to continue.
                  </p>
                </>
              )}

              {stepValue == 2 && (
                <>
                  <FormInput
                    label="New Password"
                    placeholder="*************"
                    name="new_password"
                    type="password"
                    value={forgetPassword.new_password}
                    handleChange={handleChange}
                    error={errors?.new_password}
                    autofocus={true}
                  />
                  <FormInput
                    label="Confirm Password"
                    placeholder="*************"
                    name="confirm_password"
                    type="password"
                    value={forgetPassword.confirm_password}
                    handleChange={handleChange}
                    error={errors?.confirm_password}
                    // autofocus={true}
                  />
                </>
              )}

              {stepValue == 3 ? (
                <>
                  <div className=""></div>
                  <span className={classes.dot}>
                    <TiTick />
                  </span>
                  <div>
                    <p className={classes.successFooter}>
                      Your Password is updated
                      <br></br>
                      <span>Successfully</span>
                    </p>
                  </div>
                </>
              ) : (
                <div className={classes.submitBtnWrapper}>
                  <button className={classes.submitBtn} onClick={handleSubmit}>
                    {buttonText[stepValue]}
                  </button>
                </div>
              )}
              <div className={classes.forgotPasswordWrapper}>
                <Link to="/login"> Back to Sign In</Link>
              </div>
            </div>
          </div>
        </section>
      )}

      <style type="text/css">
        {`
          html, body{
            background: #1e1e1e;
          }
          .globalInputWithIcon, .globalInputWithIcon.input-group:focus-within {
            border-color: #333e56;
          }
          .globalInputWithIcon .form-control, 
          .globalInputWithIcon .input-group-text {
            background: #21293b;
            color:#fff;
          }

           .globalInputWithIcon .form-control::placeholder, .globalInputWithIcon .input-group-text {
            color: #fff;
            opacity: 0.5;
          }
          input:-webkit-autofill,  input:-webkit-autofill:hover, 
           input:-webkit-autofill:focus,  input:-webkit-autofill:active{
            -webkit-box-shadow: 0 0 0 30px #21293b inset !important;
             -webkit-text-fill-color: #fff !important;
          }
          .customSignuUpPageTabs button{
            background: #21293b !important;
            color: #fff !important
          }
          .customSignuUpPageTabs .active{
            background: #22a081 !important;
            color: #fff !important
          }
          .submitBtn{
            background: #22a081 !important;
            color: #fff !important
          }
          .notAccountWrapper a{
            color: #22a081;
          }
            label{
              color: #fff !important
            }
        `}
      </style>
    </>
  );
};

export default ForgotPassword;
