import React, { useState, useEffect, useMemo } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link } from "react-router-dom";
import DataTable, { createTheme } from "react-data-table-component";
import { useAtom } from "jotai";
import classes from "../../../assets/styles/Contact.module.css";
import { USER, DEVICETYPEUSERCOUNT } from "../../../utils/constants/apiUrl";
import { get, deleteRecord } from "../../../server";
import showToast from "../../../helpers/showToast";
import { loggedInUser } from "../../../utils/store";
import Loader from "../../../components/Loader";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal";
import FormInput from "../../../components/Forms/FormInput";
import searchIcon from "../../../assets/images/global_icons/searchicon.svg";
import formatPhoneNumber from "../../../helpers/formatPhoneNumber";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

const AllUsers = () => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [loading, setLoading] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [users, setUsers] = useState([]);
  const [count, setCount] = useState({});
  const [deleteId, setDeleteId] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [filterText, setFilterText] = useState("");
  // const [filter, setFilter] = useState("");
  const [activeTab, setActiveTab] = useState("");

  const handleFilter = (e) => {
    setFilterText(e.target.value);
  };

  const loadUsers = async (device_type) => {
    setLoading(true);
    setIsDataLoaded(false);
    const queryString = `?device_type=${device_type}`;
    let { status, message, data } = await get(
      device_type ? USER + queryString : USER,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      if (data.length > 0 || data.length !== 0) {
        setUsers(data);
        // setFilter(device_type);
      } else {
        setUsers([]);
      }
    } else {
      showToast(status, message);
    }
    setLoading(false);
    setIsDataLoaded(true);
  };

  const handleUsersCounts = async () => {
    setLoading(true);
    let { status, message, data } = await get(
      DEVICETYPEUSERCOUNT,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      setCount(data);
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  const handleTabChange = (selectedTab) => {
    setActiveTab(selectedTab);
    loadUsers(selectedTab);
  };

  useEffect(() => {
    loadUsers();
    handleUsersCounts();
  }, []);

  const filteredItems = useMemo(() => {
    const filtered = users.filter(
      (item) =>
        `${item.first_name || ""} ${item.last_name || ""}`
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
        `${item.email || ""}`
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
        `${item.city || ""}`.toLowerCase().includes(filterText.toLowerCase()) ||
        `${item.country || ""}`
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
        `${item.country_phone ? `+${item.country_phone}` : ""}${
          item.phone_number || ""
        }`.includes(filterText)
    );

    return filtered;
  }, [filterText, users]);

  createTheme(
    "solarized",
    {
      background: {
        default: "background: var(--secondary_color)",
      },
    },
    "dark"
  );

  const columns = [
    {
      name: "Name",
      width: "200px",
      cell: (row) => <p className="globalDetail">{row.name}</p>,
    },
    {
      name: "Email",
      width: "200px",
      cell: (row) => (
        <a
          href={`mailto:${row.email}`}
          className="globalDetail"
          style={{ display: "block" }}
        >
          {row.email}
        </a>
      ),
    },
    {
      name: "Team Member",
      width: "150px",
      cell: (row) => (
        <p className="globalDetail">
          {row?.connected_teams?.length !== 0 ? "Yes" : "No"}
        </p>
      ),
    },
    {
      name: "Team Name",
      width: "200px",
      cell: (row) => (
        <p className="globalDetail">
          {row?.connected_teams?.length !== 0
            ? row.connected_teams[0].name
            : "-"}
        </p>
      ),
    },
    {
      name: "Phone #",
      width: "150px",
      cell: (row) => (
        <>
          {row.phone_number ? (
            <a
              href={`tel:${
                row.country_phone
                  ? "+" +
                    row.country_phone +
                    " " +
                    formatPhoneNumber(row.phone_number)
                  : row.phone_number || ""
              }`}
              className="globalDetail"
            >
              {row.country_phone
                ? "+" +
                  row.country_phone +
                  " " +
                  formatPhoneNumber(row.phone_number)
                : row.phone_number || ""}
            </a>
          ) : (
            <p className="globalDetail">-</p>
          )}
        </>
      ),
    },
    {
      width: "200px",
      name: "City, Country",
      cell: (row) => (
        <>
          {row.city || row.country ? (
            <a
              href={`http://maps.google.com/?q=${row.city || ""}${
                row.city && row.country ? ", " : ""
              }${row.country || " "}`}
              className="globalDetail"
              target="_blank"
              rel="noreferrer"
            >
              {row.city || ""}
              {row.city && row.country ? "," : ""} {row.country}
            </a>
          ) : (
            <p className="globalDetail">-</p>
          )}
        </>
      ),
    },
    {
      name: "Action",
      width: "100px",
      right: "true",
      cell: (row) => (
        <div className={classes.action}>
          <button
            className="btn customActionDropdown dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <BsThreeDotsVertical className={"globalVerticalThreeDots"} />
          </button>
          <ul className="customActionDropdownMenu dropdown-menu dropdown-menu-end ">
            <li>
              <Link className="dropdown-item" to={`AddEditUser/${row.id}`}>
                Edit
              </Link>
            </li>
            <li>
              <Link
                className="dropdown-item"
                to="#"
                style={{ color: "#E35252" }}
                onClick={() => handleDelete(row.id)}
              >
                Delete
              </Link>
            </li>
          </ul>
        </div>
      ),
    },
  ];

  // Handle confirmation modal
  const handleShowConfirmationModal = () => setShowConfirmationModal(true);
  const handleCloseConfirmationModal = () => setShowConfirmationModal(false);

  // handle delete
  const handleDelete = (id) => {
    setDeleteId(id);
    handleShowConfirmationModal(true);
  };

  const handleConfirmDelete = async () => {
    handleCloseConfirmationModal(true);
    setLoading(true);
    let { status, message } = await deleteRecord(
      `${USER}/${deleteId}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    showToast(status, message);

    if (status) {
      await loadUsers();
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="">
          <div className="page_Header">
            <div className="d-flex justify-content-between align-items-md-center align-items-initial flex-md-row flex-column gap-3">
              <p className="pageTitle">
                Users <span>({users.length})</span>
              </p>
              <div className="d-flex align-items-center justify-content-end gap-2 mb-2">
                <FormInput
                  icon={searchIcon}
                  placeholder="Search..."
                  value={filterText}
                  handleChange={handleFilter}
                />
                <Link to="/users/addEditUser">
                  <button className="primary_button">Add</button>
                </Link>
              </div>
            </div>
          </div>

          <div className={`listingWrapper mainWrapper`}>
            <div className={classes.tabsWrapper}>
              <Tabs
                defaultActiveKey=""
                activeKey={activeTab}
                className="customSubscriptionTabs"
                transition={false}
                onSelect={handleTabChange}
                justify
              >
                <Tab
                  eventKey=""
                  title={
                    <span>
                      All Users{" "}
                      <span className={classes.count}>({count.allUsers})</span>
                    </span>
                  }
                >
                  {/* <DataTable
                    columns={columns}
                    data={filteredItems}
                    pagination
                    theme="solarized"
                  /> */}
                  {isDataLoaded ? (
                    <DataTable
                      columns={columns}
                      data={filteredItems}
                      pagination
                      theme="solarized"
                    />
                  ) : (
                    <Loader />
                  )}
                </Tab>
                <Tab
                  eventKey="ios"
                  title={
                    <span>
                      ios Users{" "}
                      <span className={classes.count}>({count.ios})</span>
                    </span>
                  }
                >
                  {/* <DataTable
                    columns={columns}
                    data={filteredItems}
                    pagination
                    theme="solarized"
                  /> */}
                  {isDataLoaded ? (
                    <DataTable
                      columns={columns}
                      data={filteredItems}
                      pagination
                      theme="solarized"
                    />
                  ) : (
                    <Loader />
                  )}
                </Tab>
                <Tab
                  eventKey="android"
                  title={
                    <span>
                      Android Users{" "}
                      <span className={classes.count}>({count.andorid})</span>
                    </span>
                  }
                >
                  {/* <DataTable
                    columns={columns}
                    data={filteredItems}
                    pagination
                    theme="solarized"
                  /> */}
                  {isDataLoaded ? (
                    <DataTable
                      columns={columns}
                      data={filteredItems}
                      pagination
                      theme="solarized"
                    />
                  ) : (
                    <Loader />
                  )}
                </Tab>
                <Tab
                  eventKey="webapp"
                  title={
                    <span>
                      Web App Users{" "}
                      <span className={classes.count}>({count.webapp})</span>
                    </span>
                  }
                >
                  {/* <DataTable
                    columns={columns}
                    data={filteredItems}
                    pagination
                    theme="solarized"
                  /> */}
                  {isDataLoaded ? (
                    <DataTable
                      columns={columns}
                      data={filteredItems}
                      pagination
                      theme="solarized"
                    />
                  ) : (
                    <Loader />
                  )}
                </Tab>
                <Tab
                  eventKey="shopify"
                  title={
                    <span>
                      Shopify Users{" "}
                      <span className={classes.count}>({count.shopify})</span>
                    </span>
                  }
                >
                  {/* <DataTable
                    columns={columns}
                    data={filteredItems}
                    pagination
                    theme="solarized"
                  /> */}
                  {isDataLoaded ? (
                    <DataTable
                      columns={columns}
                      data={filteredItems}
                      pagination
                      theme="solarized"
                    />
                  ) : (
                    <Loader />
                  )}
                </Tab>
              </Tabs>
            </div>
          </div>

          <ConfirmationModal
            show={showConfirmationModal}
            handleCloseModal={handleCloseConfirmationModal}
            handleConfirmDelete={handleConfirmDelete}
            confirmTitle="user"
          />
        </div>
      )}
      <style type="text/css">
        {`
          .customSubscriptionTabs {
            padding: 20px;
          }
          .customSubscriptionTabs button {
            background: var(--accent_color) !important;
          }
        `}
      </style>
    </>
  );
};

export default AllUsers;
