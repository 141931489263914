import React, { useState } from "react";
import classes from "../assets/styles/Pages/Function/ViewFormBuilder.module.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import playstore from "../assets/images/footer_icons/playstore.svg";
import appstore from "../assets/images/footer_icons/appstore.svg";
import Image from "react-bootstrap/Image";
import { LEAD_ADD, IMAGE_UPLOAD } from "../utils/constants/apiUrl";
import { post } from "../server";
import { loggedInUser } from "../utils/store";
import { useAtom } from "jotai";
import showToast from "../helpers/showToast";
import FormInput from "../components/Forms/FormInput";
import { useParams, useNavigate } from "react-router-dom";
import _ from "lodash";
import baseUrl from "../utils/constants/baseUrl";
import { Modal } from "react-bootstrap";
import crossicon from "../assets/images/global_icons/crossicon.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import pictureicon from "../assets/images/function_page_icons/picIcon.svg";

const PublicFormBuilder = ({ formBuilderData }) => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [VCFData, setVCFData] = useState(formBuilderData);
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [image, selectImage] = useState("");
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [currentUploadField, setCurrentUploadField] = useState(null);
  const [uploadedImageUrl, setUploadedImageUrl] = useState({});
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const { id } = useParams();
  const countryCodes = require("country-codes-list");

  const generateCountryOptions = () => {
    const myCountryCodesObject = countryCodes.customList(
      "countryCode",
      "[{countryCode}] {countryNameEn}: +{countryCallingCode}"
    );
    // const countryOptions = Object.entries(myCountryCodesObject).map(
    //   ([code, label]) => ({
    //     value: code,
    //     label: label.replace(`[${code}] `, ""),
    //   })
    // );
    const countryOptions = Object.entries(myCountryCodesObject).map(
      ([code, label]) => {
        const callingCode = label.match(/\+(\d+)/)[1];
        return {
          value: callingCode,
          label: label.replace(`[${code}] `, ""),
        };
      }
    );
    return countryOptions;
  };
  // const handleInputChange = (e) => {
  //   setFormData({
  //     ...formData,
  //     [e.target.name]: e.target.value,
  //   });
  // };

  const handleUploadModalOpen = (field) => {
    setCurrentUploadField(field);
    setUploadModalOpen(true);
  };

  const handleUploadModalClose = () => {
    setCurrentUploadField(null);
    setUploadModalOpen(false);
  };

  const renderFormField = (field) => {
    switch (field.type) {
      case "heading":
        return <h4>{field.label}</h4>;
      case "paragraph":
        return <p>{field.label}</p>;
      case "line":
        return (
          <hr
            style={{
              borderTopColor: "var(--border_color)",
              opacity: "1",
            }}
          />
        );
      case "list":
        return (
          <div>
            {/* <h5>{field.label}</h5> */}
            <ul style={{ listStyleType: "disc", paddingLeft: "1rem" }}>
              {field.formBuilderFieldData.map((item) => (
                <li key={item.id}>{item.data}</li>
              ))}
            </ul>
          </div>
        );
      case "inputbox":
        return (
          <>
            <FormInput
              type="text"
              label={field?.label}
              name={field?.label}
              field_type={field?.field_type}
              onChange={handleInputChange}
              error={errors[field.label]}
            />
          </>
        );
      case "textarea":
        return (
          <>
            <label>{field.label}</label>
            <textarea
              //className="globalTextArea"
              className={`${
                errors[field.label]
                  ? "form-control inputError globalTextArea errorInput"
                  : "form-control globalTextArea"
              }`}
              type="text"
              name={field?.label}
              field_type={field?.field_type}
              onChange={handleInputChange}
            ></textarea>
            {errors[field.label] && (
              <p className="errorText">{errors[field.label]}</p>
            )}
          </>
        );
      case "radio":
        return (
          <div>
            <h6>{field.label}</h6>
            {field.formBuilderFieldData.map((option) => (
              <div key={option.id}>
                <input
                  type="radio"
                  id={option.id}
                  name={field.label}
                  value={option.data}
                  onChange={handleInputChange}
                />
                <label htmlFor={option.id} className={classes.inputLabel}>
                  {option.data}
                </label>
              </div>
            ))}
            {errors[field.label] && (
              <p className="errorText">{errors[field.label]}</p>
            )}
          </div>
        );
      case "checkbox":
        return (
          <div>
            <h6>{field.label}</h6>
            {field.formBuilderFieldData.map((option) => (
              <div key={option.id}>
                <input
                  type="checkbox"
                  id={option.id}
                  name={field.label}
                  value={option.data}
                  onChange={handleInputChange}
                />
                <label htmlFor={option.id} className={classes.inputLabel}>
                  {option.data}
                </label>
              </div>
            ))}
            {errors[field.label] && (
              <p className="errorText">{errors[field.label]}</p>
            )}
          </div>
        );
      case "color":
        return (
          <div>
            <div>
              <label>{field.label}</label>
              <div className="colorBorder">
                {field.formBuilderFieldData.map((option) => (
                  <div
                    key={option.id}
                    className="form-check form-check-inline form-check-color"
                  >
                    <input
                      type="radio"
                      id={option.id}
                      name={field.label}
                      value={option.data}
                      onChange={handleInputChange}
                      className="form-check-input"
                    />
                    <label htmlFor={option.id} className={classes.inputLabel}>
                      <div
                        style={{ backgroundColor: option.data }}
                        className="colorBox"
                      />
                    </label>
                  </div>
                ))}
              </div>
            </div>
            {/* <div className="colorBorder">
              {Colors.map((color) => {
                const { id, bgColor } = { ...color };
                return (
                  <div
                    className="form-check form-check-inline form-check-color"
                    key={`color${id}`}
                  >
                    <input
                      type="radio"
                      id={`color${id}`}
                      class="form-check-input"
                      name="color"
                      value={bgColor}
                      onChange={handleInputChange}
                    />
                    <label>
                      <div
                        style={{ backgroundColor: bgColor }}
                        className="colorBox"
                      />
                    </label>
                  </div>
                );
              })}
            </div> */}
          </div>
        );
      case "preDropdown":
        return (
          <div>
            <div className={classes.selectWrapper}>
              <label>{field.label}</label>
              <div className="input-group globalInputWithIcon mb-3 mb-lg-0">
                <select
                  style={{ border: "0" }}
                  name={field.label}
                  className="form-select customSelect"
                  onChange={handleInputChange}
                >
                  <option value="" disabled selected>
                    Select
                  </option>
                  <option value="home">Home</option>
                  <option value="mobile">Mobile</option>
                  <option value="office">Office</option>
                </select>
              </div>
            </div>
          </div>
        );
      case "dropdown":
        return (
          <div>
            <div className={classes.selectWrapper}>
              <label>{field.label}</label>
              <div
                className={`${
                  errors[field.label]
                    ? "inputError input-group globalInputWithIcon"
                    : "input-group globalInputWithIcon"
                }`}
              >
                <select
                  style={{ border: "0" }}
                  name={field.label}
                  className="form-select customSelect"
                  onChange={handleInputChange}
                >
                  {field.formBuilderFieldData.map((item) => (
                    <option key={item.id} value={item.data}>
                      {item.data}
                    </option>
                  ))}
                </select>
              </div>
              {errors[field.label] && (
                <p className="errorText">{errors[field.label]}</p>
              )}
            </div>
          </div>
        );
      case "countryList":
        return (
          <div>
            <div className={classes.selectWrapper}>
              <label>{field.label}</label>
              <div className="input-group globalInputWithIcon">
                <select
                  style={{ border: "0" }}
                  name={field.label}
                  className="form-select customSelect"
                  onChange={handleInputChange}
                >
                  {generateCountryOptions().map((country, index) => (
                    <option key={index} value={country.value}>
                      {country.label}
                    </option>
                  ))}
                </select>
              </div>

              {errors[field.label] && (
                <p className="errorText">{errors[field.label]}</p>
              )}
            </div>
          </div>
        );
      case "date":
        return (
          <div>
            <label className={classes.formLabel}>{field.label}</label>
            {/* <div className="input-group globalInputWithIcon">
              <input
                type="date"
                className="form-control"
                name={field.label}
                onChange={handleInputChange}
                dateFormat="MM/dd/yyyy"
              />
            </div> */}
            <DatePicker
              selected={formData[field.label]}
              onChange={(date) => handleDateInputChange(field.label, date)}
              name={field.label}
              dateFormat="MM/dd/yyyy"
              className="dobInput profile"
              placeholderText="MM/dd/yyyy"
              showYearDropdown
              isClearable
              //maxDate={new Date()}
              yearDropdownItemNumber={110}
              scrollableYearDropdown
            />
            {errors[field.label] && (
              <p className="errorText">{errors[field.label]}</p>
            )}
          </div>
        );
      case "link":
        return (
          <>
            <div>
              <label className={classes.formLabel}>{field.label}</label>
              <input
                type="url"
                name={field.label}
                onChange={handleInputChange}
              />
            </div>
            {errors[field.label] && (
              <p className="errorText">{errors[field.label]}</p>
            )}
          </>
        );
      case "upload":
        return (
          <>
            <div>
              <label className={classes.formLabel}>{field.label}</label>
              <Image
                className={classes.profileImage}
                src={
                  uploadedImageUrl[field.label]
                    ? baseUrl.PUBLIC_URL + uploadedImageUrl[field.label]
                    : pictureicon
                }
                roundedCircle={true}
              />
              <button
                onClick={() => handleUploadModalOpen(field)}
                className="btn btn-primary ms-4"
              >
                Upload
              </button>
            </div>
            {errors[field.label] && (
              <p className="errorText">{errors[field.label]}</p>
            )}
          </>
        );
      case "custom":
        return (
          <div>
            {field.field_type === "location" ? (
              <div className="row">
                <div className="col-lg-4">
                  <div className={classes.formInput}>
                    <FormInput
                      type="text"
                      label="Country"
                      name={"country"}
                      field_type={field?.field_type}
                      onChange={handleInputChange}
                      error={errors[field.label]}
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className={classes.formInput}>
                    <FormInput
                      type="text"
                      label="State"
                      name={"state"}
                      field_type={field?.field_type}
                      onChange={handleInputChange}
                      error={errors[field.label]}
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className={classes.formInput}>
                    <FormInput
                      type="text"
                      label="City"
                      name={"city"}
                      field_type={field?.field_type}
                      onChange={handleInputChange}
                      error={errors[field.label]}
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className={classes.formInput}>
                    <FormInput
                      type="text"
                      label="Address"
                      name={"address"}
                      field_type={field?.field_type}
                      onChange={handleInputChange}
                      error={errors[field.label]}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-12">
                  <label className={classes.formLabel}>{field.label}</label>
                </div>
                <div className="col-md-4">
                  <div className={classes.selectWrapper}>
                    <div
                      className={`${
                        errors[field.label]
                          ? "input-group globalInputWithIcon mb-3 mb-lg-0 errorInput"
                          : "input-group globalInputWithIcon mb-3 mb-lg-0"
                      }`}
                    >
                      <select
                        style={{ border: "0" }}
                        className="form-select customSelect"
                        name="phone_type"
                        onChange={handleInputChange}
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        <option value="home">Home</option>
                        <option value="mobile">Mobile</option>
                        <option value="office">Office</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-8">
                  <PhoneInput
                    country={"us"}
                    enableSearch={true}
                    countryCodeEditable={false}
                    //inputClass="phoneNumberInput errorInput"
                    inputClass={`${
                      errors[field.label]
                        ? "phoneNumberInput globalTextArea errorInput"
                        : "phoneNumberInput globalTextArea"
                    }`}
                    onChange={handlePhoneInputChange}
                  />
                  {errors[field.label] && (
                    <p className="errorText">{errors[field.label]}</p>
                  )}
                </div>
              </div>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  const handleSave = async () => {
    setLoading(true);

    if (image && typeof image === "object") {
      let formDataObj = new FormData();
      formDataObj.append("image_type", "form_builder");
      formDataObj.append("image", image);

      let { status, message, data } = await post(
        IMAGE_UPLOAD,
        formDataObj,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );

      if (status) {
        setUploadedImageUrl((prevUploadedImages) => ({
          ...prevUploadedImages,
          [currentUploadField.label]: data,
        }));
        setFormData((prevFormData) => ({
          ...prevFormData,
          [currentUploadField.label]: data,
        }));
        showToast(status, message);
        setUploadModalOpen(false);
      } else {
        showToast(status, message);
      }
    }
    setLoading(false);
  };
  const handleDateInputChange = (fieldName, date) => {
    setFormData({
      ...formData,
      [fieldName]: date,
    });
  };
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    // Clear the error when user starts typing
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
    if (type === "checkbox") {
      setFormData((prevFormData) => ({
        ...prevFormData,
        [name]: checked
          ? [...(prevFormData[name] || []), value]
          : (prevFormData[name] || []).filter((item) => item !== value),
      }));
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handlePhoneInputChange = (value, country) => {
    setFormData({
      ...formData,
      country_phone: country.dialCode,
      phone_number: value.slice(country.dialCode.length),
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // // Validate form fields
    // const newErrors = {};
    // formBuilderData.formBuilderField.forEach((field) => {
    //   if (field.is_required === 1) {
    //     if (!formData[field.label]?.trim()) {
    //       newErrors[field.label] = `${field.label} is required`;
    //     }
    //   }
    // });

    // if (Object.keys(newErrors).length > 0) {
    //   setErrors(newErrors);
    //   setLoading(false);
    //   return;
    // }

    const newErrors = {};

    formBuilderData.formBuilderField.forEach((field) => {
      const fieldValue = formData[field.label];

      if (field.is_required === 1) {
        if (field.type === "date") {
          const isValidDate =
            fieldValue && !isNaN(new Date(fieldValue).getTime());
          if (!isValidDate) {
            newErrors[field.label] = `${field.label} is required or invalid`;
          }
        } else if (field.type === "custom") {
          if (field.field_type === "contact_no") {
            // Validate each contact field
            if (!formData["phone_type"]) {
              newErrors["Phone Type"] = "Phone Type is required";
            }
            if (!formData["country_phone"]) {
              newErrors["Country Phone"] = "Country Phone is required";
            }
            if (!formData["phone_number"] || !formData["phone_number"].trim()) {
              newErrors["Phone Number"] = "Phone Number is required";
            }
          } else if (field.field_type === "location") {
            // Validate each location field
            if (!formData["address"] || !formData["address"].trim()) {
              newErrors["Address"] = "Address is required";
            }
            if (!formData["country"] || !formData["country"].trim()) {
              newErrors["Country"] = "Country is required";
            }
            if (!formData["state"] || !formData["state"].trim()) {
              newErrors["State"] = "State is required";
            }
            if (!formData["city"] || !formData["city"].trim()) {
              newErrors["City"] = "City is required";
            }
          }
        } else if (typeof fieldValue === "string") {
          // Validate string fields
          if (!fieldValue.trim()) {
            newErrors[field.label] = `${field.label} is required`;
          }
        } else if (Array.isArray(fieldValue)) {
          // Validate array fields
          if (fieldValue.length === 0) {
            newErrors[field.label] = `${field.label} is required`;
          }
        } else if (fieldValue == null) {
          // Validate null or undefined fields
          newErrors[field.label] = `${field.label} is required`;
        }
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    }

    const userLeadData = formBuilderData.formBuilderField.map((field) => {
      let formDataItem;
      if (field.type === "heading" || field.type === "paragraph") {
        formDataItem = {
          form_builder_field_id: field.id,
          label: field.label,
          type: field.type,
          data: field.label,
          field_type: field.field_type,
        };
      } else if (field.type === "list") {
        formDataItem = {
          form_builder_field_id: field.id,
          label: field.label,
          type: field.type,
          data: field.formBuilderFieldData.map((item) => item.data).join(", "),
          field_type: field.field_type,
        };
      } else if (field.type === "checkbox") {
        formDataItem = {
          form_builder_field_id: field.id,
          label: field.label,
          type: field.type,
          //data: formData[field.label].join(", ") || "",
          field_type: field.field_type,
          // data:
          //   formData?.Checkbox?.length > 1
          //     ? formData[field.label].join(", ")
          //     : formData?.Checkbox?.length > 0 &&
          //       formData?.Checkbox?.length === 1
          //     ? formData[field.label]
          //     : "",
          data: Array.isArray(formData?.[field.label])
            ? formData[field.label].length > 1
              ? formData[field.label].join(", ")
              : formData[field.label].length === 1
              ? formData[field.label][0]
              : ""
            : "",
        };
      } else if (field.type === "line") {
        formDataItem = {
          form_builder_field_id: field.id,
          label: field.label,
          type: field.type,
          data: "true",
          field_type: field.field_type,
        };
      } else if (field.type === "custom") {
        if (field.field_type === "contact_no") {
          formDataItem = [
            {
              form_builder_field_id: field.id,
              label: "Phone Type",
              type: "inputbox",
              data: formData["phone_type"] || "home",
              field_type: field.field_type,
            },
            {
              form_builder_field_id: field.id,
              label: "Country Phone",
              type: "inputbox",
              data: formData["country_phone"] || "",
              field_type: field.field_type,
            },
            {
              form_builder_field_id: field.id,
              label: "Phone Number",
              type: "inputbox",
              data: formData["phone_number"] || "",
              field_type: field.field_type,
            },
          ];
        } else if (field.field_type === "location") {
          formDataItem = [
            {
              form_builder_field_id: field.id,
              label: "Address",
              type: "inputbox",
              data: formData["address"] || "",
              field_type: field.field_type,
            },
            {
              form_builder_field_id: field.id,
              label: "Country",
              type: "inputbox",
              data: formData["country"] || "",
              field_type: field.field_type,
            },
            {
              form_builder_field_id: field.id,
              label: "State",
              type: "inputbox",
              data: formData["state"] || "",
              field_type: field.field_type,
            },
            {
              form_builder_field_id: field.id,
              label: "City",
              type: "inputbox",
              data: formData["city"] || "",
              field_type: field.field_type,
            },
          ];
        }
      } else {
        formDataItem = {
          form_builder_field_id: field.id,
          label: field.label,
          type: field.type,
          data: formData[field.label] || "",
          field_type: field.field_type,
        };
      }

      return formDataItem;
    });

    const payload = {
      form_builder_id: formBuilderData.id,
      userLeadData: userLeadData.flat(),
    };
    let { status, message } = await post(
      LEAD_ADD,
      payload,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      showToast("success", "Form submitted successfully");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  return (
    <>
      <div className="container">
        <div className="py-5">
          <div className="row justify-content-center">
            <div className="col-md-7 col-12">
              <div className={classes.mainWrapper}>
                <h6 className={classes.title}>{formBuilderData.name}</h6>
                <div className={classes.cardWrapper}>
                  <div className={classes.generatedForm}>
                    {formBuilderData.formBuilderField.map((field) => (
                      <div key={field.id} className="mb-4">
                        {renderFormField(field)}
                      </div>
                    ))}
                    <div className="text-center mt-4">
                      <button
                        type="submit"
                        className="submitButton"
                        onClick={handleSubmit}
                        disabled={loading === true}
                      >
                        {loading ? "Processing..." : "Submit"}
                      </button>
                    </div>
                  </div>
                </div>

                <div className={classes.cardFooter}>
                  <div className="d-flex align-items-center gap-3 justify-content-center">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.contactco.contactapp"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={playstore} alt="" />
                    </a>
                    <a
                      href="https://apps.apple.com/us/app/contact-co-connect-smarter/id6683301913"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={appstore} alt="" />
                    </a>
                  </div>
                  <p>
                    © {new Date().getFullYear()} Contact Co, All rights
                    reserved.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        centered
        className={`customModal uploadModal`}
        size="sm"
        show={uploadModalOpen}
      >
        <Modal.Header className={classes.mediaModalHeader}>
          <span>Upload</span>
          <img
            src={crossicon}
            alt=""
            style={{ cursor: "pointer" }}
            onClick={handleUploadModalClose}
          />
        </Modal.Header>

        <Modal.Body className={classes.mediaModalBody}>
          <div className="text-center">
            <div
              className={`d-flex justify-content-center align-items-center flex-column position-relative ${classes.imageUpload}`}
            >
              {currentUploadField && (
                <input
                  type="file"
                  accept="image/*"
                  name={currentUploadField.label}
                  field-type={currentUploadField.field_type}
                  onChange={(e) => selectImage(e.target.files[0])}
                />
              )}
            </div>
            <button
              className={`${classes.saveBtn} primary_button mb-3 mt-0`}
              onClick={handleSave}
            >
              Save
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <style type="text/css">
        {`
        .uploadModal{
          color: var(--heading_text_color);
        }
        .colorBorder{
          border: 2px solid var(--border_color);
          border-radius: 60px;
          padding: 9px 15px 0;
        }
        .form-check-color{
          padding-left: 0;
          position:relative;
        }
        .form-check-color .form-check-input{
          float: none;
          margin-left: 0;
          position: absolute;
          opacity: 0;
          margin-top: 0;
          width: 20px;
          height: 20px;
          left: 0;
          right: 0;
          margin: auto;
        }
        .form-check-color .form-check-input + label{margin-bottom:0}
        .form-check-color .form-check-input + label .colorBox {
          width:20px;
          height:20px;
          border-radius:50%;
          border: 3px solid transparent;
        } 
        .form-check-color .form-check-input:checked + label .colorBox {
          width:20px;
          height:20px;
          border-radius:50%;
          border: 3px solid var(--border_color);
        } 
        `}
      </style>
    </>
  );
};

export default PublicFormBuilder;
