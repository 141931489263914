import React from "react";
import classes from "../assets/styles/PageComponentsStyles/Settings/SubscriptionIndividual.module.css";
import { PACKAGES_LISTING } from "../utils/constants/apiUrl";
import { get } from "../server";
import {
  loggedInUser,
  subscribedPackage,
  accountType,
  isOwnerPackage,
  teamIDCheck,
  userIDCheck,
} from "../utils/store";
import { useAtom } from "jotai";
import Loader from "../components/Loader";
import { useEffect } from "react";
import { useState } from "react";
import showToast from "../helpers/showToast";
import { loadStripe } from "@stripe/stripe-js";
import SubscriptionModal from "../components/Modals/SubscriptionModal";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useLogout from "../hooks/useLogout";
import { AiFillCheckCircle } from "react-icons/ai";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import basicicon from "../assets/images/settings_page_icons/basicicon.png";
import proicon from "../assets/images/settings_page_icons/proicon.png";
import premiumicon from "../assets/images/settings_page_icons/premiumicon.png";
// Load Stripe Data
const stripePromise = loadStripe(
  // "pk_test_51OZ7XQSBnxMCCnjEI1odHcAyBmW5WZd48fFUxnvHPpg2LDNKRGGCOJ3sU78SW9vi5wOmuLrwX2qxf8slIZpXFukR00DuK51ScU"
  // "pk_test_51LsD48ErALx3fhV7MXHeYvMoWC4uLJr33gThVHtfuMAbiE9kNWsDMsYmKAcv1KWGSlSBPiQwplojALZAJ1fUk8bO00NnHNL8FY" //sourav send
  "pk_live_51LsD48ErALx3fhV7pL6Gg2Wd2ymytgrKjj1tjb9cJICFlMAO24seG1sVDFTtKBFuOeD9luN1KSlS7Ho3xuY9tRZt00GZDEzFrx"
);

// Stripe OPtions
const elementsOptions = {
  fonts: [
    {
      cssSrc: "https://fonts.googleapis.com/css?family=Roboto",
    },
  ],
  locale: "en",
  placeholder: {
    number: "Card number",
    cvc: "CVC",
    expiry: "MM/YY",
  },
  style: {
    base: {
      fontSize: "16px",
      color: "#fff",
      "::placeholder": {
        color: "#fff",
      },
    },
    invalid: {
      color: "#9e2146",
    },
  },
};

const SubscriptionIndividual = () => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [subscriptions, setSubscriptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedPackage, setSelectedPackage] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [purchasedPackage, setPurchasedPackage] = useAtom(subscribedPackage);
  const [personalAccount, setPersonalAccount] = useAtom(accountType);
  const [isOwnerPackageActive, setIsOwnerPackageActive] =
    useAtom(isOwnerPackage);
  const [userTeamId, setUserTeamId] = useAtom(teamIDCheck);
  const [userID, setUserID] = useAtom(userIDCheck);
  const [activeKeys, setActiveKeys] = useState([]);
  const [selectedSubscriptionType, setSelectedSubscriptionType] = useState();
  const location = useLocation();
  const logout = useLogout();
  const navigate = useNavigate();

  const handleTabSelect = (subscriptionIndex, key) => {
    setActiveKeys((prevActiveKeys) => {
      // Create a copy of the previous activeKeys array
      const updatedActiveKeys = [...prevActiveKeys];
      // Update the active key for the corresponding subscription
      updatedActiveKeys[subscriptionIndex] = key;
      return updatedActiveKeys;
    });
  };

  // Handle Logout
  const handleLogout = () => {
    logout();
  };

  // Load singlePackage data
  const loadSubscriptions = async () => {
    setLoading(true);
    const queryString = `?user_id=${userID}&team_id=${userTeamId}`;
    let { status, message, data } = await get(
      PACKAGES_LISTING + queryString,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      if (data) {
        let ownerpackages = data.filter(
          (singlePackage) => singlePackage.package_type == "personal"
        );
        setSubscriptions(ownerpackages);
      }
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadSubscriptions();
  }, []);

  if (loading) {
    return <Loader />;
  }
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handlePayment = async (obj, subscription_type) => {
    setSelectedPackage(obj);
    setSelectedSubscriptionType(subscription_type);
    setShowModal(true);
  };

  // const checkExpire = () => {
  //   let isTeamPackage = loggedUser?.package_details.filter(
  //     (obj) => obj.team_id == loggedUser?.teams.id
  //   );

  //   let endDate = new Date(isTeamPackage[0]?.end_date);
  //   let todayDate = new Date();

  //   if (todayDate >= endDate) return false;
  //   return true;
  // };

  return (
    <>
      <div className="">
        <div className="d-flex flex-md-row flex-column align-items-center justify-content-between px-3">
          <div className={classes.skipBtnWrapper}>
            <button onClick={handleLogout}>Logout</button>
          </div>
          <h1 className={classes.heading}>Subscription Plans</h1>
          <div className={classes.skipBtnWrapper}>
            <button onClick={() => navigate("/")}>Go Back</button>
          </div>
        </div>
        <div className={classes.mainWrapper}>
          <div
            className={classes.cardsWrapper}
            style={{
              gridTemplateColumns: "repeat(auto-fit, minmax(0, 330px))",
              justifyContent: "center",
            }}
          >
            {subscriptions.length !== 0
              ? subscriptions.map((singlePackage, index) => (
                  <div
                    className={classes.card}
                    key={`pricingCard${index}`}
                    style={{ padding: "20px 5px" }}
                  >
                    {/* <button className={classes.subscriptionType}>
                      {singlePackage.package_name}
                    </button> */}
                    <button className={classes.subscriptionType}>
                      {purchasedPackage.id === singlePackage.id ? (
                        <>
                          {singlePackage.package_name} &nbsp;
                          <AiFillCheckCircle />
                        </>
                      ) : (
                        <>{singlePackage.package_name}</>
                      )}
                    </button>
                    {singlePackage.id === 1 ||
                    singlePackage.id === 2 ||
                    singlePackage.id === 3 ? (
                      <div className={classes.tabsWrapper}>
                        <Tabs
                          defaultActiveKey="monthly"
                          id={`uncontrolled-tab-example-${index}`}
                          className="customSubscriptionTabs"
                          transition={false}
                          activeKey={activeKeys[index]}
                          onSelect={(k) => handleTabSelect(index, k)}
                        >
                          <Tab eventKey="monthly" title="Monthly">
                            <div className={classes.imgWrapper}>
                              <img
                                src={
                                  singlePackage.package_name === "Starter"
                                    ? basicicon
                                    : singlePackage.package_name === "Premium"
                                    ? proicon
                                    : premiumicon
                                }
                                alt={
                                  singlePackage.package_name === "Starter"
                                    ? "Starter"
                                    : singlePackage.package_name === "Premium"
                                    ? "Premium"
                                    : "Elite"
                                }
                              />
                            </div>
                            <h3 className={classes.title}>
                              {/* {singlePackage.id === 5
                            ? `$${singlePackage.price_monthly}/month`
                            : "Custom Price"} */}
                              {singlePackage.package_name === "Starter"
                                ? "Free"
                                : singlePackage.package_name === "Enterprise"
                                ? "Custom Price"
                                : `$${singlePackage.price_monthly}`}
                            </h3>

                            <ul
                              className={classes.list}
                              style={{ color: "#fff" }}
                            >
                              {/* Connection */}
                              {singlePackage.limit_connections_save === 0 ? (
                                <li>
                                  <div
                                    className="d-flex align-items-center justify-content-between"
                                    style={{
                                      color: "#535f79",
                                    }}
                                  >
                                    <p>Connections</p>
                                    <p>
                                      {singlePackage.limit_connections_save}
                                    </p>
                                  </div>
                                </li>
                              ) : (
                                <li>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p>Connections</p>
                                    <p>
                                      {singlePackage.limit_connections_save}
                                    </p>
                                  </div>
                                </li>
                              )}

                              {/*  Card Section */}
                              {singlePackage.limit_card_create === 0 ? (
                                <li>
                                  <div
                                    className="d-flex align-items-center justify-content-between"
                                    style={{
                                      color: "#535f79",
                                    }}
                                  >
                                    <p>Personal/Business Card</p>
                                    <p>{singlePackage.limit_card_create}</p>
                                  </div>
                                </li>
                              ) : singlePackage.id === 1 ? (
                                <li>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p>Personal/Business Card</p>
                                    <p></p>
                                  </div>
                                </li>
                              ) : (
                                <li>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p>Personal/Business Card</p>
                                    <p>{singlePackage.limit_card_create}</p>
                                  </div>
                                </li>
                              )}

                              {/* Payments */}
                              {singlePackage.limit_payment_create === 0 ? (
                                <li>
                                  <div
                                    className="d-flex align-items-center justify-content-between"
                                    style={{
                                      color: "#535f79",
                                    }}
                                  >
                                    <p>Payments cards</p>
                                    <p>{singlePackage.limit_payment_create}</p>
                                  </div>
                                </li>
                              ) : singlePackage.id === 1 ? (
                                <li>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p>Payments cards</p>
                                    <p></p>
                                  </div>
                                </li>
                              ) : (
                                <li>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p>Payments cards</p>
                                    <p>{singlePackage.limit_payment_create}</p>
                                  </div>
                                </li>
                              )}

                              {/* Emergency Contact */}
                              {singlePackage.limit_emergency_contact_create ===
                              0 ? (
                                <li>
                                  <div
                                    className="d-flex align-items-center justify-content-between"
                                    style={{
                                      color: "#535f79",
                                    }}
                                  >
                                    <p>Emergency Contacts</p>
                                    <p>
                                      {
                                        singlePackage.limit_emergency_contact_create
                                      }
                                    </p>
                                  </div>
                                </li>
                              ) : singlePackage.id === 1 ? (
                                <li>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p>Emergency Contacts</p>
                                    <p></p>
                                  </div>
                                </li>
                              ) : (
                                <li>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p>Emergency Contacts</p>
                                    <p>
                                      {
                                        singlePackage.limit_emergency_contact_create
                                      }
                                    </p>
                                  </div>
                                </li>
                              )}

                              {/* Custom Url */}
                              {singlePackage.limit_custom_url_create === 0 ? (
                                <li>
                                  <div
                                    className="d-flex align-items-center justify-content-between"
                                    style={{
                                      color: "#535f79",
                                    }}
                                  >
                                    <p>Custom URL</p>
                                    <p>
                                      {singlePackage.limit_custom_url_create}
                                    </p>
                                  </div>
                                </li>
                              ) : singlePackage.id === 1 ? (
                                <li>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p>Custom URL</p>
                                    <p></p>
                                  </div>
                                </li>
                              ) : (
                                <li>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p>Custom URL</p>
                                    <p>
                                      {singlePackage.limit_custom_url_create}
                                    </p>
                                  </div>
                                </li>
                              )}

                              {/* File Upload Url */}
                              {singlePackage.limit_file_upload_create === 0 ? (
                                <li>
                                  <div
                                    className="d-flex align-items-center justify-content-between"
                                    style={{
                                      color: "#535f79",
                                    }}
                                  >
                                    <p>File Upload URL</p>
                                    <p>
                                      {singlePackage.limit_file_upload_create}
                                    </p>
                                  </div>
                                </li>
                              ) : singlePackage.id === 1 ? (
                                <li>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p>File Upload URL</p>
                                    <p></p>
                                  </div>
                                </li>
                              ) : (
                                <li>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p>File Upload URL</p>
                                    <p>
                                      {singlePackage.limit_file_upload_create}
                                    </p>
                                  </div>
                                </li>
                              )}

                              {singlePackage.limit_memory === 0 ? (
                                <li>
                                  <div
                                    className="d-flex align-items-center justify-content-between"
                                    style={{
                                      color: "#535f79",
                                    }}
                                  >
                                    <p>Memory</p>
                                    <p>{singlePackage.limit_memory}</p>
                                  </div>
                                </li>
                              ) : singlePackage.id === 1 ? (
                                <li>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p>Memory</p>
                                    <p></p>
                                  </div>
                                </li>
                              ) : (
                                <li>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p>Memory</p>
                                    <p>{singlePackage.limit_memory}</p>
                                  </div>
                                </li>
                              )}
                              {singlePackage.limit_form_builder === 0 ? (
                                <li>
                                  <div
                                    className="d-flex align-items-center justify-content-between"
                                    style={{
                                      color: "#535f79",
                                    }}
                                  >
                                    <p>Lead Builder</p>
                                    <p>{singlePackage.limit_form_builder}</p>
                                  </div>
                                </li>
                              ) : singlePackage.id === 1 ? (
                                <li>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p>Lead Builder</p>
                                    <p></p>
                                  </div>
                                </li>
                              ) : (
                                <li>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p>Lead Builder</p>
                                    <p>{singlePackage.limit_form_builder}</p>
                                  </div>
                                </li>
                              )}

                              {singlePackage.limit_medical_id === 0 ? (
                                <li>
                                  <div
                                    className="d-flex align-items-center justify-content-between"
                                    style={{
                                      color: "#535f79",
                                    }}
                                  >
                                    <p>Medical ID</p>
                                    <p>{singlePackage.limit_medical_id}</p>
                                  </div>
                                </li>
                              ) : singlePackage.id === 1 ? (
                                <li>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p>Medical ID</p>
                                    <p></p>
                                  </div>
                                </li>
                              ) : (
                                <li>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p>Medical ID</p>
                                    <p>{singlePackage.limit_medical_id}</p>
                                  </div>
                                </li>
                              )}

                              {singlePackage.limit_dynamic_qr_code === 0 ? (
                                <li>
                                  <div
                                    className="d-flex align-items-center justify-content-between"
                                    style={{
                                      color: "#535f79",
                                    }}
                                  >
                                    <p>Dynamic QR Code</p>
                                    <p>{singlePackage.limit_dynamic_qr_code}</p>
                                  </div>
                                </li>
                              ) : singlePackage.id === 1 ? (
                                <li>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p>Dynamic QR Code</p>
                                    <p></p>
                                  </div>
                                </li>
                              ) : (
                                <li>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p>Dynamic QR Code</p>
                                    <p>{singlePackage.limit_dynamic_qr_code}</p>
                                  </div>
                                </li>
                              )}

                              {/*Custom Button */}
                              {singlePackage.enable_custom_buttons === 0 ? (
                                <li>
                                  <div
                                    className="d-flex align-items-center justify-content-between"
                                    style={{
                                      color: "#535f79",
                                    }}
                                  >
                                    <p>Custom Button</p>
                                    <p>
                                      {singlePackage.enable_custom_buttons
                                        ? "Yes"
                                        : "No"}
                                    </p>
                                  </div>
                                </li>
                              ) : (
                                <li>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p>Custom Button</p>
                                    <p>
                                      {singlePackage.enable_custom_buttons
                                        ? "Yes"
                                        : "No"}
                                    </p>
                                  </div>
                                </li>
                              )}

                              {/*Email Notification Button */}
                              {/* {singlePackage.enable_email_notifications == 0 ? (
                        <li>
                          <div
                            className="d-flex align-items-center justify-content-between"
                            style={{ color: "var(--primary_text_color)" }}
                          >
                            <p>Email Notification</p>
                            <p>
                              {singlePackage.enable_email_notifications
                                ? "True"
                                : "False"}
                            </p>
                          </div>
                        </li>
                      ) : (
                        <li>
                          <div className="d-flex align-items-center justify-content-between">
                            <p>Email Notification</p>
                            <p>
                              {singlePackage.enable_email_notifications
                                ? "True"
                                : "False"}
                            </p>
                          </div>
                        </li>
                      )} */}
                              {/*Maximum User Limit */}
                              {singlePackage.user_limit > 0 && (
                                <li>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p>Maximum User Limit</p>
                                    <p>{singlePackage.user_limit}</p>
                                  </div>
                                </li>
                              )}
                            </ul>
                            {singlePackage.id === 1 ? (
                              <p className="noteText text-start mt-2">
                                In free mode you are only able to create one
                                function at a time and deleting it allows you to
                                create another.
                              </p>
                            ) : (
                              ""
                            )}
                            {singlePackage.id === 6 ? (
                              <div className={classes.btnWrapper}>
                                <Link
                                  to={`/PackageContactForm/${singlePackage.id}`}
                                >
                                  <button className={classes.btn}>
                                    Contact Us
                                  </button>
                                </Link>
                              </div>
                            ) : purchasedPackage.id === singlePackage.id ? (
                              <>
                                <div className={classes.btnWrapper}>
                                  <button className={classes.btn} disabled>
                                    Subscribed{" "}
                                    <div
                                      style={{
                                        display: "inline",
                                        // backgroundColor: "white",
                                      }}
                                    >
                                      <AiFillCheckCircle />
                                    </div>
                                  </button>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className={classes.btnWrapper}>
                                  <button
                                    className={classes.btn}
                                    onClick={() =>
                                      handlePayment(singlePackage, "monthly")
                                    }
                                  >
                                    Select Package
                                  </button>
                                </div>
                              </>
                            )}
                          </Tab>
                          <Tab eventKey="yearly" title="Yearly">
                            <div className={classes.imgWrapper}>
                              <img
                                src={
                                  singlePackage.package_name === "Starter"
                                    ? basicicon
                                    : singlePackage.package_name === "Premium"
                                    ? proicon
                                    : premiumicon
                                }
                                alt={
                                  singlePackage.package_name === "Starter"
                                    ? "Starter"
                                    : singlePackage.package_name === "Premium"
                                    ? "Premium"
                                    : "Elite"
                                }
                              />
                            </div>
                            <h3 className={classes.title}>
                              {/* {singlePackage.id === 5
                            ? `$${singlePackage.price_monthly}/month`
                            : "Custom Price"} */}
                              {singlePackage.package_name === "Starter"
                                ? "Free"
                                : singlePackage.package_name === "Enterprise"
                                ? "Custom Price"
                                : `$${singlePackage.price_yearly}`}
                            </h3>

                            <ul className={classes.list}>
                              {/* Connection */}
                              {singlePackage.limit_connections_save === 0 ? (
                                <li>
                                  <div
                                    className="d-flex align-items-center justify-content-between"
                                    style={{
                                      color: "#535f79",
                                    }}
                                  >
                                    <p>Connections</p>
                                    <p>
                                      {singlePackage.limit_connections_save}
                                    </p>
                                  </div>
                                </li>
                              ) : (
                                <li>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p>Connections</p>
                                    <p>
                                      {singlePackage.limit_connections_save}
                                    </p>
                                  </div>
                                </li>
                              )}
                              {/*  Card Section */}
                              {singlePackage.limit_card_create === 0 ? (
                                <li>
                                  <div
                                    className="d-flex align-items-center justify-content-between"
                                    style={{
                                      color: "#535f79",
                                    }}
                                  >
                                    <p>Personal/Business Card</p>
                                    <p>{singlePackage.limit_card_create}</p>
                                  </div>
                                </li>
                              ) : singlePackage.id === 1 ? (
                                <li>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p>Personal/Business Card</p>
                                    <p></p>
                                  </div>
                                </li>
                              ) : (
                                <li>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p>Personal/Business Card</p>
                                    <p>{singlePackage.limit_card_create}</p>
                                  </div>
                                </li>
                              )}

                              {/* Payments */}
                              {singlePackage.limit_payment_create === 0 ? (
                                <li>
                                  <div
                                    className="d-flex align-items-center justify-content-between"
                                    style={{
                                      color: "#535f79",
                                    }}
                                  >
                                    <p>Payments cards</p>
                                    <p>{singlePackage.limit_payment_create}</p>
                                  </div>
                                </li>
                              ) : singlePackage.id === 1 ? (
                                <li>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p>Payments cards</p>
                                    <p></p>
                                  </div>
                                </li>
                              ) : (
                                <li>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p>Payments cards</p>
                                    <p>{singlePackage.limit_payment_create}</p>
                                  </div>
                                </li>
                              )}

                              {/* Emergency Contact */}
                              {singlePackage.limit_emergency_contact_create ===
                              0 ? (
                                <li>
                                  <div
                                    className="d-flex align-items-center justify-content-between"
                                    style={{
                                      color: "#535f79",
                                    }}
                                  >
                                    <p>Emergency Contacts</p>
                                    <p>
                                      {
                                        singlePackage.limit_emergency_contact_create
                                      }
                                    </p>
                                  </div>
                                </li>
                              ) : singlePackage.id === 1 ? (
                                <li>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p>Emergency Contacts</p>
                                    <p></p>
                                  </div>
                                </li>
                              ) : (
                                <li>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p>Emergency Contacts</p>
                                    <p>
                                      {
                                        singlePackage.limit_emergency_contact_create
                                      }
                                    </p>
                                  </div>
                                </li>
                              )}

                              {/* Custom Url */}
                              {singlePackage.limit_custom_url_create === 0 ? (
                                <li>
                                  <div
                                    className="d-flex align-items-center justify-content-between"
                                    style={{
                                      color: "#535f79",
                                    }}
                                  >
                                    <p>Custom URL</p>
                                    <p>
                                      {singlePackage.limit_custom_url_create}
                                    </p>
                                  </div>
                                </li>
                              ) : singlePackage.id === 1 ? (
                                <li>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p>Custom URL</p>
                                    <p></p>
                                  </div>
                                </li>
                              ) : (
                                <li>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p>Custom URL</p>
                                    <p>
                                      {singlePackage.limit_custom_url_create}
                                    </p>
                                  </div>
                                </li>
                              )}

                              {/* File Upload Url */}
                              {singlePackage.limit_file_upload_create === 0 ? (
                                <li>
                                  <div
                                    className="d-flex align-items-center justify-content-between"
                                    style={{
                                      color: "#535f79",
                                    }}
                                  >
                                    <p>File Upload URL</p>
                                    <p>
                                      {singlePackage.limit_file_upload_create}
                                    </p>
                                  </div>
                                </li>
                              ) : singlePackage.id === 1 ? (
                                <li>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p>File Upload URL</p>
                                    <p></p>
                                  </div>
                                </li>
                              ) : (
                                <li>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p>File Upload URL</p>
                                    <p>
                                      {singlePackage.limit_file_upload_create}
                                    </p>
                                  </div>
                                </li>
                              )}

                              {singlePackage.limit_memory === 0 ? (
                                <li>
                                  <div
                                    className="d-flex align-items-center justify-content-between"
                                    style={{
                                      color: "#535f79",
                                    }}
                                  >
                                    <p>Memory</p>
                                    <p>{singlePackage.limit_memory}</p>
                                  </div>
                                </li>
                              ) : singlePackage.id === 1 ? (
                                <li>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p>Memory</p>
                                    <p></p>
                                  </div>
                                </li>
                              ) : (
                                <li>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p>Memory</p>
                                    <p>{singlePackage.limit_memory}</p>
                                  </div>
                                </li>
                              )}
                              {singlePackage.limit_form_builder === 0 ? (
                                <li>
                                  <div
                                    className="d-flex align-items-center justify-content-between"
                                    style={{
                                      color: "#535f79",
                                    }}
                                  >
                                    <p>Lead Builder</p>
                                    <p>{singlePackage.limit_form_builder}</p>
                                  </div>
                                </li>
                              ) : singlePackage.id === 1 ? (
                                <li>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p>Lead Builder</p>
                                    <p></p>
                                  </div>
                                </li>
                              ) : (
                                <li>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p>Lead Builder</p>
                                    <p>{singlePackage.limit_form_builder}</p>
                                  </div>
                                </li>
                              )}

                              {singlePackage.limit_medical_id === 0 ? (
                                <li>
                                  <div
                                    className="d-flex align-items-center justify-content-between"
                                    style={{
                                      color: "#535f79",
                                    }}
                                  >
                                    <p>Medical ID</p>
                                    <p>{singlePackage.limit_medical_id}</p>
                                  </div>
                                </li>
                              ) : singlePackage.id === 1 ? (
                                <li>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p>Medical ID</p>
                                    <p></p>
                                  </div>
                                </li>
                              ) : (
                                <li>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p>Medical ID</p>
                                    <p>{singlePackage.limit_medical_id}</p>
                                  </div>
                                </li>
                              )}

                              {singlePackage.limit_dynamic_qr_code === 0 ? (
                                <li>
                                  <div
                                    className="d-flex align-items-center justify-content-between"
                                    style={{
                                      color: "#535f79",
                                    }}
                                  >
                                    <p>Dynamic QR Code</p>
                                    <p>{singlePackage.limit_dynamic_qr_code}</p>
                                  </div>
                                </li>
                              ) : singlePackage.id === 1 ? (
                                <li>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p>Dynamic QR Code</p>
                                    <p></p>
                                  </div>
                                </li>
                              ) : (
                                <li>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p>Dynamic QR Code</p>
                                    <p>{singlePackage.limit_dynamic_qr_code}</p>
                                  </div>
                                </li>
                              )}

                              {/*Custom Button */}
                              {singlePackage.enable_custom_buttons === 0 ? (
                                <li>
                                  <div
                                    className="d-flex align-items-center justify-content-between"
                                    style={{
                                      color: "#535f79",
                                    }}
                                  >
                                    <p>Custom Button</p>
                                    <p>
                                      {singlePackage.enable_custom_buttons
                                        ? "Yes"
                                        : "No"}
                                    </p>
                                  </div>
                                </li>
                              ) : (
                                <li>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <p>Custom Button</p>
                                    <p>
                                      {singlePackage.enable_custom_buttons
                                        ? "Yes"
                                        : "No"}
                                    </p>
                                  </div>
                                </li>
                              )}
                            </ul>

                            {singlePackage.id === 1 ? (
                              <p className="noteText text-start">
                                In free mode you are only able to create one
                                function at a time and deleting it allows you to
                                create another.
                              </p>
                            ) : (
                              ""
                            )}
                            {singlePackage.id === 6 ? (
                              <div className={classes.btnWrapper}>
                                <Link
                                  to={`/PackageContactForm/${singlePackage.id}`}
                                >
                                  <button className={classes.btn}>
                                    Contact Us
                                  </button>
                                </Link>
                              </div>
                            ) : purchasedPackage.id === singlePackage.id ? (
                              <>
                                <div className={classes.btnWrapper}>
                                  <button className={classes.btn} disabled>
                                    Subscribed{" "}
                                    <div
                                      style={{
                                        display: "inline",
                                        // backgroundColor: "white",
                                      }}
                                    >
                                      <AiFillCheckCircle />
                                    </div>
                                  </button>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className={classes.btnWrapper}>
                                  <button
                                    className={classes.btn}
                                    onClick={() =>
                                      handlePayment(singlePackage, "yearly")
                                    }
                                  >
                                    Select Package
                                  </button>
                                </div>
                              </>
                            )}
                          </Tab>
                        </Tabs>
                      </div>
                    ) : (
                      <div className={classes.tabsWrapper}>
                        <div className={classes.imgWrapper}>
                          <img
                            src={
                              singlePackage.package_name === "Starter"
                                ? basicicon
                                : singlePackage.package_name === "Premium"
                                ? proicon
                                : premiumicon
                            }
                            alt={
                              singlePackage.package_name === "Starter"
                                ? "Starter"
                                : singlePackage.package_name === "Premium"
                                ? "Premium"
                                : "Elite"
                            }
                          />
                        </div>
                        <h3 className={classes.title}>
                          {/* {singlePackage.id === 5
                            ? `$${singlePackage.price_monthly}/month`
                            : "Custom Price"} */}
                          {singlePackage.package_name === "Starter"
                            ? "Free"
                            : singlePackage.package_name === "Enterprise"
                            ? "Custom Price"
                            : `$${singlePackage.price_monthly}`}
                        </h3>

                        <ul className={classes.list}>
                          {/* Connection */}
                          {singlePackage.limit_connections_save === 0 ? (
                            <li>
                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ color: "#535f79" }}
                              >
                                <p>Connections</p>
                                <p>{singlePackage.limit_connections_save}</p>
                              </div>
                            </li>
                          ) : (
                            <li>
                              <div className="d-flex align-items-center justify-content-between">
                                <p>Connections</p>
                                <p>{singlePackage.limit_connections_save}</p>
                              </div>
                            </li>
                          )}
                          {/*  Card Section */}
                          {singlePackage.limit_card_create === 0 ? (
                            <li>
                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ color: "#535f79" }}
                              >
                                <p>Personal/Business Card</p>
                                <p>{singlePackage.limit_card_create}</p>
                              </div>
                            </li>
                          ) : singlePackage.id === 1 ? (
                            <li>
                              <div className="d-flex align-items-center justify-content-between">
                                <p>Personal/Business Card</p>
                                <p></p>
                              </div>
                            </li>
                          ) : (
                            <li>
                              <div className="d-flex align-items-center justify-content-between">
                                <p>Personal/Business Card</p>
                                <p>{singlePackage.limit_card_create}</p>
                              </div>
                            </li>
                          )}

                          {/* Payments */}
                          {singlePackage.limit_payment_create == 0 ? (
                            <li>
                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ color: "#535f79" }}
                              >
                                <p>Payments cards</p>
                                <p>{singlePackage.limit_payment_create}</p>
                              </div>
                            </li>
                          ) : singlePackage.id === 1 ? (
                            <li>
                              <div className="d-flex align-items-center justify-content-between">
                                <p>Payments cards</p>
                                <p></p>
                              </div>
                            </li>
                          ) : (
                            <li>
                              <div className="d-flex align-items-center justify-content-between">
                                <p>Payments cards</p>
                                <p>{singlePackage.limit_payment_create}</p>
                              </div>
                            </li>
                          )}

                          {/* Emergency Contact */}
                          {singlePackage.limit_emergency_contact_create == 0 ? (
                            <li>
                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ color: "#535f79" }}
                              >
                                <p>Emergency Contacts</p>
                                <p>
                                  {singlePackage.limit_emergency_contact_create}
                                </p>
                              </div>
                            </li>
                          ) : singlePackage.id === 1 ? (
                            <li>
                              <div className="d-flex align-items-center justify-content-between">
                                <p>Emergency Contacts</p>
                                <p></p>
                              </div>
                            </li>
                          ) : (
                            <li>
                              <div className="d-flex align-items-center justify-content-between">
                                <p>Emergency Contacts</p>
                                <p>
                                  {singlePackage.limit_emergency_contact_create}
                                </p>
                              </div>
                            </li>
                          )}

                          {/* Custom Url */}
                          {singlePackage.limit_custom_url_create == 0 ? (
                            <li>
                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ color: "#535f79" }}
                              >
                                <p>Custom URL</p>
                                <p>{singlePackage.limit_custom_url_create}</p>
                              </div>
                            </li>
                          ) : singlePackage.id === 1 ? (
                            <li>
                              <div className="d-flex align-items-center justify-content-between">
                                <p>Custom URL</p>
                                <p></p>
                              </div>
                            </li>
                          ) : (
                            <li>
                              <div className="d-flex align-items-center justify-content-between">
                                <p>Custom URL</p>
                                <p>{singlePackage.limit_custom_url_create}</p>
                              </div>
                            </li>
                          )}

                          {/* File Upload Url */}
                          {singlePackage.limit_file_upload_create == 0 ? (
                            <li>
                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ color: "#535f79" }}
                              >
                                <p>File Upload URL</p>
                                <p>{singlePackage.limit_file_upload_create}</p>
                              </div>
                            </li>
                          ) : singlePackage.id === 1 ? (
                            <li>
                              <div className="d-flex align-items-center justify-content-between">
                                <p>File Upload URL</p>
                                <p></p>
                              </div>
                            </li>
                          ) : (
                            <li>
                              <div className="d-flex align-items-center justify-content-between">
                                <p>File Upload URL</p>
                                <p>{singlePackage.limit_file_upload_create}</p>
                              </div>
                            </li>
                          )}
                          {singlePackage.limit_memory == 0 ? (
                            <li>
                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ color: "#535f79" }}
                              >
                                <p>Memory</p>
                                <p>{singlePackage.limit_memory}</p>
                              </div>
                            </li>
                          ) : singlePackage.id === 1 ? (
                            <li>
                              <div className="d-flex align-items-center justify-content-between">
                                <p>Memory</p>
                                <p></p>
                              </div>
                            </li>
                          ) : (
                            <li>
                              <div className="d-flex align-items-center justify-content-between">
                                <p>Memory</p>
                                <p>{singlePackage.limit_memory}</p>
                              </div>
                            </li>
                          )}
                          {singlePackage.limit_form_builder == 0 ? (
                            <li>
                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ color: "#535f79" }}
                              >
                                <p>Lead Builder</p>
                                <p>{singlePackage.limit_form_builder}</p>
                              </div>
                            </li>
                          ) : singlePackage.id === 1 ? (
                            <li>
                              <div className="d-flex align-items-center justify-content-between">
                                <p>Lead Builder</p>
                                <p></p>
                              </div>
                            </li>
                          ) : (
                            <li>
                              <div className="d-flex align-items-center justify-content-between">
                                <p>Lead Builder</p>
                                <p>{singlePackage.limit_form_builder}</p>
                              </div>
                            </li>
                          )}

                          {singlePackage.limit_medical_id == 0 ? (
                            <li>
                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ color: "#535f79" }}
                              >
                                <p>Medical ID</p>
                                <p>{singlePackage.limit_medical_id}</p>
                              </div>
                            </li>
                          ) : singlePackage.id === 1 ? (
                            <li>
                              <div className="d-flex align-items-center justify-content-between">
                                <p>Medical ID</p>
                                <p></p>
                              </div>
                            </li>
                          ) : (
                            <li>
                              <div className="d-flex align-items-center justify-content-between">
                                <p>Medical ID</p>
                                <p>{singlePackage.limit_medical_id}</p>
                              </div>
                            </li>
                          )}

                          {singlePackage.limit_dynamic_qr_code == 0 ? (
                            <li>
                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ color: "#535f79" }}
                              >
                                <p>Dynamic QR Code</p>
                                <p>{singlePackage.limit_dynamic_qr_code}</p>
                              </div>
                            </li>
                          ) : singlePackage.id === 1 ? (
                            <li>
                              <div className="d-flex align-items-center justify-content-between">
                                <p>Dynamic QR Code</p>
                                <p></p>
                              </div>
                            </li>
                          ) : (
                            <li>
                              <div className="d-flex align-items-center justify-content-between">
                                <p>Dynamic QR Code</p>
                                <p>{singlePackage.limit_dynamic_qr_code}</p>
                              </div>
                            </li>
                          )}

                          {/*Custom Button */}
                          {singlePackage.enable_custom_buttons == 0 ? (
                            <li>
                              <div
                                className="d-flex align-items-center justify-content-between"
                                style={{ color: "#535f79" }}
                              >
                                <p>Custom Button</p>
                                <p>
                                  {singlePackage.enable_custom_buttons
                                    ? "Yes"
                                    : "No"}
                                </p>
                              </div>
                            </li>
                          ) : (
                            <li>
                              <div className="d-flex align-items-center justify-content-between">
                                <p>Custom Button</p>
                                <p>
                                  {singlePackage.enable_custom_buttons
                                    ? "Yes"
                                    : "No"}
                                </p>
                              </div>
                            </li>
                          )}
                        </ul>

                        {singlePackage.id === 4 ? (
                          <>
                            <div className={classes.couponWrapper}>
                              <div class="form-check text-start">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  value=""
                                  id="flexCheckDefault"
                                />
                                <label
                                  class="form-check-label"
                                  for="flexCheckDefault"
                                >
                                  Do you have a coupon?
                                </label>
                              </div>
                            </div>
                            <div className={classes.btnWrapper}>
                              <Link
                                to={`/PackageContactForm/${singlePackage.id}`}
                              >
                                <button className={classes.btn}>
                                  Contact Us
                                </button>
                              </Link>
                            </div>
                          </>
                        ) : purchasedPackage.id == singlePackage.id ? (
                          <>
                            <div className={classes.btnWrapper}>
                              <button className={classes.btn} disabled>
                                Subscribed{" "}
                                <div
                                  style={{
                                    display: "inline",
                                    // backgroundColor: "white",
                                  }}
                                >
                                  <AiFillCheckCircle />
                                </div>
                              </button>

                              {singlePackage.id === 1 ? (
                                ""
                              ) : (
                                <button
                                  className={`${classes.outlineBtn} mt-4`}
                                  //onClick={handleCancelSubscription}
                                >
                                  Cancel Subscription
                                </button>
                              )}
                            </div>
                          </>
                        ) : (
                          <>
                            {singlePackage.id == 7 ? (
                              <div className={classes.btnWrapper}>
                                <Link
                                  to={`/PackageContactForm/${singlePackage.id}`}
                                >
                                  <button className={classes.btn}>
                                    Contact Us
                                  </button>
                                </Link>
                              </div>
                            ) : (
                              <div className={classes.btnWrapper}>
                                <button
                                  className={classes.btn}
                                  onClick={() =>
                                    handlePayment(
                                      singlePackage,
                                      singlePackage.duration_type
                                    )
                                  }
                                >
                                  Select Package
                                </button>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    )}
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>
      {showModal && (
        <SubscriptionModal
          show={showModal}
          stripePromise={stripePromise}
          handleCloseModal={handleCloseModal}
          selectedPackage={selectedPackage}
          selectedSubscriptionType={selectedSubscriptionType}
          elementsOptions={elementsOptions}
        />
      )}
      <style type="text/css">
        {`
          html, body{
            background: #1e1e1e;
          }
          .noteText{
            color: rgb(227, 82, 82); 
            font-size: 12px; 
            text-align: left;
            margin-bottom:0;
          }
          .customSubscriptionTabs button {
            color: #535f79 !important;
            background: #21293b !important;
          }
        `}
      </style>
    </>
  );
};

export default SubscriptionIndividual;
