import React, { useEffect, useState, useMemo } from "react";
import classes from "../../../assets/styles/Pages/CouponManagement/CouponManagement.module.css";
import { useAtom } from "jotai";
import { loggedInUser } from "../../../utils/store";
import Loader from "../../../components/Loader";
import "react-datepicker/dist/react-datepicker.css";
import { deleteRecord, get, post, put } from "../../../server";
import { GENERATE_QRCODE } from "../../../utils/constants/apiUrl";
import showToast from "../../../helpers/showToast";
import { Link } from "react-router-dom";
import DataTable, { createTheme } from "react-data-table-component";
import { BsThreeDotsVertical } from "react-icons/bs";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal";
import FormInput from "../../../components/Forms/FormInput";
import searchIcon from "../../../assets/images/global_icons/searchicon.svg";
import { useParams } from "react-router-dom";
import GenerateQRCodeEditModal from "../../../components/Modals/GenerateQRCodeEditModal";
const AllQRCode = () => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [loading, setLoading] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [errors, setErrors] = useState({});
  const [generateQRCode, setGenerateQRCode] = useState([]);
  const [couponId, setCouponId] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [contentEditModal, setContentEditModal] = useState(false);
  const [selectedContentEditModal, setSelectedContentEditModal] = useState({});
  let { id } = useParams();
  const handleFilter = (e) => {
    setFilterText(e.target.value);
  };

  const generateQRCodeList = async () => {
    setLoading(true);
    let { status, message, data } = await get(
      GENERATE_QRCODE,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      if (data.length > 0) {
        setGenerateQRCode(data);
      }
    }
    // else {
    //   showToast(status, message);
    // }
    setLoading(false);
  };

  const filteredItems = useMemo(() => {
    const filtered = generateQRCode.filter(
      (item) =>
        `${item.name || ""}`.toLowerCase().includes(filterText.toLowerCase()) ||
        `${item.coupon || ""}`
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
        `${item.discount || ""}`.includes(filterText)
    );

    return filtered;
  }, [filterText, generateQRCode]);

  createTheme(
    "solarized",
    {
      background: {
        default: "background: var(--secondary_color)",
      },
    },
    "dark"
  );

  const columns = [
    {
      name: "Name",
      cell: (row) => <p className="globalDetail">{row.name}</p>,
    },
    {
      name: "Type",
      cell: (row) => (
        <p className="globalDetail">
          {row.type
            ?.split("_")
            .map((text) => text.charAt(0).toUpperCase() + text.slice(1))
            .join(" ")}
        </p>
      ),
    },
    {
      name: "Quantity",
      cell: (row) => <p className="globalDetail">{row.quantity}</p>,
    },
    {
      name: "Created Date",
      cell: (row) => {
        const creationDate = new Date(row.created_at);
        const formattedDate = creationDate.toLocaleString();
        return <p className="globalDetail">{formattedDate}</p>;
      },
    },
    {
      name: "Action",
      right: "true",
      cell: (row) => (
        <div className={classes.action}>
          <button
            className="btn customActionDropdown dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <BsThreeDotsVertical className={"globalVerticalThreeDots"} />
          </button>
          <ul className="customActionDropdownMenu dropdown-menu dropdown-menu-end ">
            {row.type === "profile_qr_code" ? (
              <li>
                <Link className="dropdown-item" to={`batchQRCode/${row.id}`}>
                  Details
                </Link>
              </li>
            ) : (
              ""
            )}
            {row.type === "custom_qr_code" ? (
              <li>
                <Link
                  className="dropdown-item"
                  onClick={() => handleStatusChange(row)}
                >
                  Edit
                </Link>
              </li>
            ) : (
              ""
            )}
            <li>
              <Link className="dropdown-item" to={row.export_file}>
                Download
              </Link>
            </li>
            <li>
              <Link
                className="dropdown-item"
                to="#"
                style={{ color: "#E35252" }}
                onClick={() => handleDelete(row.id)}
              >
                Delete
              </Link>
            </li>
            {/* <li>
              <Link className="dropdown-item" to={`AddEditCoupons/${row.id}`}>
                Edit
              </Link>
            </li>
            <li>
              <Link
                className="dropdown-item"
                to="#"
                style={{ color: "#E35252" }}
                onClick={() => handleDelete(row.id)}
              >
                Delete
              </Link>
            </li> */}
          </ul>
        </div>
      ),
    },
  ];

  // Handle confirmation modal
  const handleShowConfirmationModal = () => setShowConfirmationModal(true);
  const handleCloseConfirmationModal = () => setShowConfirmationModal(false);

  // handle delete
  const handleDelete = (id) => {
    setDeleteId(id);
    handleShowConfirmationModal(true);
  };

  const handleConfirmDelete = async () => {
    handleCloseConfirmationModal(true);
    setLoading(true);
    // const queryString = `?id=${couponId}`;
    let { status, message } = await deleteRecord(
      `${GENERATE_QRCODE}/${deleteId}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      await generateQRCodeList();
      showToast(true, message);
      setLoading(false);
    } else {
      showToast(false, message);
    }
    setLoading(false);
  };

  // Hanlde Status Change Modal
  const handleStatusChange = (row) => {
    setSelectedContentEditModal(row);
    setContentEditModal(true);
  };

  const handleStatusModalClose = () => {
    setSelectedContentEditModal({});
    setContentEditModal(false);
  };

  const handleContentModalSubmit = async (data) => {
    handleStatusModalClose();
    setLoading(true);
    let { status, message } = await put(
      `${GENERATE_QRCODE}/${data.id}`,
      {
        qr_code_content: data.qr_code_content,
      },
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    showToast(status, message);
    if (status) {
      const allContacts = generateQRCode.map((contact) =>
        contact.id === data.id
          ? { ...contact, qr_code_content: data.qr_code_content }
          : contact
      );
      setGenerateQRCode(allContacts);
    }
    setLoading(false);
  };

  useEffect(() => {
    generateQRCodeList();
  }, []);

  return (
    <>
      {!loading ? (
        <div>
          <div className="page_Header">
            <div className="d-flex justify-content-between align-items-md-center align-items-initial flex-md-row flex-column gap-3">
              <p className="pageTitle">
                QR Code <span>({generateQRCode.length})</span>
              </p>
              <div className="d-flex align-items-center justify-content-between gap-2">
                <FormInput
                  icon={searchIcon}
                  placeholder="Search..."
                  value={filterText}
                  handleChange={handleFilter}
                />
                <Link to="/generateQRCode/addEditQRCode">
                  <button className="primary_button">Add</button>
                </Link>
              </div>
            </div>
          </div>
          <div className={`listingWrapper mainWrapper`}>
            <DataTable
              columns={columns}
              data={filteredItems}
              pagination
              theme="solarized"
            />
          </div>
          <ConfirmationModal
            show={showConfirmationModal}
            handleCloseModal={handleCloseConfirmationModal}
            handleConfirmDelete={() => handleConfirmDelete(deleteId)}
            confirmTitle="Qr Code"
          />
          <GenerateQRCodeEditModal
            show={contentEditModal}
            data={selectedContentEditModal}
            setSelectedData={setSelectedContentEditModal}
            handleSaveShowModal={handleContentModalSubmit}
            handleCloseModal={handleStatusModalClose}
          />
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default AllQRCode;
