import React, { useState, useEffect } from "react";
import classes from "../../assets/styles/Pages/QRCode/QRCode.module.css";
import { QRCode } from "react-qrcode-logo";
import qrCodeBorder from "../../assets/images/qr-code-border.svg";
import { SHARE_QR } from "../../utils/constants/common";
import qrCodeLogo from "../../assets/images/sidebar_icons/collapsedLogo.svg";
import { GiShare } from "react-icons/gi";
import baseUrl from "../../utils/constants/baseUrl";
import Loader from "../../components/Loader";
import { useAtom } from "jotai";
import {
  DYNAMIC_QRCODE,
  CUSTOMIZE_QRCODE,
  IMAGE_UPLOAD,
} from "../../utils/constants/apiUrl";
import { get, post, put } from "../../server";
import showToast from "../../helpers/showToast";
import formatPhoneNumber from "../../helpers/formatPhoneNumber";
import ImageUploadSquare from "../../components/ImageUploadSquare";
import removeQrLogo from "../../assets/images/function_page_icons/nocamera.svg";
import { Form } from "react-bootstrap";
import { useParams, useNavigate, Link } from "react-router-dom";
import {
  loggedInUser,
  teamIDCheck,
  subscribedPackage,
} from "../../utils/store";
import useFunctionsCount from "../../hooks/useFunctionsCount";
import functionTypes from "../../utils/constants/functionTypes";
const AddEditQRCodePage = ({
  target,
  qrOpen,
  handleQRClose,
  setShowCopyPopup,
  handleDownload,
  qrCodeId,
  // functionType,
  functionUuid,
  url,
  qr_bg_color,
  qr_front_color,
  qr_image,
  function_type,
  function_id,
  payment_type,
  qrCodeCustomize,
  selectedData,
}) => {
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [teamID, setTeamID] = useAtom(teamIDCheck);
  const [loading, setLoading] = useState(false);
  const [purchasedPackage, setPurchasedPackage] = useAtom(subscribedPackage);
  const [bgColor, setBgColor] = useState("#ffffff");
  const [fgColor, setFgColor] = useState("#000000");
  const [editBgColor, setEditBgColor] = useState(bgColor);
  const [editFgColor, setEditFgColor] = useState(fgColor);
  const [logoImageURL, setLogoImageURL] = useState(qrCodeLogo);
  const [editLogoImageURL, setEditLogoImageURL] = useState(logoImageURL);
  const [shouldRemoveLogo, setShouldRemoveLogo] = useState(false);
  const [data, setData] = useState({});
  const [image, selectImage] = useState("");
  const navigate = useNavigate();
  let { id } = useParams();
  const [functionType, setFunctionTypes] = useState({
    card: false,
    payment: false,
    emergency_contact: false,
    custom_url: false,
    file_upload: false,
    memory: false,
    medical_id: false,
    form_builder: false,
  });
  const { compareLimits, updateCount, updateUserActionsForDynamicQRCode } = useFunctionsCount();
  const getQRDetails = async (e) => {
    setLoading(true);
    if (id) {
      let { status, message, data } = await get(
        `${DYNAMIC_QRCODE}/${id}`,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
      if (status) {
        setData(data);
        const functionTypesFromApi = {};
        data.dynamicQRCodeFunctions.forEach((item) => {
          functionTypesFromApi[item.function_type] = true;
        });
        setFunctionTypes(functionTypesFromApi);
        setEditBgColor(data?.qrCodeCustomize?.qr_bg_color || "#ffffff");
        setEditFgColor(data?.qrCodeCustomize?.qr_front_color || "#000000");
        setEditLogoImageURL(
          baseUrl.PUBLIC_URL + data?.qrCodeCustomize?.qr_image || qrCodeLogo
        );
      } else {
        showToast(status, message);
      }
    }
    setLoading(false);
  };
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFunctionTypes((prevTypes) => ({
      ...prevTypes,
      [name]: checked,
    }));
  };
  const handleRemoveLogoImage = async (e) => {
    const removeLogoPayload = {
      id: id,
      function_type: "dynamic_qr_code",
      function_id: id,
      qr_bg_color: editBgColor,
      qr_front_color: editFgColor,
      qr_image: null,
    };
    let { status, message, data } = await post(
      CUSTOMIZE_QRCODE,
      removeLogoPayload,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      setLogoImageURL("");
      setEditLogoImageURL("");
      setShouldRemoveLogo(true);
    } else {
      showToast(status, message);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (shouldRemoveLogo) {
      await handleRemoveLogoImage();
    }
    let uploadedImageUrl = "";
    if (image && typeof image === "object") {
      let formDataObj = new FormData();
      formDataObj.append("image_type", "dynamic_qr_code");
      formDataObj.append("image", image);

      try {
        let { status, message, data } = await post(
          IMAGE_UPLOAD,
          formDataObj,
          Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
        );

        if (status) {
          uploadedImageUrl = data || "";
          setLogoImageURL(uploadedImageUrl);
        } else {
          showToast(status, message);
          setLoading(false);
          return;
        }
      } catch (error) {
        setLoading(false);
        return;
      }
    }

    const payload = {
      function_types: Object.keys(functionType).filter(
        (key) => functionType[key]
      ),
      // function_types: Object.entries(functionTypes)
      //   .filter(([key, ids]) => ids.length > 0)
      //   .map(([key, ids]) => ({
      //     function_name: key,
      //     function_ids: ids,
      //   })),
      team_id: teamID,
    };

    try {
      let response;
      if (id) {
        response = await put(
          `${DYNAMIC_QRCODE}/${id}`,
          payload,
          Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
        );
      } else {
        response = await post(
          DYNAMIC_QRCODE,
          payload,
          Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
        );
      }

      if (response.status) {
        updateCount(functionTypes.DYNAMIC_QR_CODE, functionTypes.ADD);
        const functionId = response.data.id;

        const customPayload = {
          id: id,
          function_type: "dynamic_qr_code",
          function_id: functionId,
          qr_bg_color: editBgColor,
          qr_front_color: editFgColor,
          qr_image: uploadedImageUrl,
        };

        const customizeResponse = await post(
          CUSTOMIZE_QRCODE,
          customPayload,
          Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
        );

        if (customizeResponse.status) {
          if(functionId){
            updateUserActionsForDynamicQRCode(functionId)
          } 
          if (!functionId) {
            updateCount(functionTypes.DYNAMIC_QR_CODE, functionTypes.ADD);
          }
          navigate("/qrcode");
        } else {
          showToast(customizeResponse.status, customizeResponse.message);
        }
        showToast(response.status, response.message);
      } else {
        showToast(response.status, response.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }

    setLoading(false);
  };
  useEffect(() => {
    getQRDetails();
  }, []);
  return (
    <>
      <div>
        <div className="row align-items-center">
          <div className="col-6">
            <p className={classes.title}> {id ? "Edit" : "Add"} QR Code</p>
          </div>
        </div>
        <div className={classes.boxWrapper}>
          <>
            <div className="row">
              <div className="col-md-6 col-12 text">
                <div className={`${classes.qrWrapper} mt-0`}>
                  <div className={classes.qrCodeInner}>
                    <div className={classes.qrCode}>
                      <div className={classes.modalGradientCircle}></div>
                      <img src={qrCodeBorder} />

                      <QRCode
                        // value={url ? url : `${SHARE_QR}dynamic_qr_code/${uuid}`}
                        id="qrCodeEl"
                        logoImage={
                          editLogoImageURL ? editLogoImageURL : qrCodeLogo
                        }
                        logoOpacity={1}
                        logoPadding={3}
                        bgColor={
                          editBgColor
                            ? editBgColor
                            : data.qrCodeCustomize.qr_bg_color
                        }
                        fgColor={
                          editFgColor
                            ? editFgColor
                            : data.qrCodeCustomize.qr_front_color
                        }
                        eyeRadius={0}
                        removeQrCodeBehindLogo
                      />
                    </div>
                    {/* {id && ( */}
                    <ul className="customizedSidebar list-unstyled m-0">
                      <li className="mb-4">
                        <ImageUploadSquare
                          selectedImage={
                            typeof image == "object"
                              ? image
                              : baseUrl.PUBLIC_URL + image
                          }
                          setSelectedImage={selectImage}
                        />
                      </li>
                      <li className="mb-4">
                        <button
                          className={classes.removeImgBtn}
                          onClick={handleRemoveLogoImage}
                        >
                          <img src={removeQrLogo} />
                        </button>
                      </li>
                      <li className="mb-4">
                        <Form.Group controlId="">
                          <input
                            type="color"
                            //value={}
                            className={classes.colorPicker}
                            //onChange={handleBgColorChange}
                            onChange={(e) => setEditBgColor(e.target.value)}
                          />
                        </Form.Group>
                      </li>
                      <li>
                        <Form.Group controlId="">
                          <input
                            type="color"
                            //value={}
                            className={classes.colorPicker}
                            //onChange={handleFgColorChange}
                            onChange={(e) => setEditFgColor(e.target.value)}
                          />
                        </Form.Group>
                      </li>
                    </ul>
                    {/* )} */}
                  </div>
                  <hr />
                  <h6
                    style={{
                      margin: "10px 0 20px",
                      textAlign: "left",
                      fontSize: "14px",
                    }}
                  >
                    Choose Functions
                  </h6>
                  <div className="row text-start">
                    <div className="col-sm-6 col-12">
                      <div className="box-pill d-flex justify-content-between align-items-center">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="card"
                            checked={functionType.card}
                            onChange={handleCheckboxChange}
                          />
                          <label className="form-check-label" htmlFor="card">
                            Digital Cards
                          </label>
                        </div>
                        {/* {id ? (
                          ""
                        ) : (
                          <Link to="/dynamicQRCode/function/cards">
                            <button className="primary_button px-3 py-1">
                              Add
                            </button>
                          </Link>
                        )} */}
                      </div>
                    </div>
                    <div className="col-sm-6 col-12">
                      <div className="box-pill d-flex justify-content-between align-items-center">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="payment"
                            checked={functionType.payment}
                            onChange={handleCheckboxChange}
                          />
                          <label className="form-check-label" htmlFor="payment">
                            Payment Service
                          </label>
                        </div>
                        {/* {id ? (
                          ""
                        ) : (
                          <Link to="/function/paymentListing">
                            <button className="primary_button px-3 py-1">
                              Add
                            </button>
                          </Link>
                        )} */}
                      </div>
                    </div>
                    <div className="col-sm-6 col-12">
                      <div className="box-pill d-flex justify-content-between align-items-center">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="emergency_contact"
                            checked={functionType.emergency_contact}
                            onChange={handleCheckboxChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="emergency_contact"
                          >
                            Emergency Contact
                          </label>
                        </div>
                        {/* {id ? (
                          ""
                        ) : (
                          <Link to="/function/emergencycontacts">
                            <button className="primary_button px-3 py-1">
                              Add
                            </button>
                          </Link>
                        )} */}
                      </div>
                    </div>
                    <div className="col-sm-6 col-12">
                      <div className="box-pill d-flex justify-content-between align-items-center">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="custom_url"
                            checked={functionType.custom_url}
                            onChange={handleCheckboxChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="custom_url"
                          >
                            Custom URL’s
                          </label>
                        </div>
                        {/* {id ? (
                          ""
                        ) : (
                          <Link to="/function/customurlslisting">
                            <button className="primary_button px-3 py-1">
                              Add
                            </button>
                          </Link>
                        )} */}
                      </div>
                    </div>
                    <div className="col-sm-6 col-12">
                      <div className="box-pill d-flex justify-content-between align-items-center">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="file_upload"
                            checked={functionType.file_upload}
                            onChange={handleCheckboxChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="file_upload"
                          >
                            File Upload URL
                          </label>
                        </div>
                        {/* {id ? (
                          ""
                        ) : (
                          <Link to="/function/fileupload">
                            <button className="primary_button px-3 py-1">
                              Add
                            </button>
                          </Link>
                        )} */}
                      </div>
                    </div>
                    <div className="col-sm-6 col-12">
                      <div className="box-pill d-flex justify-content-between align-items-center">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="memory"
                            checked={functionType.memory}
                            onChange={handleCheckboxChange}
                          />
                          <label className="form-check-label" htmlFor="memory">
                            Memory
                          </label>
                        </div>
                        {/* {id ? (
                          ""
                        ) : (
                          <Link to="/function/memoryListing">
                            <button className="primary_button px-3 py-1">
                              Add
                            </button>
                          </Link>
                        )} */}
                      </div>
                    </div>
                    <div className="col-sm-6 col-12">
                      <div className="box-pill d-flex justify-content-between align-items-center">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="medical_id"
                            checked={functionType.medical_id}
                            onChange={handleCheckboxChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="medical_id"
                          >
                            Medical ID
                          </label>
                        </div>
                        {/* {id ? (
                          ""
                        ) : (
                          <Link to="/function/medicalListing">
                            <button className="primary_button px-3 py-1">
                              Add
                            </button>
                          </Link>
                        )} */}
                      </div>
                    </div>
                    <div className="col-sm-6 col-12">
                      <div className="box-pill d-flex justify-content-between align-items-center">
                        <div className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            name="form_builder"
                            checked={functionType.form_builder}
                            onChange={handleCheckboxChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="form_builder"
                          >
                            Lead Builder
                          </label>
                        </div>
                        {/* {id ? (
                          ""
                        ) : (
                          <Link to="/function/formBuilder">
                            <button className="primary_button px-3 py-1">
                              Add
                            </button>
                          </Link>
                        )} */}
                      </div>
                    </div>
                  </div>
                  <div className={classes.btnWrapper}>
                    <button onClick={handleSubmit}>Save</button>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>
      </div>
      <style type="text/css">
        {`
          .primary_button{
            font-size: 12px;
          }
          .box-pill{
            background-color: var(--secondary_color);
            border-radius: 10px;
            padding: .75rem;
            margin-bottom:.75rem;
            width:100%;
          }
          .form-check-label{
            font-size:13px;
          }
          .imageWrapper {
            position: relative;
            width: 16px !important;
            height: 16px !important;
            margin: auto;
          }
          .imageWrapper::before{
            background:transparent !important;
          }
          .imageWrapper img{display:none;}
          .imageWrapper .icon {
            color:var(--primary_button_color);
            font-size:18px
          }
          .customizedSidebar{
            background-color: var(--secondary_color);
            border-radius: 10px;
            padding: 15px;
            height:fit-content;
          }
          .customizedSidebar li img{
            width: 16px !important;
            height: 16px !important;
          }
          .customizedSidebar li input{
            width: 16px;
            height: 16px;
            border: 0;
            padding: 0;
            border-radius:50%;
          }
          .customizedSidebar li button{
            background-color:transparent;
            border:0;
            padding:0;
          }
        `}
      </style>
    </>
  );
};
export default AddEditQRCodePage;
