import React, { useState, useEffect, useRef } from "react";
import classes from "../../assets/styles/Pages/Function/ViewMemory.module.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import headingicon from "../../assets/images/function_page_icons/headingicon.svg";
import contacticon from "../../assets/images/function_page_icons/contacticon.svg";
import Loader from "../../components/Loader";
import Image from "react-bootstrap/Image";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import html2canvas from "html2canvas";
import { GiShare } from "react-icons/gi";
import { QRCode } from "react-qrcode-logo";
import { MdClose } from "react-icons/md";
import { Modal } from "react-bootstrap";
import qrCodeBorder from "../../assets/images/qr-code-border.svg";
import {
  EMERGENCY_CONTACT,
  EMERGENCY_CONTACT_VCF_FILE_URL,
  MEDICAL_ID,
  MEMORY,
} from "../../utils/constants/apiUrl";
import { get, put, post } from "../../server";
import { loggedInUser, teamIDCheck } from "../../utils/store";
import baseURL from "../../utils/constants/baseUrl";
import { toast } from "react-toastify";
import { useAtom } from "jotai";
import FormInput from "../../components/Forms/FormInput";
import personicon from "../../assets/images/global_icons/personicon.svg";
import dummyImage from "../../assets/images/global_icons/dummyimage.svg";
import MemoryAddEditModal from "../../components/Modals/MemoryAddEditModal";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import showToast from "../../helpers/showToast";
import _ from "lodash";
import handlePhoneNumber from "../../helpers/handlePhoneNumber";
import uploadImage from "../../helpers/uploadImage";
import { mobileViewCheck } from "../../utils/store";
import QRCodeModal from "../../components/Modals/QRCodeModal";
import formatPhoneNumber from "../../helpers/formatPhoneNumber";
import useFunctionsCount from "../../hooks/useFunctionsCount";
import functionTypes from "../../utils/constants/functionTypes";
import MedicalAddEditModal from "../../components/Modals/MedicalAddEditModal";

const ViewMedicalId = () => {
  const target = useRef(null);
  const navigate = useNavigate();
  const { id, uuid, teamId, userId } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const team_id = searchParams.get("team_id");
  const user_id = searchParams.get("user_id");

  const [userTeamId, setUserTeamId] = useAtom(teamIDCheck);
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [modalLoading, setModalLoading] = useState(false);
  const [loading, setLoading] = useState(true);
  const [selectedData, setSelectedData] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showCopyPopup, setShowCopyPopup] = useState(false);
  const [qrOpen, setQrOpen] = useState(false);
  const [mobileView] = useAtom(mobileViewCheck);
  const [medical, setMedical] = useState({});
  const [showFullText, setShowFullText] = useState(false);
  const { compareLimits, updateCount, limitExceededForStarter } =
    useFunctionsCount();
  const displayText = showFullText
    ? medical?.description
    : medical?.description?.length > 60
    ? medical?.description?.slice(0, 60) + "..."
    : medical?.description;

  const toggleText = () => {
    setShowFullText(!showFullText);
  };

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setMedical({ ...medical, [name]: value });
  };

  const handleOpenModal = () => {
    let newObj = _.cloneDeep(medical);
    setSelectedData(newObj);
    setShowModal(true);
  };

  const handleDownload = () => {
    html2canvas(document.querySelector("#qr-code")).then(function (canvas) {
      const link = document.createElement("a");
      link.download = "qrcode.png";
      link.href = canvas.toDataURL();
      link.click();
    });
  };

  const handleCloseModal = () => {
    setSelectedData({});
    setShowModal(false);
  };

  const handleQrOpen = (medical) => {
    setSelectedData(medical);
    setQrOpen(true);
  };

  const handleQRClose = () => {
    setQrOpen(false);
    setShowCopyPopup(false);
    setSelectedData("");
  };

  const handleSaveShowModal = async (newData) => {
    setLoading(true);

    let payload = _.cloneDeep(newData);
    handlePhoneNumber(payload);

    if (payload.photo && typeof payload.photo === "object") {
      const { status, message, data } = await uploadImage(
        "medical_id",
        payload.photo,
        loggedUser?.token
      );

      if (status) {
        payload.photo = data;
      } else {
        showToast(status, message);
        setLoading(false);
        return;
      }
    }

    let result = {};
    if (selectedData.id) {
      result = await put(
        MEDICAL_ID + `/${selectedData.id}`,
        payload,
        loggedUser?.token || null
      );
    } else {
      if (teamId && userId) {
        payload.team_id = teamId;
        payload.user_id = userId;
      }
      if (team_id && user_id) {
        payload.team_id = team_id;
        payload.user_id = user_id;
      }
      if (userTeamId) {
        payload.team_id = userTeamId;
      }
      result = await post(MEDICAL_ID, payload, loggedUser?.token || null);
    }

    const { status, message, data } = result;

    if (status) {
      setLoading(false);

      let medicalIDArr = _.cloneDeep(medical);

      // On Update
      if (!selectedData.id) {
        updateCount(functionTypes.MEDICAL_ID, functionTypes.ADD);
      }

      setMedical(medicalIDArr);
      loadMedicalID();
      handleCloseModal();
    } else {
      setLoading(false);
    }
    showToast(status, message);
  };

  const loadMedicalID = async () => {
    let queryParams = "";

    if (teamId || team_id || userTeamId) {
      queryParams = `?team_id=${teamId || team_id || userTeamId}`;
    }

    // if (userId || user_id) {
    //   queryParams += `${queryParams ? "&" : "?"}user_id=${userId || user_id}`;
    // }
    let { status, message, data } = await get(
      `${MEDICAL_ID}/${id}${queryParams}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      if (Object.keys(data).length > 0) {
        let objData = data;
        objData = { ...objData, user_id: loggedUser.id };
        setMedical(objData);
      }
    } else {
      // error message
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    loadMedicalID();
  }, []);

  const handleVcfFile = async () => {
    // setLoading(true);

    let { status, message, data } = await post(
      EMERGENCY_CONTACT_VCF_FILE_URL + `/${medical.uuid}`,
      medical,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      const link = document.createElement("a");
      link.download = "temp.vcf";
      link.href = data.url;
      link.click();
    } else {
      showToast(status, message);
    }
    // setLoading(false);
  };

  const handleUpdate = async () => {
    setLoading(true);
    let { status, message, data } = await put(
      EMERGENCY_CONTACT + `/${id}`,
      medical,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      toast.success(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } else {
      toast.error(message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    // error message

    setLoading(false);
  };

  const handleGoBack = () => {
    navigate(-1);
    // if (teamId && userId) {
    //   navigate(-2);
    // } else {
    //   navigate("/function");
    // }
  };

  return (
    <>
      {!loading ? (
        <div className="row justify-content-center">
          <div className="col-12 col-md-6 col-lg-5">
            <div className={classes.mainWrapper}>
              <div
                className={`d-flex justify-content-between align-items-center`}
              >
                <h6 className={classes.title} style={{ textAlign: "left" }}>
                  {teamId && userId ? (
                    <Link
                      onClick={handleGoBack}
                      style={{ color: "var(--primary_text_color)" }}
                    >
                      Functions
                    </Link>
                  ) : (
                    <Link
                      to="/function"
                      style={{ color: "var(--primary_text_color)" }}
                    >
                      Functions
                    </Link>
                  )}

                  {` > Medical Id`}
                </h6>
                <button
                  type="button"
                  onClick={handleGoBack}
                  className="bg-transparent p-0 border-0"
                  style={{ color: "var(--primary_text_color)" }}
                >
                  <h6 className="back">{` < Back`}</h6>
                </button>
              </div>
              <div className={classes.cardWrapper}>
                <div
                  className={`${classes.profileCardWrapper} profileCardWrapper`}
                  // style={{ background: "var(--border_color)" }}
                >
                  <div className={classes.imgWrapper}>
                    <Image
                      className="globalIMageFit"
                      src={
                        medical.photo
                          ? `${baseURL.PUBLIC_URL}${medical.photo}`
                          : dummyImage
                      }
                      alt="img"
                      roundedCircle={true}
                      loading="lazy"
                      decoding="async"
                    />
                  </div>
                  <div className={`${classes.detailWrapper} detailWrappere`}>
                    <h6 className="my-4">{`${medical.first_name} ${medical.last_name}`}</h6>
                  </div>
                  <div
                    className={`${classes.detailWrapper} detailWrapper mb-4 p-4`}
                  >
                    {medical?.dob && (
                      <p className="subtext">
                        <strong>Date of Birth : </strong>
                        {`${medical.dob}`}
                      </p>
                    )}
                    {medical?.blood_type && (
                      <p className="subtext">
                        <strong>Blood Type : </strong>
                        {`${medical.blood_type}`}
                      </p>
                    )}
                    {medical?.weight && (
                      <p className="subtext">
                        <strong>Weight : </strong>
                        {`${medical.weight}`}
                      </p>
                    )}
                    {medical?.height && (
                      <p className="subtext">
                        <strong>Height : </strong>
                        {`${medical.height}`}
                      </p>
                    )}
                    {medical?.primary_language && (
                      <p className="subtext">
                        <strong>Primary Language : </strong>
                        {`${medical.primary_language}`}
                      </p>
                    )}
                    {medical?.medical_condition && (
                      <p className="subtext">
                        <strong>Medical Condition : </strong>
                        {`${medical.medical_condition}`}
                      </p>
                    )}
                    {medical?.allergies_reactions && (
                      <p className="subtext">
                        <strong>Allergies Reactions : </strong>
                        {`${medical.allergies_reactions}`}
                      </p>
                    )}
                    {medical?.medications && (
                      <p className="subtext">
                        <strong>Medications : </strong>
                        {`${medical.medications}`}
                      </p>
                    )}
                    {medical?.medical_notes && (
                      <p className="subtext">
                        <strong>Medical Notes : </strong>
                        {`${medical.medical_notes}`}
                      </p>
                    )}
                  </div>
                  {medical.medicalIdEmergencyContact.length > 0 ? (
                    <div
                      className={`${classes.detailWrapper} detailWrapper p-4`}
                    >
                      <h6 className="mb-3 text-start subtitle">
                        Emergency Contact
                      </h6>
                      <ul>
                        {medical.medicalIdEmergencyContact?.map((contact) => {
                          return (
                            <li key={contact.id}>
                              <p className="subtext">
                                <strong>Name: </strong> {contact.person_name}
                              </p>
                              <p className="subtext">
                                <strong>Relation: </strong> {contact.relation}
                              </p>
                              <p className="subtext">
                                {" "}
                                <strong>Phone No. : </strong>
                                {`+${contact.country_phone}${formatPhoneNumber(
                                  contact.phone_number
                                )}`}
                              </p>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  ) : (
                    ""
                  )}
                  <div className={`mt-2  ${classes.savePhonebookBtnWrapper}`}>
                    <div className="d-flex align-items-center justify-content-center gap-3">
                      <button onClick={handleOpenModal} className={classes.btn}>
                        Edit
                      </button>
                      <button
                        onClick={() => handleQrOpen(medical)}
                        className={classes.btn}
                      >
                        Download QR
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )}

      {showModal && (
        <MedicalAddEditModal
          show={showModal}
          data={selectedData}
          setSelectedData={setSelectedData}
          handleSaveShowModal={handleSaveShowModal}
          handleCloseModal={handleCloseModal}
          loading={loading}
        />
      )}

      {qrOpen && (
        // <QRCodeModal
        //   qrOpen={qrOpen}
        //   target={target}
        //   handleQRClose={handleQRClose}
        //   setShowCopyPopup={setShowCopyPopup}
        //   handleDownload={handleDownload}
        //   functionType="emergencyContact"
        //   functionUuid={selectedData}
        // />
        <QRCodeModal
          qrOpen={qrOpen}
          target={target}
          handleQRClose={handleQRClose}
          setShowCopyPopup={setShowCopyPopup}
          handleDownload={handleDownload}
          functionType={medical.function_type}
          function_type={medical.function_type}
          functionId={medical.id}
          functionUuid={medical.uuid}
          id={medical.id}
          QRCodeCustomize={medical.qrCodeCustomize}
          refreshData={loadMedicalID}
        />
      )}

      <Overlay
        target={target.current}
        show={showCopyPopup}
        placement={mobileView ? "left" : "right"}
      >
        {(props) => (
          <Tooltip id="overlay-example" {...props}>
            Link Copied
          </Tooltip>
        )}
      </Overlay>
      <style type="">
        {`
        .subtitle{
          color: var(--heading_text_color) !important
        }
          .memory-img{
            object-fit:cover;
          }
          p.subtext{
            font-size:13px;
            text-align: left;
            margin-bottom:0px;
          }
          .detailWrapper{
            background-color: var(--tertiary_color);
            border-radius: .35rem;
          }
          .detailWrappere h6, .detailWrapper p{
            color: var(--heading_text_color);
          }
          .profileCardWrapper{
            background: var(--secondary_color);
          }
        `}
      </style>
    </>
  );
};

export default ViewMedicalId;
