import React, { useState, useEffect, useMemo } from "react";
import classes from "../../assets/styles/Pages/Function/CustomURLSListing.module.css";
import ShowEmergencyFunctions from "../../components/PageComponents/Function/ShowEmergencyFunctions";
import Loader from "../../components/Loader";
import { useAtom } from "jotai";
import { get, deleteRecord, post, put } from "../../server";
import {
  EMERGENCY_CONTACT,
  FUNCTION_STATUS,
} from "../../utils/constants/apiUrl";
import {
  loggedInUser,
  teamIDCheck,
  subscribedPackage,
} from "../../utils/store";
import { toast } from "react-toastify";
import showToast from "../../helpers/showToast";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import EmergencyAddEditModal from "../../components/Modals/EmergencyAddEditModal";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import FormInput from "../../components/Forms/FormInput";
import searchIcon from "../../assets/images/global_icons/searchicon.svg";
import _ from "lodash";
import uploadImage from "../../helpers/uploadImage";
import EmptyListingBanner from "../../components/EmptyListingBanner";
import handlePhoneNumber from "../../helpers/handlePhoneNumber";
import useFunctionsCount from "../../hooks/useFunctionsCount";
import functionTypes from "../../utils/constants/functionTypes";
import { FaCirclePlus } from "react-icons/fa6";
import FunctionsLimitModal from "../../components/Modals/FunctionsLimitModal";

const EmergencyContactsListing = () => {
  let emptyEmergencyContactData = {
    isNew: true,
    id: "",
    first_name: "",
    last_name: "",
    country_phone: "",
    phone_number: "",
    image: "",
    description: "",
  };

  const navigate = useNavigate();
  const { teamId, userId } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const team_id = searchParams.get("team_id");
  const user_id = searchParams.get("user_id");
  const dynamicQRCodeId = searchParams.get("dynamic_qr_code");

  const [loading, setLoading] = useState(true);
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [userTeamId, setUserTeamId] = useAtom(teamIDCheck);
  const [emergencyContacts, setEmergencyContacts] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState(emptyEmergencyContactData);
  const [filterText, setFilterText] = useState("");
  const {
    compareLimits,
    updateCount,
    limitExceededForStarter,
    compareLimitsForDynamicQR,
  } = useFunctionsCount();
  const [showLimitModal, setShowLimitModal] = useState(false);
  const [purchasedPackage, setPurchasedPackage] = useAtom(subscribedPackage);

  const handleFilter = (e) => {
    setFilterText(e.target.value);
  };

  // Handle main modal
  const handleShowModal = (obj) => {
    let newObj = _.cloneDeep(obj);

    if (newObj && Object.keys(newObj).length) {
      if (newObj.phone_number) {
        newObj.phone_number = `${
          newObj.country_phone ? newObj.country_phone : ""
        }${newObj.phone_number}`;
      }

      if (newObj.country_phone) {
        newObj.country_phone = `+${newObj.country_phone}`;
      }

      setSelectedData(newObj);
    }

    setShowModal(true);
  };

  // const handleShowEmergencyContactModal = () => {
  //   const isLimitExceeded = compareLimits(functionTypes.EMERGENCY_CONTACT);
  //   // if (isLimitExceeded)
  //   //   return showToast(
  //   //     false,
  //   //     "The requested feature is not compatible with your current plan. Upgrade your plan to continue using these features"
  //   //   );
  //   if (isLimitExceeded) {
  //     setShowLimitModal(true);
  //     return;
  //   }
  //   setShowModal(true);
  // };

  const handleShowEmergencyContactModal = async () => {
    if (dynamicQRCodeId !== null) {
      const isLimitExceeded = compareLimitsForDynamicQR(
        functionTypes.EMERGENCY_CONTACT,
        dynamicQRCodeId
      );
      if (isLimitExceeded) {
        return showToast(
          false,
          "The requested feature is not compatible with your current plan. Upgrade your plan to continue using these features"
        );
      } else {
        setShowModal(true);
      }
    } else if (purchasedPackage.id === 1) {
      limitExceededForStarter()
        .then((isLimitExceeded) => {
          if (isLimitExceeded) {
            setShowLimitModal(true);
          } else {
            setShowModal(true);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      const isLimitExceeded = compareLimits(functionTypes.EMERGENCY_CONTACT);
      if (isLimitExceeded) {
        return showToast(
          false,
          "The requested feature is not compatible with your current plan. Upgrade your plan to continue using these features"
        );
      } else {
        setShowModal(true);
      }
    }
    // if (isLimitExceeded)
    //   return showToast(
    //     false,
    //     "The requested feature is not compatible with your current plan. Upgrade your plan to continue using these features"
    //   );

    // if (isLimitExceeded) {
    //   setShowLimitModal(true);
    //   return;
    // }
  };

  const handleSaveShowModal = async (newData) => {
    setLoading(true);

    let payload = _.cloneDeep(newData);

    handlePhoneNumber(payload);
    payload.dynamic_qr_code_id = dynamicQRCodeId;

    if (payload.image && typeof payload.image == "object") {
      const { status, message, data } = await uploadImage(
        "emergency_contact",
        payload.image,
        loggedUser?.token
      );

      if (status) {
        payload.image = data;
      } else {
        showToast(status, message);
        setLoading(false);
        return;
      }
    }

    let result = {};
    if (selectedData.id) {
      result = await put(
        EMERGENCY_CONTACT + `/${selectedData.id}`,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
    } else {
      if (teamId && userId) {
        payload.team_id = teamId;
        payload.user_id = userId;
      }
      if (team_id && user_id) {
        payload.team_id = team_id;
        payload.user_id = user_id;
      }
      if (userTeamId) {
        payload.team_id = userTeamId;
      }
      result = await post(
        EMERGENCY_CONTACT,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
    }

    let { status, message, data } = result;

    if (status) {
      setLoading(false);

      let emergencyContactsArr = _.cloneDeep(emergencyContacts);

      // On Update
      if (!selectedData.id) {
        updateCount(
          functionTypes.EMERGENCY_CONTACT,
          functionTypes.ADD,
          dynamicQRCodeId
        );
      }
      if (selectedData.id) {
        const index = emergencyContacts.findIndex(
          (el) => el.id == selectedData.id
        );
        emergencyContactsArr[index] = data;
      } else {
        emergencyContactsArr.unshift(data);
      }

      setEmergencyContacts(emergencyContactsArr);
      handleCloseModal();
    } else {
      setLoading(false);
    }
    showToast(status, message);
  };

  const handleCloseModal = () => {
    setSelectedData(emptyEmergencyContactData);
    setShowModal(false);
    setShowLimitModal(false);
  };

  // Handle confirmation modal
  const handleShowConfirmationModal = () => setShowConfirmationModal(true);
  const handleCloseConfirmationModal = () => setShowConfirmationModal(false);

  // handle delete
  const handleDelete = (id) => {
    setDeleteId(id);
    handleShowConfirmationModal(true);
  };

  const handleConfirmDelete = async () => {
    handleCloseConfirmationModal(true);

    setLoading(true);
    let queryParams = "";

    if (dynamicQRCodeId) {
      queryParams += `?dynamic_qr_code_id=${dynamicQRCodeId}`;
    }

    if (teamId || team_id || userTeamId) {
      queryParams += `${queryParams ? "&" : "?"}team_id=${
        teamId || team_id || userTeamId
      }`;
    }

    // if (teamId || team_id || userTeamId) {
    //   queryParams = `?team_id=${teamId || team_id || userTeamId}`;
    // }
    let { status, message } = await deleteRecord(
      `${EMERGENCY_CONTACT}/${deleteId}${queryParams}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    showToast(status, message);

    if (status) {
      await getEmergencyContacts();
      updateCount(
        functionTypes.EMERGENCY_CONTACT,
        functionTypes.DELETE,
        dynamicQRCodeId
      );
      setLoading(false);
    }

    setLoading(false);
  };

  // Get all custom urls
  const getEmergencyContacts = async () => {
    let queryParams = "";

    if (dynamicQRCodeId) {
      queryParams += `?dynamic_qr_code_id=${dynamicQRCodeId}`;
    }

    if (teamId || team_id || userTeamId) {
      queryParams += `${queryParams ? "&" : "?"}team_id=${
        teamId || team_id || userTeamId
      }`;
    }
    // if (teamId || team_id || userTeamId) {
    //   queryParams = `?team_id=${teamId || team_id || userTeamId}`;
    // }

    if (userId || user_id) {
      queryParams += `${queryParams ? "&" : "?"}user_id=${userId || user_id}`;
    }

    let { status, message, data } = await get(
      `${EMERGENCY_CONTACT}${queryParams}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      setEmergencyContacts(data);
    } else {
      toast.error(message);
    }
    setLoading(false);
  };

  const handleStatus = async (id) => {
    const allEmergencyContacts = emergencyContacts.find(
      (emergencyContact) => emergencyContact.id === id
    );
    let newStatus = allEmergencyContacts.status == true ? 0 : 1;
    let queryParams = "";

    if (dynamicQRCodeId) {
      queryParams += `?dynamic_qr_code_id=${dynamicQRCodeId}`;
    }
    const payload = {
      id: allEmergencyContacts.id,
      status: newStatus,
      type: allEmergencyContacts.function_type,
      team_id: allEmergencyContacts.team_id,
    };
    let { status, message } = await post(
      `${FUNCTION_STATUS}${queryParams}`,
      payload,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      await getEmergencyContacts();
    } else {
      toast.error(message);
    }
    setLoading(false);
  };

  useEffect(() => {
    getEmergencyContacts();
  }, [userTeamId]);

  const filteredItems = useMemo(() => {
    const filtered = emergencyContacts.filter(
      (item) =>
        `${item.first_name || ""} ${item.last_name || ""}`
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
        `${item.country_phone ? `+${item.country_phone}` : ""}${
          item.phone_number || ""
        }`.includes(filterText)
    );

    return filtered;
  }, [filterText, emergencyContacts]);

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="">
          <div className={classes.mainWrapper}>
            <h6 className={classes.title}>
              <Link
                onClick={handleGoBack}
                style={{ color: "var(--heading_text_color)" }}
              >
                Functions
              </Link>
              {` > Emergency Contacts`}
            </h6>
            <div className="d-flex align-items-center justify-content-center">
              {emergencyContacts.length !== 0 ? (
                <FormInput
                  icon={searchIcon}
                  placeholder="Search..."
                  value={filterText}
                  handleChange={handleFilter}
                />
              ) : null}
              {dynamicQRCodeId !== null ? (
                <Link className="primary_button ms-4" to={`/qrcode`}>
                  {`< Back`}
                </Link>
              ) : (
                ""
              )}
            </div>
          </div>

          {emergencyContacts.length === 0 ? (
            <EmptyListingBanner
              headLineText="emergency contacts"
              title="Add Contact"
              onClick={handleShowEmergencyContactModal}
              showAdd={
                loggedUser.role.includes("customer") ||
                loggedUser.role.includes("team_owner")
              }
            />
          ) : (
            <div className={classes.customURLSWrapper}>
              <div>
                <div className={classes.addWrapper}>
                  {(Object.keys(loggedUser).length !== 0 &&
                    loggedUser.role.includes("customer")) ||
                  loggedUser.role.includes("team_owner") ? (
                    <span onClick={handleShowEmergencyContactModal}>
                      <div className={classes.addContentWrapper}>
                        {/* <img src={addcontact} alt="" /> */}
                        <FaCirclePlus />
                        <p>Add Contact</p>
                      </div>
                    </span>
                  ) : null}
                </div>
                <ShowEmergencyFunctions
                  loading={loading}
                  show={showModal}
                  emergencyContacts={filteredItems}
                  handleShowModal={handleShowModal}
                  handleCloseModal={handleCloseModal}
                  handleDelete={handleDelete}
                  teamId={teamId}
                  userId={userId}
                  team_id={team_id}
                  user_id={user_id}
                  handleStatus={handleStatus}
                  getEmergencyContacts={getEmergencyContacts}
                />
              </div>
            </div>
          )}

          {/* Emergency Contact modal */}
          {showModal && (
            <EmergencyAddEditModal
              show={showModal}
              data={selectedData}
              setSelectedData={setSelectedData}
              handleSaveShowModal={handleSaveShowModal}
              handleCloseModal={handleCloseModal}
            />
          )}
        </div>
      )}

      {/* confirmation modal */}

      <ConfirmationModal
        show={showConfirmationModal}
        handleCloseModal={handleCloseConfirmationModal}
        handleConfirmDelete={handleConfirmDelete}
        confirmTitle="emergency contact"
      />
      <FunctionsLimitModal
        show={showLimitModal}
        handleClose={handleCloseModal}
        getData={getEmergencyContacts}
      />
    </>
  );
};

export default EmergencyContactsListing;
