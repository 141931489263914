import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import SecondLoader from "../SecondLoader";
import AddCustomURLSchema from "../../utils/SchemasValidation/AddCustomURLSchema";
import ValidateSchema from "../../helpers/ValidateSchema";
import classes from "../../assets/styles/MemoryModal.module.css";
import { MdClose } from "react-icons/md";
import FormInput from "../../components/Forms/FormInput";
import { Form } from "react-bootstrap";

const MemoryAddEditModal = ({
  loading,
  data,
  setSelectedData,
  handleSaveShowModal,
  handleCloseModal,
  show,
}) => {
  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setSelectedData({ ...data, [name]: value });
  };
  const handleFileChange = (files) => {
    setSelectedData({ ...data, medias: [...files] });
  };

  // handle Validation
  const validateForm = () => {
    let newErrors = ValidateSchema(data, AddCustomURLSchema);
    setErrors(newErrors);
    if (Object.keys(newErrors).length) return false;
    return true;
  };

  const handleAddUrl = async (e) => {
    //if (!validateForm()) return;
    handleSaveShowModal(data);
  };

  // Handle Update
  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          handleCloseModal();
          setErrors({});
        }}
        className={`customModal`}
        size="sm"
      >
        <Modal.Header className={classes.modalHeader}>
          <span className={classes.modalHeading}>
            {data?.id ? "Edit" : "Add"} Memory
          </span>
          <div className="modalGradientCircle"></div>
          <MdClose
            onClick={() => {
              handleCloseModal();
              setErrors({});
            }}
            className="modalCloseBtn"
          />
        </Modal.Header>
        {loading ? (
          <SecondLoader />
        ) : (
          <>
            <Modal.Body>
              <div style={{ marginTop: "-140px" }}>
                <label className={classes.label}>Media Type</label>
                <div className="">
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="media_type"
                      value="audio"
                      onChange={handleChange}
                      checked={data.media_type === "audio"}
                    />
                    <label class="form-check-label">Audio</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="media_type"
                      value="video"
                      onChange={handleChange}
                      checked={data.media_type === "video"}
                    />
                    <label class="form-check-label">Video</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="media_type"
                      value="image"
                      onChange={handleChange}
                      checked={data.media_type === "image"}
                    />
                    <label class="form-check-label">Image</label>
                  </div>
                </div>
                <FormInput
                  type="text"
                  label="Title"
                  placeholder="Enter Title"
                  name="title"
                  value={data.title}
                  onChange={handleChange}
                  error={errors?.title}
                />

                <FormInput
                  type="text"
                  label="Reminder"
                  placeholder="Enter reminder text"
                  name="reminder"
                  value={data.reminder}
                  onChange={handleChange}
                  error={errors?.reminder}
                />
                <Form.Group controlId="formFile" className="mb-2">
                  <label className={classes.label}>Choose Media File</label>
                  <div
                    className={`${classes.media} input-group globalInputWithIcon`}
                  >
                    <Form.Control
                      type="file"
                      name="medias"
                      onChange={(e) => handleFileChange(e.target.files)}
                      className={classes.media}
                      multiple
                    />
                  </div>
                </Form.Group>
                <div className={classes.selectWrapper}>
                  <label htmlFor="" className={classes.label}>
                    Save Type
                  </label>
                  <div className="input-group globalInputWithIcon">
                    <select
                      className="form-select customSelect"
                      name="save_type"
                      value={data.save_type}
                      onChange={handleChange}
                    >
                      <option value="" disabled selected>
                        Select
                      </option>
                      <option value="uploaded">Uploaded</option>
                      {/* <option value="recorded">Recorded</option> */}
                    </select>
                  </div>
                </div>
              </div>
            </Modal.Body>

            <Modal.Footer>
              <div className={classes.btnWrapper}>
                <Button
                  type="button"
                  onClick={() => {
                    handleCloseModal();
                    setErrors({});
                  }}
                  className={classes.cancelBtn}
                >
                  Cancel
                </Button>

                <Button
                  type="button"
                  onClick={handleAddUrl}
                  className={classes.saveBtn}
                >
                  Save
                </Button>
              </div>
            </Modal.Footer>
          </>
        )}
      </Modal>
      <style type="">
        {`
          .form-check-label{
            color:var(--heading_text_color);
            font-size: 12px;
          }
        `}
      </style>
    </>
  );
};

export default MemoryAddEditModal;
