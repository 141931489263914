import React, { useState, useEffect, useRef } from "react";
import classes from "../../../assets/styles/PageComponentsStyles/Function/Card.module.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import headingicon from "../../../assets/images/function_page_icons/headingicon.svg";
import contacticon from "../../../assets/images/function_page_icons/contacticon.svg";
import Loader from "../../../components/Loader";
import Image from "react-bootstrap/Image";
import { Card_URL } from "../../../utils/constants/apiUrl";
import { get, put, post } from "../../../server";
import { loggedInUser, teamIDCheck } from "../../../utils/store";
import baseURL from "../../../utils/constants/baseUrl";
import { toast } from "react-toastify";
import { useAtom } from "jotai";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import showToast from "../../../helpers/showToast";
import _ from "lodash";
import { BsFileImage } from "react-icons/bs";
import formatPhoneNumber from "../../../helpers/formatPhoneNumber";
import playstore from "../../../assets/images/footer_icons/playstore.svg";
import appstore from "../../../assets/images/footer_icons/appstore.svg";
import pictureicon from "../../../assets/images/function_page_icons/pictureicon.svg";
import companylogo from "../../../assets/images/function_page_icons/companylogo.svg";
import { FiMail } from "react-icons/fi";
import { MdLocationOn } from "react-icons/md";
import { BsGlobe, BsFillTelephoneFill } from "react-icons/bs";
import phoneIcon from "../../../assets/images/function_page_icons/phone-icon.svg";
import { ReactComponent as HomePhoneIcon } from "../../../assets/images/function_page_icons/HomePhone.svg";
import { ReactComponent as OfficePhoneIcon } from "../../../assets/images/function_page_icons/OfficePhone.svg";
import websiteIcon from "../../../assets/images/function_page_icons/website-icon.svg";
import locationIcon from "../../../assets/images/function_page_icons/location-icon.svg";
import mailIcon from "../../../assets/images/function_page_icons/mail-icon.svg";
import {
  CardBackGroundImages,
  Colors,
  AddMoreCustomUrls,
} from "../../../utils/data/CardFunctionsData";
const tagBorderColors = {
  "#22A081": "#ABEDDD",
  "#E35252": "#C52020",
  "#E4A56B": "#DB8A3D",
  "#7000B5": "#D899FF",
  "#EEB8C9": "#E184A1",
  "#DFF87C": "#B1DA0B",
  "#34D844": "#21AB2F",
  "#3B58BD": "#9EACE0",
  "#FF5099": "#FF1A79",
};
const skeletonColor = "#E8E8E8";
const ViewCard = () => {
  const [cardFunction, setCardFunction] = useState({
    card_type: "",
    first_name: "",
    last_name: "",
    email: "",
    company: "",
    company_position: "",
    logo: "",
    background_image: "",
    profile_image: "",
    color: "",
    country_phone: "",
    phone_number: "",
    description: "",
    address: "",
    country: "",
    city: "",
    state: "",
    website_url: "",
    card_social_media: [],
    pronouns: "",
    card_phone: [],
    user_id: "",
    team_id: null,
    team_detail: null,
    content_view_type: "",
  });
  const navigate = useNavigate();
  const { cardId } = useParams();
  const [loading, setLoading] = useState(false);
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [customBackgroundImage, setCustomBackgroundImage] = useState(false);
  const [customPronoun, setCustomPronoun] = useState("");
  const [pronounCheckBox, setPronounCheckBox] = useState(false);
  const [showFullText, setShowFullText] = useState(false);
  const [address, setAddress] = useState("");
  const [customLabel, setCustomLabel] = useState([
    {
      index_no: "",
      label: "",
    },
  ]);
  useEffect(() => {
    if (cardFunction?.city || cardFunction?.state || cardFunction?.country) {
      let addressArr = [];
      if (cardFunction?.address) addressArr.push(cardFunction?.address);
      if (cardFunction?.city) addressArr.push(cardFunction?.city);
      if (cardFunction?.state) addressArr.push(cardFunction?.state);
      if (cardFunction?.country) addressArr.push(cardFunction?.country);
      handleAddress(addressArr);
    }
  }, [
    cardFunction?.address,
    cardFunction?.city,
    cardFunction?.state,
    cardFunction?.country,
  ]);
  const handleAddress = (data) => {
    let commaAddress = data.join(", ");
    setAddress(commaAddress);
  };
  const displayText = showFullText
    ? cardFunction?.description
    : cardFunction?.description?.length > 60
    ? cardFunction?.description?.slice(0, 60) + "..."
    : cardFunction?.description;

  const toggleText = () => {
    setShowFullText(!showFullText);
  };
  const fetchSingleCard = async () => {
    setLoading(true);

    let { status, message, data } = await get(
      `${Card_URL}/${cardId}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    if (status) {
      let card_social_media = [];

      data.cardSocialMedia.forEach((item) => {
        let foundIcon = AddMoreCustomUrls.find(
          (single) =>
            single.name === item.media_type ||
            single.oldName === item.media_type
        );

        card_social_media.push({
          media_type: item.media_type,
          url: item.url,
          icon: item.media_type === "custom" ? "custom" : foundIcon?.icon,
          custom_label: item.custom_label,
        });
      });
      let newCardPhone = [];
      data.cardPhone.forEach((singlePhone, index) => {
        const customOrNot = ["home", "office"].includes(singlePhone.phone_type);

        newCardPhone.push({
          country_phone: singlePhone.country_phone,
          phone_number: singlePhone.phone_number
            ? `${singlePhone.country_phone ? singlePhone.country_phone : ""}${
                singlePhone.phone_number
              }`
            : "",
          phone_type: customOrNot ? singlePhone.phone_type : "custom",
          custom_label: customOrNot ? "" : singlePhone.phone_type,
        });
      });
      const customPronounsOrNot = [
        null,
        "",
        "she/her",
        "he/him",
        "they/them",
      ].includes(data.pronouns);
      setCardFunction({
        card_type: data.card_type,
        first_name: data.first_name ? data.first_name : "",
        last_name: data.last_name ? data.last_name : "",
        email: data.email ? data.email : "",
        company: data.company ? data.company : "",
        company_position: data.company_position ? data.company_position : "",
        logo: data.logo ? data.logo : "",
        background_image: data.background_image ? data.background_image : "",
        profile_image: data.profile_image ? data.profile_image : "",
        color: data.color ? data.color : "",
        country_phone: data.country_phone ? `+${data.country_phone}` : "",
        phone_number: data.phone_number
          ? `${data.country_phone ? data.country_phone : ""}${
              data.phone_number
            }`
          : "",
        description: data.description ? data.description : "",
        address: data.address ? data.address : "",
        country: data.country ? data.country : "",
        city: data.city ? data.city : "",
        state: data.state ? data.state : "",
        website_url: data.website_url ? data.website_url : "",
        card_social_media: card_social_media,
        pronouns: customPronounsOrNot ? data.pronouns : "custom",
        card_phone: newCardPhone,
        user_id: data.user_id ? data.user_id : "",
        team_id: data.team_id ? data.team_id : null,
        team_detail: data?.team_detail ? data?.team_detail : null,
        content_view_type: data?.content_view_type
          ? data?.content_view_type
          : null,
      });
    } else {
      showToast(status, message);
    }

    setLoading(false);
  };
  let showCountryCodeSeparateUpdate;
  let checkPath = window.location.pathname;
  if (checkPath.includes("viewcontact") || checkPath.includes("share")) {
    showCountryCodeSeparateUpdate = true;
  }
  useEffect(() => {
    fetchSingleCard();
  }, []);
  const handleGoBack = () => {
    navigate(-1);
  };
  return (
    <>
      <div className="row justify-content-center">
        <div className="col-5">
          <div className={classes.mainWrapper}>
            <div className="d-flex align-items-center justify-content-between mb-4">
              <h6 className={classes.title} style={{ textAlign: "left" }}>
                {/* {teamId && userId ? (
              <Link
                onClick={handleGoBack}
                style={{ color: "var(--primary_text_color)" }}
              >
                Functions
              </Link>
            ) : (
              <Link
                to="/function"
                style={{ color: "var(--primary_text_color)" }}
              >
                Functions
              </Link>
            )} */}

                {`Card`}
              </h6>
              <button
                type="button"
                onClick={handleGoBack}
                className="bg-transparent p-0 border-0"
                style={{ color: "var(--primary_text_color)" }}
              >
                <h6 className="back">{` < Back`}</h6>
              </button>
            </div>
            <div className={classes.cardWrapper}>
              <div
                className={`${classes.cardWrapper} ${
                  cardFunction?.content_view_type
                    ? cardFunction.content_view_type
                    : null
                }`}
                id="cardWrapper"
              >
                <div className={classes.imgWrapper}>
                  <div className={classes.coverImg}>
                    {cardFunction?.background_image ? (
                      <Image
                        src={
                          typeof cardFunction?.background_image == "object"
                            ? `${URL.createObjectURL(
                                cardFunction?.background_image
                              )}`
                            : `${baseURL.PUBLIC_URL}${cardFunction?.background_image}`
                        }
                        alt="cover-img"
                      />
                    ) : (
                      <div style={{ backgroundColor: skeletonColor }} />
                    )}
                  </div>

                  {/* <div
                key={selectedProfileShape}
                className={`${classes.profileImg} ${
                  selectedProfileShape
                    ? classes[selectedProfileShape]
                    : classes.defaultShape
                }`}
              > */}
                  <div
                    //key={selectedProfileShape}
                    className={`${classes.profileImg} ${
                      cardFunction?.profile_image_shape
                        ? cardFunction.profile_image_shape
                        : classes.defaultShape
                    } profileImg`}
                  >
                    {cardFunction?.profile_image ? (
                      <Image
                        src={
                          typeof cardFunction?.profile_image == "object"
                            ? `${URL.createObjectURL(
                                cardFunction?.profile_image
                              )}`
                            : `${baseURL.PUBLIC_URL}${cardFunction?.profile_image}`
                        }
                        onError={(e) => {
                          e.currentTarget.src = pictureicon;
                        }}
                        alt="profile-img"
                      />
                    ) : cardFunction?.profile_image ? (
                      <Image src={pictureicon} alt="logo-img" />
                    ) : (
                      <div
                        style={{
                          backgroundColor: skeletonColor,
                        }}
                      />
                    )}
                  </div>

                  {cardFunction?.card_type === "business" ? (
                    // <div
                    //   //className={classes.logoWrapper}
                    //   key={selectedLogoShape}
                    //   className={`${classes.logoWrapper} ${
                    //     selectedLogoShape
                    //       ? classes[selectedLogoShape]
                    //       : classes.defaultShape
                    //   }`}
                    // >
                    <div
                      //key={selectedLogoShape}
                      className={`${classes.logoWrapper} ${
                        cardFunction?.logo_shape
                          ? cardFunction.logo_shape
                          : classes.defaultShape
                      } logoWrapper`}
                    >
                      {cardFunction?.logo ? (
                        <Image
                          src={
                            typeof cardFunction?.logo == "object"
                              ? `${URL.createObjectURL(cardFunction?.logo)}`
                              : `${baseURL.PUBLIC_URL}${cardFunction?.logo}`
                          }
                          alt="logo-img"
                          onError={(e) => {
                            e.currentTarget.src = companylogo;
                          }}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  ) : null}
                </div>

                <div className={classes.infoWrapper}>
                  <div className={classes.leftSide}>
                    {cardFunction?.card_type ? (
                      <div
                        className={classes.cardType}
                        style={{
                          backgroundColor: cardFunction?.color
                            ? cardFunction?.color
                            : "#22A081",
                          border: `1px solid ${
                            cardFunction?.color
                              ? tagBorderColors[`${cardFunction?.color}`]
                              : "ABEDDD"
                          }`,
                        }}
                      >
                        <span>{cardFunction?.card_type}</span>
                      </div>
                    ) : null}

                    {cardFunction?.first_name || cardFunction?.last_name ? (
                      <div
                        className={classes.name}
                        style={{
                          color: cardFunction.color
                            ? cardFunction.color
                            : "#232c3d",
                        }}
                      >
                        {`${cardFunction?.first_name || ""} ${
                          cardFunction?.last_name || ""
                        }`}
                      </div>
                    ) : (
                      <div
                        className={classes.textSkeleton}
                        style={{
                          width: "130px",
                          height: "17px",
                          margin: "auto",
                          marginBottom: "15px",
                        }}
                      />
                    )}

                    {cardFunction?.pronouns && (
                      <>
                        {cardFunction?.pronouns ? (
                          <div
                            className={classes.name}
                            style={{
                              color: cardFunction.color
                                ? cardFunction.color
                                : "#232c3d",
                              fontSize: "12px",
                              textTransform: [
                                "she/her",
                                "he/him",
                                "they/them",
                              ].includes(cardFunction?.pronouns)
                                ? "capitalize"
                                : null,
                            }}
                          >
                            {`(${cardFunction?.pronouns || ""})`}
                          </div>
                        ) : (
                          <div
                            className={classes.textSkeleton}
                            style={{
                              width: "100px",
                              height: "12px",
                              margin: "auto",
                              marginBottom: "15px",
                              marginTop: "5px",
                            }}
                          />
                        )}
                      </>
                    )}

                    {cardFunction?.card_type === "business" ? (
                      <>
                        {cardFunction.company_position && (
                          <>
                            {cardFunction.company_position ? (
                              <div className={classes.profession}>
                                {cardFunction.company_position}
                              </div>
                            ) : (
                              <div
                                className={classes.textSkeleton}
                                style={{
                                  width: "92px",
                                  height: "10px",
                                  margin: "auto",
                                  marginBottom: "10px",
                                }}
                              />
                            )}
                          </>
                        )}

                        {cardFunction.company && (
                          <>
                            {cardFunction.company ? (
                              <div className={classes.company}>
                                {cardFunction.company}
                              </div>
                            ) : (
                              <div
                                className={classes.textSkeleton}
                                style={{
                                  width: "92px",
                                  height: "10px",
                                  margin: "auto",
                                }}
                              />
                            )}
                          </>
                        )}
                      </>
                    ) : null}
                  </div>
                </div>

                {cardFunction?.description && (
                  <div className={classes.descriptionWrapper}>
                    <div className={classes.leftSide}>
                      {/* {cardFunction?.description ? (
                    <h6>About me</h6>
                  ) : (
                    <div
                      className={classes.textSkeleton}
                      style={{
                        width: "100px",
                        height: "13px",
                        marginBottom: "10px",
                      }}
                    />
                  )} */}
                      {cardFunction?.description ? (
                        // <span>{cardFunction.description}</span>
                        <span>
                          {displayText}
                          {cardFunction?.description?.length > 60 && (
                            <button onClick={toggleText}>
                              {showFullText ? "Show Less" : "Read More"}
                            </button>
                          )}
                        </span>
                      ) : (
                        <>
                          <div
                            className={classes.textSkeleton}
                            style={{
                              width: "100%",
                              height: "10px",
                              marginBottom: "10px",
                              marginTop: "10px",
                            }}
                          />
                          <div
                            className={classes.textSkeleton}
                            style={{
                              width: "100%",
                              height: "10px",
                              marginBottom: "10px",
                            }}
                          />
                          <div
                            className={classes.textSkeleton}
                            style={{
                              width: "100%",
                              height: "10px",
                              marginBottom: "10px",
                            }}
                          />
                        </>
                      )}
                      <hr />
                    </div>
                  </div>
                )}

                {/* {cardFunction?.email ||
                  cardFunction?.address ||
                  cardFunction?.city ||
                  cardFunction?.state ||
                  cardFunction?.country ||
                  cardFunction?.website_url ||
                  cardFunction?.phone_number ||
                  (cardFunction?.card_phone?.length !== 0 && ( */}
                <div className={`${classes.contactInfo} contactInfo`}>
                  <div className="contactInfoInner">
                    {cardFunction?.email ||
                    cardFunction?.address ||
                    cardFunction?.city ||
                    cardFunction?.state ||
                    cardFunction?.country ||
                    cardFunction?.website_url ||
                    cardFunction?.phone_number ||
                    cardFunction?.card_phone?.length !== 0 ||
                    cardFunction?.contact_card_phone?.length !== 0 ? (
                      <h6>Contact Info</h6>
                    ) : (
                      <div
                        className={classes.textSkeleton}
                        style={{
                          width: "100px",
                          height: "13px",
                          marginBottom: "10px",
                        }}
                      />
                    )}
                    {/* <hr /> */}

                    {cardFunction?.email && (
                      <div
                        className={`${classes.infoContnt} infoContnt`}
                        style={{
                          borderColor: cardFunction.color
                            ? cardFunction.color
                            : "#232c3d",
                        }}
                      >
                        {cardFunction.color ? (
                          <>
                            {cardFunction?.email ? (
                              <a
                                href={`mailto:${cardFunction?.email}`}
                                style={{
                                  backgroundColor: cardFunction.color,
                                }}
                                className={`${classes.moreInfoIcon} moreInfoIcon`}
                              >
                                {cardFunction?.email ? <FiMail /> : null}
                              </a>
                            ) : (
                              <div
                                style={{
                                  backgroundColor: cardFunction.color,
                                }}
                                className={`${classes.moreInfoIcon} moreInfoIcon`}
                              >
                                {cardFunction?.email ? <FiMail /> : null}
                              </div>
                            )}
                          </>
                        ) : (
                          <div className={classes.iconSkeleton} />
                        )}

                        {cardFunction.email ? (
                          <>
                            <div>
                              <p
                                className={`${classes.subHeading} subHeading`}
                                style={{
                                  color: cardFunction.color
                                    ? cardFunction.color
                                    : "#232c3d",
                                }}
                              >
                                Email
                              </p>
                              {/* <a href={`mailto:${cardFunction.email}`}>{cardFunction.email}</a> */}
                              {cardFunction.content_view_type ===
                              "iconDesign" ? (
                                <a href={`mailto:${cardFunction.email}`}>
                                  {/* <MailIcon /> */}
                                  <img src={mailIcon} alt="" />
                                </a>
                              ) : (
                                <a href={`mailto:${cardFunction.email}`}>
                                  {cardFunction.email}
                                </a>
                              )}
                            </div>
                          </>
                        ) : (
                          <div
                            className={classes.textSkeleton}
                            style={{ width: "60%", height: "10px" }}
                          />
                        )}
                      </div>
                    )}

                    {cardFunction?.address ||
                    cardFunction?.city ||
                    cardFunction?.state ||
                    cardFunction?.country ? (
                      <div
                        className={`${classes.infoContnt} infoContnt`}
                        style={{
                          borderColor: cardFunction.color
                            ? cardFunction.color
                            : "#232c3d",
                        }}
                      >
                        {cardFunction.color ? (
                          <>
                            {cardFunction?.address ||
                            cardFunction?.city ||
                            cardFunction?.state ||
                            cardFunction?.country ? (
                              <a
                                href={`http://maps.google.com/?q=${address}`}
                                style={{
                                  backgroundColor: cardFunction.color,
                                }}
                                className={`${classes.moreInfoIcon} moreInfoIcon`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {cardFunction?.address ||
                                cardFunction?.city ||
                                cardFunction?.state ||
                                cardFunction?.country ? (
                                  <MdLocationOn />
                                ) : null}
                              </a>
                            ) : (
                              <div
                                style={{
                                  backgroundColor: cardFunction.color,
                                }}
                                className={`${classes.moreInfoIcon} moreInfoIcon`}
                              >
                                {cardFunction?.address ||
                                cardFunction?.city ||
                                cardFunction?.state ||
                                cardFunction?.country ? (
                                  <MdLocationOn />
                                ) : null}
                              </div>
                            )}
                          </>
                        ) : (
                          <div className={classes.iconSkeleton} />
                        )}

                        {cardFunction?.address ||
                        cardFunction?.city ||
                        cardFunction?.state ||
                        cardFunction?.country ? (
                          <>
                            <div>
                              <p
                                className={`${classes.subHeading} subHeading`}
                                style={{
                                  color: cardFunction.color
                                    ? cardFunction.color
                                    : "#232c3d",
                                }}
                              >
                                Location
                              </p>
                              {/* <a
                              href={`http://maps.google.com/?q=${address}`}
                              target="_blank"
                              style={{
                                lineHeight: "1.3",
                                display: "inline-flex",
                                whiteSpace: "normal",
                              }}
                            >
                              {address}
                            </a> */}
                              {cardFunction.content_view_type ===
                              "iconDesign" ? (
                                <a
                                  href={`http://maps.google.com/?q=${address}`}
                                  target="_blank"
                                  style={{
                                    lineHeight: "1.3",
                                    display: "inline-flex",
                                    whiteSpace: "normal",
                                  }}
                                  rel="noreferrer"
                                >
                                  <img src={locationIcon} alt="" />
                                </a>
                              ) : (
                                <a
                                  href={`http://maps.google.com/?q=${address}`}
                                  target="_blank"
                                  style={{
                                    lineHeight: "1.3",
                                    display: "inline-flex",
                                    whiteSpace: "normal",
                                  }}
                                  rel="noreferrer"
                                >
                                  {address}
                                </a>
                              )}
                            </div>
                          </>
                        ) : (
                          <div
                            className={classes.textSkeleton}
                            style={{ width: "60%", height: "10px" }}
                          />
                        )}
                      </div>
                    ) : (
                      ""
                    )}

                    {cardFunction?.website_url && (
                      <div
                        className={`${classes.infoContnt} infoContnt`}
                        style={{
                          borderColor: cardFunction.color
                            ? cardFunction.color
                            : "#232c3d",
                        }}
                      >
                        {cardFunction.color ? (
                          <>
                            {cardFunction?.website_url ? (
                              <a
                                style={{
                                  backgroundColor: cardFunction.color,
                                }}
                                className={`${classes.moreInfoIcon} moreInfoIcon`}
                                href={cardFunction?.website_url}
                                target="_blank"
                                rel="noreferrer"
                              >
                                {cardFunction?.website_url ? <BsGlobe /> : null}
                              </a>
                            ) : (
                              <div
                                style={{
                                  backgroundColor: cardFunction.color,
                                }}
                                className={`${classes.moreInfoIcon} moreInfoIcon`}
                              >
                                {cardFunction?.website_url ? <BsGlobe /> : null}
                              </div>
                            )}
                          </>
                        ) : (
                          <div className={classes.iconSkeleton} />
                        )}

                        {cardFunction?.website_url ? (
                          <>
                            <div>
                              <p
                                className={`${classes.subHeading} subHeading`}
                                style={{
                                  color: cardFunction.color
                                    ? cardFunction.color
                                    : "#232c3d",
                                }}
                              >
                                Website
                              </p>
                              {/* <a href={cardFunction?.website_url} target="_blank">
                              {cardFunction?.website_url}
                            </a> */}
                              {cardFunction.content_view_type ===
                              "iconDesign" ? (
                                <a href={`mailto:${cardFunction.email}`}>
                                  {/* <WebsiteIcon /> */}
                                  <img src={websiteIcon} alt="" />
                                </a>
                              ) : (
                                <a
                                  href={cardFunction?.website_url}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  {cardFunction?.website_url}
                                </a>
                              )}
                            </div>
                          </>
                        ) : (
                          <div
                            className={classes.textSkeleton}
                            style={{ width: "60%", height: "10px" }}
                          />
                        )}
                      </div>
                    )}

                    {cardFunction?.card_phone?.length !== 0 && (
                      <>
                        {cardFunction?.card_phone?.map((singlePhone) => {
                          return (
                            <div
                              className={`${classes.infoContnt} infoContnt`}
                              style={{
                                borderColor: cardFunction.color
                                  ? cardFunction.color
                                  : "#232c3d",
                              }}
                            >
                              {cardFunction?.color ? (
                                <>
                                  {cardFunction?.card_phone?.length !== 0 &&
                                  singlePhone?.phone_number ? (
                                    <a
                                      style={{
                                        backgroundColor: cardFunction.color,
                                      }}
                                      className={`${classes.moreInfoIcon} moreInfoIcon`}
                                      href={`tel:${
                                        showCountryCodeSeparateUpdate
                                          ? `+${
                                              singlePhone?.country_phone
                                            }${formatPhoneNumber(
                                              singlePhone?.phone_number
                                            )}`
                                          : `+${
                                              singlePhone.country_phone
                                            }${formatPhoneNumber(
                                              singlePhone.phone_number.slice(
                                                singlePhone.country_phone
                                                  .length,
                                                singlePhone.phone_number.length
                                              )
                                            )}`
                                      }`}
                                    >
                                      {singlePhone.phone_number ? (
                                        <>
                                          {singlePhone.phone_type === "home" ? (
                                            <HomePhoneIcon />
                                          ) : singlePhone.phone_type ===
                                            "office" ? (
                                            <OfficePhoneIcon />
                                          ) : (
                                            <BsFillTelephoneFill />
                                          )}
                                        </>
                                      ) : null}
                                    </a>
                                  ) : (
                                    <div
                                      style={{
                                        backgroundColor: cardFunction.color,
                                      }}
                                      className={`${classes.moreInfoIcon} moreInfoIcon`}
                                    >
                                      {singlePhone.phone_number ? (
                                        <>
                                          {singlePhone.phone_type === "home" ? (
                                            <HomePhoneIcon />
                                          ) : singlePhone.phone_type ===
                                            "office" ? (
                                            <OfficePhoneIcon />
                                          ) : (
                                            <BsFillTelephoneFill />
                                          )}
                                        </>
                                      ) : null}
                                    </div>
                                  )}
                                </>
                              ) : (
                                <div className={classes.iconSkeleton} />
                              )}

                              {singlePhone.phone_number ? (
                                <>
                                  <div>
                                    <p
                                      className={`${classes.subHeading} subHeading`}
                                      style={{
                                        color: cardFunction.color
                                          ? cardFunction.color
                                          : "#232c3d",
                                      }}
                                    >
                                      Phone
                                    </p>
                                    {/* <a
                                    href={`tel:${
                                      showCountryCodeSeparateUpdate
                                        ? `+${
                                            singlePhone.country_phone
                                          }${formatPhoneNumber(
                                            singlePhone.phone_number
                                          )}`
                                        : `+${
                                            singlePhone.country_phone
                                          }${formatPhoneNumber(
                                            singlePhone.phone_number.slice(
                                              singlePhone.country_phone.length,
                                              singlePhone.phone_number.length
                                            )
                                          )}`
                                    }`}
                                  >
                                    {showCountryCodeSeparateUpdate
                                      ? `+${
                                          singlePhone.country_phone
                                        }${formatPhoneNumber(
                                          singlePhone.phone_number
                                        )}`
                                      : `+${
                                          singlePhone.country_phone
                                        }${formatPhoneNumber(
                                          singlePhone.phone_number.slice(
                                            singlePhone.country_phone.length,
                                            singlePhone.phone_number.length
                                          )
                                        )}`}
                                  </a> */}
                                    {cardFunction.content_view_type ===
                                    "iconDesign" ? (
                                      <a href={`mailto:${cardFunction.email}`}>
                                        {/* <PhoneIcon /> */}
                                        <img src={phoneIcon} alt="" />
                                      </a>
                                    ) : (
                                      <a
                                        href={`tel:${
                                          showCountryCodeSeparateUpdate
                                            ? `+${
                                                singlePhone.country_phone
                                              }${formatPhoneNumber(
                                                singlePhone.phone_number
                                              )}`
                                            : `+${
                                                singlePhone.country_phone
                                              }${formatPhoneNumber(
                                                singlePhone.phone_number.slice(
                                                  singlePhone.country_phone
                                                    .length,
                                                  singlePhone.phone_number
                                                    .length
                                                )
                                              )}`
                                        }`}
                                      >
                                        {showCountryCodeSeparateUpdate
                                          ? `+${
                                              singlePhone.country_phone
                                            }${formatPhoneNumber(
                                              singlePhone.phone_number
                                            )}`
                                          : `+${
                                              singlePhone.country_phone
                                            }${formatPhoneNumber(
                                              singlePhone.phone_number.slice(
                                                singlePhone.country_phone
                                                  .length,
                                                singlePhone.phone_number.length
                                              )
                                            )}`}
                                      </a>
                                    )}
                                  </div>
                                </>
                              ) : (
                                <div
                                  className={classes.textSkeleton}
                                  style={{ width: "60%", height: "10px" }}
                                />
                              )}
                            </div>
                          );
                        })}
                      </>
                    )}

                    {cardFunction?.contact_card_phone?.length !== 0 && (
                      <>
                        {cardFunction?.contact_card_phone?.map(
                          (singlePhone) => {
                            return (
                              <div
                                className={`${classes.infoContnt} infoContnt`}
                                style={{
                                  borderColor: cardFunction.color
                                    ? cardFunction.color
                                    : "#232c3d",
                                }}
                              >
                                {cardFunction?.color ? (
                                  <>
                                    {cardFunction?.card_phone?.length !== 0 &&
                                    singlePhone?.phone_number ? (
                                      <a
                                        style={{
                                          backgroundColor: cardFunction.color,
                                        }}
                                        className={`${classes.moreInfoIcon} moreInfoIcon`}
                                        href={`tel:${
                                          showCountryCodeSeparateUpdate
                                            ? `+${
                                                singlePhone?.country_phone
                                              }${formatPhoneNumber(
                                                singlePhone?.phone_number
                                              )}`
                                            : `+${
                                                singlePhone.country_phone
                                              }${formatPhoneNumber(
                                                singlePhone.phone_number.slice(
                                                  singlePhone.country_phone
                                                    .length,
                                                  singlePhone.phone_number
                                                    .length
                                                )
                                              )}`
                                        }`}
                                      >
                                        {singlePhone.phone_number ? (
                                          <>
                                            {singlePhone.phone_type ===
                                            "home" ? (
                                              <HomePhoneIcon />
                                            ) : singlePhone.phone_type ===
                                              "office" ? (
                                              <OfficePhoneIcon />
                                            ) : (
                                              <BsFillTelephoneFill />
                                            )}
                                          </>
                                        ) : null}
                                      </a>
                                    ) : (
                                      <div
                                        style={{
                                          backgroundColor: cardFunction.color,
                                        }}
                                        className={`${classes.moreInfoIcon} moreInfoIcon`}
                                      >
                                        {singlePhone.phone_number ? (
                                          <>
                                            {singlePhone.phone_type ===
                                            "home" ? (
                                              <HomePhoneIcon />
                                            ) : singlePhone.phone_type ===
                                              "office" ? (
                                              <OfficePhoneIcon />
                                            ) : (
                                              <BsFillTelephoneFill />
                                            )}
                                          </>
                                        ) : null}
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  <div className={classes.iconSkeleton} />
                                )}

                                {singlePhone.phone_number ? (
                                  <>
                                    <div>
                                      <p
                                        className={`${classes.subHeading} subHeading`}
                                        style={{
                                          color: cardFunction.color
                                            ? cardFunction.color
                                            : "#232c3d",
                                        }}
                                      >
                                        Phone
                                      </p>
                                      {cardFunction.content_view_type ===
                                      "iconDesign" ? (
                                        <a
                                          href={`mailto:${cardFunction.email}`}
                                        >
                                          {/* <PhoneIcon /> */}
                                          <img src={phoneIcon} alt="" />
                                        </a>
                                      ) : (
                                        <a
                                          href={`tel:${
                                            showCountryCodeSeparateUpdate
                                              ? `+${
                                                  singlePhone.country_phone
                                                }${formatPhoneNumber(
                                                  singlePhone.phone_number
                                                )}`
                                              : `+${
                                                  singlePhone.country_phone
                                                }${formatPhoneNumber(
                                                  singlePhone.phone_number.slice(
                                                    singlePhone.country_phone
                                                      .length,
                                                    singlePhone.phone_number
                                                      .length
                                                  )
                                                )}`
                                          }`}
                                        >
                                          {showCountryCodeSeparateUpdate
                                            ? `+${
                                                singlePhone.country_phone
                                              }${formatPhoneNumber(
                                                singlePhone.phone_number
                                              )}`
                                            : `+${
                                                singlePhone.country_phone
                                              }${formatPhoneNumber(
                                                singlePhone.phone_number.slice(
                                                  singlePhone.country_phone
                                                    .length,
                                                  singlePhone.phone_number
                                                    .length
                                                )
                                              )}`}
                                        </a>
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  <div
                                    className={classes.textSkeleton}
                                    style={{ width: "60%", height: "10px" }}
                                  />
                                )}
                              </div>
                            );
                          }
                        )}
                      </>
                    )}
                  </div>
                </div>
                {/* ))} */}

                {cardFunction?.card_social_media?.length !== 0 && (
                  <div className={`${classes.socialMediaWrapper} mb-3`}>
                    {cardFunction?.card_social_media?.length !== 0 ? (
                      <h6>Social Media</h6>
                    ) : (
                      <div
                        className={classes.textSkeleton}
                        style={{
                          width: "100px",
                          height: "13px",
                          marginBottom: "10px",
                        }}
                      />
                    )}
                    {/* <hr /> */}

                    <div className={classes.socialMediaIconWrapper}>
                      {cardFunction?.card_social_media?.map((single, index) => (
                        <>
                          <a
                            key={`socialMediaCard${index}`}
                            style={{ backgroundColor: cardFunction.color }}
                            className={classes.socialMediaIcon}
                            href={single.url}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {single.icon === "custom"
                              ? single.custom_label.charAt(0)
                              : single.icon}
                          </a>
                        </>
                      ))}
                      {cardFunction?.card_social_media?.length === 0 ? (
                        <>
                          {cardFunction.color ? (
                            <>
                              <div
                                style={{ backgroundColor: cardFunction.color }}
                                className={classes.iconSkeleton}
                              />
                              <div
                                style={{ backgroundColor: cardFunction.color }}
                                className={classes.iconSkeleton}
                              />
                            </>
                          ) : (
                            <>
                              <div className={classes.iconSkeleton} />
                              <div className={classes.iconSkeleton} />
                            </>
                          )}
                        </>
                      ) : null}
                    </div>
                  </div>
                )}

                {cardFunction?.contact_card_other_cardFunction?.length !== 0 &&
                !cardFunction.contact_card_other_cardFunction ? (
                  ""
                ) : (
                  <>
                    {cardFunction.contact_card_other_cardFunction?.length !==
                    0 ? (
                      <div className={`${classes.contactInfo} contactInfo`}>
                        <div className="contactInfoInner">
                          {cardFunction.contact_card_other_cardFunction
                            ?.length !== 0 ? (
                            <>
                              <h6>Other Info</h6>
                            </>
                          ) : (
                            ""
                          )}
                          {cardFunction.contact_card_other_cardFunction?.map(
                            (item, index) => (
                              <>
                                <div
                                  className={`${classes.infoContnt} infoContnt mt-0`}
                                  style={{
                                    borderColor: cardFunction.color
                                      ? cardFunction.color
                                      : "#232c3d",
                                  }}
                                >
                                  <div key={index}>
                                    {item.type === "heading" ||
                                    item.type === "paragraph" ||
                                    item.type === "list" ||
                                    item.type === "line" ? (
                                      ""
                                    ) : (
                                      <>
                                        <p
                                          className={`${classes.subHeading} subHeading mt-3`}
                                          style={{
                                            color: cardFunction.color
                                              ? cardFunction.color
                                              : "#232c3d",
                                          }}
                                        >
                                          {item.label}
                                        </p>
                                        <p>{item.data}</p>
                                      </>
                                    )}
                                  </div>
                                </div>
                              </>
                            )
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </>
                )}
              </div>
            </div>
            {cardFunction.uuid ? (
              <div className={classes.cardFooter}>
                <div className="d-flex align-items-center gap-3 justify-content-center">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.contactco.contactapp"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={playstore} alt="" />
                  </a>
                  <a
                    href="https://apps.apple.com/us/app/contact-co-connect-smarter/id6683301913"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={appstore} alt="" />
                  </a>
                </div>
                <p>
                  © {new Date().getFullYear()} Contact Co, All rights reserved.
                </p>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <style type="text/css">
        {`
          .listDesign {
            //background-color: #e1e17d;
          }
          
          .gridDesign .contactInfoInner {
            display: flex;
            flex-wrap: wrap;
            justify-content:space-between;
          }
          .gridDesign .infoContnt {
            width: 48%;
            flex: 0 0 auto;
            padding: 0.5rem;
            display: block;id
            text-align: center;
            border:1px solid var(--tertiary_color);
            background:#fff;
            border-radius: 5px;
          }
          .treeDesign .contactInfoInner h6, .treeDesign .contactInfoInner .infoContnt{
            position:relative;
          }
          .gridDesign .infoContnt a{
            // white-space:normal;
            // display: inline-flex;
            word-break: break-word;
          }
          .gridDesign .contactInfo h6{
            width:100%;
          }
          .gridDesign .moreInfoIcon{
            max-width:38px;
            max-height:38px;
            margin:0 0 10px;
          }
          .treeDesign .contactInfoInner{position:relative;padding-left:15px}
          .treeDesign .contactInfoInner:before{
            content:"";
            border-left: 1px solid #B2B2B2;
            height: 100%;
            position: absolute;id
            top: 13px;
            left: 0px;
          }
          
          .treeDesign .contactInfoInner h6{
            background-color: #DBDBDB;
            padding: 0.2rem 0.75rem;
            display:inline-block;
          }
          .treeDesign .contactInfoInner h6:before{
            content:"";
            width: 15px;
            position: absolute;
            left: -15px;
            top: 0px;
            bottom: 0;
            height: 1px;
            margin: auto;id
            position: absolute;
            height: 1px;
            background-color: #B2B2B2;
            left: -15px;
            top: 0;
            bottom: 0;
            margin: auto;
          }
          .treeDesign .contactInfoInner .infoContnt:before{
            content:"";
            width: 15px;
            position: absolute;
            height: 1px;
            background-color: #B2B2B2;
            left: -15px;
            top: 0;
            bottom: 0;
            margin: auto;
          }
          .treeDesign .contactInfoInner .infoContnt:after{
            content:"";
            width: 5px;
            height: 5px;
            background-color: #B2B2B2;
            border-radius: 50%;
            position: absolute;
            left: -2.4%;
          }
          .iconDesign .contactInfoInner{
            display:flex;
            flex-wrap:wrap;
          }
          .iconDesign .contactInfo h6{
            width:100%;
          }
          .iconDesign .contactInfoInner .infoContnt{
            width:30.33333%;
            flex: 0 0 auto;
            margin-right:3%;
          }
          .iconDesign .contactInfoInner .infoContnt .moreInfoIcon{
            display:none;
          }
          .iconDesign .contactInfoInner .infoContnt .subHeading{
            display:none;
          }
          .profileImg.circle div,
          .profileImg.circle img {
            border-radius: 50%;
            transition: 0.5s all ease;
          }
          .profileImg.square div,
          .profileImg.square img {
            border-radius: 2px;
            transition: 0.5s all ease;
          }
          .profileImg.rectangular div,
          .profileImg.rectangular img {
            border-radius: 2px;
            width: 90px;
            height: 65px;
            transition: 0.5s all ease;
          }
          .logoWrapper{
            position: absolute;
            top: 115px;
            right: 45px;
          }
          .logoWrapper.circle div,
          .logoWrapper.circle img {
            border-radius: 50%;
            transition: 0.5s all ease;
          }
          .logoWrapper.square div,
          .logoWrapper.square img {
            border-radius: 2px;
            transition: 0.5s all ease;
          }
          .logoWrapper.rectangular div,
          .logoWrapper.rectangular img {
            border-radius: 2px;
            width: 50px;
            height: 25px;
            transition: 0.5s all ease;
          }
        `}
      </style>
    </>
  );
};

export default ViewCard;
