import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import SecondLoader from "../SecondLoader";
import ValidateSchema from "../../helpers/ValidateSchema";
import classes from "../../assets/styles/CustomURLModal.module.css";
import { MdClose } from "react-icons/md";
import FormInput from "../../components/Forms/FormInput";
import { UserToTeamOwnerSignupSchema } from "../../utils/SchemasValidation/SignupSchema";

const CreateTeamModal = ({
  loading,
  data,
  setSelectedData,
  handleSaveShowModal,
  handleCloseModal,
  show,
}) => {
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setSelectedData({ ...data, [name]: value });
  };

  // handle Validation
  const validateForm = () => {
    let newErrors = ValidateSchema(data, UserToTeamOwnerSignupSchema);
    setErrors(newErrors);
    if (Object.keys(newErrors).length) return false;
    return true;
  };

  const handleAddUrl = async (e) => {
    if (!validateForm()) return;
    handleSaveShowModal(data);
  };

  // Handle Update
  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          handleCloseModal();
          setErrors({});
        }}
        className={`customModal`}
        size="sm"
      >
        <Modal.Header className={classes.modalHeader}>
          <span className={classes.modalHeading}>Create Team</span>
          <div
            className="modalGradientCircle"
            style={{ position: "absolute", top: "25px" }}
          ></div>
          <MdClose
            onClick={() => {
              handleCloseModal();
              setErrors({});
            }}
            className="modalCloseBtn"
          />
        </Modal.Header>
        {loading ? (
          <SecondLoader shortModal={true} />
        ) : (
          <>
            <Modal.Body>
              <div style={{ marginTop: "10px" }}>
                <FormInput
                  type="text"
                  label="Team Name"
                  placeholder="Team Name"
                  name="team_name"
                  value={data.team_name}
                  onChange={handleChange}
                  error={errors?.team_name}
                  autofocus={true}
                />
              </div>
            </Modal.Body>

            <Modal.Footer>
              <div className={classes.btnWrapper}>
                <Button
                  type="button"
                  onClick={() => {
                    handleCloseModal();
                    setErrors({});
                  }}
                  className={classes.cancelBtn}
                >
                  Cancel
                </Button>

                <Button
                  type="button"
                  onClick={handleAddUrl}
                  className={classes.saveBtn}
                >
                  Save
                </Button>
              </div>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </>
  );
};

export default CreateTeamModal;
