import React, { useState, useEffect, useMemo } from "react";
import classes from "../../assets/styles/Pages/Function/CustomURLSListing.module.css";
import Loader from "../../components/Loader";
import { useAtom } from "jotai";
import { get, deleteRecord, post, put } from "../../server";
import {
  DOCUMENT_UPLOAD,
  FILE_UPLOAD,
  FUNCTION_STATUS,
} from "../../utils/constants/apiUrl";
import {
  loggedInUser,
  teamIDCheck,
  subscribedPackage,
} from "../../utils/store";
import { toast } from "react-toastify";
import showToast from "../../helpers/showToast";
import ConfirmationModal from "../../components/Modals/ConfirmationModal";
import FileUploadAddEditModal from "../../components/Modals/FileUploadAddEditModal";
import { Link } from "react-router-dom";
import FormInput from "../../components/Forms/FormInput";
import searchIcon from "../../assets/images/global_icons/searchicon.svg";
import { formatUrl } from "../../utils/functions/index";
import _ from "lodash";
import EmptyListingBanner from "../../components/EmptyListingBanner";
import ShowFileUpload from "../../components/PageComponents/Function/ShowFileUpload";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import useFunctionsCount from "../../hooks/useFunctionsCount";
import functionTypes from "../../utils/constants/functionTypes";
import { FaCirclePlus } from "react-icons/fa6";
import FunctionsLimitModal from "../../components/Modals/FunctionsLimitModal";
import baseURL from "../../utils/constants/baseUrl";

const FileUpload = () => {
  let emptyFilesData = {
    isNew: true,
    id: "",
    title: "",
    url_path: "",
  };
  const navigate = useNavigate();
  const { teamId, userId } = useParams();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const team_id = searchParams.get("team_id");
  const user_id = searchParams.get("user_id");
  const dynamicQRCodeId = searchParams.get("dynamic_qr_code");

  const [loading, setLoading] = useState(true);
  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [userTeamId, setUserTeamId] = useAtom(teamIDCheck);
  const [files, setFiles] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState(emptyFilesData);
  const [filterText, setFilterText] = useState("");
  const {
    compareLimits,
    updateCount,
    limitExceededForStarter,
    compareLimitsForDynamicQR,
  } = useFunctionsCount();
  const [showLimitModal, setShowLimitModal] = useState(false);
  const [purchasedPackage, setPurchasedPackage] = useAtom(subscribedPackage);

  const handleFilter = (e) => {
    setFilterText(e.target.value);
  };

  // Handle main modal
  const handleShowModal = (obj) => {
    if (obj && Object.keys(obj).length) setSelectedData(obj);
    setShowModal(true);
  };

  // const handleShowFileUploadModal = () => {
  //   const isLimitExceeded = compareLimits(functionTypes.FILE_UPLOAD);
  //   // if (isLimitExceeded)
  //   //   return showToast(
  //   //     false,
  //   //     "The requested feature is not compatible with your current plan. Upgrade your plan to continue using these features"
  //   //   );
  //   if (isLimitExceeded) {
  //     setShowLimitModal(true);
  //     return;
  //   }
  //   setShowModal(true);
  // };

  const handleShowFileUploadModal = async () => {
    if (dynamicQRCodeId) {
      const isLimitExceeded = compareLimitsForDynamicQR(
        functionTypes.FILE_UPLOAD,
        dynamicQRCodeId
      );
      if (isLimitExceeded) {
        return showToast(
          false,
          "The requested feature is not compatible with your current plan. Upgrade your plan to continue using these features"
        );
      } else {
        setShowModal(true);
      }
    } else if (purchasedPackage.id === 1) {
      limitExceededForStarter()
        .then((isLimitExceeded) => {
          if (isLimitExceeded) {
            setShowLimitModal(true);
          } else {
            setShowModal(true);
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      const isLimitExceeded = compareLimits(functionTypes.FILE_UPLOAD);
      if (isLimitExceeded) {
        return showToast(
          false,
          "The requested feature is not compatible with your current plan. Upgrade your plan to continue using these features"
        );
      } else {
        setShowModal(true);
      }
    }
    // if (isLimitExceeded)
    //   return showToast(
    //     false,
    //     "The requested feature is not compatible with your current plan. Upgrade your plan to continue using these features"
    //   );

    // if (isLimitExceeded) {
    //   setShowLimitModal(true);
    //   return;
    // }
  };

  // const handleSaveShowModal = async () => {
  //   setLoading(true);

  //   let payload = _.cloneDeep(selectedData);
  //   payload.url_path = formatUrl(payload.url_path);

  //   let result = {};
  //   if (selectedData.id) {
  //     result = await put(
  //       FILE_UPLOAD + `/${selectedData.id}`,
  //       payload,
  //       Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
  //     );
  //   } else {
  //     if (teamId && userId) {
  //       payload.team_id = teamId;
  //       payload.user_id = userId;
  //     }
  //     if (team_id && user_id) {
  //       payload.team_id = team_id;
  //       payload.user_id = user_id;
  //     }
  //     if (userTeamId) {
  //       payload.team_id = userTeamId;
  //     }
  //     result = await post(
  //       FILE_UPLOAD,
  //       payload,
  //       Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
  //     );
  //   }

  //   let { status, message, data } = result;

  //   if (status) {
  //     if (!selectedData.id) {
  //       updateCount(functionTypes.FILE_UPLOAD, functionTypes.ADD);
  //     }
  //     setLoading(false);

  //     let filesArr = _.cloneDeep(files);

  //     // On Update
  //     if (selectedData.id) {
  //       const index = files.findIndex((el) => el.id == selectedData.id);
  //       filesArr[index] = data;
  //     } else {
  //       filesArr.unshift(data);
  //     }

  //     setFiles(filesArr);
  //     handleCloseModal();
  //   } else {
  //     setLoading(false);
  //   }

  //   showToast(status, message);
  // };

  const handleSaveShowModal = async (selectedData, selectedFile) => {
    setLoading(true);

    try {
      let payload = _.cloneDeep(selectedData);
      payload.url_path = formatUrl(payload.url_path);
      payload.dynamic_qr_code_id = dynamicQRCodeId;

      let uploadResult = {};

      if (selectedFile) {
        const formData = new FormData();
        formData.append("document", selectedFile);
        formData.append("function_type", "document");

        // Upload the document
        uploadResult = await post(
          DOCUMENT_UPLOAD,
          formData,
          Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
        );

        if (uploadResult.status) {
          payload.url_path = baseURL.AWS_URL + uploadResult.data.url;
          payload.document_type = uploadResult.data.extension;
        } else {
          throw new Error(uploadResult.message);
        }
      }

      let result = {};
      if (selectedData.id) {
        // Update existing record
        result = await put(
          FILE_UPLOAD + `/${selectedData.id}`,
          payload,
          Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
        );
      } else {
        // Add new record
        if (teamId && userId) {
          payload.team_id = teamId;
          payload.user_id = userId;
        }
        if (team_id && user_id) {
          payload.team_id = team_id;
          payload.user_id = user_id;
        }
        if (userTeamId) {
          payload.team_id = userTeamId;
        }
        result = await post(
          FILE_UPLOAD,
          payload,
          Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
        );
      }

      let { status, message, data } = result;

      if (status) {
        if (!selectedData.id) {
          updateCount(
            functionTypes.FILE_UPLOAD,
            functionTypes.ADD,
            dynamicQRCodeId
          );
        }
        setLoading(false);

        let filesArr = _.cloneDeep(files);

        // On Update
        if (selectedData.id) {
          const index = files.findIndex((el) => el.id == selectedData.id);
          filesArr[index] = data;
        } else {
          filesArr.unshift(data);
        }

        setFiles(filesArr);
        handleCloseModal();
      } else {
        setLoading(false);
        showToast(false, message);
      }
    } catch (error) {
      setLoading(false);
      showToast(false, error.message);
    }
  };

  const handleCloseModal = () => {
    setSelectedData(emptyFilesData);
    setShowModal(false);
    setShowLimitModal(false);
  };

  // Handle confirmation modal
  const handleShowConfirmationModal = () => setShowConfirmationModal(true);
  const handleCloseConfirmationModal = () => setShowConfirmationModal(false);

  // handle delete
  const handleDelete = (id) => {
    setDeleteId(id);
    handleShowConfirmationModal(true);
  };

  const handleConfirmDelete = async () => {
    handleCloseConfirmationModal(true);
    setLoading(true);
    let queryParams = "";

    if (dynamicQRCodeId) {
      queryParams += `?dynamic_qr_code_id=${dynamicQRCodeId}`;
    }

    if (teamId || team_id || userTeamId) {
      queryParams += `${queryParams ? "&" : "?"}team_id=${
        teamId || team_id || userTeamId
      }`;
    }

    // if (teamId || team_id || userTeamId) {
    //   queryParams = `?team_id=${teamId || team_id || userTeamId}`;
    // }
    let { status, message } = await deleteRecord(
      `${FILE_UPLOAD}/${deleteId}${queryParams}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    showToast(status, message);

    if (status) {
      await getFiles();
      updateCount(
        functionTypes.FILE_UPLOAD,
        functionTypes.DELETE,
        dynamicQRCodeId
      );
      setLoading(false);
    }
    setLoading(false);
  };

  // Get all custom urls
  const getFiles = async () => {
    let queryParams = "";

    if (dynamicQRCodeId) {
      queryParams += `?dynamic_qr_code_id=${dynamicQRCodeId}`;
    }

    if (teamId || team_id || userTeamId) {
      queryParams += `${queryParams ? "&" : "?"}team_id=${
        teamId || team_id || userTeamId
      }`;
    }

    // if (teamId || team_id || userTeamId) {
    //   queryParams = `?team_id=${teamId || team_id || userTeamId}`;
    // }

    if (userId || user_id) {
      queryParams += `${queryParams ? "&" : "?"}user_id=${userId || user_id}`;
    }

    let { status, message, data } = await get(
      `${FILE_UPLOAD}${queryParams}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      setFiles(data);
    } else {
      toast.error(message);
    }
    setLoading(false);
  };
  const handleStatus = async (id) => {
    const allFiles = files.find((file) => file.id === id);
    let newStatus = allFiles.status == true ? 0 : 1;
    let queryParams = "";

    if (dynamicQRCodeId) {
      queryParams += `?dynamic_qr_code_id=${dynamicQRCodeId}`;
    }
    const payload = {
      id: allFiles.id,
      status: newStatus,
      type: allFiles.function_type,
      team_id: allFiles.team_id,
    };
    let { status, message } = await post(
      `${FUNCTION_STATUS}${queryParams}`,
      payload,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      await getFiles();
    } else {
      toast.error(message);
    }
    setLoading(false);
  };
  useEffect(() => {
    getFiles();
  }, [userTeamId]);

  const filteredItems = useMemo(() => {
    const filtered = files.filter(
      (item) =>
        `${item.title || ""}`
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
        `${item.url_path || ""}`
          .toLowerCase()
          .includes(filterText.toLowerCase())
    );

    return filtered;
  }, [filterText, files]);

  const handleGoBack = () => {
    navigate(-1);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="">
          <div className={classes.mainWrapper}>
            <h6 className={classes.title}>
              <Link
                onClick={handleGoBack}
                style={{ color: "var(--heading_text_color)" }}
              >
                Functions
              </Link>
              {` > File Upload`}
            </h6>
            <div className="d-flex align-items-center justify-content-center">
              {files.length !== 0 ? (
                <FormInput
                  icon={searchIcon}
                  placeholder="Search..."
                  value={filterText}
                  handleChange={handleFilter}
                />
              ) : null}
              {dynamicQRCodeId !== null ? (
                <Link className="primary_button ms-4" to={`/qrcode`}>
                  {`< Back`}
                </Link>
              ) : (
                ""
              )}
            </div>
          </div>

          {files.length === 0 ? (
            <EmptyListingBanner
              headLineText="URLs for your File Uploads"
              title="Upload File"
              onClick={handleShowFileUploadModal}
              showAdd={
                loggedUser.role.includes("customer") ||
                loggedUser.role.includes("team_owner")
              }
            />
          ) : (
            <div className={classes.customURLSWrapper}>
              <div>
                <div className={classes.addWrapper}>
                  {(Object.keys(loggedUser).length !== 0 &&
                    loggedUser.role.includes("customer")) ||
                  loggedUser.role.includes("team_owner") ? (
                    <span onClick={handleShowFileUploadModal}>
                      <div className={classes.addContentWrapper}>
                        {/* <img src={addcontact} alt="" /> */}
                        <FaCirclePlus />
                        <p>Upload File</p>
                      </div>
                    </span>
                  ) : null}
                </div>

                <ShowFileUpload
                  loading={loading}
                  show={showModal}
                  files={filteredItems}
                  handleShowModal={handleShowModal}
                  handleCloseModal={handleCloseModal}
                  handleDelete={handleDelete}
                  handleStatus={handleStatus}
                  getFiles={getFiles}
                />
              </div>
            </div>
          )}

          {/* Custom url modals */}
          {showModal && (
            <FileUploadAddEditModal
              show={showModal}
              data={selectedData}
              setSelectedData={setSelectedData}
              handleSaveShowModal={handleSaveShowModal}
              handleCloseModal={handleCloseModal}
            />
          )}
        </div>
      )}

      {/* confirmation modal */}

      <ConfirmationModal
        show={showConfirmationModal}
        handleCloseModal={handleCloseConfirmationModal}
        handleConfirmDelete={handleConfirmDelete}
      />
      <FunctionsLimitModal
        show={showLimitModal}
        handleClose={handleCloseModal}
        getData={getFiles}
      />
    </>
  );
};

export default FileUpload;
