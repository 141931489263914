import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import SecondLoader from "../SecondLoader";
import AddVenmoSchema from "../../utils/SchemasValidation/AddVenmoSchema";
import ValidateSchema from "../../helpers/ValidateSchema";
import classes from "../../assets/styles/CustomURLModal.module.css";
import { MdClose } from "react-icons/md";
import FormInput from "../../components/Forms/FormInput";

const VenmoAddEditModal = ({
  data,
  loading,
  setSelectedData,
  handleSaveShowModal,
  handleCloseModal,
  show,
}) => {
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setSelectedData({ ...data, [name]: value });
  };

  // handle Validation
  const validateForm = () => {
    let newErrors = ValidateSchema(data, AddVenmoSchema);
    setErrors(newErrors);
    if (Object.keys(newErrors).length) return false;
    return true;
  };

  const handleAddUrl = async (e) => {
    if (!validateForm()) return;
    handleSaveShowModal(data);
  };

  // Handle Update
  return (
    <>
      <Modal
        show={show}
        onHide={() => {
          handleCloseModal();
          setErrors({});
        }}
        className={`customModal`}
        size="sm"
      >
        <Modal.Header className={classes.modalHeader}>
          <span className={classes.modalHeading}>
            {data?.isNew ? "Add" : "Edit "} Venmo
          </span>
          <div className="modalGradientCircle"></div>
          <MdClose
            onClick={() => {
              handleCloseModal();
              setErrors({});
            }}
            className="modalCloseBtn"
          />
        </Modal.Header>
        {loading ? (
          <SecondLoader />
        ) : (
          <>
            <Modal.Body>
              <div style={{ marginTop: "-140px" }}>
                <FormInput
                  type="text"
                  label="Title"
                  placeholder="Enter Title"
                  name="title"
                  value={data.title}
                  onChange={handleChange}
                  error={errors?.title}
                />

                <FormInput
                  type="text"
                  label="URL"
                  placeholder="Enter URL"
                  name="url_path"
                  value={data.url_path}
                  onChange={handleChange}
                  error={errors?.url_path}
                />
              </div>
            </Modal.Body>

            <Modal.Footer>
              <div className={classes.btnWrapper}>
                <Button
                  type="button"
                  onClick={() => {
                    handleCloseModal();
                    setErrors({});
                  }}
                  className={classes.cancelBtn}
                >
                  Cancel
                </Button>

                <Button
                  type="button"
                  onClick={handleAddUrl}
                  className={classes.saveBtn}
                >
                  Save
                </Button>
              </div>
            </Modal.Footer>
          </>
        )}
      </Modal>
    </>
  );
};

export default VenmoAddEditModal;
