import CardFunctionLogo from "../../pages/Function/FunctionLogo/CardFunctionLogo";
import Emergencycontactlogo from "../../pages/Function/FunctionLogo/Emergencycontactlogo";
import CustomURLsLogo from "../../pages/Function/FunctionLogo/CustomURLsLogo";
import Paymenticon from "../../pages/Function/FunctionLogo/Paymenticon";
// import UploadFilesIcon from "../../assets/images/function_page_icons/UploadFilesIcon.svg";
import UploadFilesIcon from "../../pages/Function/FunctionLogo/UploadFilesIcon";
import FormBuilderLogo from "../../pages/Function/FunctionLogo/FormBuilderLogo";
import MyMemoryLogo from "../../pages/Function/FunctionLogo/MyMemoryLogo";
import MedicalIDLogo from "../../pages/Function/FunctionLogo/MedicalIDLogo";

const FunctionCreateCardsData = [
  {
    id: 1,
    bgColor:
      "radial-gradient(52.22% 52.22% at 50% 39.76%, var(--gradient_color_dark) 0%, rgba(34, 160, 129, 0) 100%)",
    //image: cardfunctionlogo,
    image: (
      <CardFunctionLogo
        primaryColor="var(--button_text_color)"
        secondaryColor="var(--primary_button_color)"
      />
    ),
    title: "Digital Card",
    description: "Create Your Personal & Businesss Card",
    url: "/function/cards",
    function_type: "card",
  },
  // {
  //   id: 2,
  //   bgColor:
  //     "radial-gradient(52.22% 52.22% at 50% 39.76%, #0288FF 0%, rgba(2, 136, 255, 0) 100%)",
  //   image: venmologo,
  //   title: "Venmo",
  //   description: "Link Your Venmo Account",
  //   url: "/function/venmoListing",
  // },
  {
    id: 3,
    bgColor:
      "radial-gradient(52.22% 52.22% at 50% 39.76%,var(--gradient_color_light) 0%, rgba(0, 214, 50, 0) 100%)",
    // image: paymenticon,
    image: (
      <Paymenticon
        primaryColor="var(--button_text_color)"
        secondaryColor="var(--primary_button_color)"
      />
    ),
    title: "Payment Service",
    description: "Link Your Payment Service Account",
    url: "/function/paymentListing",
    function_type: "payment",
  },
  {
    id: 4,
    bgColor:
      " radial-gradient(52.22% 52.22% at 50% 39.76%, var(--gradient_color_dark) 0%, rgba(34, 160, 129, 0) 100%)",
    //image: emergencycontactlogo,
    image: (
      <Emergencycontactlogo
        primaryColor="var(--button_text_color)"
        secondaryColor="var(--border_color)"
      />
    ),
    title: "Emergency Contact",
    description: "For Emergency Purpose",
    url: "/function/emergencycontacts",
    function_type: "emergency_contact",
  },
  {
    id: 5,
    bgColor:
      "radial-gradient(52.22% 52.22% at 50% 39.76%, var(--gradient_color_dark) 0%, rgba(34, 160, 129, 0) 100%) ",
    //image: customurlslogo,
    image: (
      <CustomURLsLogo
        primaryColor="var(--heading_text_color)"
        secondaryColor="var(--primary_button_color)"
        accentColor="var(--border_color)"
      />
    ),
    title: "Custom URL’s",
    description: "Create Your Custom URL’s",
    url: "/function/customurlslisting",
    function_type: "custom_url",
  },
  {
    id: 6,
    bgColor:
      "radial-gradient(52.22% 52.22% at 50% 39.76%, var(--gradient_color_light) 0%, rgba(0, 214, 50, 0) 100%)",
    //image: UploadFilesIcon,
    image: (
      <UploadFilesIcon
        primaryColor="var(--heading_text_color)"
        secondaryColor="var(--primary_button_color)"
      />
    ),
    title: "File Upload URL",
    description: "Upload and Share documents",
    url: "/function/fileupload",
    function_type: "file_upload",
  },
  {
    id: 7,
    bgColor:
      "radial-gradient(52.22% 52.22% at 50% 39.76%, var(--gradient_color_dark) 0%, rgba(34, 160, 129, 0) 100%) ",
    //image: FormBuilderIcon,
    image: (
      <FormBuilderLogo
        primaryColor="var(--heading_text_color)"
        secondaryColor="var(--primary_button_color)"
      />
    ),
    title: "Lead Builder",
    description: "For Lead Generation",
    url: "/function/formBuilderListing",
    function_type: "form_builder",
  },
  {
    id: 8,
    bgColor:
      "radial-gradient(52.22% 52.22% at 50% 39.76%, var(--gradient_color_dark) 0%, rgba(34, 160, 129, 0) 100%) ",
    image: (
      <MyMemoryLogo
        primaryColor="var(--button_text_color)"
        secondaryColor="var(--primary_button_color)"
      />
    ),
    title: "Memory",
    description: "Create Your Audio & video Link",
    url: "/function/memoryListing",
    function_type: "memory",
  },
  {
    id: 9,
    bgColor:
      "radial-gradient(52.22% 52.22% at 50% 39.76%, var(--gradient_color_dark) 0%, rgba(34, 160, 129, 0) 100%) ",
    image: (
      <MedicalIDLogo
        primaryColor="var(--button_text_color)"
        secondaryColor="var(--border_color)"
      />
    ),
    title: "Medical ID",
    description: "Create Your Medical ID",
    url: "/function/medicalListing",
    function_type: "medical_id",
  },
];

export default FunctionCreateCardsData;
