import React, { useState, useEffect, useMemo } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import DataTable, { createTheme } from "react-data-table-component";
import { useAtom } from "jotai";
import classes from "../../../assets/styles/Contact.module.css";
import {
  TEAM_MEMBER_STATUS,
  USER,
  TEAMDETAIL,
} from "../../../utils/constants/apiUrl";
import { get, post, put, deleteRecord } from "../../../server";
import showToast from "../../../helpers/showToast";
import { loggedInUser } from "../../../utils/store";
import Loader from "../../../components/Loader";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal";
import FormInput from "../../../components/Forms/FormInput";
import searchIcon from "../../../assets/images/global_icons/searchicon.svg";
import formatPhoneNumber from "../../../helpers/formatPhoneNumber";
import { Button } from "react-bootstrap";
import jobIcon from "../../../assets/images/settings_page_icons/job.svg";
import ValidateSchema from "../../../helpers/ValidateSchema";
import TeamDetailSchema from "../../../utils/SchemasValidation/TeamDetailSchema";
import _ from "lodash";
import MemberSuspendModal from "../../../components/Modals/MemberSuspendModal";
const TeamDetail = () => {
  const { teamId } = useParams();

  const [loggedUser, setLoggedUser] = useAtom(loggedInUser);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [deleteId, setDeleteId] = useState(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [filterText, setFilterText] = useState("");
  const [selectedData, setSelectedData] = useState("");
  const [showModal, setShowModal] = useState(false);

  const [errors, setErrors] = useState({});
  const [teamDetail, setTeamDetail] = useState({ name: "" });

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setTeamDetail({ ...teamDetail, [name]: value });
  };

  // Handle main modal
  const handleShowModal = (obj) => {
    if (obj && Object.keys(obj).length) setSelectedData(obj);
    setShowModal(true);
  };

  const handleSaveShowModal = async () => {
    // setLoading(true);
    handleCloseModal();
    let payload = _.cloneDeep(selectedData);

    let result = {};
    if (selectedData.user_staus_id) {
      result = await post(
        TEAM_MEMBER_STATUS + `/${selectedData.user_staus_id}`,
        payload,
        Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
      );
    }

    let { status, message, data } = result;

    if (status) {
      await loadUsers();
      setLoading(false);
    } else {
      // setLoading(false);
    }

    showToast(status, message);
  };

  const handleCloseModal = () => {
    setSelectedData("");
    setShowModal(false);
  };

  const handleFilter = (e) => {
    setFilterText(e.target.value);
  };

  const loadUsers = async () => {
    setLoading(true);
    let { status, message, data } = await get(
      `${TEAMDETAIL}/${teamId}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    if (status) {
      const teamMembersData = data.team_members.map((member) => ({
        ...member.user_data,
        team_id: member.team_id,
        user_id: member.user_id,
        status: member.check_status,
        user_staus_id: member.id,
      }));
      setUsers(teamMembersData);
      setTeamDetail({ ...teamDetail, name: data.name });
    } else {
      showToast(status, message);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadUsers();
  }, []);

  const validateForm = () => {
    let newErrors = ValidateSchema(teamDetail, TeamDetailSchema);
    setErrors(newErrors);
    if (Object.keys(newErrors).length) return false;
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    setLoading(true);
    let { status, message, data } = await put(
      `${TEAMDETAIL}/${teamId}`,
      teamDetail,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );

    showToast(status, message);

    setLoading(false);
  };

  const filteredItems = useMemo(() => {
    const filtered = users.filter(
      (item) =>
        `${item.first_name || ""} ${item.last_name || ""}`
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
        `${item.email || ""}`
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
        `${item.status === "suspended" ? "suspend" : item.status || ""}`
          .toLowerCase()
          .includes(filterText.toLowerCase()) ||
        `${item.country_phone ? `+${item.country_phone}` : ""}${
          item.phone_number || ""
        }`.includes(filterText)
    );

    return filtered;
  }, [filterText, users]);

  createTheme(
    "solarized",
    {
      background: {
        default: "background: var(--secondary_color)",
      },
    },
    "dark"
  );

  const columns = [
    {
      name: "Name",
      width: "20%",
      cell: (row) => (
        <p className="globalDetail">{`${row.first_name} ${row.last_name}`}</p>
      ),
    },
    {
      name: "Email",
      width: "20%",
      cell: (row) => (
        <a
          href={`mailto:${row.email}`}
          className="globalDetail"
          style={{ display: "block" }}
        >
          {row.email}
        </a>
      ),
    },
    {
      name: "Phone #",
      cell: (row) => (
        <>
          {row.phone_number ? (
            <a
              href={`tel:${
                row.country_phone
                  ? "+" +
                    row.country_phone +
                    " " +
                    formatPhoneNumber(row.phone_number)
                  : row.phone_number || ""
              }`}
              className="globalDetail"
            >
              {row.country_phone
                ? "+" +
                  row.country_phone +
                  " " +
                  formatPhoneNumber(row.phone_number)
                : row.phone_number || ""}
            </a>
          ) : (
            <p className="globalDetail">-</p>
          )}
        </>
      ),
    },
    {
      width: "20%",
      name: "Status",
      cell: (row) => (
        <p className="globalDetail">
          {row.status === "suspended" ? "suspend" : row.status}
        </p>
      ),
    },
    {
      name: "Action",
      width: "20%",
      right: "true",
      cell: (row) => (
        <div className={classes.action}>
          <button
            className="btn customActionDropdown dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <BsThreeDotsVertical className={"globalVerticalThreeDots"} />
          </button>
          <ul className="customActionDropdownMenu dropdown-menu dropdown-menu-end ">
            <li>
              <Link
                className="dropdown-item"
                to={`/allTeamOwners/teamDetail/${teamId}/editTeamMember/${row.id}`}
              >
                Edit
              </Link>
            </li>
            <li>
              <span
                className="dropdown-item"
                onClick={() => handleShowModal(row)}
              >
                Change Status
              </span>
            </li>
            <li>
              <span
                className="dropdown-item"
                style={{ color: "#E35252" }}
                onClick={() => handleDelete(row.id)}
              >
                Delete
              </span>
            </li>
          </ul>
        </div>
      ),
    },
  ];

  // Handle confirmation modal
  const handleShowConfirmationModal = () => setShowConfirmationModal(true);
  const handleCloseConfirmationModal = () => setShowConfirmationModal(false);

  // handle delete
  const handleDelete = (id) => {
    setDeleteId(id);
    handleShowConfirmationModal(true);
  };

  const handleConfirmDelete = async () => {
    handleCloseConfirmationModal(true);
    setLoading(true);
    let { status, message } = await deleteRecord(
      `${USER}/${deleteId}`,
      Object.keys(loggedUser).length !== 0 ? loggedUser.token : null
    );
    showToast(status, message);

    if (status) {
      await loadUsers();
      setLoading(false);
    }
    setLoading(false);
  };

  return (
    <>
      {!loading ? (
        <div className="">
          <div className="page_Header">
            <div className="d-flex justify-content-between align-items-md-center align-items-initial flex-md-row flex-column gap-3">
              <h6>
                <Link
                  to="/allTeamOwners"
                  style={{ color: "var(--primary_text_color)" }}
                >
                  Team Owners
                </Link>
                {` > Team Details`}
              </h6>
              <div>
                <FormInput
                  icon={searchIcon}
                  placeholder="Search..."
                  value={filterText}
                  handleChange={handleFilter}
                />
              </div>
            </div>
          </div>

          <div className={`mainWrapper ${classes.outerWrapper}`}>
            <div className="row gx-5 gy-3">
              <div className="col-md-4 col-12">
                <FormInput
                  label="Team Name"
                  type="text"
                  placeholder="Team Name"
                  name="name"
                  value={teamDetail.name}
                  handleChange={handleChange}
                  icon={jobIcon}
                  error={errors.name}
                />
              </div>
            </div>
            <div className="formBottom">
              <Button
                className="submitButton"
                type="submit"
                onClick={handleSubmit}
              >
                Save
              </Button>
            </div>
          </div>

          <div className={`listingWrapper mainWrapper`}>
            <DataTable
              columns={columns}
              data={filteredItems}
              pagination
              theme="solarized"
            />
          </div>

          <ConfirmationModal
            show={showConfirmationModal}
            handleCloseModal={handleCloseConfirmationModal}
            handleConfirmDelete={handleConfirmDelete}
            confirmTitle="user"
          />
        </div>
      ) : (
        <Loader />
      )}

      <MemberSuspendModal
        show={showModal}
        data={selectedData}
        setSelectedData={setSelectedData}
        handleSaveShowModal={async () => {
          await handleSaveShowModal(selectedData);
          handleCloseModal();
        }}
        handleCloseModal={handleCloseModal}
        loading={loading}
      />
    </>
  );
};

export default TeamDetail;
